import React, { Component } from "react"
import { connect } from 'react-redux'
import { Trans, withTranslation } from "react-i18next"

import { actions } from "../../actions/editor"
import { actions as actionsTrafico } from '../../actions/trafico'
import ModalEditor from "./ModalEditor"
import FilaOtrosMapas from "../../components/personal/FilaOtrosMapas"
import { updateMapaTrafico } from "../../fetchactions/trafico"

class ModalTrafico extends Component {
    handleClickAceptar () {
        this.hide()
    }

    handleClickCancelar () {
        this.hide()
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalTrafico(false))
    }

    handleClickNuevoMapa () {
        const { dispatch } = this.props
        this.hide()
        dispatch(actions.setVisibleModalNuevoTrafico(true))
    }

    handleClickEditar (mapa) {
        const { dispatch } = this.props
        dispatch(actionsTrafico.setMapaTraficoEditando(mapa))
        this.hide()
        dispatch(actions.setVisibleModalEditarTrafico(true))
    }

    handleClickEliminar (mapa) {
        const { dispatch } = this.props
        dispatch(actions.setTraficoEliminar(mapa))
        dispatch(actions.setVisibleModalTrafico(false))
        dispatch(actions.setVisibleModalEliminarTrafico(true))
    }

    handleReordenar (prevOrden, nextOrden) {
        const { mapasTrafico, dispatch } = this.props
        const ordenes = mapasTrafico.map(v => v.orden)
        const prevIndex = ordenes.indexOf(prevOrden)
        const nextIndex = ordenes.indexOf(nextOrden)
        const mtC = mapasTrafico.slice()
        if (nextIndex >= mtC.length) {
            let k = nextIndex - mtC.length + 1
            while (k--) {
                mtC.push(undefined)
            }
        }
        mtC.splice(nextIndex, 0, mtC.splice(prevIndex, 1)[0])
        for (let i in mtC) {
            if (mtC[i].orden !== ordenes[i]) {
                dispatch(updateMapaTrafico(mtC[i].id, {
                    nombre: mtC[i].nombre,
                    orden: ordenes[i],
                    capa_fondo: mtC[i].capa_fondo
                }))
            }
        }
    }

    render() {
        const { t, mapasTrafico } = this.props
        return (
            <ModalEditor
                show
                footer
                onClickAceptar={this.handleClickAceptar.bind(this)}
                onClickCancelar={this.handleClickCancelar.bind(this)}
                onHide={this.handleClickCancelar.bind(this)}
                titulo={t('Mapas de tráfico')}
            >
                <div className='row'>
                    <div className='col-12'>
                        <ul className='list-group list-group-flush'>
                            {mapasTrafico.sort((a,b) => a.orden - b.orden).map(mt =>
                                <FilaOtrosMapas
                                    key={mt.id}
                                    mapa={mt}
                                    handleClickEditar={this.handleClickEditar.bind(this)}
                                    handleClickEliminar={this.handleClickEliminar.bind(this)}
                                    handleReordena={this.handleReordenar.bind(this)}
                                />
                            )}
                        </ul>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <button
                            className='btn btn-primary float-right mt-2'
                            onClick={this.handleClickNuevoMapa.bind(this)}
                        >
                            <i className='fas fa-plus-circle mr-1' />
                            <Trans>Nuevo mapa de tráfico</Trans>
                        </button>
                    </div>
                </div>
            </ModalEditor>
        )
    }
}

const mapStateToProps = state => ({
    mapasTrafico: state.mapasTrafico.data
})

export default withTranslation()(connect(mapStateToProps)(ModalTrafico))
