import {actions} from "../actions/servicios";
import {actions as actionsComun} from "../actions/comun";
import {API_BASE_URL} from "../constants/api";

export function fetchServicios() {
    return dispatch => {
        dispatch(actions.fetchServiciosPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'servicio/')
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);

                dispatch(actions.fetchServiciosCorrecto(res));
                dispatch(actionsComun.restaPeticion())
            })
            .catch(err => {
                dispatch(actions.fetchServiciosError(err));
                dispatch(actionsComun.restaPeticion());
            })
    }
}
