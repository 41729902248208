import React, {useEffect} from 'react';
import {useDrag, useDrop} from "react-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTh, faTrashAlt} from "@fortawesome/free-solid-svg-icons";

const FilaDragTrayectos = (props) => {
    const {index, trayecto, onChangeRepeticiones, getDistanciaTrayecto, distanciaEnUnidad, sector, repeticiones,
        reordenaTrayectos, onClickEliminar, esquemaSimplificado, onChangeEsquemaSimplificado, esquema} = props;
    const repRef = React.createRef();
    const esquemaSimplificadoRef = React.createRef();
    const id = index;
    const [, drag] = useDrag({
        item: {type: 'fila', id},
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
    });
    const [, drop] = useDrop({
        accept: 'fila',
        drop({id: draggedId}) {
            if(draggedId !== id) {
                reordenaTrayectos(id, draggedId, sector);
            }
        }
    });

    useEffect(() => {
        repRef.current.value = repeticiones;
        esquemaSimplificadoRef.current.checked = esquemaSimplificado;
    });


    return <tr ref={node=>drag(drop(node))}>
            <td><FontAwesomeIcon icon={faTh} /></td>
            <td>
                <div className='titulo-trayecto' style={{backgroundColor: trayecto.color}}>
                    {trayecto.nombre}</div>
            </td>
            <td>
                <input min={1} className='input-repeticiones' type='number' defaultValue={repeticiones} ref={repRef}
                       onChange={e => {onChangeRepeticiones(e, esquema, sector)}}/>
            </td>
            <td>
                <input type='checkbox' defaultChecked={esquemaSimplificado} ref={esquemaSimplificadoRef}
                       onChange={e => {onChangeEsquemaSimplificado(e, esquema, sector)}}/>
            </td>
            <td>{getDistanciaTrayecto(trayecto)}</td>
            <td>
                {trayecto.distancia_homologada ? distanciaEnUnidad(trayecto.distancia_homologada,
                    trayecto.unidad_medida) : getDistanciaTrayecto(trayecto)}
            </td>
            <td className='basura-trayecto' onClick={e => onClickEliminar(e, index - 1, sector)}>
                <FontAwesomeIcon icon={faTrashAlt}/>
            </td>
        </tr>
};

export default FilaDragTrayectos;
