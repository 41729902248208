import { initialState } from "../constants/state"
import {ACTION_TYPES} from "../actions/elementoVoluntarios";

export function elementoVoluntariosCreateReducer (state = initialState.elementoVoluntariosCreate, action) {
    switch (action) {
        case ACTION_TYPES.CREATE_ELEMENTO_VOLUNTARIOS_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.CREATE_ELEMENTO_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.elementoVoluntarios
            }

        case ACTION_TYPES.CREATE_ELEMENTO_VOLUNTARIOS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}


export function elementoVoluntariosDeleteReducer (state = initialState.elementoVoluntariosDelete, action) {
    switch (action) {
        case ACTION_TYPES.DELETE_ELEMENTO_VOLUNTARIOS_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.DELETE_ELEMENTO_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.elementoVoluntarios
            }

        case ACTION_TYPES.DELETE_ELEMENTO_VOLUNTARIOS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}
