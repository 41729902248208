import React, {Component} from 'react';
import {Polyline, withLeaflet} from "react-leaflet";
import L from "leaflet";
import "leaflet-polylinedecorator";
import "leaflet-geometryutil";

class TrayectoFlechas extends Component {
    componentDidMount() {
        const {polyRef, elemento, onMouseover, onMouseout} = this.props;
        const polyline = polyRef.current.leafletElement;
        const {map} = polyRef.current.props.leaflet;
        let patterns = [
            {
                offset: 0,
                repeat: 200,
                symbol: L.Symbol.arrowHead({
                    pixelSize: 17,
                    pathOptions: {
                        fillOpacity: 1,
                        weight: 0,
                        color: elemento.color
                    },
                    interactive: false
                })
            }

        ];
        this.decorator = L.polylineDecorator(polyline, {
            patterns: patterns
        });
        map.addLayer(this.decorator);
        this.decorator.on('mouseover', onMouseover);
        this.decorator.on('mouseout', onMouseout);
        this.animateFlechas(map, polyline, elemento);
    }

    animateFlechas(mapa, linea, elemento) {
        let offset = 0;
        setInterval(() => {
            let arrayPoints = [];
            for(let i in linea._latlngs) {
                arrayPoints.push(mapa.latLngToLayerPoint(linea._latlngs[i]));
            }
            let lengthPixels = L.GeometryUtil.length(arrayPoints);
            let maxOffset = lengthPixels > 200 ? 200 : lengthPixels
            let patterns = [
                {
                    offset: offset,
                    repeat: maxOffset,
                    symbol: L.Symbol.arrowHead({
                        pixelSize: 17,
                        pathOptions: {
                            fillOpacity: 1,
                            weight: 0,
                            color: elemento.color
                        }
                    })
                }
            ]
            if(offset < maxOffset) {
                offset += 1;
            } else {
                offset = 0;
            }
            this.decorator.setPatterns(patterns)
        }, 40)
    }

    componentWillUnmount() {
        const {polyRef} = this.props;
        const {map} = polyRef.current.props.leaflet;

        map.removeLayer(this.decorator);
    }

    render() {
        const {polyRef} = this.props;
        return <Polyline ref={polyRef} {...this.props}/>
    }
}

export default withLeaflet(TrayectoFlechas);