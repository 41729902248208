import React, { Component } from 'react'
import { bindActionCreators } from "redux"
import { withTranslation, Trans } from "react-i18next"
import { connect } from "react-redux"
import { Row, Col, Form } from "react-bootstrap"
import * as turf from '@turf/turf'

import ModalEditor from "./ModalEditor"
import { actions } from "../../actions/editor"
import { getBloqueSeleccionado } from "../../reducers/editor"
import { getProyecto } from "../../reducers/proyectos"
import { creaNuevoTrayecto } from "../../fetchactions/trayectos"
import { creaNuevaLinea } from "../../fetchactions/lineas"
import { getLineas } from "../../reducers/lineas"
import { getTrayectos } from "../../reducers/trayectos"
import { getPoligonos } from "../../reducers/poligonos"
import { creaNuevoPoligono } from "../../fetchactions/poligonos"

const tj = require('@mapbox/togeojson');
const JSZip = require('jszip');


class ModalSubirTrack extends Component {

    constructor(props) {
        super(props)
        this.refForm = React.createRef()

        this.onHide = this.onHide.bind(this)
        this.onClickAceptar = this.onClickAceptar.bind(this)
        this.onClickCancelar = this.onClickCancelar.bind(this)
        this.state = {
            simplificar: true
        }
    }

    componentDidMount() {
        const {resetFormasFicheroSubido} = this.props;
        resetFormasFicheroSubido();
    }

    hide() {
        const {setVisibleModalSubirTrack} = this.props;
        setVisibleModalSubirTrack(false);
    }

    onHide() {
        this.hide();
    }

    onClickCancelar() {
        this.hide();
    }

    isValidExtension (fichero) {
        const extensiones = ['gpx', 'kml', 'kmz', 'json', 'geojson'];
        const nameSplit = fichero.name.split('.')
        for(let i in extensiones) {
            if(extensiones[i] === nameSplit[nameSplit.length - 1]) return true;
        }
        return false;
    }

    onClickAceptar () {
        const {t} = this.props;
        let input = document.getElementById('input-track');
        if(input.files[0]) {
            if(this.isValidExtension(input.files[0])) {
                this.convertToGeojson(input.files[0]);
            } else {
                alert(t('introduce-fichero-valido'));
            }
        } else {
            alert(t('no-fichero-seleccionado'));
        }
    }

    convertToGeojson (blob) {
        let reader = new FileReader();
        reader.onload = e => {
            let fichero = e.target.result;
            const ext = blob.name.split('.')[blob.name.split('.').length - 1].toLowerCase()
            if (ext !== 'geojson' && ext !== 'json') {
                let geojson = {};
                if (ext === 'gpx') {
                    let base64 = fichero.split(';')[1].split(',')[1];
                    geojson = tj.gpx(new DOMParser().parseFromString(atob(base64), 'text/xml'));
                    this.subeForma(geojson);
                } else if (ext === 'kml') {
                    let base64 = fichero.split(';')[1].split(',')[1];
                    geojson = tj.kml(new DOMParser().parseFromString(atob(base64), 'text/xml'));
                    this.subeForma(geojson);
                } else {
                    let jszip = new JSZip();
                    jszip.loadAsync(blob).then((zip) => {
                        Object.keys(zip.files).forEach(file => {
                            jszip.file(file).async('string').then(content => {
                                geojson = tj.kml(new DOMParser().parseFromString(content, 'text/xml'));
                                this.subeForma(geojson);
                            })
                        })
                    })
                }
            } else {
                let base64 = fichero.split(';')[1].split(',')[1];
                let geojson = JSON.parse(atob(base64));
                this.subeForma(geojson)
            }
        };

        reader.readAsDataURL(blob);
    }

    subeForma(geojson) {
        const {bloqueSeleccionado} = this.props;
        switch (bloqueSeleccionado.tipo) {
            case 'tr':
                this.subeTrayectos(geojson);
                break;
            case 'ln':
                this.subeLinea(geojson);
                break;
            case 'pl':
                this.subePoligono(geojson);
                break;
            default:
                break;
        }
    }

    subeTrayectos(geojson) {
        const {
            creaNuevoTrayecto, bloqueSeleccionado, proyecto, addFormaFicheroSubido, setVisibleModalSeleccionarForma
        } = this.props
        let features = []
        for (let i in geojson.features) {
            if (geojson.features[i].geometry.type === 'LineString') {
                if (this.state.simplificar) {
                    features.push(turf.simplify(geojson.features[i], {
                        tolerance: this.getTolerance(geojson.features[i])
                    }))
                } else {
                    features.push(geojson.features[i])
                }
            }
        }
        let propsDefecto = bloqueSeleccionado.props_defecto
        if (features.length > 1) {
            for (let i in features){
                addFormaFicheroSubido(features[i])
            }
            this.hide()
            setVisibleModalSeleccionarForma(true)
            return;
        } else if (features.length === 1) {
            let options = {
                nombre: features[0].properties.name ? (features[0].properties.name.length > 50
                    ? features[0].properties.name.substring(0, 49) : features[0].properties.name)
                    : this.getNombreSiguienteElemento(bloqueSeleccionado),
                color: features[0].properties.stroke ? features[0].properties.stroke : propsDefecto.color,
                grosor: propsDefecto.grosor,
                bloque: bloqueSeleccionado.id,
                coordenadas: this.featureTo2d(features[0]).geometry,
                proyecto: proyecto.id
            }
            creaNuevoTrayecto(options, true)
        }
        this.hide()
    }

    subeLinea(geojson) {
        const {
            creaNuevaLinea, bloqueSeleccionado, proyecto, addFormaFicheroSubido, setVisibleModalSeleccionarForma
        } = this.props
        let features = []
        for(let i in geojson.features) {
            if (geojson.features[i].geometry.type === 'LineString') {
                if (this.state.simplificar) {
                    features.push(turf.simplify(geojson.features[i], {
                        tolerance: this.getTolerance(geojson.features[i])
                    }))
                } else {
                    features.push(geojson.features[i])
                }
            }
        }
        let propsDefecto = bloqueSeleccionado.props_defecto
        if (features.length > 1) {
            for (let i in features) {
                addFormaFicheroSubido(features[i])
            }
            this.hide()
            setVisibleModalSeleccionarForma(true)
            return
        } else if (features.length === 1) {
            creaNuevaLinea({
                nombre: features[0].properties.name ? (features[0].properties.name.length > 50
                    ? features[0].properties.name.substring(0, 49) : features[0].properties.name)
                    : this.getNombreSiguienteElemento(bloqueSeleccionado),
                color: bloqueSeleccionado.color_editable ?
                    (features[0].properties.stroke ? features[0].properties.stroke : propsDefecto.color) :
                    propsDefecto.color,
                grosor: propsDefecto.grosor ? propsDefecto.grosor : 3,
                bloque: bloqueSeleccionado.id,
                patron: bloqueSeleccionado.patron.id,
                opacidad: propsDefecto.opacidad ? propsDefecto.opacidad: 1,
                coordenadas: this.featureTo2d(features[0]).geometry,
                proyecto: proyecto.id
            }, true)
        }
        this.hide()
    }

    subePoligono(geojson) {
        const {
            creaNuevoPoligono, bloqueSeleccionado, proyecto, addFormaFicheroSubido, setVisibleModalSeleccionarForma
        } = this.props
        let features = []
        for (let i in geojson.features) {
            if (geojson.features[i].geometry.type === 'Polygon') {
                if (this.state.simplificar) {
                    features.push(turf.simplify(geojson.features[i], {
                        tolerance: this.getTolerance(geojson.features[i])
                    }))
                } else {
                    features.push(geojson.features[i])
                }
            }
        }
        let propsDefecto = bloqueSeleccionado.props_defecto
        if (features.length > 1) {
            for (let i in features) {
                addFormaFicheroSubido(features[i])
            }
            this.hide()
            setVisibleModalSeleccionarForma(true)
            return
        } else if (features.length === 1) {
            creaNuevoPoligono({
                nombre: features[0].properties.name ? (features[0].properties.name.length > 50
                    ? features[0].properties.name.substring(0, 49) : features[0].properties.name)
                    : this.getNombreSiguienteElemento(bloqueSeleccionado),
                color: bloqueSeleccionado.color_editable
                    ? (features[0].properties.fill ? features[0].properties.fill : propsDefecto.color)
                    : propsDefecto.color,
                grosor_linea: propsDefecto.grosor_linea ? propsDefecto.grosor_linea : 3,
                bloque: bloqueSeleccionado.id,
                patron: bloqueSeleccionado.patron.id,
                opacidad_fondo: propsDefecto.opacidad_fondo ? propsDefecto.opacidad_fondo: 1,
                coordenadas: this.featureTo2dPolygon(features[0]).geometry,
                proyecto: proyecto.id
            }, true)
        }
        this.hide()
    }

    getNombreSiguienteElemento(bloqueSeleccionado) {
        const {trayectos, lineas, poligonos} = this.props;
        let elementos = trayectos;
        if(bloqueSeleccionado.tipo === 'ln'){
            elementos = lineas;
        } else if(bloqueSeleccionado.tipo === 'pl') {
            elementos = poligonos
        }
        let nombre;
        let contador = 1;
        while (!nombre) {
            const cont = contador;
            if (elementos.filter(elemento => elemento.nombre === bloqueSeleccionado.nombre + cont).length === 0) {
                nombre = bloqueSeleccionado.nombre + cont;
            }
            contador++;
        }
        return nombre;
    }

    featureTo2d(feature){
        for(let i in feature.geometry.coordinates){
            if(feature.geometry.coordinates[i].length > 2)
                feature.geometry.coordinates[i] = feature.geometry.coordinates[i].slice(0,2);
        }
        return feature;
    }

    featureTo2dPolygon(feature) {
        for(let i in feature.geometry.coordinates){
            for(let j in feature.geometry.coordinates[i]) {
                if(feature.geometry.coordinates[i][j].length > 2)
                    feature.geometry.coordinates[i][j] = feature.geometry.coordinates[i][j].slice(0,2);
            }
        }
        return feature;
    }

    getTolerance(feature) {
        const longitud = feature.geometry.coordinates.length;
        if (longitud < 150) {
            return 0;
        } else if (longitud < 500) {
            return .000001;
        } else if (longitud < 3000) {
            return .000005;
        } else if (longitud < 4500) {
            return .00001
        } else if (longitud < 6000) {
            return .00005;
        } else if (longitud < 9000) {
            return .0001
        } else {
            return .0005;
        }
    }

    handleChangeNoSimplificar (e) {
        this.setState({
            simplificar: !e.target.checked
        })
    }

    render (props) {
        const {t, show} = this.props;
        return <ModalEditor show={show} onHide={this.onHide} footer onClickAceptar={this.onClickAceptar}
                            onClickCancelar={this.onClickCancelar} titulo={t('subir-trayecto-fichero')}>
            <Row>
                <Col>
                    <Form ref={this.refForm}>
                        <Form.Group controlId='input-track'>
                            <Form.Label>{t('seleccionar-fichero-trayecto')}</Form.Label>
                            <Form.Control type='file'
                                          accept='application/gpx+xml, application/vnd.google-earth.kml+xml,
                                          application/vnd.google-earth.kmz, application/vnd.geo+json, application/json,
                                          application/geo+json'/>
                            <Form.Text className='text-muted'>
                                <p>.gpx, .kml, .kmz, .geojson {t('mgs-simplificar-track')}</p>
                            </Form.Text>
                        </Form.Group>
                        <div className='form-check'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                checked={!this.state.simplificar}
                                onChange={this.handleChangeNoSimplificar.bind(this)}
                                id='check-no-simplificar-importar'
                            />
                            <label htmlFor='check-no-simplificar-importar'><Trans>No simplificar *</Trans></label>
                            <small className='form-text text-muted'>
                                <Trans>
                                    <p>
                                        *Si marcas esta opción no modificaremos el elemento importado del fichero,
                                        pero puede provocar un mal rendimiento.
                                    </p>
                                </Trans>
                            </small>
                        </div>
                    </Form>
                </Col>
            </Row>
        </ModalEditor>
    }
}

const mapStateToProps = state => ({
    bloqueSeleccionado: getBloqueSeleccionado(state),
    proyecto: getProyecto(state),
    trayectos: getTrayectos(state),
    lineas: getLineas(state),
    poligonos: getPoligonos(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalSubirTrack: actions.setVisibleModalSubirTrack,
    creaNuevoTrayecto: creaNuevoTrayecto,
    creaNuevaLinea: creaNuevaLinea,
    creaNuevoPoligono: creaNuevoPoligono,
    resetFormasFicheroSubido: actions.resetFormasFicheroSubido,
    addFormaFicheroSubido: actions.addFormaFicheroSubido,
    setVisibleModalSeleccionarForma: actions.setVisibleModalSeleccionarForma
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalSubirTrack));
