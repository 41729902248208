/* global fetch */
import { actions } from "../actions/elementoVoluntarios"
import { actions as actionsComun } from '../actions/comun'
import { actions as actionsError } from '../actions/error'
import { API_BASE_URL } from "../constants/api";
import getCsrfToken from "../lib/csrf";

export function createElementoVoluntarios (opciones) {
    return dispatch => {
        dispatch(actions.createElementoVoluntariosBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'voluntarioselemento/')
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createElementoVoluntariosError(data))
                            dispatch(actionsError.hasError(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createElementoVoluntariosSuccess(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                }
            })
            .catch(err => {
                dispatch(actions.createElementoVoluntariosError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function deleteElementoVoluntarios (elementoVoluntarios) {
    return dispatch => {
        dispatch(actions.deleteElementoVoluntariosBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'voluntarioselemento/')
        Object.keys(elementoVoluntarios).forEach(
            key => url.searchParams.append(key, elementoVoluntarios[key])
        )
        return fetch(url, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw res.error
                } else if (res && res.length) {
                    const urlDelete = new URL(API_BASE_URL + 'voluntarioselemento/' + res[0].id + '/')
                    return fetch(urlDelete, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'X-CSRFToken': getCsrfToken()
                        }
                    })
                        .then(res => {
                            if (res.status !== 204) {
                                return res.json()
                                    .then(data => {
                                        dispatch(actionsError.hasError(data))
                                        dispatch(actionsComun.restaPeticion())
                                    })
                            } else {
                                dispatch(actions.deleteElementoVoluntariosSuccess(elementoVoluntarios))
                                dispatch(actionsComun.restaPeticion())
                            }
                        })
                        .catch(err => {
                            dispatch(actions.deleteElementoVoluntariosError(err))
                            dispatch(actionsError.hasError(err))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    throw new Error('Not found')
                }
            })
            .catch(err => {
                dispatch(actions.deleteElementoVoluntariosError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}
