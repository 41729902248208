export const ACTION_TYPES = {
    HAS_ERROR: 'HAS_ERROR',
    CLEAR_ERROR: 'CLEAR_ERROR'
};

function hasError(error) {
    return {
        type: ACTION_TYPES.HAS_ERROR,
        error
    }
}

function clearError () {
    return {
        type: ACTION_TYPES.CLEAR_ERROR,
        error: null
    }
}

export const actions = {
    hasError: hasError,
    clearError: clearError
}

export default actions
