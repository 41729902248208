import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/deportes";

export function deportesReducer(state = initialState.deportes, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_DEPORTES_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                list: [],
                error: false
            };
        case ACTION_TYPES.FETCH_DEPORTES_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.FETCH_DEPORTES_CORRECTO:
            return {
                ...state,
                pendiente: false,
                list: action.deportes
            };
        default:
            return {
                ...state
            };
    }
}


export const getDeportes = state => state.deportes.list;
export const getDeportesError = state => state.deportes.error;
export const getDeportesPendiente = state => state.deportes.pendiente;
