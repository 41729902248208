import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation, Trans } from "react-i18next"

import ModalEditor from "./ModalEditor"
import { actions } from "../../actions/editor"
import { actions as actionsVoluntarios } from '../../actions/voluntarios'
import FilaOtrosMapas from "../../components/personal/FilaOtrosMapas";
import { updateVoluntarios } from "../../fetchactions/voluntarios";

class ModalVoluntarios extends Component {
    handleClickAceptar () {
        this.hide()
    }

    handleClickCancelar () {
        this.hide()
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalVoluntarios(false))
    }

    handleClickNuevoVoluntarios () {
        const { dispatch } = this.props
        this.hide()
        dispatch(actions.setVisibleNuevoVoluntarios(true))
    }

    handleClickEliminar (voluntarios) {
        const { dispatch } = this.props
        dispatch(actions.setVoluntariosEliminar(voluntarios))
        this.hide()
        dispatch(actions.setVisibleModalEliminarVoluntarios(true))
    }

    handleClickEditar (voluntarios) {
        const { dispatch } = this.props
        dispatch(actionsVoluntarios.setVoluntariosEditando(voluntarios))
        this.hide()
        dispatch(actions.setVisibleModalEditarVoluntarios(true))
    }

    handleReordenar (prevOrden, nextOrden) {
        const { voluntarios, dispatch } = this.props
        const ordenes = voluntarios.map(v => v.orden)
        const prevIndex = ordenes.indexOf(prevOrden)
        const nextIndex = ordenes.indexOf(nextOrden)
        const voluntariosC = voluntarios.slice()
        if (nextIndex >= voluntariosC.length) {
            let k = nextIndex - voluntariosC.length + 1
            while (k--) {
                voluntariosC.push(undefined)
            }
        }
        voluntariosC.splice(nextIndex, 0, voluntariosC.splice(prevIndex, 1)[0])
        for (let i in voluntariosC) {
            if (voluntariosC[i].orden !== ordenes[i]) {
                dispatch(updateVoluntarios(voluntariosC[i].id, {
                    nombre: voluntariosC[i].nombre,
                    orden: ordenes[i],
                    capa_fondo: voluntariosC[i].capa_fondo
                }))
            }
        }
    }

    render() {
        const { t, voluntarios } = this.props

        return (
            <ModalEditor
                show
                footer
                onClickAceptar={this.handleClickAceptar.bind(this)}
                onClickCancelar={this.handleClickCancelar.bind(this)}
                onHide={this.handleClickCancelar.bind(this)}
                titulo={t('Mapas de staff')}
            >
                <div className='row'>
                    <div className='col-12'>
                        <ul className='list-group list-group-flush'>
                            {voluntarios.sort((a,b) => a.orden - b.orden).map(v =>
                                <FilaOtrosMapas
                                    key={v.id}
                                    mapa={v}
                                    handleClickEditar={this.handleClickEditar.bind(this)}
                                    handleClickEliminar={this.handleClickEliminar.bind(this)}
                                    handleReordena={this.handleReordenar.bind(this)}
                                />
                            )}
                        </ul>
                    </div>
                </div>
                {voluntarios.filter(m => m.es_pagado).length > 0 &&
                    <div className='row'>
                        <div className='col-12'>
                            <p className='mt-3 text-right blockquote-footer'>
                                <Trans>* No se puden eliminar los mapas con una subscripción activa</Trans>
                            </p>
                        </div>
                    </div>}
                <div className='row'>
                    <div className='col-12'>
                        <button
                            className='btn btn-primary float-right mt-2'
                            onClick={this.handleClickNuevoVoluntarios.bind(this)}
                        >
                            <i className='fas fa-plus-circle mr-1' />
                            <Trans>Nuevo mapa de staff</Trans>
                        </button>
                    </div>
                </div>
            </ModalEditor>
        )
    }
}

const mapStateToProps = state => ({
    voluntarios: state.voluntarios.data
})

export default withTranslation()(connect(mapStateToProps)(ModalVoluntarios))
