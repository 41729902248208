import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Form, Row, Spinner} from "react-bootstrap";
import * as turf from '@turf/turf';
import * as L from 'leaflet';

import './ModalNuevoRecorrido.css';

import {getRecorridoCreando, getRecorridos} from "../../reducers/recorridos";
import ModalEditor from "./ModalEditor";
import {getTrayectos} from "../../reducers/trayectos";
import {actions} from "../../actions/editor";
import {actions as actionsRecorridos} from "../../actions/recorridos";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TabSecuenciaRecorrido from "./TabSecuenciaRecorrido";
import TabAvanzadoRecorrido from "./TabAvanzadoRecorrido";
import {getMapaRecorridos} from "../../reducers/mapas";
import {creaNuevoRecorrido} from "../../fetchactions/recorridos";
import TabPerfilRecorrido from "./TabPerfilRecorrido";
import TabPdisRecorrido from "./TabPdisRecorrido";
import {
    getAnimacionCrearRecorrido,
    getIdUltimoRecorridoCreado,
    getPestanaActivaRecorrido,
    getPksPorAnadir,
    getRecorridoCompletoCreado
} from "../../reducers/editor";
import {getCapas} from "../../reducers/capas";
import {getProyecto} from "../../reducers/proyectos";
import {creaNuevoPunto} from "../../fetchactions/puntos";
import TabVistaInicialRecorrido from "./TabVistaInicialRecorrido";


export class ModalNuevoRecorrido extends Component {

    constructor(props) {
        super(props);
        this.refForm = React.createRef();
        this.refUnidadesDistancia = React.createRef();
        this.crearSalidaMeta = false;
        this.recorridoAntes = null;
        this.recorridoPreEdicion = null;
        this.pksCreados = false;
        this.iniFinSectoresCreados = false;

        this.onHide = this.onHide.bind(this);
        this.onClickAceptar = this.onClickAceptar.bind(this);
        this.distanciaEnUnidad = this.distanciaEnUnidad.bind(this);
        this.onClickAnterior = this.onClickAnterior.bind(this);
        this.onClickSiguiente = this.onClickSiguiente.bind(this);
        this.onSelectTab = this.onSelectTab.bind(this);
    }

    componentDidMount() {
        const {t, setSectorPorDefecto, resetPksPorAnadir, setPestanaActivaRecorrido,
            setAnimacionCrearRecorrido} = this.props;
        setSectorPorDefecto(t('sector') + ' 1');
        resetPksPorAnadir();
        this.recorridoAntes = null;
        setPestanaActivaRecorrido(1);
        setAnimacionCrearRecorrido(false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {recorridoCompletoCreado} = this.props;
        if ((this.recorridoAntes && !this.recorridoAntes.id) && recorridoCompletoCreado && !prevProps.recorridoCompletoCreado) {
            if (this.crearSalidaMeta) this.creaSalidaMeta();
            if (this.recorridoAntes.sectores.length > 1) this.creaInicioFinSectores();
            this.creaPksAuto();
            this.recorridoAntes = null;
            this.hide();
        }
    }

    onHide() {
        this.hide();
    }

    hide() {
        const {t, setVisibleModalNuevoRecorrido, setSectorPorDefecto} = this.props;
        setSectorPorDefecto(t('sector') + ' 1');
        setVisibleModalNuevoRecorrido(false);
    }

    onClickAceptar() {
        const {recorridoCreando, mapaRecorridos, creaNuevoRecorrido, pksPorAnadir,
            setAnimacionCrearRecorrido} = this.props;
        if (this.valida()) {
            let opciones = {};
            const formData = new FormData(this.refForm.current);
            formData.forEach((value, key) => {
                opciones[key] = value;
            });
            if (!opciones.visible) {
                opciones.visible = false
            } else {
                opciones.visible = true
            }
            opciones.mapa = mapaRecorridos.id;

            if(recorridoCreando.vista_inicial) opciones.vista_inicial = recorridoCreando.vista_inicial;
            else opciones.vista_inicial = undefined;

            this.crearSalidaMeta = document.getElementById('crear-salida-meta').checked;

            this.recorridoAntes = JSON.parse(JSON.stringify(recorridoCreando));
            creaNuevoRecorrido(opciones, recorridoCreando.sectores, pksPorAnadir);
            setAnimacionCrearRecorrido(true);
        } else {
            return false;
        }
    }

    creaInicioFinSectores(servicios) {
        const {capas} = this.props;
        const bloques = [];
        capas.map(c => c.bloques).map(b => b.map(bl => bloques.push(bl)));
        for (let i = 0; i < this.recorridoAntes.sectores.length; i++) {
            if (this.recorridoAntes.sectores[i].generar_inicio_fin) {
                const trayectoIni = this.getTrayectoById(this.recorridoAntes.sectores[i].esquemas[0].trayecto.id);
                const trayectoFin = this.getTrayectoById(this.recorridoAntes.sectores[i].esquemas[this.recorridoAntes.sectores[i].esquemas.length - 1].trayecto.id);
                const coordsIni = trayectoIni.coordenadas.coordinates[0];
                const coordsFin = trayectoFin.coordenadas.coordinates[trayectoFin.coordenadas.coordinates.length - 1];
                const dist = turf.distance(coordsIni, coordsFin, {units: 'meters'});
                const idSector = this.recorridoAntes.sectores[i].id
                if ((!this.crearSalidaMeta || i !== 0) && dist > 1) {
                    this.crearIniSector(i, coordsIni, bloques, servicios && servicios[idSector] ?
                        servicios[idSector].inicio : []);
                }
                if ((!this.crearSalidaMeta || i !== this.recorridoAntes.sectores.length - 1) && dist > 1) {
                    this.crearFinSector(i, coordsFin, bloques, servicios && servicios[idSector] ?
                        servicios[idSector].fin : []);
                }
                if ((!this.crearSalidaMeta || (i !== 0 && i !== this.recorridoAntes.sectores.length - 1)) && dist < 1) {
                    this.crearIniFinSector(i, coordsFin, bloques, servicios && servicios[idSector] ?
                        (servicios[idSector].inicioFin.length > 0 ?
                            servicios[idSector].inicioFin : servicios[idSector].inicio.concat(servicios[idSector].fin))
                        : []);
                }
            }

        }
    }

    crearIniSector(indexSector, coords, bloques, servicios) {
        const {t, proyecto, creaNuevoPunto} = this.props;
        const recorrido = this.getUltimoRecorridoCreado();
        const bloque = bloques.filter(b => b.tipo_especial === 'is')[0];
        creaNuevoPunto({
            nombre: t('ini-sector') + ' ' + recorrido.sectores[indexSector].nombre,
            color: bloque.props_defecto.color ? bloque.props_defecto.color : '#ffff00',
            color_icono: bloque.props_defecto.color_icono ? bloque.props_defecto.color_icono : '#000000',
            bloque: bloque.id,
            icono: bloque.props_defecto.icono_id ? bloque.props_defecto.icono_id : null,
            proyecto: proyecto.id,
            trayecto_ancla: recorrido.sectores[indexSector].esquemas[0].trayecto.id,
            coordenadas: L.marker([coords[1], coords[0]]).toGeoJSON().geometry,
            sectores: [recorrido.sectores[indexSector].id],
            servicios: servicios
        }, null, recorrido.sectores[indexSector]);
    }

    crearFinSector(indexSector, coords, bloques, servicios) {
        const {t, proyecto, creaNuevoPunto} = this.props;
        const recorrido = this.getUltimoRecorridoCreado();
        const bloque = bloques.filter(b => b.tipo_especial === 'fs')[0];
        creaNuevoPunto({
            nombre: t('fin-sector') + ' ' + recorrido.sectores[indexSector].nombre,
            color: bloque.props_defecto.color ? bloque.props_defecto.color : '#ffff00',
            color_icono: bloque.props_defecto.color_icono ? bloque.props_defecto.color_icono : '#000000',
            bloque: bloque.id,
            icono: bloque.props_defecto.icono_id ? bloque.props_defecto.icono_id : null,
            proyecto: proyecto.id,
            trayecto_ancla: recorrido.sectores[indexSector].esquemas[recorrido.sectores[indexSector].esquemas.length - 1].trayecto.id,
            coordenadas: L.marker([coords[1], coords[0]]).toGeoJSON().geometry,
            sectores: [recorrido.sectores[indexSector].id],
            servicios: servicios
        }, null, recorrido.sectores[indexSector]);
    }

    crearIniFinSector(indexSector, coords, bloques, servicios) {
        const {t, proyecto, creaNuevoPunto} = this.props;
        const recorrido = this.getUltimoRecorridoCreado();
        const bloque = bloques.filter(b => b.tipo_especial === 'sc')[0];
        creaNuevoPunto({
            nombre: t('ini-fin-sector') + ' ' + recorrido.sectores[indexSector].nombre,
            color: bloque.props_defecto.color ? bloque.props_defecto.color : '#ffff00',
            color_icono: bloque.props_defecto.color_icono ? bloque.props_defecto.color_icono : '#000000',
            bloque: bloque.id,
            icono: bloque.props_defecto.icono_id ? bloque.props_defecto.icono_id : null,
            proyecto: proyecto.id,
            trayecto_ancla: recorrido.sectores[indexSector].esquemas[0].trayecto.id,
            coordenadas: L.marker([coords[1], coords[0]]).toGeoJSON().geometry,
            sectores: [recorrido.sectores[indexSector].id],
            servicios: servicios
        }, null, recorrido.sectores[indexSector]);
    }

    creaSalidaMeta(servicios) {
        const {t, capas, proyecto, creaNuevoPunto} = this.props;
        const recorrido = this.getUltimoRecorridoCreado();
        const bloques = [];
        capas.map(c => c.bloques).map(b => b.map(bl => bloques.push(bl)));
        const trayectoIni = this.getTrayectoById(recorrido.sectores[0].esquemas[0].trayecto.id);
        const trayectoFin = this.getTrayectoById(recorrido.sectores[recorrido.sectores.length - 1].esquemas[recorrido.sectores[recorrido.sectores.length - 1].esquemas.length - 1].trayecto.id);
        const coordsIni = trayectoIni.coordenadas.coordinates[0];
        const coordsFin = trayectoFin.coordenadas.coordinates[trayectoFin.coordenadas.coordinates.length - 1];
        const dist = turf.distance(coordsIni, coordsFin, {units: 'meters'});

        if (dist < 1) {
            const bloqueSalidaMeta = bloques.filter(b => b.tipo_especial === 'sm')[0];
            creaNuevoPunto({
                nombre: t('salida-meta') + ' ' + recorrido.nombre,
                color: bloqueSalidaMeta.props_defecto.color ? bloqueSalidaMeta.props_defecto.color : '#ffff00',
                color_icono: bloqueSalidaMeta.props_defecto.color_icono ?
                    bloqueSalidaMeta.props_defecto.color_icono : '#000000',
                bloque: bloqueSalidaMeta.id,
                icono: bloqueSalidaMeta.props_defecto.icono_id ? bloqueSalidaMeta.props_defecto.icono_id : null,
                proyecto: proyecto.id,
                trayecto_ancla: recorrido.sectores[0].esquemas[0].trayecto.id,
                coordenadas: L.marker([coordsFin[1], coordsFin[0]]).toGeoJSON().geometry,
                sectores: [recorrido.sectores[0].id],
                servicios: servicios ? (servicios.salidaMeta.length > 0 ?
                    servicios.salidaMeta : servicios.salida.concat(servicios.meta)) : []
            }, null, recorrido.sectores[0]);
        } else {
            const bloqueMeta = bloques.filter(b => b.tipo_especial === 'me')[0];
            const bloqueSalida = bloques.filter(b => b.tipo_especial === 'sa')[0];
            creaNuevoPunto({
                nombre: t('salida') + ' ' + recorrido.nombre,
                color: bloqueSalida.props_defecto.color ? bloqueSalida.props_defecto.color : '#ffff00',
                color_icono: bloqueSalida.props_defecto.color_icono ?
                    bloqueSalida.props_defecto.color_icono : '#000000',
                bloque: bloqueSalida.id,
                icono: bloqueSalida.props_defecto.icono_id ? bloqueSalida.props_defecto.icono_id : null,
                proyecto: proyecto.id,
                trayecto_ancla: recorrido.sectores[0].esquemas[0].trayecto.id,
                coordenadas: L.marker([coordsIni[1], coordsIni[0]]).toGeoJSON().geometry,
                sectores: [recorrido.sectores[0].id],
                servicios: servicios ? servicios.salida : []
            }, null, recorrido.sectores[0]);
            creaNuevoPunto({
                nombre: t('meta') + ' ' + recorrido.nombre,
                color: bloqueMeta.props_defecto.color ? bloqueMeta.props_defecto.color : '#ffff00',
                color_icono: bloqueMeta.props_defecto.color_icono ? bloqueMeta.props_defecto.color_icono : '#000000',
                bloque: bloqueMeta.id,
                icono: bloqueMeta.props_defecto.icono_id ? bloqueMeta.props_defecto.icono_id : null,
                proyecto: proyecto.id,
                trayecto_ancla: recorrido.sectores[recorrido.sectores.length - 1].esquemas[recorrido.sectores[recorrido.sectores.length - 1].esquemas.length - 1].trayecto.id,
                coordenadas: L.marker([coordsFin[1], coordsFin[0]]).toGeoJSON().geometry,
                sectores: [recorrido.sectores[recorrido.sectores.length - 1].id],
                servicios: servicios ? servicios.meta : []
            }, null, recorrido.sectores[recorrido.sectores.length - 1]);
        }
    }

    creaPksAuto() {
        const recorrido = this.getUltimoRecorridoCreado();
        recorrido.sectores.sort((a,b) => a.orden - b.orden);
        this.recorridoAntes.sectores.sort((a, b) => a.orden - b.orden);
        for (let i in recorrido.sectores) {
            const sectorAntes = this.recorridoAntes && this.recorridoAntes.id ?
                this.recorridoAntes.sectores.filter(s => s.id === recorrido.sectores[i].id)[0] : null;
            if (recorrido.sectores[i].puntos_km_auto && (!sectorAntes || !sectorAntes.puntos_km_auto)) {
                this.creaPksSector(i, recorrido.sectores[i]);
            }
        }
    }

    creaPksSector(indexSector, sector) {
        const { capas, proyecto, creaNuevoPunto } = this.props;
        const recorrido = this.getUltimoRecorridoCreado();
        const bloques = [];
        capas.map(c => c.bloques).map(b => b.map(bl => bloques.push(bl)));
        const bloquePk = bloques.filter(b => b.es_pk)[0];
        const distOficialSector = this.getDistanciaOficialSector(this.recorridoAntes.sectores[indexSector], true);
        const cadenciaMetros = this.recorridoAntes.sectores[indexSector].cadencia_km ?
            this.toMetros(this.recorridoAntes.sectores[indexSector].cadencia_km, this.recorridoAntes) :
            this.toMetros(this.getCadenciaDefecto(), this.recorridoAntes);
        let contador = 1;
        let siguienteDistancia = cadenciaMetros;
        while (siguienteDistancia < distOficialSector) {
            let esquema = this.recorridoAntes.sectores[indexSector].esquemas[0];
            let distAcum = 0;
            let i = 1;
            while (siguienteDistancia > distAcum + esquema.trayecto.distancia_metros * esquema.repeticiones) {
                distAcum += esquema.trayecto.distancia_metros * esquema.repeticiones;
                esquema = this.recorridoAntes.sectores[indexSector].esquemas[i];
                i++;
            }
            const trayecto = this.getTrayectoById(esquema.trayecto.id);
            const distIni = (siguienteDistancia - distAcum) % esquema.trayecto.distancia_metros;
            const distIniReal = distIni * turf.length(trayecto.coordenadas, {units: 'meters'}) / esquema.trayecto.distancia_metros;
            const punto = turf.along(turf.lineString(trayecto.coordenadas.coordinates), distIniReal, {
                units: 'meters'
            });
            const sigDistanciaUnidad = Math.round(this.metersToUnit(siguienteDistancia, this.recorridoAntes.unidad_distancia));
            let distanciaMostrar = sigDistanciaUnidad;
            if (this.recorridoAntes.unidad_distancia === 'm')
                distanciaMostrar = Math.round(turf.convertLength(siguienteDistancia, 'meters', 'kilometers'));
            else if (this.recorridoAntes.unidad_distancia === 'ft')
                distanciaMostrar = Math.round(turf.convertLength(sigDistanciaUnidad, 'feet', 'miles'));
            const multiplo5 = distanciaMostrar % 5 === 0;
            const cadenciaNombre = this.recorridoAntes.sectores[indexSector].cadencia_km ?
                this.recorridoAntes.sectores[indexSector].cadencia_km :
                this.getCadenciaDefecto();
            creaNuevoPunto({
                nombre: cadenciaNombre * contador + this.recorridoAntes.unidad_distancia,
                color: multiplo5 ? bloquePk.props_defecto.color_icono : bloquePk.props_defecto.color,
                color_icono: multiplo5 ? bloquePk.props_defecto.color : bloquePk.props_defecto.color_icono,
                bloque: bloquePk.id,
                icono: bloquePk.props_defecto.icono_id ? bloquePk.props_defecto.icono_id : null,
                proyecto: proyecto.id,
                trayecto_ancla: trayecto.id,
                coordenadas: punto.geometry,
                semiautomatico: true,
                es_pk: true,
                nombre_agrupacion: recorrido.nombre +
                    (this.recorridoAntes.sectores.length > 1 ?
                        ' - ' + this.recorridoAntes.sectores[indexSector].nombre : '') +
                    ' pks'
            }, null, sector, sigDistanciaUnidad);
            siguienteDistancia += cadenciaMetros;
            contador++
        }
    }

    getCadenciaDefecto() {
        const {recorridoCreando} = this.props;
        switch(recorridoCreando.unidad_distancia) {
            case 'm':
                return 1000;
            case'km':
            case 'mi':
                return 1;
            case 'ft':
                return 500;
            default:
                return 1000;
        }
    }

    toMetros(distancia, recorrido) {
        const {recorridoCreando} = this.props;
        switch (recorrido ? recorrido.unidad_distancia : recorridoCreando.unidad_distancia) {
            case 'km':
                return turf.convertLength(distancia, 'kilometers', 'meters');
            case 'ft':
                return turf.convertLength(distancia, 'feet', 'meters');
            case 'mi':
                return turf.convertLength(distancia, 'miles', 'meters');
            default:
                return distancia;
        }
    }

    getDistanciaOficialSector(sector, noUnidades) {
        let distancia = 0;
        sector.esquemas.forEach(esquema => {
            const trayecto = this.getTrayectoById(esquema.trayecto.id);
            if (trayecto.distancia_homologada) {
                let distTra = 0;
                switch (trayecto.unidad_distancia) {
                    case 'km':
                        distTra = turf.convertLength(trayecto.distancia_homologada, 'kilometers', 'meters');
                        break;
                    case 'ft':
                        distTra = turf.convertLength(trayecto.distancia_homologada, 'feet', 'meters');
                        break;
                    case 'mi':
                        distTra = turf.convertLength(trayecto.distancia_homologada, 'kilometers', 'meters');
                        break;
                    default:
                        distTra = trayecto.distancia_homologada;
                }
                distancia += distTra * esquema.repeticiones;
            } else {
                distancia += turf.length(trayecto.coordenadas, {units: 'meters'}) * esquema.repeticiones;
            }
        });
        if (noUnidades)
            return distancia;
        else
            return this.distanciaEnUnidad(distancia, this.refUnidadesDistancia.current ?
                this.refUnidadesDistancia.current.value : 'm');
    }

    getDistanciaRealSector(sector, noUnidades) {
        let distancia = 0;
        sector.esquemas.forEach(esquema => {
            const trayecto = this.getTrayectoById(esquema.trayecto.id)
            if(trayecto)
                distancia += turf.length(trayecto.coordenadas, {units: 'meters'}) * esquema.repeticiones;
        });
        if (noUnidades)
            return distancia;
        else
            return this.distanciaEnUnidad(distancia, this.refUnidadesDistancia.current ?
                this.refUnidadesDistancia.current.value : 'm');
    }

    metersToUnit(distancia, unidad) {
        switch (unidad) {
            case 'km':
                return turf.convertLength(distancia, 'meters', 'kilometers')
            case 'ft':
                return turf.convertLength(distancia, 'meters', 'feet')
            case 'mi':
                return turf.convertLength(distancia, 'meters', 'miles')
            default:
                return distancia;
        }
    }

    distanciaEnUnidad(distancia, unidad) {
        switch (unidad) {
            case 'km':
                return turf.convertLength(distancia, 'meters', 'kilometers').toFixed(3) + ' km';
            case 'ft':
                return turf.convertLength(distancia, 'meters', 'feet').toFixed(2) + ' ft';
            case 'mi':
                return turf.convertLength(distancia, 'meters', 'miles').toFixed(2) + ' mi';
            default:
                return distancia.toFixed(2) + ' m';
        }
    }

    getTrayectoById(idTrayecto) {
        const {trayectos} = this.props;
        return trayectos.filter(t => t.id === idTrayecto)[0]
    }

    getUltimoRecorridoCreado() {
        const {recorridos, idUltimoRecorridoCreado} = this.props;
        return recorridos.filter(r => r.id === idUltimoRecorridoCreado)[0]
    }

    valida() {
        const {t, recorridoCreando} = this.props;
        const nombre = document.getElementsByName('nombre')[0];
        const deporte = document.getElementsByName('deporte')[0];

        if (nombre.value.length <= 0) {
            alert(t('msg-no-nombre'));
            return false;
        }
        if (isNaN(deporte.value)) {
            alert(t('msg-no-deporte'));
            return false;
        }
        for (let i in recorridoCreando.sectores) {
            if (recorridoCreando.sectores[i].esquemas <= 0) {
                alert(t('msg-no-trayectos-sector'));
                return false
            }
        }
        return true;
    }

    onClickAnterior() {
        const {pestanaActivaRecorrido, setPestanaActivaRecorrido} = this.props;
        setPestanaActivaRecorrido(pestanaActivaRecorrido - 1);
    }

    onClickSiguiente() {
        const {pestanaActivaRecorrido, setPestanaActivaRecorrido} = this.props;
        if(pestanaActivaRecorrido === 1 && this.valida()) {
            setPestanaActivaRecorrido(pestanaActivaRecorrido + 1);
        } else if(pestanaActivaRecorrido !== 1){
            setPestanaActivaRecorrido(pestanaActivaRecorrido + 1);
        }
    }

    onSelectTab(e) {
        const {setPestanaActivaRecorrido} = this.props;
        setPestanaActivaRecorrido(parseInt(e));
    }

    onClickDescargarTrack() {
        const { recorridoCreando, proyecto, trayectos } = this.props;
        let lineaTotal;
        let puntos = [];
        recorridoCreando.sectores.forEach(sec => {
            sec.esquemas.forEach(esq => {
                const trayecto = esq.trayecto.coordenadas
                    ? esq.trayecto : trayectos.filter(t=> t.id === esq.trayecto.id)[0];
                if (trayecto) {
                    const coords = trayecto.coordenadas_interpoladas
                        ? trayecto.coordenadas_interpoladas : trayecto.coordenadas;
                    if (!lineaTotal) {
                        lineaTotal = turf.lineString(coords.coordinates, { name: recorridoCreando.nombre });
                        for (let i = 1; i < esq.repeticiones; i++) {
                            lineaTotal.geometry.coordinates =  lineaTotal.geometry.coordinates.concat(coords.coordinates);
                        }
                    } else {
                        for (let i = 0; i < esq.repeticiones; i++) {
                            lineaTotal.geometry.coordinates =  lineaTotal.geometry.coordinates.concat(coords.coordinates);
                        }
                    }
                }
            })
            if (sec.pdis_recorrido) {
                puntos = puntos.concat(
                    sec.pdis_recorrido.filter(pdi => pdi.coordenadas)
                        .map(pdi => turf.point(pdi.coordenadas.coordinates, { name: pdi.nombre }))
                )
            }
            if (sec.puntos_km) {
                puntos = puntos.concat(
                    sec.puntos_km.filter(pk => pk.punto && pk.punto.coordenadas)
                        .map(pk => turf.point(pk.punto.coordenadas.coordinates, {name: pk.punto.nombre}))
                );
            }
        })
        const features = turf.featureCollection([lineaTotal, ...puntos], {
            name: proyecto.nombre + ' - ' + recorridoCreando.nombre
        });
        const togpx = require('togpx')
        const blob = new Blob([togpx(features)], { type: 'application/gpx+xml' })

        const a = document.createElement('a');
        a.download = (proyecto.nombre + '-' + recorridoCreando.nombre + '.gpx').replace(/\s/g, '_');
        a.href = URL.createObjectURL(blob);
        a.dataset.downloadurl = ['application/gpx+xml', a.download, a.href].join(':');
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setTimeout(() => URL.revokeObjectURL(a.href), 1500);
    }

    hayPuntosRecorrido () {
        const { puntos } = this.props
        return puntos.filter(p => p.bloque.pdi_sobre_trayecto && !p.bloque.es_pk && !p.automatico).length > 0
    }

    render() {
        const {
            t, show, trayectos, recorridoCreando, editar, pestanaActivaRecorrido, animacionCrearRecorrido
        } = this.props;
        if (animacionCrearRecorrido) {
            return <ModalEditor show={show}
                                titulo={
                                    !editar ?
                                        t('modal-nuevo-recorrido') :
                                        t('modal-editar-recorrido') + ' ' + recorridoCreando.nombre}
            >
                <Row>
                    <Col className='col-spinner'>
                        <div className='spinner-respuesta-recorrido'>
                            <Spinner className='spinner-respuesta-servidor' animation="border" role="status">
                                <span className="sr-only">Cargando...</span>
                            </Spinner>
                        </div>
                    </Col>
                </Row>
            </ModalEditor>
        } else if (trayectos && trayectos.length > 0) {
            return <ModalEditor show={show} onHide={this.onHide} footer onClickAceptar={this.onClickAceptar}
                                onClickCancelar={this.onHide} backdrop={editar ? true : 'static'}
                                titulo={
                                    !editar ?
                                        t('modal-nuevo-recorrido') :
                                        t('modal-editar-recorrido') + ' ' + recorridoCreando.nombre}

                                nuevoRecorrido={!editar}
                                onClickSiguiente={this.onClickSiguiente}
                                onClickAnterior={this.onClickAnterior}
                                pestanaActivaRecorrido={pestanaActivaRecorrido}
                                onClickDescargarTrack={this.onClickDescargarTrack.bind(this)}
            >
                <Form ref={this.refForm}>
                    <Row>
                        <Col sm={10}>
                            <Form.Group as={Row} controlId='nombre-nuevo-recorrido'>
                                <Form.Label column sm={3} md={2}>{t('nombre') + ':'}</Form.Label>
                                <Col sm={9} md={10}>
                                    <Form.Control name='nombre' placeholder={t('introducir-nombre-recorrido')}
                                                defaultValue={recorridoCreando.nombre}/>
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            <Form.Row>
                                <Form.Check type='checkbox' id='publicacion'>
                                    <Form.Check.Input name='visible' type='checkbox'
                                                      defaultChecked={recorridoCreando.visible} />
                                    <Form.Check.Label>{t('visible')}</Form.Check.Label>
                                </Form.Check>
                            </Form.Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Tabs activeKey={pestanaActivaRecorrido} onSelect={editar ? this.onSelectTab : null}>
                                <Tab eventKey={1} title={t('secuencia')}
                                     disabled={!editar && pestanaActivaRecorrido !== 1}>
                                    <TabSecuenciaRecorrido refUnidadesDistancia={this.refUnidadesDistancia}
                                                           modalRecorridos={this}/>
                                </Tab>
                                <Tab eventKey={2} title={t('avanzado')}
                                     disabled={!editar && pestanaActivaRecorrido !== 2}>
                                    <TabAvanzadoRecorrido refUnidadesDistancia={this.refUnidadesDistancia}/>
                                </Tab>
                                <Tab eventKey={3} title={t('puntos-km')}
                                     disabled={!editar && pestanaActivaRecorrido !== 3}>
                                    <TabPdisRecorrido editar={editar} puntosKm={true} show={show}/>
                                </Tab>
                                {this.hayPuntosRecorrido() &&
                                    <Tab eventKey={4} title={t('puntos-recorrido')}
                                         disabled={!editar && pestanaActivaRecorrido !== 4}>
                                        {pestanaActivaRecorrido === 4 &&
                                        <TabPdisRecorrido editar={editar} puntosKm={false} show={show}/>
                                        }
                                    </Tab>}
                                <Tab eventKey={5} title={t('perfil')}
                                     disabled={!editar && pestanaActivaRecorrido !== 5}>
                                    {pestanaActivaRecorrido === 5 &&
                                        <TabPerfilRecorrido/>
                                    }
                                </Tab>
                                <Tab eventKey={6} title={t('Vista inicial')}
                                     disabled={!editar && pestanaActivaRecorrido !== 6}>
                                    {pestanaActivaRecorrido === 6 &&
                                        <TabVistaInicialRecorrido
                                            pestanaActiva={parseInt(pestanaActivaRecorrido) === 6}/>
                                    }
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Form>
            </ModalEditor>
        } else {
            return <ModalEditor show={show} onHide={this.onHide} titulo={t('modal-nuevo-recorrido')}>
                <Row>
                    <Col>
                        {t('msg-no-trayectos-recorrido')}
                    </Col>
                </Row>
            </ModalEditor>;
        }
    }

}


const mapStateToProps = state => ({
    recorridoCreando: getRecorridoCreando(state),
    trayectos: getTrayectos(state),
    mapaRecorridos: getMapaRecorridos(state),
    pksPorAnadir: getPksPorAnadir(state),
    capas: getCapas(state),
    proyecto: getProyecto(state),
    recorridoCompletoCreado: getRecorridoCompletoCreado(state),
    idUltimoRecorridoCreado: getIdUltimoRecorridoCreado(state),
    recorridos: getRecorridos(state),
    pestanaActivaRecorrido: getPestanaActivaRecorrido(state),
    animacionCrearRecorrido: getAnimacionCrearRecorrido(state),
    puntos: state.puntos.list
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalNuevoRecorrido: actions.setVisibleModalNuevoRecorrido,
    setSectorPorDefecto: actionsRecorridos.setSectorPorDefecto,
    creaNuevoRecorrido: creaNuevoRecorrido,
    resetPksPorAnadir: actions.resetPkPorAnadir,
    creaNuevoPunto: creaNuevoPunto,
    setPestanaActivaRecorrido: actions.setPestanaActivaRecorrido,
    setAnimacionCrearRecorrido: actions.setAnimacionCrearRecorrido
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalNuevoRecorrido));
