import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Polyline} from "react-leaflet";
import * as L from "leaflet";

import {actions} from "../actions/editor";
import {actions as actionsLinea} from "../actions/lineas";
import {getElementoRecienSubido, getObjetoSeleccionado} from "../reducers/editor";
import {actualizaLinea, creaNuevaLinea} from "../fetchactions/lineas";
import * as turf from "@turf/turf";
import invert from "invert-color";
import {getProyecto} from "../reducers/proyectos";
import {getLineaActualizando} from "../reducers/lineas";
import LineaFlechas from "./LineaFlechas";

class Linea extends Component {

    constructor(props){
        super(props);
        this.lineRef = React.createRef();
        this.montado = true;
        this.seleccionado = false;
        this.type = 'ln';
        this.continuando = false;
        this.cortando = false;

        this.onClick = this.onClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onDrawingEnd = this.onDrawingEnd.bind(this);
        this.onMapSecondClick = this.onMapSecondClick.bind(this);
        this.onDrawigEndRect = this.onDrawigEndRect.bind(this);
        this.onContextMenu = this.onContextMenu.bind(this);
        this.onPressKey = this.onPressKey.bind(this);
    }

    componentDidMount() {
        const {addObjetoDibujado, objetoSeleccionado, elemento} = this.props;
        addObjetoDibujado(this);
         if(objetoSeleccionado && objetoSeleccionado.type === 'ln' && objetoSeleccionado.props.elemento.id === elemento.id) {
            this.seleccionar(true);
        }
        this.montado = true;

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            lineaActualizando, elementoRecienSubido, setElementoRecienSubido, setVisibleModalPropAvanzadas, elemento
        }= this.props;
        if(this.cortando && lineaActualizando) {
            this.cortando = false;
            const map = this.props.map.current.leafletElement;
            map.once('click', this.onMapClick);
        }
        if(this.seleccionado){
            const line = this.lineRef.current.leafletElement;
            line.disableEdit();
            line.enableEdit();
        }
        if(elementoRecienSubido && elementoRecienSubido.id === elemento.id) {
            this.seleccionar();
            setElementoRecienSubido(null);
            setVisibleModalPropAvanzadas(true);
        }
    }

    onClick(e) {
        const {objetoSeleccionado, elemento} = this.props;
        e.originalEvent.stopPropagation();
        if(!objetoSeleccionado || objetoSeleccionado.props.elemento.id !== elemento.id)
            this.seleccionar(true);
    }

    onMapClick(e){
        if(!this.continuando || !this.cortando){
            const map = this.props.map.current.leafletElement;
            if(!this.isInDistanciaSeguridad(e)) {
                map.off('click', this.onMapClick);
                if (this.seleccionado) {
                    this.deseleccionar()
                }
            } else {
                map.on('click', this.onMapSecondClick);
            }
        }
    }

    onMapSecondClick(e) {
        const map = this.props.map.current.leafletElement;
        map.off('click', this.onMapSecondClick);
        this.onMapClick(e);
    }

    isInDistanciaSeguridad(e) {
        const map = this.props.map.current.leafletElement;
        const line = this.lineRef.current.leafletElement;
        const puntoCercando = turf.nearestPointOnLine(line.toGeoJSON().geometry, [e.latlng.lng, e.latlng.lat]);
        const puntoPantalla = map.latLngToContainerPoint(L.latLng(puntoCercando.geometry.coordinates[1],
            puntoCercando.geometry.coordinates[0]));
        const dist = Math.sqrt((puntoPantalla.x - e.containerPoint.x) * (puntoPantalla.x - e.containerPoint.x)  +
            (puntoPantalla.y - e.containerPoint.y) * (puntoPantalla.y - e.containerPoint.y));
        return dist < 20
    }

    seleccionar(noCentrar) {
        const {objetoSeleccionado, setObjetoSeleccionado, cambiarModoBarraHerramientas, removeCapaColapsada, elemento,
            cambiarVisibleBarraHerramientas, removeAgrupacionColapsada} = this.props;
        const line = this.lineRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;
        if(objetoSeleccionado && objetoSeleccionado.montado) objetoSeleccionado.deseleccionar();
        line.enableEdit();
        this.seleccionado = true;
        cambiarModoBarraHerramientas('ln');
        cambiarVisibleBarraHerramientas(true);
        if(!noCentrar) this.centrarEnMi();
        setObjetoSeleccionado(this);
        removeCapaColapsada(elemento.bloque.capa);
        removeAgrupacionColapsada(elemento.agrupacion);
        document.addEventListener('keyup', this.onPressKey);
        setTimeout(() => {                          //esto non deberia ser necesario pero senon non funciona conmutar entre lineas
            map.once('click', this.onMapClick);
        }, 20);
    }

    deseleccionar(){
        const {setObjetoSeleccionado, elemento, actualizaCoordsLinea, cambiarVisibleBarraHerramientas,
            actualizaLinea} = this.props;
        const line = this.lineRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;
        line.disableEdit();
        actualizaCoordsLinea(elemento.id, line.toGeoJSON().geometry);
        actualizaLinea(elemento.id, {
            nombre: elemento.nombre,
            bloque: elemento.bloque.id,
            coordenadas: line.toGeoJSON().geometry
        });
        this.seleccionado = false;
        setObjetoSeleccionado(null);
        cambiarVisibleBarraHerramientas(false);
        map.off('click', this.onMapClick);
        document.removeEventListener('keyup', this.onPressKey);
    }

    getLatLngs() {
        const {elemento} = this.props;
        let latLngs = [];
        for(let i in elemento.coordenadas.coordinates) {
            latLngs.push(L.latLng(elemento.coordenadas.coordinates[i][1], elemento.coordenadas.coordinates[i][0]));
        }
        return latLngs
    }

    centrarEnMi(){
        const line = this.lineRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;

        let bounds = line.getBounds();
        map.flyToBounds(bounds);
    }

    duplicar(paralelo, negativo) {
        if(this.cortando) this.cancelaRecorte();
        if(this.continuando) this.cancelaContinuar();
        const {t, elemento, proyecto, setDuplicando, creaNuevaLinea} = this.props;
        const line = turf.lineString(elemento.coordenadas.coordinates);
        const offsetLine = turf.lineOffset(line, negativo ? -3 : 3, {units: 'meters'});
        const sufijo = paralelo ? t('paralelo').toLowerCase() + (negativo ? '-':'+') : t('duplicado').toLowerCase();
        setDuplicando(true);
        creaNuevaLinea({
            nombre: elemento.nombre + '-' + sufijo,
            bloque: elemento.bloque.id,
            color: paralelo ? elemento.color : invert(elemento.color),
            grosor: elemento.grosor,
            coordenadas: paralelo ? offsetLine.geometry : elemento.coordenadas,
            patron: elemento.patron.id,
            proyecto: proyecto.id
        });
    }

    getUltimasCoords(){
        const line = this.lineRef.current.leafletElement;
        return line.toGeoJSON().geometry;
    }

    continuar() {
        if(this.cortando) this.cancelaRecorte();
        const line = this.lineRef.current.leafletElement;
        this.continuando = true;
        line.editor.continueForward();
        line.on('editable:drawing:end', this.onDrawingEnd);
    }

    onDrawingEnd() {
        const line = this.lineRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;
        this.continuando = false;
        line.off('editable:drawing:end');
        map.once('click', this.onMapClick);
    }

    cortar() {
        if(this.continuando) this.cancelaContinuar()
        const map = this.props.map.current.leafletElement;
        this.cortando = true;
        const rect = map.editTools.startRectangle();
        rect.setStyle({color: '#bbb', fillOpacity: .15, dashArray:'10 10'})
        rect.on('editable:drawing:end', this.onDrawigEndRect);
    }

    onDrawigEndRect(e) {
        const {setVisibleModalConfirmarRecorte} = this.props;
        e.layer.off('editable:drawing:end');
        e.layer.disableEdit();
        this.lineaRecorte = this.recorte(e.layer.toGeoJSON());
        if(this.lineaRecorte) {
            setVisibleModalConfirmarRecorte(true);
        }
    }

    aplicaRecorte() {
        const map = this.props.map.current.leafletElement;
        const {elemento, actualizaTrayecto, actualizaCoordsTrayecto} = this.props;
        actualizaCoordsTrayecto(elemento.id, this.lineaRecorte.geometry);
        actualizaTrayecto(elemento.id, {
            nombre: elemento.nombre,
            bloque: elemento.bloque.id,
            coordenadas: this.lineaRecorte.geometry
        });
        map.removeLayer(this.rectanguloRecorte);
    }

    recorte(rectangulo) {
        const {t} = this.props;
        const line = this.lineRef.current.leafletElement;
        const lineJson = line.toGeoJSON();
        const intersect = turf.lineIntersect(lineJson, rectangulo);
        if(intersect.features.length < 1) {
            alert(t('msg-error-recortar'));
            return false;
        } else {
            const segmentos = [];
            segmentos.push(turf.lineSlice(lineJson.geometry.coordinates[0], intersect.features[0], lineJson));
            for(let i = 0; i<intersect.features.length-1; i++) {
                segmentos.push(turf.lineSlice(intersect.features[i],
                    intersect.features[i+1], lineJson));
            }
            segmentos.push(turf.lineSlice(intersect.features[intersect.features.length - 1],
                lineJson.geometry.coordinates[lineJson.geometry.coordinates.length -1], lineJson));
            let coordenadas = []
            for(let i in segmentos) {
                if(!this.isDentroRectangulo(segmentos[i], rectangulo))
                    coordenadas = coordenadas.concat(segmentos[i].geometry.coordinates);
            }
            return turf.lineString(coordenadas);
        }

    }

    isDentroRectangulo(segmento, rectangulo) {
        for(let i=1; i< segmento.geometry.coordinates.length-1; i++) {
            if(!turf.booleanPointInPolygon(segmento.geometry.coordinates[i], rectangulo))
                return false;
        }
        return true;
    }

    onPressKey(e) {
        if(this.seleccionado) {
            if(e.keyCode === 27) {
                if(this.cortando) {
                    this.cancelaRecorte();
                    return true;
                }
                if(this.continuando) {
                    this.cancelaContinuar();
                    return true;
                }
                if(this.seleccionado) {
                    this.deseleccionar();
                }
            }
            /*else if(e.keyCode === 46) { // para eliminar co botón de suprimir, pero funciona raro, xa o arrleglarei
                const {cambiarVisibleModalEliminarElemento} = this.props;
                cambiarVisibleModalEliminarElemento(true);
            }*/
        }
    }

    cancelaRecorte() {
        const map = this.props.map.current.leafletElement;
        this.rectanguloRecorte.off('editable:drawing:end');
        this.rectanguloRecorte.disableEdit();
        map.removeLayer(this.rectanguloRecorte);
        this.cortando = false;
        map.once('click', this.onMapClick)
    }

    cancelaContinuar() {
        const polyline = this.polyRef.current.leafletElement;
        polyline.disableEdit();
        polyline.enableEdit();
    }

    onContextMenu(e) {
        const {objetoSeleccionado, elemento, setVisibleModalPropAvanzadas} = this.props;
        e.originalEvent.stopPropagation();
        if(!objetoSeleccionado || objetoSeleccionado.props.elemento.id !== elemento.id)
            this.seleccionar(true);
        setVisibleModalPropAvanzadas(true);
    }

    descargar() {
        const { elemento } = this.props;
        const toGpx = require('togpx');
        const gpx = toGpx(turf.lineString(elemento.coordenadas.coordinates, {
            name: elemento.nombre,
            color: elemento.color,
            desc: elemento.descripcion
        }));

        const blob = new Blob([gpx], { type: 'application/gpx+xml' })

        const a = document.createElement('a')
        a.download = (elemento.nombre + '.gpx').replace(/\s/g, '_')
        a.href = URL.createObjectURL(blob)
        a.dataset.downloadurl = ['application/gpx+xml', a.download, a.href].join(':')
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        setTimeout(() => URL.revokeObjectURL(a.href), 1500)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !this.continuando;
    }

    componentWillUnmount() {
        const {removeObjetoDibujado, cambiarVisibleBarraHerramientas, setObjetoSeleccionado} = this.props;
        const map = this.props.map.current? this.props.map.current.leafletElement : null;
        if(map) map.off('click', this.onMapClick);
        removeObjetoDibujado(this);
        if(this.seleccionado) {
            if(this.eliminado) {
                setObjetoSeleccionado(null);
                setTimeout(() => {
                    cambiarVisibleBarraHerramientas(false);
                }, 75);
            }
        }
        this.montado = false;
    }

    render() {
        const { elemento, objetoSeleccionado } = this.props;
        if ((!objetoSeleccionado || objetoSeleccionado.props.elemento.id !== elemento.id) && elemento.bloque.patron.flechas) {
            return <LineaFlechas
                refLinea={this.lineRef}
                positions={this.getLatLngs()}
                elemento={elemento}
                onContextMenu={this.onContextMenu}
                onClick={this.onClick}
            />
        }
        return <Polyline ref={this.lineRef} positions={this.getLatLngs()} color={elemento.color} onClick={this.onClick}
                         weight={elemento.grosor} dashArray={elemento.bloque.patron.patron} opacity={elemento.opacidad}
                         onContextmenu={this.onContextMenu}/>
    }
}

const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state),
    proyecto: getProyecto(state),
    lineaActualizando: getLineaActualizando(state),
    elementoRecienSubido: getElementoRecienSubido(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    addObjetoDibujado: actions.addObjetoDibujado,
    setObjetoSeleccionado: actions.objetoSeleccionado,
    actualizaCoordsLinea: actionsLinea.actualizaCoordsLinea,
    actualizaLinea: actualizaLinea,
    removeObjetoDibujado: actions.removeObjetoDibujado,
    cambiarVisibleBarraHerramientas: actions.cambiarVisibleBarraHerramientas,
    cambiarModoBarraHerramientas: actions.cambiarModoBarraHerramientas,
    setDuplicando: actions.setDuplicando,
    creaNuevaLinea: creaNuevaLinea,
    removeCapaColapsada: actions.removeCapaColapsada,
    setVisibleModalPropAvanzadas: actions.setVisibleModalPropAvanzadas,
    setVisibleModalConfirmarRecorte: actions.setVisibleModalCofirmarRecorte,
    cambiarVisibleModalEliminarElemento: actions.cambiarVisibleModalEliminarElemento,
    removeAgrupacionColapsada: actions.removeAgrupacionColapsada,
    setElementoRecienSubido: actions.setElementoRecienSubido,
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Linea))
