export const ACTION_TYPES = {
    CREATE_MAPA_TRAFICO_BEGIN: 'CREATE_MAPA_TRAFICO_BEGIN',
    CREATE_MAPA_TRAFICO_SUCCESS: 'CREATE_MAPA_TRAFICO_SUCCESS',
    CREATE_MAPA_TRAFICO_ERROR: 'CREATE_MAPA_TRAFICO_ERROR',
    DELETE_MAPA_TRAFICO_BEGIN: 'DELETE_MAPA_TRAFICO_BEGIN',
    DELETE_MAPA_TRAFICO_SUCCESS: 'DELETE_MAPA_TRAFICO_SUCCESS',
    DELETE_MAPA_TRAFICO_ERROR: 'DELETE_MAPA_TRAFICO_ERROR',
    FETCH_MAPAS_TRAFICO_BEGIN: 'FETCH_MAPAS_TRAFICO_BEGIN',
    FETCH_MAPAS_TRAFICO_SUCCESS: 'FETCH_MAPAS_TRAFICO_SUCCESS',
    FETCH_MAPAS_TRAFICO_ERROR: 'FETCH_MAPAS_TRAFICO_ERROR',
    RESET_TRAFICO_EDITANDO: 'RESET_TRAFICO_EDITANDO',
    SET_CAPA_FONDO: 'SET_CAPA_FONDO_TRAFICO',
    SET_NOMBRE: 'SET_NOMBRE_TRAFICO',
    SET_VISIBLE: 'SET_VISIBLE_TRAFICO',
    SET_DESCRIPCION: 'SET_DESCRIPCION_TRAFICO',
    SET_MAPA_TRAFICO_EDITANDO: 'SET_MAPA_TRAFICO_EDITANDO',
    SET_META_DESCRIPCION: 'SET_META_DESCRIPCION_TRAFICO',
    ADD_ELEMENTO_TRAFICO: 'ADD_ELEMENTO_TRAFICO',
    REMOVE_ELEMENTO_TRAFICO: 'REMOVE_ELEMENTO_TRAFICO',
    UPDATE_MAPA_TRAFICO_BEGIN: 'UPDATE_MAPA_TRAFICO_BEGIN',
    UPDATE_MAPA_TRAFICO_SUCCESS: 'UPDATE_MAPA_TRAFICO_SUCCESS',
    UPDATE_MAPA_TRAFICO_ERROR: 'UPDATE_MAPA_TRAFICO_ERROR'
}

function fetchMapasTraficoBegin () {
    return {
        type: ACTION_TYPES.FETCH_MAPAS_TRAFICO_BEGIN
    }
}

function fetchMapasTraficoSuccess (mapasTrafico) {
    return {
        type: ACTION_TYPES.FETCH_MAPAS_TRAFICO_SUCCESS,
        mapasTrafico
    }
}

function fetchMapasTraficoError (error) {
    return {
        type: ACTION_TYPES.FETCH_MAPAS_TRAFICO_ERROR,
        error
    }
}

function setNombre (nombre) {
    return {
        type: ACTION_TYPES.SET_NOMBRE,
        nombre
    }
}

function setCapaFondo (capaFondo) {
    return {
        type: ACTION_TYPES.SET_CAPA_FONDO,
        capaFondo
    }
}

function resetTraficoEditando () {
    return {
        type: ACTION_TYPES.RESET_TRAFICO_EDITANDO
    }
}

function setVisible (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE,
        visible
    }
}

function setDescripcion (descripcion) {
    return {
        type: ACTION_TYPES.SET_DESCRIPCION,
        descripcion
    }
}

function setMetaDescripcion (metaDescripcion) {
    return {
        type: ACTION_TYPES.SET_META_DESCRIPCION,
        metaDescripcion
    }
}

function addElementoTrafico (elemento) {
    return {
        type: ACTION_TYPES.ADD_ELEMENTO_TRAFICO,
        elemento
    }
}

function removeElementoTrafico (elemento) {
    return {
        type: ACTION_TYPES.REMOVE_ELEMENTO_TRAFICO,
        elemento
    }
}

function createMapaTraficoBegin () {
    return {
        type: ACTION_TYPES.CREATE_MAPA_TRAFICO_BEGIN
    }
}

function createMapaTraficoSuccess (mapaTrafico) {
    return {
        type: ACTION_TYPES.CREATE_MAPA_TRAFICO_SUCCESS,
        mapaTrafico
    }
}

function createMapaTraficoError (error) {
    return {
        type: ACTION_TYPES.CREATE_MAPA_TRAFICO_ERROR,
        error
    }
}

function deleteMapaTraficoBegin () {
    return {
        type: ACTION_TYPES.DELETE_MAPA_TRAFICO_BEGIN
    }
}

function deleteMapaTraficoSuccess (mapaTrafico) {
    return {
        type: ACTION_TYPES.DELETE_MAPA_TRAFICO_SUCCESS,
        mapaTrafico
    }
}

function deleteMapaTraficoError (error) {
    return {
        type: ACTION_TYPES.DELETE_MAPA_TRAFICO_ERROR,
        error
    }
}

function updateMapaTraficoBegin () {
    return {
        type: ACTION_TYPES.UPDATE_MAPA_TRAFICO_BEGIN
    }
}

function updateMapaTraficoSuccess (mapaTrafico) {
    return {
        type: ACTION_TYPES.UPDATE_MAPA_TRAFICO_SUCCESS,
        mapaTrafico
    }
}

function updateMapaTraficoError (error) {
    return {
        type: ACTION_TYPES.UPDATE_MAPA_TRAFICO_ERROR,
        error
    }
}

function setMapaTraficoEditando (mapaTrafico) {
    return {
        type: ACTION_TYPES.SET_MAPA_TRAFICO_EDITANDO,
        mapaTrafico
    }
}

export const actions = {
    fetchMapasTraficoBegin,
    fetchMapasTraficoSuccess,
    fetchMapasTraficoError,
    setNombre,
    setCapaFondo,
    resetTraficoEditando,
    setVisible,
    setDescripcion,
    setMetaDescripcion,
    addElementoTrafico,
    removeElementoTrafico,
    createMapaTraficoBegin,
    createMapaTraficoSuccess,
    createMapaTraficoError,
    deleteMapaTraficoBegin,
    deleteMapaTraficoSuccess,
    deleteMapaTraficoError,
    updateMapaTraficoBegin,
    updateMapaTraficoSuccess,
    updateMapaTraficoError,
    setMapaTraficoEditando
}
