import React, { Component } from 'react'
import {Polyline} from "react-leaflet";
import L from "leaflet";
require('../lib/L.Symbol')

export default class LineaFlechas extends Component {
    componentDidMount() {
        const { refLinea, elemento, onClick } = this.props
        const polyline = refLinea.current.leafletElement
        const { map } = refLinea.current.props.leaflet
        const optionsSymbol = {
            pixelSize: 12,
            polygon: false,
            headAngle: 50,
            pathOptions: {
                fillOpacity: 1,
                weight: 3,
                color: elemento.color,
                stroke: true
            },
            interactive: false
        }
        let patterns = [
            {
                offset: 0,
                repeat: elemento.bloque.patron.patron,
                symbol: this.getSymbol(optionsSymbol)
            }

        ]
        this.decorator = L.polylineDecorator(polyline, {
            patterns: patterns
        })
        this.decorator.on('click', onClick)
        map.addLayer(this.decorator)
    }

    getSymbol (opciones) {
        const { elemento } = this.props
        switch (elemento.bloque.patron.flechas_tipo) {
            case 'sa':
                return L.Symbol.arrowHeadSalidaArriba(opciones)
            case 'sb':
                return L.Symbol.arrowHeadSalidaAbajo(opciones)
            case 'ea':
                return L.Symbol.arrowHeadEntradaArriba(opciones)
            case 'eb':
                return L.Symbol.arrowHeadEntradaAbajo(opciones)
            case 'm':
                return L.Symbol.arrowHeadMango(opciones)
            default:
                return L.Symbol.arrowHead(opciones)
        }
    }

    componentWillUnmount() {
        const { refLinea } = this.props
        const { map } = refLinea.current.props.leaflet

        map.removeLayer(this.decorator)
    }

    render() {
        const { elemento, positions, refLinea, onContextMenu, onClick } = this.props
        return (
            <Polyline
                ref={refLinea}
                positions={positions}
                color={elemento.color}
                onClick={onClick}
                weight={0}
                dashArray={elemento.bloque.patron.patron}
                opacity={elemento.opacidad}
                onContextmenu={onContextMenu}
            />
        )

    }
}
