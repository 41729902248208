import React, {Component} from 'react';
import {Marker} from "react-leaflet";
import {bindActionCreators} from "redux";
import connect from "react-redux/es/connect/connect";
import invert from "invert-color";
import {withTranslation} from "react-i18next";
import * as turf from '@turf/turf';

import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import {Icon} from "../lib/leaflet.awesome-markers-svg";
import '../lib/icons/icons.css';
import {actions as actionsEditor} from "../actions/editor";
import {getObjetoSeleccionado} from "../reducers/editor";
import {actualizaPunto, creaNuevoPunto} from "../fetchactions/puntos";
import {actions} from "../actions/puntos";
import {getProyecto} from "../reducers/proyectos";
import {getPuntoActualizando} from "../reducers/puntos";

export class Punto extends Component{

    constructor(props) {
        super(props);
        this.pointRef = React.createRef();
        this.seleccionado = false;
        this.montado = false;
        this.type = 'pt';

        this.onClick = this.onClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onContextmenu = this.onContextmenu.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
        const {addObjetoDibujado, objetoSeleccionado, elemento} = this.props;
        addObjetoDibujado(this);
        if(objetoSeleccionado && objetoSeleccionado.props.elemento.id === elemento.id && objetoSeleccionado.type === 'pt') {
            this.seleccionar(true);
        }
        this.montado = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {elemento} = this.props;
        if(this.seleccionado){
            const marker = this.pointRef.current.leafletElement;
            if(!elemento.bloque.automatico) {
                marker.disableEdit();
                marker.enableEdit();
            }
            this.setStyleSeleccionado()
        }
    }

    getIcon(){
        const {elemento} = this.props;
        return new Icon({
            iconSize: [elemento.icono.fondo.ancho, elemento.icono.fondo.alto],
            iconAnchor: [elemento.icono.fondo.centro_x, elemento.icono.fondo.centro_y],
            icon: elemento.icono.icono,
            iconColor: elemento.color_icono,
            prefix: elemento.icono.prefijo_fuente,
            markerColor: elemento.color,
            svg: elemento.icono.fondo.svg,
            fontSizeIcon: elemento.icono.fondo.tamano_fuente_icono,
            classFondo: ' icono-' + elemento.icono.fondo.nombre
        });
    }

    onClick(e){
        const {objetoSeleccionado, elemento} = this.props;
        e.originalEvent.stopPropagation();
        if(!objetoSeleccionado || objetoSeleccionado.props.elemento.id !== elemento.id)
            this.seleccionar(true);
    }

    seleccionar(noCentrar) {
        const {setObjetoSeleccionado, objetoSeleccionado, cambiarModoBarraHerramientas, removeCapaColapsada, elemento,
            cambiarVisibleBarraHerramientas, removeAgrupacionColapsada} = this.props;
        const marker = this.pointRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;
        if(objetoSeleccionado && objetoSeleccionado.montado) objetoSeleccionado.deseleccionar();
        if(!elemento.bloque.automatico) marker.enableEdit();
        this.setStyleSeleccionado();
        cambiarModoBarraHerramientas('pt');
        cambiarVisibleBarraHerramientas(true);
        this.seleccionado = true;
        setObjetoSeleccionado(this);
        removeCapaColapsada(elemento.bloque.capa);
        removeAgrupacionColapsada(elemento.agrupacion);
        if(!noCentrar) this.centrarEnMi();
        document.addEventListener('keyup', this.onKeyPress);
        setTimeout(() => {                          //esto non deberia ser necesario pero senon non funciona conmutar entre lineas
            map.once('click', this.onMapClick);
        }, 20);
    }

    onMapClick(e) {
        const map = this.props.map.current.leafletElement;
        map.off('click',this.onMapClick);
        if(this.seleccionado) {
            this.deseleccionar()
        }
    }

    deseleccionar() {
        const {setObjetoSeleccionado, actualizaPunto, elemento, actualizaCoordsPunto,
            cambiarVisibleBarraHerramientas} = this.props;
        const marker = this.pointRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;
        if(!elemento.bloque.automatico) marker.disableEdit();
        this.setStyleDeseleccionado();
        actualizaCoordsPunto(elemento.id, marker.toGeoJSON().geometry);
        actualizaPunto(elemento.id, {
            nombre: elemento.nombre,
            bloque: elemento.bloque.id,
            icono: elemento.icono.id,
            coordenadas: marker.toGeoJSON().geometry
        });
        cambiarVisibleBarraHerramientas(false);
        this.seleccionado = false;
        setObjetoSeleccionado(null);
        document.removeEventListener('keyup', this.onKeyPress);
        map.off('click', this.onMapClick)
    }

    setStyleSeleccionado() {
        const {elemento} = this.props;
        const marker = this.pointRef.current.leafletElement;// sectoresAsociados.forEach(sec => {
        //     formData.append('sectores', sec.id);
        // })
        marker.setOpacity(.75);
        marker._icon.querySelectorAll('svg')[0].style.stroke = invert(elemento.color);
        marker._icon.querySelectorAll('svg')[0].style.strokeWidth = '.3px';
    }

    setStyleDeseleccionado() {
        const marker = this.pointRef.current.leafletElement;
        marker.setOpacity(1);
        marker._icon.querySelectorAll('svg')[0].style.strokeWidth = '0';
    }

    centrarEnMi() {
        const {elemento} = this.props;
        const map = this.props.map.current.leafletElement;
        map.flyTo([elemento.coordenadas.coordinates[1], elemento.coordenadas.coordinates[0]], 18)
    }

    duplicar() {
        const {t, elemento, proyecto, creaNuevoPunto, setDuplicando} = this.props;
        setDuplicando(true);
        let translated = turf.transformTranslate(elemento.coordenadas, 3, 0, {units: 'meters'});
        creaNuevoPunto({
            nombre: elemento.nombre + '-' + t('duplicado').toLowerCase(),
            bloque: elemento.bloque.id,
            icono: elemento.icono.id,
            color: elemento.color,
            color_icono: elemento.color_icono,
            coordenadas: translated,
            proyecto: proyecto.id
        })
    }

    getUltimasCoords(){
        const marker = this.pointRef.current.leafletElement;
        return marker.toGeoJSON().geometry;
    }

    onContextmenu(e) {
        const {objetoSeleccionado, elemento, setVisibleModalPropAvanzada} = this.props;
        e.originalEvent.stopPropagation();
        if(!objetoSeleccionado || objetoSeleccionado.props.elemento.id !== elemento.id)
            this.seleccionar(true);
        setVisibleModalPropAvanzada(true);
    }

    onKeyPress(e){
        if(e.keyCode === 27) {
            if(this.seleccionado) {
                this.deseleccionar();
            }
        }
        /*else if(e.keyCode === 46 && this.seleccionado) { // para eliminar co botón de suprimir, pero funciona raro, xa o arrleglarei
            const {cambiarVisibleModalEliminarElemento} = this.props;
            cambiarVisibleModalEliminarElemento(true);
        }*/
    }

    componentWillUnmount() {
        const {removeObjetoDibujado, setObjetoSeleccionado, cambiarVisibleBarraHerramientas} = this.props;
        const map = this.props.map.current? this.props.map.current.leafletElement : null;
        if(map) map.off('click', this.onMapClick);
        removeObjetoDibujado(this);
        if(this.seleccionado) {
            if(this.eliminado) {
                setObjetoSeleccionado(null);
                setTimeout(() => {
                    cambiarVisibleBarraHerramientas(false);
                }, 75);
            }
        }
        this.montado = false;
    }

    render() {
        const {elemento} = this.props;
        if(elemento.coordenadas)
            return <Marker icon={this.getIcon()} onClick={this.onClick} ref={this.pointRef}
                       position={[elemento.coordenadas.coordinates[1], elemento.coordenadas.coordinates[0]]}
                           onContextmenu={this.onContextmenu}/>
        else return null;
    }
}

const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state),
    proyecto: getProyecto(state),
    puntoActualizando: getPuntoActualizando(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setObjetoSeleccionado: actionsEditor.objetoSeleccionado,
    actualizaPunto: actualizaPunto,
    actualizaCoordsPunto: actions.actualizaCoordsPunto,
    addObjetoDibujado: actionsEditor.addObjetoDibujado,
    removeObjetoDibujado: actionsEditor.removeObjetoDibujado,
    cambiarModoBarraHerramientas: actionsEditor.cambiarModoBarraHerramientas,
    cambiarVisibleBarraHerramientas: actionsEditor.cambiarVisibleBarraHerramientas,
    creaNuevoPunto: creaNuevoPunto,
    setDuplicando: actionsEditor.setDuplicando,
    removeCapaColapsada: actionsEditor.removeCapaColapsada,
    setVisibleModalPropAvanzada: actionsEditor.setVisibleModalPropAvanzadas,
    cambiarVisibleModalEliminarElemento: actionsEditor.cambiarVisibleModalEliminarElemento,
    removeAgrupacionColapsada: actionsEditor.removeAgrupacionColapsada
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Punto));
