import { connect } from 'react-redux'
import { withTranslation } from "react-i18next"

import { ModalNuevoMapaTrafico } from "./ModalNuevoMapaTrafico"
import { actions } from "../../actions/editor"
import { updateMapaTrafico } from "../../fetchactions/trafico"

class ModalEditarTrafico extends ModalNuevoMapaTrafico {
    componentDidMount () {
        this.setState({
            filtroElementos: ''
        })
    }

    handleClickAceptar() {
        const {dispatch, traficoEditando, mapasTrafico} = this.props
        if (this.valida()) {
            const prevMapa = mapasTrafico.filter(mt => mt.id === traficoEditando.id)
            dispatch(updateMapaTrafico(
                traficoEditando.id,
                {
                    nombre: traficoEditando.nombre,
                    capa_fondo: traficoEditando.capa_fondo,
                    descripcion: traficoEditando.descripcion,
                    meta_descripcion: traficoEditando.meta_descripcion,
                    visible: traficoEditando.visible
                },
                prevMapa.length > 0 ? prevMapa[0].elementos_trafico : [],
                traficoEditando.elementos_trafico
            ))
            this.hide()
        }
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalEditarTrafico(false))
        dispatch(actions.setVisibleModalTrafico(true))
    }
}

const mapStateToProps = state => ({
    traficoEditando: state.traficoEditando,
    capasFondo: state.capasFondo.list,
    trayectos: state.trayectos.present.list,
    puntos: state.puntos.list,
    poligonos: state.poligonos.list,
    lineas: state.lineas.list,
    tiposMapas: state.tiposMapas.data,
    agrupaciones: state.agrupacionesProyecto.list,
    proyecto: state.proyecto.data,
    mapasTrafico: state.mapasTrafico.data
})

export default withTranslation()(connect(mapStateToProps)(ModalEditarTrafico))
