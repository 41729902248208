import React from 'react';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BtnBarraHerramientas = ({ onClick, className, icon, titulo, disabled, classIcon, svg }) => {
    return (
        <Button className={className + ' p-1'} onClick={onClick} disabled={disabled} title={titulo}>
            {classIcon
                ? (
                    <div>
                        <i className={classIcon} />
                    </div>
                ) : (
                    <div>
                        <FontAwesomeIcon icon={icon} />
                    </div>
                )}
        </Button>
    )
}

export default BtnBarraHerramientas
