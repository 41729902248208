import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";
import * as turf from "@turf/turf";
import Select from 'react-select'

import {getRecorridoCreando} from "../../reducers/recorridos";
import {getTrayectos} from "../../reducers/trayectos";
import {getDeportes} from "../../reducers/deportes";
import {actions} from "../../actions/recorridos";
import {actions as actionsEditor} from "../../actions/editor";
import BoxTrayectoDrag from "../../components/BoxTrayectoDrag";
import AreaDragSector from "../../components/AreaDragSector";
import ContainerSecuenciaSectores from "./ContainerSecuenciaSectores";



class TabSecuenciaRecorrido extends Component {

    constructor(props) {
        super(props);

        this.onChangeDeporte = this.onChangeDeporte.bind(this);
        this.getDistanciaTrayecto = this.getDistanciaTrayecto.bind(this);
        this.onChangeRepeticiones = this.onChangeRepeticiones.bind(this);
        this.reordenaTrayectos = this.reordenaTrayectos.bind(this);
        this.onClickEliminarTrayecto = this.onClickEliminarTrayecto.bind(this);
        this.onChangeEsquemaSimplificado = this.onChangeEsquemaSimplificado.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onClickAddSector = this.onClickAddSector.bind(this);
        this.onChangeDeporteSector = this.onChangeDeporteSector.bind(this);
        this.onChangeNombreSector = this.onChangeNombreSector.bind(this);
        this.onClickEliminarSector = this.onClickEliminarSector.bind(this);
        this.onChangeColorSector = this.onChangeColorSector.bind(this);
    }

    onChangeDeporte(e) {
        const {cambiarDeporteRecorridoCreando} = this.props;
        cambiarDeporteRecorridoCreando(parseInt(e.value));
    }

    getTrayectoById (id) {
        let encontrado = this.props.trayectos.filter(t => t.id === id)
        if (encontrado.length) {
            return encontrado[0]
        }
        throw new Error('El trayecto no existe')
    }

    getDistanciaTrayecto(trayecto) {
        const {modalRecorridos} = this.props;
        const trayectoReal = this.getTrayectoById(trayecto.id)
        const distancia = turf.length(trayectoReal.coordenadas, {units: 'meters'});
        return modalRecorridos.distanciaEnUnidad(distancia, trayectoReal.unidad_distancia);
    }

    onChangeRepeticiones(e, esquema, sector) {
        const {setRepeticionesTrayectoSector} = this.props;
        setRepeticionesTrayectoSector(esquema, sector, Math.floor(e.target.value));
    }

    reordenaTrayectos(ordenNuevo, ordenOrignal, sector) {
        const {reordenaTrayectosNuevo} = this.props;
        reordenaTrayectosNuevo(ordenOrignal, ordenNuevo, sector);
    }

    onClickEliminarTrayecto(e, indexTrayecto, sector) {
        const {eliminaTrayectoSector} = this.props;
        eliminaTrayectoSector(indexTrayecto, sector);
    }

    onChangeEsquemaSimplificado(e, esquema, sector){
        const {setEsquemaSimplificadoTrayectoSector} = this.props;
        setEsquemaSimplificadoTrayectoSector(esquema, sector, e.target.checked);
    }

    getDistanciaRealTotal() {
        const {recorridoCreando, refUnidadesDistancia, modalRecorridos} = this.props;
        let distancia = 0;
        for(let i in recorridoCreando.sectores) {
            distancia += modalRecorridos.getDistanciaRealSector(recorridoCreando.sectores[i], true);
        }
        return modalRecorridos.distanciaEnUnidad(distancia, refUnidadesDistancia.current ?
            refUnidadesDistancia.current.value : 'm');
    }

    getDistanciaOficialTotal() {
        const {recorridoCreando, refUnidadesDistancia, modalRecorridos}  = this.props;
        let distancia = 0;
        for(let i in recorridoCreando.sectores) {
            distancia += modalRecorridos.getDistanciaOficialSector(recorridoCreando.sectores[i], true);
        }
        return modalRecorridos.distanciaEnUnidad(distancia, refUnidadesDistancia.current ?
            refUnidadesDistancia.current.value : 'm');
    }

    isDeporteMultiple(){
        const {deportes, recorridoCreando} = this.props;
        const deporteRec = recorridoCreando.deporte && recorridoCreando.deporte.id
            ? recorridoCreando.deporte.id : recorridoCreando.deporte;
        const deporte = deportes.filter(dep =>
            dep.id === deporteRec)[0];
        return deporte && deporte.multiple;
    }

    onDrop(item, sector) {
        const {addTrayectoToSector} = this.props;
        addTrayectoToSector(item.trayecto, sector);
    }

    onChangeDeporteSector(e, sector) {
        const {cambiarDeporteSector} = this.props;
        cambiarDeporteSector(e.value, sector);
    };

    onChangeNombreSector(e, sector) {
        const {cambiarNombreSectorNuevo} = this.props;
        cambiarNombreSectorNuevo(sector, e.target.value);
    }

    onClickAddSector() {
        const {addSectorNuevo} = this.props;
        addSectorNuevo(this.getNombreSiguienteSector());
    }

    getNombreSiguienteSector() {
        const {t, recorridoCreando} = this.props;
        const sectores = recorridoCreando.sectores;
        let nombre;
        let contador = 1;
        while (!nombre) {
            const cont = contador;
            if (sectores.filter(sector => sector.nombre === t('sector') + ' ' + cont).length === 0) {
                nombre = t('sector') + ' ' + cont;
            }
            contador++;
        }
        return nombre;
    }

    onClickEliminarSector(sector) {
        const {t, eliminarSectorNuevo, recorridoCreando, setSectorPorDefecto, setActiveKeySecuenciaSector} = this.props;
        setActiveKeySecuenciaSector(recorridoCreando.sectores[0].orden !== sector.orden ?
            recorridoCreando.sectores[0].orden: recorridoCreando.sectores[1].orden);
        eliminarSectorNuevo(sector);
        if(recorridoCreando.sectores.length <= 1) {
            setSectorPorDefecto(t('sector') + ' 1')
        }
    }

    onChangeColorSector(e, sector) {
        const {setColorSector} = this.props;
        setColorSector(sector, e.target.value);
    }

    render() {
        const {t, recorridoCreando, deportes, trayectos, modalRecorridos} = this.props;
        const optionsDeportes = deportes.map(d => {
            return {
                value: d.id,
                label: d.nombre,
            }
        });
        const optionSeleccionada = recorridoCreando.deporte ?
            optionsDeportes.filter(op =>
                op.value === (recorridoCreando.deporte.id ? recorridoCreando.deporte.id : recorridoCreando.deporte))[0]
            : null;
        return [
            <Row key='deporte'>
                <Col>
                    <Form.Group as={Row} controlId='deporte-recorrido'>
                        <Form.Label column sm={4} md={3}>{t('deporte') + ':'}</Form.Label>
                        <Col sm={8} md={9}>
                            <Select className='form-group deporte-input' name='deporte' onChange={this.onChangeDeporte}
                                    placeholder={t('seleccione-deporte')}
                                    value={optionSeleccionada}
                                    isSearchable options={optionsDeportes}/>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>,
            <Row key='mensaje' className='explicacion'>
                <Col>{t('explicacion-secuencia-recorrido')}</Col>
            </Row>,
            <Row key='secuencia'>
                <Col className='zona-arrastre' sm={3}>
                    <h6>{t('trayectos')}</h6>
                    {trayectos.map(trayecto =>
                        <BoxTrayectoDrag key={trayecto.id} trayecto={trayecto}/>
                    )}
                </Col>
                <Col className='zona-arrastre' sm={9}>
                    {recorridoCreando && !this.isDeporteMultiple() && recorridoCreando.sectores.map(sector => [
                        <h6 key={-1}>{t('secuencia')}</h6>,
                        <AreaDragSector distanciaEnUnidad={modalRecorridos.distanciaEnUnidad}
                                        onDrop={this.onDrop}
                                        sector={sector} key={sector.orden + '-drag'}
                                        getDistanciaTrayecto={this.getDistanciaTrayecto}
                                        onChangeRepeticiones={this.onChangeRepeticiones}
                                        reordenaTrayectos={this.reordenaTrayectos}
                                        onClickEliminar={this.onClickEliminarTrayecto}
                                        onChangeEsquemaSimplificado={this.onChangeEsquemaSimplificado}
                        />
                    ])}
                    {recorridoCreando && this.isDeporteMultiple() &&
                        <ContainerSecuenciaSectores onDrop={this.onDrop}
                                                    getDistanciaTrayecto={this.getDistanciaTrayecto}
                                                    onChangeRepeticiones={this.onChangeRepeticiones}
                                                    reordenaTrayectos={this.reordenaTrayectos}
                                                    modalRecorridos={modalRecorridos}
                                                    onClickEliminarTrayecto={this.onClickEliminarTrayecto}
                                                    onChangeEsquemaSimplificado={this.onChangeEsquemaSimplificado}
                                                    onChangeDeporteSector={this.onChangeDeporteSector}
                                                    onChangeNombreSector={this.onChangeNombreSector}
                                                    onClickEliminarSector={this.onClickEliminarSector}
                                                    onChangeColorSector={this.onChangeColorSector}
                                                    onClickAddSector={this.onClickAddSector}
                        />
                    }
                    <Row>
                        <Col className='titulo-distancias-totales'>
                            {t('distancia-total-real') + ': '}
                            <span className='valor'>{this.getDistanciaRealTotal()}</span>
                            <span className='px-2'> | </span> {t('distancia-total-oficial') + ': '}
                            <span className='valor'>{this.getDistanciaOficialTotal()}</span>
                        </Col>
                    </Row>
                </Col>
            </Row>
        ]
    }
}

const mapStateToProps = state => ({
    recorridoCreando: getRecorridoCreando(state),
    trayectos: getTrayectos(state),
    deportes: getDeportes(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cambiarDeporteRecorridoCreando: actions.cambiarDeporteRecorridoCreando,
    setRepeticionesTrayectoSector: actions.setRepeticionesTrayectoSector,
    reordenaTrayectosNuevo: actions.reordenaTrayectosNuevo,
    eliminaTrayectoSector: actions.eliminaTrayectoSector,
    setEsquemaSimplificadoTrayectoSector: actions.setEsquemaSimplificadoTrayectoSector,
    addTrayectoToSector: actions.addTrayectoToSector,
    cambiarDeporteSector: actions.cambiarDeporteSector,
    cambiarNombreSectorNuevo: actions.cambiarNombreSectorNuevo,
    addSectorNuevo: actions.addSectorNuevo,
    eliminarSectorNuevo: actions.eliminarSectorNuevo,
    setSectorPorDefecto: actions.setSectorPorDefecto,
    setColorSector: actions.setColorSector,
    setActiveKeySecuenciaSector: actionsEditor.setActiveKeySecuenciaSector
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TabSecuenciaRecorrido));
