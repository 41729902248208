import {API_BASE_URL} from "../constants/api";
import {actions} from "../actions/sectores";
import {actions as actionsComun} from "../actions/comun";
import getCsrfToken from '../lib/csrf'
import { actions as actionsError } from '../actions/error'
import {actualizaPk, creaNuevoPk, eliminarPk} from "./recorridos";


export function fetchSector(id) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.fetchSectorPending());
        fetch(API_BASE_URL + 'sector/' + id + '/')
            .then(res => res.json())
            .then(res=> {
                if(res.error) {
                    throw(res.error);
                }
                dispatch(actions.fetchSectorSuccess(res));
                dispatch(actionsComun.restaPeticion());
                return res;
            })
            .catch(error => {
                dispatch(actions.fetchSectorError(error));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

//export function creaNuevoSector(opciones, trayectos, pksPorAnadir, idProyecto, bloqueSalida, bloqueMeta, bloqueSalidaMeta) {
export function creaNuevoSector(opciones, trayectos, pksPorAnadir) {
    return dispatch => {
        dispatch(actions.nuevoSectorPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'sector/' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.nuevoSectorCorrecto(data));
                            dispatch(actionsComun.restaPeticion());
                            for(let i in trayectos){
                                dispatch(creaNuevoEsquema({
                                    sector: data.id,
                                    trayecto: trayectos[i].trayecto.id,
                                    repeticiones: trayectos[i].repeticiones,
                                    orden: trayectos[i].orden,
                                    esquema_simplificado: trayectos[i].esquema_simplificado
                                }, data.recorrido));
                            }
                            for(let i in pksPorAnadir) {
                                if(pksPorAnadir[i].sector === data.orden)
                                    dispatch(creaNuevoPk({
                                        sector: data.id,
                                        punto: pksPorAnadir[i].punto.id,
                                        distancia_oficial: pksPorAnadir[i].distancia_oficial,
                                        publicado: pksPorAnadir[i].publicado
                                    }, data.recorrido));
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function actualizaSector(id, opciones, recorridoId, trayectos, trayectosAntiguos, pksPorAsociar, borrarTodo) {
    return dispatch => {
        dispatch(actions.sectorActualizandoPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'sector/' + id + '/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.sectorActualizandoCorrecto(data));
                            dispatch(actionsComun.restaPeticion());
                            for(let i in trayectosAntiguos) {
                                const indexNuevo = trayectos.map(trayecto => trayecto.id).indexOf(trayectosAntiguos[i].id);
                                if(indexNuevo >= 0) {
                                    dispatch(actualizaEsquema(trayectosAntiguos[i].id, {
                                        id: trayectosAntiguos[i].id,
                                        sector: trayectosAntiguos[i].sector,
                                        trayecto: trayectosAntiguos[i].trayecto.id,
                                        repeticiones: trayectos[indexNuevo].repeticiones,
                                        orden: trayectos[indexNuevo].orden,
                                        esquema_simplificado: trayectos[indexNuevo].esquema_simplificado
                                    }, data.recorrido))
                                } else {
                                    dispatch(eliminaEsquema(trayectosAntiguos[i], data.recorrido));
                                }
                            }
                            for(let i in trayectos) {
                                if(trayectosAntiguos.map(trayecto => trayecto.id).indexOf(trayectos[i].id) < 0){
                                    dispatch(creaNuevoEsquema({
                                        sector: data.id,
                                        trayecto: trayectos[i].trayecto.id,
                                        repeticiones: trayectos[i].repeticiones,
                                        orden: trayectos[i].orden,
                                        esquema_simplificado: trayectos[i].esquema_simplificado
                                    }, data.recorrido));
                                }
                            }
                            for(let i in data.puntos_km){
                                let noEsta = true;
                                for(let j in pksPorAsociar) {
                                    if(data.puntos_km[i].id === pksPorAsociar[j].id) {
                                        dispatch(actualizaPk({
                                            sector: data.id,
                                            punto: pksPorAsociar[j].punto.id,
                                            distancia_oficial: pksPorAsociar[j].distancia_oficial,
                                            publicado: pksPorAsociar[j].publicado
                                        }, data.puntos_km[i].id, data.recorrido));
                                        noEsta = false;
                                    }
                                }
                                if(noEsta && !borrarTodo) dispatch(eliminarPk(data.puntos_km[i], data.recorrido));
                            }
                            for(let i in pksPorAsociar){
                                if(!pksPorAsociar[i].id) dispatch(creaNuevoPk({
                                    sector: data.id,
                                    punto: pksPorAsociar[i].punto.id,
                                    distancia_oficial: pksPorAsociar[i].distancia_oficial,
                                    publicado: pksPorAsociar[i].publicado
                                }, data.recorrido));
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function actualizaSectorSimple(id, opciones) {
    return dispatch => {
        dispatch(actions.sectorActualizandoPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'sector/' + id + '/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        }).then(res => {
            if (res.status !== 200) {
                return res.json()
                    .then(data => {
                        dispatch(actionsError.hasError(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            } else {
                return res.json()
                    .then(data => {
                        dispatch(actions.sectorActualizandoCorrecto(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            }
        })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function eliminaSector(sector, recorridoId) {
    return dispatch => {
        dispatch(actions.sectorEliminandoPendiente(sector));
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'sector/' + sector.id + '/', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
        })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    dispatch(actions.sectorEliminandoCorrecto(sector, recorridoId));
                    dispatch(actionsComun.restaPeticion());
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function creaNuevoEsquema(opciones, recorridoId) {
    return dispatch => {
        dispatch(actions.nuevoTrayectoEsquemaPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'esquema/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.nuevoTrayectoEsquemaCorrecto(data, recorridoId));
                            dispatch(actionsComun.restaPeticion());
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function eliminaEsquema(esquema, recorridoId) {
    return dispatch => {
        dispatch(actions.esquemaEliminadoPendiente(esquema));
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'esquema/' + esquema.id + '/', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include'
        })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    dispatch(actions.esquemaEliminandoCorrecto(esquema, recorridoId));
                    dispatch(actionsComun.restaPeticion());
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function actualizaEsquema(id, opciones, recorridoId) {
    return dispatch => {
        dispatch(actions.esquemaActualizandoPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'esquema/' + id + '/' , {
            method: 'PUT',
            headers: {
                "Content-Type": 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.esquemaActualizandoCorrecto(data, recorridoId));
                            dispatch(actionsComun.restaPeticion());
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}
