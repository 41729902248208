import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/lineas";

export function lineasReducer(state = initialState.lineas, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_LINEAS_CORRECTO:
            return {
                ...state,
                list: action.lineas,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_LINEAS_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_LINEAS_PENDIENTE:
            return {
                ...state,
                pendiente: true
            };
        case ACTION_TYPES.ADD_LINEA:
            return {
                ...state,
                list: state.list.concat(action.linea)
            };
        case ACTION_TYPES.REMOVE_LINEA:
        case ACTION_TYPES.LINEA_ELIMINADA_CORRECTO:
            return {
                ...state,
                list: state.list.filter(linea => linea.id !== action.linea.id)
            };
        case ACTION_TYPES.ACTUALIZA_COORDS_LINEA:
            const indexLinea = state.list.map(linea => linea.id).indexOf(action.idLinea);
            let list = state.list.slice();
            list[indexLinea].coordenadas = action.coordenadas;
            return {
                ...state,
                list: list
            };
        case ACTION_TYPES.SET_COLOR_LINEA: {
            const il = state.list.map(l => l.id).indexOf(action.linea.id);
            let list = JSON.parse(JSON.stringify(state.list));
            list[il].color = action.color;
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.SET_NOMBRE_LINEA: {
            const il = state.list.map(l => l.id).indexOf(action.linea.id);
            let list = JSON.parse(JSON.stringify(state.list));
            list[il].nombre = action.nombre;
            return {
                ...state,
                list
            }
        }
        default:
            return {
                ...state
            };
    }
}

export function lineaNuevaReducer(state = initialState.lineaNueva, action) {
    switch (action.type) {
        case ACTION_TYPES.LINEA_NUEVA_CORRECTO:
            return {
                ...state,
                elemento: action.linea,
                pendiente: false
            };
        case ACTION_TYPES.LINEA_NUEVA_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false,
            };
        case ACTION_TYPES.LINEA_NUEVA_PENDIENTE:
            return {
                ...state,
                elemento: null,
                error: false,
                pendiente: true
            };
        case ACTION_TYPES.RESET_NUEVA_LINEA:
            return {
                ...state,
                elemento: null,
                error: false,
                pendiente: false
            };
        default:
            return {
                ...state
            };
    }
}

export function lineaActualizandoReducer(state = initialState.lineaActualizando, action) {
    switch (action.type) {
        case ACTION_TYPES.LINEA_ACTUALIZANDO_CORRECTO:
            return {
                ...state,
                elemento: action.linea,
                pendiente: false
            };
        case ACTION_TYPES.LINEA_ACTUALIZANDO_PENDIENTE:
            return {
                ...state,
                elemento: null,
                error: false,
                pendiente: true
            };
        case ACTION_TYPES.LINEA_ACTUALIZANDO_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        default:
            return {
                ...state
            };
    }
}

export function lineaEliminadaReducer(state = initialState.lineaEliminada, action) {
    switch (action.type) {
        case ACTION_TYPES.LINEA_ELIMINADA_CORRECTO:
            return {
                ...state,
                elemento: action.linea,
                correcto: true,
                pendiente: false
            };
        case ACTION_TYPES.LINEA_ELIMINADA_ERROR:
            return {
                ...state,
                elemento: null,
                error: action.error,
                pendiente: false
            };
        case ACTION_TYPES.LINEA_ELIMINADA_PENDIENTE:
            return {
                ...state,
                correcto: false,
                pendiente: true,
                error: false,
                elemento: action.linea
            };
        default:
            return {
                ...state
            };
    }
}


export const getLineas = state => state.lineas.list;
export const getLineasError = state => state.lineas.error;
export const getLineasPendiente = state => state.lineas.pendiente;
export const getLineaNueva = state => state.lineaNueva.elemento;
export const getLineaNuevaPendiente = state => state.lineaNueva.pendiente;
export const getLineaNuevaError = state => state.lineaNueva.error;
export const getLineaActualizando = state => state.lineaActualizando.elemento;
export const getLineaActualizandoPendiente = state => state.lineaActualizando.pendiente;
export const getLineaActualizandoError = state => state.lineaActualizando.error;
export const getLineaEliminada = state => state.lineaEliminada.elemento;
export const getLineaEliminadaCorrecto = state => state.lineaEliminada.correcto;
export const getLineaEliminadaError = state => state.lineaEliminada.error;
export const getLineaEliminadaPendiente = state => state.lineaEliminada.pendiente;
