import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faTh} from "@fortawesome/free-solid-svg-icons";
import {Modal, Container, Button} from 'react-bootstrap';

import './ModalEditor.css';
import {withTranslation} from "react-i18next";

class ModalEditor extends Component{

    render() {
        const {className, show, onHide, titulo, footer, t, size, onClickAceptar, onClickCancelar, nuevoRecorrido,
            pestanaActivaRecorrido, onClickAnterior, onClickSiguiente, backdrop, onClickDescargarTrack} = this.props;
        return <Modal className={'diag-editor ' + className} show={show} onHide={onHide} size={size ? size : 'lg'}
                      centered backdrop={backdrop ? backdrop : true}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FontAwesomeIcon icon={faTh} />
                    <span>{titulo}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {this.props.children}
                </Container>
            </Modal.Body>
            {footer &&
                <Modal.Footer>
                    {onClickDescargarTrack &&
                        <Button variant="secondary" className='btn-footer-left' onClick={onClickDescargarTrack}>
                            <FontAwesomeIcon className='mr-2' icon={faDownload} />
                            {t('Descargar track')}
                        </Button>
                    }
                    <Button variant="secondary" onClick={onClickCancelar}>{t('cancelar')}</Button>
                    {nuevoRecorrido && pestanaActivaRecorrido !== 1 &&
                        <Button variant="primary" onClick={onClickAnterior}>{t('anterior')}</Button>
                    }
                    {nuevoRecorrido && pestanaActivaRecorrido !== 6 &&
                        <Button variant="primary" onClick={onClickSiguiente}>{t('siguiente')}</Button>
                    }
                    {(!nuevoRecorrido || pestanaActivaRecorrido === 6) &&
                        <Button variant="primary" onClick={onClickAceptar}>{t('aceptar')}</Button>
                    }
                </Modal.Footer>
            }
        </Modal>
    }
}

export default withTranslation()(ModalEditor);
