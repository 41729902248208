import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Row, Col, Form, Table, Button} from "react-bootstrap";
import * as turf from '@turf/turf';

import {getRecorridoCreando, getRecorridos} from "../../reducers/recorridos";
import {getPuntos} from "../../reducers/puntos";
import {actions} from "../../actions/recorridos";
import {actions as actionsEditor} from "../../actions/editor";
import PksEsquema from "./PksEsquema";

class ContainerPksSector extends Component {

    constructor(props) {
        super(props);

        this.onChangeGenerarInicioFin = this.onChangeGenerarInicioFin.bind(this);
        this.onChangePksAuto = this.onChangePksAuto.bind(this);
        this.onChangeCadencia = this.onChangeCadencia.bind(this);
        this.onClickBorrarTodos = this.onClickBorrarTodos.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Esto que sigue, non vale para nada
        const {sector, puntos, addPdiSector, editar, show} = this.props;
        if(!editar && show && !prevProps.show) {
            for(let i in sector.trayectos) {
                const ptsTrayecto = puntos.filter(pt => pt.trayecto_ancla === sector.trayectos[i].id);
                for(let j in ptsTrayecto) addPdiSector(sector, ptsTrayecto[j]);
            }
        }
    }

    getDistanciaAcumulada(esquema, repeticion) {
        const {sector} = this.props;
        let i = 0;
        let distancia = 0;
        while(sector.esquemas[i].orden !== esquema.orden) {
            distancia += sector.esquemas[i].trayecto.distancia_metros * sector.esquemas[i].repeticiones;
            i++;
        }
        return this.toUnits(distancia + esquema.trayecto.distancia_metros * (repeticion - 1 === 0 ? repeticion - 1 : 0));
    }

    toUnits(distancia){
        const {recorridoCreando} = this.props;
        switch (recorridoCreando.unidad_distancia) {
            case 'km':
                return turf.convertLength(distancia, 'meters', 'kilometers');
            case 'ft':
                return turf.convertLength(distancia, 'meters', 'feet');
            case 'mi':
                return turf.convertLength(distancia, 'meters', 'miles');
            default:
                return distancia;
        }
    }

    getPaso(esquema, repeticion){
        const {sector} = this.props;
        let pasos = 0;
        let i = 0;
        while(sector.esquemas[i].orden !== esquema.orden) {
            if(sector.esquemas[i].trayecto.id === esquema.trayecto.id) pasos += sector.esquemas[i].repeticiones;
            i++;
        }
        pasos += repeticion;
        return pasos;
    }

    onChangeGenerarInicioFin(e) {
        const {setGenerarInicioFinSector, sector} = this.props;
        setGenerarInicioFinSector(sector, e.target.checked);
    }

    pksYaCreados() {
        const {recorridos, sector} = this.props;
        const recorrido = recorridos.filter(r => r.id === sector.recorrido)[0];
        const sectorGuardado = recorrido ?  recorrido.sectores.filter(s => s.id === sector.id)[0] : false;
        return sectorGuardado && sectorGuardado.puntos_km_auto;
    }

    onChangePksAuto(e) {
        const {setPuntosKmAuto, sector} = this.props;
        setPuntosKmAuto(e.target.checked, sector);
    }

    getCadencias(){
        const {recorridoCreando} = this.props;
        switch (recorridoCreando.unidad_distancia) {
            case 'km':
            case 'mi':
                return [1, 5, 10, 25, 50, 100];
            case 'ft':
                return [100, 500, 1000, 5000];
            default:
                return [1000, 5000, 10000];
        }
    }

    onChangeCadencia(e) {
        const {sector, setCadenciaPks} = this.props;
        setCadenciaPks(parseInt(e.target.value), sector);
    }

    onClickBorrarTodos() {
        const {sector, setVisibleModalBorrarPks, setSectorBorrarPks} = this.props;
        setSectorBorrarPks(sector);
        setVisibleModalBorrarPks(true);
    }

    render() {
        const {t, sector, editar, recorridoCreando, show, puntos} = this.props;
        let haiPuntos = false;
        sector.esquemas.forEach(e => {
          if(puntos.filter(pt =>
               (pt.bloque.es_pk || pt.bloque.automatico) && !pt.semiautomatico && pt.bloque.pdi_sobre_trayecto &&
               pt.trayecto_ancla === e.trayecto.id)) {
              haiPuntos = true;
          }
        });
        return <div>
            {!editar && recorridoCreando.sectores.length > 1 &&
            <Row>
                <Col>
                    <Form.Row>
                        <Form.Check type='checkbox' id={'crear-ini-fin-sector-' + sector.id}>
                            <Form.Check.Input type='checkbox' checked={sector.generar_inicio_fin}
                                              onChange={this.onChangeGenerarInicioFin}/>
                            <Form.Check.Label>{t('crear-ini-fin-sector')}</Form.Check.Label>
                        </Form.Check>
                    </Form.Row>
                </Col>
            </Row>
            }
            {(!editar || !this.pksYaCreados()) &&
            <Row>
                <Col>
                    <Form.Row>
                        <Form.Check type='checkbox' id={'auto-pk-' + (sector ? sector.id : 1)}>
                            <Form.Check.Input type='checkbox' checked={sector ? sector.puntos_km_auto : true}
                                              onChange={this.onChangePksAuto}/>
                            <Form.Check.Label>{t('automatizar-pk')}</Form.Check.Label>
                        </Form.Check>
                    </Form.Row>
                </Col>
                <Col>
                    <Form.Group as={Row}>
                        <Form.Label column sm={4} md={3}>{t('cadencia') + ':'}</Form.Label>
                        <Col sm={8} md={9}>
                            <Form.Control name='cadencia' as='select' disabled={sector ? !sector.puntos_km_auto : true}
                                          onChange={this.onChangeCadencia}>
                                {this.getCadencias().map(cadencia =>
                                    <option key={cadencia} value={cadencia}>
                                        {cadencia + ' ' + recorridoCreando.unidad_distancia}
                                    </option>
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Col>
            </Row>
            }
            {editar && this.pksYaCreados() &&
            <Row className="borrarTodosPks mb-4 flex-column">
                <Col className="d-flex flex-column align-self-end">
                    <Button className="align-self-end" onClick={this.onClickBorrarTodos}><i className="fas fa-trash-alt pr-1"></i> {t('borrar-todos-pks')}</Button>
                </Col>
            </Row>
            }
            {haiPuntos ?
                <Row>
                    <Col>
                        <Table>
                            <thead>
                            <tr>
                                <th>{t('publicado')}</th>
                                <th>{t('nombre')}</th>
                                <th>
                                    {recorridoCreando.sectores.length > 1 ? t('distancia-sector') : t('distancia-recorrido')}
                                </th>
                                <th>{t('distancia-trayecto')}</th>
                                <th>{t('paso-trayecto')}</th>
                                <th>{t('servicios-asociados')}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {sector && sector.esquemas.map(esquema => {
                                let filas = [];
                                for (let i = 0; i < esquema.repeticiones; i++) {
                                    filas.push(<PksEsquema key={esquema.orden + '-' + i}
                                                           esquema={esquema}
                                                           sector={sector}
                                                           paso={this.getPaso(esquema, i + 1)}
                                                           distanciaAcumulada={this.getDistanciaAcumulada(esquema, i + 1)}
                                                           show={show}
                                    />);
                                }
                                return filas
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                :
                <>{t('msg-no-pks-recorrido')}</>
            }
        </div>
    }
}

const mapStateToProps = state => ({
    recorridoCreando: getRecorridoCreando(state),
    puntos: getPuntos(state),
    recorridos: getRecorridos(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    addPdisSector: actions.addPdiSector,
    setGenerarInicioFinSector: actions.setGenerarInicioFinSector,
    setPuntosKmAuto: actions.setPuntosKmAuto,
    setCadenciaPks: actions.setCadenciaPks,
    setVisibleModalBorrarPks: actionsEditor.setVisibleModalBorrarPks,
    setSectorBorrarPks: actionsEditor.setSectorBorrarPks,
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContainerPksSector));
