/* global fetch */

import { actions } from "../actions/elementosMontaje"
import { actions as actionsComun } from '../actions/comun'
import { actions as actionsError } from '../actions/error'
import { API_BASE_URL } from "../constants/api"
import getCsrfToken from "../lib/csrf";

export function createElementoMontaje (opciones) {
    return dispatch => {
        dispatch(actions.createElementoMontajeBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'montaje-elemento/')
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    res.json()
                        .then(err => {
                            dispatch(actions.createElementoMontajeError(err))
                            dispatch(actionsError.hasError(err))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    res.json()
                        .then(data => {
                            dispatch(actions.createElementoMontajeSuccess(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                }
            })
            .catch(err => {
                dispatch(actions.createElementoMontajeError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function deleteElementoMontaje (elementoMontaje) {
    return dispatch => {
        dispatch(actions.deleteElementoMontajeBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'montaje-elemento/')
        Object.keys(elementoMontaje).forEach(
            key => url.searchParams.append(key, elementoMontaje[key])
        )
        return fetch(url, {
            credentials: 'include'
        })
            .then(res => res.json)
            .then(res => {
                if (res.error) {
                    throw res.error
                } else if (res && res.length) {
                    const urlDelete = new URL(API_BASE_URL + 'montaje-elemento/' + res[0].id + '/')
                    return fetch(urlDelete, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'X-CSRFToken': getCsrfToken()
                        }
                    })
                        .then(res => {
                            if (res.status !== 204) {
                                res.json()
                                    .then(err => {
                                        dispatch(actions.deleteElementoMontajeError(err))
                                        dispatch(actionsError.hasError(err))
                                        dispatch(actionsComun.restaPeticion())
                                    })
                            } else {
                                dispatch(actions.deleteElementoMontajeSuccess(elementoMontaje))
                                dispatch(actionsComun.restaPeticion())
                            }
                        })
                        .catch(err => {
                            dispatch(actions.deleteElementoMontajeError(err))
                            dispatch(actionsError.hasError(err))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    throw new Error('Not found')
                }
            })
            .catch(err => {
                dispatch(actions.deleteElementoMontajeError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}
