import React, { Component } from 'react'
import { withTranslation, Trans } from "react-i18next"
import { connect } from "react-redux"
import { Col, Row, Spinner } from "react-bootstrap"
import Select from 'react-select'
import * as turf from '@turf/turf'
import polyline from '@mapbox/polyline'

import ModalEditor from "./ModalEditor"
import { actions } from "../../actions/editor"
import fetchProyectos from "../../fetchactions/proyectos"
import { fetchAgrupacionesProyecto } from "../../fetchactions/agrupaciones"

import './ModalImportarElementos.css'
import {creaNuevoTrayecto} from "../../fetchactions/trayectos";
import {creaNuevaLinea} from "../../fetchactions/lineas";
import {creaNuevoPunto} from "../../fetchactions/puntos";
import {creaNuevoPoligono} from "../../fetchactions/poligonos";

class ModalImportarElementos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            proyectoSeleccionado: null
        }
    }

    componentDidMount() {
        const { proyectos, proyectosError, dispatch, usuario } = this.props
        dispatch(actions.resetElementosImportar())
        if (usuario && !proyectosError && proyectos.length === 0) {
            dispatch(fetchProyectos({ user: usuario.id }))
        }
    }

    onHide () {
        this.hide()
    }

    onClickAceptar () {
        const { elementosImportar } = this.props
        elementosImportar.forEach(e => {
            switch (e.bloque.tipo) {
                case 'tr':
                    this.crearTrayecto(e)
                    break
                case 'pt':
                    this.crearPunto(e)
                    break
                case 'ln':
                    this.crearLinea(e)
                    break
                default:
                    this.crearPoligno(e)
                    break
            }
        })
        this.hide()
    }

    crearTrayecto (original) {
        const { dispatch, proyecto } = this.props
        const opciones = {
            bloque: original.bloque.id,
            color: original.color,
            distancia_homologada: original.distancia_homologada,
            descripcion: original.descripcion,
            flechas: original.flechas,
            grosor: original.grosor,
            nombre: original.nombre,
            url_embebido: original.url_embebido ? original.url_embebido : null,
            coordenadas: turf.lineString(polyline.decode(original.linea_codificada)).geometry,
            proyecto: proyecto.id,
            orden_editor: original.orden_editor
        }
        dispatch(creaNuevoTrayecto(opciones, null, true))
    }


    crearPunto (original) {
        const { dispatch, proyecto } = this.props
        const opciones = {
            bloque: original.bloque.id,
            color: original.color,
            color_icono: original.color_icono,
            coordenadas: JSON.stringify(original.coordenadas),
            descripcion: original.descripcion,
            es_pk: original.es_pk,
            icono: original.icono.id,
            limitado_a_trayecto: original.limitado_a_trayecto,
            nombre: original.nombre,
            pdi_proyecto: original.pdi_proyecto,
            servicios: original.servicios.map(s => s.id ? s.id : s),
            url_embebido: original.url_embebido ? original.url_embebido : '',
            proyecto: proyecto.id,
            orden_editor: original.orden_editor
        }
        const formData = this.opcionesToFormData(opciones)
        if (original.imagen) {
            fetch(original.imagen).then(r => r.blob())
                .then(blob => {
                    const file = new File(
                        [blob], 'image.' + blob.type.split('/')[1], {type: blob.type}
                        )
                    formData.append('imagen', file)
                    dispatch(creaNuevoPunto(null, formData, null, null, true))
                })
        } else {
            dispatch(creaNuevoPunto(null, formData, null, null, true))
        }
    }

    crearLinea (original) {
        const { dispatch, proyecto } = this.props
        const opciones = {
            bloque: original.bloque.id,
            color: original.color,
            coordenadas: original.coordenadas,
            descripcion: original.descripcion,
            grosor: original.grosor,
            nombre: original.nombre,
            pdi_proyecto: original.pdi_proyecto,
            url_embebido: original.url_embebido ? original.url_embebido : '',
            proyecto: proyecto.id,
            orden_editor: original.orden_editor
        }
        const formData = this.opcionesToFormData(opciones)
        if (original.imagen) {
            fetch(original.imagen).then(r => r.blob())
                .then(blob => {
                    const file = new File(
                        [blob], 'image.' + blob.type.split('/')[1], {type: blob.type}
                    )
                    formData.append('imagen', file)
                    dispatch(creaNuevaLinea(null, false, true, formData))
                })
        } else {
            dispatch(creaNuevaLinea(null, false, true, formData))
        }

    }

    crearPoligno (original) {
        const { dispatch, proyecto } = this.props
        const opciones = {
            bloque: original.bloque.id,
            color: original.color,
            color_fondo_icono: original.color_fondo_icono,
            color_icono: original.color_icono,
            coordenadas: original.coordenadas,
            descripcion: original.descripcion,
            grosor_linea: original.grosor_linea,
            nombre: original.nombre,
            opacidad_fondo: original.opacidad_fondo,
            pdi_proyecto: original.pdi_proyecto,
            url_embebido: original.url_embebido ? original.url_embebido : '',
            proyecto: proyecto.id,
            orden_editor: original.orden_editor
        }
        const formData = this.opcionesToFormData(opciones)
        if (original.imagen) {
            fetch(original.imagen).then(r => r.blob())
                .then(blob => {
                    const file = new File(
                        [blob], 'image.' + blob.type.split('/')[1], {type: blob.type}
                    )
                    formData.append('imagen', file)
                    dispatch(creaNuevoPoligono(null, false, true, formData))
                })
        } else {
            dispatch(creaNuevoPoligono(null, false, true, formData))
        }
    }

    opcionesToFormData (opciones) {
        const formData = new FormData()
        Object.keys(opciones).forEach(o => {
            if (Array.isArray(opciones[o])) {
                for (let i in opciones[o]) {
                    formData.append(o, opciones[o][i])
                }
            } else {
                formData.append(o, opciones[o])
            }
        })
        return formData
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalImportar(false))
    }

    onChangeSelectProyecto (e) {
        const { proyectos, dispatch } = this.props
        if (e.value !== this.state.proyectoSeleccionado) {
            dispatch(actions.resetElementosImportar())
        }
        this.setState({
            proyectoSeleccionado: e.value
        })
        if (!proyectos.filter(p => p.id === e.value)[0].agrupaciones) {
            const fields = ['elementos_completos', 'id', 'nombre', 'orden', 'proyecto']
            dispatch(fetchAgrupacionesProyecto({proyecto: e.value}, fields, true))
        }
    }

    getAgrupacionesPorCapas () {
        const { proyectos } = this.props
        let capas = []
        const agrupaciones =  proyectos.filter(p => p.id === this.state.proyectoSeleccionado)[0].agrupaciones
        agrupaciones.forEach(a => {
            if (capas.map(c => c.id).includes(a.elementos_completos[0].bloque.capa.id)) {
                const capa = capas.filter(c => c.id === a.elementos_completos[0].bloque.capa.id)[0]
                capa.agrupaciones.push(a)
            } else {
                capas.push(a.elementos_completos[0].bloque.capa)
                capas[capas.length - 1].agrupaciones = [a]
            }
        })
        return capas.sort((a, b) => a.orden - b.orden)
    }

    onChangeElemento (e, elemento) {
        const { dispatch } = this.props
        if (e.target.checked) {
            dispatch(actions.addElementoImportar(elemento))
        } else {
            dispatch(actions.removeElementoImportar(elemento))
        }
    }

    onChangeAgrupacion (e, agrupacion) {
        const { dispatch, elementosImportar } = this.props
        if (e.target.checked) {
            for (let i in agrupacion.elementos_completos) {
                if (!elementosImportar.map(e => e.id).includes(agrupacion.elementos_completos[i].id)) {
                    dispatch(actions.addElementoImportar(agrupacion.elementos_completos[i]))
                }
            }
        } else {
            for (let i in agrupacion.elementos_completos) {
                if (elementosImportar.map(e => e.id).includes(agrupacion.elementos_completos[i].id)) {
                    dispatch(actions.removeElementoImportar(agrupacion.elementos_completos[i]))
                }
            }
        }
    }

    onChangeCapa (e, capa) {
        const { dispatch, elementosImportar } = this.props
        if (e.target.checked) {
            capa.agrupaciones.forEach(a => {
                a.elementos_completos.forEach(e => {
                    if (!elementosImportar.map(e => e.id).includes(e.id)) {
                        dispatch(actions.addElementoImportar(e))
                    }
                })
            })
        } else {
            capa.agrupaciones.forEach(a => {
                a.elementos_completos.forEach(e => {
                    if (elementosImportar.map(e => e.id).includes(e.id)) {
                        dispatch(actions.removeElementoImportar(e))
                    }
                })
            })
        }
    }

    isAgrupacionChecked (agrupacion) {
        const { elementosImportar } = this.props
        for (let i in agrupacion.elementos_completos) {
            if (!elementosImportar.map(e => e.id).includes(agrupacion.elementos_completos[i].id)) {
                return false
            }
        }
        return true
    }

    isCapaChecked (capa) {
        const { elementosImportar } = this.props
        for (let i in capa.agrupaciones) {
            for (let j in capa.agrupaciones[i].elementos_completos) {
                if (!elementosImportar.map(e => e.id).includes(capa.agrupaciones[i].elementos_completos[j].id)) {
                    return false
                }
            }
        }
        return true
    }

    render () {
        const {
            t, show, proyectos, proyectosPendientes, ultimasAgrupacionesPendiente, elementosImportar
        } = this.props

        const options = proyectos.map(p => { return { value: p.id, label: p.nombre } })
        return (
            <ModalEditor
                footer show={show}
                titulo={t('Importar elementos')}
                onHide={this.onHide.bind(this)}
                onClickAceptar={this.onClickAceptar.bind(this)}
                onClickCancelar={this.onHide.bind(this)}
            >
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label><Trans>Proyecto:</Trans></label>
                            <Select
                                className='form-group importar__selector'
                                name='proyecto'
                                isSearchable
                                isClearable
                                isLoading={proyectosPendientes}
                                menuPosition='fixed'
                                placeholder={t('Escoge el proyecto del que quieras importar elementos')}
                                options={options}
                                onChange={this.onChangeSelectProyecto.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                {this.state.proyectoSeleccionado &&
                    <>
                        {proyectos.filter(p => p.id === this.state.proyectoSeleccionado)[0].agrupaciones
                            ? (
                                <div className='row'>
                                    <div className='col-12'>
                                        <ul className='list-group list-group-flush'>
                                            {this.getAgrupacionesPorCapas().map(c =>
                                                <li className='list-group-item' key={c.id}>
                                                    <div className='form-group row'>
                                                        <div className='col-1'>
                                                            <input
                                                                id={'check-capa-' + c.id}
                                                                type='checkbox'
                                                                onChange={e => {this.onChangeCapa(e, c)}}
                                                                checked={this.isCapaChecked(c)}
                                                            />
                                                        </div>
                                                        <div className='col-11'>
                                                            <label htmlFor={'check-capa-' + c.id}>
                                                                <strong>{c.nombre}</strong>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <ul className='list-group list-group-flush'>
                                                        {c.agrupaciones.map(a =>
                                                            <React.Fragment key={a.id}>
                                                                {a.elementos_completos.length === 1
                                                                    ? (
                                                                        <li className='list-group-item'>
                                                                            <div className='form-group row'>
                                                                                <div className='col-1'>
                                                                                    <input
                                                                                        id={'check-elemento-' + a.elementos_completos[0].id}
                                                                                        type='checkbox'
                                                                                        onChange={e => {this.onChangeElemento(e, a.elementos_completos[0])}}
                                                                                        checked={elementosImportar.map(e => e.id).includes(a.elementos_completos[0].id)}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-11'>
                                                                                    <label
                                                                                        htmlFor={'check-elemento-' + a.elementos_completos[0].id}
                                                                                    >
                                                                                        {a.elementos_completos[0].nombre}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    ) : (
                                                                        <li className='list-group-item'>
                                                                            <div className='form-group row'>
                                                                                <div className='col-1'>
                                                                                    <input
                                                                                        id={'check-agrup-' + a.id}
                                                                                        type='checkbox'
                                                                                        onChange={e => {this.onChangeAgrupacion(e, a)}}
                                                                                        checked={this.isAgrupacionChecked(a)}
                                                                                    />
                                                                                </div>
                                                                                <div className='col-11'>
                                                                                    <label
                                                                                        htmlFor={'check-agrup-' + a.id}
                                                                                    >
                                                                                        <strong>
                                                                                            {a.nombre}
                                                                                        </strong>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <ul className='list-group list-group-flush'>
                                                                                {a.elementos_completos.map(e =>
                                                                                    <li
                                                                                        className='list-group-item'
                                                                                        key={e.id}
                                                                                    >
                                                                                        <div className='form-group row'>
                                                                                            <div className='col-1'>
                                                                                                <input
                                                                                                    id={'check-elemento-' + e.id}
                                                                                                    type='checkbox'
                                                                                                    onChange={ev => {this.onChangeElemento(ev, e)}}
                                                                                                    checked={elementosImportar.map(el => el.id).includes(e.id)}
                                                                                                />
                                                                                            </div>
                                                                                            <div className='col-11'>
                                                                                                <label
                                                                                                    htmlFor={'check-elemento-' + e.id}
                                                                                                >
                                                                                                    {e.nombre}
                                                                                                </label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )}
                                                                            </ul>
                                                                        </li>
                                                                    )}
                                                            </React.Fragment>
                                                        )}
                                                    </ul>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>

                            ) : (
                                <>
                                    {ultimasAgrupacionesPendiente &&
                                        <Row>
                                            <Col className='col-spinner'>
                                                <div className='spinner-respuesta-recorrido'>
                                                    <Spinner
                                                        className='spinner-respuesta-servidor'
                                                        animation="border"
                                                        role="status"
                                                    >
                                                        <span className="sr-only">Cargando...</span>
                                                    </Spinner>
                                                </div>
                                            </Col>
                                        </Row>}
                                </>
                            )}
                    </>

                }
            </ModalEditor>
        )
    }
}

const mapStateToProps = state => ({
    proyecto: state.proyecto.data,
    proyectosError: state.proyectos.error,
    proyectos: state.proyectos.list,
    proyectosPendientes: state.proyectos.pendiente,
    usuario: state.usuario.data,
    ultimasAgrupacionesPendiente: state.ultimasAgrupacionesCargadas.pendiente,
    elementosImportar: state.editor.elementosImportar
})

export default withTranslation()(connect(mapStateToProps)(ModalImportarElementos))
