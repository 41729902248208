
import {API_BASE_URL} from "../constants/api";
import {actions} from "../actions/puntos";
import {actions as actionsComun} from "../actions/comun";
import {actions as actionsError } from "../actions/error";
import {actions as actionsRecorridos} from "../actions/recorridos";
import {fetchAgrupacion} from "./agrupaciones";
import getCsrfToken from '../lib/csrf'
import validaFiltrosElemento from './utils'
import {creaNuevoPk} from "./recorridos";


export function fetchPuntos(params, fields=[]) {
    let url = validaFiltrosElemento(API_BASE_URL + 'punto/', params)

    if (fields && fields.length) {
        url = url + '&fields=' + fields.join(',')
    }

    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.fetchPuntosPendiente());
        fetch(url)
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);
                dispatch(actions.fetchPuntosCorrecto(res));
                dispatch(actionsComun.restaPeticion());
            })
            .catch(error => {
                dispatch(actions.fetchPuntosError(error));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function creaNuevoPunto(opciones, formData, sector, distancia, subido) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.puntoNuevoPendiente());
        return fetch(API_BASE_URL + 'punto/', {
            method: 'POST',
            headers: formData
                ? {
                    'X-CSRFToken': getCsrfToken()
                } : {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCsrfToken()
                },
            credentials: 'include',
            body: formData ? formData : JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.puntoNuevoElemento(data));
                            dispatch(actionsComun.restaPeticion());
                            dispatch(fetchAgrupacion(data.agrupacion));
                            if (subido) {
                                dispatch(actions.resetNuevoPunto())
                                dispatch(actions.addPunto(data))
                            }
                            if(sector && !distancia){
                                dispatch(actionsRecorridos.addPdiCreadoSector(data, sector))
                            } else if(distancia) {
                                dispatch(creaNuevoPk({
                                    sector: sector.id,
                                    punto: data.id,
                                    distancia_oficial: distancia,
                                    publicado: true
                                }, sector.recorrido));
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function actualizaPunto(id, opciones, formData) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.puntoActualizadoPendiente());
        fetch(API_BASE_URL + 'punto/' + id + '/', {
            method: 'PUT',
            headers: formData ? {
                'X-CSRFToken': getCsrfToken()
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: formData ? formData : JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.puntoActualizandoCorrecto(data));
                            dispatch(actionsComun.restaPeticion());
                            dispatch(actions.removePunto(data));
                            dispatch(actions.addPunto(data));
                        })
                }
            })
            .catch(err => {
                console.log(err);
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });

    }
}

export function eliminarPunto(punto, sector) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.puntoEliminadoPendiente(punto));
        fetch(API_BASE_URL + 'punto/' + punto.id + '/', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
        })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                            if (sector) {
                               dispatch(actionsRecorridos.removePdiCreadoSector(punto, sector));
                            }
                        })
                } else {
                    dispatch(actions.puntoEliminadoCorrecto(punto));
                    dispatch(actionsComun.restaPeticion());
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}
