import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import * as turf from "@turf/turf";
import * as L from "leaflet";
import {withTranslation} from "react-i18next";

import {NuevoPunto} from "./NuevoPunto";
import {getDuplicando, getElementosVisibles, getObjetoSeleccionado} from "../reducers/editor";
import {getPuntoActualizando} from "../reducers/puntos";
import {actualizaPunto, eliminarPunto} from "../fetchactions/puntos";
import {actions} from "../actions/puntos";
import {actions as actionsEditor} from "../actions/editor";

import {getTrayectos} from "../reducers/trayectos";
import {Icon} from "../lib/leaflet.awesome-markers-svg";
import {getServicios} from "../reducers/servicios";

class NuevoPuntoSobreTrayecto extends NuevoPunto{

    onDrawingEnd(e) {
        const {t, elemento, actualizaPunto, puntoActualizando, setVisibleModalPropAvanzada} = this.props;
        if(this.pointRef.current) {
            const marker = this.pointRef.current.leafletElement;
            const puntoFijar = this.isInDistanciaSeguridadAlgunTrayecto(e);
            if(puntoFijar) {
                marker.disableEdit();
                elemento.trayecto_ancla = puntoFijar.trayecto.id
                actualizaPunto(elemento.id, {
                    nombre: puntoActualizando && puntoActualizando.id === elemento.id ?
                        puntoActualizando.nombre : elemento.nombre,
                    bloque: elemento.bloque.id,
                    icono: elemento.icono.id,
                    coordenadas: puntoFijar.punto.geometry,
                    trayecto_ancla: puntoFijar.trayecto.id
                });
                setVisibleModalPropAvanzada(true);
                //resetNuevoPunto();
            } else {
                alert(t('los') + ' ' + elemento.nombre + ' ' + t('msg-pt-sobre-trayecto'));
                marker.disableEdit();
                marker.enableEdit().startDrawing();
            }
        }
    }

    elementoIsVisible(elemento){
        const {elementosVisibles} = this.props;
        return elementosVisibles.filter(elementoVisible => elementoVisible.id === elemento.id).length > 0;
    }

    isInDistanciaSeguridadAlgunTrayecto(e) {
        const {trayectos} = this.props;
        const map = this.props.map.current.leafletElement;
        const puntoClickPantalla = map.latLngToContainerPoint(e.target._latlng);
        let distMenor = Infinity;
        let puntoMasCerca = null;
        const trayectosVisibles = trayectos.filter(t => this.elementoIsVisible(t));
        for(let i in trayectosVisibles) {
            const puntoCercano = turf.nearestPointOnLine(trayectosVisibles[i].coordenadas, [e.target._latlng.lng, e.target._latlng.lat]);
            const puntoPantalla = map.latLngToContainerPoint(L.latLng(puntoCercano.geometry.coordinates[1],
                puntoCercano.geometry.coordinates[0]));
            const dist = Math.sqrt((puntoClickPantalla.x - puntoPantalla.x) * (puntoClickPantalla.x - puntoPantalla.x)
                + (puntoClickPantalla.y - puntoPantalla.y) * (puntoClickPantalla.y - puntoPantalla.y));
            puntoMasCerca = (dist < distMenor && dist < 15) ? {punto: puntoCercano, trayecto: trayectosVisibles[i]} : puntoMasCerca;
            distMenor = distMenor > dist ? dist: distMenor;
        }
        return puntoMasCerca;
    }

    getIcon() {
        const {elemento, servicios} = this.props;
        if(elemento.bloque.pdi_sobre_trayecto && !elemento.bloque.es_pk && elemento.servicios.length === 1) {
            const servicio = elemento.servicios[0].id ?
                elemento.servicios[0] : servicios.filter(srv => srv.id === elemento.servicios[0])[0];
            return new Icon({
                iconSize: [servicio.icono.fondo.ancho, servicio.icono.fondo.alto],
                iconAnchor: [servicio.icono.fondo.centro_x, servicio.icono.fondo.centro_y],
                icon: servicio.icono.icono,
                iconColor: servicio.color_icono,
                prefix: servicio.icono.prefijo_fuente,
                markerColor: servicio.color_fondo,
                svg: servicio.icono.fondo.svg,
                fontSizeIcon: servicio.icono.fondo.tamano_fuente_icono,
                classFondo: ' icono-' + servicio.icono.fondo.nombre
            });
        } else {
            return new Icon({
                iconSize: [elemento.icono.fondo.ancho, elemento.icono.fondo.alto],
                iconAnchor: [elemento.icono.fondo.centro_x, elemento.icono.fondo.centro_y],
                icon: elemento.icono.icono,
                iconColor: elemento.color_icono,
                prefix: elemento.icono.prefijo_fuente,
                markerColor: elemento.color,
                svg: elemento.icono.fondo.svg,
                fontSizeIcon: elemento.icono.fondo.tamano_fuente_icono,
                classFondo: ' icono-' + elemento.icono.fondo.nombre
            });
        }
    }
}

const mapStateToProps = state => ({
    duplicando: getDuplicando(state),
    objetoSeleccionado: getObjetoSeleccionado(state),
    puntoActualizando: getPuntoActualizando(state),
    trayectos: getTrayectos(state),
    servicios: getServicios(state),
    elementosVisibles: getElementosVisibles(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    actualizaPunto: actualizaPunto,
    resetNuevoPunto: actions.resetNuevoPunto,
    setDuplicando: actionsEditor.setDuplicando,
    addPunto: actions.addPunto,
    setObjetoSeleccionado: actionsEditor.objetoSeleccionado,
    removeCapaColapsada: actionsEditor.removeCapaColapsada,
    eliminarPunto: eliminarPunto,
    setVisibleModalPropAvanzada: actionsEditor.setVisibleModalPropAvanzadas,
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NuevoPuntoSobreTrayecto));
