import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getObjetoSeleccionado} from "../reducers/editor";
import BtnBarraHerramientas from "./BtnBarraHerramientas";
import {faCut} from "@fortawesome/free-solid-svg-icons";

class BtnCortar extends Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this)
    }

    onClick(){
        const {objetoSeleccionado} = this.props;
        objetoSeleccionado.cortar();
    }

    render() {
        const {t} = this.props;
        return <BtnBarraHerramientas onClick={this.onClick} className={this.props.className} icon={faCut}
                                     titulo={t('cortar')} />
    }
}

const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state)
});

export default withTranslation()(connect(mapStateToProps, null)(BtnCortar))
