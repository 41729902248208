import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/poligonos";

export function poligonosReducer(state = initialState.poligonos, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_POLIGNOS_CORRECTO:
            return {
                ...state,
                list: action.poligonos,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_POLIGONOS_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_POLIGONOS_PENDIENTE:
            return {
                ...state,
                pendiente: true
            };
        case ACTION_TYPES.ADD_POLIGONO:
            return {
                ...state,
                list: state.list.concat(action.poligono)
            };
        case ACTION_TYPES.REMOVE_POLIGONO:
        case ACTION_TYPES.POLIGONO_ELIMINADO_CORRECTO:
            return {
                ...state,
                list: state.list.filter(poligono => poligono.id !== action.poligono.id)
            };
        case ACTION_TYPES.ACTUALIZA_COORDS_POLIGONO:
            const indexLinea = state.list.map(linea => linea.id).indexOf(action.idPoligono);
            let list = state.list.slice();
            list[indexLinea].coordenadas = action.coordenadas;
            return {
                ...state,
                list: list
            };
        case ACTION_TYPES.SET_COLOR_POLIGONO: {
            const ip = state.list.map(pl => pl.id).indexOf(action.poligono.id);
            let list = JSON.parse(JSON.stringify(state.list));
            list[ip].color = action.color;
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.SET_NOMBRE_POLIGONO: {
            const ip = state.list.map(pl => pl.id).indexOf(action.poligono.id);
            let list = JSON.parse(JSON.stringify(state.list));
            list[ip].nombre = action.nombre
            return {
                ...state,
                list
            }
        }
        default:
            return {
                ...state
            }
    }
}

export function poligonoNuevoReducer(state = initialState.poligonoNuevo, action) {
    switch (action.type) {
        case ACTION_TYPES.POLIGONO_NUEVO_CORRECTO:
            return {
                ...state,
                elemento: action.poligono,
                pendiente: false
            };
        case ACTION_TYPES.POLIGONO_NUEVO_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        case ACTION_TYPES.POLIGONO_NUEVO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                error: false,
                elemento: false
            };
        case ACTION_TYPES.RESET_NUEVO_POLIGNO:
            return {
                ...state,
                pendiente: false,
                error: false,
                elemento: null
            };
        default:
            return {
                ...state
            };
    }
}

export function poligonoActualizandoReducer(state = initialState.poligonoActualizando, action) {
    switch (action.type) {
        case ACTION_TYPES.POLIGONO_ACTUALIZANDO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                elemento: action.poligono
            };
        case ACTION_TYPES.POLIGONO_ACTUALIZANDO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.POLIGONO_ACTUALIZANDO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                error: false,
                elemento: null
            };
        default:
            return {
                ...state
            };
    }
}

export function poligonoEliminadoReducer(state = initialState.poligonoEliminado, action) {
    switch (action.type) {
        case ACTION_TYPES.POLIGONO_ELIMINADO_PENDIENTE:
            return {
                ...state,
                pediente: true,
                error: false,
                elemento: action.poligono,
                correcto: false
            };
        case ACTION_TYPES.POLIGONO_ELIMINADO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error,
                elemento: null
            };
        case ACTION_TYPES.POLIGONO_ELIMINADO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                elemento: action.poligono
            };
        default:
            return {
                ...state
            };
    }
}


export const getPoligonos = state => state.poligonos.list;
export const getPoligonosError = state => state.poligonos.error;
export const getPoligonosPendiente = state => state.poligonos.pendiente;
export const getPoligonoNuevo = state => state.poligonoNuevo.elemento;
export const getPoligonoNuevoError = state => state.poligonoNuevo.error;
export const getPoligonoNuevoPendiente = state => state.poligonosPendiente.pendiente;
export const getPoligonoActualizando = state => state.poligonoActualizando.elemento;
export const getPoligonoActualizandoError = state => state.poligonoActualizando.error;
export const getPoligonoActualizandoPendiente = state => state.poligonoActualizando.pendiente;
export const getPoligonoEliminado = state => state.poligonoEliminado.elemento;
export const getPoligonoEliminadoCorrecto = state => state.poligonoEliminado.correcto;
export const getPoligonoEliminadoError = state => state.poligonoEliminado.error;
export const getPoligonoEliminadoPendiente = state => state.poligonoEliminado.pendiente;
