import React, { Component } from 'react'
import { bindActionCreators } from "redux"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Form } from "react-bootstrap"

import { getRecorridos } from "../../reducers/recorridos"

import './FiltroVisibilidad.css'
import { actions } from "../../actions/editor"
import { getLineas } from "../../reducers/lineas"
import { getTrayectos } from "../../reducers/trayectos"
import { getPuntos } from "../../reducers/puntos"
import { getPoligonos } from "../../reducers/poligonos"
import { getAgrupacionesProyecto } from "../../reducers/agrupaciones"
import { getElementosVisibles } from "../../reducers/editor"
import { TIPOS_MAPAS_NOMBRES } from "../../constants/state"

class FiltroVisibilidad extends Component {
    constructor (props) {
        super(props)
        this.inputRef = React.createRef()

        this.onChange = this.onChange.bind(this)
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        const { elementosVisibles, trayectos, puntos, lineas, poligonos, setFiltroVisibleActivo } = this.props
        const input = this.inputRef.current
        if (parseInt(input.value)) {
            if (!this.isElementosRecorrido(parseInt(input.value))) {
                input.value = 'p'
                setFiltroVisibleActivo('p')
            }
        } else {
            if (elementosVisibles.length === 0) input.value = 'n'
            else if(elementosVisibles.length === (trayectos.length + puntos.length + lineas.length + poligonos.length)) {
                input.value = 't'
            } else if (this.isElementosTiposMapa()) input.value = this.isElementosTiposMapa()
            else input.value = 'p'
            setFiltroVisibleActivo(input.value)
        }
    }

    isElementosTiposMapa () {
        const { elementosVisibles, tiposMapas } = this.props
        for (let i in tiposMapas) {
            for (let j in elementosVisibles) {
                if (tiposMapas[i].nombre === TIPOS_MAPAS_NOMBRES.RECORRIDOS) {
                    const el = elementosVisibles[j]
                    if (el.bloque.tipo !== 'tr' && !el.bloque.posible_pdi_proyecto && !el.bloque.pdi_sobre_trayecto) {
                        break
                    }
                } else {
                    if (!tiposMapas[i].capa_permitida.map(c => c.id).includes(elementosVisibles[i].bloque.capa)) {
                        break
                    }
                }
            }
            return tiposMapas[i].nombre
        }
        return false
    }

    isElementosRecorrido (idRecorrido) {
        const { recorridos, elementosVisibles } = this.props
        const recorrido = recorridos.filter(recorrido => recorrido.id === parseInt(idRecorrido))[0]
        for (let i in recorrido.sectores) {
            for (let j in recorrido.sectores[i].esquemas) {
                if(elementosVisibles.map(elemento =>
                    elemento.id).indexOf(recorrido.sectores[i].esquemas[j].trayecto.id) === -1)
                    return false
            }
        }
        for (let i in recorrido.sectores) {
            for (let j in recorrido.sectores[i].puntos_km) {
                if(elementosVisibles.map(elemento =>
                    elemento.id).indexOf(recorrido.sectores[i].puntos_km[j].punto.id ?
                    recorrido.sectores[i].puntos_km[j].punto.id : recorrido.sectores[i].puntos_km[j].punto) === -1)
                    return false
            }

            for(let j in recorrido.sectores[i].pdis_recorrido) {
                if(elementosVisibles.map(el => el.id).indexOf(recorrido.sectores[i].pdis_recorrido[j].id ?
                    recorrido.sectores[i].pdis_recorrido[j].id : recorrido.sectores[i].pdis_recorrido[j]) === -1)
                    return false
            }
        }
        for (let i in elementosVisibles) {
            if (elementosVisibles[i].bloque.tipo === 'tr') {
                let estaEnRecorrido = false
                for (let j in recorrido.sectores) {
                    if (recorrido.sectores[j].esquemas.map(esquema =>
                        esquema.trayecto.id).indexOf(elementosVisibles[i].id) !== -1)
                        estaEnRecorrido = true
                }
                if (!estaEnRecorrido) return false
            } else if (!elementosVisibles[i].pdi_proyecto) {
                let puntosKmRec = []
                let pdisRecorrido = []
                for (let j in recorrido.sectores) {
                    puntosKmRec = puntosKmRec.concat(recorrido.sectores[j].puntos_km)
                    pdisRecorrido = pdisRecorrido.concat(recorrido.sectores[j].pdis_recorrido)
                }
                if (puntosKmRec.map(pk =>
                    pk.punto.id ? pk.punto.id : pk.punto).indexOf(elementosVisibles[i].id) === -1 &&
                    pdisRecorrido.map(p => p.id ? p.id : p).indexOf(elementosVisibles[i].id) === -1
                ) {
                    return false
                }
            }
        }
        return true
    }

    ponVisibleRecorrido (idRecorrido) {
        const { recorridos, removeAllElementosVisibles, addElementoVisible, agrupaciones } = this.props
        let elementos = []
        agrupaciones.forEach(a => {
           elementos = elementos.concat(a.elementos)
        })
        const recorrido = recorridos.filter(recorrido => recorrido.id === parseInt(idRecorrido))[0]
        removeAllElementosVisibles()
        for (let i in recorrido.sectores) {
            for (let j in recorrido.sectores[i].esquemas) {
                addElementoVisible(recorrido.sectores[i].esquemas[j].trayecto)
            }
        }
        for (let i in recorrido.sectores) {
            for (let j in recorrido.sectores[i].puntos_km) {
                const ix = elementos.map(el => el.id).indexOf(recorrido.sectores[i].puntos_km[j].punto.id
                    ? recorrido.sectores[i].puntos_km[j].punto.id : recorrido.sectores[i].puntos_km[j].punto)
                addElementoVisible(elementos[ix])
            }
            for (let j in recorrido.sectores[i].pdis_recorrido) {
                const ix = elementos.map(el => el.id).indexOf(recorrido.sectores[i].pdis_recorrido[j].id
                    ? recorrido.sectores[i].pdis_recorrido[j].id : recorrido.sectores[i].pdis_recorrido[j])
                addElementoVisible(elementos[ix])
            }
        }
        for (let i in agrupaciones) {
            for (let j in agrupaciones[i].elementos) {
                if (agrupaciones[i].elementos[j].pdi_proyecto) addElementoVisible(agrupaciones[i].elementos[j])
            }
        }
    }

    ponVisibleTipoMapa (nombreMapa) {
        const { addElementoVisible, tiposMapas, agrupaciones, removeAllElementosVisibles } = this.props
        let elementos = []
        agrupaciones.forEach(a => {
            elementos = elementos.concat(a.elementos)
        })
        removeAllElementosVisibles()
        switch (nombreMapa) {
            case TIPOS_MAPAS_NOMBRES.RECORRIDOS:
                elementos.forEach(e => {
                    if (e.bloque.tipo === 'tr' || e.bloque.posible_pdi_proyecto || e.bloque.pdi_sobre_trayecto) {
                        addElementoVisible(e)
                    }
                })
                break
            default:
                elementos.forEach(e => {
                    const tipoMapa = tiposMapas.filter(tm => tm.nombre === nombreMapa)
                    if (tipoMapa.length) {
                        elementos.forEach(e => {
                            if (tipoMapa[0].capa_permitida.map(c => c.id).includes(e.bloque.capa.id)) {
                                addElementoVisible(e)
                            }
                        })
                    }
                })
        }
    }

    onChange(e) {
        const {
            addAllElementosVisibles, setFiltroVisibleActivo, removeAllElementosVisibles, trayectos, lineas, puntos,
            poligonos
        } = this.props
        setFiltroVisibleActivo(e.target.value)
        switch (e.target.value) {
            case 't':
                const elementos = trayectos.concat(lineas, puntos, poligonos)
                addAllElementosVisibles(elementos)
                break
            case 'n':
                removeAllElementosVisibles()
                break
            case 'p':
                break
            default:
                if (isNaN(e.target.value)) {
                    this.ponVisibleTipoMapa(e.target.value)
                } else {
                    this.ponVisibleRecorrido(e.target.value)
                }
                break
        }
    }

    render() {
        const { t, recorridos, tiposMapas } = this.props
        return (
            <Form.Group className='filtro-visibilidad' controlId='select-filtro-visibilidad' >
                <Form.Control as='select' onChange={this.onChange} defaultValue='t' ref={this.inputRef}>
                    <option value='t'>{t('todos')}</option>
                    <option value='n'>{t('ninguno')}</option>
                    <option value='p'>{t('personalizado')}</option>
                    {tiposMapas.map(tm =>
                        <option key={tm.nombre} value={tm.nombre}>
                            {t('Disponible en')}: {tm.titulo}
                        </option>
                    )}
                    {recorridos.map(recorrido =>
                        <option key={recorrido.id} value={recorrido.id}>{recorrido.nombre}</option>
                    )}
                </Form.Control>
            </Form.Group>
        )
    }
}

const mapStateToProps = state => ({
    recorridos: getRecorridos(state),
    trayectos: getTrayectos(state),
    lineas: getLineas(state),
    puntos: getPuntos(state),
    poligonos: getPoligonos(state),
    agrupaciones: getAgrupacionesProyecto(state),
    elementosVisibles: getElementosVisibles(state),
    tiposMapas: state.tiposMapas.data
});

const mapDispatchToProps = dispatch => bindActionCreators({
    addAllElementosVisibles: actions.addAllElementosVisibles,
    removeAllElementosVisibles: actions.removeAllElementosVisibles,
    addElementoVisible: actions.addElementoVisible,
    setFiltroVisibleActivo: actions.setFiltroVisibleActivo
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FiltroVisibilidad));
