export const ACTION_TYPES = {
    FETCH_CAPAS_FONDO_PENDIENTE: 'FETCH_CAPAS_FONDO_PENDIENTE',
    FETCH_CAPAS_FONDO_CORRECTO: 'FETCH_CAPAS_FONDO_CORRECTO',
    FETCH_CAPAS_FONDO_ERROR: 'FETCH_CAPAS_FONDO_ERROR'
}

function fetchCapasFondoPendiente() {
    return {
        type: ACTION_TYPES.FETCH_CAPAS_FONDO_PENDIENTE
    }
}

function fetchCapasFondoCorrecto(capasFondo) {
    return {
        type: ACTION_TYPES.FETCH_CAPAS_FONDO_CORRECTO,
        capasFondo: capasFondo
    }
}

function fetchCapasFondoError(err) {
    return {
        type: ACTION_TYPES.FETCH_CAPAS_FONDO_ERROR,
        error: err
    }
}

export const actions = {
    fetchCapasFondoPendiente: fetchCapasFondoPendiente,
    fetchCapasFondoCorrecto: fetchCapasFondoCorrecto,
    fetchCapasFondoError: fetchCapasFondoError
}