import React, { Component } from 'react'
import { withTranslation } from "react-i18next"
import { getObjetoSeleccionado } from "../reducers/editor"
import { connect } from "react-redux"

import BtnBarraHerramientas from "./BtnBarraHerramientas"

class BtnContinuar extends Component {
    constructor (props) {
        super(props)

        this.onClick = this.onClick.bind(this)
    }

    onClick () {
        const { objetoSeleccionado } = this.props
        objetoSeleccionado.continuar()
    }

    render() {
        const { t } = this.props
        return (
            <BtnBarraHerramientas
                onClick={this.onClick}
                className={this.props.className}
                classIcon='rmp-ico-continuar'
                titulo={t('continuar')}
            />
        )
    }
}

const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state)
});

export default withTranslation()(connect(mapStateToProps, null)(BtnContinuar))
