import {API_BASE_URL} from '../constants/api';
import {actions as actionsProyectos} from '../actions/proyectos';
import {actions as actionsComun} from '../actions/comun';
import getCsrfToken from "../lib/csrf";
import {actions as actionsError} from "../actions/error";

export default function fetchProyectos(params) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actionsProyectos.fetchPoryectosPending());
        fetch(API_BASE_URL + 'proyecto/' + (params ? '?' + objectToQueryString(params) : ''), {
            credentials: 'include',
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) {
                    throw(res.error)
                }
                dispatch(actionsProyectos.fetchPoryectosSuccess(res));
                dispatch(actionsComun.restaPeticion());
                return res;
            })
            .catch(error => {
                dispatch(actionsProyectos.fetchProyectosError(error));
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function fetchProyecto(id, fields=[]) {
    return dispatch => {
        let url = new URL(API_BASE_URL + 'proyecto/')
        const params = {'id': id}
        if (fields && fields.length) {
            params['fields'] = fields.join(',')
        }
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )

        dispatch(actionsComun.sumaPeticion());
        dispatch(actionsProyectos.fetchProyectoPendiente());
        fetch(url, {
            credentials: 'include',
        })
            .then(res => res.json())
            .then(res=> {
                if(res.error) {
                    throw(res.error);
                }
                if (res && !res.length) {
                    dispatch(actionsProyectos.fetchProyectoError('No encontrado'));
                    dispatch(actionsComun.restaPeticion());
                } else {
                    dispatch(actionsProyectos.fetchProyectoCorrecto(res[0]));
                    dispatch(actionsComun.restaPeticion());
                }
                return res;
            })
            .catch(error => {
                dispatch(actionsProyectos.fetchProyectoError(error));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function actualizaProyecto(idProyecto, opciones, config={}) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actionsProyectos.proyectoActualizandoPendiente());
        fetch(API_BASE_URL + 'proyecto/' + idProyecto + '/', {
            method: 'PUT',
            headers: {
                "Content-Type": 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        }).then(res => {
                if(res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        });
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actionsProyectos.proyectoActualizandoCorrecto(data));
                            dispatch(actionsComun.restaPeticion());
                        });
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsProyectos.proyectoActualizandoError(err));
                dispatch(actionsComun.restaPeticion());
            })

    }
}


export function actualizaProyectoFormData(idProyecto, formData) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actionsProyectos.proyectoActualizandoPendiente());
        fetch(API_BASE_URL + 'proyecto/' + idProyecto + '/', {
            method: 'PUT',
            headers: {
                // "Content-Type": "multipart/form-data",
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: formData
        }).then(res => {
            if(res.status !== 200) {
                return res.json()
                    .then(data => {
                        dispatch(actionsError.hasError(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            } else {
                return res.json()
                    .then(data => {
                        dispatch(actionsProyectos.proyectoActualizandoCorrecto(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            }
        })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsProyectos.proyectoActualizandoError(err));
                dispatch(actionsComun.restaPeticion());
            })

    }
}


function objectToQueryString(obj) {
    return Object.keys(obj).map(key => key + '=' + encodeURIComponent(obj[key])).join('&');
}
