import React, {Component} from 'react';
import {Polyline} from "react-leaflet";
import * as L from "leaflet";
import 'leaflet-geometryutil';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as turf from "@turf/turf";
import {withTranslation} from "react-i18next";
import invert from 'invert-color';
import 'leaflet.heightgraph';

import 'leaflet.heightgraph/dist/L.Control.Heightgraph.min.css'

import {getElementoRecienSubido, getObjetoSeleccionado, getUniendo} from "../reducers/editor";
import {actions} from "../actions/editor";
import {actions as actionsTrayectos} from "../actions/trayectos";
import {actualizaTrayecto, creaNuevoTrayecto, eliminarTrayecto} from "../fetchactions/trayectos";
import TrayectoFlechas from "./TrayectoFlechas";
import {getProyecto} from "../reducers/proyectos";
import {getTrayectoActualizando} from "../reducers/trayectos";
import {getRecorridos} from "../reducers/recorridos";

import './perfil.css';

require('leaflet-editable');

class Trayecto extends Component {

    constructor(props) {
        super(props);
        this.polyRef = React.createRef();
        this.montado = true;
        this.type = 'tr';
        this.eliminado = false;
        this.encima = false;
        this.continuando = false;
        this.cortando = false;

        this.onClick = this.onClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onMapMouseMove = this.onMapMouseMove.bind(this);
        this.onDrawingEnd = this.onDrawingEnd.bind(this);
        this.onMapSecondClick = this.onMapSecondClick.bind(this);
        this.onDrawigEndRect = this.onDrawigEndRect.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onContextmenu = this.onContextmenu.bind(this);
    }

    componentDidMount() {
        const {addObjetoDibujado, getObjetoSeleccionado, elemento} = this.props;
        addObjetoDibujado(this);
        if(getObjetoSeleccionado && getObjetoSeleccionado.type === 'tr' && getObjetoSeleccionado.props.elemento.id === elemento.id) {
            this.seleccionar(true);
        }
        this.montado = true;
        const map = this.props.map.current.leafletElement;
        map.on('mousemove', this.onMapMouseMove);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            trayectoActualizando, elementoRecienSubido, elemento, setElementoRecienSubido, setVisibleModalPropAvanzada,
            setMuestraAvisoDistancia
        } = this.props;
        if (this.cortando && trayectoActualizando) {
            this.cortando = false;
            const map = this.props.map.current.leafletElement;
            map.once('click', this.onMapClick);
        }
        if (this.seleccionado) {
            const polyline = this.polyRef.current.leafletElement;
            polyline.disableEdit();
            polyline.enableEdit();
        }
        if (elementoRecienSubido && elementoRecienSubido.id === elemento.id) {
            this.seleccionar();
            setElementoRecienSubido(null);
            setMuestraAvisoDistancia(true);
            setVisibleModalPropAvanzada(true);
        }
    }

    duplicar(paralelo, negativo) {
        if(this.cortando) this.cancelaRecorte()
        if(this.continuando) this.cancelaContinuar()
        const {elemento, t, proyecto, setDuplicando, creaNuevoTrayecto} = this.props
        let line = turf.lineString(elemento.coordenadas.coordinates)
        let offsetLine = turf.lineOffset(line, negativo ? -3 : 3, {units: 'meters'})
        let sufijo = paralelo ? t('paralelo').toLowerCase() + (negativo ? '-':'+') : t('duplicado').toLowerCase()
        const nombre = elemento.nombre + '-' + sufijo
        let options = {
            nombre: nombre.length < 50 ? nombre : nombre.substring(0, 50),
            bloque: elemento.bloque.id,
            color: paralelo ? elemento.color : invert(elemento.color),
            grosor: elemento.grosor,
            coordenadas: paralelo ? offsetLine.geometry : elemento.coordenadas,
            proyecto: proyecto.id,
            distancia_homologada: elemento.distancia_homologada ? elemento.distancia_homologada : null
        }
        setDuplicando(true);
        creaNuevoTrayecto(options);
        this.deseleccionar()
    }

    duplicarTramo () {
        const { t, elemento, proyecto, setDuplicando, creaNuevoTrayecto } = this.props
        alert(t('Selecciona el punto inicial sobre la linea'))
        const polyline = this.polyRef.current.leafletElement

        polyline.once('click', e => {
            const punto1 = [e.latlng.lng, e.latlng.lat]
            alert(t('Selecciona el punto final sobre la linea'))
            polyline.once('click', ev => {
                const punto2 = [ev.latlng.lng, ev.latlng.lat]
                const lineSlice = turf.lineSlice(punto1, punto2, polyline.toGeoJSON().geometry)
                setDuplicando(true)
                const nombre = elemento.nombre + '- tramo'
                const options = {
                    nombre: nombre.length < 50 ? nombre : nombre.substring(0, 50),
                    bloque: elemento.bloque.id,
                    color: invert(elemento.color),
                    grosor: elemento.grosor,
                    coordenadas: lineSlice.geometry,
                    proyecto: proyecto.id,
                    distancia_homologada: null
                }
                creaNuevoTrayecto(options)
                this.deseleccionar()
            })
        })
    }

    unir (elementoUnir) {
        if(this.cortando) this.cancelaRecorte()
        if(this.continuando) this.cancelaContinuar()
        const { elemento, actualizaTrayecto, actualizaCoordsTrayecto, eliminarTrayecto } = this.props
        let coords =  {
            "type": "LineString",
            "coordinates": elemento.coordenadas.coordinates.concat(elementoUnir.coordenadas.coordinates)
        }
        actualizaCoordsTrayecto(elemento.id, coords)
        const nombre = elemento.nombre + ' + ' + elementoUnir.nombre
        actualizaTrayecto(elementoUnir.id, {
            nombre: nombre.length < 50 ? nombre : nombre.substring(0, 50),
            bloque: elemento.bloque.id,
            coordenadas: coords
        })
        eliminarTrayecto(elemento)
        // this.deseleccionar();
    }

    getLatLngs() {
        const { elemento } = this.props
        let latLngs = []
        for (let i in elemento.coordenadas.coordinates) {
            latLngs.push(L.latLng(elemento.coordenadas.coordinates[i][1], elemento.coordenadas.coordinates[i][0]))
        }
        return latLngs
    }

    onClick(e) {
        const {getObjetoSeleccionado, elemento} = this.props;
        e.originalEvent.stopPropagation();
        if(!getObjetoSeleccionado || getObjetoSeleccionado.props.elemento.id !== elemento.id)
            this.seleccionar(true);
    }

    seleccionar(noCentrar) {
        const {objetoSeleccionado, getObjetoSeleccionado, cambiarModoBarraHerramientas, setUniendo, uniendo,
            cambiarVisibleBarraHerramientas, elemento, removeCapaColapsada,
            removeAgrupacionColapsada} = this.props;
        if(!uniendo){
            const polyline = this.polyRef.current.leafletElement;
            const map = this.props.map.current.leafletElement;
            if(getObjetoSeleccionado && getObjetoSeleccionado.montado) getObjetoSeleccionado.deseleccionar();
            polyline.enableEdit();
            this.seleccionado = true;
            cambiarModoBarraHerramientas('tr');
            cambiarVisibleBarraHerramientas(true);
            if(!noCentrar) this.centrarEnMi();
            objetoSeleccionado(this);
            this.configurePerfil();
            removeCapaColapsada(elemento.bloque.capa);
            removeAgrupacionColapsada(elemento.agrupacion);
            document.addEventListener('keyup', this.onKeyPress);
            map.on('editable:vertex:dragend', this.onDragEnd);
            map.on('editable:vertex:new', this.onDragEnd);
            map.on('editable:vertex:deleted', this.onDragEnd);
            setTimeout(() => {                          //esto non deberia ser necesario pero senon non funciona conmutar entre lineas
                map.once('click', this.onMapClick);
            }, 20);
        } else {
            if(!getObjetoSeleccionado || getObjetoSeleccionado.props.id === elemento.id || this.type !== 'tr') {
                alert('Elemento no válido, seleccione otro trayecto');
            } else {
                getObjetoSeleccionado.unir(elemento);
                // eliminarTrayecto(elemento);
                setUniendo(false);
            }
        }
    }

    onMapClick(e) {
        if(!this.continuando && !this.cortando) {
            const map = this.props.map.current.leafletElement;
            if(!this.isInDistanciaSeguridad(e)) {
                map.off('click',this.onMapClick);
                if(this.seleccionado) {
                    this.deseleccionar()
                }
            } else {
                map.on('click', this.onMapSecondClick);
            }
        }
    }

    onMapSecondClick(e) {
        const map = this.props.map.current.leafletElement;
        map.off('click', this.onMapSecondClick);
        this.onMapClick(e);
    }

    isInDistanciaSeguridad(e) {
        const map = this.props.map.current.leafletElement;
        const polyline = this.polyRef.current.leafletElement;
        const puntoCercando = turf.nearestPointOnLine(polyline.toGeoJSON().geometry, [e.latlng.lng, e.latlng.lat]);
        const puntoPantalla = map.latLngToContainerPoint(L.latLng(puntoCercando.geometry.coordinates[1],
            puntoCercando.geometry.coordinates[0]));
        const dist = Math.sqrt((puntoPantalla.x - e.containerPoint.x) * (puntoPantalla.x - e.containerPoint.x)  +
            (puntoPantalla.y - e.containerPoint.y) * (puntoPantalla.y - e.containerPoint.y));
        return dist < 20
    }

    deseleccionar() {
        const {objetoSeleccionado, elemento, actualizaCoordsTrayecto, actualizaTrayecto,
            cambiarVisibleBarraHerramientas} = this.props;
        const map = this.props.map.current.leafletElement;
        const polyline = this.polyRef.current ? this.polyRef.current.leafletElement : null;
        if(polyline) polyline.disableEdit();
        actualizaCoordsTrayecto(elemento.id, polyline.toGeoJSON().geometry);
        actualizaTrayecto(elemento.id, {
           nombre: elemento.nombre,
           bloque: elemento.bloque.id,
           coordenadas: polyline.toGeoJSON().geometry
        });
        this.seleccionado = false;
        objetoSeleccionado(null);
        cambiarVisibleBarraHerramientas(false);
        this.eliminaPerfil();
        map.off('click', this.onMapClick);
        document.removeEventListener('keypup', this.onKeyPress);
        map.off('editable:vertex:new', this.onDragEnd);
        map.off('editable:vertex:dragend', this.onDragEnd);
        map.off('editable:vertex:deleted', this.onDragEnd);
    }

    centrarEnMi() {
        const polyline = this.polyRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;

        let bounds = polyline.getBounds();
        map.flyToBounds(bounds);
    }

    cambioSentido() {
        if(this.cortando) this.cancelaRecorte();
        if(this.continuando) this.cancelaContinuar();
        const polyline = this.polyRef.current.leafletElement;
        let polyReverse = L.GeometryUtil.reverse(polyline);
        polyline.setLatLngs(polyReverse.getLatLngs());
        this.deseleccionar();
    }

    getUltimasCoords() {
        const polyline = this.polyRef.current.leafletElement;
        return polyline.toGeoJSON().geometry;
    }

    onMouseOver(e) {
        const {setPickDisplayDistancia, elemento} = this.props;
        setPickDisplayDistancia({
            trayecto: elemento,
            latLng: e.latlng
        });
        this.encima = true;
    }

    onMapMouseMove(e){
        const {setPickDisplayDistancia, elemento} = this.props;
        if(this.encima){
            let closetPoint= turf.nearestPointOnLine(elemento.coordenadas, new turf.point([e.latlng.lng, e.latlng.lat]),
                {units: 'meters'});
            if (closetPoint.properties.dist < 2) setPickDisplayDistancia({
                trayecto: elemento,
                latLng: e.latlng
            });
            else {
                this.encima = false;
                setPickDisplayDistancia(null);
            }
        }
    }

    onMouseOut(e){
        const {setPickDisplayDistancia} = this.props;
        this.encima = false;
        setPickDisplayDistancia(null);
    }

    continuar() {
        if(this.cortando) this.cancelaRecorte();
        const polyline = this.polyRef.current.leafletElement;
        this.continuando = true;
        polyline.editor.continueForward();
        polyline.on('editable:drawing:end', this.onDrawingEnd);
    }

    onDrawingEnd(e) {
        const polyline = this.polyRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;
        this.continuando = false;
        polyline.off('editable:drawing:end');
        map.once('click', this.onMapClick);
    }

    cortar() {
        if(this.continuando) this.cancelaContinuar();
        const map = this.props.map.current.leafletElement;
        this.cortando = true;
        this.rectanguloRecorte = map.editTools.startRectangle();
        this.rectanguloRecorte.setStyle({color: '#bbb', fillOpacity: .15, dashArray:'10 10'})
        this.rectanguloRecorte.on('editable:drawing:end', this.onDrawigEndRect);
    }

    onDrawigEndRect(e) {
        const {setVisibleModalConfirmarRecorte} = this.props;
        e.layer.off('editable:drawing:end');
        e.layer.disableEdit();
        this.lineaRecorte = this.recorte(e.layer.toGeoJSON());
        if(this.lineaRecorte) {
            setVisibleModalConfirmarRecorte(true);
        }
    }

    aplicaRecorte() {
        const map = this.props.map.current.leafletElement;
        const {elemento, actualizaTrayecto, actualizaCoordsTrayecto} = this.props;
        actualizaCoordsTrayecto(elemento.id, this.lineaRecorte.geometry);
        actualizaTrayecto(elemento.id, {
             nombre: elemento.nombre,
             bloque: elemento.bloque.id,
             coordenadas: this.lineaRecorte.geometry
        });
        map.removeLayer(this.rectanguloRecorte);
    }

    recorte(rectangulo) {
        const {t} = this.props;
        const polyline = this.polyRef.current.leafletElement;
        const polyJson = polyline.toGeoJSON();
        const intersect = turf.lineIntersect(polyJson, rectangulo);
        const split = turf.lineSplit(polyJson, rectangulo);
        if(intersect.features.length < 1) {
            alert(t('msg-error-recortar'));
            return false;
        } else {
            const segmentos = split.features;
            let coordenadas = [];
            for(let i in segmentos) {
                if(!this.isDentroRectangulo(segmentos[i], rectangulo)){
                    coordenadas = coordenadas.concat(segmentos[i].geometry.coordinates);
                }
            }
            return turf.lineString(coordenadas);
        }
    }

    isDentroRectangulo(segmento, rectangulo) {
        for(let i=1; i< segmento.geometry.coordinates.length-1; i++) {
            if(!turf.booleanPointInPolygon(segmento.geometry.coordinates[i], rectangulo))
                return false;
        }
        return true;
    }

    onKeyPress(e) {
        if(e.keyCode === 27) {
            if(this.cortando) {
                this.cancelaRecorte();
                return true;
            }
            if(this.continuando) {
                this.cancelaContinuar();
                return true;
            }
            if(this.seleccionado) {
                this.deseleccionar();
            }
        }
        /*else if(e.keyCode === 46) { // para eliminar co botón de suprimir, pero funciona raro, xa o arrleglarei
            const {t, cambiarVisibleModalEliminarElemento} = this.props;
            if(!this.isInRecorrido()) {
                cambiarVisibleModalEliminarElemento(true);
            } else {
                alert(t('msg-trayecto-en-recorrido-borrar'))
            }
        }*/
    }

    isInRecorrido(){
        const {recorridos, elemento} = this.props;
        for(let i in recorridos) {
            for(let j in recorridos[i].sectores) {
                for(let k in recorridos[i].sectores[j].trayectos){
                    if(recorridos[i].sectores[j].trayectos[k].id === elemento.id && this.seleccionado)
                        return true;
                }
            }
        }
        return false;
    }

    cancelaRecorte() {
        const map = this.props.map.current.leafletElement;
        this.rectanguloRecorte.off('editable:drawing:end');
        this.rectanguloRecorte.disableEdit();
        map.removeLayer(this.rectanguloRecorte);
        this.cortando = false;
        map.once('click', this.onMapClick)
    }

    cancelaContinuar() {
        const polyline = this.polyRef.current.leafletElement;
        polyline.disableEdit();
        polyline.enableEdit();
    }

    onDragEnd(e) {
        const {actualizaCoordsTrayecto, elemento} = this.props;
        actualizaCoordsTrayecto(elemento.id, e.layer.toGeoJSON().geometry, true);
    }

    onContextmenu(e) {
        const {getObjetoSeleccionado, elemento, setVisibleModalPropAvanzada} = this.props;
        e.originalEvent.stopPropagation();
        if(!getObjetoSeleccionado || getObjetoSeleccionado.props.elemento.id !== elemento.id)
            this.seleccionar(true);
        setVisibleModalPropAvanzada(true);
    }

    configurePerfil () {
        const { elemento } = this.props;
        const map = this.props.map.current ? this.props.map.current.leafletElement : null;
        if (!elemento.coordenadas_interpoladas || !map) {
            return null;
        } else {
            const fc = [{
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    "geometry": elemento.coordenadas_interpoladas,
                    "properties": {
                        "attributeType": 0
                    }
                }],
                "properties": {
                    "summary": "elevation",
                }
            }];
            const hg = this.hg = L.control.heightgraph({
                height: 200,
                width: 700,
                graphStyle: {
                    opacity: 1,
                    'fill-opacity': 0.25,
                    'stroke-width': '3px'
                },
                margins: {
                    top: 25,
                    right: 25,
                    bottom: 50,
                    left: 50
                },
                mappings: {
                    elevation: {
                        '0': {
                            color: elemento.color
                        }
                    }
                },
                highlightStyle: {
                    color: "purple"
                },
                expandControls: false
            });
            hg.addTo(map);
            hg.addData(fc);
        }
    }

    eliminaPerfil() {
        if(this.hg) {
            this.hg.remove();
            this.hg = null;
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !this.continuando;
    }

    componentWillUnmount() {
        const {removeObjetoDibujado, cambiarVisibleBarraHerramientas, objetoSeleccionado} = this.props;
        const map = this.props.map.current ? this.props.map.current.leafletElement : null;
        if (map) {
            map.off('click', this.onMapClick);
            map.off('editable:vertex:new', this.onDragEnd);
            map.off('editable:vertex:dragend', this.onDragEnd);
            map.off('editable:vertex:deleted', this.onDragEnd);
            map.off('mousemove', this.onMapMouseMove)
        }
        removeObjetoDibujado(this);
        if (this.seleccionado) {
            if (this.eliminado) {
                objetoSeleccionado(null);
                setTimeout(() => {
                    cambiarVisibleBarraHerramientas(false);
                }, 75);
            }
        }
        if(this.hg) this.hg.remove();
        this.montado = false;
    }

    descargar() {
        const { elemento } = this.props;
        const toGpx = require('togpx');
        const gpx = toGpx(turf.lineString(elemento.coordenadas.coordinates, {
            name: elemento.nombre,
            color: elemento.color,
            desc: elemento.descripcion
        }));

        const blob = new Blob([gpx], { type: 'application/gpx+xml' })

        const a = document.createElement('a')
        a.download = (elemento.nombre + '.gpx').replace(/\s/g, '_')
        a.href = URL.createObjectURL(blob)
        a.dataset.downloadurl = ['application/gpx+xml', a.download, a.href].join(':')
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        setTimeout(() => URL.revokeObjectURL(a.href), 1500)
    }

    render() {
        const {editable, elemento} = this.props;
        if(!this.seleccionado && elemento.flechas) {
            return <TrayectoFlechas elemento={elemento} polyRef={this.polyRef} positions={this.getLatLngs()}
                                    color={elemento.color} weight={elemento.grosor} opacity={.75} trayecto={this}
                                    onClick={editable ? this.onClick : () => {}}
                                    onMouseover={editable ? this.onMouseOver : () => {}}
                                    onMouseout={editable ? this.onMouseOut : () => {}}
                                    onContextmenu={editable ? this.onContextmenu : () => {}}/>
        } else {
            return <Polyline ref={this.polyRef} positions={this.getLatLngs()} color={elemento.color} opacity={1}
                             weight={elemento.grosor} onClick={editable ? this.onClick : () => {}}
                             onMouseover={editable ? this.onMouseOver : () => {}}
                             onMouseout={editable ? this.onMouseOut : () => {}}/>
        }
    }
}

const mapStateToProps = state => ({
    getObjetoSeleccionado: getObjetoSeleccionado(state),
    proyecto: getProyecto(state),
    uniendo: getUniendo(state),
    trayectoActualizando: getTrayectoActualizando(state),
    recorridos: getRecorridos(state),
    elementoRecienSubido: getElementoRecienSubido(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    objetoSeleccionado: actions.objetoSeleccionado,
    actualizaCoordsTrayecto: actionsTrayectos.actualizaCoordsTrayecto,
    actualizaTrayecto: actualizaTrayecto,
    addObjetoDibujado: actions.addObjetoDibujado,
    removeObjetoDibujado: actions.removeObjetoDibujado,
    cambiarModoBarraHerramientas: actions.cambiarModoBarraHerramientas,
    cambiarVisibleBarraHerramientas: actions.cambiarVisibleBarraHerramientas,
    setDuplicando: actions.setDuplicando,
    creaNuevoTrayecto: creaNuevoTrayecto,
    eliminarTrayecto: eliminarTrayecto,
    setUniendo: actions.setUniendo,
    setPickDisplayDistancia: actions.setPickDisplayDistancia,
    removeCapaColapsada: actions.removeCapaColapsada,
    setVisibleModalPropAvanzada: actions.setVisibleModalPropAvanzadas,
    setVisibleModalConfirmarRecorte: actions.setVisibleModalCofirmarRecorte,
    cambiarVisibleModalEliminarElemento: actions.cambiarVisibleModalEliminarElemento,
    removeAgrupacionColapsada: actions.removeAgrupacionColapsada,
    setElementoRecienSubido: actions.setElementoRecienSubido,
    setMuestraAvisoDistancia: actions.setMuestraAvisoDistancia
}, dispatch);


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Trayecto));




(function() {
    //Monkey patch para que non casque cando se añade un punto novo, solo habia que cambiar o momento de lanzar o evento ao final da función
    L.Editable.MiddleMarker.prototype.onMouseDown = function (e) {
        var iconPos = L.DomUtil.getPosition(this._icon),
            latlng = this.editor.map.layerPointToLatLng(iconPos);
        e = {
            originalEvent: e,
            latlng: latlng
        };
        if (this.options.opacity === 0) return;
        L.Editable.makeCancellable(e);
        this.editor.onMiddleMarkerMouseDown(e);
        if (e._cancelled) return;
        this.latlngs.splice(this.index(), 0, e.latlng);
        this.editor.refresh();
        var icon = this._icon;
        var marker = this.editor.addVertexMarker(e.latlng, this.latlngs);
        /* Hack to workaround browser not firing touchend when element is no more on DOM */
        var parent = marker._icon.parentNode;
        parent.removeChild(marker._icon);
        marker._icon = icon;
        parent.appendChild(marker._icon);
        marker._initIcon();
        marker._initInteraction();
        marker.setOpacity(1);
        /* End hack */
        // Transfer ongoing dragging to real marker
        L.Draggable._dragging = false;
        marker.dragging._draggable._onDown(e.originalEvent);
        this.delete();
        this.editor.onNewVertex(marker);
    }

    /* Parche para que solo se mostren os puntos que estan dentro da pantalla */
    L.Editable.PathEditor.prototype.addVertexMarkers = function (latlngs) {
        for (var i = 0; i < latlngs.length; i++) {
            const bounds = this.map.getBounds();
            if(bounds.contains(latlngs[i]))
                this.addVertexMarker(latlngs[i], latlngs);
        }
    }

    L.Editable.BaseEditor.prototype.addHooks = function (reset) {
        if (this.isConnected()) this.onFeatureAdd();
        else this.feature.once('add', this.onFeatureAdd, this);
        if(!reset) this.onEnable();
        this.map.on('moveend', this.onMoveEnd, this);
        this.feature.on(this._getEvents(), this);
    }

    L.Editable.BaseEditor.prototype.removeHooks = function (reset) {
        this.feature.off(this._getEvents(), this);
        if (this.feature.dragging) this.feature.dragging.disable();
        this.editLayer.clearLayers();
        this.tools.editLayer.removeLayer(this.editLayer);
        if(!reset) this.onDisable();
        this.map.off('moveend', this.onMoveEnd, this);
        if (this._drawing) this.cancelDrawing();
    }

    //actualizar os puntos cando se move o mapa
    L.Editable.BaseEditor.prototype.onMoveEnd = function (e) {
        this.reset();
    }

    L.Editable.BaseEditor.prototype.reset = function () {
        if(!this.feature || !this.feature._latlng) {    //this.feature._latlng nos puntos que non faga isto
            this.removeHooks(true);
            this.addHooks(true);
        }
    }
    /* Fin do parche */
})();
