import {Col, Row, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {withTranslation} from "react-i18next";
import Select from 'react-select';

import AreaDragSector from "../../components/AreaDragSector";

function SubTabSecuenciaSector(props) {
    const {
        t, modalRecorridos, sector, deportes, recorridoCreando, onDrop, getDistanciaTrayecto, reordenaTrayectos,
        onChangeRepeticiones, onClickEliminarTrayecto, onChangeEsquemaSimplificado, onChangeDeporteSector,
        onChangeNombreSector, onClickEliminarSector, onChangeColorSector
    } = props;
    const deporteRecorrido = recorridoCreando.deporte.id
        ? recorridoCreando.deporte : deportes.filter(deporte => deporte.id === (recorridoCreando.deporte))[0];

    const optionsDeportes = deportes.filter(d => deporteRecorrido.permitidos.indexOf(d.id) >= 0).map(dp => {
        return {
            value: dp.id,
            label: dp.nombre
        }
    });

    const optionSeleccionada = sector.deporte ?
        optionsDeportes.filter(od => od.value === (sector.deporte.id ? sector.deporte.id : sector.deporte))[0] : null;

    return [<Row key={sector.orden} className='encabezado-sector'>
        <Col className='distancias'
             sm={12}>{t('distancia-real') + ': ' + modalRecorridos.getDistanciaRealSector(sector) + ' | ' +
        t('distancia_homologada') + ': ' + modalRecorridos.getDistanciaOficialSector(sector)}</Col>

        <Col sm={3} className=''>
            <Form.Label>{t('nombre') + ':'}</Form.Label>
            <Form.Control defaultValue={sector.nombre} className='input-nombre-sector'
                          onChange={(e) => onChangeNombreSector(e, sector)}/>
        </Col>
        <Col sm={4} className='deporte-sector'>
            <Form.Label>{t('deporte') + ':'}</Form.Label>
            <Select options={optionsDeportes} onChange={e => onChangeDeporteSector(e, sector)} name='deporte-sector'
                    value={optionSeleccionada} className='form-group deporte-input' placeholder={t('seleccione-deporte')}/>
        </Col>
        <Col sm={2} className=''>
            <Form.Label>{t('color') + ':'}</Form.Label>
            <Form.Control type='color' defaultValue={sector.color} className='input-nombre-sector'
                          onChange={(e) => onChangeColorSector(e, sector)}/>
        </Col>
        <Col sm={2} onClick={() => onClickEliminarSector(sector)}>
            <div className='icono-eliminar'>
                <FontAwesomeIcon icon={faTrashAlt}/>
            </div>
        </Col>
    </Row>,
        <h6 key={'secuencia-' + sector.id}>{t('secuencia')}</h6>,
        <AreaDragSector distanciaEnUnidad={modalRecorridos.distanciaEnUnidad} onDrop={onDrop}
                        sector={sector} key={sector.orden + '-drag'}
                        getDistanciaTrayecto={getDistanciaTrayecto}
                        onChangeRepeticiones={onChangeRepeticiones}
                        reordenaTrayectos={reordenaTrayectos}
                        onClickEliminar={onClickEliminarTrayecto}
                        onChangeEsquemaSimplificado={onChangeEsquemaSimplificado}
        />
    ]
}

export default withTranslation()(SubTabSecuenciaSector)
