export const ACTION_TYPES = {
    ADD_AGRUPACION_RECORRIDO_CREANDO: 'ADD_AGRUPACION_RECORRIDO_CREANDO',
    ADD_ELEMENTO_RECORRIDO_CREANDO: 'ADD_ELEMENTO_RECORRIDO_CREANDO',
    ADD_PDI_CREADO_SECTOR: 'ADD_PDI_CREADO_SECTOR',
    ADD_PDI_SECTOR: 'ADD_PDI_SECTOR',
    ADD_RECORRIDO: 'ADD_RECORRIDO',
    ADD_SECTOR_NUEVO: 'ADD_SECTOR_NUEVO',
    ADD_TRAYECTO_TO_SECTOR: 'ADD_TRAYECTO_TO_SECTOR',
    CAMBIAR_CAPA_FONDO_RECORRIDO: 'CAMBIAR_CAPA_FONDO_RECORRIDO',
    CAMBIAR_DEPORTE_RECORRIDO_CREANDO: 'CAMBIAR_DEPORTE_RECORRIDO_CREANDO',
    CAMBIAR_DEPORTE_SECTOR: 'CAMBIAR_DEPORTE_SECTOR',
    CAMBIAR_NOMBRE_SECTOR_NUEVO: 'CAMBIAR_NOMBRE_SECTOR_NUEVO',
    CAMBIAR_UNIDAD_DISTANCIA: 'CAMBIAR_UNIDAD_DISTANCIA',
    ELEMENTO_RECORRIDO_ACTUALIZANDO_CORRECTO: 'ELEMENTO_RECORRIDO_ACTUALIZANDO_CORRECTO',
    ELEMENTO_RECORRIDO_ACTUALIZANDO_ERROR: 'ELEMENTO_RECORRIDO_ACTUALIZANDO_ERROR',
    ELEMENTO_RECORRIDO_ACTUALIZANDO_PENDIENTE: 'ELEMENTO_RECORRIDO_ACTUALIZANDO_PENDIENTE',
    ELEMENTO_RECORRIDO_ELIMINANDO_CORRECTO: 'ELEMENTO_RECORRIDO_ELIMINANDO_CORRECTO',
    ELEMENTO_RECORRIDO_ELIMINANDO_ERROR: 'ELEMENTO_RECORRIDO_ELIMINANDO_ERROR',
    ELEMENTO_RECORRIDO_ELIMINANDO_PENDIENTE: 'ELEMENTO_RECORRIDO_ELIMINANDO_PENDIENTE',
    ELIMINA_TRAYECTO_SECTOR: 'ELIMINA_TRAYECTO_SECTOR',
    ELIMINAR_SECTOR_NUEVO: 'ELIMINAR_SECTOR_NUEVO',
    ELIMINA_PKS_RECORRIDO_CREANDO: 'ELIMINA_PKS_RECORRIDO_CREANDO',
    FETCH_RECORRIDOS_CORRECTO: 'FETCH_RECORRIDOS_CORRECTO',
    FETCH_RECORRIDOS_ERROR: 'FETCH_RECORRIDOS_ERROR',
    FETCH_RECORRIDOS_PENDIENTE: 'FETCH_RECORRIDOS_PENDIENTE',
    FETCH_RECORRIDO_CORRECTO: 'FETCH_RECORRIDO_CORRECTO',
    FETCH_RECORRIDO_ERROR: 'FETCH_RECORRIDO_ERROR',
    FETCH_RECORRIDO_PENDIENTE: 'FETCH_RECORRIDO_PENDIENTE',
    NUEVO_PK_CORRECTO: 'NUEVO_PK_CORRECTO',
    NUEVO_PK_ERROR: 'NUEVO_PK_ERROR',
    NUEVO_PK_PENDIENTE: 'NUEVO_PK_PENDIENTE',
    NUEVO_ELEMENTO_RECORRIDO_CORRECTO: 'NUEVO_ELEMENTO_RECORRIDO_CORRECTO',
    NUEVO_ELEMENTO_RECORRIDO_ERROR: 'NUEVO_ELEMENTO_RECORRIDO_ERROR',
    NUEVO_ELEMENTO_RECORRIDO_PENDIENTE: 'NUEVO_ELEMENTO_RECORRIDO_PENDIENTE',
    NUEVO_RECORRIDO_CORRECTO: 'NUEVO_RECORRIDO_CORRECTO',
    NUEVO_RECORRIDO_ERROR: 'NUEVO_RECORRIDO_ERROR',
    NUEVO_RECORRIDO_PENDIENTE: 'NUEVO_RECORRIDO_PENDIENTE',
    PK_ACTUALIZANDO_CORRECTO: 'PK_ACTUALIZANDO_CORRECTO',
    PK_ACTUALIZANDO_ERROR: 'PK_ACTUALIZANDO_ERROR',
    PK_ACTUALIZANDO_PENDIENTE: 'PK_ACTUALIZANDO_PENDIENTE',
    PK_ELIMINANDO_CORRECTO: 'PK_ELIMINANDO_CORRECTO',
    PK_ELIMINANDO_ERROR: 'PK_ELIMINANDO_ERROR',
    PK_ELIMINANDO_PENDIENTE: 'PK_ELIMINANDO_PENDIENTE',
    RECORRIDO_ACTUALIZANDO_CORRECTO: 'RECORRIDO_ACTUALIZANDO_CORRECTO',
    RECORRIDO_ACTUALIZANDO_ERROR: 'RECORRIDO_ACTUALIZANDO_ERROR',
    RECORRIDO_ACTUALIZANDO_PENDIENTE: 'RECORRIDO_ACTUALIZANDO_PENDIENTE',
    RECORRIDO_ELIMINANDO_CORRECTO: 'RECORRIDO_ELIMINANDO_CORRECTO',
    RECORRIDO_ELIMINANDO_ERROR: 'RECORRIDO_ELIMINANDO_ERROR',
    RECORRIDO_ELIMINANDO_PENDIENTE: 'RECORRIDO_ELIMINANDO_PENDIENTE',
    REORDENA_PDIS: 'REORDENA_PDIS',
    REORDENA_TRAYECTOS_NUEVO: 'REORDENA_TRAYECTOS_NUEVO',
    REMOVE_AGRUPACION_RECORRIDO_CREANDO: 'REMOVE_AGRUPACION_RECORRIDO_CREANDO',
    REMOVE_ELEMENTO_RECORRIDO_CREANDO: 'REMOVE_ELEMENTO_RECORRIDO_CREANDO',
    REMOVE_PDI_CREADO_SECTOR: 'REMOVE_PDI_CREADO_SECTOR',
    REMOVE_PDI_SECTOR: 'REMOVE_PDI_SECTOR',
    REMOVE_RECORRIDO: 'REMOVE_RECORRIDO',
    SET_CADENCIA_PKS: 'SET_CADENCIA_PKS',
    SET_COLOR_SECTOR: 'SET_COLOR_SECTOR',
    SET_DATOS_RECORRIDO_CREANDO: 'SET_DATOS_RECORRIDO_CREANDO',
    SET_ESQUEMA_SIMPLIFCIADO_TRAYECTO_SECTOR: 'SET_ESQUEMA_SIMPLIFCIADO_TRAYECTO_SECTOR',
    SET_GENERAR_INICIO_FIN_SECTOR: 'SET_GENERAR_INICIO_FIN_SECTOR',
    SET_PERFIL_PUBLICADO: 'SET_PERFIL_PUBLICADO',
    SET_PUNTOS_KM_AUTO: 'SET_PUNTOS_KM_AUTO',
    SET_RECORRIDO_CREANDO: 'SET_RECORRIDO_CREANDO',
    SET_REPETICIONES_TRAYECTO_SECTOR: 'SET_REPETICIONES_TRAYECTO_SECTOR',
    SET_SECTOR_POR_DEFECTO: 'SET_SECTOR_POR_DEFECTO',
    SET_VISTA_INICIAL: 'SET_VISTA_INICIAL'
};

function setSectorPorDefecto(nombre) {
    return{
        type: ACTION_TYPES.SET_SECTOR_POR_DEFECTO,
        nombre: nombre
    }
}

function addTrayectoToSector(trayecto, sector) {
    return {
        type: ACTION_TYPES.ADD_TRAYECTO_TO_SECTOR,
        trayecto: trayecto,
        sector: sector
    }
}

function setRepeticionesTrayectoSector(esquema, sector, repeticiones) {
    return {
        type: ACTION_TYPES.SET_REPETICIONES_TRAYECTO_SECTOR,
        esquema: esquema,
        sector: sector,
        repeticiones: repeticiones
    }
}

function reordenaTrayectosNuevo(indexOriginal, indexFinal, sector) {
    return {
        type: ACTION_TYPES.REORDENA_TRAYECTOS_NUEVO,
        indexOriginal: indexOriginal,
        indexFinal: indexFinal,
        sector: sector
    };
}

function eliminaTrayectoSector(indexTrayecto, sector) {
    return {
        type: ACTION_TYPES.ELIMINA_TRAYECTO_SECTOR,
        indexTrayecto: indexTrayecto,
        sector: sector
    }
}

function addSectorNuevo(nombre) {
    return {
        type: ACTION_TYPES.ADD_SECTOR_NUEVO,
        nombre: nombre
    };
}

function eliminarSectorNuevo(sector) {
    return {
        type: ACTION_TYPES.ELIMINAR_SECTOR_NUEVO,
        sector: sector
    };
}

function nuevoRecorridoCorrecto(recorrido) {
    return {
        type: ACTION_TYPES.NUEVO_RECORRIDO_CORRECTO,
        recorrido: recorrido
    };
}

function nuevoRecorridoError(error) {
    return {
        type: ACTION_TYPES.NUEVO_RECORRIDO_ERROR,
        error: error
    };
}

function nuevoRecorridoPendiente(totalEsquemas) {
    return {
        type: ACTION_TYPES.NUEVO_RECORRIDO_PENDIENTE,
        totalEsquemas: totalEsquemas
    };
}

function fetchRecorridosPendiente () {
    return {
        type: ACTION_TYPES.FETCH_RECORRIDOS_PENDIENTE
    };
}

function fetchRecorridosCorrecto(recorridos) {
    return {
        type: ACTION_TYPES.FETCH_RECORRIDOS_CORRECTO,
        recorridos: recorridos
    };
}

function fetchRecorridosError(err) {
    return {
        type: ACTION_TYPES.FETCH_RECORRIDOS_ERROR,
        error: err
    };
}

function fetchRecorridoPendiente() {
    return {
        type: ACTION_TYPES.FETCH_RECORRIDO_PENDIENTE
    };
}

function fetchRecorridoCorrecto(recorrido) {
    return {
        type: ACTION_TYPES.FETCH_RECORRIDO_CORRECTO,
        recorrido: recorrido
    };
}

function fetchRecorridoError(err) {
    return {
        type: ACTION_TYPES.FETCH_RECORRIDO_ERROR,
        error: err
    };
}

function recorridoActualizandoCorrecto(recorrido) {
    return{
        type: ACTION_TYPES.RECORRIDO_ACTUALIZANDO_CORRECTO,
        recorrido: recorrido
    };
}

function recorridoActualizandoError(error) {
    return {
        type: ACTION_TYPES.RECORRIDO_ACTUALIZANDO_ERROR,
        error: error
    };
}

function recorridoActualizandoPendiente(totalEsquemas) {
    return {
        type: ACTION_TYPES.RECORRIDO_ACTUALIZANDO_PENDIENTE,
        totalEsquemas: totalEsquemas
    };
}

function setRecorridoCreando(recorrido) {
    return {
        type: ACTION_TYPES.SET_RECORRIDO_CREANDO,
        recorrido: recorrido
    };
}

function addRecorrido(recorrido) {
    return {
        type: ACTION_TYPES.ADD_RECORRIDO,
        recorrido: recorrido
    };
}

function removeRecorrido(recorrido) {
    return {
        type: ACTION_TYPES.REMOVE_RECORRIDO,
        recorrido: recorrido
    };
}

function recorridoEliminandoPendiente(recorrido) {
    return {
        type: ACTION_TYPES.RECORRIDO_ELIMINANDO_PENDIENTE,
        recorrido: recorrido
    };
}

function recorridoEliminandoError(err) {
    return {
        type: ACTION_TYPES.RECORRIDO_ELIMINANDO_ERROR,
        error: err
    };
}

function recorridoEliminandoCorrecto(recorrido) {
    return {
        type: ACTION_TYPES.RECORRIDO_ELIMINANDO_CORRECTO,
        recorrido: recorrido
    };
}

function cambiarNombreSectorNuevo(sector, nombre) {
    return {
        type: ACTION_TYPES.CAMBIAR_NOMBRE_SECTOR_NUEVO,
        sector: sector,
        nombre: nombre
    };
}

function addAgrupacionRecorridoCreando(agrupacion) {
    return {
        type: ACTION_TYPES.ADD_AGRUPACION_RECORRIDO_CREANDO,
        agrupacion: agrupacion
    };
}

function removeAgrupacionRecorridoCreando(agrupacion) {
    return {
        type: ACTION_TYPES.REMOVE_AGRUPACION_RECORRIDO_CREANDO,
        agrupacion: agrupacion
    };
}

function nuevoPkCorrecto(idRecorrido, pk) {
    return {
        type: ACTION_TYPES.NUEVO_PK_CORRECTO,
        idRecorrido: idRecorrido,
        pk: pk
    };
}

function nuevoPkError(err) {
    return {
        type: ACTION_TYPES.NUEVO_PK_ERROR,
        error: err
    };
}

function nuevoPkPendiente(){
    return {
        type: ACTION_TYPES.NUEVO_PK_PENDIENTE
    };
}

function pkEliminadoCorrecto(idRecorrido, pk) {
    return {
        type: ACTION_TYPES.PK_ELIMINANDO_CORRECTO,
        idRecorrido: idRecorrido,
        pk: pk
    };
}

function pkEliminandoError(err) {
    return {
        type: ACTION_TYPES.PK_ELIMINANDO_ERROR,
        error: err
    };
}

function pkEliminandoPendiente() {
    return {
        type: ACTION_TYPES.PK_ELIMINANDO_PENDIENTE
    };
}

function pkActualizandoCorrecto(idRecorrido, pk) {
    return {
        type: ACTION_TYPES.PK_ACTUALIZANDO_CORRECTO,
        idRecorrido: idRecorrido,
        pk: pk
    };
}

function pkActualizandoError(err) {
    return {
        type: ACTION_TYPES.PK_ACTUALIZANDO_ERROR,
        error: err
    };
}

function pkActualizandoPendiente() {
    return {
        type: ACTION_TYPES.PK_ACTUALIZANDO_PENDIENTE
    };
}

function setEsquemaSimplificadoTrayectoSector(esquema, sector, esquemaPrincipal) {
    return {
        type: ACTION_TYPES.SET_ESQUEMA_SIMPLIFCIADO_TRAYECTO_SECTOR,
        esquema: esquema,
        sector: sector,
        esquemaPrincipal: esquemaPrincipal
    };
}

function addElementoRecorridoCreando(elemento, orden){
    return {
        type: ACTION_TYPES.ADD_ELEMENTO_RECORRIDO_CREANDO,
        elemento: elemento,
        orden: orden
    };
}

function removeElementoRecorridoCreando(elemento) {
    return {
        type: ACTION_TYPES.REMOVE_ELEMCAMBIAR_CAPA_FONDOENTO_RECORRIDO_CREANDO,
        elemento: elemento
    };
}

function nuevoElementoRecorridoCorrecto(elementoRecorrido) {
    return {
        type: ACTION_TYPES.NUEVO_ELEMENTO_RECORRIDO_CORRECTO,
        elementoRecorrido: elementoRecorrido
    };
}

function nuevoElementoRecorridoError(err) {
    return {
        type: ACTION_TYPES.NUEVO_ELEMENTO_RECORRIDO_ERROR,
        error: err,
    };
}

function nuevoElementoRecorridoPendiente() {
    return {
        type: ACTION_TYPES.NUEVO_ELEMENTO_RECORRIDO_PENDIENTE
    };
}

function elementoRecorridoEliminandoCorrecto(elementoRecorrido) {
    return {
        type: ACTION_TYPES.ELEMENTO_RECORRIDO_ELIMINANDO_CORRECTO,
        elementoRecorrido: elementoRecorrido
    };
}

function elementoRecorridoEliminandoError(err) {
    return {
        type: ACTION_TYPES.ELEMENTO_RECORRIDO_ELIMINANDO_ERROR,
        error: err
    };
}

function elementoRecorridoEliminandoPendiente() {
    return {
        type: ACTION_TYPES.ELEMENTO_RECORRIDO_ELIMINANDO_PENDIENTE
    };
}

function elementoRecorridoActualizandoCorrecto(elementoRecorrido) {
    return {
        type: ACTION_TYPES.ELEMENTO_RECORRIDO_ACTUALIZANDO_CORRECTO,
        elementoRecorrido: elementoRecorrido
    };
}

function elementoRecorridoActualizandoError(err) {
    return {
        type: ACTION_TYPES.ELEMENTO_RECORRIDO_ACTUALIZANDO_ERROR,
        error: err
    };
}

function elementoRecorridoActualizandoPendiente() {
    return {
        type: ACTION_TYPES.ELEMENTO_RECORRIDO_ACTUALIZANDO_PENDIENTE
    };
}

function reordenaPdis(ordenOriginal, ordenFinal) {
    return {
        type: ACTION_TYPES.REORDENA_PDIS,
        ordenOriginal: ordenOriginal,
        ordenFinal: ordenFinal
    };
}

function cambiarDeporteRecorridoCreando(deporte) {
    return {
        type: ACTION_TYPES.CAMBIAR_DEPORTE_RECORRIDO_CREANDO,
        deporte: deporte
    };
}

function cambiarDeporteSector(deporte, sector) {
    return {
        type: ACTION_TYPES.CAMBIAR_DEPORTE_SECTOR,
        deporte: deporte,
        sector: sector
    };
}

function setPerfilPublicado(idSector, publicado) {
    return {
        type: ACTION_TYPES.SET_PERFIL_PUBLICADO,
        idSector: idSector,
        publicado: publicado
    };
}

function setDatosRecorridoCreando(datosRecorrido) {
    return {
        type: ACTION_TYPES.SET_DATOS_RECORRIDO_CREANDO,
        datosRecorrido: datosRecorrido
    };
}

function addPdiSector(sector, pdi) {
    return {
        type: ACTION_TYPES.ADD_PDI_SECTOR,
        sector: sector,
        pdi: pdi
    };
}

function removePdiSector(sector, pdi) {
    return {
        type: ACTION_TYPES.REMOVE_PDI_SECTOR,
        sector: sector,
        pdi: pdi
    };
}

function cambiarUnidadDistancia(unidad) {
    return {
        type: ACTION_TYPES.CAMBIAR_UNIDAD_DISTANCIA,
        unidad: unidad
    };
}

function setGenerarInicioFinSector(sector, valor) {
    return {
        type: ACTION_TYPES.SET_GENERAR_INICIO_FIN_SECTOR,
        sector: sector,
        valor: valor
    };
}

function addPdiCreadoSector(pdi, sector) {
    return {
        type: ACTION_TYPES.ADD_PDI_CREADO_SECTOR,
        pdi: pdi,
        sector: sector
    };
}

function setPuntosKmAuto(valor, sector) {
    return {
        type: ACTION_TYPES.SET_PUNTOS_KM_AUTO,
        valor: valor,
        sector: sector
    };
}

function setCadenciaPks(cadencia, sector) {
    return {
        type: ACTION_TYPES.SET_CADENCIA_PKS,
        sector: sector,
        cadencia: cadencia
    };
}

function setColorSector(sector, color) {
    return {
        type: ACTION_TYPES.SET_COLOR_SECTOR,
        sector: sector,
        color: color
    };
}

function eliminaPksRecorridoCreando(sector) {
    return{
        type: ACTION_TYPES.ELIMINA_PKS_RECORRIDO_CREANDO,
        sector: sector
    };
}

function cambiarCapaFondoRecorrido(capa) {
    return {
        type: ACTION_TYPES.CAMBIAR_CAPA_FONDO_RECORRIDO,
        capa: capa
    };
}

function setVistaInicial(vista) {
    return {
        type: ACTION_TYPES.SET_VISTA_INICIAL,
        vista: vista
    };
}

function removePdiCreadoSector(pdi, sector) {
    return {
        type: ACTION_TYPES.REMOVE_PDI_CREADO_SECTOR,
        pdi: pdi,
        sector: sector
    };
}

export const actions = {
    setSectorPorDefecto: setSectorPorDefecto,
    addTrayectoToSector: addTrayectoToSector,
    setRepeticionesTrayectoSector: setRepeticionesTrayectoSector,
    reordenaTrayectosNuevo: reordenaTrayectosNuevo,
    eliminaTrayectoSector: eliminaTrayectoSector,
    addSectorNuevo: addSectorNuevo,
    eliminarSectorNuevo: eliminarSectorNuevo,
    nuevoRecorridoCorrecto: nuevoRecorridoCorrecto,
    nuevoRecorridoError: nuevoRecorridoError,
    nuevoRecorridoPendiente: nuevoRecorridoPendiente,
    fetchRecorridosCorrecto: fetchRecorridosCorrecto,
    fetchRecorridosError: fetchRecorridosError,
    fetchRecorridosPendiente: fetchRecorridosPendiente,
    fetchRecorridoCorrecto: fetchRecorridoCorrecto,
    fetchRecorridoError: fetchRecorridoError,
    fetchRecorridoPendiente: fetchRecorridoPendiente,
    recorridoActualizandoCorrecto: recorridoActualizandoCorrecto,
    recorridoActualizandoError: recorridoActualizandoError,
    recorridoActualizandoPendiente: recorridoActualizandoPendiente,
    setRecorridoCreando: setRecorridoCreando,
    addRecorrido: addRecorrido,
    removeRecorrido: removeRecorrido,
    recorridoEliminandoCorrecto: recorridoEliminandoCorrecto,
    recorridoEliminandoError: recorridoEliminandoError,
    recorridoEliminandoPendiente: recorridoEliminandoPendiente,
    cambiarNombreSectorNuevo: cambiarNombreSectorNuevo,
    addAgrupacionRecorridoCreando: addAgrupacionRecorridoCreando,
    removeAgrupacionRecorridoCreando: removeAgrupacionRecorridoCreando,
    nuevoPkCorrecto: nuevoPkCorrecto,
    nuevoPkError: nuevoPkError,
    nuevoPkPendiente: nuevoPkPendiente,
    pkEliminadoCorrecto: pkEliminadoCorrecto,
    pkEliminandoError:pkEliminandoError,
    pkEliminandoPendiente: pkEliminandoPendiente,
    pkActualizandoCorrecto: pkActualizandoCorrecto,
    pkActualizandoError: pkActualizandoError,
    pkActualizandoPendiente: pkActualizandoPendiente,
    setEsquemaSimplificadoTrayectoSector: setEsquemaSimplificadoTrayectoSector,
    addElementoRecorridoCreando: addElementoRecorridoCreando,
    removeElementoRecorridoCreando: removeElementoRecorridoCreando,
    nuevoElementoRecorridoCorrecto: nuevoElementoRecorridoCorrecto,
    nuevoElementoRecorridoError: nuevoElementoRecorridoError,
    nuevoElementoRecorridoPendiente: nuevoElementoRecorridoPendiente,
    elementoRecorridoEliminandoCorrecto: elementoRecorridoEliminandoCorrecto,
    elementoRecorridoEliminandoError: elementoRecorridoEliminandoError,
    elementoRecorridoEliminandoPendiente: elementoRecorridoEliminandoPendiente,
    elementoRecorridoActualizandoCorrecto: elementoRecorridoActualizandoCorrecto,
    elementoRecorridoActualizandoError: elementoRecorridoActualizandoError,
    elementoRecorridoActualizandoPendiente: elementoRecorridoActualizandoPendiente,
    reordenaPdis: reordenaPdis,
    cambiarDeporteRecorridoCreando: cambiarDeporteRecorridoCreando,
    cambiarDeporteSector: cambiarDeporteSector,
    setPerfilPublicado: setPerfilPublicado,
    setDatosRecorridoCreando: setDatosRecorridoCreando,
    addPdiSector: addPdiSector,
    removePdiSector: removePdiSector,
    cambiarUnidadDistancia: cambiarUnidadDistancia,
    setGenerarInicioFinSector: setGenerarInicioFinSector,
    addPdiCreadoSector: addPdiCreadoSector,
    setPuntosKmAuto: setPuntosKmAuto,
    setCadenciaPks: setCadenciaPks,
    setColorSector: setColorSector,
    eliminaPksRecorridoCreando: eliminaPksRecorridoCreando,
    cambiarCapaFondoRecorrido: cambiarCapaFondoRecorrido,
    setVistaInicial: setVistaInicial,
    removePdiCreadoSector: removePdiCreadoSector
};

export default actions
