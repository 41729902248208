import {API_BASE_URL} from "../constants/api";
import {actions} from "../actions/lineas";
import {actions as actionsComun} from "../actions/comun";
import {actions as actionsEditor} from "../actions/editor";
import {fetchAgrupacion} from "./agrupaciones";
import getCsrfToken from '../lib/csrf'
import { actions as actionsError } from '../actions/error'
import validaFiltrosElemento from './utils'

export function fetchLineas(params) {
    const url = validaFiltrosElemento(API_BASE_URL + 'linea/', params)

    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.fetchLineasPendiente());
        fetch(url)
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);
                dispatch(actions.fetchLineasCorrecto(res));
                dispatch(actionsComun.restaPeticion());
                return res;
            })
            .catch(err => {
                dispatch(actions.fetchLineasError(err));
                dispatch(actionsComun.restaPeticion());
                return err;
            });
    }
}

export function creaNuevaLinea(opciones, subido, importado, formData) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.lineaNuevaPendiente());
        fetch(API_BASE_URL + 'linea/', {
            method: 'POST',
            headers: formData
                ? {
                    'X-CSRFToken': getCsrfToken()
                } : {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCsrfToken()
                },
            credentials: 'include',
            body: formData ? formData : JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.lineaNuevaCorrecto(data));
                            dispatch(actionsComun.restaPeticion());
                            dispatch(fetchAgrupacion(data.agrupacion));
                            if(subido) {
                                dispatch(actions.resetNuevaLinea());
                                dispatch(actions.addLinea(data));
                                dispatch(actionsEditor.setElementoRecienSubido(data));
                            }
                            if (importado) {
                                dispatch(actions.resetNuevaLinea())
                                dispatch(actions.addLinea(data))
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function actualizaLinea(id, opciones, formData) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.lineaActualizandoPendiente());
        fetch(API_BASE_URL + 'linea/' + id + '/', {
            method: 'PUT',
            headers: formData ? {
                'X-CSRFToken': getCsrfToken()
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: formData ? formData : JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.lineaActualizandoCorrecto(data));
                            dispatch(actions.removeLinea(data));
                            dispatch(actions.addLinea(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function eliminarLinea(linea) {
    return dispatch => {
        dispatch(actionsComun.restaPeticion());
        dispatch(actions.lineaEliminadaPendiente(linea));
        fetch(API_BASE_URL + 'linea/' + linea.id + '/', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
        })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    dispatch(actions.lineaEliminadaCorrecto(linea));
                    dispatch(actionsComun.restaPeticion());
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}
