import React, { Component } from 'react'
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

import { getObjetoSeleccionado } from "../reducers/editor"
import BtnBarraHerramientas from "./BtnBarraHerramientas"

class BtnCambiarSentido extends Component {
    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this)
    }

    onClick() {
        const { objetoSeleccionado } = this.props
        objetoSeleccionado.cambioSentido()
    }

    render() {
        const { t } = this.props;
        return (
            <BtnBarraHerramientas
                onClick={this.onClick}
                className={this.props.className}
                titulo={t('flip')}
                classIcon='rmp-ico-cambiar-sentido'
            />
        )
    }
}

const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state)
});


export default withTranslation()(connect(mapStateToProps, null)(BtnCambiarSentido));
