import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import {contadorPeticionesReducer, isLoadingReducer} from './comun';
import editorReducer from './editor';
import {proyectoActualizandoReducer, proyectoReducer, proyectosReducer} from "./proyectos";
import {capasFondoReducer} from "./capasFondo";
import {capasReducer} from "./capas";
import ultimasAgrupacionesReducer, {
    agrupacionActualizandoReducer,
    agrupacionEliminarReducer,
    agrupacionesReducer,
    agrupacionNuevaReducer, elementoActualizandoReducer
} from './agrupaciones'
import {puntoActualizandoReducer, puntoEliminadoReducer, puntoNuevoReducer, puntosReducer} from "./puntos";
import {lineaActualizandoReducer, lineaEliminadaReducer, lineaNuevaReducer, lineasReducer} from "./lineas";
import {
    poligonoActualizandoReducer,
    poligonoEliminadoReducer,
    poligonoNuevoReducer,
    poligonosReducer
} from "./poligonos";
import {
    trayectoActualizandoReducer,
    trayectoEliminadoReducer,
    trayectoNuevoReducer,
    trayectosReducer
} from "./trayectos";
import {
    recorridoActualizandoReducer,
    recorridoCreandoReducer, recorridoEliminandoReducer,
    recorridoNuevoReducer,
    recorridoReducer,
    recorridosReducer
} from "./recorridos";
import {mapasReducer, mapaReducer} from "./mapas";
import {
    esquemaActualizandoReducer,
    esquemaEliminandoReducer,
    esquemaNuevoReducer,
    sectorActualizandoReducer,
    sectorEliminandoReducer,
    sectorNuevoReducer,
    sectorReducer
} from "./sectores";
import errorReducer from './error'
import { usuarioReducer } from './usuario'
import undoable from "redux-undo";
import {ACTION_TYPES as ACTION_TYPES_TR} from "../actions/trayectos";
import {ACTION_TYPES as ACTION_TYPES_UNDO} from "../actions/undoable";
import {serviciosReducer} from "./servicios";
import {deportesReducer} from "./deportes";
import visorReducer from './visor'
import carritoReducer from "./carrito";
import voluntariosReducer, {
    voluntariosCreateReducer,
    voluntariosDeleteReducer,
    voluntariosEditandoReducer, voluntariosUpdateReducer
} from "./voluntarios"
import tiposMapasReducer from "./tiposMapas"
import { elementoVoluntariosCreateReducer, elementoVoluntariosDeleteReducer } from "./elementosVoluntarios"
import contactosReducer from "./contactos"
import {
    mapasTraficoReducer,
    mapaTraficoCreateReducer,
    mapaTraficoDeleteReducer, mapaTraficoUpdateReducer,
    traficoEditandoReducer
} from "./trafico"
import { elementoTraficoCreateReducer, elementoTraficoDeleteReducer } from "./elementosTrafico"
import {
    mapaMontajeCreateReducer, mapaMontajeDeleteReducer,
    mapaMontajeUpdateReducer,
    mapasMontajeReducer,
    montajeEditandoReducer
} from "./montaje"
import {elementoMontajeDeleteReducer} from "./elementosMontaje";

const actionsIncludeTrayectos = [
    ACTION_TYPES_TR.FETCH_TRAYECTOS_CORRECTO, ACTION_TYPES_TR.ACTUALIZA_COORDS_TRAYECTO, ACTION_TYPES_TR.ADD_TRAYECTO,
    ACTION_TYPES_TR.TRAYECTO_ELIMINADO_CORRECTO, ACTION_TYPES_TR.REMOVE_TRAYECTO
];

export const rootReducer = combineReducers({
    isLoading: isLoadingReducer,
    routing: routerReducer,
    proyecto: proyectoReducer,
    proyectoActualizando: proyectoActualizandoReducer,
    proyectos: proyectosReducer,
    capasFondo: capasFondoReducer,
    capas: capasReducer,
    agrupacionesProyecto: agrupacionesReducer,
    agrupacionActualizando: agrupacionActualizandoReducer,
    agrupacionNueva: agrupacionNuevaReducer,
    agrupacionEliminar: agrupacionEliminarReducer,
    puntos: puntosReducer,
    puntoNuevo: puntoNuevoReducer,
    puntoActualizando: puntoActualizandoReducer,
    puntoEliminado: puntoEliminadoReducer,
    lineas: lineasReducer,
    lineaNueva: lineaNuevaReducer,
    lineaActualizando: lineaActualizandoReducer,
    lineaEliminada: lineaEliminadaReducer,
    poligonos: poligonosReducer,
    poligonoNuevo: poligonoNuevoReducer,
    poligonoActualizando: poligonoActualizandoReducer,
    poligonoEliminado: poligonoEliminadoReducer,
    trayectos: undoable(trayectosReducer, {
        limit: 50,
        filter: (action) => actionsIncludeTrayectos.indexOf(action.type) !== -1,
        undoType: ACTION_TYPES_UNDO.UNDO_TRAYECTOS,
        redoType: ACTION_TYPES_UNDO.REDO_TRAYECTOS
    }),
    trayectoNuevo: trayectoNuevoReducer,
    trayectoActualizando: trayectoActualizandoReducer,
    trayectoEliminado: trayectoEliminadoReducer,
    recorridoCreando: recorridoCreandoReducer,
    mapas: mapasReducer,
    mapa: mapaReducer,
    recorrido: recorridoReducer,
    recorridos: recorridosReducer,
    recorridoNuevo: recorridoNuevoReducer,
    recorridoActualizando: recorridoActualizandoReducer,
    recorridoEliminando: recorridoEliminandoReducer,
    sectorNuevo: sectorNuevoReducer,
    sectorActualizando: sectorActualizandoReducer,
    sectorEliminando: sectorEliminandoReducer,
    esquemaNuevo: esquemaNuevoReducer,
    esquemaActualizando: esquemaActualizandoReducer,
    esquemaEliminando: esquemaEliminandoReducer,
    error: errorReducer,
    usuario: usuarioReducer,
    servicios: serviciosReducer,
    deportes: deportesReducer,
    sector: sectorReducer,
    contadorPeticiones: contadorPeticionesReducer,
    carrito: carritoReducer,
    elementoActualizado: elementoActualizandoReducer,
    editor: editorReducer,
    visor: visorReducer,
    ultimasAgrupacionesCargadas: ultimasAgrupacionesReducer,
    tiposMapas: tiposMapasReducer,
    voluntarios: voluntariosReducer,
    voluntariosEditando: voluntariosEditandoReducer,
    voluntariosCreate: voluntariosCreateReducer,
    voluntariosDelete: voluntariosDeleteReducer,
    voluntariosUpdate: voluntariosUpdateReducer,
    elementoVoluntariosCreate: elementoVoluntariosCreateReducer,
    elementoVoluntariosDelete: elementoVoluntariosDeleteReducer,
    contactos: contactosReducer,
    mapasTrafico: mapasTraficoReducer,
    traficoEditando: traficoEditandoReducer,
    mapaTraficoCreate: mapaTraficoCreateReducer,
    elementoTraficoCreate: elementoTraficoCreateReducer,
    elementoTraficoDelete: elementoTraficoDeleteReducer,
    mapaTraficoDelete: mapaTraficoDeleteReducer,
    mapaTraficoUpdate: mapaTraficoUpdateReducer,
    mapasMontaje: mapasMontajeReducer,
    montajeEditando: montajeEditandoReducer,
    mapaMontajeCreate: mapaMontajeCreateReducer,
    elementoMontajeCreate: elementoTraficoCreateReducer,
    elementoMontajeDelete: elementoMontajeDeleteReducer,
    mapaMontajeUpdate: mapaMontajeUpdateReducer,
    mapaMontajeDelete: mapaMontajeDeleteReducer
})
