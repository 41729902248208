import {API_BASE_URL} from "../constants/api";
import {actions} from "../actions/deportes";
import {actions as actionsComun} from '../actions/comun';
import {actions as actionsError} from '../actions/error';

export function fetchDeportes() {
    return dispatch => {
        dispatch(actions.fetchDeportesPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'deporte/')
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);

                dispatch(actions.fetchDeportesCorrecto(res));
                dispatch(actionsComun.restaPeticion());
            })
            .catch(err => {
               dispatch(actions.fetchDeportesError(err));
               dispatch(actionsError.hasError(err));
               dispatch(actionsComun.restaPeticion());
            });
    }
}
