import React, {useState} from 'react';
import {useDrop} from 'react-dnd';
import TextoElementoAgrupacion from "./TextoElementoAgrupacion";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {Card, Accordion} from "react-bootstrap";

const TextoAgrupacion = props => {
    const {
        nombre, indice, agrupa, multiple, elementos, ordenarElementos, onClickLiElemento, elementoIsVisible,
        onClickVisiblidadElemento, agrupacionIsVisible, agrupacion, onClickVisibilidadAgrupacion, onToggleAgrupacion,
        onDoubleClickAgrupacion, isSeleccionado, puntos
    } = props

    const [, setHasDropped] = useState(false)
    const [, setHasDroppedOnChild] = useState(false)

    const [{ isOver }, drop] = useDrop({
        accept: 'fila' + elementos[0].bloque.capa.id,
        drop: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if(didDrop) return;
            if(indice !== item.id) {
                agrupa(item.id, indice)
                setHasDropped(true);
                setHasDroppedOnChild(didDrop);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.getItem() && monitor.getItem().id !== indice && monitor.isOver()
        })
    })

    let indices = 0
    let punto = !multiple
        ? (elementos[0].bloque.tipo === 'pt' ? puntos.filter(p => p.id === elementos[0].id) : null) : null;
    let color = (punto && punto[0] && !punto[0].es_pk && !punto[0].bloque.es_pk && !punto[0].bloque.automatico)
        ? (punto[0].servicios.length === 1 ? punto[0].servicios[0].color_fondo : elementos[0].color)
        : elementos[0].color
    return [
        <Accordion.Toggle
            eventKey={0}
            key={1}
            className={
                (isOver ? 'texto texto-high' : 'texto') +
                (multiple ? ' editor-carpeta' : (isSeleccionado(elementos[0]) ? ' seleccionado' : ''))
            }
            as={Card.Header}
            onClick={multiple ? () => onToggleAgrupacion(agrupacion) : undefined}
        >
            <div className={'contenedor-mover'}>
                {multiple &&
                <span onClick={multiple ? e => onDoubleClickAgrupacion(e, agrupacion) : undefined}
                      className='editar fas fa-pencil-alt' />}
                <span className='mover fas fa-arrows-alt' />
            </div>
            <div className={'editor-elemento-contenedor'} ref={multiple ? undefined : drop}>
                <FontAwesomeIcon
                    onClick={!multiple
                        ? e => onClickVisiblidadElemento(e, elementos[0])
                        : e => onClickVisibilidadAgrupacion(e, agrupacion)}
                    icon={!multiple
                        ? (elementoIsVisible(elementos[0]) ? faEye : faEyeSlash)
                        : (agrupacionIsVisible(agrupacion) ? faEye : faEyeSlash)}
                />

                {!multiple
                    ? <span className='editor-acordeon-marca-color' style={{backgroundColor: color}}/>
                    : <span className={'fas fa-folder mr-2'} />}
                <span className={'editor-elemento-nombre'}>{nombre}</span>
            </div>
        </Accordion.Toggle>,
        <Accordion.Collapse  eventKey={!multiple ? 1 : 0} className='' key={2}>
            <Card.Body ref={multiple ? drop : undefined}>
                {multiple &&
                    <ul>
                        {elementos.sort((a,b) => a.orden_editor - b.orden_editor).map(el => {
                            indices++;
                            return <TextoElementoAgrupacion
                                indice={indices - 1} key={el.id} elemento={el}
                                ordenarElementos={ordenarElementos}
                                onClickLiElemento={onClickLiElemento}
                                elementoIsVisible={elementoIsVisible}
                                onClickVisiblidadElemento={onClickVisiblidadElemento}
                                isSeleccionado={isSeleccionado}
                                puntos={puntos}
                            />
                        })}
                    </ul>}
            </Card.Body>
        </Accordion.Collapse>
    ]
};

export default TextoAgrupacion;
