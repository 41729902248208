import React from "react";
import BtnBarraHerramientas from "./BtnBarraHerramientas";
import {withTranslation} from "react-i18next";
import {faDownload} from "@fortawesome/free-solid-svg-icons";

const BtnDescargarTrack = props => {
    const t = props.t

    if (props.objetoSeleccionado && props.objetoSeleccionado.descargar) {
        return (
            <BtnBarraHerramientas onClick={props.objetoSeleccionado ? props.objetoSeleccionado.descargar.bind(props.objetoSeleccionado) : undefined}
                                     className={props.className}
                                     icon={faDownload} titulo={t('Descargar')}/>
        )
    }
        return <></>
}

export default withTranslation()(BtnDescargarTrack)
