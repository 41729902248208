export const ACTION_TYPES = {
    FETCH_SERVICIOS_CORRECTO: 'FETCH_SERVICIOS_CORRECTO',
    FETCH_SERVICIOS_ERROR: 'FETCH_SERVICIOS_ERROR',
    FETCH_SERVICIOS_PENDIENTE: 'FETCH_SERVICIOS_PENDIENTE'
};

function fetchServiciosCorrecto(servicios) {
    return {
        type: ACTION_TYPES.FETCH_SERVICIOS_CORRECTO,
        servicios: servicios
    };
}

function fetchServiciosError(err) {
    return {
        type: ACTION_TYPES.FETCH_SERVICIOS_ERROR,
        error: err
    };
}

function fetchServiciosPendiente() {
    return {
        type: ACTION_TYPES.FETCH_SERVICIOS_PENDIENTE
    };
}


export const actions = {
    fetchServiciosCorrecto: fetchServiciosCorrecto,
    fetchServiciosError: fetchServiciosError,
    fetchServiciosPendiente: fetchServiciosPendiente
};
