import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {InputGroup} from "react-bootstrap";
import * as turf from '@turf/turf';
import {getDistanciaDisplay} from "../../reducers/editor";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import './DisplayDistanciaReal.css';

import {actions} from "../../actions/editor";


class DisplayDistanciaReal extends Component{

    constructor(props) {
        super(props);
        this.onLineDrag = this.onLineDrag.bind(this);
    }

    componentDidMount() {
        const {objeto, setDistanciaDisplay} = this.props;
        if(objeto.props.map && objeto.props.map.current) {
            const map = objeto.props.map.current.leafletElement;
            map.on('editable:vertex:drag', this.onLineDrag);
            map.on('editable:vertex:new', this.onLineDrag);
            const elemento = objeto.props.elemento;
            setDistanciaDisplay(turf.length(elemento.coordenadas, {units: 'meters'}));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {setDistanciaDisplay, objeto} = this.props;
        const elemento = objeto.props.elemento;
        const prevElemento = prevProps.objeto.props.elemento;
        if(elemento.id !== prevElemento.id)
            setDistanciaDisplay(turf.length(elemento.coordenadas, {units: 'meters'}))
    }

    onLineDrag(e) {
        const {setDistanciaDisplay} = this.props;
        setDistanciaDisplay(turf.length(e.layer.toGeoJSON().geometry, {units: 'meters'}));
    }

    getDistanciaEnUnidades(){
        const {objeto, distancia} = this.props;
        switch (objeto.props.elemento.unidad_distancia) {
            case 'km':
                return turf.convertLength(distancia, 'meters', 'kilometers').toFixed(3) + ' km';
            case 'ft':
                return turf.convertLength(distancia, 'meters', 'feet').toFixed(2) + ' ft';
            case 'mi':
                return turf.convertLength(distancia, 'meters', 'miles').toFixed(2) + ' mi';
            default:
                return distancia.toFixed(2) + ' m';
        }
    }

    componentWillUnmount() {
        const {objeto, setDistanciaDisplay} = this.props;
        if(objeto.props.map && objeto.props.map.current) {
            const map = objeto.props.map.current.leafletElement;
            map.off('editable:vertex:drag');
            map.off('editable:vertex:new');
        }
        setDistanciaDisplay(0);
    }

    render() {
        const {t} = this.props;
        return <InputGroup className="input-group-propiedad input-group-propiedad-distancia-real">
            <InputGroup.Prepend>
                <InputGroup.Text>{t('distancia-real') + ':'}</InputGroup.Text>
            </InputGroup.Prepend>
            <InputGroup.Text><b>{this.getDistanciaEnUnidades()}</b></InputGroup.Text>
        </InputGroup>
    }
}

const mapStateToProps = state => ({
    distancia: getDistanciaDisplay(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setDistanciaDisplay: actions.setDistanciaDisplay
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DisplayDistanciaReal));