import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/capas";

export function capasReducer(state = initialState.capas, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_CAPAS_PENDIENTE:
            return {
                ...state,
                pendiente: true
            };
        case ACTION_TYPES.FETCH_CAPAS_CORRECTO:
            return {
                ...state,
                list: action.capas,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_CAPAS_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        default :
            return{
                ...state
            };
    }
}


export const getCapasPendiente = state => state.capas.pendiente;
export const getCapas = state => state.capas.list;
export const getCapasError = state => state.capas.error;