export const ACTION_TYPES = {
    FETCH_PROYECTOS_PENDING: 'FETCH_PROYECTOS_PENDING',
    FETCH_PROYECTOS_SUCCESS: 'FETCH_PROYECTOS_SUCCESS',
    FETCH_PROYECTOS_ERROR: 'FETCH_PROYECTOS_ERROR',
    FETCH_PROYECTO_PENDIENTE: 'FETCH_PROYECTO_PENDIENTE',
    FETCH_PROYECTO_CORRECTO: 'FETCH_PROYECTO_CORRECTO',
    FETCH_PROYECTO_ERROR: 'FETCH_PROYECTO_ERROR',
    PROYECTO_ACTUALIZANDO_CORRECTO: 'PROYECTO_ACTUALIZANDO_CORRECTO',
    PROYECTO_ACTUALIZANDO_ERROR: 'PROYECTO_ACTUALIZANDO_ERROR',
    PROYECTO_ACTUALIZANDO_PENDIENTE: 'PROYECTO_ACTUALIZANDO_PENDIENTE'
};

function fetchPoryectosPending() {
    return {
        type: ACTION_TYPES.FETCH_PROYECTOS_PENDING
    }
}

function fetchPoryectosSuccess(proyectos){
    return {
        type: ACTION_TYPES.FETCH_PROYECTOS_SUCCESS,
        proyectos: proyectos
    }
}

function fetchProyectosError(error) {
    return {
        type: ACTION_TYPES.FETCH_PROYECTOS_ERROR,
        error: error
    }
}

function fetchProyectoPendiente() {
    return {
        type: ACTION_TYPES.FETCH_PROYECTO_PENDIENTE
    }
}

function fetchProyectoCorrecto(proyecto) {
    return {
        type: ACTION_TYPES.FETCH_PROYECTO_CORRECTO,
        proyecto: proyecto
    }
}

function fetchProyectoError(err) {
    return {
        type: ACTION_TYPES.FETCH_PROYECTO_ERROR,
        error: err
    }
}

function proyectoActualizandoCorrecto(proyecto) {
    return {
        type: ACTION_TYPES.PROYECTO_ACTUALIZANDO_CORRECTO,
        proyecto: proyecto
    };
}

function proyectoActualizandoError(err) {
    return {
        type: ACTION_TYPES.PROYECTO_ACTUALIZANDO_ERROR,
        error: err
    };
}

function proyectoActualizandoPendiente() {
    return {
        type: ACTION_TYPES.PROYECTO_ACTUALIZANDO_PENDIENTE
    };
}

export const actions = {
    fetchPoryectosPending: fetchPoryectosPending,
    fetchPoryectosSuccess: fetchPoryectosSuccess,
    fetchProyectosError: fetchProyectosError,
    fetchProyectoCorrecto: fetchProyectoCorrecto,
    fetchProyectoPendiente: fetchProyectoPendiente,
    fetchProyectoError: fetchProyectoError,
    proyectoActualizandoCorrecto: proyectoActualizandoCorrecto,
    proyectoActualizandoError: proyectoActualizandoError,
    proyectoActualizandoPendiente: proyectoActualizandoPendiente
}
