/* global fetch */

import { actions } from "../actions/trafico"
import { actions as actionsComun } from '../actions/comun'
import { actions as actionsError } from '../actions/error'
import { API_BASE_URL } from "../constants/api"
import getCsrfToken from "../lib/csrf"
import { createElementoTrafico, deleteElementoTrafico } from "./elementosTrafico"

export function fetchMapasTrafico (params = {}, fields) {
    return dispatch => {
        dispatch(actions.fetchMapasTraficoBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'trafico/')
        if (fields && fields.length) {
            params['fields'] = fields.join(',')
        }
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )
        return fetch(url, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw res.error
                } else if (res && res.length) {
                    dispatch(actions.fetchMapasTraficoSuccess(res))
                    dispatch(actionsComun.restaPeticion())
                } else {
                    dispatch(actionsComun.restaPeticion())
                }
            })
            .catch(err => {
                dispatch(actions.fetchMapasTraficoError(err))
                dispatch(actionsComun.restaPeticion())
                dispatch(actionsError.hasError(err))
            })
    }
}

export function createMapaTrafico (opciones, elementos) {
    return dispatch => {
        dispatch(actions.createMapaTraficoBegin())
        dispatch(actionsComun.sumaPeticion())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'trafico/')
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createMapaTraficoError(data))
                            dispatch(actionsError.hasError(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createMapaTraficoSuccess(data))
                            dispatch(actionsComun.restaPeticion())
                            if (elementos) {
                                for (let i in elementos) {
                                    dispatch(createElementoTrafico({
                                        elemento: elementos[i],
                                        trafico: data.id
                                    }))
                                }
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actions.createMapaTraficoError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function deleteMapaTrafico (mapaTrafico) {
    return dispatch => {
        dispatch(actions.deleteMapaTraficoBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'trafico/' + mapaTrafico.id + '/')
        return fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'X-CSRFToken': getCsrfToken()
            }
        })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                        .then(data => {
                            dispatch(actions.deleteMapaTraficoError(data))
                            dispatch(actionsComun.restaPeticion())
                            dispatch(actionsError.hasError(data))
                        })
                } else {
                    dispatch(actions.deleteMapaTraficoSuccess(mapaTrafico))
                    dispatch(actionsComun.restaPeticion())
                }
            })
            .catch(err => {
                dispatch(actions.deleteMapaTraficoError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function updateMapaTrafico (idMapaTrafico, opciones, prevElem, nextElem) {
    return dispatch => {
        dispatch(actions.updateMapaTraficoBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'trafico/' + idMapaTrafico + '/')
        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actions.updateMapaTraficoError(data))
                            dispatch(actionsError.hasError(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.updateMapaTraficoSuccess(data))
                            dispatch(actionsComun.restaPeticion())
                            if (prevElem && nextElem) {
                                for(let i in prevElem) {
                                    if (!nextElem.includes(prevElem[i])) {
                                        dispatch(deleteElementoTrafico({
                                            elemento: prevElem[i],
                                            trafico: data.id
                                        }))
                                    }
                                }
                                for (let i in nextElem) {
                                    if (!prevElem.includes(nextElem[i])) {
                                        dispatch(createElementoTrafico({
                                            elemento: nextElem[i],
                                            trafico: data.id
                                        }))
                                    }
                                }
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actions.updateMapaTraficoError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}
