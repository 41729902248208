import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/recorridos";
import {ACTION_TYPES as ACTION_TYPES_SEC} from "../actions/sectores";
import {ACTION_TYPES as ACTION_TYPES_PT} from "../actions/puntos";
import update from 'immutability-helper';

export function recorridoCreandoReducer(state = initialState.recorridoCreando, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_SECTOR_POR_DEFECTO:
            const rec =  {
                deporte: null,
                sectores: [{
                    nombre: action.nombre,
                    orden: 1,
                    esquemas:[],
                    id: -1,
                    deporte: null,
                    perfil_publicado: true,
                    distancia_calculada: 0,
                    pdis_recorrido: [],
                    generar_inicio_fin: true,
                    puntos_km_auto: true,
                    cadencia_km: 1000,
                    color: '#aaaaaa'
                }],
                elementos: [],
                nombre: null,
                descripcion: null,
                unidad_distancia: 'm',
                publicado: true,
                dificultad: null,
                superficie: null,
                capa_fondo: 1,
                visible: true
            };
            return rec;
        case ACTION_TYPES.ADD_TRAYECTO_TO_SECTOR: {
            let sectorList = state.sectores.slice();
            const sectorIndex = sectorList.map(sec => sec.id).indexOf(action.sector.id);
            const numTrayectos = sectorList[sectorIndex].esquemas.length;
            sectorList[sectorIndex].esquemas.push({
                orden: numTrayectos + 1,
                repeticiones: 1,
                esquema_simplificado: true,
                trayecto: action.trayecto
            });
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.SET_REPETICIONES_TRAYECTO_SECTOR: {
            let sectorList = state.sectores.slice();
            const sectorIndex = sectorList.map(sec => sec.id).indexOf(action.sector.id);
            const esquemaIndex = sectorList[sectorIndex].esquemas.map(esquema =>
                esquema.orden).indexOf(action.esquema.orden);
            sectorList[sectorIndex].esquemas[esquemaIndex].repeticiones = action.repeticiones;
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.SET_ESQUEMA_SIMPLIFCIADO_TRAYECTO_SECTOR: {
            let sectorList = state.sectores.slice();
            const sectorIndex = sectorList.map(sec => sec.id).indexOf(action.sector.id);
            const esquemaIndex = sectorList[sectorIndex].esquemas.map(esquema =>
                esquema.orden).indexOf(action.esquema.orden);
            sectorList[sectorIndex].esquemas[esquemaIndex].esquema_simplificado = action.esquemaPrincipal;
            return {
                ...state,
                sectores: sectorList
            }
        }
        case ACTION_TYPES.REORDENA_TRAYECTOS_NUEVO: {
            let sectorList = state.sectores.slice();
            const sectorIndex = sectorList.map(sec => sec.id).indexOf(action.sector.id);
            const trayectoMovido = sectorList[sectorIndex].esquemas[action.indexOriginal - 1];
            const nuevaLista = update(sectorList[sectorIndex].esquemas, {
               $splice: [
                   [action.indexOriginal - 1, 1],
                   [action.indexFinal - 1, 0, trayectoMovido]
               ]
            });

            for(let i in nuevaLista) {
                nuevaLista[i].orden = parseInt(i) + 1;
            }
            sectorList[sectorIndex].esquemas = nuevaLista;
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.ELIMINA_TRAYECTO_SECTOR: {
            let sectorList = state.sectores.slice();
            const sectorIndex = sectorList.map(sec => sec.id).indexOf(action.sector.id);
            sectorList[sectorIndex].esquemas.splice(action.indexTrayecto, 1);
            for(let i in sectorList[sectorIndex].esquemas) {
                sectorList[sectorIndex].esquemas[i].orden = parseInt(i) + 1;
            }
            return {
                ...state,
                sectores: sectorList
            }
        }
        case ACTION_TYPES.ADD_SECTOR_NUEVO:
            return {
                ...state,
                sectores: state.sectores.concat({
                    nombre: action.nombre,
                    orden: state.sectores.length  + 1,
                    esquemas:[],
                    id: -(state.sectores.length + 1),
                    deporte: null,
                    perfil_publicado: true,
                    distancia_calculada: 0,
                    pdis_recorrido: [],
                    generar_inicio_fin: true,
                    color: '#aaaaaa'
                })
            };
        case ACTION_TYPES.ELIMINAR_SECTOR_NUEVO:
            return {
                ...state,
                sectores: state.sectores.filter(sector => sector.id !== action.sector.id)
            };
        case ACTION_TYPES.SET_RECORRIDO_CREANDO:
            return action.recorrido;
        case ACTION_TYPES.CAMBIAR_NOMBRE_SECTOR_NUEVO: {
            const sectorList = state.sectores.slice();
            const indexSector = sectorList.map(sector => sector.id).indexOf(action.sector.id);
            sectorList[indexSector].nombre = action.nombre;
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.CAMBIAR_DEPORTE_SECTOR: {
            const sectorList = state.sectores.slice();
            const indexSector = sectorList.map(sector => sector.id).indexOf(action.sector.id);
            sectorList[indexSector].deporte = action.deporte;
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.ADD_AGRUPACION_RECORRIDO_CREANDO:
            return {
                ...state,
                agrupacion: state.agrupacion.concat(action.agrupacion.id)
            };
        case ACTION_TYPES.REMOVE_AGRUPACION_RECORRIDO_CREANDO:
            return {
                ...state,
                agrupacion: state.agrupacion.filter(idAgrupacion => idAgrupacion !== action.agrupacion.id)
            };
        case ACTION_TYPES.ADD_ELEMENTO_RECORRIDO_CREANDO:
            return {
                ...state,
                elementos: state.elementos.concat({
                    elemento: action.elemento,
                    orden: action.orden
                }).sort((a,b) => a.orden - b.orden)
            };
        case ACTION_TYPES.REMOVE_ELEMENTO_RECORRIDO_CREANDO: {
            let list = state.elementos.filter(er => er.elemento !== action.elemento);
            for(let i in list) {
                list[i].orden = i;
            }
            return {
                ...state,
                elementos: list
            };
        }
        case ACTION_TYPES.REORDENA_PDIS: {
            let list = state.elementos.slice().sort((a,b) => a.orden - b.orden);
            const indexOriginal = list.map(er => er.orden).indexOf(action.ordenOriginal);
            const indexFinal = list.map(er => er.orden).indexOf(action.ordenFinal);
            const pdiMov = list[indexOriginal];
            list.splice(indexOriginal, 1);
            list.splice(indexFinal, 0, pdiMov)
            for(let i in list) {
                list[i].orden = parseInt(i) + 1;
            }
            list.sort((a,b) => a.orden - b.orden);
            return {
                ...state,
                elementos: list
            };
        }
        case ACTION_TYPES.CAMBIAR_DEPORTE_RECORRIDO_CREANDO:
            return {
                ...state,
                deporte: action.deporte
            };
        case ACTION_TYPES.SET_PERFIL_PUBLICADO: {
            const sectorList = state.sectores.slice();
            const indexSector = sectorList.map(sector => sector.id).indexOf(action.idSector);
            sectorList[indexSector].perfil_publicado = action.publicado;
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.SET_DATOS_RECORRIDO_CREANDO: {
            return {
                ...state,
                nombre: action.datosRecorrido.nombre ? action.datosRecorrido.nombre : state.nombre,
                descripcion: action.datosRecorrido.descripcion ? action.datosRecorrido.descripcion : state.descripcion,
                unidad_distancia: action.datosRecorrido.unidad_distancia ? action.datosRecorrido.unidad_distancia: state.unidad_distancia,
                superficie: action.datosRecorrido.superficie ? action.datosRecorrido.superficie : state.superficie,
                dificultad: action.datosRecorrido.dificultad ? action.datosRecorrido.dificultad : state.dificultad,
                deporte: action.datosRecorrido.deporte ? action.datosRecorrido.deporte : state.deporte,
                publicado: action.datosRecorrido.publicado ? action.datosRecorrido.publicado : state.publicado,
            };
        }
        case ACTION_TYPES.ADD_PDI_SECTOR: {
            const sectorList = state.sectores.slice();
            const indexSector = sectorList.map(sec => sec.id).indexOf(action.sector.id);
            sectorList[indexSector].pdis_recorrido = sectorList[indexSector].pdis_recorrido.concat(action.pdi);
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.REMOVE_PDI_SECTOR: {
            const sectorList = state.sectores.slice();
            const indexSector = sectorList.map(sec => sec.id).indexOf(action.sector.id);
            sectorList[indexSector].pdis_recorrido = sectorList[indexSector].pdis_recorrido.filter(p =>
                p.id !== action.pdi.id);
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.CAMBIAR_UNIDAD_DISTANCIA: {
            let cadencia = action.unidad === 'km' || action.unidad === 'mi' ? 1 : (action.unidad === 'm' ? 1000 : 100);
            const sectorList = state.sectores.slice();
            for(let i in sectorList) {
                sectorList[i].cadencia_km = cadencia;
            }
            return {
                ...state,
                sectores: sectorList,
                unidad_distancia: action.unidad
            };
        }
        case ACTION_TYPES.CAMBIAR_CAPA_FONDO_RECORRIDO:
            return {
                ...state,
                capa_fondo: action.capa
            };
        case ACTION_TYPES.SET_GENERAR_INICIO_FIN_SECTOR: {
            const sectorList = state.sectores.slice();
            const indexSector = sectorList.map(sec => sec.orden).indexOf(action.sector.orden);
            sectorList[indexSector].generar_inicio_fin = action.valor;
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.SET_PUNTOS_KM_AUTO: {
            const sectorList = state.sectores.slice();
            const indexSector = sectorList.map(s => s.orden).indexOf(action.sector.orden);
            sectorList[indexSector].puntos_km_auto = action.valor;
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.SET_CADENCIA_PKS: {
            const sectorList = state.sectores.slice();
            const indexSector = sectorList.map(s => s.orden).indexOf(action.sector.orden);
            sectorList[indexSector].cadencia_km = action.cadencia;
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.SET_COLOR_SECTOR: {
            const sectorList = state.sectores.slice();
            const indexSector = sectorList.map(sector => sector.orden).indexOf(action.sector.orden);
            sectorList[indexSector].color = action.color;
            return {
                ...state,
                sectores: sectorList
            };
        }
        case ACTION_TYPES.ELIMINA_PKS_RECORRIDO_CREANDO: {
            const sectorList = state.sectores.slice();
            const indexSector =  sectorList.map(s => s.orden).indexOf(action.sector.orden);
            sectorList[indexSector].puntos_km = sectorList[indexSector].puntos_km.filter(pk => !pk.punto.semiautomatico);
            sectorList[indexSector].puntos_km_auto = false;
            return {
                ...state,
                sectores: sectorList,
            };
        }
        case ACTION_TYPES.SET_VISTA_INICIAL: {
            return {
                ...state,
                vista_inicial: action.vista
            };
        }
        default:
            return {
                ...state
            };
    }
}

export function recorridoReducer(state = initialState.recorrido, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_RECORRIDO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: false
            };
        case ACTION_TYPES.FETCH_RECORRIDO_ERROR:
            return {
                ...state,
                data: null,
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.FETCH_RECORRIDO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                data: action.recorrido
            };
        default:
            return {
                ...state
            };
    }
}

export function recorridosReducer(state = initialState.recorridos, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_RECORRIDOS_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                list: [],
                error: false
            };
        case ACTION_TYPES.FETCH_RECORRIDOS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.FETCH_RECORRIDOS_CORRECTO:
            return {
                ...state,
                pendiente: false,
                list: action.recorridos
            };
        case ACTION_TYPES.ADD_RECORRIDO:
        case ACTION_TYPES.NUEVO_RECORRIDO_CORRECTO:
            return {
                ...state,
                list: state.list.concat(action.recorrido).sort((a,b) => a.orden - b.orden)
            };
        case ACTION_TYPES_SEC.NUEVO_SECTOR_CORRECTO:
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.sector.recorrido);
            list[indexRecorrido].sectores.push(action.sector);
            return {
                ...state,
                list: list
            };
        case ACTION_TYPES_SEC.NUEVO_TRAYECTO_ESQUEMA_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.recorridoId);
            const indexSector = list[indexRecorrido].sectores.map(sector => sector.id).indexOf(action.esquema.sector);
            list[indexRecorrido].sectores[indexSector].esquemas.push(action.esquema);
            return {
                ...state,
                list: list
            }
        }
        case ACTION_TYPES.RECORRIDO_ELIMINANDO_CORRECTO:
        case ACTION_TYPES.REMOVE_RECORRIDO:
            return {
                ...state,
                list: state.list.filter(recorrido => recorrido.id !== action.recorrido.id)
            };
        case ACTION_TYPES_SEC.SECTOR_ACTUALIZANDO_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.sector.recorrido);
            const indexSector = list[indexRecorrido].sectores.map(sector => sector.id).indexOf(action.sector.id);
            list[indexRecorrido].sectores[indexSector] = action.sector;
            return {
                ...state,
                list: list
            }
        }
        case ACTION_TYPES_SEC.SECTOR_ELIMINANDO_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido  => recorrido.id).indexOf(action.recorridoId);
            list[indexRecorrido].sectores = list[indexRecorrido].sectores.filter(sector => sector.id !== action.sector.id);
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES_SEC.ESQUEMA_ELIMINANDO_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.recorridoId);
            const indexSector = list[indexRecorrido].sectores.map(sector => sector.id).indexOf(action.esquema.sector);
            list[indexRecorrido].sectores[indexSector].esquemas = list[indexRecorrido].sectores[indexSector].esquemas.filter(esquema =>
                esquema.id !== action.esquema.id);
            return {
                ...state,
                list: list
            }
        }
        case ACTION_TYPES_SEC.ESQUEMA_ACTUALIZANDO_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.recorridoId);
            const indexSector = list[indexRecorrido].sectores.map(sector => sector.id).indexOf(action.esquema.sector);
            const indexEsquema = list[indexRecorrido].sectores[indexSector].esquemas.map(esquema =>
                esquema.id).indexOf(action.esquema.id);
            list[indexRecorrido].sectores[indexSector].esquemas[indexEsquema] = action.esquema;
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.NUEVO_PK_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.idRecorrido);
            const indexSector = list[indexRecorrido].sectores.map(sec => sec.id).indexOf(action.pk.sector);
            list[indexRecorrido].sectores[indexSector].puntos_km = list[indexRecorrido].sectores[indexSector].puntos_km.concat(action.pk);
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.PK_ELIMINANDO_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.idRecorrido);
            const indexSector = list[indexRecorrido].sectores.map(sec => sec.id).indexOf(action.pk.sector);
            list[indexRecorrido].sectores[indexSector].puntos_km = list[indexRecorrido].sectores[indexSector].puntos_km.filter(pk =>
                pk.id !== action.pk.id);
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.PK_ACTUALIZANDO_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.idRecorrido);
            const indexSector = list[indexRecorrido].sectores.map(sec => sec.id).indexOf(action.pk.sector);
            const indexPk = list[indexRecorrido].sectores[indexSector].puntos_km.map(pk => pk.id).indexOf(action.pk.id);
            list[indexRecorrido].sectores[indexSector].puntos_km[indexPk] = action.pk;
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.NUEVO_ELEMENTO_RECORRIDO_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.elementoRecorrido.recorrido);
            list[indexRecorrido].elementos.push(action.elementoRecorrido);
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.ELEMENTO_RECORRIDO_ACTUALIZANDO_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.elementoRecorrido.recorrido);
            const indexEr = list[indexRecorrido].elementos.map(er => er.id).indexOf(action.elementoRecorrido.id);
            list[indexRecorrido].elementos[indexEr] = action.elementoRecorrido;
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.ELEMENTO_RECORRIDO_ELIMINANDO_CORRECTO: {
            const list = state.list.slice();
            const indexRecorrido = list.map(recorrido => recorrido.id).indexOf(action.elementoRecorrido.recorrido);
            list[indexRecorrido].elementos = list[indexRecorrido].elementos.filter(er =>
                er.id !== action.elementoRecorrido.id);
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.ADD_PDI_CREADO_SECTOR: {
            const list = state.list.slice();
            const indexRecorrido = list.map(r => r.id).indexOf(action.sector.recorrido);
            const indexSector = list[indexRecorrido].sectores.map(s => s.id).indexOf(action.sector.id);
            list[indexRecorrido].sectores[indexSector].pdis_recorrido = list[indexRecorrido].sectores[indexSector].pdis_recorrido.concat(action.pdi);
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.REMOVE_PDI_CREADO_SECTOR: {
            const list = state.list.slice();
            const indexRecorrido = list.map(r => r.id).indexOf(action.sector.recorrido);
            const indexSector = list[indexRecorrido].sectores.map(s => s.id).indexOf(action.sector.id);
            list[indexRecorrido].sectores[indexSector].pdis_recorrido =
                list[indexRecorrido].sectores[indexSector].pdis_recorrido.filter(pdi =>
                    (pdi.id ? pdi.id : pdi) !== action.pdi.id);
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES_PT.PUNTO_ACTUALIZANDO_CORRECTO: {
            const list = state.list.slice();
            list.forEach(r => {
                r.sectores.forEach(s => {
                    if (action.punto.sectores.includes(s.id) &&
                        !s.pdis_recorrido.map(pdi => pdi.id ? pdi.id : pdi).includes(action.punto.id)) {
                        s.pdis_recorrido = s.pdis_recorrido.concat(action.punto);
                    }
                    if (s.pdis_recorrido.map(pdi => pdi.id ? pdi.id : pdi).includes(action.punto.id) &&
                        !action.punto.sectores.includes(s.id)) {
                        s.pdis_recorrido = s.pdis_recorrido.filter(pdi => pdi !== action.punto.id);
                    }
                })
            })
            return {
                ...state,
                list: list
            }
        }
        default:
            return {
                ...state
            };
    }
}

export function recorridoNuevoReducer(state = initialState.recorridoNuevo, action) {
    switch (action.type) {
        case ACTION_TYPES.NUEVO_RECORRIDO_CORRECTO:
            return {
                pendiente: false,
                recorrido: action.recorrido
            };
        case ACTION_TYPES.NUEVO_RECORRIDO_ERROR:
            return {
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.NUEVO_RECORRIDO_PENDIENTE:
            return {
                pendiente: true,
                error: action.error,
                recorrido: null
            };
        default:
            return {
                ...state
            };
    }
}

export function recorridoActualizandoReducer(state = initialState.recorridoActualizando, action) {
    switch (action) {
        case ACTION_TYPES.RECORRIDO_ACTUALIZANDO_CORRECTO:
            return{
                ...state,
                pendiente: false,
                recorrido: action.recorrido
            };
        case ACTION_TYPES.RECORRIDO_ACTUALIZANDO_PENDIENTE:
            return{
                ...state,
                pendiente: true,
                error: false
            };
        case ACTION_TYPES.RECORRIDO_ACTUALIZANDO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        default:
            return {
                ...state
            };
    }
}

export function recorridoEliminandoReducer(state = initialState.recorridoEliminando, action) {
    switch (action.type) {
        case ACTION_TYPES.RECORRIDO_ELIMINANDO_CORRECTO:
            return {
                ...state,
                correcto: true,
                pendiente: false,
                recorrido: action.recorrido
            };
        case ACTION_TYPES.RECORRIDO_ELIMINANDO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.RECORRIDO_ELIMINANDO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                error: false,
                correcto: false,
                recorrido: action.recorrido
            };
        default:
            return {
                ...state
            };
    }
}

export const getRecorridoCreando = state => state.recorridoCreando;
export const getRecorridoNuevo = state => state.recorridoNuevo.recorrido;
export const getRecorridoNuevoPendiente = state => state.recorridoNuevo.pendiente;
export const getRecorridoNuevoError = state => state.recorridoNuevo.error;
export const getRecorrido = state => state.recorrido.data;
export const getRecorridoError = state => state.recorrido.error;
export const getRecorridoPendiente = state => state.recorrido.pendiente;
export const getRecorridos = state => state.recorridos.list;
export const getRecorridosError = state => state.recorridos.error;
export const getRecorridosPendiente = state => state.recorridos.pendiente;
export const getRecorridoActualizando = state => state.recorridoActualizando.recorrido;
export const getRecorridoActualizandoCorrecto = state => state.recorridoActualizando.correcto;
export const getRecorridoActualizandoError = state => state.recorridoActualizando.error;
export const getRecorridoActualizandoPendiente = state => state.recorridoActualizando.pendiente;
export const getRecorridoEliminando = state => state.recorridoEliminando.recorrido;
export const getRecorridoEliminandoPendiente = state => state.recorridoEliminando.pendiente;
export const getRecorridoEliminandoCorrecto = state => state.recorridoEliminando.correcto;
export const getRecorridoEliminandoError = state => state.recorridoEliminando.error;
