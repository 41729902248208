export const ACTION_TYPES = {
    CARRITO_RESET: 'CARRITO_RESET',
    CARRITO_SET_PROYECTO: 'CARRITO_SET_PROYECTO',
    CARRITO_ADD_RECORRIDOS: 'CARRITO_ADD_RECORRIDOS',
    CARRITO_UPDATE_PRECIO: 'CARRITO_UPDATE_PRECIO',
    CARRITO_SET_CUSTOMER: 'CARRITO_SET_CUSTOMER',
    CARRITO_SET_PROCESANDO: 'CARRITO_SET_PROCESANDO',
    CARRITO_UNSET_PROCESANDO: 'CARRITO_UNSET_PROCESANDO',
    CARRITO_MODAL_VISIBLE: 'CARRITO_MODAL_VISIBLE',
    CARRITO_PAGO_OK: 'CARRITO_PAGO_OK',
    CARRITO_PAGO_KO: 'CARRITO_PAGO_KO',
    CARRITO_SET_STRIPE_CARD_ERROR: 'CARRITO_SET_STRIPE_CARD_ERROR',
}

function carritoReset () {
    return {
        type: ACTION_TYPES.CARRITO_RESET
    }
}


function carritoSetProyecto (proyecto) {
    return {
        type: ACTION_TYPES.CARRITO_SET_PROYECTO,
        proyecto: proyecto
    }

}


function carritoUpdateRecorridos (recorridos) {
    return {
        type: ACTION_TYPES.CARRITO_ADD_RECORRIDOS,
        recorridos: recorridos
    }
}


function carritoUpdatePrecio (precio) {
    return {
        type: ACTION_TYPES.CARRITO_UPDATE_PRECIO,
        precio: precio
    }
}


function carritoSetCustomer(customer) {
    return {
        type: ACTION_TYPES.CARRITO_SET_CUSTOMER,
        customer: customer
    }
}

function carritoSetProcesando() {
    return {
        type: ACTION_TYPES.CARRITO_SET_PROCESANDO,
        procesando: true
    }
}

function carritoUnsetProcesando() {
    return {
        type: ACTION_TYPES.CARRITO_UNSET_PROCESANDO,
        procesando: false
    }
}


function carritoModalVisible(visible) {
    return {
        type: ACTION_TYPES.CARRITO_MODAL_VISIBLE,
        modalVisible: visible
    }
}

function carritoPagoOk() {
    return {
        type: ACTION_TYPES.CARRITO_PAGO_OK
    }
}

function carritoPagoKo(errorDesc, errorCode=null) {
    return {
        type: ACTION_TYPES.CARRITO_PAGO_KO,
        pagoErrorCode: errorCode,
        pagoErrorDesc: errorDesc
    }
}


function carritoSetStirpeCardError(errorMsg=null) {
    return {
        type: ACTION_TYPES.CARRITO_SET_STRIPE_CARD_ERROR,
        stripeCardError: errorMsg
    }
}


export const actions = {
    carritoReset: carritoReset,
    carritoSetProyecto: carritoSetProyecto,
    carritoUpdateRecorridos: carritoUpdateRecorridos,
    carritoUpdatePrecio: carritoUpdatePrecio,
    carritoSetCustomer: carritoSetCustomer,
    carritoSetProcesando: carritoSetProcesando,
    carritoUnsetProcesando: carritoUnsetProcesando,
    carritoModalVisible: carritoModalVisible,
    carritoPagoOk: carritoPagoOk,
    carritoPagoKo: carritoPagoKo,
    carritoSetStirpeCardError: carritoSetStirpeCardError
}


export default actions
