import {API_BASE_URL} from "../constants/api";
import {actions as actionsComun} from "../actions/comun";
import {actions} from "../actions/capas";

export function fetchCapas() {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.fetchCapasPendiente());
        fetch(API_BASE_URL + 'capa/')
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);
                dispatch(actions.fetchCapasCorrecto(res));
                dispatch(actionsComun.restaPeticion());
            })
            .catch(error => {
                dispatch(actions.fetchCapasError(error));
                dispatch(actionsComun.restaPeticion());
            })
    }
}
