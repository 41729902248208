import {API_BASE_URL} from "../constants/api";
import {actions as actionsComun} from "../actions/comun";
import {actions} from "../actions/usuario";
import { actions as actionsError } from '../actions/error'

export function fetchUsuario() {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.fetchUsuarioPendiente());
        return fetch(API_BASE_URL + 'usuario/yo/', {
            credentials: 'include'
        })
            .then(res => {
                dispatch(actionsComun.restaPeticion())
                if (res.status === 200) {
                    return res.json()
                        .then(data => dispatch(actions.fetchUsuarioCorrecto(data)))
                } else {
                    return dispatch(actions.fetchUsuarioError(res.status))
                }
            })
            .catch(error => {
                dispatch(actionsComun.restaPeticion());
                return dispatch(actionsError.hasError(error));
            })
    }
}
