import React, { Component } from "react"
import { connect } from 'react-redux'
import { withTranslation, Trans } from "react-i18next"
import { Tab, Tabs } from "react-bootstrap"

import { actions } from "../../actions/editor"
import { actions as actionsTrafico } from '../../actions/trafico'
import ModalEditor from "./ModalEditor"
import { TIPOS_MAPAS_NOMBRES } from "../../constants/state"
import { createMapaTrafico } from "../../fetchactions/trafico"

export class ModalNuevoMapaTrafico extends Component {
    constructor (props) {
        super(props)
        this.state = {
            filtroElementos: ''
        }
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(actionsTrafico.resetTraficoEditando())
    }

    valida () {
        const { t, traficoEditando } = this.props
        if (!traficoEditando.nombre || traficoEditando.nombre.length === 0) {
            alert(t('El mapa debe de tener un nombre'))
            return false
        }
        return true
    }

    handleClickAceptar () {
        const { dispatch, traficoEditando, proyecto } = this.props
        if (this.valida()) {
            dispatch(createMapaTrafico({
                nombre: traficoEditando.nombre,
                capa_fondo: traficoEditando.capa_fondo,
                visible: traficoEditando.visible,
                descripcion: traficoEditando.descripcion,
                meta_descripcion: traficoEditando.meta_descripcion,
                proyecto: proyecto.id
            }, traficoEditando.elementos_trafico))
            this.hide()
        }
    }

    handleClickCancelar () {
        this.hide()
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalNuevoTrafico(false))
        dispatch(actions.setVisibleModalTrafico(true))
    }

    handleChangeNombre (e) {
        const { dispatch } = this.props
        dispatch(actionsTrafico.setNombre(e.target.value))
    }

    handleChangeCapaFondo (e) {
        const { dispatch } = this.props
        dispatch(actionsTrafico.setCapaFondo(e.target.value))
    }

    handleChangeVisible (e) {
        const { dispatch } = this.props
        dispatch(actionsTrafico.setVisible(e.target.checked))
    }

    handleChangeDescripcion (e) {
        const { dispatch } = this.props
        dispatch(actionsTrafico.setDescripcion(e.target.value))
    }

    handleChangeMetaDescripcion (e) {
        const { dispatch } = this.props
        dispatch(actionsTrafico.setMetaDescripcion(e.target.value))
    }

    handleChangeFiltroElementos (e) {
        this.setState({
            filtroElementos: e.target.value
        })
    }

    isCapaInTrafico (capa) {
        const { traficoEditando } = this.props
        for (let i in capa.elementos) {
            if (!traficoEditando.elementos_trafico.includes(capa.elementos[i].id)) {
                return false
            }
        }
        return true
    }

    handleChangeCapa (e, capa) {
        const { traficoEditando, dispatch } = this.props
        if (e.target.checked) {
            for (let i in capa.elementos) {
                if (!traficoEditando.elementos_trafico.includes(capa.elementos[i].id)) {
                    dispatch(actionsTrafico.addElementoTrafico(capa.elementos[i].id))
                }
            }
        } else {
            for (let i in capa.elementos) {
                if (traficoEditando.elementos_trafico.includes(capa.elementos[i].id)) {
                    dispatch(actionsTrafico.removeElementoTrafico(capa.elementos[i].id))
                }
            }
        }
    }

    isElementoInVoluntarios (elemento) {
        const { traficoEditando } = this.props
        return traficoEditando.elementos_trafico.includes(elemento.id)
    }

    handleChangeElemento (e, elemento) {
        const { dispatch } = this.props
        if (e.target.checked) {
            dispatch(actionsTrafico.addElementoTrafico(elemento.id))
        } else {
            dispatch(actionsTrafico.removeElementoTrafico(elemento.id))
        }
    }

    render() {
        const {
            t, traficoEditando, capasFondo, tiposMapas, trayectos, puntos, lineas, poligonos, agrupaciones
        } = this.props
        let capasPermitidas = tiposMapas.filter(t => t.nombre === TIPOS_MAPAS_NOMBRES.TRAFICO)[0].capa_permitida
        const elem2Capa = array => {
            array.forEach(e => {
                if (capasPermitidas.map(c => c.id).includes(e.bloque.capa.id)) {
                    const i = capasPermitidas.map(c => c.id).indexOf(e.bloque.capa.id)
                    if (!capasPermitidas[i].elementos || !capasPermitidas[i].elementos.map(e => e.id).includes(e.id)) {
                        capasPermitidas[i].elementos = capasPermitidas[i].elementos
                            ? capasPermitidas[i].elementos.concat(e) : [e]
                    }
                }
            })
        }
        elem2Capa(trayectos)
        elem2Capa(puntos)
        elem2Capa(lineas)
        elem2Capa(poligonos)
        capasPermitidas.forEach(cp => cp.elementos && cp.elementos.sort((a, b) => {
            const agrupA = agrupaciones.filter(ag => ag.elementos.map(e => e.id).includes(a.id))
            const agrupB = agrupaciones.filter(ag => ag.elementos.map(e => e.id).includes(b.id))
            if (agrupA.length && agrupB.length) {
                if (agrupA[0].id === agrupB[0].id) return a.orden_editor - b.orden_editor
                return agrupA[0].orden - agrupB[0].orden
            }
            return a.orden_editor - b.orden_editor
        }))
        capasPermitidas = capasPermitidas.filter(c => {
            const nombreCoincide = new RegExp([this.state.filtroElementos].join('|'), 'i').test(c.nombre)
            const elementosFiltrados = c.elementos && c.elementos.filter(p =>
                new RegExp([this.state.filtroElementos].join('|'), 'i').test(p.nombre))
            if (!nombreCoincide) {
                c.elementos = elementosFiltrados
            }
            return nombreCoincide || (elementosFiltrados && elementosFiltrados.length)
        })
        return (
            <ModalEditor
                show
                footer
                onClickAceptar={this.handleClickAceptar.bind(this)}
                onClickCancelar={this.handleClickCancelar.bind(this)}
                onHide={this.handleClickCancelar.bind(this)}
                titulo={t('Nuevo mapa de tráfico')}
            >
                <div className='row'>
                    <div className='col-12 form-group'>
                        <label htmlFor='nombre-mapa-trafico'>
                            <strong><Trans>Nombre</Trans></strong>
                        </label>
                        <input
                            type='text'
                            id='nombre-mapa-trafico'
                            name='nombre'
                            className='form-control'
                            onChange={this.handleChangeNombre.bind(this)}
                            value={traficoEditando.nombre}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Tabs defaultActiveKey={0} className='nav-fill' id='tabs-trafico'>
                            <Tab eventKey={0} title={t('General')}>
                                <div className='row'>
                                    <div className='col-9 form-group'>
                                        <label htmlFor='capa-fondo-trafico'>
                                            <strong><Trans>Capa de fondo</Trans></strong>
                                        </label>
                                        <select
                                            className='form-control'
                                            id='capa-fondo-trafico'
                                            name='capa_fondo'
                                            value={traficoEditando.capa_fondo}
                                            onChange={this.handleChangeCapaFondo.bind(this)}
                                        >
                                            {capasFondo.map(cf =>
                                                <option key={cf.id} value={cf.id}>{cf.nombre}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className='col-3 form-group form-check'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            id='visible-trafico'
                                            name='visible'
                                            checked={traficoEditando.visible}
                                            onChange={this.handleChangeVisible.bind(this)}
                                        />
                                        <label className='form-check-label' htmlFor='visible-trafico'>
                                            <Trans><strong>Visible</strong></Trans>
                                        </label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 form-group'>
                                        <label htmlFor='descripcion-trafico'>
                                            <strong><Trans>Descripción</Trans></strong>
                                        </label>
                                        <textarea
                                            className='form-control'
                                            id='descripcion-trafico'
                                            name='descripcion'
                                            value={traficoEditando.descripcion}
                                            onChange={this.handleChangeDescripcion.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 form-group'>
                                        <label htmlFor='meta-descripcion-trafico'>
                                            <strong><Trans>Descripción para redes sociales</Trans></strong>
                                        </label>
                                        <textarea
                                            className='form-control'
                                            id='meta-descripcion-trafico'
                                            name='meta_descripcion'
                                            value={traficoEditando.meta_descripcion}
                                            onChange={this.handleChangeMetaDescripcion.bind(this)}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey={1} title={t('Ubicaciones')}>
                                <div className='row'>
                                    <div className='col-6'>
                                        <p><Trans>Elige entre los elementos disponibles para este mapa:</Trans></p>
                                    </div>
                                    <div className='col-6 form-group'>
                                        <input
                                            className='form-control'
                                            placeholder={t('Filtrar')}
                                            value={this.state.filtroElementos}
                                            onChange={this.handleChangeFiltroElementos.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <ul className='list-group'>
                                            {capasPermitidas.map(cp => cp.elementos && cp.elementos.length > 0 &&
                                                <li key={cp.id} className='list-group-item'>
                                                    <div className='form-group form-check'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input '
                                                            id={'input-capa-trafico-' + cp.id}
                                                            checked={this.isCapaInTrafico(cp)}
                                                            onChange={e => this.handleChangeCapa(e, cp)}
                                                        />
                                                        <label htmlFor={'input-capa-trafico-' + cp.id}>
                                                            <strong>{cp.nombre}</strong>
                                                        </label>
                                                    </div>
                                                    <ul className='list-group list-group-flush ml-2'>
                                                        {cp.elementos.map(e =>
                                                            <li
                                                                key={e.id}
                                                                className='list-group-item form-group form-check'
                                                            >
                                                                <input
                                                                    type='checkbox'
                                                                    className='form-check-input'
                                                                    id={'input-elemento-trafico-' + e.id}
                                                                    checked={this.isElementoInVoluntarios(e)}
                                                                    onChange={ev => this.handleChangeElemento(ev, e)}
                                                                />
                                                                <label
                                                                    className='d-flex'
                                                                    htmlFor={'input-elemento-trafico-' + e.id}
                                                                >
                                                                    {e.nombre}
                                                                    <div
                                                                        className='n-voluntarios__ele-icono d-flex
                                                                            align-items-center justify-content-center'
                                                                        style={{
                                                                            backgroundColor: e.color,
                                                                            color: e.bloque.props_defecto.color_icono
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className={e.bloque.icono_descripcion}
                                                                        />
                                                                    </div>
                                                                </label>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </ModalEditor>
        )
    }
}

const mapStateToProps = state => ({
    traficoEditando: state.traficoEditando,
    capasFondo: state.capasFondo.list,
    trayectos: state.trayectos.present.list,
    puntos: state.puntos.list,
    poligonos: state.poligonos.list,
    lineas: state.lineas.list,
    tiposMapas: state.tiposMapas.data,
    agrupaciones: state.agrupacionesProyecto.list,
    proyecto: state.proyecto.data
})

export default withTranslation()(connect(mapStateToProps)(ModalNuevoMapaTrafico))
