import React, {Component} from 'react';
import {Polyline} from "react-leaflet";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {actualizaTrayecto, eliminarTrayecto} from "../fetchactions/trayectos";
import {actions} from "../actions/trayectos";
import {actions as actionsEditor} from "../actions/editor";
import {getDuplicando} from "../reducers/editor";
import {getTrayectoActualizando, getTrayectoActualizandoCorrecto} from "../reducers/trayectos";

require('leaflet-editable');

class NuevoTrayecto extends Component{
    constructor(props){
        super(props);
        this.polyRef = React.createRef();
        this.type = 'ntr';

        this.onDrawingEnd = this.onDrawingEnd.bind(this);
        this.onPressEsc = this.onPressEsc.bind(this);
    }

    componentDidMount() {
        const {duplicando, setDuplicando, resetTrayectoNuevo, elemento, addTrayecto, objetoSeleccionado,
        removeCapaColapsada} = this.props;
        removeCapaColapsada(elemento.bloque.capa);
        if(!duplicando) {
            const polyline = this.polyRef.current.leafletElement;
            polyline.enableEdit().newShape();
            polyline.on('editable:drawing:end', this.onDrawingEnd);
            objetoSeleccionado(this);
            document.addEventListener('keyup', this.onPressEsc);
        } else {
            setDuplicando(false);
            resetTrayectoNuevo();
            addTrayecto(elemento);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const polyline = this.polyRef.current.leafletElement;
        polyline.enableEdit().newShape();
    }

    onDrawingEnd() {
        if(this.polyRef.current) {
            const polyline = this.polyRef.current.leafletElement;
            const {elemento, actualizaTrayecto, trayectoActualizando, setVisibleModalPropAvanzadas} = this.props;
            polyline.disableEdit();
            actualizaTrayecto(elemento.id, {
                nombre: trayectoActualizando && trayectoActualizando.id === elemento.id ?
                    trayectoActualizando.nombre : elemento.nombre,
                bloque: elemento.bloque.id,
                coordenadas: polyline.toGeoJSON().geometry
            });
            setVisibleModalPropAvanzadas(true)
            //resetTrayectoNuevo();
        }
    }

    getUltimasCoords() {
        const polyline = this.polyRef.current.leafletElement;
        return polyline.toGeoJSON().geometry;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {trayectoActualizando, trayectoActualizandoCorrecto, elemento} = this.props;
        const polyline = this.polyRef.current.leafletElement;
        if(trayectoActualizandoCorrecto && trayectoActualizando.id === elemento.id) {
            polyline.setStyle({color: nextProps.trayectoActualizando.color});
        }
        return false;
    }

    onPressEsc(e) {
        if(e.keyCode === 27) {
            const {resetTrayectoNuevo, eliminarTrayecto, elemento} = this.props;
            eliminarTrayecto(elemento);
            resetTrayectoNuevo();
        }
    }

    componentWillUnmount() {
        const {objetoSeleccionado} = this.props;
        objetoSeleccionado(null);
        document.removeEventListener('keyup', this.onPressEsc);
    }

    render() {
        const {elemento} = this.props;
        return <Polyline ref={this.polyRef} positions={[]} color={elemento.color}
                         weight={parseFloat(elemento.grosor)}/>
    }

}

const mapStateToProps = state => ({
    duplicando: getDuplicando(state),
    trayectoActualizandoCorrecto: getTrayectoActualizandoCorrecto(state),
    trayectoActualizando: getTrayectoActualizando(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    actualizaTrayecto: actualizaTrayecto,
    resetTrayectoNuevo: actions.resetTrayectoNuevo,
    setDuplicando: actionsEditor.setDuplicando,
    addTrayecto: actions.addTrayecto,
    objetoSeleccionado: actionsEditor.objetoSeleccionado,
    removeCapaColapsada: actionsEditor.removeCapaColapsada,
    eliminarTrayecto: eliminarTrayecto,
    setVisibleModalPropAvanzadas: actionsEditor.setVisibleModalPropAvanzadas
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NuevoTrayecto);
