
import {connect} from "react-redux";
import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {Spinner} from "react-bootstrap";
import {withTranslation, Trans} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

import './BarraIzquierdaEditor.css';

import {
    getBarraIzquierdaAbierta, getElementosVisibles, getObjetosDibujados,
    getPrimeraCarga
} from "../../reducers/editor";
import {
    getAgrupacionesProyecto, getAgrupacionesProyectoError, getAgrupacionesProyectoPendiente
} from "../../reducers/agrupaciones";
import {
    getCapas, getCapasError, getCapasPendiente
} from "../../reducers/capas";
import {actions} from "../../actions/editor";
import AcordeonBarraIzquierda from "./AcordeonBarraIzquierda";

class BarraIzquierdaEditor extends Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { primeraCarga } = this.props;
        if (primeraCarga) {
            this.setElementosVisibles()
        }
    }

    componenteCargando() {
        const {capasPendiente, agrupacionesProyectoPendiente} = this.props;
        return !!(capasPendiente || agrupacionesProyectoPendiente);
    }

    componenteError() {
        const {capasError, agrupacionesProyectoError} = this.props;
        return !!(capasError || agrupacionesProyectoError);
    }

    setElementosVisibles() {
        const {agrupacionesPendiente, agrupacionesError, agrupaciones, addElementoVisible, setPrimeraCarga} = this.props;
        if (!agrupacionesPendiente && !agrupacionesError) {
            agrupaciones.map(agrupacion => agrupacion.elementos.map(elemento => addElementoVisible(elemento)));
            setPrimeraCarga()
        }
    }

    render() {
        const {barraIzquierdaAbierta, agrupacionesProyecto, capas} = this.props;
        if (this.componenteCargando()) {         //todo spiner
            return <div className='fondo-carga'>
                <Spinner className='spinner-carga' animation="border" role="status">
                    <span className="sr-only">Cargando...</span>
                </Spinner>
            </div>
        } else if (this.componenteError()) {     //todo error
            return <div className='fondo-carga'>
                <Spinner className='spinner-carga' animation="border" role="status">
                    <span className="sr-only">Cargando...</span>
                </Spinner>
            </div>
        } else if (agrupacionesProyecto.length === 0) {
            return <div
                className={'editor-barra-izquierda-vacia editor-barra-izquierda'
                + (barraIzquierdaAbierta ? '' : ' editor-barra-izquierda-cerrada')}>
                <div className='msg-nada-anadido'>
                    <div className={'ico-nada-anadido'}>
                        <FontAwesomeIcon icon={faInfoCircle}/>
                    </div>
                    <div className={'txt-nada-anadido'}>
                        <Trans key='msg-nada-anadido'>
                            No tiene ningún elemento añadido al proyecto.
                            Haga click sobre el botón \\"+\\" de la esquina inferior
                            derecha para añadir elementos nuevos.
                        </Trans>
                    </div>
                </div>
            </div>
        } else {
            return <div className={'editor-barra-izquierda'
            + (barraIzquierdaAbierta ? '' : ' editor-barra-izquierda-cerrada')}>
                {capas.map(capa =>
                    <AcordeonBarraIzquierda
                        key={capa.id}
                        capa={capa}
                        agrupaciones={agrupacionesProyecto.filter(agrupacion => {
                            if (agrupacion.elementos && agrupacion.elementos.length) {
                                return agrupacion.elementos[0].bloque.capa.id === capa.id
                            }
                            return false
                        })
                            .sort((a,b) => a.orden - b.orden)}
                    />
                )}
            </div>
        }

    }
}


const mapStateToProps = state => ({
    barraIzquierdaAbierta: getBarraIzquierdaAbierta(state),
    agrupacionesProyecto: getAgrupacionesProyecto(state),
    agrupacionesProyectoError: getAgrupacionesProyectoError(state),
    agrupacionesProyectoPendiente: getAgrupacionesProyectoPendiente(state),
    capas: getCapas(state),
    capasPendiente: getCapasPendiente(state),
    capasError: getCapasError(state),
    elementosVisibles: getElementosVisibles(state),
    primeraCarga: getPrimeraCarga(state),
    agrupaciones: getAgrupacionesProyecto(state),
    agrupacionesError: getAgrupacionesProyectoError(state),
    agrupacionesPendiente: getAgrupacionesProyectoPendiente(state),
    objetosDibujados: getObjetosDibujados(state),
});

const mapStateToDispatch = dispatch => bindActionCreators({
    addElementoVisible: actions.addElementoVisible,
    setPrimeraCarga: actions.setPrimeraCarga,
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapStateToDispatch)(BarraIzquierdaEditor));
