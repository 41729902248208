import React, {Component} from 'react';
import {InputGroup} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {getLongitudDisplay} from "../../reducers/editor";
import {actions} from "../../actions/editor";


class DisplayLng extends Component {

    constructor(props) {
        super(props);

        this.onPointDrag = this.onPointDrag.bind(this);
    }

    componentDidMount() {
        const {objeto, setLongitudDisplay} = this.props;
        if(objeto.props.map && objeto.props.map.current) {
            const map = objeto.props.map.current.leafletElement;
            map.on('editable:drag', this.onPointDrag);
            map.on('editable:created', this.onPointDrag);
            const elemento = objeto.props.elemento;
            if(elemento.coordenadas)  setLongitudDisplay(elemento.coordenadas.coordinates[0]);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {setLongitudDisplay, objeto} = this.props;
        if(objeto.props.elemento.id !== prevProps.objeto.props.elemento.id)
            if(objeto.props.elemento.coordenadas) setLongitudDisplay(objeto.props.elemento.coordenadas.coordinates[0]);
    }

    onPointDrag(e) {
        const {setLongitudDisplay} = this.props;
        setLongitudDisplay(e.layer._latlng.lng);
    }

    componentWillUnmount() {
        const {objeto} = this.props;
        if(objeto.props.map && objeto.props.map.current) {
            const map = objeto.props.map.current.leafletElement;
            map.off('editable:drag');
            map.off('editable:created');
        }
    }

    render (){
        const {longitud} = this.props;
        return <InputGroup className="input-group-propiedad input-group-propiedad-distancia-real input-group-propiedad-coord">
            <InputGroup.Prepend>
                <InputGroup.Text>{'Lng:'}</InputGroup.Text>
            </InputGroup.Prepend>
            <InputGroup.Text>
                <b>{longitud ? longitud.toFixed(4) : '-'}</b>
            </InputGroup.Text>
        </InputGroup>
    }
}

const mapStateToProps = state => ({
    longitud: getLongitudDisplay(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setLongitudDisplay: actions.setLongitudDisplay
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DisplayLng);