import React from 'react'
import i18n from 'i18next'
import {Trans} from 'react-i18next'

import talefano from '../../../images/preview/talefano.png'
import ordenador from '../../../images/preview/ordenador.png'

import './PreviewModal.css'


class PreviewModal extends React.Component {

    constructor(props){
        super(props);
        this.escFunction = this.escFunction.bind(this);
        this.copiadoMsgRef = React.createRef();
    }

    escFunction(event){
        if(event.keyCode === 27) {
            //Do whatever when esc is pressed
            this.props.onClickClose()
        }
    }
    componentDidMount(){
        document.addEventListener("keydown", this.escFunction, false);
    }
    componentWillUnmount(){
        document.removeEventListener("keydown", this.escFunction, false);
    }

    copyToClipboard() {
        const el = document.createElement('textarea');
        el.value = this.props.previewUrl;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        if(this.copiadoMsgRef.current) {
            this.copiadoMsgRef.current.classList.add('preview__copy-msg--blink')
            window.setTimeout(() => {
                this.copiadoMsgRef.current.classList.remove('preview__copy-msg--blink')
            }, 5000)
        }
    }

    render () {
        const {
            onClickClose, previewUrl
        } = this.props

        return <div className='preview__backdrop'>
            <button className='preview__close-button' onClick={onClickClose}>
                <i className="fas fa-times-circle" />
            </button>
            <div className='preview__centerbox'>
                <div className='preview__top-row'>
                    <div className='preview__phone'
                         style={{backgroundImage: "url(" + talefano + ")"}}>
                        <iframe
                            title='Previsualizar en teléfono'
                            width={300} height={629} src={previewUrl}
                        />
                    </div>
                    <div className='preview__desktop'
                         style={{backgroundImage: "url(" + ordenador + ")"}}>
                        <iframe
                            title='Previsualizar en ordenador'
                            width={300} height={629} src={previewUrl}
                        />
                    </div>
                </div>
                <div className='preview__bottom-row'>
                    <div className="input-group">
                        <input className='preview__url-input' value={previewUrl} readOnly />
                        <div className="input-group-append">
                            <button
                                className="input-group-text" id="basic-addon2"
                                title={i18n.t("Copiar al portapeles")}
                                onClick={this.copyToClipboard.bind(this)}
                            >
                                <i className='fas fa-clipboard'/>
                            </button>
                        </div>
                    </div>
                    <div className='preview__copy-msg' ref={this.copiadoMsgRef}>
                        <Trans>Copiado con éxito.</Trans>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default PreviewModal
