import React, { Component } from "react"
import { connect } from 'react-redux'
import { withTranslation, Trans } from "react-i18next"
import { Tab, Tabs } from "react-bootstrap"

import { actions } from "../../actions/editor"
import ModalEditor from "./ModalEditor"
import { actions as actionsMontaje } from "../../actions/montaje"
import { TIPOS_MAPAS_NOMBRES } from "../../constants/state"
import { SERVER_DOMAIN } from "../../constants/api"
import { createMapaMontaje } from "../../fetchactions/montaje"

export class ModalNuevoMapaMontaje extends Component {
    constructor (props) {
        super(props)
        this.state = {
            filtroElementos: ''
        }
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(actionsMontaje.resetMontajeEditando())
    }

    valida () {
        const { t, montajeEditando } = this.props
        if (!montajeEditando.nombre || montajeEditando.nombre.length === 0) {
            alert(t('El mapa debe de tener un nombre'))
            return false
        }
        return true
    }

    handleClickAceptar () {
        const { dispatch, montajeEditando, proyecto } = this.props
        if (this.valida()) {
            dispatch(createMapaMontaje({
                nombre: montajeEditando.nombre,
                capa_fondo: montajeEditando.capa_fondo,
                visible: montajeEditando.visible,
                descripcion: montajeEditando.descripcion,
                meta_descripcion: montajeEditando.meta_descripcion,
                proyecto: proyecto.id
            }, montajeEditando.elementos_montaje))
            this.hide()
        }
    }

    handleClickCancelar () {
        this.hide()
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalNuevoMontaje(false))
        dispatch(actions.setVisibleModalMontaje(true))
    }

    handleChangeNombre (e) {
        const { dispatch } = this.props
        dispatch(actionsMontaje.setNombre(e.target.value))
    }

    handleChangeCapaFondo (e) {
        const { dispatch } = this.props
        dispatch(actionsMontaje.setCapaFondo(e.target.value))
    }

    handleChangeVisible (e) {
        const { dispatch } = this.props
        dispatch(actionsMontaje.setVisible(e.target.checked))
    }

    handleChangeDescripcion (e) {
        const { dispatch } = this.props
        dispatch(actionsMontaje.setDescripcion(e.target.value))
    }

    handleChangeMetaDescripcion (e) {
        const { dispatch } = this.props
        dispatch(actionsMontaje.setMetaDescripcion(e.target.value))
    }

    handleChangeFiltroElementos (e) {
        this.setState({
            filtroElementos: e.target.value
        })
    }

    isCapaInMontaje (capa) {
        const { montajeEditando } = this.props
        for (let i in capa.elementos) {
            if (!montajeEditando.elementos_montaje.includes(capa.elementos[i].id)) {
                return false
            }
        }
        return true
    }

    handleChangeCapa (e, capa) {
        const { montajeEditando, dispatch } = this.props
        if (e.target.checked) {
            for (let i in capa.elementos) {
                if (!montajeEditando.elementos_montaje.includes(capa.elementos[i].id)) {
                    dispatch(actionsMontaje.addElementoMontaje(capa.elementos[i].id))
                }
            }
        } else {
            for (let i in capa.elementos) {
                if (montajeEditando.elementos_montaje.includes(capa.elementos[i].id)) {
                    dispatch(actionsMontaje.removeElementoMontaje(capa.elementos[i].id))
                }
            }
        }
    }

    isElementoInMontaje (elemento) {
        const { montajeEditando } = this.props
        return montajeEditando.elementos_montaje.includes(elemento.id)
    }

    handleChangeElemento (e, elemento) {
        const { dispatch } = this.props
        if (e.target.checked) {
            dispatch(actionsMontaje.addElementoMontaje(elemento.id))
        } else {
            dispatch(actionsMontaje.removeElementoMontaje(elemento.id))
        }
    }

    render() {
        const {
            t, montajeEditando, capasFondo, lineas, poligonos, puntos, trayectos, agrupaciones, tiposMapas
        } = this.props
        let capasPermitidas = tiposMapas.filter(t => t.nombre === TIPOS_MAPAS_NOMBRES.MONTAJE)[0].capa_permitida
        const elem2Capa = array => {
            array.forEach(e => {
                if (capasPermitidas.map(c => c.id).includes(e.bloque.capa.id)) {
                    const i = capasPermitidas.map(c => c.id).indexOf(e.bloque.capa.id)
                    if (!capasPermitidas[i].elementos || !capasPermitidas[i].elementos.map(e => e.id).includes(e.id)) {
                        capasPermitidas[i].elementos = capasPermitidas[i].elementos
                            ? capasPermitidas[i].elementos.concat(e) : [e]
                    }
                }
            })
        }
        elem2Capa(trayectos)
        elem2Capa(puntos)
        elem2Capa(lineas)
        elem2Capa(poligonos)
        capasPermitidas.forEach(cp => cp.elementos && cp.elementos.sort((a, b) => {
            const agrupA = agrupaciones.filter(ag => ag.elementos.map(e => e.id).includes(a.id))
            const agrupB = agrupaciones.filter(ag => ag.elementos.map(e => e.id).includes(b.id))
            if (agrupA.length && agrupB.length) {
                if (agrupA[0].id === agrupB[0].id) return a.orden_editor - b.orden_editor
                return agrupA[0].orden - agrupB[0].orden
            }
            return a.orden_editor - b.orden_editor
        }))
        capasPermitidas = capasPermitidas.filter(c => {
            const nombreCoincide = new RegExp([this.state.filtroElementos].join('|'), 'i').test(c.nombre)
            const elementosFiltrados = c.elementos && c.elementos.filter(p =>
                new RegExp([this.state.filtroElementos].join('|'), 'i').test(p.nombre))
            if (!nombreCoincide) {
                c.elementos = elementosFiltrados
            }
            return nombreCoincide || (elementosFiltrados && elementosFiltrados.length)
        })
        return (
            <ModalEditor
                show
                footer
                onClickAceptar={this.handleClickAceptar.bind(this)}
                onClickCancelar={this.handleClickCancelar.bind(this)}
                onHide={this.handleClickCancelar.bind(this)}
                titulo={t('Nuevo mapa de montaje')}
            >
                <div className='row'>
                    <div className='col-12 form-group'>
                        <label htmlFor='nombre-mapa-montaje'>
                            <strong><Trans>Nombre</Trans></strong>
                        </label>
                        <input
                            type='text'
                            id='nombre-mapa-montaje'
                            name='nombre'
                            className='form-control'
                            onChange={this.handleChangeNombre.bind(this)}
                            value={montajeEditando.nombre}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <Tabs defaultActiveKey={0} className='nav-fill' id='tabs-montaje'>
                            <Tab eventKey={0} title={t('General')}>
                                <div className='row'>
                                    <div className='col-9 form-group'>
                                        <label htmlFor='capa-fondo-montaje'>
                                            <strong><Trans>Capa de fondo</Trans></strong>
                                        </label>
                                        <select
                                            className='form-control'
                                            id='capa-fondo-montaje'
                                            name='capa_fondo'
                                            value={montajeEditando.capa_fondo}
                                            onChange={this.handleChangeCapaFondo.bind(this)}
                                        >
                                            {capasFondo.map(cf =>
                                                <option key={cf.id} value={cf.id}>{cf.nombre}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className='col-3 form-group form-check'>
                                        <input
                                            type='checkbox'
                                            className='form-check-input'
                                            id='visible-montaje'
                                            name='visible'
                                            checked={montajeEditando.visible}
                                            onChange={this.handleChangeVisible.bind(this)}
                                        />
                                        <label className='form-check-label' htmlFor='visible-montaje'>
                                            <Trans><strong>Visible</strong></Trans>
                                        </label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 form-group'>
                                        <label htmlFor='descripcion-montaje'>
                                            <strong><Trans>Descripción</Trans></strong>
                                        </label>
                                        <textarea
                                            className='form-control'
                                            id='descripcion-montaje'
                                            name='descripcion'
                                            value={montajeEditando.descripcion}
                                            onChange={this.handleChangeDescripcion.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12 form-group'>
                                        <label htmlFor='meta-descripcion-montaje'>
                                            <strong><Trans>Descripción para redes sociales</Trans></strong>
                                        </label>
                                        <textarea
                                            className='form-control'
                                            id='meta-descripcion-montaje'
                                            name='meta_descripcion'
                                            value={montajeEditando.meta_descripcion}
                                            onChange={this.handleChangeMetaDescripcion.bind(this)}
                                        />
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey={1} title={t('Ubicaciones')}>
                                <div className='row'>
                                    <div className='col-6'>
                                        <p><Trans>Elige entre los elementos disponibles para este mapa:</Trans></p>
                                    </div>
                                    <div className='col-6 form-group'>
                                        <input
                                            className='form-control'
                                            placeholder={t('Filtrar')}
                                            value={this.state.filtroElementos}
                                            onChange={this.handleChangeFiltroElementos.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-12'>
                                        <ul className='list-group'>
                                            {capasPermitidas.map(cp => cp.elementos && cp.elementos.length > 0 &&
                                                <li key={cp.id} className='list-group-item'>
                                                    <div className='form-group form-check'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input '
                                                            id={'input-capa-montaje-' + cp.id}
                                                            checked={this.isCapaInMontaje(cp)}
                                                            onChange={e => this.handleChangeCapa(e, cp)}
                                                        />
                                                        <label htmlFor={'input-capa-montaje-' + cp.id}>
                                                            <strong>{cp.nombre}</strong>
                                                        </label>
                                                    </div>
                                                    <ul className='list-group list-group-flush ml-2'>
                                                        {cp.elementos.map(e =>
                                                            <li
                                                                key={e.id}
                                                                className='list-group-item form-group form-check'
                                                            >
                                                                <input
                                                                    type='checkbox'
                                                                    className='form-check-input'
                                                                    id={'input-elemento-montaje-' + e.id}
                                                                    checked={this.isElementoInMontaje(e)}
                                                                    onChange={ev => this.handleChangeElemento(ev, e)}
                                                                />
                                                                <label
                                                                    className='d-flex'
                                                                    htmlFor={'input-elemento-montaje-' + e.id}
                                                                >
                                                                    {e.nombre}
                                                                    <div
                                                                        className='n-voluntarios__ele-icono d-flex
                                                                            align-items-center justify-content-center'
                                                                        style={{
                                                                            backgroundColor: e.color,
                                                                            color: e.bloque.props_defecto.color_icono
                                                                        }}
                                                                    >
                                                                        <i
                                                                            className={e.bloque.icono_descripcion}
                                                                        />
                                                                    </div>
                                                                </label>
                                                                <ul className='list-group ml-3'>
                                                                    {e.usuarios.map(u =>
                                                                        <li
                                                                            key={u.id}
                                                                            className='d-flex align-items-center p-1 n-voluntarios__item-user'
                                                                        >
                                                                            {u.avatar
                                                                                ? <img
                                                                                    src={SERVER_DOMAIN + u.avatar}
                                                                                    alt='avatar'
                                                                                    className='n-voluntarios__user-avatar rounded-circle mr-1'
                                                                                />
                                                                                : <i className='fas fa-user-circle fa-2x mr-2' />}
                                                                            {u.first_name}
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </ModalEditor>
        )
    }
}

const mapStateToProps = state => ({
    montajeEditando: state.montajeEditando,
    capasFondo: state.capasFondo.list,
    trayectos: state.trayectos.present.list,
    puntos: state.puntos.list,
    poligonos: state.poligonos.list,
    lineas: state.lineas.list,
    tiposMapas: state.tiposMapas.data,
    agrupaciones: state.agrupacionesProyecto.list,
    proyecto: state.proyecto.data
})

export default withTranslation()(connect(mapStateToProps)(ModalNuevoMapaMontaje))
