import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/puntos";

export function puntosReducer(state = initialState.puntos, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_PUNTOS_CORRECTO:
            return {
                ...state,
                list: action.puntos,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_PUNTOS_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_PUNTOS_PENDIENTE:
            return {
                ...state,
                pendiente: true
            };
        case ACTION_TYPES.ACTUALIZA_COORDS_PUNTO:
            let indexPunto = state.list.map(punto => punto.id).indexOf(action.idPunto);
            let list = state.list.slice();
            list[indexPunto].coordenadas = action.coordenadas;
            return {
                ...state,
                list: list
            };
        case ACTION_TYPES.PUNTO_ELIMINADO_CORRECTO:
        case ACTION_TYPES.REMOVE_PUNTO:
            return {
                ...state,
                list: state.list.filter(punto => punto.id !== action.punto.id)
            };
        case ACTION_TYPES.ADD_PUNTO:
            return {
                ...state,
                list: state.list.concat(action.punto)
            };
        case ACTION_TYPES.SET_COLOR_PUNTO: {
            const ip = state.list.map(p => p.id).indexOf(action.punto.id);
            let list =  JSON.parse(JSON.stringify(state.list));
            list[ip].color = action.color;
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.SET_COLOR_ICONO_PUNTO: {
            const ip = state.list.map(p => p.id).indexOf(action.punto.id);
            let list = JSON.parse(JSON.stringify(state.list));
            list[ip].color_icono = action.colorIcono;
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.SET_NOMBRE_PUNTO: {
            const ip = state.list.map(p => p.id).indexOf(action.punto.id);
            let list = JSON.parse(JSON.stringify(state.list));
            list[ip].nombre = action.nombre;
            return {
                ...state,
                list
            };
        }
        default:
            return {
                ...state
            };
    }
}

export function puntoNuevoReducer(state = initialState.puntoNuevo, action) {
    switch (action.type) {
        case ACTION_TYPES.PUNTO_NUEVO_ELEMENTO:
            return {
                ...state,
                elemento: action.elemento,
                pendiente: false
            };
        case ACTION_TYPES.PUNTO_NUEVO_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false,
            };
        case ACTION_TYPES.PUNTO_NUEVO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                elemento: null,
                error: false
            };
        case ACTION_TYPES.RESET_NUEVO_PUNTO:
            return {
              ...state,
              error: false,
              elemento: null
            };
        default:
            return {
                ...state
            };
    }
}

export function puntoActualizandoReducer(state = initialState.puntoActualizando, action) {
    switch (action.type) {
        case ACTION_TYPES.PUNTO_ACTUALIZANDO_PENDIENTE:
            return{
                ...state,
                elemento: null,
                pendiente: true,
                error: false
            };
        case ACTION_TYPES.PUNTO_ACTUALIZANDO_CORRECTO:
            return {
                ...state,
                elemento: action.punto,
                pendiente: false
            };
        case ACTION_TYPES.PUNTO_ACTUALIZANDO_ERROR:
            return{
                ...state,
                error: action.error,
                pendiente: false
            };
        default:
            return{
                ...state
            };
    }
}

export function puntoEliminadoReducer(state = initialState.puntoEliminado, action) {
    switch (action.type) {
        case ACTION_TYPES.PUNTO_ELIMINADO_CORRECTO:
            return {
                ...state,
                elemento: action.punto,
                correcto: true,
                pendiente: false
            };
        case ACTION_TYPES.PUNTO_ELIMINADO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                correcto: false,
                error: false,
                elemento: action.punto
            };
        case ACTION_TYPES.PUNTO_ELIMINADO_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        default:
            return {
                ...state
            };
    }
}

export const getPuntos = state => state.puntos.list;
export const getPuntosError = state => state.puntos.error;
export const getPuntosPendiente = state => state.puntos.pendiente;
export const getPuntoNuevo = state => state.puntoNuevo.elemento;
export const getPuntoNuevoError = state => state.puntoNuevo.error;
export const getPuntoNuevoPendiente = state => state.puntoNuevo.pendiente;
export const getPuntoActualizando = state => state.puntoActualizando.elemento;
export const getPuntoActualizandoError = state => state.puntoActualizando.error;
export const getPuntoActualizandoPendiente = state => state.puntoActualizando.pendiente;
export const getPuntoEliminado = state => state.puntoEliminado.elemento;
export const getPuntoEliminadoCorrecto = state => state.puntoEliminado.correcto;
export const getPuntoEliminadoError = state => state.puntoEliminado.error;
export const getPuntoEliminadoPendiente = state => state.puntoEliminado.pendiente;
