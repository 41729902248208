import React from 'react'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { DndProvider } from "react-dnd"
import Backend from 'react-dnd-html5-backend'
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"

import { actions } from '../../actions/editor'
import {
    getCapaFondoActiva,
    getEditorIniciado,
    getModalAnadirElementoVisible,
    getModalAnadirServiciosVisible,
    getModalBorrarPksVisible,
    getModalConfirmarRecorteVisible,
    getModalEditarRecorridoVisilbe,
    getModalEditarServiciosVisible,
    getModalElimarTrayectoVisible,
    getModalEliminarRecorridoVisible,
    getModalModoCrearElementoVisible, getModalNombreAgrupacionVisible,
    getModalNuevoRecorridoVisible,
    getModalOrganizarRecorridosVisible,
    getModalPreferenciasVisible,
    getModalPropAvanzadasVisible, getModalSeleccionarFormaVisible,
    getModalSubirTrackVisible, getObjetoSeleccionado
} from '../../reducers/editor'
import { getUsuario, getUsuarioError, getUsuarioPendiente } from '../../reducers/usuario'
import { getTrayectos, getTrayectosError, getTrayectosPendiente } from "../../reducers/trayectos"
import { fetchProyecto } from "../../fetchactions/proyectos"
import { getProyecto, getProyectoError, getProyectoPendiente } from "../../reducers/proyectos"

import CabeceraEditor from "./CabeceraEditor"

import './Editor.css'

import BarraIzquierdaEditor from "./BarraIzquierdaEditor"
import SubcabeceraEditor from "./SubcabeceraEditor"
import MapaContenedor from "./MapaContenedor"
import ModalAnadirElemento from "./ModalAnadirElemento"
import ModalModoCrearElemento from "./ModalModoCrearElemento"
import { fetchAgrupacionesProyecto } from "../../fetchactions/agrupaciones"
import { fetchCapasFondo } from "../../fetchactions/capasFondo"
import { fetchPuntos } from "../../fetchactions/puntos"
import { fetchLineas } from "../../fetchactions/lineas"
import { fetchPoligonos } from "../../fetchactions/poligonos"
import { fetchTrayectos } from "../../fetchactions/trayectos"
import { fetchCapas } from "../../fetchactions/capas"
import BarraHerramientasEditor from "./BarraHerramientasEditor"
import ModalEliminarElemento from "./ModalEliminarElemento"
import ModalPropAvanzadas from "./ModalPropAvanzadas"
import ModalSubirTrack from "./ModalSubirTrack"
import ModalSubscripcion from "./ModalSubscripcion"
import DisplayDistancias from "./DisplayDistancias"
import ModalNuevoRecorrido from "./ModalNuevoRecorrido"
import { fetchMapas } from "../../fetchactions/mapas"
import ModalEditarRecorrido from "./ModalEditarRecorrido"
import DisplayError from '../DisplayError'
import { actions as errorActions } from '../../actions/error'
import { getError } from '../../reducers/error'
import { fetchUsuario } from '../../fetchactions/usuario'
import ModalPreferencias from "./ModalPreferencias"
import {
    FRONTEND_SERVER_DOMAIN,
    SERVER_DOMAIN,
    STRIPE_PUBLISHABLE_KEY,
} from '../../constants/api'
import { fetchServicios } from "../../fetchactions/servicios"
import ModalEliminarRecorrido from "./ModalEliminarRecorrido"
import ModalOrganizarRecorridos from "./ModalOrganizarRecorridos"
import { getMapas, getMapasPendiente } from '../../reducers/mapas'
import { fetchRecorridos } from '../../fetchactions/recorridos'
import { getRecorridos, getRecorridosPendiente } from '../../reducers/recorridos'
import { fetchDeportes } from "../../fetchactions/deportes"
import ModalSelectorServicios from "./ModalSelectorServicios"
import ModalEditarServicios from "./ModalEditarServicios"
import { getContadorPeticiones } from "../../reducers/comun"
import SpinEsperaRespuesta from "./SpinEsperaRespuesta"
import ModalBorrarPks from "./ModalBorrarPks"
import ModalConfirmarRecorte from "./ModalConfirmarRecorte"
import PreviewModal from './preview/PreviewModal'
import ModalCambiarNombreAgrupacion from "./ModalCambiarNombreAgrupacion"
import ModalSeleccionarForma from "./ModalSeleccionarForma"
import ModalImportarElementos from "./ModalImportarElementos"
import ModalBienvenida from "./ModalBienvenida"
import { fetchVoluntarios } from "../../fetchactions/voluntarios"
import ModalVoluntarios from "./ModalVoluntarios"
import ModalNuevosVoluntarios from "./ModalNuevosVoluntarios"
import fetchTiposMapas from "../../fetchactions/tiposMapas"
import ModalEliminarVoluntarios from "./ModalEliminarVoluntarios"
import ModalEditarVoluntarios from "./ModalEditarVoluntarios"
import { fetchContactos } from "../../fetchactions/contactos"
import { fetchMapasTrafico } from "../../fetchactions/trafico"
import ModalTrafico from "./ModalTrafico"
import ModalNuevoMapaTrafico from "./ModalNuevoMapaTrafico"
import ModalEliminarMapaTrafico from "./ModalEliminarMapaTrafico"
import ModalEditarTrafico from "./ModalEditarTrafico"
import ModalMontaje from "./ModalMontaje"
import { fetchMapasMontaje } from "../../fetchactions/montaje"
import ModalNuevoMapaMontaje from "./ModalNuevoMapaMontaje";
import ModalEditarMontaje from "./ModalEditarMontaje";
import ModalEliminarMontaje from "./ModalEliminarMontaje";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)

class Editor extends React.Component{

    constructor(props){
        super(props);

        this.onClickBtnAdd = this.onClickBtnAdd.bind(this);
    }

    componentDidMount() {
        const {
            fetchProyecto, fetchAgrupacionesProyecto, editorIniciado, fetchCapasFondo, fetchPuntos, fetchServicios,
            fetchLineas, fetchPoligonos, fetchTrayectos, fetchCapas, fetchMapas, fetchUsuarioMap, match,
            fetchDeportes, fetchVoluntarios, fetchTiposMapas, fetchMapasTrafico, fetchMapasMontaje
        } = this.props

        if (match.params && match.params.id) {

            fetchProyecto(match.params.id)
            const fields = ['elementos', 'id', 'nombre', 'orden', 'proyecto']
            fetchAgrupacionesProyecto({ proyecto: match.params.id }, fields)
            fetchCapasFondo()
            fetchCapas()
            fetchPuntos({ 'agrupacion__proyecto': match.params.id })
            fetchLineas({ 'agrupacion__proyecto': match.params.id })
            fetchPoligonos({ 'agrupacion__proyecto': match.params.id })
            fetchTrayectos({ 'agrupacion__proyecto': match.params.id })
            fetchMapas(match.params.id)
            editorIniciado()
            fetchUsuarioMap()
            fetchServicios()
            fetchDeportes()
            fetchVoluntarios({ 'mapa__proyecto': match.params.id })
            fetchMapasTrafico({ 'mapa__proyecto': match.params.id })
            fetchMapasMontaje({ 'mapa__proyecto': match.params.id })
            fetchTiposMapas()
        }
    }

    componentDidUpdate (prevProps, prevState, prevContext) {
        const { usuario, fetchContactos } = this.props

        if (prevProps.mapasPendiente && !this.props.mapasPendiente) {
            // ... se carga el mapa...
            if (!prevProps.recorridosPendiente && !this.props.recorridosPendiente) {
                if (this.props.mapas.length) {
                    // console.info('fetch...')
                    this.props.fetchRecorridos(this.props.mapas[0].id)
                }
            }
        }

        if (!prevProps.usuario.data && usuario.data) {
            fetchContactos({ user: usuario.data.id, estado: 'a' })
        }
    }

    shouldComponentRender() {
        const { iniciado, proyectoPendiente, agrupacionesPendiente } = this.props
        return !!(iniciado && !proyectoPendiente && !agrupacionesPendiente)
    }

    onClickPrevisualizar (id, tipoMapa) {
        const { previsualizarMapa } = this.props
        previsualizarMapa(FRONTEND_SERVER_DOMAIN + '/' + tipoMapa +  '/' + id)
    }

    onClickClosePrevisualizar () {
        const { previsualizarMapa } = this.props
        previsualizarMapa(null)
    }

    togglePrevisualizarDropDown () {
        const { togglePrevisualizarMapa } = this.props
        togglePrevisualizarMapa()
    }

    onClickBtnAdd() {
        const { cambiarVisibleModalAnadirElemento, objetoSeleccionado } = this.props
        if (objetoSeleccionado) objetoSeleccionado.deseleccionar()
        cambiarVisibleModalAnadirElemento(true)
    }

    render() {
        if(this.shouldComponentRender()){
            const {
                proyectoError, proyecto, modalAnadirElementoVisible, modalModoCrearElementoVisible, usuario,
                modalEliminarTrayectoVisible, modalPropAvanzadasVisible, modalSubirTrackVisible, modalEditarRecorridoVisible,
                modalNuevoRecorridoVisible, modalPreferenciasVisible, error, clearErrorAction, modalBorrarPksVisible,
                modalEliminarRecorridoVisible, modalOrganizarRecorridosVisible, modalAnadirServiciosVisible,
                trayectos, modalEditarServiciosVisible, contadorPeticiones, modalConfirmarRecorteVisible,
                carrito, mapas, urlPrevisualizacion, modalNombreAgrupacionVisible, modalSeleccionarFormaVisible,
                modalImportarVisible, modalBienvenidoVisible, modalVoluntariosVisible, modalNuevoVoluntariosVisible,
                modalEliminarVoluntariosVisible, modalEditarVoluntariosVisible, modalTraficoVisible,
                modalNuevoTraficoVisible, modalEliminarTraficoVisible, modalEditarTraficoVisible, modalMontajeVisible,
                modalNuevoMontajeVisible, modalEditarMontajeVisible, modalEliminarMontajeVisible
            } = this.props
            if (!usuario.pendiente && usuario.error) {
                window.location = SERVER_DOMAIN + '/usuario/entrar?next=' + window.location
            }

            // console.info('EDITOR.RENDER', trayectos)
            if (!proyectoError && proyecto && proyecto.id) {

                return <Elements stripe={stripePromise}>
                    <div className='layout'>
                        <CabeceraEditor
                            proyecto={proyecto} usuario={usuario}
                            onClickPrevisualizar={this.onClickPrevisualizar.bind(this)}
                            togglePrevisualizarDrowpDown={this.togglePrevisualizarDropDown.bind(this)}
                        />
                        <DisplayError error={error} clearError={clearErrorAction}/>
                        <main className='contenedor-central'>
                            <SubcabeceraEditor/>
                            <BarraIzquierdaEditor/>
                            <MapaContenedor/>
                            <Button onClick={this.onClickBtnAdd} className='btn-add' variant='dark'>
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            {modalAnadirElementoVisible &&
                                <ModalAnadirElemento show/>}
                            {modalModoCrearElementoVisible &&
                                <ModalModoCrearElemento show/>}
                            {modalEliminarTrayectoVisible &&
                                <ModalEliminarElemento show/>}
                            {modalPropAvanzadasVisible &&
                                <ModalPropAvanzadas show/>}
                            {modalSubirTrackVisible &&
                                <ModalSubirTrack show/>}
                            {modalPreferenciasVisible &&
                                <ModalPreferencias show/>}
                            <DndProvider backend={Backend}>
                                {modalNuevoRecorridoVisible &&
                                    <ModalNuevoRecorrido show trayectos={trayectos}/>}
                                {modalEditarRecorridoVisible &&
                                    <ModalEditarRecorrido show editar trayectos={trayectos}/>}
                                {modalOrganizarRecorridosVisible &&
                                    <ModalOrganizarRecorridos show trayectos={trayectos}/>}
                                {modalVoluntariosVisible &&
                                    <ModalVoluntarios />}
                                {modalTraficoVisible &&
                                    <ModalTrafico />}
                                {modalMontajeVisible &&
                                    <ModalMontaje />}
                            </DndProvider>
                            {carrito.modalVisible &&
                                <ModalSubscripcion show mapas={mapas}/>}
                            {modalEliminarRecorridoVisible &&
                                <ModalEliminarRecorrido show/>}
                            {modalAnadirServiciosVisible &&
                                <ModalSelectorServicios show/>}
                            {modalEditarServiciosVisible &&
                                <ModalEditarServicios show/>}
                            {modalBorrarPksVisible &&
                                <ModalBorrarPks show/>}
                            {modalConfirmarRecorteVisible &&
                                <ModalConfirmarRecorte show/>}
                            {modalNombreAgrupacionVisible &&
                                <ModalCambiarNombreAgrupacion show/>}
                            {modalSeleccionarFormaVisible &&
                                <ModalSeleccionarForma show/>}
                            {modalImportarVisible &&
                                <ModalImportarElementos show />}
                            {modalBienvenidoVisible &&
                                <ModalBienvenida />}
                            {modalNuevoVoluntariosVisible &&
                                <ModalNuevosVoluntarios />}
                            {modalEliminarVoluntariosVisible &&
                                <ModalEliminarVoluntarios />}
                            {modalEditarVoluntariosVisible &&
                                <ModalEditarVoluntarios />}
                            {modalNuevoTraficoVisible &&
                                <ModalNuevoMapaTrafico />}
                            {modalEliminarTraficoVisible &&
                                <ModalEliminarMapaTrafico />}
                            {modalEditarTraficoVisible &&
                                <ModalEditarTrafico />}
                            {modalNuevoMontajeVisible &&
                                <ModalNuevoMapaMontaje />}
                            {modalEditarMontajeVisible &&
                                <ModalEditarMontaje />}
                            {modalEliminarMontajeVisible &&
                                <ModalEliminarMontaje />}
                            <BarraHerramientasEditor/>
                            <DisplayDistancias/>
                            <SpinEsperaRespuesta show={contadorPeticiones > 0}/>
                        </main>
                    </div>
                    { urlPrevisualizacion &&
                        <PreviewModal
                            previewUrl={urlPrevisualizacion}
                            onClickClose={this.onClickClosePrevisualizar.bind(this)}
                        />}
                </Elements>
            } else if (proyectoError) {
                return <h5>Error: {proyectoError}</h5>
            } else {
                return <h5>Error: emosidoengañados, non hai tal id</h5> //@todo erro 404
            }
        } else {
            return <div className='fondo-carga'>
                <Spinner className='spinner-carga' animation="border" role="status">
                    <span className="sr-only">Cargando...</span>
                </Spinner>
            </div>
        }
    }
}


const mapStateToProps = state => {
    return {
        error: getError(state),
        iniciado: getEditorIniciado(state),
        recorridos: getRecorridos(state),
        recorridosPendiente: getRecorridosPendiente(state),
        mapas: getMapas(state),
        mapasPendiente: getMapasPendiente(state),
        proyecto: getProyecto(state),
        proyectoPendiente: getProyectoPendiente(state),
        proyectoError: getProyectoError(state),
        capaFondoActiva: getCapaFondoActiva(state),
        modalAnadirElementoVisible: getModalAnadirElementoVisible(state),
        modalModoCrearElementoVisible: getModalModoCrearElementoVisible(state),
        modalEliminarTrayectoVisible: getModalElimarTrayectoVisible(state),
        modalPropAvanzadasVisible: getModalPropAvanzadasVisible(state),
        modalSubirTrackVisible: getModalSubirTrackVisible(state),
        modalNuevoRecorridoVisible: getModalNuevoRecorridoVisible(state),
        modalEditarRecorridoVisible: getModalEditarRecorridoVisilbe(state),
        modalPreferenciasVisible: getModalPreferenciasVisible(state),
        usuario: {
            data: getUsuario(state),
            error: getUsuarioError(state),
            pendiente: getUsuarioPendiente(state)
        },
        modalEliminarRecorridoVisible: getModalEliminarRecorridoVisible(state),
        modalOrganizarRecorridosVisible: getModalOrganizarRecorridosVisible(state),
        trayectos: getTrayectos(state),
        trayectosError: getTrayectosError(state),
        trayectosPendiente: getTrayectosPendiente(state),
        modalAnadirServiciosVisible: getModalAnadirServiciosVisible(state),
        modalEditarServiciosVisible: getModalEditarServiciosVisible(state),
        contadorPeticiones: getContadorPeticiones(state),
        modalBorrarPksVisible: getModalBorrarPksVisible(state),
        modalConfirmarRecorteVisible: getModalConfirmarRecorteVisible(state),
        carrito: state.carrito,
        urlPrevisualizacion: state.editor.urlPrevisualizacion,
        modalNombreAgrupacionVisible: getModalNombreAgrupacionVisible(state),
        modalSeleccionarFormaVisible: getModalSeleccionarFormaVisible(state),
        objetoSeleccionado: getObjetoSeleccionado(state),
        modalImportarVisible: state.editor.modalImportarVisible,
        modalBienvenidoVisible: state.editor.modalBienvenidoVisible,
        modalVoluntariosVisible: state.editor.modalVoluntariosVisible,
        modalNuevoVoluntariosVisible: state.editor.modalNuevoVoluntariosVisible,
        modalEliminarVoluntariosVisible: state.editor.modalEliminarVoluntariosVisible,
        modalEditarVoluntariosVisible: state.editor.modalEditarVoluntariosVisible,
        modalTraficoVisible: state.editor.modalTraficoVisible,
        modalNuevoTraficoVisible: state.editor.modalNuevoTraficoVisible,
        modalEliminarTraficoVisible: state.editor.modalEliminarTraficoVisible,
        modalEditarTraficoVisible: state.editor.modalEditarTraficoVisible,
        modalMontajeVisible: state.editor.modalMontajeVisible,
        modalNuevoMontajeVisible: state.editor.modalNuevoMontajeVisible,
        modalEditarMontajeVisible: state.editor.modalEditarMontajeVisible,
        modalEliminarMontajeVisible: state.editor.modalEliminarMontajeVisible
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    editorIniciado: actions.editorIniciado,
    fetchProyecto: fetchProyecto,
    cambiarVisibleModalAnadirElemento: actions.cambiarVisibleModalAnadirElemento,
    fetchAgrupacionesProyecto: fetchAgrupacionesProyecto,
    fetchCapasFondo: fetchCapasFondo,
    fetchPuntos: fetchPuntos,
    fetchLineas: fetchLineas,
    fetchPoligonos: fetchPoligonos,
    fetchTrayectos: fetchTrayectos,
    fetchCapas: fetchCapas,
    fetchMapas: fetchMapas,
    clearErrorAction: errorActions.clearError,
    fetchUsuarioMap: fetchUsuario,
    fetchServicios: fetchServicios,
    fetchRecorridos: fetchRecorridos,
    fetchDeportes: fetchDeportes,
    previsualizarMapa: actions.previsualizarMapa,
    togglePrevisualizarMapa: actions.togglePrevisualizarMapa,
    fetchVoluntarios: fetchVoluntarios,
    fetchTiposMapas: fetchTiposMapas,
    fetchContactos: fetchContactos,
    fetchMapasTrafico: fetchMapasTrafico,
    fetchMapasMontaje: fetchMapasMontaje
}, dispatch)

export default connect( mapStateToProps, mapDispatchToProps )(Editor)

