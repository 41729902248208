import React from 'react';
import i18n from 'i18next';
import {CardElement} from '@stripe/react-stripe-js';
import './SeccionTarjeta.css'

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            backgroundColor: "#dddddd",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            lineHeight: "2.5em",
            "::placeholder": {
                color: "#889999",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

export default function SeccionTarjeta({stripe}) {
    return (
        <label style={{width: '100%', maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}}>
            {i18n.t('')}
            <CardElement options={CARD_ELEMENT_OPTIONS} stripe={stripe} />
        </label>
    );
};
