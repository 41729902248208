import { initialState } from "../constants/state"
import { ACTION_TYPES } from "../actions/elementosMontaje"

export function elementoMontajeCreateReducer (state = initialState.elementoMontajeCreate, action) {
    switch (action.type) {
        case ACTION_TYPES.CREATE_ELEMENTO_MONTAJE_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.CREATE_ELEMENTO_MONTAJE_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.elementoMontaje
            }

        case ACTION_TYPES.CREATE_ELEMENTO_MONTAJE_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}

export function elementoMontajeDeleteReducer (state = initialState.elementoMontajeDelete, action) {
    switch (action.type) {
        case ACTION_TYPES.DELETE_ELEMENTO_MONTAJE_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.DELETE_ELEMENTO_MONTAJE_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.elementoMontaje
            }

        case ACTION_TYPES.DELETE_ELEMENTO_MONTAJE_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}
