import React, {Component} from 'react';
import {ButtonGroup, Button} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import './SubcabeceraEditor.css';

import {getBarraIzquierdaAbierta, getFiltroVisibleActivo, getObjetoSeleccionado} from "../../reducers/editor";
import {actions} from "../../actions/editor";
import InputPropiedad from "./InputPropiedad";
import InputColor from "./InputColor";
import DisplayDistanciaReal from "./DisplayDistanciaReal";
import DisplayLat from "./DisplayLat";
import DisplayLng from "./DisplayLng";
import DisplayArea from "./DisplayArea";
import SwitchPdiProyecto from "./SwitchPdiProyecto";
import FiltroVisibilidad from "./FiltroVisibilidad";
import {getRecorridos} from "../../reducers/recorridos";
import * as turf from "@turf/turf";



class SubcabeceraEditor extends Component {

    constructor(props) {
        super();
        this.onToggleBarraClick = this.onToggleBarraClick.bind(this);
        this.onClickBtnAvanzadas = this.onClickBtnAvanzadas.bind(this);
    }

    onToggleBarraClick() {
        const { abrirBarraIzquierda, cerrarBarraIzquierda, barraIzquierdaAbierta } = this.props;
        if(barraIzquierdaAbierta) {
            cerrarBarraIzquierda();
        }else{
            abrirBarraIzquierda();
        }
    }

    onClickBtnAvanzadas() {
        const {setVisibleModalPropAvanzada} = this.props;
        setVisibleModalPropAvanzada(true);
    }

    getValoresUnidadesDistancia() {
        return [
            {nombre: 'metros', value:'m'},
            {nombre: 'kilometros', value: 'km'},
            {nombre: 'millas', value: 'mi'},
            {nombre: 'pies', value: 'ft'}
        ]
    }

    distanciaEnUnidad(distancia, unidad) {
        switch (unidad) {
            case 'km':
                return turf.convertLength(distancia, 'meters', 'kilometers').toFixed(3) + ' km';
            case 'ft':
                return turf.convertLength(distancia, 'meters', 'feet').toFixed(0) + ' ft';
            case 'mi':
                return turf.convertLength(distancia, 'meters', 'miles').toFixed(2) + ' mi';
            default:
                return distancia.toFixed(0) + ' m';
        }
    }

    render() {
        const {t, objetoSeleccionado, filtroVisibleActivo, recorridos} = this.props;
        const recorridoFiltro = parseInt(filtroVisibleActivo) ?
            recorridos.filter(recorrido => recorrido.id === parseInt(filtroVisibleActivo))[0] : null;
        return <div className='subcabecera-editor'>
            <div className='subcabecera-editor-barra-izquierda'>
                <ButtonGroup className="mb-2">
                    <Button className='btn-toggle-esquema-edit' variant="secondary" onClick={this.onToggleBarraClick}>
                        <FontAwesomeIcon icon={faBars} />
                    </Button>
                </ButtonGroup>
                <FiltroVisibilidad/>
            </div>
            {objetoSeleccionado &&
            <div className='subcabecera-editor-propiedades'>
                <InputPropiedad propiedad='nombre' elemento={objetoSeleccionado.props.elemento}
                                objeto={objetoSeleccionado}/>
                {objetoSeleccionado.props.elemento.bloque.color_editable &&
                    <InputColor elemento={objetoSeleccionado.props.elemento} objeto={objetoSeleccionado} />
                }
                {objetoSeleccionado.props.elemento.bloque.posible_pdi_proyecto &&
                    <SwitchPdiProyecto elemento={objetoSeleccionado.props.elemento} objeto={objetoSeleccionado}/>
                }
                {objetoSeleccionado.props.elemento.bloque.tipo === 'tr' && [
                    <DisplayDistanciaReal key={2} objeto={objetoSeleccionado}/>,
                    <InputPropiedad key={3} propiedad='distancia_homologada' elemento={objetoSeleccionado.props.elemento}
                                    objeto={objetoSeleccionado}/>
                ]}
                {objetoSeleccionado.props.elemento.bloque.tipo === 'pt' && objetoSeleccionado.props.elemento.bloque.color_editable &&
                    <InputColor elemento={objetoSeleccionado.props.elemento} objeto={objetoSeleccionado} xl
                                        propiedad='color_icono'/>
                }
                {objetoSeleccionado.props.elemento.bloque.tipo === 'pt' && [
                    <DisplayLat key={1} objeto={objetoSeleccionado}/>,
                    <DisplayLng key={2} objeto={objetoSeleccionado}/>
                ]}
                {objetoSeleccionado.props.elemento.bloque.tipo === 'ln' &&
                    <DisplayDistanciaReal objeto={objetoSeleccionado}/>
                }
                {objetoSeleccionado.props.elemento.bloque.tipo === 'pl' &&
                    <DisplayArea objeto={objetoSeleccionado}/>
                }
                <Button className='btn-opciones-avanzadas' variant="secondary" onClick={this.onClickBtnAvanzadas}>
                    {t('avanzado')}
                </Button>
            </div>
            }
            {!objetoSeleccionado && recorridoFiltro &&
            <div className='subcabecera-editor-propiedades'>
                {recorridoFiltro.sectores.map(sector => sector.esquemas.map(esquema =>
                    <div key={esquema.id} className="display-trayecto-cabecera">
                        <div>
                            <div className="nombre-trayecto">{esquema.trayecto.nombre}</div>
                            <div className="distancia-trayecto" style={{backgroundColor: esquema.trayecto.color}}>
                                {this.distanciaEnUnidad(esquema.trayecto.distancia_homologada ?
                                    esquema.trayecto.distancia_homologada : esquema.trayecto.distancia_calculada,
                                    esquema.trayecto.unidad_medida)}
                            </div>
                        </div>
                        <div className="repeticiones-trayecto">{'x' + esquema.repeticiones}</div>
                    </div>
                ))}
            </div>
            }
        </div>
    }
}


const mapStateToProps = state => ({
    barraIzquierdaAbierta: getBarraIzquierdaAbierta(state),
    objetoSeleccionado: getObjetoSeleccionado(state),
    filtroVisibleActivo: getFiltroVisibleActivo(state),
    recorridos: getRecorridos(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    abrirBarraIzquierda: actions.abrirBarraIzquierda,
    cerrarBarraIzquierda: actions.cerrarBarraIzquierda,
    setVisibleModalPropAvanzada: actions.setVisibleModalPropAvanzadas
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SubcabeceraEditor))
