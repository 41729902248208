import React, {Component} from 'react';
import {Navbar, Nav, NavDropdown, Form} from 'react-bootstrap';
import {Trans, withTranslation} from "react-i18next";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faUser,
    faLayerGroup,
    faRoute,
    faCog,
    faPlusCircle,
    faSort,
    faQuestionCircle,
    faShoppingCart, faEye, faMap
} from '@fortawesome/free-solid-svg-icons';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {getCapasFondo, getCapasFondoError, getCapasFondoPendiente} from "../../reducers/capasFondo";
import {actions} from "../../actions/editor";
import {actions as carritoActions} from '../../actions/carrito';
import {actions as actionsRecorridos} from '../../actions/recorridos';

import logo from '../../logos/logo_peque.png';
import './CabeceraEditor.css';
import {
    getCapaFondoActiva,
    getCapasTransparentesAtivas, getObjetoSeleccionado,
    getShowDropdownCapas,
    getShowDropdownRecorridos
} from "../../reducers/editor";
import {getRecorridos} from "../../reducers/recorridos";
import { SERVER_DOMAIN } from '../../constants/api'
import {actualizaRecorrido} from "../../fetchactions/recorridos";


class CabeceraEditor extends Component{

    constructor(props){
        super(props);

        this.proyecto = props.proyecto;
        this.onToggle = this.onToggle.bind(this);
        this.onToggleRecorrido = this.onToggleRecorrido.bind(this);
        this.onClickNuevoRecorrido = this.onClickNuevoRecorrido.bind(this);
        this.onClickPropiedades = this.onClickPropiedades.bind(this);
        this.onClickOrganizarRecorridos = this.onClickOrganizarRecorridos.bind(this);
    }

    getTitleFondosMapa() {
        const {t} = this.props;
        return <div className='btn-cabecera-editor'>
            <FontAwesomeIcon className='icono-cabecera-editor' icon={faLayerGroup}/>
            <span>{t('fondo_mapa')}</span>
        </div>
    }

    getTitleRecorridos(){
        const {t} = this.props;
        return <div className='btn-cabecera-editor'>
            <FontAwesomeIcon className='icono-cabecera-editor' icon={faRoute}/>
            <span>{t('recorridos')}</span>
        </div>
    }

    onRadioBaseChanged(capa){
        const {setCapaFondo} = this.props;
        setCapaFondo(capa);
    }

    onToggle(isOpen, e, metadata) {
        const {showDropdownCapas, hideDropdownCapas} = this.props;
        const esTransparente = e.target &&
            (" " + e.target.className + " ").replace(/[\n\t]/g, " ").indexOf(" capa-transparente ") > -1;
        if(!isOpen && esTransparente && metadata.source === 'select'){
            showDropdownCapas()
        } else {
            if(isOpen) showDropdownCapas();
            else hideDropdownCapas();
        }
    }

    onToggleRecorrido(isOpen, e, metadata) {
        const {setShowDropdownRecorridos} = this.props;
        if(!isOpen && metadata.source === 'select') setShowDropdownRecorridos(true);
        else {
            if(isOpen) setShowDropdownRecorridos(true);
            else setShowDropdownRecorridos(false);
        }
    }

    onCheckTransparenteChanged(capa) {
        const {capasTransparentesActivas, addCapaTransparente, removeCapaTransparente} = this.props;
        if(capasTransparentesActivas.filter(capaTrans => capaTrans.id === capa.id).length > 0) {
            removeCapaTransparente(capa);
        } else{
            addCapaTransparente(capa);
        }
    }

    onClickNuevoRecorrido() {
        const {setVisibleModalNuevoRecorrido, objetoSeleccionado} = this.props;
        if (objetoSeleccionado) objetoSeleccionado.deseleccionar();
        setVisibleModalNuevoRecorrido(true);
    }

    onClickRecorrido(recorrido) {
        const {setRecorridoCreando, setVisibleModalEditarRecorrido} = this.props;
        setRecorridoCreando(JSON.parse(JSON.stringify(recorrido)));
        setVisibleModalEditarRecorrido(true);
    }

    onClickPropiedades() {
        const {setVisibleModalPreferencias} = this.props;
        setVisibleModalPreferencias(true);
    }

    onClickOrganizarRecorridos() {
        const {setVisibleModalOrganizarRecorridos} = this.props;
        setVisibleModalOrganizarRecorridos(true);
    }

    toggleModalSubscripcion () {
        const {carrito, carritoModalVisible} = this.props;
        carritoModalVisible(!carrito.modalVisible);
    }

    handleClickVoluntarios () {
        const { setVisibleModalVoluntarios } = this.props
        setVisibleModalVoluntarios(true)
    }

    handleClickTrafico () {
        const { setVisibleModalTrafico } = this.props
        setVisibleModalTrafico(true)
    }

    handleClickMontaje () {
        const { setVisibleModalMontaje } = this.props
        setVisibleModalMontaje(true)
    }

    render() {
        const {
            t, capasFondo, capaFondoActiva, getShowDropdownCapas, showDropdownRecorridos, recorridos,
            capasTransparentesActivas, proyecto, usuario, onClickPrevisualizar, showDropdownPrevisualizar,
            togglePrevisualizarDrowpDown, voluntarios, mapasTrafico, mapasMontaje
        } = this.props

        return <Navbar className='cabecera' expand='xl' fixed='top' variant="dark">
            <Navbar.Brand href={`${SERVER_DOMAIN}/proyecto/` }>
                <img className='logo-editor' src={logo} alt='Logo' />
            </Navbar.Brand>
            <Navbar.Brand className='titulo' href=''>
                {proyecto.nombre.toUpperCase()}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse className="justify-content-end">
                <Nav variant='pills'>
                    <NavDropdown
                        show={showDropdownPrevisualizar}
                        className={
                            "cont-btn-cabecera-edit " +
                            ((recorridos.length === 0 && voluntarios.length === 0) ? 'disabled' : '')
                        }
                        title={
                            <div className='btn-cabecera-editor'>
                                <FontAwesomeIcon className='icono-cabecera-editor' icon={faEye}/>
                                <span>{t('Previsualizar')}</span>
                            </div>
                        }
                        onToggle={togglePrevisualizarDrowpDown}
                    >
                        {recorridos.length > 0 &&
                            <NavDropdown.Item className='dropdown-item-recorridos'>
                                <span className='cabecera-navitem-nombre'>
                                    <strong><Trans>Recorridos</Trans></strong>
                                </span>
                            </NavDropdown.Item>}
                        {recorridos.map(recorrido =>
                            <NavDropdown.Item
                                key={'r' + recorrido.id} className='dropdown-item-recorridos'
                                onClick={() => onClickPrevisualizar(recorrido.id, 'recorrido')}
                            >
                                <span className='cabecera-navitem-nombre'>{recorrido.nombre}</span>
                            </NavDropdown.Item>
                        )}
                        {voluntarios.length > 0 &&
                            <>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className='dropdown-item-recorridos'>
                                        <span className='cabecera-navitem-nombre'>
                                            <strong><Trans>Staff</Trans></strong>
                                        </span>
                                </NavDropdown.Item>
                            </>}
                        {voluntarios.map(v =>
                            <NavDropdown.Item
                                key={'v' + v.id}
                                className='dropdown-item-recorridos'
                                onClick={() => onClickPrevisualizar(v.id, 'personal')}
                            >
                                <span className='cabecera-navitem-nombre'>{v.nombre}</span>
                            </NavDropdown.Item>
                        )}
                        {mapasTrafico.length > 0 &&
                            <>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className='dropdown-item-recorridos'>
                                    <span className='cabecera-navitem-nombre'>
                                        <strong><Trans>Tráfico</Trans></strong>
                                    </span>
                                </NavDropdown.Item>
                            </>}
                        {mapasTrafico.map(mt =>
                            <NavDropdown.Item
                                key={'mt' + mt.id}
                                className='dropdown-item-recorridos'
                                onClick={() => onClickPrevisualizar(mt.id, 'trafico')}
                            >
                                <span className='cabecera-navitem-nombre'>{mt.nombre}</span>
                            </NavDropdown.Item>
                        )}
                        {mapasMontaje.length > 0 &&
                            <>
                                <NavDropdown.Divider />
                                <NavDropdown.Item className='dropdown-item-recorridos'>
                                    <span className='cabecera-navitem-nombre'>
                                        <strong><Trans>Montaje</Trans></strong>
                                    </span>
                                </NavDropdown.Item>
                            </>}
                        {mapasMontaje.map(mm =>
                            <NavDropdown.Item
                                key={'mt' + mm.id}
                                className='dropdown-item-recorridos'
                                onClick={() => onClickPrevisualizar(mm.id, 'montaje')}
                            >
                                <span className='cabecera-navitem-nombre'>{mm.nombre}</span>
                            </NavDropdown.Item>
                        )}
                    </NavDropdown>


                    <Nav.Item className={'cont-btn-cabecera-edit ' + (recorridos.length === 0 ? 'disabled' : '')}>
                        <Nav.Link className='btn-cabecera-editor' onClick={this.toggleModalSubscripcion.bind(this)}>
                            <FontAwesomeIcon className='icono-cabecera-editor' icon={faShoppingCart}/>
                            <span>{t('Publicar')}</span>
                        </Nav.Link>
                    </Nav.Item>
                    <NavDropdown
                        show={showDropdownRecorridos} title={this.getTitleRecorridos()}
                        className="cont-btn-cabecera-edit"
                        onToggle={this.onToggleRecorrido}
                    >
                        {recorridos.map(recorrido =>
                            <NavDropdown.Item
                                key={recorrido.id} className='dropdown-item-recorridos'
                                onClick={() => this.onClickRecorrido(recorrido)}
                            >
                                <span className='cabecera-navitem-nombre'>{recorrido.nombre}</span>
                            </NavDropdown.Item>
                        )}
                        <NavDropdown.Divider/>
                        <NavDropdown.Item className='dropdown-item-recorridos' onClick={this.onClickNuevoRecorrido}>
                            <FontAwesomeIcon icon={faPlusCircle}/>{t('nuevo-recorrido')}
                        </NavDropdown.Item>
                        {recorridos.length >= 1 &&
                            <NavDropdown.Item
                                className='dropdown-item-recorridos'
                                onClick={this.onClickOrganizarRecorridos}
                            >
                                <FontAwesomeIcon icon={faSort}/>{t('organizar-recorridos')}
                            </NavDropdown.Item>
                        }
                    </NavDropdown>
                    <NavDropdown
                        className='cont-btn-cabecera-edit'
                        title={
                            <div className='btn-cabecera-editor'>
                                <FontAwesomeIcon className='icono-cabecera-editor' icon={faMap}/>
                                <span><Trans>Más Mapas</Trans></span>
                            </div>
                        }
                    >
                        <NavDropdown.Item
                            className='dropdown-item-recorridos' onClick={this.handleClickVoluntarios.bind(this)}
                        >
                            <Trans>Staff</Trans>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            className='dropdown-item-recorridos' onClick={this.handleClickTrafico.bind(this)}
                        >
                            <Trans>Tráfico</Trans>
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            className='dropdown-item-recorridos' onClick={this.handleClickMontaje.bind(this)}
                        >
                            <Trans>Montaje</Trans>
                        </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                        show={getShowDropdownCapas} onToggle={this.onToggle}
                        title={this.getTitleFondosMapa()}
                        className='cont-btn-cabecera-edit'
                    >
                        {capasFondo.map(capa => {
                            if (!capa.transparencia) {
                                return <NavDropdown.Item
                                    active={false} key={capa.id} eventKey={'base'}
                                    onSelect={() => {this.onRadioBaseChanged(capa)}}
                                >
                                    <Form.Check type='radio' name='radio-base' id={'radio-base-' + capa.id}>
                                        <Form.Check.Label>
                                            <Form.Check.Input className='radio-capas' type='radio'
                                                              checked={capa.id === capaFondoActiva.id}
                                                          onChange={() => this.onRadioBaseChanged(capa)}/>
                                            {capa.nombre}
                                        </Form.Check.Label>
                                    </Form.Check>
                                </NavDropdown.Item>
                            }
                            return null
                        })}
                        <NavDropdown.Divider/>
                        {capasFondo.map(capa => {
                            if (capa.transparencia) {
                                return <NavDropdown.Item eventKey={'overlay'}
                                    active={false} key={capa.id} className='capa-transparente'
                                    onSelect={() => {this.onCheckTransparenteChanged(capa)}}
                                >
                                    <Form.Check type='switch'
                                                name='check-overlay'
                                                className='radio-capas'
                                                label={capa.nombre}
                                                checked={capasTransparentesActivas.filter(
                                                    capaTrans => capaTrans.id ===
                                                        capa.id).length > 0}
                                                onChange={() => this.onCheckTransparenteChanged(capa)}
                                    />
                                </NavDropdown.Item>
                            }
                            return null
                        })}
                    </NavDropdown>
                    <Nav.Item className='cont-btn-cabecera-edit'>
                        <Nav.Link className='btn-cabecera-editor' onClick={this.onClickPropiedades}>
                            <FontAwesomeIcon className='icono-cabecera-editor' icon={faCog} />
                            <span>{t('propiedades')}</span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className='cont-btn-cabecera-edit'>
                        <Nav.Link className='btn-cabecera-editor' href={SERVER_DOMAIN + '/ayuda/'}>
                            <FontAwesomeIcon className='icono-cabecera-editor' icon={faQuestionCircle} />
                            <span>{t('Soporte')}</span>
                        </Nav.Link>
                    </Nav.Item>
                    <NavDropdown alignRight
                        className="cont-btn-cabecera-edit"
                        title={
                            <div className='btn-cabecera-editor btn-cabecera-editor-usuarios'>
                                <FontAwesomeIcon className='icono-cabecera-editor' icon={faUser} />
                                <span>{usuario.data ? usuario.data.username: '...'}</span>
                            </div>
                        }
                    >
                        <NavDropdown.Item href={`${SERVER_DOMAIN}/proyecto/`} className='dropdown-item-recorridos'>
                            Mis Proyectos
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            href={`${SERVER_DOMAIN}/usuario/subscripciones/`} className='dropdown-item-recorridos'
                        >
                            Mis Publicaciones
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                        <NavDropdown.Item
                            href={`${SERVER_DOMAIN}/usuario/perfil/`} className='dropdown-item-recorridos'
                        >
                            <i className="fas fa-user-edit mr-1" /> Editar mis datos
                        </NavDropdown.Item>
                        <NavDropdown.Item
                            href={`${SERVER_DOMAIN}/usuario/salir/`} className='dropdown-item-recorridos'
                        >
                            <i className="fas fa-power-off mr-1" /> Desconectarse
                        </NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    }
}

const mapStateToProps = state => ({
    capasFondo: getCapasFondo(state),
    capasFondoPendiente: getCapasFondoPendiente(state),
    capasFondoError: getCapasFondoError(state),
    capaFondoActiva: getCapaFondoActiva(state),
    getShowDropdownCapas: getShowDropdownCapas(state),
    capasTransparentesActivas: getCapasTransparentesAtivas(state),
    showDropdownRecorridos: getShowDropdownRecorridos(state),
    showDropdownPrevisualizar: state.editor.showDropdownPrevisualizar,
    recorridos: getRecorridos(state),
    carrito: state.carrito,
    objetoSeleccionado: getObjetoSeleccionado(state),
    voluntarios: state.voluntarios.data,
    mapasTrafico: state.mapasTrafico.data,
    mapasMontaje: state.mapasMontaje.data
})

const mapDispatchToProps = dispatch => bindActionCreators({
    setCapaFondo: actions.setCapaFondo,
    showDropdownCapas: actions.showDropdownCapas,
    hideDropdownCapas: actions.hideDropdownCapa,
    addCapaTransparente: actions.addCapaTransparente,
    removeCapaTransparente: actions.removeCapaTransparente,
    setShowDropdownRecorridos: actions.setShowDropdownRecorridos,
    setVisibleModalNuevoRecorrido: actions.setVisibleModalNuevoRecorrido,
    setRecorridoCreando: actionsRecorridos.setRecorridoCreando,
    setVisibleModalEditarRecorrido: actions.setVisibleModalEditarRecorrido,
    setVisibleModalPreferencias: actions.setVisibleModalPreferencias,
    setVisibleModalEliminarRecorrido: actions.setVisibleModalEliminarRecorrido,
    setRecorridoPorEliminar: actions.setRecorridoPorEliminar,
    actualizaRecorrido: actualizaRecorrido,
    setVisibleModalOrganizarRecorridos: actions.setVisibleModalOrganizarRecorridos,
    carritoModalVisible: carritoActions.carritoModalVisible,
    setVisibleModalVoluntarios: actions.setVisibleModalVoluntarios,
    setVisibleModalTrafico: actions.setVisibleModalTrafico,
    setVisibleModalMontaje: actions.setVisibleModalMontaje
}, dispatch)

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CabeceraEditor))
