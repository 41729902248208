import {initialState} from '../constants/state';
import {ACTION_TYPES} from "../actions/comun";

export function isLoadingReducer(state = initialState.isLoading, action) {
    switch (action.type) {
        case ACTION_TYPES.IS_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ACTION_TYPES.IS_NOT_LOADING:
            return{
                ...state,
                isLoading: false,
            };
        default:
            return {
                state: state
            }
    }
}

export function contadorPeticionesReducer(state = initialState.contadorPeticiones, action) {
    switch (action.type) {
        case ACTION_TYPES.SUMA_PETICION:
            return state + 1;
        case ACTION_TYPES.RESTA_PETICION:
            return state - 1;
        default:
            return state

    }
}

export const getIsLoading = state => state.isLoading;
export const getContadorPeticiones = state => state.contadorPeticiones;
