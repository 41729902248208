

export default function validaFiltrosElemento(
    baseUrl, params, validFilters=['agrupacion__proyecto', 'agrupacion', 'proyecto_id', 'pdi_proyecto']
) {

    let query = []

    if (typeof params === 'object' && params !== null) {
        for (const [key, value] of Object.entries(params)) {
            if (validFilters.includes(key)) {
                query.push(key + '=' + value)
            } else {
                throw new Error('El campo de filtro ' + key + ' no es válido.')
            }
        }
    } else {
        throw  new Error('Debe especificarse algún filtro.')
    }

    return baseUrl + '?' + query.join('&')
}

