import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Marker} from "react-leaflet";

import {Icon} from "../lib/leaflet.awesome-markers-svg";
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css';
import '../lib/icons/icons.css';

import {actualizaPunto, eliminarPunto} from "../fetchactions/puntos";
import {actions} from "../actions/puntos";
import {actions as actionsEditor} from "../actions/editor";
import {getDuplicando, getObjetoSeleccionado} from "../reducers/editor";
import {getPuntoActualizando} from "../reducers/puntos";

export class NuevoPunto extends Component {

    constructor(props) {
        super(props);
        this.pointRef = React.createRef();
        this.type = 'npt';

        this.onDrawingEnd = this.onDrawingEnd.bind(this);
        this.onPressEsc = this.onPressEsc.bind(this);
    }

    componentDidMount() {
        const {duplicando, setDuplicando, resetNuevoPunto, addPunto, elemento, setObjetoSeleccionado,
        removeCapaColapsada} = this.props;
        removeCapaColapsada(elemento.bloque.capa);
        if(!duplicando && !elemento.bloque.automatico && !elemento.semiautomatico){
            const marker = this.pointRef.current.leafletElement;
            marker.enableEdit().startDrawing();
            marker.on('editable:drawing:end', this.onDrawingEnd);
            document.addEventListener('keyup', this.onPressEsc);
            setObjetoSeleccionado(this);
        } else {
            setDuplicando(false);
            resetNuevoPunto();
            addPunto(elemento);
        }
    }

    onDrawingEnd(){
        if(this.pointRef.current) {
            const {actualizaPunto, elemento, puntoActualizando, setVisibleModalPropAvanzada} = this.props;
            const marker = this.pointRef.current.leafletElement;
            marker.disableEdit();
            actualizaPunto(elemento.id, {
                nombre: puntoActualizando && puntoActualizando.id === elemento.id ?
                    puntoActualizando.nombre : elemento.nombre,
                bloque: elemento.bloque.id,
                icono: elemento.icono.id,
                coordenadas: marker.toGeoJSON().geometry
            });
            setVisibleModalPropAvanzada(true);
            //resetNuevoPunto();
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {elemento} = this.props;
        const marker = this.pointRef.current.leafletElement;
        if(nextProps.puntoActualizando && nextProps.puntoActualizando.id === elemento.id) {
            marker._icon.style.fill = nextProps.puntoActualizando.color;
            if(marker._icon.querySelectorAll('i').length)
                marker._icon.querySelectorAll('i')[0].style.color = nextProps.puntoActualizando.color_icono;
        }
        return false;
    }

    getIcon(){
        const {elemento} = this.props;
        return new Icon({
            iconSize: [elemento.icono.fondo.ancho, elemento.icono.fondo.alto],
            iconAnchor: [elemento.icono.fondo.centro_x, elemento.icono.fondo.centro_y],
            icon: elemento.icono.icono,
            iconColor: elemento.color_icono,
            prefix: elemento.icono.prefijo_fuente,
            markerColor: elemento.color,
            svg: elemento.icono.fondo.svg,
            fontSizeIcon: elemento.icono.fondo.tamano_fuente_icono,
            classFondo: ' icono-' + elemento.icono.fondo.nombre
        });
    }

    onPressEsc(e){
        if(e.keyCode === 27){
            const {resetNuevoPunto, eliminarPunto, elemento} = this.props;
            eliminarPunto(elemento)
            resetNuevoPunto();
        }
    }

    componentWillUnmount() {
        const {setObjetoSeleccionado} = this.props;
        setObjetoSeleccionado(null);
        document.removeEventListener('keyup', this.onPressEsc);
    }

    render() {
        return <Marker icon={this.getIcon()} ref={this.pointRef} position={[0,0]} />
    }
}

const mapStateToProps = state => ({
    duplicando: getDuplicando(state),
    objetoSeleccionado: getObjetoSeleccionado(state),
    puntoActualizando: getPuntoActualizando(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    actualizaPunto: actualizaPunto,
    resetNuevoPunto: actions.resetNuevoPunto,
    setDuplicando: actionsEditor.setDuplicando,
    addPunto: actions.addPunto,
    setObjetoSeleccionado: actionsEditor.objetoSeleccionado,
    removeCapaColapsada: actionsEditor.removeCapaColapsada,
    eliminarPunto: eliminarPunto,
    setVisibleModalPropAvanzada: actionsEditor.setVisibleModalPropAvanzadas,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NuevoPunto);
