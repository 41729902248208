export const ACTION_TYPES = {
    FETCH_MAPAS_PENDIENTE: 'FETCH_MAPAS_PENDIENTE',
    FETCH_MAPAS_CORRECTO: 'FETCH_MAPAS_CORRECTO',
    FETCH_MAPAS_ERROR: 'FETCH_MAPAS_ERROR',
    FETCH_MAPA_PENDIENTE: 'FETCH_MAPA_PENDIENTE',
    FETCH_MAPA_CORRECTO: 'FETCH_MAPA_CORRECTO',
    FETCH_MAPA_ERROR: 'FETCH_MAPA_ERROR'
};


function fetchMapasPendiente() {
    return {
        type: ACTION_TYPES.FETCH_MAPAS_PENDIENTE
    };
}

function fetchMapasCorrecto(mapas) {
    return {
        type: ACTION_TYPES.FETCH_MAPAS_CORRECTO,
        mapas: mapas,
    };
}

function fetchMapasError(err) {
    return {
        type: ACTION_TYPES.FETCH_MAPAS_ERROR,
        error: err
    };
}

function fetchMapaPendiente() {
    return {
        type: ACTION_TYPES.FETCH_MAPA_PENDIENTE
    };
}

function fetchMapaCorrecto(mapa) {
    return {
        type: ACTION_TYPES.FETCH_MAPA_CORRECTO,
        mapa: mapa,
    };
}

function fetchMapaError(err) {
    return {
        type: ACTION_TYPES.FETCH_MAPA_ERROR,
        error: err
    };
}


export const actions = {
    fetchMapasCorrecto: fetchMapasCorrecto,
    fetchMapasError: fetchMapasError,
    fetchMapasPendiente: fetchMapasPendiente,
    fetchMapaCorrecto: fetchMapaCorrecto,
    fetchMapaError: fetchMapaError,
    fetchMapaPendiente: fetchMapaPendiente
};
