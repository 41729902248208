import { initialState } from "../constants/state"
import { ACTION_TYPES } from "../actions/montaje"
import { ACTION_TYPES as ACTION_TYPES_ELM } from '../actions/elementosMontaje'

export function mapasMontajeReducer (state = initialState.mapasMontaje, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_MAPAS_MONTAJE_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: [],
                error: null
            }

        case ACTION_TYPES.FETCH_MAPAS_MONTAJE_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.mapasMontaje
            }

        case ACTION_TYPES.FETCH_MAPAS_MONTAJE_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        case ACTION_TYPES.CREATE_MAPA_MONTAJE_SUCCESS:
            return {
                ...state,
                data: state.data.concat(action.mapaMontaje)
            }

        case ACTION_TYPES.UPDATE_MAPA_MONTAJE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(mm => mm.id !== action.mapaMontaje.id).concat(action.mapaMontaje)
            }

        case ACTION_TYPES.DELETE_MAPA_MONTAJE_SUCCESS:
            return {
                ...state,
                data: state.data.filter(mm => mm.id !== action.mapaMontaje.id)
            }

        case ACTION_TYPES_ELM.CREATE_ELEMENTO_MONTAJE_SUCCESS:
            return {
                ...state,
                data: state.data.map(mm => {
                    if (mm.id === action.elementoMontaje.montaje) {
                        mm.elementos_montaje = mm.elementos_montaje
                            ? mm.elementos_montaje.concat(action.elementoMontaje.elemento.id)
                            : [action.elementoMontaje.elemento.id]
                    }
                    return mm
                })
            }

        case ACTION_TYPES_ELM.DELETE_ELEMENTO_MONTAJE_SUCCESS:
            return {
                ...state,
                data: state.data.map(mm => {
                    if (mm.id === action.elementoMontaje.montaje) {
                        mm.elementos_montaje = mm.elementos_montaje
                            ? mm.elementos_montaje.filter(e => e !== action.elementoMontaje.elemento)
                            : []
                    }
                    return mm
                })
            }

        default:
            return {
                ...state
            }
    }
}

export function montajeEditandoReducer (state = initialState.montajeEditando, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_NOMBRE:
            return {
                ...state,
                nombre: action.nombre
            }

        case ACTION_TYPES.SET_CAPA_FONDO:
            return {
                ...state,
                capa_fondo: action.capaFondo
            }

        case ACTION_TYPES.SET_VISIBLE:
            return {
                ...state,
                visible: action.visible
            }

        case ACTION_TYPES.SET_DESCRIPCION:
            return {
                ...state,
                descripcion: action.descripcion
            }

        case ACTION_TYPES.SET_META_DESCRIPCION:
            return {
                ...state,
                meta_descripcion: action.metaDescripcion
            }

        case ACTION_TYPES.RESET_MONTAJE_EDITANDO:
            return {
                ...state,
                nombre: '',
                capa_fondo: 11,
                visible: true,
                descripcion: '',
                meta_descripcion: '',
                elementos_montaje: []
            }

        case ACTION_TYPES.ADD_ELEMENTO_MONTAJE:
            return {
                ...state,
                elementos_montaje: state.elementos_montaje.concat(action.elemento)
            }

        case ACTION_TYPES.REMOVE_ELEMENTO_MONTAJE:
            return {
                ...state,
                elementos_montaje: state.elementos_montaje.filter(em => em !== action.elemento)
            }

        case ACTION_TYPES.SET_MAPA_MONTAJE_EDITANDO:
            return JSON.parse(JSON.stringify(action.mapaMontaje))


        default:
            return {
                ...state
            }
    }
}

export function mapaMontajeCreateReducer (state = initialState.mapaMontajeCreate, action) {
    switch (action.type) {
        case ACTION_TYPES.CREATE_MAPA_MONTAJE_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.CREATE_MAPA_MONTAJE_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.mapaMontaje
            }

        case ACTION_TYPES.CREATE_MAPA_MONTAJE_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}

export function mapaMontajeUpdateReducer (state = initialState.mapaMontajeUpdate, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_MAPA_MONTAJE_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.UPDATE_MAPA_MONTAJE_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.mapaMontaje
            }

        case ACTION_TYPES.UPDATE_MAPA_MONTAJE_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}

export function mapaMontajeDeleteReducer (state = initialState.mapaMontajeDelete, action) {
    switch (action.type) {
        case ACTION_TYPES.DELETE_MAPA_MONTAJE_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.DELETE_MAPA_MONTAJE_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.mapaMontaje
            }

        case ACTION_TYPES.DELETE_MAPA_MONTAJE_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}
