import React, {Component} from 'react';
import { Map } from 'react-leaflet';
import Control from 'react-leaflet-control'
import * as L from "leaflet";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Spinner} from "react-bootstrap";
import * as turf from "@turf/turf";
import AlgoliaPlaces from 'algolia-places-react';
import {withTranslation} from "react-i18next";

import './MapaContenedor.css';

import {
    getBarraIzquierdaAbierta,
    getCapaFondoActiva,
    getCapasTransparentesAtivas,
    getElementosVisibles, getFeaturesIniciales, getObjetoSeleccionado
} from "../../reducers/editor";
import CapaMapa from "../../components/CapaMapa";
import {getCapasFondoError, getCapasFondoPendiente} from "../../reducers/capasFondo";
import {getProyecto} from "../../reducers/proyectos";
import {getTrayectoNuevo, getTrayectos, getTrayectosError, getTrayectosPendiente} from "../../reducers/trayectos";
import NuevoTrayecto from "../../components/NuevoTrayecto";
import FeatureGroup from "react-leaflet/es/FeatureGroup";
import Trayecto from "../../components/Trayecto";
import {getPuntoNuevo, getPuntos, getPuntosError, getPuntosPendiente} from "../../reducers/puntos";
import {getLineaNueva, getLineas, getLineasError, getLineasPendiente} from "../../reducers/lineas";
import {getPoligonoNuevo, getPoligonos, getPoligonosError, getPoligonosPendiente} from "../../reducers/poligonos";
import Punto from "../../components/Punto";
import NuevoPunto from "../../components/NuevoPunto";
import Linea from "../../components/Linea.js";
import NuevaLinea from "../../components/NuevaLinea";
import Poligono from "../../components/Poligono";
import NuevoPoligono from "../../components/NuevoPoligono";
import NuevoPuntoSobreTrayecto from "../../components/NuevoPuntoSobreTrayecto";
import PuntoSobreTrayecto from "../../components/PuntoSobreTrayecto";
import EstadoProyecto from "./EstadoProyecto";

//require('leaflet-editable');


class MapaContenedor extends Component{

    constructor(props) {
        super(props);
        this.mapRef = React.createRef();

        this.onHitSuggestionBuscador = this.onHitSuggestionBuscador.bind(this);
        this.onLocate = this.onLocate.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {barraIzquierdaAbierta, trayectoNuevo} = this.props;
        if(prevProps.barraIzquierdaAbierta && prevProps.barraIzquierdaAbierta !== barraIzquierdaAbierta){
            this.redimensiona();
        }
        if(trayectoNuevo) {
            if(trayectoNuevo.coordenadas.coordinates.length > 0) this.centraEn(trayectoNuevo)
        }
    }

    centraEn(elemento) {
        const map = this.mapRef.current.leafletElement;
        let enveloped = turf.envelope(elemento.coordenadas);
        map.flyToBounds(L.geoJson(enveloped).getBounds());
    }

    getCenter() {
        const {featuresIniciales} = this.props;
        let features = [];
        featuresIniciales.lineas.map(linea => features.push(turf.lineString(linea.coordenadas.coordinates)));
        featuresIniciales.poligonos.map(poligono => features.push(turf.polygon(poligono.coordenadas.coordinates)));
        featuresIniciales.puntos.map(punto => features.push(turf.point(punto.coordenadas.coordinates)));
        if(features.length === 0) return {center: L.latLng(43.045817, -7.569672)};
        else {
            let enveloped = turf.envelope(turf.featureCollection(features));
            let geojson = L.geoJson(enveloped);
            return {bounds: geojson.getBounds()};
        }
    }

    shouldRenderComponent() {
        const {trayectosError, trayectosPendiente, puntosError, puntosPendiente, lineasError, lineasPendiente,
        poligonosError, poligonosPendiente} = this.props;
        return !trayectosError && !trayectosPendiente && !puntosError && !puntosPendiente && !lineasError
            && !lineasPendiente && !poligonosError && !poligonosPendiente;
    }

    redimensiona(){
        setTimeout( ( ) => {
            let map = this.mapRef.current.leafletElement;
            map.invalidateSize();
        }, 200);
    }

    elementoIsVisible(elemento) {
        const {elementosVisibles} = this.props;
        return elementosVisibles.filter(elementoVisible => elementoVisible.id === elemento.id).length > 0;
    }

    onHitSuggestionBuscador(e) {
        if(this.mapRef.current) {
            const map = this.mapRef.current.leafletElement;
            map.flyTo([e.suggestion.latlng.lat, e.suggestion.latlng.lng], 17);
        }
    }

    onLocate(e) {
        if(this.mapRef.current) {
            const map = this.mapRef.current.leafletElement;
            map.locate();
            map.on('locationfound', e => {
                map.flyTo(e.latlng, 17);
                map.off('locationfound');
                map.stopLocate();
            });
            map.on('locationerror', e => {
                console.error(e);
                map.off('locationerror');
                map.stopLocate();
            });
        }
    }

    render() {
        const {
            t, barraIzquierdaAbierta, capasFondoPendiente, capasFondoError, capaFondo, capasTransparentes, trayectos,
            nuevoTrayecto, puntos, puntoNuevo, lineas, lineaNueva, poligonos, poligonoNuevo, objetoSeleccionado
        } = this.props

        if(!capasFondoPendiente && !capasFondoError && this.shouldRenderComponent()){
            return <Map editable={true} center={this.getCenter().center} zoomControl={false} ref={this.mapRef}
                        zoom={this.getCenter().center ? 6 : undefined} bounds={this.getCenter().bounds} maxZoom={22}
                        minZoom={4} worldCopyJump
                        className={'contenedor-mapa-editor' +
                        (barraIzquierdaAbierta ? '' : ' contenedor-mapa-editor-extended')}>
                <Control position='topleft'>
                    <AlgoliaPlaces
                        placeholder={t('msg-buscador-mapa')}
                        options={{
                            appId: 'pl1X4NXX5MYP',
                            apiKey: '45642adc9fe03b39cafe804348f9c050',
                            language: 'es'
                        }}
                        onCursorChanged={this.onHitSuggestionBuscador}
                        onLocate={this.onLocate}
                    />
                </Control>
                {!objetoSeleccionado &&
                    <EstadoProyecto />}
                <CapaMapa capa={capaFondo} map={this.mapRef}/>
                {capasTransparentes.map(capa => <CapaMapa key={capa.id} capa={capa}/>)}
                <FeatureGroup>
                    {nuevoTrayecto &&
                        <NuevoTrayecto elemento={nuevoTrayecto} map={this.mapRef}/>
                    }
                </FeatureGroup>
                <FeatureGroup>
                    {trayectos.map(trayecto =>
                        this.elementoIsVisible(trayecto) && (!nuevoTrayecto || nuevoTrayecto.id !== trayecto.id) &&
                        <Trayecto key={trayecto.id} elemento={trayecto} editable={true} map={this.mapRef}/>
                    )}
                </FeatureGroup>
                <FeatureGroup>
                    {(puntoNuevo && !puntoNuevo.bloque.pdi_sobre_trayecto) ?
                        <NuevoPunto elemento={puntoNuevo} map={this.mapRef}/>:
                        puntoNuevo &&
                            <NuevoPuntoSobreTrayecto elemento={puntoNuevo} map={this.mapRef}/>
                    }
                </FeatureGroup>
                <FeatureGroup>
                    {puntos.map(punto =>
                        punto.bloque.pdi_sobre_trayecto === false ?
                            this.elementoIsVisible(punto) && (!puntoNuevo || puntoNuevo.id !== punto.id) &&
                                <Punto key={punto.id} elemento={punto} map={this.mapRef}/> :
                            this.elementoIsVisible(punto) && (!puntoNuevo || puntoNuevo.id !== punto.id) &&
                                <PuntoSobreTrayecto key={punto.id} elemento={punto} map={this.mapRef}/>
                    )}
                </FeatureGroup>
                <FeatureGroup>
                    {lineaNueva &&
                        <NuevaLinea elemento={lineaNueva} map={this.mapRef}/>
                    }
                </FeatureGroup>
                <FeatureGroup>
                    {lineas.map(linea => this.elementoIsVisible(linea) && (!lineaNueva || lineaNueva.id !== linea.id) &&
                        <Linea key={linea.id} elemento={linea} map={this.mapRef}/>
                    )}
                </FeatureGroup>
                <FeatureGroup>
                    {poligonoNuevo &&
                        <NuevoPoligono elemento={poligonoNuevo} map={this.mapRef}/>
                    }
                </FeatureGroup>
                <FeatureGroup>
                    {poligonos.map(poligono => this.elementoIsVisible(poligono) && (!poligonoNuevo || poligonoNuevo.id !== poligono.id) &&
                        <Poligono key={poligono.id} elemento={poligono} map={this.mapRef}/>
                    )}
                </FeatureGroup>
            </Map>
        } else if(capasFondoPendiente) {
            return <div className='fondo-carga'>
                <Spinner className='spinner-carga' animation="border" role="status">
                    <span className="sr-only">Cargando...</span>
                </Spinner>
            </div>                                                  //TODO: animacion carga
        } else {
            return <div>Error carga capas </div>    //TODO: error
        }
    }
}


const mapStateToProps = state => ({
    barraIzquierdaAbierta: getBarraIzquierdaAbierta(state),
    capasFondoPendiente: getCapasFondoPendiente(state),
    capasFondoError: getCapasFondoError(state),
    capaFondo: getCapaFondoActiva(state),
    capasTransparentes: getCapasTransparentesAtivas(state),
    proyecto: getProyecto(state),
    nuevoTrayecto: getTrayectoNuevo(state),
    trayectos: getTrayectos(state),
    elementosVisibles: getElementosVisibles(state),
    trayectosError: getTrayectosError(state),
    trayectosPendiente: getTrayectosPendiente(state),
    puntos: getPuntos(state),
    puntosError: getPuntosError(state),
    puntosPendiente: getPuntosPendiente(state),
    lineas: getLineas(state),
    lineasError: getLineasError(state),
    lineasPendiente: getLineasPendiente(state),
    poligonos: getPoligonos(state),
    poligonosError: getPoligonosError(state),
    poligonosPendiente: getPoligonosPendiente(state),
    featuresIniciales: getFeaturesIniciales(state),
    trayectoNuevo: getTrayectoNuevo(state),
    puntoNuevo: getPuntoNuevo(state),
    lineaNueva: getLineaNueva(state),
    poligonoNuevo: getPoligonoNuevo(state),
    objetoSeleccionado: getObjetoSeleccionado(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
}, dispatch);


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MapaContenedor));
