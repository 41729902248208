export const ACTION_TYPES = {
    FETCH_USUARIO_ERROR: 'FETCH_USUARIO_ERROR',
    FETCH_USUARIO_CORRECTO: 'FETCH_USUARIO_CORRECTO',
    FETCH_USUARIO_PENDIENTE: 'FETCH_USUARIO_PENDIENTE'
};

function fetchUsuarioError(error) {
    return {
        type: ACTION_TYPES.FETCH_USUARIO_ERROR,
        error: error,
        pendiente: false,
        data: null
    };
}

function fetchUsuarioCorrecto(data) {
    return {
        type: ACTION_TYPES.FETCH_USUARIO_CORRECTO,
        data: data,
        pendiente: false,
        error: null
    };
}

function fetchUsuarioPendiente() {
    return {
        type: ACTION_TYPES.FETCH_USUARIO_PENDIENTE,
        pendiente: true,
        error: null,
        data: null
    }
}

export const actions = {
    fetchUsuarioError: fetchUsuarioError,
    fetchUsuarioCorrecto: fetchUsuarioCorrecto,
    fetchUsuarioPendiente: fetchUsuarioPendiente
}
