export const TIPOS_MAPAS_NOMBRES = {
    RECORRIDOS: 'Recorrido',
    STAFF: 'Personal',
    TRAFICO: 'Trafico',
    MONTAJE: 'Montaje'
}

export const initialState = {
    error: null,
    contadorPeticiones: 0,
    usuario: {
      data: null,
      error: null,
      pendiente: false,
    },
    agrupacionActualizando: {
        elemento: null,
        error: false,
        pendiente: false
    },
    agrupacionEliminar: {
        correcto: false,
        elemento: null,
        error: false,
        pendiente: false
    },
    agrupacionNueva: {
        elemento: null,
        error: false,
        pendiente: false,
    },
    agrupacionesProyecto: {
        error: false,
        list: [],
        pendiente: true
    },
    capas: {
        error: false,
        list: [],
        pendiente: true
    },
    capasFondo: {
        error: false,
        list: [],
        pendiente: true,
    },
    deportes: {
        error: false,
        list: [],
        pendiente: true
    },
    editor: {
        activeKeySecuenciaSector: null,
        agrupacionesColapsadas: [],
        agrupacionSeleccionada: null,
        animacionCrearRecorrido: false,
        avisoDistanciaMostrado: false,
        barraIzquierdaAbierta: true,
        barraHerramientasVisible: false,
        bloqueSeleccionado: null,
        capaBiblioSeleccionada: null,
        capasColapsadasBarra: [],
        capaFondo: {},
        capasTransparentes: [],
        contadorEsquemasCreados: 0,
        dibujandoTrayecto: false,
        displayDistanciasPick: null,
        displayMediciones: {
            distancia: 0,
            area: 0,
            latitud: null,
            longitud: null,
        },
        duplicando: false,
        elementoRecienSubido: null,
        elementosImportar: [],
        elementosVisibles: [],
        featuresIniciales: {
            lineas: [],
            poligonos: [],
            puntos: []
        },
        filtroAnadirElementos: '',
        filtroVisiblesActivo: 't',
        formasFicheroSubido: [],
        grupoBloquesSeleccionado: null,
        idUltimoRecorridoCreado: null,
        indexSectorPerfil: 0,
        iniciado: false,
        modalAnadirElementoVisible: false,
        modalAnadirServiciosVisible: false,
        modalBienvenidoVisible: true,
        modalBorrarPksVisible: false,
        modalConfirmarRecorteVisible: false,
        modalEditarMontajeVisible: false,
        modalEditarRecorridoVisible: false,
        modalEditarServiciosVisible: false,
        modalEditarTraficoVisible: false,
        modalEditarVoluntariosVisible: false,
        modalEliminarMontajeVisible: false,
        modalEliminarRecorridoVisible: false,
        modalEliminarTraficoVisible: false,
        modalEliminarTrayectoVisible: false,
        modalEliminarVoluntariosVisible: false,
        modalImportarVisible: false,
        modalModoCrearElementoVisible: false,
        modalMontajeVisible: false,
        modalNombreAgrupacionVisible: false,
        modalNuevoMontajeVisible: false,
        modalNuevoRecorridoVisible: false,
        modalNuevoVoluntariosVisible: false,
        modalNuevoTraficoVisible: false,
        modalOrganizarRecorridosVisible: false,
        modalPerfilVisible: false,
        modalPreferenciasVisible: false,
        modalPropAvanzadasVisible: false,
        modalSeleccionarFormaVisible: false,
        modalSubirTrackVisible: false,
        modalTraficoVisible: false,
        modalVoluntariosVisible: false,
        modoBarraHerramientas: 'tr',
        montajeEliminar: null,
        muestraAvisoDistancia: false,
        objetosDibujados: [],
        objetoSeleccionado: null,
        pdiEditar: null,
        pestanaActivaRecorrido: 1,
        pksBorrados: false,
        pksPorAnadir: [],
        previewImagenSubida: null,
        primeraCarga: true,
        recorridoCompletoCreado: false,
        recorridosPorEliminar: [],
        recorridosAsociar: [],
        recorridosReorden: [],
        redoHistory: [],
        sectorBorrarPks: null,
        sectoresAsociados: [],
        serviciosAnadidos: [],
        serviciosSeleccionados: [],
        showDropdownCapas: false,
        showDropdownRecorridos: false,
        totalEsquemasCrear: 0,
        traficoEliminar: null,
        ultimaGeometriaActualizada: null,
        ultimoElementoImportado: null,
        undoHistory: [],
        uniendo: false,
        voluntariosEliminar: null
    },
    elementoActualizado: {
        elemento: null,
        error: false,
        pendiente: false
    },
    esquemaActualizando:{
        esquema: null,
        error: false,
        pendiente: false
    },
    esquemaEliminando: {
        correcto: false,
        esquema: null,
        error: false,
        pendiente: false
    },
    esquemaNuevo: {
        esquema: null,
        error: false,
        pendiente: false
    },
    isLoading: false,
    lineaActualizando: {
        elemento: null,
        error: false,
        pendiente: false
    },
    lineaEliminada: {
        correcto: false,
        elemento: null,
        error: false,
        pendiente: false
    },
    lineaNueva: {
        elemento: null,
        error: false,
        pendiente: false
    },
    lineas: {
        error: false,
        list: [],
        pendiente: false
    },
    mapas: {
        error: false,
        list: [],
        pendiente: false
    },
    mapa: {
        error: false,
        data: null,
        pendiente: false
    },
    poligonoActualizando: {
        elemento: null,
        error: false,
        pendiente: false
    },
    poligonoEliminado: {
        correcto: false,
        elemento: null,
        error: false,
        pendiente: false
    },
    poligonoNuevo: {
        elemento: null,
        error: false,
        pendiente: false
    },
    poligonos: {
        error: false,
        list: [],
        pendiente: false
    },
    proyecto: {
        error: false,
        data: {},
        pendiente: false
    },
    proyectos: {
        error: false,
        list: [],
        pendiente: false
    },
    proyectoActualizando: {
        error: false,
        pendiente: false,
        proyecto: null
    },
    puntoActualizando: {
        error: false,
        elemento: null,
        pendiente: false
    },
    puntoEliminado: {
        correcto: false,
        elemento: null,
        error: false,
        pendiente: false
    },
    puntoNuevo: {
        error: false,
        elemento: null,
        pendiente: false
    },
    puntos: {
        error: false,
        list: [],
        pendiente: false
    },
    recorridoActualizando: {
        recorrido: null,
        pendiente: false,
        error: false
    },
    recorridoCreando: {
        deporte: null,
        sectores: [{
            nombre: 'Sector 1',
            orden: 1,
            esquemas:[],
            id: -1,
            deporte: null,
            perfil_publicado: true,
            distancia_calculada: 0,
            pdis_recorrido: [],
            generar_inicio_fin: true,
            puntos_km_auto: true,
            cadencia_km: 1000,
            color: '#aaaaaa'
        }],
        elementos: [],
        nombre: null,
        descripcion: null,
        unidad_distancia: 'm',
        publicado: true,
        dificultad: null,
        superficie: null,
        capa_fondo: 11,
        visible: true
    },
    recorridoEliminando: {
        correcto: false,
        error: false,
        pendiente: false,
        recorrido: null
    },
    recorridoNuevo: {
        correcto: false,
        recorrido: null,
        pendiente: false,
    },
    recorrido: {
        data: null,
        error: false,
        pendiente: false
    },
    recorridos: {
        list: [],
        error: false,
        pendiente: false
    },
    sectorActualizando: {
        error: false,
        pendiente: false,
        sector: null
    },
    sectorEliminando: {
        correcto: false,
        error: false,
        pendiente: false,
        sector: null
    },
    sectorNuevo: {
        error: false,
        pendiente: false,
        sector: null
    },
    sector: {
        error: false,
        pendiente: false,
        data: null
    },
    servicios: {
        error: false,
        list: [],
        pendiente: true
    },
    tiposMapas: {
        pendiente: false,
        data: [],
        error: false
    },
    trayectoActualizando: {
        correcto: false,
        elemento: null,
        error: false,
        pendiente: false
    },
    trayectoEliminado: {
        correcto: false,
        elemento: null,
        error: false,
        pendiente: false
    },
    trayectoNuevo: {
        elemento: null,
        error: false,
        pendiente: false,
    },
    trayectos: {
        error: false,
        list: [],
        pendiente: false
    },
    visor: {
        compartirVisible: false,
        compartirInsertarVisible: false,
        modalEsquemaVisible: false,
        modalPdiSeleccionado: null,
        modalPdisVisible: false,
        modalPdisProyectoVisible: false,
        modalPerfilVisible: false,
        modalPuntosKmSeleccionado: null,
        submenuHerramientasVisible: false,
        submenuPdisVisible: false,
        bounds: false,
        mapaPdisProyectoVisible: true,
        mapaPdisRecorridoVisible: true,
        modalCambiarVistaVisible: false,
        capaFondo: null,
        graficoDomain: null,
        graficoZoomDomain: null,
    },
    carrito: {
        proyecto: null,
        recorridos: [],
        precio: null,
        customer: null,
        procesando: false,
        modalVisible: false,
        pagoCorrecto: null,
        pagoErrorCode: null,
        pagoErrorDesc: null,
        stripeCardError: null,
    },
    ultimasAgrupacionesCargadas: {
        pendiente: false,
        data: [],
        error: null
    },
    voluntarios: {
        pendiente: false,
        data: [],
        error: null
    },
    voluntariosEditando: {
        nombre: '',
        capa_fondo: 11,
        visible: true,
        visible_todos: false,
        descripcion: '',
        meta_descripcion: '',
        elementos_voluntarios: []
    },
    voluntariosCreate: {
        pendiente: false,
        data: [],
        error: null
    },
    voluntariosDelete: {
        pendiente: false,
        data: [],
        error: null
    },
    voluntariosUpdate: {
        pendiente: false,
        data: [],
        error: null
    },
    elementoVoluntariosCreate: {
        pendiente: false,
        data: null,
        error: null
    },
    elementoVoluntariosDelete: {
        pendiente: false,
        data: null,
        error: null
    },
    contactos: {
        pendiente: false,
        data: [],
        error: null
    },
    mapasTrafico: {
        pendiente: false,
        data: [],
        error: null
    },
    traficoEditando: {
        nombre: '',
        capa_fondo: 11,
        visible: true,
        descripcion: '',
        meta_descripcion: '',
        elementos_trafico: []
    },
    mapaTraficoCreate: {
        pendiente: false,
        data: null,
        error: null
    },
    elementoTraficoCreate: {
        pendiente: false,
        data: null,
        error: null
    },
    elementoTraficoDelete: {
        pendiente: false,
        data: null,
        error: null
    },
    mapaTraficoDelete: {
        pendiente: false,
        data: null,
        error: null
    },
    mapaTraficoUpdate: {
        pendiente: false,
        data: null,
        error: null
    },
    mapasMontaje: {
        pendiente: false,
        data: [],
        error: null
    },
    montajeEditando: {
        nombre: '',
        capa_fondo: 11,
        visible: true,
        descripcion: '',
        meta_descripcion: '',
        elementos_montaje: []
    },
    mapaMontajeCreate: {
        pendiente: false,
        data: null,
        error: null
    },
    elementoMontajeCreate: {
        pendiente: false,
        data: null,
        error: null
    },
    elementoMontajeDelete: {
        pendiente: false,
        data: null,
        error: null
    },
    mapaMontajeUpdate: {
        pendiente: false,
        data: null,
        error: null
    },
    mapaMontajeDelete: {
        pendiente: false,
        data: null,
        error: null
    }
}
