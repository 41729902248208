export const ACTION_TYPES = {
    FETCH_VOLUNTARIOS_BEGIN: 'FETCH_VOLUNTARIOS_BEGIN',
    FETCH_VOLUNTARIOS_SUCCESS: 'FETCH_VOLUNTARIOS_SUCCESS',
    FETCH_VOLUNTARIOS_ERROR: 'FETCH_VOLUNTARIOS_ERROR',
    RESET_VOLUNTARIOS_EDITANDO: 'RESET_VOLUNTARIOS_EDITANDO',
    SET_VOLUNTARIOS_EDITANDO: 'SET_VOLUNTARIOS_EDITANDO',
    SET_NOMBRE: 'SET_NOMBRE',
    SET_CAPA_FONDO: 'SET_CAPA_FONDO_VOLUNTARIOS',
    SET_DESCRIPCION: 'SET_DESCRIPCION',
    SET_META_DESCRIPCION: 'SET_META_DESCRIPCION',
    SET_VISIBLE: 'SET_VISIBLE',
    SET_VISIBLE_TODOS: 'SET_VISIBLE_TODOS',
    ADD_ELEMENTO_VOLUNTARIOS: 'ADD_ELEMENTO_VOLUNTARIOS',
    REMOVE_ELEMENTO_VOLUNTARIOS: 'REMOVE_ELEMENTO_VOLUNTARIOS',
    CREATE_VOLUNTARIOS_BEGIN: 'CREATE_VOLUNTARIOS_BEGIN',
    CREATE_VOLUNTARIOS_SUCCESS: 'CREATE_VOLUNTARIOS_SUCCESS',
    CREATE_VOLUNTARIOS_ERROR: 'CREATE_VOLUNTARIOS_ERROR',
    DELETE_VOLUNTARIOS_BEGIN: 'DELETE_VOLUNTARIOS_BEGIN',
    DELETE_VOLUNTARIOS_SUCCESS: 'DELETE_VOLUNTARIOS_SUCCESS',
    DELETE_VOLUNTARIOS_ERROR: 'DELETE_VOLUNTARIOS_ERROR',
    UPDATE_VOLUNTARIOS_BEGIN: 'UPDATE_VOLUNTARIOS_BEGIN',
    UPDATE_VOLUNTARIOS_SUCCESS: 'UPDATE_VOLUNTARIOS_SUCCESS',
    UPDATE_VOLUNTARIOS_ERROR: 'UPDATE_VOLUNTARIOS_ERROR'
}

function fetchVoluntariosBegin () {
    return {
        type: ACTION_TYPES.FETCH_VOLUNTARIOS_BEGIN
    }
}

function fetchVoluntariosSuccess (voluntarios) {
    return {
        type: ACTION_TYPES.FETCH_VOLUNTARIOS_SUCCESS,
        voluntarios
    }
}

function fetchVoluntariosError (error) {
    return {
        type: ACTION_TYPES.FETCH_VOLUNTARIOS_ERROR,
        error
    }
}

function setNombre (nombre) {
    return {
        type: ACTION_TYPES.SET_NOMBRE,
        nombre
    }
}

function setCapaFondo (capaFondo) {
    return {
        type: ACTION_TYPES.SET_CAPA_FONDO,
        capaFondo
    }
}

function setDescripcion (descripcion) {
    return {
        type: ACTION_TYPES.SET_DESCRIPCION,
        descripcion
    }
}

function setMetaDescripion (metaDescripcion) {
    return {
        type: ACTION_TYPES.SET_META_DESCRIPCION,
        metaDescripcion
    }
}

function setVisible (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE,
        visible
    }
}

function createVoluntariosBegin () {
    return {
        type: ACTION_TYPES.CREATE_VOLUNTARIOS_BEGIN
    }
}

function createVoluntariosSuccess (voluntarios) {
    return {
        type: ACTION_TYPES.CREATE_VOLUNTARIOS_SUCCESS,
        voluntarios
    }
}

function createVoluntariosError (error) {
    return {
        type: ACTION_TYPES.CREATE_VOLUNTARIOS_ERROR,
        error
    }
}

function addElementoVoluntario (elemento) {
    return {
        type: ACTION_TYPES.ADD_ELEMENTO_VOLUNTARIOS,
        elemento
    }
}

function removeElementoVoluntario (elemento) {
    return {
        type: ACTION_TYPES.REMOVE_ELEMENTO_VOLUNTARIOS,
        elemento
    }
}

function resetVoluntariosEditando () {
    return {
        type: ACTION_TYPES.RESET_VOLUNTARIOS_EDITANDO
    }
}

function deleteVoluntariosBegin () {
    return {
        type: ACTION_TYPES.DELETE_VOLUNTARIOS_BEGIN
    }
}

function deleteVoluntariosSuccess (voluntarios) {
    return {
        type: ACTION_TYPES.DELETE_VOLUNTARIOS_SUCCESS,
        voluntarios
    }
}

function deleteVoluntariosError (error) {
    return {
        type: ACTION_TYPES.DELETE_VOLUNTARIOS_ERROR,
        error
    }
}

function setVoluntariosEditando(voluntarios) {
    return {
        type: ACTION_TYPES.SET_VOLUNTARIOS_EDITANDO,
        voluntarios
    }
}

function updateVoluntariosBegin () {
    return {
        type: ACTION_TYPES.UPDATE_VOLUNTARIOS_BEGIN
    }
}

function updateVoluntariosSuccess (voluntarios) {
    return {
        type: ACTION_TYPES.UPDATE_VOLUNTARIOS_SUCCESS,
        voluntarios
    }
}

function updateVoluntariosError (error) {
    return {
        type: ACTION_TYPES.UPDATE_VOLUNTARIOS_ERROR,
        error
    }
}

function setVisibleTodos (visibleTodos) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_TODOS,
        visibleTodos
    }
}

export const actions = {
    fetchVoluntariosBegin,
    fetchVoluntariosSuccess,
    fetchVoluntariosError,
    resetVoluntariosEditando,
    setVoluntariosEditando,
    setNombre,
    setCapaFondo,
    setDescripcion,
    setMetaDescripion,
    setVisible,
    setVisibleTodos,
    addElementoVoluntario,
    removeElementoVoluntario,
    createVoluntariosBegin,
    createVoluntariosSuccess,
    createVoluntariosError,
    deleteVoluntariosBegin,
    deleteVoluntariosSuccess,
    deleteVoluntariosError,
    updateVoluntariosBegin,
    updateVoluntariosSuccess,
    updateVoluntariosError
}
