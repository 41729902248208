import {API_BASE_URL} from "../constants/api";
import {actions} from "../actions/carrito";
import {actions as actionsComun} from '../actions/comun';
import {actions as actionsError} from '../actions/error';

export function carritoFetchCustomer() {
    return dispatch => {
        dispatch(actions.carritoSetCustomer(null));
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'stripe/customer/', {
            method: 'GET',
                headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);
                dispatch(actions.carritoSetCustomer(res.customer));
                dispatch(actionsComun.restaPeticion());
            })
            .catch(err => {
               dispatch(actionsError.hasError(err));
               dispatch(actionsComun.restaPeticion());
            });
    }
}
