/* global fetch */

import { actions } from "../actions/elementosTrafico"
import { actions as actionsComun } from '../actions/comun'
import { actions as actionsError } from '../actions/error'
import { API_BASE_URL } from "../constants/api"
import getCsrfToken from "../lib/csrf";

export function createElementoTrafico (opciones) {
    return dispatch => {
        dispatch(actions.createElementoTraficoBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'trafico-elemento/')
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createElementoTraficoError(data))
                            dispatch(actionsError.hasError(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createElementoTraficoSuccess(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                }
            })
            .catch(err => {
                dispatch(actions.createElementoTraficoError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function deleteElementoTrafico (elementoTrafico) {
    return dispatch => {
        dispatch(actions.deleteElementoTraficoBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'trafico-elemento/')
        Object.keys(elementoTrafico).forEach(
            key => url.searchParams.append(key, elementoTrafico[key])
        )
        return fetch(url, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw res.error
                } else if (res && res.length) {
                    const urlDelete = new URL(API_BASE_URL + 'trafico-elemento/' + res[0].id + '/')
                    return fetch(urlDelete, {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'X-CSRFToken': getCsrfToken()
                        }
                    })
                        .then(res => {
                            if (res.status !== 204) {
                                return res.json()
                                    .then(data => {
                                        dispatch(actionsError.hasError(data))
                                        dispatch(actionsComun.restaPeticion())
                                    })
                            } else {
                                dispatch(actions.deleteElementoTraficoSuccess(elementoTrafico))
                                dispatch(actionsComun.restaPeticion())
                            }
                        })
                        .catch(err => {
                            dispatch(actions.deleteElementoTraficoError(err))
                            dispatch(actionsError.hasError(err))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    throw new Error('Not found')
                }
            })
            .catch(err => {
                dispatch(actions.deleteElementoTraficoError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}
