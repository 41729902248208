import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from "react-i18next"

import './EstadoProyecto.css'
import {OverlayTrigger, Popover} from "react-bootstrap"

class EstadoProyecto extends Component {
    render() {
        const { trayectos, lineas, poligonos, puntos, recorridos, mapasPersonal, mapasMontaje, mapasTrafico } = this.props
        const editado = trayectos.length > 0 || lineas.length > 0 || poligonos.length > 0 || puntos.length > 0
        const configurado = recorridos.length > 0 || mapasPersonal.length > 0 || mapasMontaje.length > 0 || mapasTrafico.length > 0
        const publicado = recorridos.filter(r => r.es_pagado).length > 0 || mapasPersonal.filter(m => m.es_pagado).length > 0 ||
            mapasTrafico.filter(m => m.es_pagado).length > 0 || mapasTrafico.filter(m => m.es_pagado).length > 0
        return (
            <div className='m-auto estado-proyecto__container position-absolute w-100 d-flex align-items-center justify-content-center'>
                <div className='estado-proyecto__wrapper p-2 pb-3 d-flex align-items-center justify-content-center'>
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Popover>
                                <Popover.Content>
                                    {editado
                                        ? (
                                            <ul className='list-group list-group-flush'>
                                                <li className='list-group-item'>
                                                    {(trayectos.length + lineas.length + puntos.length + poligonos.length)} <Trans>elementos creados</Trans>
                                                </li>
                                            </ul>
                                        ) : (
                                            <Trans>
                                                Para añadir un elemento debes hacer click sobre el botón "+" de la esquina inferior derecha.
                                            </Trans>
                                        )}
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <div
                            className={
                                'estado-proyecto__item d-flex flex-column align-items-center justify-content-center'
                                + (!editado ? ' estado-proyecto__item--disabled' : '')
                            }
                        >
                            <div className='w-100 estado-proyecto__item-texto font-weight-bold'><Trans>Editado</Trans></div>
                            <div className='rounded-circle estado-proyecto__item-punto' />
                        </div>
                    </OverlayTrigger>
                    <div className={'estado-proyecto__flecha pl-2 pr-2' + (!editado ? ' estado-proyecto__flecha--disabled' : '')}>
                        <i className='fas fa-long-arrow-alt-right' />
                    </div>
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Popover>
                                <Popover.Content>
                                    {configurado
                                        ? (
                                            <ul className='list-group list-group-flush'>
                                                <li className='list-group-item'>
                                                    {recorridos.length} <Trans>Recorridos</Trans>
                                                </li>
                                                <li className='list-group-item'>
                                                    {mapasPersonal.length} <Trans>Mapas de staff</Trans>
                                                </li>
                                                <li className='list-group-item'>
                                                    {mapasTrafico.length} <Trans>Mapas de tráfico</Trans>
                                                </li>
                                                <li className='list-group-item'>
                                                    {mapasMontaje.length} <Trans>Mapas de montaje</Trans>
                                                </li>
                                            </ul>
                                        ) : (
                                            <Trans>
                                                <p>Para configurar un recorrido, debes tener, al menos, un trayecto creado, y luego debes ir la botón de "Recorridos" de la barra superior, y allí podrás configurar un nuevo recorrido.</p>
                                                <p>Si quieres configurar otro tipo de mapa has de hacerlo desde el botón "Mas mapas" de la misma barra superior y escoger el tipo de mapa que quieras crear.</p>
                                            </Trans>
                                        )}
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <div
                            className={
                                'estado-proyecto__item d-flex flex-column align-items-center justify-content-center'
                                + (!configurado ? ' estado-proyecto__item--disabled' : '')
                            }
                        >
                            <div className='w-100 estado-proyecto__item-texto font-weight-bold'><Trans>Configurado</Trans></div>
                            <div className='rounded-circle estado-proyecto__item-punto' />
                        </div>
                    </OverlayTrigger>
                    <div className={'estado-proyecto__flecha pl-2 pr-2' + (!configurado ? ' estado-proyecto__flecha--disabled' : '')}>
                        <i className='fas fa-long-arrow-alt-right' />
                    </div>
                    <OverlayTrigger
                        placement='top'
                        overlay={
                            <Popover>
                                <Popover.Content>
                                    {publicado
                                        ? (
                                            <ul className='list-group list-group-flush'>
                                                <li className='list-group-item'>
                                                    {recorridos.filter(r => r.es_pagado).length} <Trans>Recorridos</Trans>
                                                </li>
                                                <li className='list-group-item'>
                                                    {mapasPersonal.filter(m => m.es_pagado).length} <Trans>Mapas de staff</Trans>
                                                </li>
                                                <li className='list-group-item'>
                                                    {mapasTrafico.filter(m => m.es_pagado).length} <Trans>Mapas de tráfico</Trans>
                                                </li>
                                                <li className='list-group-item'>
                                                    {mapasMontaje.filter(m => m.es_pagado).length} <Trans>Mapas de montaje</Trans>
                                                </li>
                                            </ul>
                                        ) : (
                                            <Trans>
                                                Para publicar un mapa ya creado debes hacer click sobre el botón de "Publicar" de la barra superior y podrás proceder a su pago
                                            </Trans>
                                        )}
                                </Popover.Content>
                            </Popover>
                        }
                    >
                        <div
                            className={
                                'estado-proyecto__item d-flex flex-column align-items-center justify-content-center'
                                + (!publicado ? ' estado-proyecto__item--disabled' : '')
                            }
                        >
                            <div className='w-100 estado-proyecto__item-texto font-weight-bold'><Trans>Publicado</Trans></div>
                            <div className='rounded-circle estado-proyecto__item-punto' />
                        </div>
                    </OverlayTrigger>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    trayectos: state.trayectos.present.list,
    lineas: state.lineas.list,
    poligonos: state.poligonos.list,
    puntos: state.puntos.list,
    recorridos: state.recorridos.list,
    mapasPersonal: state.voluntarios.data,
    mapasTrafico: state.mapasTrafico.data,
    mapasMontaje: state.mapasMontaje.data
})

export default connect(mapStateToProps)(EstadoProyecto)
