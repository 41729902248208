import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import {Trans} from 'react-i18next'


function DisplayError(props) {
    let show = true // props.error !== null

    if (props.error) {

        let errorMsg = props.error
        if (typeof errorMsg === 'string' ) {
            // nada...
        } else if (typeof errorMsg === 'object' ) {
            if ('detail' in props.error) {
                errorMsg = props.error.detail
            } else {
                if ('item' in props.error) {
                    errorMsg = "" + props.error.item
                } else {
                    errorMsg = JSON.stringify(props.error)
                }
            }
        } else {
            errorMsg = "" + props.error
        }

        return <Modal show={show} onHide={() => props.clearError()}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <Trans>Se produjo un error</Trans>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body><div>{ errorMsg }</div></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.clearError()}>
                    <Trans>Aceptar</Trans>
                </Button>
            </Modal.Footer>
        </Modal>
    }
    return null
}

export default DisplayError
