import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/visor'

export default function visorReducer(state = initialState.visor, action) {
    // console.info(state, action)
    switch (action.type) {
        case ACTION_TYPES.MODAL_ESQUEMA_MOSTRAR:
            return {
                ...state,
                modalEsquemaVisible: true
            }
        case ACTION_TYPES.MODAL_ESQUEMA_OCULTAR:
            return {
                ...state,
                modalEsquemaVisible: false
            }

        case ACTION_TYPES.MODAL_PDIS_MOSTRAR:
            return {
                ...state,
                modalPdisVisible: true
            }
        case ACTION_TYPES.MODAL_PDIS_OCULTAR:
            return {
                ...state,
                modalPdisVisible: false
            }
        case ACTION_TYPES.MODAL_PDIS_PROYECTO_MOSTRAR:
            return {
                ...state,
                modalPdisProyectoVisible: true
            }

        case ACTION_TYPES.MODAL_PDIS_PROYECTO_OCULTAR:
            return {
                ...state,
                modalPdisProyectoVisible: false
            }

        case ACTION_TYPES.MODAL_PDI_SELECCIONADO_MOSTRAR:
            return {
                ...state,
                modalPdiSeleccionado: action.modalPdiSeleccionado
            }
        case ACTION_TYPES.MODAL_PDI_SELECCIONADO_OCULTAR:
            return {
                ...state,
                modalPdiSeleccionado: null
            }

        case ACTION_TYPES.MODAL_PERFILES_MOSTRAR:
            return {
                ...state,
                modalPerfilVisible: true
            }
        case ACTION_TYPES.MODAL_PERFILES_OCULTAR:
            return {
                ...state,
                modalPerfilVisible: false
            }


        case ACTION_TYPES.SUBMENU_HERRAMIENTAS_MOSTRAR:
            return {
                ...state,
                submenuHerramientasVisible: true
            }
        case ACTION_TYPES.SUBMENU_HERRAMIENTAS_OCULTAR:
            return {
                ...state,
                submenuHerramientasVisible: false
            }

        case ACTION_TYPES.SUBMENU_PDIS_MOSTRAR:
            return {
                ...state,
                submenuPdisVisible: true
            }
        case ACTION_TYPES.SUBMENU_PDIS_OCULTAR:
            return {
                ...state,
                submenuPdisVisible: false
            }

        case ACTION_TYPES.SET_MAP_BOUNDS:
            return {
                ...state,
               bounds: action.bounds
            }

        case ACTION_TYPES.MAPA_PDIS_PROYECTO_MOSTRAR:
            return {
                ...state,
                mapaPdisProyectoVisible: true
            }

        case ACTION_TYPES.MAPA_PDIS_PROYECTO_OCULTAR:
            return {
                ...state,
                mapaPdisProyectoVisible: false
            }

        case ACTION_TYPES.MAPA_PDIS_RECORRIDO_MOSTRAR:
            return {
                ...state,
                mapaPdisRecorridoVisible: true
            }

        case ACTION_TYPES.MAPA_PDIS_RECORRIDO_OCULTAR:
            return {
                ...state,
                mapaPdisRecorridoVisible: false
            }

        case ACTION_TYPES.COMPARTIR_MOSTRAR:
            return {
                ...state,
                compartirVisible: true
            }


        case ACTION_TYPES.COMPARTIR_OCULTAR:
            return {
                ...state,
                compartirVisible: false
            }

        case ACTION_TYPES.COMPARTIR_INSERTAR_TOGGLE:
            return {
                ...state,
                compartirInsertarVisible: !state.compartirInsertarVisible
            }

        case ACTION_TYPES.MODAL_CAMBIAR_VISTA_MOSTRAR:
            return {
                ...state,
                modalCambiarVistaVisible: true
            }


        case ACTION_TYPES.MODAL_CAMBIAR_VISTA_OCULTAR:
            return {
                ...state,
                modalCambiarVistaVisible: false
            }

        case ACTION_TYPES.SELECCIONAR_CAPA_FONDO:
            return {
                ...state,
                capaFondo: action.capa
            }

        case ACTION_TYPES.SELECCIONAR_PUNTO_KM:
            return {
                ...state,
                modalPuntosKmSeleccionado: action.punto
            }

        case ACTION_TYPES.SET_GRAFICO_DOMAIN:
            return {
                ...state,
                graficoDomain: action.domain
            }

        case ACTION_TYPES.SET_GRAFICO_ZOOM_DOMAIN:
            return {
                ...state,
                graficoZoomDomain: action.domain
            }

        default:
            return {
                ...state
            }
    }
}


export const visorMostrarModalEsquema = state => state.visor.modalEsquemaMostrar
