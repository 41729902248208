import {API_BASE_URL} from "../constants/api";
import {actions} from "../actions/trayectos";
import {actions as actionsComun} from "../actions/comun";
import {actions as actionsEditor} from "../actions/editor";
import {actions as actionsAgrupaciones} from "../actions/agrupaciones";
import {fetchAgrupacion} from "./agrupaciones";
import getCsrfToken from '../lib/csrf'
import { actions as actionsError } from '../actions/error'
import validaFiltrosElemento from './utils'

export function fetchTrayectos(params) {
    const url = validaFiltrosElemento(API_BASE_URL + 'trayecto/', params)

    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.fetchTrayectosPendiente());
        fetch(url)
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);
                dispatch(actions.fetchTrayectosCorrecto(res));
                dispatch(actionsComun.restaPeticion());
                return res;
            })
            .catch(err => {
                dispatch(actions.fetchTrayectosError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function creaNuevoTrayecto(opciones, subido, importado) {
    return dispatch => {
       dispatch(actionsComun.sumaPeticion());
       dispatch(actions.trayectoNuevoPendiente());
       fetch(API_BASE_URL + 'trayecto/', {
           method: 'POST',
           headers: {
               'Content-Type': 'application/json',
               'X-CSRFToken': getCsrfToken()
           },
           credentials: 'include',
           body: JSON.stringify(opciones)
       })
           .then(res => {
               if (res.status !== 201) {
                   return res.json()
                       .then(data => {
                           dispatch(actionsError.hasError(data));
                           dispatch(actionsComun.restaPeticion());
                       })
               } else {
                   return res.json()
                       .then(data => {
                           dispatch(actions.trayectoNuevoElemento(data));
                           dispatch(actionsComun.restaPeticion());
                           dispatch(fetchAgrupacion(data.agrupacion));
                           dispatch(actionsEditor.addElementoVisible(data));
                           if(subido) {
                               dispatch(actions.resetTrayectoNuevo());
                               dispatch(actions.addTrayecto(data));
                               dispatch(actionsEditor.setElementoRecienSubido(data));
                           }
                           if (importado) {
                               dispatch(actions.resetTrayectoNuevo())
                               dispatch(actions.addTrayecto(data))
                               dispatch(actionsEditor.setElementoImportado(data))
                           }
                       })
               }
           })
           .catch(err => {
               dispatch(actionsError.hasError(err));
               dispatch(actionsComun.restaPeticion());
           });
    }
}

export function actualizaTrayecto(id, opciones, formData) {
    return function (dispatch) {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.trayectoActualizandoPendiente());
        fetch(API_BASE_URL + 'trayecto/' + id  + '/', {
            method: 'PUT',
            headers: formData ? {
                'X-CSRFToken': getCsrfToken()
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: formData ? formData : JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.trayectoActualizandoCorrecto(data));
                            dispatch(actions.removeTrayecto(data));
                            dispatch(actions.addTrayecto(data));
                            dispatch(actionsAgrupaciones.actualizaElementoAgruapcion(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function eliminarTrayecto(trayecto) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion);
        dispatch(actions.trayectoEliminadoPendiente(trayecto));
        fetch(API_BASE_URL + 'trayecto/' + trayecto.id + '/', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
        })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    dispatch(actions.trayectoEliminadoCorrecto(trayecto));
                    dispatch(actionsComun.restaPeticion());
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}
