import {initialState} from '../constants/state';
import {ACTION_TYPES} from "../actions/capasFondo";

export function capasFondoReducer(state = initialState.capasFondo, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_CAPAS_FONDO_PENDIENTE:
            return {
                ...state,
                pendiente: true
            };
        case ACTION_TYPES.FETCH_CAPAS_FONDO_CORRECTO:
            return {
               ...state,
                list: action.capasFondo,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_CAPAS_FONDO_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state
    }
}


export const getCapasFondoPendiente = state => state.capasFondo.pendiente;
export const getCapasFondo = state =>  state.capasFondo.list;
export const getCapasFondoError = state => state.capasFondo.error;