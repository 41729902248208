import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Trans, withTranslation} from "react-i18next"

import ModalEditor from "./ModalEditor"
import { actions } from "../../actions/editor"
import {deleteMapaMontaje} from "../../fetchactions/montaje";

class ModalEliminarMontaje extends Component {
    handleClickAceptar () {
        const { dispatch, montajeEliminar } = this.props
        dispatch(deleteMapaMontaje(montajeEliminar))
        this.hide()
    }

    handleClickCancelar () {
        this.hide()
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalEliminarMontaje(false))
        dispatch(actions.setVisibleModalMontaje(true))
    }

    render() {
        const { t, montajeEliminar } = this.props
        return (
            <ModalEditor
                footer
                show
                onClickAceptar={this.handleClickAceptar.bind(this)}
                onClickCancelar={this.handleClickCancelar.bind(this)}
                onHide={this.handleClickCancelar.bind(this)}
                titulo={t('Eliminar el mapa de tráfico') + ': ' + montajeEliminar.nombre}
            >
                <div className='row'>
                    <div className='col-12'>
                        <p><Trans>¿Está seguro de querer eliminar este mapa de montaje?</Trans></p>
                    </div>
                </div>
            </ModalEditor>
        )
    }
}

const mapStateToProps = state => ({
    montajeEliminar: state.editor.montajeEliminar
})

export default withTranslation()(connect(mapStateToProps)(ModalEliminarMontaje))
