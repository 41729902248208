import { initialState } from "../constants/state"
import { ACTION_TYPES } from "../actions/tiposMapa"

export default function tiposMapasReducer (state = initialState.tiposMapas, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_TIPOS_MAPAS_BEGIN:
            return {
                ...state,
                pendiente: true,
                error: false,
                data: []
            }

        case ACTION_TYPES.FETCH_TIPOS_MAPAS_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.tiposMapas
            }

        case ACTION_TYPES.FETCH_TIPOS_MAPAS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}
