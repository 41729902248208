import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/carrito'

export default function carritoReducer (state=initialState.carrito, action) {
    // console.info(state, action)
    switch (action.type) {
        case ACTION_TYPES.CARRITO_RESET:
            return {
                ...state,
                proyecto: null,
                recorridos: [],
                precio: null,
                customer: null,
                procesando: false,
                // modalVisible: false,
                pagoCorrecto: null,
                pagoErrorCode: null,
                pagoErrorDesc: null,
                stripeCardError: null
            }

        case ACTION_TYPES.CARRITO_SET_PROYECTO:
            return {
                ...state,
                proyecto: action.proyecto,
            }

        case ACTION_TYPES.CARRITO_ADD_RECORRIDOS:
            return {
                ...state,
                recorridos: action.recorridos,
            }

        case ACTION_TYPES.CARRITO_UPDATE_PRECIO:
            return {
                ...state,
                precio: action.precio,
            }

        case ACTION_TYPES.CARRITO_SET_CUSTOMER:
            return {
                ...state,
                customer: action.customer
            }

        case ACTION_TYPES.CARRITO_SET_PROCESANDO:
            return {
                ...state,
                procesando: action.procesando
            }

        case ACTION_TYPES.CARRITO_UNSET_PROCESANDO:
            return {
                ...state,
                procesando: action.procesando
            }


        case ACTION_TYPES.CARRITO_MODAL_VISIBLE:
            return {
                ...state,
                modalVisible: action.modalVisible
            }


        case ACTION_TYPES.CARRITO_PAGO_OK:
            return {
                ...state,
                pagoCorrecto: true,
                pagoErrorDesc: null,
                pagoErrorCode: null,
                stripeCardError: null
            }

        case ACTION_TYPES.CARRITO_PAGO_KO:
            return {
                ...state,
                pagoCorrecto: false,
                pagoErrorDesc: action.pagoErrorDesc,
                pagoErrorCode: action.pagoErrorCode
            }

        case ACTION_TYPES.CARRITO_SET_STRIPE_CARD_ERROR:
            return {
                ...state,
                stripeCardError: action.stripeCardError,
            }


        default:
            return {
                ...state
            }
    }
}
