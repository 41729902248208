import React from "react"
import BtnBarraHerramientas from "./BtnBarraHerramientas"
import { withTranslation } from "react-i18next"

const BtnDuplicarTramo = ({ t, objetoSeleccionado, className }) => {
    if (objetoSeleccionado) {
        return (
            <BtnBarraHerramientas
                className={className}
                classIcon='rmp-ico-duplicar-tramo'
                titulo={(t('Duplicar tramo'))}
                onClick={() => objetoSeleccionado.duplicarTramo()}
            />
        )
    }
    return <></>
}

export default withTranslation()(BtnDuplicarTramo)
