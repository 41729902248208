import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Form, Nav, Row, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faArrowUp,
    faSortAmountDown,
    faSortAmountUp
} from "@fortawesome/free-solid-svg-icons";

import {getRecorridoCreando} from "../../reducers/recorridos";
import {getTrayectos} from "../../reducers/trayectos";
import {GraficoPerfil} from "../public/components/perfil/GraficoPerfil";
import {actions as actionsRecoridos} from "../../actions/recorridos";

class TabPerfilRecorrido extends Component {

    constructor(props) {
        super(props);

        this.onChangePublicado = this.onChangePublicado.bind(this);
    }

    getDesnivelPositivo(sector) {
        const esquemas = sector.esquemas;
        let acumulado = 0;
        for(let i in esquemas) {
            acumulado += esquemas[i].trayecto.desnivel_acumulado_positivo * esquemas[i].repeticiones;
        }
        return acumulado;
    }

    getDesnivelNegativo(sector) {
        const esquemas = sector.esquemas;
        let acumulado = 0;
        for(let i in esquemas) {
            acumulado += esquemas[i].trayecto.desnivel_acumulado_negativo * esquemas[i].repeticiones;
        }
        return acumulado;
    }

    getAlturaMaxima(sector) {
        return Math.max(...sector.esquemas.map(e => e.trayecto.altura_maxima));
    }

    getAlturaMinima(sector) {
        return Math.min(...sector.esquemas.map(e => e.trayecto.altura_minima));
    }

    getTrayectosSector(sector) {
        const {trayectos} = this.props;
        let trayectosSector = [];
        sector.esquemas.flat(1).forEach(item => {
            trayectosSector.push(trayectos.filter(t => t.id === item.trayecto.id)[0]);
        });
        return trayectosSector
    }

    onChangePublicado(e, sector) {
        const {setPerfilPublicado} = this.props;
        setPerfilPublicado(sector.id, e.target.checked);
    }

    render() {
        const {t, recorridoCreando} = this.props;
        return <Tab.Container
            defaultActiveKey={recorridoCreando.sectores[0] ? recorridoCreando.sectores[0].orden : null}>
            {recorridoCreando.sectores.length > 1 &&
                <Row className='modal-secuenciaSectores'>
                    <Nav variant="pills">
                        {recorridoCreando.sectores.map(sec =>
                            <Col key={sec.orden}>
                                <Nav.Item>
                                    <Nav.Link eventKey={sec.orden}>{sec.nombre}</Nav.Link>
                                </Nav.Item>
                            </Col>
                        )}
                    </Nav>
                </Row>
            }
            {recorridoCreando.sectores.map(sec => {
                const trayectos = this.getTrayectosSector(sec);

                return <Tab.Content key={sec.orden}>
                    <Tab.Pane eventKey={sec.orden}>
                        <Row>
                            <Col>
                                <Form.Row>
                                    <Form.Check type='checkbox' id={'publicacion-' + sec.orden}>
                                        <Form.Check.Input name='publicado' type='checkbox'
                                                          onChange={e => this.onChangePublicado(e, sec)}
                                                          checked={sec.perfil_publicado}/>
                                        <Form.Check.Label>{t('publicado')}</Form.Check.Label>
                                    </Form.Check>
                                </Form.Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faSortAmountUp}/>
                                <b>{' ' + t('desnivel-positivo')}</b>: {this.getDesnivelPositivo(sec).toFixed(2) + ' m'}
                            </Col>
                            <Col>
                                <FontAwesomeIcon icon={faSortAmountDown}/>
                                <b>{' ' + t('desnivel-negativo')}</b>: {this.getDesnivelNegativo(sec).toFixed(2) + ' m'}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FontAwesomeIcon icon={faArrowUp}/>
                                <b>{' ' + t('altura-maxima')}</b>: {this.getAlturaMaxima(sec).toFixed(2) + ' m'}
                            </Col>
                            <Col>
                                <FontAwesomeIcon icon={faArrowDown}/>
                                <b>{' ' + t('altura-minima')}</b>: {this.getAlturaMinima(sec).toFixed(2) + ' m'}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='contenedor-perfil_sizer'>
                                    <div className='contenedor-perfil' style={{height: '300px'}}>
                                        {trayectos.length > 0 && <GraficoPerfil
                                            sector={sec}
                                            trayectos={trayectos}
                                            puntosKm={recorridoCreando.puntos_km}
                                            handleClickPuntoKm={() => {
                                            }}
                                            containerWidth={500}
                                            containerHeight={400}
                                        />}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Tab.Pane>
                </Tab.Content>
            })}
        </Tab.Container>
    }
}


const mapStateToProps = state => ({
    recorridoCreando: getRecorridoCreando(state),
    trayectos: getTrayectos(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setPerfilPublicado: actionsRecoridos.setPerfilPublicado
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TabPerfilRecorrido));
