import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/servicios";

export function serviciosReducer(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_SERVICIOS_PENDIENTE:
            return {
                ...state,
                list: [],
                pendiente: true,
                error: false,
            };
        case ACTION_TYPES.FETCH_SERVICIOS_CORRECTO:
            return {
                ...state,
                list: action.servicios,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_SERVICIOS_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false,
            };
        default:
            return {
                ...state
            };
    }
}

export const getServicios = state => state.servicios.list;
export const getServiciosError = state => state.servicios.error;
export const getServiciosPendiente = state => state.servicios.pendiente;
