import React, { Component } from "react"
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'

import ModalEditor from "./ModalEditor"
import { actions } from "../../actions/editor"
import { deleteVoluntarios } from "../../fetchactions/voluntarios"


class ModalEliminarVoluntarios extends Component {
    handleClickAceptar () {
        const { dispatch, voluntariosEliminar } = this.props
        dispatch(deleteVoluntarios(voluntariosEliminar))
        this.hide()
        dispatch(actions.setVisibleModalVoluntarios(true))
    }

    handleClickCancelar () {
        this.hide()
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalEliminarVoluntarios(false))
    }

    componentWillUnmount() {
        const { dispatch } = this.props
        dispatch(actions.setVoluntariosEliminar(null))
    }

    render () {
        const { t, voluntariosEliminar } = this.props

        return(
            <ModalEditor
                footer
                show
                onClickAceptar={this.handleClickAceptar.bind(this)}
                onClickCancelar={this.handleClickCancelar.bind(this)}
                onHide={this.handleClickCancelar.bind(this)}
                titulo={t('Eliminar el mapa de personal') + ': ' + voluntariosEliminar.nombre}
            >
                <div className='row'>
                    <div className='col-12'>
                        <p><Trans>¿Está seguro de querer eliminar este mapa de personal?</Trans></p>
                    </div>
                </div>
            </ModalEditor>
        )
    }
}

const mapStateToProps = state => ({
    voluntariosEliminar: state.editor.voluntariosEliminar
})

export default withTranslation()(connect(mapStateToProps)(ModalEliminarVoluntarios))
