import React, {Component} from 'react';
import {Polygon} from "react-leaflet";
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {getDuplicando} from "../reducers/editor";
import {actualizaPoligono, eliminarPoligono} from "../fetchactions/poligonos";
import {actions} from "../actions/poligonos";
import {actions as actionsEditor} from "../actions/editor";
import {getPoligonoActualizando} from "../reducers/poligonos";


class NuevoPoligono extends Component {

    constructor(props) {
        super(props);
        this.poligonRef = React.createRef();
        this.type = 'npl';

        this.onDrawingEnd = this.onDrawingEnd.bind(this);
        this.onPressEsc = this.onPressEsc.bind(this);
    }

    componentDidMount() {
        const {duplicando, setDuplicando, resetNuevoPoligono, addPoligono, elemento, setObjetoSeleccionado,
        removeCapaColapsada} = this.props;
        removeCapaColapsada(elemento.bloque.capa);
        if(!duplicando) {
            const poligon = this.poligonRef.current.leafletElement;
            poligon.enableEdit().newShape();
            poligon.on('editable:drawing:end', this.onDrawingEnd);
            document.addEventListener('keyup', this.onPressEsc);
            setObjetoSeleccionado(this)
        } else {
            setDuplicando(false);
            resetNuevoPoligono();
            addPoligono(elemento)
        }
    }

    onDrawingEnd() {
        const {elemento, actualizaPoligono, poligonoActualizando, setVisibleModalPropAvanzada} = this.props;
        if(this.poligonRef.current) {
            const poligon = this.poligonRef.current.leafletElement;
            poligon.disableEdit();
            actualizaPoligono(elemento.id, {
                nombre: poligonoActualizando && poligonoActualizando.id === elemento.id ?
                    poligonoActualizando.nombre : elemento.nombre,
                bloque: elemento.bloque.id,
                coordenadas: poligon.toGeoJSON().geometry
            });
            setVisibleModalPropAvanzada(true);
            //resetNuevoPoligono();
        }
    }

    getUltimasCoords(){
        const poligon = this.poligonRef.current.leafletElement;
        return poligon.toGeoJSON().geometry;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {elemento} = this.props;
        const poligono = this.poligonRef.current.leafletElement;
        if(nextProps.poligonoActualizando && nextProps.poligonoActualizando.id === elemento.id) {
            poligono.setStyle({color: nextProps.poligonoActualizando.color});
        }
        return false;
    }

    onPressEsc(e) {
        if(e.keyCode === 27) {
            const {resetNuevoPoligono, elemento, eliminarPoligono} = this.props;
            eliminarPoligono(elemento);
            resetNuevoPoligono();
        }
    }

    componentWillUnmount() {
        const {setObjetoSeleccionado} = this.props;
        setObjetoSeleccionado(null);
        document.removeEventListener('keyup', this.onPressEsc);
    }

    render() {
        const {elemento} = this.props;
        return <Polygon ref={this.poligonRef} positions={[]} color={elemento.color} weight={elemento.grosor_linea}
                        fillOpacity={elemento.opacidad_fondo} dashArray={elemento.bloque.patron.patron}/>
    }
}

const mapStateToProps = state => ({
    duplicando: getDuplicando(state),
    poligonoActualizando: getPoligonoActualizando(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    actualizaPoligono: actualizaPoligono,
    resetNuevoPoligono: actions.resetNuevoPoligono,
    setDuplicando: actionsEditor.setDuplicando,
    addPoligono: actions.addPoligono,
    setObjetoSeleccionado: actionsEditor.objetoSeleccionado,
    removeCapaColapsada: actionsEditor.removeCapaColapsada,
    eliminarPoligono: eliminarPoligono,
    setVisibleModalPropAvanzada: actionsEditor.setVisibleModalPropAvanzadas
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NuevoPoligono));
