export const ACTION_TYPES = {
    FETCH_SECTOR_PENDIENTE: 'FETCH_SECTOR_PENDIENTE',
    FETCH_SECTOR_CORRECTO: 'FETCH_SECTOR_CORRECTO',
    FETCH_SECTOR_ERROR: 'FETCH_SECTOR_ERROR',
    ESQUEMA_ACTUALIZANDO_CORRECTO: 'ESQUEMA_ACTUALIZANDO_CORRECTO',
    ESQUEMA_ACTUALIZANDO_ERROR: 'ESQUEMA_ACTUALIZANDO_ERROR',
    ESQUEMA_ACTUALIZANDO_PENDIENTE: 'ESQUEMA_ACTUALIZANDO_PENDIENTE',
    ESQUEMA_ELIMINANDO_CORRECTO: 'ESQUEMA_ELIMINANDO_CORRECTO',
    ESQUEMA_ELIMINANDO_ERROR: 'ESQUEMA_ELIMINANDO_ERROR',
    ESQUEMA_ELIMINANDO_PENDIENTE: 'ESQUEMA_ELIMINANDO_PENDIENTE',
    NUEVO_SECTOR_CORRECTO: 'NUEVO_SECTOR_CORRECTO',
    NUEVO_SECTOR_ERROR: 'NUEVO_SECTOR_ERROR',
    NUEVO_SECTOR_PENDIENTE: 'NUEVO_SECTOR_PENDIENTE',
    NUEVO_TRAYECTO_ESQUEMA_CORRECTO: 'NUEVO_TRAYECTO_ESQUEMA_CORRECTO',
    NUEVO_TRAYECTO_ESQUEMA_PENDIENTE: 'NUEVO_TRAYECTO_ESQUEMA_PENDIENTE',
    NUEVO_TRAYECTO_ESQUEMA_ERROR: 'NUEVO_TRAYECTO_ESQUEMA_ERROR',
    SECTOR_ACTUALIZANDO_CORRECTO: 'SECTOR_ACTUALIZANDO_CORRECTO',
    SECTOR_ACTUALIZANDO_PENDIENTE: 'SECTOR_ACTUALIZANDO_PENDIENTE',
    SECTOR_ACTUALIZANDO_ERROR: 'SECTOR_ACTUALIZANDO_ERROR',
    SECTOR_ELIMINANDO_CORRECTO: 'SECTOR_ELIMINANDO_CORRECTO',
    SECTOR_ELIMINANDO_ERROR: 'SECTOR_ELIMINANDO_ERROR',
    SECTOR_ELIMINANDO_PENDIENTE: 'SECTOR_ELIMINANDO_PENDIENTE'
};

function fetchSectorPending() {
    return {
        type: ACTION_TYPES.FETCH_SECTOR_PENDIENTE
    }
}

function fetchSectorSuccess(sector) {
    return {
        type: ACTION_TYPES.FETCH_SECTOR_CORRECTO,
        sector: sector
    }
}

function fetchSectorError(error) {
    return {
        type: ACTION_TYPES.FETCH_SECTOR_ERROR,
        error: error
    }
}

function nuevoSectorCorrecto(sector) {
    return {
        type: ACTION_TYPES.NUEVO_SECTOR_CORRECTO,
        sector: sector
    };
}

function nuevoSectorPendiente() {
    return {
        type: ACTION_TYPES.NUEVO_SECTOR_PENDIENTE
    };
}

function nuevoSectorError(err) {
    return {
        type: ACTION_TYPES.NUEVO_SECTOR_ERROR,
        error: err
    };
}

function nuevoTrayectoEsquemaCorrecto(esquema, recorridoId) {
    return {
        type: ACTION_TYPES.NUEVO_TRAYECTO_ESQUEMA_CORRECTO,
        esquema: esquema,
        recorridoId: recorridoId
    };
}

function nuevoTrayectoEsquemaPendiente() {
    return {
        type: ACTION_TYPES.NUEVO_TRAYECTO_ESQUEMA_PENDIENTE,
    };
}

function nuevoTrayectoEsquemaError(err) {
    return{
        type: ACTION_TYPES.NUEVO_TRAYECTO_ESQUEMA_ERROR,
        error: err
    };
}

function sectorActualizandoCorrecto(sector, idRecorrido) {
    return {
        type: ACTION_TYPES.SECTOR_ACTUALIZANDO_CORRECTO,
        sector: sector,
        idRecorrido: idRecorrido
    };
}

function sectorActualizandoError(err) {
    return {
        type: ACTION_TYPES.SECTOR_ACTUALIZANDO_ERROR,
        error: err
    };
}

function sectorActualizandoPendiente() {
    return {
        type: ACTION_TYPES.SECTOR_ACTUALIZANDO_PENDIENTE
    };
}

function sectorEliminandoCorrecto(sector, recorridoId) {
    return {
        type: ACTION_TYPES.SECTOR_ELIMINANDO_CORRECTO,
        sector: sector,
        recorridoId: recorridoId
    };
}

function sectorEliminandoPendiente(sector) {
    return {
        type: ACTION_TYPES.SECTOR_ELIMINANDO_PENDIENTE,
        sector: sector
    };
}

function sectorEliminandoError(err) {
    return {
        type: ACTION_TYPES.SECTOR_ELIMINANDO_ERROR,
        error: err
    };
}

function esquemaEliminandoCorrecto(esquema, recorridoId) {
    return {
        type: ACTION_TYPES.ESQUEMA_ELIMINANDO_CORRECTO,
        esquema: esquema,
        recorridoId: recorridoId
    };
}

function esquemaEliminandoError(err) {
    return {
        type: ACTION_TYPES.ESQUEMA_ELIMINANDO_ERROR,
        error: err
    };
}

function esquemaEliminadoPendiente(esquema) {
    return {
        type: ACTION_TYPES.ESQUEMA_ELIMINANDO_PENDIENTE,
        equema: esquema
    };
}

function esquemaActualizandoCorrecto(esquema, recorridoId) {
    return {
        type: ACTION_TYPES.ESQUEMA_ACTUALIZANDO_CORRECTO,
        esquema: esquema,
        recorridoId: recorridoId
    };
}

function esquemaActualizandoPendiente() {
    return {
        type: ACTION_TYPES.ESQUEMA_ACTUALIZANDO_PENDIENTE
    };
}

function esquemaActualizandoError(err) {
    return {
        type: ACTION_TYPES.ESQUEMA_ACTUALIZANDO_ERROR,
        error: err
    };
}


export const actions = {
    fetchSectorPending: fetchSectorPending,
    fetchSectorSuccess: fetchSectorSuccess,
    fetchSectorError: fetchSectorError,
    nuevoSectorCorrecto: nuevoSectorCorrecto,
    nuevoSectorError: nuevoSectorError,
    nuevoSectorPendiente: nuevoSectorPendiente,
    nuevoTrayectoEsquemaCorrecto: nuevoTrayectoEsquemaCorrecto,
    nuevoTrayectoEsquemaPendiente: nuevoTrayectoEsquemaPendiente,
    nuevoTrayectoEsquemaError: nuevoTrayectoEsquemaError,
    sectorActualizandoCorrecto: sectorActualizandoCorrecto,
    sectorActualizandoError: sectorActualizandoError,
    sectorActualizandoPendiente: sectorActualizandoPendiente,
    sectorEliminandoCorrecto: sectorEliminandoCorrecto,
    sectorEliminandoError: sectorEliminandoError,
    sectorEliminandoPendiente: sectorEliminandoPendiente,
    esquemaEliminandoCorrecto: esquemaEliminandoCorrecto,
    esquemaEliminadoPendiente: esquemaEliminadoPendiente,
    esquemaEliminandoError: esquemaEliminandoError,
    esquemaActualizandoCorrecto: esquemaActualizandoCorrecto,
    esquemaActualizandoPendiente: esquemaActualizandoPendiente,
    esquemaActualizandoError: esquemaActualizandoError
};
