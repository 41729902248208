export const ACTION_TYPES = {
    ACTUALIZA_COORDS_PUNTO: 'ACTUALIZA_COORDS_PUNTO',
    ADD_PUNTO: 'ADD_PUNTO',
    FETCH_PUNTOS_CORRECTO: 'FETCH_PUNTOS_CORRECTO',
    FETCH_PUNTOS_ERROR: 'FETCH_PUNTOS_ERROR',
    FETCH_PUNTOS_PENDIENTE: 'FETCH_PUNTOS_PENDIENTE',
    PUNTO_NUEVO_ELEMENTO: 'PUNTO_NUEVO_ELEMENTO',
    PUNTO_NUEVO_ERROR: 'PUNTO_NUEVO_ERROR',
    PUNTO_NUEVO_PENDIENTE: 'PUNTO_NUEVO_PENDIENTE',
    PUNTO_ACTUALIZANDO_CORRECTO: 'PUNTO_ACTUALIZANDO_CORRECTO',
    PUNTO_ACTUALIZANDO_ERROR: 'PUNTO_ACTUALIZANDO_ERROR',
    PUNTO_ACTUALIZANDO_PENDIENTE: 'PUNTO_ACTUALIZANDO_PENDIENTE',
    PUNTO_ELIMINADO_CORRECTO: 'PUNTO_ELIMINADO_CORRECTO',
    PUNTO_ELIMINADO_ERROR: 'PUNTO_ELIMINADO_ERROR',
    PUNTO_ELIMINADO_PENDIENTE: 'PUNTO_ELIMINADO_PENDIENTE',
    REMOVE_PUNTO: 'REMOVE_PUNTO',
    RESET_NUEVO_PUNTO: 'RESET_NUEVO_PUNTO',
    SET_COLOR_PUNTO: 'SET_COLOR_PUNTO',
    SET_COLOR_ICONO_PUNTO: 'SET_COLOR_ICONO_PUNTO',
    SET_NOMBRE_PUNTO: 'SET_NOMBRE_PUNTO'
};

function fetchPuntosCorrecto(puntos) {
    return {
        type: ACTION_TYPES.FETCH_PUNTOS_CORRECTO,
        puntos: puntos
    };
}

function fetchPuntosError(error) {
    return {
        type: ACTION_TYPES.FETCH_PUNTOS_ERROR,
        error: error
    };
}

function fetchPuntosPendiente() {
    return {
        type: ACTION_TYPES.FETCH_PUNTOS_PENDIENTE
    };
}

function puntoNuevoElemento(elemento) {
    return {
        type: ACTION_TYPES.PUNTO_NUEVO_ELEMENTO,
        elemento: elemento
    };
}

function puntoNuevoError(err) {
    return {
        type: ACTION_TYPES.PUNTO_NUEVO_ERROR,
        error: err
    };
}

function puntoNuevoPendiente() {
    return {
        type: ACTION_TYPES.PUNTO_NUEVO_PENDIENTE
    };
}

function resetNuevoPunto() {
    return {
        type: ACTION_TYPES.RESET_NUEVO_PUNTO
    };
}

function puntoActualizandoCorrecto(punto) {
    return {
        type: ACTION_TYPES.PUNTO_ACTUALIZANDO_CORRECTO,
        punto: punto
    };
}

function puntoActualizandoError(err) {
    return {
        type: ACTION_TYPES.PUNTO_ACTUALIZANDO_ERROR,
        error: err
    };
}

function puntoActualizadoPendiente() {
    return {
        type: ACTION_TYPES.PUNTO_ACTUALIZANDO_PENDIENTE
    };
}

function actualizaCoordsPunto(idPunto, coords) {
    return {
        type: ACTION_TYPES.ACTUALIZA_COORDS_PUNTO,
        idPunto: idPunto,
        coordenadas: coords
    };
}

function puntoEliminadoCorrecto(punto) {
    return {
        type: ACTION_TYPES.PUNTO_ELIMINADO_CORRECTO,
        punto: punto
    };
}

function puntoEliminadoError(err) {
    return {
        type: ACTION_TYPES.PUNTO_ELIMINADO_ERROR,
        error: err
    };
}

function puntoEliminadoPendiente(punto) {
    return {
        type: ACTION_TYPES.PUNTO_ELIMINADO_PENDIENTE,
        punto: punto
    };
}

function addPunto(punto) {
    return {
        type: ACTION_TYPES.ADD_PUNTO,
        punto: punto
    };
}

function removePunto(punto) {
    return {
        type: ACTION_TYPES.REMOVE_PUNTO,
        punto: punto
    }
}

function setColorPunto(color, punto) {
    return {
        type: ACTION_TYPES.SET_COLOR_PUNTO,
        color: color,
        punto: punto
    };
}

function setColorIconoPunto(colorIcono, punto) {
    return {
        type: ACTION_TYPES.SET_COLOR_ICONO_PUNTO,
        colorIcono: colorIcono,
        punto: punto
    }
}

function setNombrePunto(nombre, punto) {
    return {
        type: ACTION_TYPES.SET_NOMBRE_PUNTO,
        nombre: nombre,
        punto: punto
    }
}

export const actions = {
    fetchPuntosCorrecto: fetchPuntosCorrecto,
    fetchPuntosError: fetchPuntosError,
    fetchPuntosPendiente: fetchPuntosPendiente,
    puntoNuevoElemento: puntoNuevoElemento,
    puntoNuevoError: puntoNuevoError,
    puntoNuevoPendiente: puntoNuevoPendiente,
    resetNuevoPunto: resetNuevoPunto,
    puntoActualizandoCorrecto: puntoActualizandoCorrecto,
    puntoActualizandoError: puntoActualizandoError,
    puntoActualizadoPendiente: puntoActualizadoPendiente,
    actualizaCoordsPunto: actualizaCoordsPunto,
    puntoEliminadoCorrecto: puntoEliminadoCorrecto,
    puntoEliminadoError: puntoEliminadoError,
    puntoEliminadoPendiente: puntoEliminadoPendiente,
    addPunto: addPunto,
    removePunto: removePunto,
    setColorPunto: setColorPunto,
    setColorIconoPunto: setColorIconoPunto,
    setNombrePunto: setNombrePunto
};
