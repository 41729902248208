import React, {Component} from 'react';
import {InputGroup} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import './InputColor.css';
import {actualizaTrayecto} from "../../fetchactions/trayectos";
import {actualizaPunto} from "../../fetchactions/puntos";
import {getTrayectoActualizando} from "../../reducers/trayectos";
import {getPuntoActualizando} from "../../reducers/puntos";
import {actualizaLinea} from "../../fetchactions/lineas";
import {actualizaPoligono} from "../../fetchactions/poligonos";
import {getLineaActualizando} from "../../reducers/lineas";
import {getPoligonoActualizando} from "../../reducers/poligonos";
import {actions as actionsTr} from "../../actions/trayectos";
import {actions as actionsLn} from "../../actions/lineas";
import {actions as actionsPt} from "../../actions/puntos";
import {actions as actionsPl} from "../../actions/poligonos";


class InputColor extends Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();

        this.elementoActualizado = null;
        this.onFocus = this.onFocus.bind(this);
        this.onChange = this.onChange.bind(this);
        this.cambiado = false;
        this.tog = false;
    }

    componentDidMount() {
        let input = this.inputRef.current;
        input.addEventListener('blur', this.onFocus);
        this.cambiado = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {elemento, propiedad, trayectoActualizado, puntoActualizando, poligonoActualizando,
            lineaActualizando} = this.props;
        const esTrayectoActualizado = (!prevProps.trayectoActualizado && trayectoActualizado && trayectoActualizado.id === elemento.id);
        const esPuntoActualizando = (!prevProps.puntoActualizando && puntoActualizando && puntoActualizando.id === elemento.id);
        const esPoligonoActualizando = (!prevProps.poligonoActualizando && poligonoActualizando && poligonoActualizando.id === elemento.id);
        const esLineaActualizada = (!prevProps.lineaActualizando && lineaActualizando && lineaActualizando.id === elemento.id);
        if(prevProps.elemento.id !== elemento.id || esTrayectoActualizado || esPuntoActualizando || esPoligonoActualizando || esLineaActualizada) {
            const value = esTrayectoActualizado ? trayectoActualizado[propiedad ? propiedad : 'color'] : (esPuntoActualizando ?
                puntoActualizando[propiedad ? propiedad : 'color'] : (esPoligonoActualizando ?
                    poligonoActualizando[propiedad ? propiedad : 'color'] : (esLineaActualizada ?
                        lineaActualizando[propiedad ? propiedad : 'color'] : elemento[propiedad ? propiedad : 'color'])))
            let input = this.inputRef.current;
            input.value = value;
        }
        this.cambiado = false;
    }

    onFocus(e) {
        if(this.cambiado)
            this.guardarCambios(e);
    }

    onChange(e) {
        const {setColorTrayecto, setColorLinea, setColorPoligono, setColorPunto, setColorIconoPunto, propiedad,
            elemento} = this.props;
        switch (elemento.bloque.tipo) {
            case 'tr':
                setColorTrayecto(e.target.value, elemento);
                break;
            case 'ln':
                setColorLinea(e.target.value, elemento);
                break;
            case 'pl':
                setColorPoligono(e.target.value, elemento);
                break;
            case 'pt':
                if(propiedad === 'color_icono') setColorIconoPunto(e.target.value, elemento);
                else setColorPunto(e.target.value, elemento);
                break;
            default:
                break;
        }
        this.cambiado = true;
    }

    guardarCambios(e) {
        const {elemento, actualizaTrayecto, objeto, actualizaPunto, propiedad, actualizaLinea,
            actualizaPoligono} = this.props;
        let elementoAct = this.elementoActualizado ? this.elementoActualizado: elemento;
        let opciones = {
            nombre: elementoAct.nombre,
            bloque: Number.isInteger(elemento.bloque) ? elemento.bloque : elemento.bloque.id,
            color: propiedad ? elementoAct.color: e.target.value
        };
        if(objeto.getUltimasCoords) opciones['coordenadas'] = objeto.getUltimasCoords();
        if(propiedad) opciones[propiedad] = e.target.value;
        switch(elemento.bloque.tipo) {
            case 'tr':
                actualizaTrayecto(elemento.id, opciones);
                break;
            case 'pt':
                opciones['icono'] = elemento.icono.id;
                actualizaPunto(elemento.id, opciones);
                break;
            case 'ln':
                actualizaLinea(elemento.id, opciones);
                break;
            case 'pl':
                if(opciones.coordenadas.coordinates[0].length < 4)
                    opciones.coordenadas = {"type": "Polygon", "coordinates": [[]]};
                actualizaPoligono(elemento.id, opciones);
                break;
            default:
                break;
        }
        this.cambiado = false;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {elemento} = this.props;
        if(nextProps.trayectoActualizado && elemento.id === nextProps.trayectoActualizado.id){
            this.elementoActualizado = nextProps.trayectoActualizado;
            //return false;
        } else if(nextProps.puntoActualizando && elemento.id === nextProps.puntoActualizando.id) {
            this.elementoActualizado = nextProps.puntoActualizando;
            //return false;
        } else if(nextProps.lineaActualizando && elemento.id === nextProps.lineaActualizando.id){
            this.elementoActualizado = nextProps.lineaActualizando;
            //return false;
        } else if(nextProps.poligonoActualizando && elemento.id === nextProps.poligonoActualizando.id) {
            this.elementoActualizado = nextProps.poligonoActualizando;
        }
        return true;
    }

    render() {
        const {t, elemento, propiedad, xl} = this.props;
        return <InputGroup className={"input-group-propiedad input-group-propiedad-color" +
            (xl ? " input-group-propiedad-color-xl" : "")}>
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3">{(propiedad ? t(propiedad) : t('color')) + ':'}</InputGroup.Text>
            </InputGroup.Prepend>
                <input ref={this.inputRef} className='input-color-muestra' onChange={this.onChange} type='color'
                       defaultValue={propiedad ? elemento[propiedad] : elemento.color}/>
        </InputGroup>
    }
}

const mapStateToProps = state => ({
    trayectoActualizado: getTrayectoActualizando(state),
    puntoActualizando: getPuntoActualizando(state),
    lineaActualizando: getLineaActualizando(state),
    poligonoActualizando: getPoligonoActualizando(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    actualizaTrayecto: actualizaTrayecto,
    actualizaPunto: actualizaPunto,
    actualizaLinea: actualizaLinea,
    actualizaPoligono: actualizaPoligono,
    setColorTrayecto: actionsTr.setColorTrayecto,
    setColorPunto: actionsPt.setColorPunto,
    setColorIconoPunto: actionsPt.setColorIconoPunto,
    setColorLinea: actionsLn.setColorLinea,
    setColorPoligono: actionsPl.setColorPoligono
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InputColor));
