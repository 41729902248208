export const ACTION_TYPES = {
    MODAL_ESQUEMA_MOSTRAR: 'MODAL_ESQUEMA_MOSTRAR',
    MODAL_ESQUEMA_OCULTAR: 'MODAL_ESQUEMA_OCULTAR',
    MODAL_PDIS_MOSTRAR: 'MODAL_PDIS_MOSTRAR',
    MODAL_PDIS_OCULTAR: 'MODAL_PDIS_OCULTAR',
    MODAL_PDIS_PROYECTO_MOSTRAR: 'MODAL_PDIS_PROYECTO_MOSTRAR',
    MODAL_PDIS_PROYECTO_OCULTAR: 'MODAL_PDIS_PROYECTO_OCULTAR',
    MODAL_PDI_SELECCIONADO_MOSTRAR: 'MODAL_PDI_SELECCIONADO_MOSTRAR',
    MODAL_PDI_SELECCIONADO_OCULTAR: 'MODAL_PDI_SELECCIONADO_OCULTAR',
    MODAL_CAMBIAR_VISTA_MOSTRAR: 'MODAL_CAMBIAR_VISTA_MOSTRAR',
    MODAL_CAMBIAR_VISTA_OCULTAR: 'MODAL_CAMBIAR_VISTA_OCULTAR',
    MODAL_PERFILES_MOSTRAR: 'MODAL_PERFILES_MOSTRAR',
    MODAL_PERFILES_OCULTAR: 'MODAL_PERFILES_OCULTAR',
    SUBMENU_HERRAMIENTAS_MOSTRAR: 'SUBMENU_HERRAMIENTAS_MOSTRAR',
    SUBMENU_HERRAMIENTAS_OCULTAR: 'SUBMENU_HERRAMIENTAS_OCULTAR',
    SUBMENU_PDIS_MOSTRAR: 'SUBMENU_PDIS_MOSTRAR',
    SUBMENU_PDIS_OCULTAR: 'SUBMENU_PDIS_OCULTAR',
    SET_MAP_BOUNDS: 'SET_MAP_BOUNDS',
    MAPA_PDIS_PROYECTO_MOSTRAR: 'MAPA_PDIS_PROYECTO_MOSTRAR',
    MAPA_PDIS_PROYECTO_OCULTAR: 'MAPA_PDIS_PROYECTO_OCULTAR',
    MAPA_PDIS_RECORRIDO_MOSTRAR: 'MAPA_PDIS_RECORRIDO_MOSTRAR',
    MAPA_PDIS_RECORRIDO_OCULTAR: 'MAPA_PDIS_RECORRIDO_OCULTAR',
    COMPARTIR_MOSTRAR: 'COMPARTIR_MOSTRAR',
    COMPARTIR_OCULTAR: 'COMPARTIR_OCULTAR',
    COMPARTIR_INSERTAR_TOGGLE: 'COMPARTIR_INSERTAR_TOGGLE',
    SELECCIONAR_CAPA_FONDO: 'SELECCIONAR_CAPA_FONDO',
    SELECCIONAR_PUNTO_KM: 'SELECCIONAR_PUNTO_KM',
    SET_GRAFICO_DOMAIN: 'SET_GRAFICO_DOMAIN',
    SET_GRAFICO_ZOOM_DOMAIN: 'SET_GRAFICO_ZOOM_DOMAIN'
}

function modalEsquemaMostrar () {

    return {
        type: ACTION_TYPES.MODAL_ESQUEMA_MOSTRAR
    }
}

function modalEsquemaOcultar () {

    return {
        type: ACTION_TYPES.MODAL_ESQUEMA_OCULTAR
    }
}

function modalPdisMostrar () {
    return {
        type: ACTION_TYPES.MODAL_PDIS_MOSTRAR
    }
}

function modalPdisOcultar () {
    return {
        type: ACTION_TYPES.MODAL_PDIS_OCULTAR
    }
}

function modalPdisProyectoMostrar () {
    return {
        type: ACTION_TYPES.MODAL_PDIS_PROYECTO_MOSTRAR
    }
}

function modalPdisProyectoOcultar () {
    return {
        type: ACTION_TYPES.MODAL_PDIS_PROYECTO_OCULTAR
    }
}

function modalPdiMostrar (pdi) {
    return {
        type: ACTION_TYPES.MODAL_PDI_SELECCIONADO_MOSTRAR,
        modalPdiSeleccionado: pdi
    }
}

function modalPdiOcultar () {
    return {
        type: ACTION_TYPES.MODAL_PDI_SELECCIONADO_OCULTAR,
        modalPdiSeleccionado: null
    }
}

function submenuHerramientasMostrar () {
    return {
        type: ACTION_TYPES.SUBMENU_HERRAMIENTAS_MOSTRAR
    }
}

function submenuHerramientasOcultar () {
    return {
        type: ACTION_TYPES.SUBMENU_HERRAMIENTAS_OCULTAR
    }
}

function submenuPdisMostrar () {
    return {
        type: ACTION_TYPES.SUBMENU_PDIS_MOSTRAR
    }
}

function submenuPdisOcultar () {
    return {
        type: ACTION_TYPES.SUBMENU_PDIS_OCULTAR
    }
}

function setMapBounds (bounds) {
    return {
        type: ACTION_TYPES.SET_MAP_BOUNDS,
        bounds: bounds
    }
}

function mapaPdisProyectoMostrar () {
    return {
        type: ACTION_TYPES.MAPA_PDIS_PROYECTO_MOSTRAR
    }

}

function mapaPdisProyectoOcultar () {
    return {
        type: ACTION_TYPES.MAPA_PDIS_PROYECTO_OCULTAR
    }

}

function mapaPdisRecorridoMostrar () {
    return {
        type: ACTION_TYPES.MAPA_PDIS_RECORRIDO_MOSTRAR
    }

}

function mapaPdisRecorridoOcultar () {
    return {
        type: ACTION_TYPES.MAPA_PDIS_RECORRIDO_OCULTAR
    }

}

function compartirMostrar () {
    return {
        type: ACTION_TYPES.COMPARTIR_MOSTRAR
    }
}


function compartirOcultar () {
    return {
        type: ACTION_TYPES.COMPARTIR_OCULTAR
    }
}

function compartirInsertarToggle() {
    return {
        type: ACTION_TYPES.COMPARTIR_INSERTAR_TOGGLE
    }
}


function modalCambiarVistaMostrar () {
    return {
        type: ACTION_TYPES.MODAL_CAMBIAR_VISTA_MOSTRAR
    }
}


function modalCambiarVistaOcultar () {
    return {
        type: ACTION_TYPES.MODAL_CAMBIAR_VISTA_OCULTAR
    }
}


function seleccionarCapaFondo (capa) {
    return {
        type: ACTION_TYPES.SELECCIONAR_CAPA_FONDO,
        capa
    }
}


function seleccionarPuntoKm (punto) {
    return {
        type: ACTION_TYPES.SELECCIONAR_PUNTO_KM,
        punto
    }
}


function modalPerfilesMostrar () {
    return {
        type: ACTION_TYPES.MODAL_PERFILES_MOSTRAR
    }
}

function modalPerfilesOcultar () {
    return {
        type: ACTION_TYPES.MODAL_PERFILES_OCULTAR
    }
}


function setGraficoDomain (domain) {
    return {
        type: ACTION_TYPES.SET_GRAFICO_DOMAIN,
        domain
    }
}



function setGraficoZoomDomain (domain) {
    return {
        type: ACTION_TYPES.SET_GRAFICO_ZOOM_DOMAIN,
        domain
    }
}

export const actions = {
    modalEsquemaOcultar: modalEsquemaOcultar,
    modalEsquemaMostrar: modalEsquemaMostrar,
    modalPdisMostrar: modalPdisMostrar,
    modalPdisOcultar: modalPdisOcultar,
    modalPdisProyectoMostrar: modalPdisProyectoMostrar,
    modalPdisProyectoOcultar: modalPdisProyectoOcultar,
    modalPdiMostrar: modalPdiMostrar,
    modalPdiOcultar: modalPdiOcultar,
    submenuHerramientasMostrar: submenuHerramientasMostrar,
    submenuHerramientasOcultar: submenuHerramientasOcultar,
    submenuPdisMostrar: submenuPdisMostrar,
    submenuPdisOcultar: submenuPdisOcultar,
    setMapBounds: setMapBounds,
    mapaPdisProyectoMostrar: mapaPdisProyectoMostrar,
    mapaPdisProyectoOcultar: mapaPdisProyectoOcultar,
    mapaPdisRecorridoMostrar: mapaPdisRecorridoMostrar,
    mapaPdisRecorridoOcultar: mapaPdisRecorridoOcultar,
    compartirMostrar:compartirMostrar,
    compartirOcultar: compartirOcultar,
    modalCambiarVistaMostrar: modalCambiarVistaMostrar,
    modalCambiarVistaOcultar: modalCambiarVistaOcultar,
    seleccionarCapaFondo: seleccionarCapaFondo,
    modalPerfilesMostrar: modalPerfilesMostrar,
    modalPerfilesOcultar: modalPerfilesOcultar,
    seleccionarPuntoKm: seleccionarPuntoKm,
    setGraficoDomain: setGraficoDomain,
    setGraficoZoomDomain: setGraficoZoomDomain,
    compartirInsertarToggle: compartirInsertarToggle
}

export default actions
