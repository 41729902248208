import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {InputGroup} from "react-bootstrap";
import * as turf from '@turf/turf';
import {getAreaDisplay} from "../../reducers/editor";
import {bindActionCreators} from "redux";
import {actions} from "../../actions/editor";
import {connect} from "react-redux";


class DisplayArea extends Component{

    constructor(props) {
        super(props);
        this.onLineDrag = this.onLineDrag.bind(this);
    }

    componentDidMount() {
        const {objeto, setAreaDisplay} = this.props;
        if(objeto.props.map && objeto.props.map.current) {
            const map = objeto.props.map.current.leafletElement;
            map.on('editable:vertex:drag', this.onLineDrag);
            map.on('editable:vertex:new', this.onLineDrag);
            const elemento = objeto.props.elemento;
            setAreaDisplay(turf.area(elemento.coordenadas));
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {objeto, setAreaDisplay} = this.props;
        if(objeto.props.elemento.id !== prevProps.objeto.props.elemento.id) {
            setAreaDisplay(turf.area(objeto.props.elemento.coordenadas));
        }
    }

    onLineDrag(e) {
        const {setAreaDisplay} = this.props;
        setAreaDisplay(turf.area(e.layer.toGeoJSON().geometry));
    }

    getArea(){
        const {area} = this.props;
        return area.toFixed(2) + ' m²';
    }

    componentWillUnmount() {
        const {objeto, setAreaDisplay} = this.props;
        if(objeto.props.map && objeto.props.map.current) {
            const map = objeto.props.map.current.leafletElement;
            map.off('editable:vertex:drag');
            map.off('editable:vertex:new');
        }
        setAreaDisplay(0)
    }

    render() {
        const {t} = this.props;
        return <InputGroup className="input-group-propiedad input-group-propiedad-distancia-real">
            <InputGroup.Prepend>
                <InputGroup.Text>{t('area') + ':'}</InputGroup.Text>
            </InputGroup.Prepend>
            <InputGroup.Text><b>{this.getArea()}</b></InputGroup.Text>
        </InputGroup>
    }
}

const mapStateToProps = state => ({
   area: getAreaDisplay(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setAreaDisplay: actions.setAreaDisplay
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DisplayArea));