import { initialState } from "../constants/state"
import { ACTION_TYPES } from "../actions/contactos"

export default function contactosReducer (state = initialState.contactos, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_CONTACTOS_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: [],
                error: null
            }

        case ACTION_TYPES.FETCH_CONTACTOS_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.contactos
            }

        case ACTION_TYPES.FETCH_CONTACTOS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}
