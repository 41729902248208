import {Spinner} from "react-bootstrap";
import React from "react";

import './SpinEsperaRespuesta.css';

export default function SpinEsperaRespuesta(props) {
    if(props.show) {
        return <div className='contenedor-spinner-respuesta-servidor'>
            <Spinner className='spinner-respuesta-servidor' animation="border" role="status">
                <span className="sr-only">Cargando...</span>
            </Spinner>
        </div>
    } else {
        return null;
    }
}
