
import {ACTION_TYPES} from "../actions/error";

export default function errorReducer(state = {}, action) {
    switch (action.type) {
        case ACTION_TYPES.HAS_ERROR:
            return action.error

        case ACTION_TYPES.CLEAR_ERROR:
            return action.error

        default:
            return state
    }

}

export const getError = state => state.error;
