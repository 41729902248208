import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Row, Col} from "react-bootstrap";
import * as turf from '@turf/turf';

import ModalEditor from "./ModalEditor";
import {getBloqueSeleccionado, getFormasFicheroSubido} from "../../reducers/editor";
import {actions} from "../../actions/editor";
import {getProyecto} from "../../reducers/proyectos";
import {creaNuevoTrayecto} from "../../fetchactions/trayectos";
import {creaNuevaLinea} from "../../fetchactions/lineas";
import {getTrayectos} from "../../reducers/trayectos";
import {getLineas} from "../../reducers/lineas";
import {getPoligonos} from "../../reducers/poligonos";
import {creaNuevoPoligono} from "../../fetchactions/poligonos";

import './ModalSeleccionarForma.css';
import {getPuntos} from "../../reducers/puntos";


class ModalSeleccionarForma extends Component {
    constructor(props) {
        super(props);

        this.onHide = this.onHide.bind(this);
    }

    onHide() {
        this.hide();
    }

    hide() {
        const {setVisibleModalSeleccionarForma} = this.props;
        setVisibleModalSeleccionarForma(false);
    }

    getMedida(forma) {
        const {bloqueSeleccionado} = this.props;
        if(bloqueSeleccionado.tipo === 'pl') {
            return turf.area(forma.geometry)
        } else {
            return turf.length(forma.geometry, {units: 'meters'});
        }
    }

    onClickForma(forma) {
        const {bloqueSeleccionado, proyecto, creaNuevoTrayecto, creaNuevaLinea, creaNuevoPoligono} = this.props;
        const propsDefecto = bloqueSeleccionado.props_defecto;
        const nombre = forma.properties.name ?
            (forma.properties.name.substring(0, forma.properties.name.length > 50 ? 49 : forma.properties.name.length)) :
            this.getNombreSiguienteElemento(bloqueSeleccionado);
        switch (bloqueSeleccionado.tipo) {
            case 'tr':
                creaNuevoTrayecto({
                    nombre:nombre,
                    color: forma.properties.stroke ? forma.properties.stroke : propsDefecto.color,
                    grosor: propsDefecto.grosor,
                    bloque: bloqueSeleccionado.id,
                    coordenadas: this.featureTo2d(forma).geometry,
                    proyecto: proyecto.id
                }, true);
                this.hide();
                break;
            case 'ln':
                creaNuevaLinea({
                    nombre: nombre,
                    color: bloqueSeleccionado.color_editable ?
                        (forma.properties.stroke ? forma.properties.stroke : propsDefecto.color) :
                        propsDefecto.color,
                    grosor: propsDefecto.grosor ? propsDefecto.grosor : 3,
                    bloque: bloqueSeleccionado.id,
                    patron: bloqueSeleccionado.patron.id,
                    opacidad: propsDefecto.opacidad ? propsDefecto.opacidad : 1,
                    coordenadas: this.featureTo2d(forma).geometry,
                    proyecto: proyecto.id
                }, true);
                this.hide();
                break;
            case 'pl':
                creaNuevoPoligono({
                    nombre: nombre,
                    color: bloqueSeleccionado.color_editable ?
                        (forma.properties.fill ? forma.properties.fill : propsDefecto.color) :
                        propsDefecto.color,
                    grosor_linea: propsDefecto.grosor_linea ? propsDefecto.grosor_linea : 3,
                    bloque: bloqueSeleccionado.id,
                    patron: bloqueSeleccionado.patron.id,
                    opacidad_fondo: propsDefecto.opacidad_fondo ? propsDefecto.opacidad_fondo: 1,
                    coordenadas: this.featureTo2dPolygon(forma).geometry,
                    proyecto: proyecto.id
                }, true);
                this.hide();
                break;
            default:
                this.hide();
        }
    }

    getNombreSiguienteElemento(bloqueSeleccionado) {
        const { trayectos, lineas, poligonos, puntos } = this.props;
        const elementos = trayectos.concat(puntos, lineas, poligonos);
        let nombre;
        let contador = 1;
        while (!nombre) {
            const cont = contador;
            if (elementos.map(el => el.nombre).indexOf(bloqueSeleccionado.nombre + cont) < 0) {
                nombre = bloqueSeleccionado.nombre + cont;
            }
            contador++;
        }
        return nombre;
    }

    featureTo2d(feature){
        for(let i in feature.geometry.coordinates){
            if(feature.geometry.coordinates[i].length > 2)
                feature.geometry.coordinates[i] = feature.geometry.coordinates[i].slice(0,2);
        }
        return feature;
    }

    featureTo2dPolygon(feature) {
        for(let i in feature.geometry.coordinates){
            for(let j in feature.geometry.coordinates[i]) {
                if(feature.geometry.coordinates[i][j].length > 2)
                    feature.geometry.coordinates[i][j] = feature.geometry.coordinates[i][j].slice(0,2);
            }
        }
        return feature;
    }

    render() {
        const {t, show, formasFicheroSubido, bloqueSeleccionado} = this.props;
        let contador = 0;
        if(bloqueSeleccionado) {
            return <ModalEditor show={show} onHide={this.onHide}>
                <Row>
                    <Col sm={12}>{t('msg-seleccionar-forma')} <b>{bloqueSeleccionado.nombre} :</b></Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <ul className='lista-formas'>
                            {formasFicheroSubido.map(f => {
                                contador ++;
                                return <li onClick={e => this.onClickForma(f)} key={contador}>{f.properties.name}
                                    <span className='medida'>{this.getMedida(f).toFixed(0)} m
                                        {bloqueSeleccionado.tipo === 'pl' ? '²' : ''}
                                        </span>
                                </li>
                            })}
                        </ul>
                    </Col>
                </Row>
            </ModalEditor>
        } else
            return null;

    }
}

const mapStateToProps = state => ({
    formasFicheroSubido: getFormasFicheroSubido(state),
    bloqueSeleccionado: getBloqueSeleccionado(state),
    proyecto: getProyecto(state),
    trayectos: getTrayectos(state),
    lineas: getLineas(state),
    poligonos: getPoligonos(state),
    puntos: getPuntos(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalSeleccionarForma: actions.setVisibleModalSeleccionarForma,
    creaNuevoTrayecto: creaNuevoTrayecto,
    creaNuevaLinea: creaNuevaLinea,
    creaNuevoPoligono: creaNuevoPoligono
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalSeleccionarForma))
