import React, {Component} from 'react';
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {getObjetoSeleccionado} from "../reducers/editor";
import BtnBarraHerramientas from "./BtnBarraHerramientas";

class BtnDuplicarElemento extends Component{
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const {objetoSeleccionado} = this.props;
        objetoSeleccionado.duplicar();
    }

    render() {
        const {t} = this.props;
        return <BtnBarraHerramientas onClick={this.onClick} className={this.props.className} icon={faCopy}
                                     titulo={t('duplicar')} />
    }
}

const mapStateToProps = state => ({
   objetoSeleccionado: getObjetoSeleccionado(state)
});

export default withTranslation()(connect(mapStateToProps, null)(BtnDuplicarElemento));