import React, {Component} from 'react';
import {connect} from "react-redux";
import {Row, Col, Tab, Nav, Form} from "react-bootstrap";
import {withTranslation} from "react-i18next";

import {getRecorridoCreando} from "../../reducers/recorridos";
import ContainerPdisSector from "./ContainerPdisSector";
import ContainerPksSector from "./ContainerPksSector";


class TabPdisRecorrido extends Component {

    render() {
        const {t, recorridoCreando, editar, puntosKm, show} = this.props;

        if(recorridoCreando.sectores.length > 1) {
            return <Tab.Container defaultActiveKey={recorridoCreando.sectores[0].orden}>
                {(!editar && puntosKm) &&
                <Row>
                    <Col>
                        <Form.Row>
                            <Form.Check type='checkbox' id='crear-salida-meta'>
                                <Form.Check.Input name='crear-salida-meta' type='checkbox'
                                                  defaultChecked={true}/>
                                <Form.Check.Label>{t('crear-salida-meta')}</Form.Check.Label>
                            </Form.Check>
                        </Form.Row>
                    </Col>
                </Row>
                }
                <Row className='modal-secuenciaSectores'>
                    <Nav variant="pills">
                        {recorridoCreando.sectores.map(sec =>
                            <Col key={sec.orden}>
                                <Nav.Item>
                                    <Nav.Link eventKey={sec.orden}>{sec.nombre}</Nav.Link>
                                </Nav.Item>
                            </Col>
                        )}
                    </Nav>
                </Row>
                <Row>
                    <Col>
                        {recorridoCreando.sectores.sort((a, b) => a.orden - b.orden).map(sec =>
                            <Tab.Content key={sec.orden}>
                                <Tab.Pane eventKey={sec.orden}>
                                    {puntosKm ?
                                        <ContainerPksSector sector={sec} editar={editar} show={show}/>
                                        :
                                        <ContainerPdisSector sector={sec} editar={editar} show={show}/>
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        )}
                    </Col>
                </Row>
            </Tab.Container>
        } else {
            return <div>
                {(!editar && puntosKm) &&
                <Row>
                    <Col>
                        <Form.Row>
                            <Form.Check type='checkbox' id='crear-salida-meta'>
                                <Form.Check.Input name='crear-salida-meta' type='checkbox' defaultChecked={true}/>
                                <Form.Check.Label>{t('crear-salida-meta')}</Form.Check.Label>
                            </Form.Check>
                        </Form.Row>
                    </Col>
                </Row>
                }
                {puntosKm ?
                    <ContainerPksSector sector={recorridoCreando.sectores[0]} editar={editar} show={show}/>
                    :
                    <ContainerPdisSector sector={recorridoCreando.sectores[0]} editar={editar} show={show}/>
                }
            </div>
        }
    }
}

const mapStateToProps = state => ({
    recorridoCreando: getRecorridoCreando(state)
});

export default withTranslation()(connect(mapStateToProps)(TabPdisRecorrido));
