import React, { Component } from 'react'
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import { actions } from "../actions/editor"
import BtnBarraHerramientas from "./BtnBarraHerramientas"

class BtnUnirTrayecto extends Component{
    constructor(props) {
        super(props)

        this.onClick = this.onClick.bind(this)
    }

    onClick() {
        const { setUniendo } = this.props
        setUniendo(true)
    }

    render() {
        const { t } = this.props
        return (
            <BtnBarraHerramientas
                onClick={this.onClick}
                className={this.props.className}
                classIcon='rmp-ico-anexionar'
                titulo={t('unir')}
            />
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    setUniendo: actions.setUniendo
}, dispatch);

export default withTranslation()(connect(null, mapDispatchToProps)(BtnUnirTrayecto));
