export const ACTION_TYPES = {
    ACTUALIZA_COORDS_TRAYECTO: 'ACTUALIZA_COORDS_TRAYECTO',
    ADD_TRAYECTO: 'ADD_TRAYECTO',
    FETCH_TRAYECTOS_CORRECTO: 'FETCH_TRAYECTOS_CORRECTO',
    FETCH_TRAYECTOS_ERROR: 'FETCH_TRAYECTOS_ERROR',
    FETCH_TRAYECTOS_PENDIENTE: 'FETCH_TRAYECTOS_PENDIENTE',
    REMOVE_TRAYECTO: 'REMOVE_TRAYECTO',
    RESET_TRAYECTO_NUEVO: 'RESET_TRAYECTO_NUEVO',
    SET_COLOR_TRAYECTO: 'SET_COLOR_TRAYECTO',
    SET_NOMBRE_TRAYECTO: 'SET_NOMBRE_TRAYECTO',
    SET_TRAYECTO_ACTUALIZANDO: 'SET_TRAYECTO_ACTUALIZANDO',
    TRAYECTO_ACTUALIZANDO_CORRECTO: 'TRAYECTO_ACTUALIZANDO_CORRECTO',
    TRAYECTO_ACTUALIZANDO_ERROR: 'TRAYECTO_ACTUALIZANDO_ERROR',
    TRAYECTO_ACTUALIZANDO_PENDIENTE: 'TRAYECTO_ACTUALIZANDO_PENDIENTE',
    TRAYECTO_ELIMINADO_CORRECTO: 'TRAYECTO_ELIMINADO_CORRECTO',
    TRAYECTO_ELIMNADO_ERROR: 'TRAYECTO_ELIMNADO_ERROR',
    TRAYECTO_ELIMINADO_PENDIENTE: 'TRAYECTO_ELIMINADO_PENDIENTE',
    TRAYECTO_NUEVO_ELEMENTO: 'TRAYECTO_NUEVO_ELEMENTO',
    TRAYECTO_NUEVO_ERROR: 'TRAYECTO_NUEVO_ERROR',
    TRAYECTO_NUEVO_PENDIENTE: 'TRAYECTO_NUEVO_PENDIENTE'
};

function fetchTrayectosCorrecto(trayectos) {
    return {
        type: ACTION_TYPES.FETCH_TRAYECTOS_CORRECTO,
        trayectos: trayectos
    };
}

function fetchTrayectosError(err) {
    return {
        type: ACTION_TYPES.FETCH_TRAYECTOS_ERROR,
        error: err
    };
}

function fetchTrayectosPendiente() {
    return {
        type: ACTION_TYPES.FETCH_TRAYECTOS_PENDIENTE
    };
}

function trayectoNuevoElemento(elemento) {
    return {
        type: ACTION_TYPES.TRAYECTO_NUEVO_ELEMENTO,
        elemento: elemento
    };
}

function trayectoNuevoError(err) {
    return {
        type: ACTION_TYPES.TRAYECTO_NUEVO_ERROR,
        error: err
    };
}

function trayectoNuevoPendiente() {
    return {
        type: ACTION_TYPES.TRAYECTO_NUEVO_PENDIENTE
    };
}

function addTrayecto(trayecto) {
    return{
        type: ACTION_TYPES.ADD_TRAYECTO,
        trayecto: trayecto
    };
}

function setTrayectoActualizando(trayecto) {
    return {
        type: ACTION_TYPES.SET_TRAYECTO_ACTUALIZANDO,
        trayecto: trayecto
    };
}

function trayectoActualizandoCorrecto(trayecto) {
    return {
        type: ACTION_TYPES.TRAYECTO_ACTUALIZANDO_CORRECTO,
        trayecto: trayecto
    };
}

function trayectoActualizandoError(error) {
    return {
        type: ACTION_TYPES.TRAYECTO_NUEVO_ERROR,
        error: error
    };
}

function trayectoActualizandoPendiente() {
    return {
        type: ACTION_TYPES.TRAYECTO_ACTUALIZANDO_PENDIENTE
    };
}

function removeTrayecto(trayecto) {
    return {
        type: ACTION_TYPES.REMOVE_TRAYECTO,
        trayecto: trayecto
    };
}

function resetTrayectoNuevo() {
    return {
        type: ACTION_TYPES.RESET_TRAYECTO_NUEVO
    };
}

function actualizaCoordsTrayecto(idTrayecto, coordenadas, noGuarda) {
    return {
        type: ACTION_TYPES.ACTUALIZA_COORDS_TRAYECTO,
        idTrayecto: idTrayecto,
        coordenadas: coordenadas,
        noGuarda: noGuarda
    };
}

function trayectoEliminadoPendiente(trayecto) {
    return {
        type: ACTION_TYPES.TRAYECTO_ELIMINADO_PENDIENTE,
        trayecto: trayecto
    };
}

function trayectoEliminadoCorrecto(trayecto) {
    return {
        type: ACTION_TYPES.TRAYECTO_ELIMINADO_CORRECTO,
        trayecto: trayecto
    };
}

function trayectoEliminadoError(err) {
    return {
        type: ACTION_TYPES.TRAYECTO_ELIMNADO_ERROR,
        error: err
    };
}

function setColorTrayecto(color, trayecto) {
    return {
        type: ACTION_TYPES.SET_COLOR_TRAYECTO,
        trayecto: trayecto,
        color: color
    };
}

function setNombreTrayecto(nombre, trayecto) {
    return {
        type: ACTION_TYPES.SET_NOMBRE_TRAYECTO,
        nombre,
        trayecto
    }
}

export const actions = {
    fetchTrayectosCorrecto: fetchTrayectosCorrecto,
    fetchTrayectosError: fetchTrayectosError,
    fetchTrayectosPendiente: fetchTrayectosPendiente,
    trayectoNuevoElemento: trayectoNuevoElemento,
    trayectoNuevoError: trayectoNuevoError,
    trayectoNuevoPendiente: trayectoNuevoPendiente,
    addTrayecto: addTrayecto,
    setTrayectoActualizando: setTrayectoActualizando,
    trayectoActualizandoCorrecto: trayectoActualizandoCorrecto,
    trayectoActualizandoError: trayectoActualizandoError,
    trayectoActualizandoPendiente: trayectoActualizandoPendiente,
    removeTrayecto: removeTrayecto,
    resetTrayectoNuevo: resetTrayectoNuevo,
    actualizaCoordsTrayecto: actualizaCoordsTrayecto,
    trayectoEliminadoPendiente: trayectoEliminadoPendiente,
    trayectoEliminadoCorrecto: trayectoEliminadoCorrecto,
    trayectoEliminadoError: trayectoEliminadoError,
    setColorTrayecto: setColorTrayecto,
    setNombreTrayecto: setNombreTrayecto
};
