export const ACTION_TYPES = {
    CREATE_ELEMENTO_TRAFICO_BEGIN: 'CREATE_ELEMENTO_TRAFICO_BEGIN',
    CREATE_ELEMENTO_TRAFICO_SUCCESS: 'CREATE_ELEMENTO_TRAFICO_SUCCESS',
    CREATE_ELEMENTO_TRAFICO_ERROR: 'CREATE_ELEMENTO_TRAFICO_ERROR',
    DELETE_ELEMENTO_TRAFICO_BEGIN: 'DELETE_ELEMENTO_TRAFICO_BEGIN',
    DELETE_ELEMENTO_TRAFICO_SUCCESS: 'DELETE_ELEMENTO_TRAFICO_SUCCESS',
    DELETE_ELEMENTO_TRAFICO_ERROR: 'DELETE_ELEMENTO_TRAFICO_ERROR'
}

function createElementoTraficoBegin () {
    return {
        type: ACTION_TYPES.CREATE_ELEMENTO_TRAFICO_BEGIN
    }
}

function createElementoTraficoSuccess (elementoTrafico) {
    return {
        type: ACTION_TYPES.CREATE_ELEMENTO_TRAFICO_SUCCESS,
        elementoTrafico
    }
}

function createElementoTraficoError (error) {
    return {
        type: ACTION_TYPES.CREATE_ELEMENTO_TRAFICO_ERROR,
        error
    }
}

function deleteElementoTraficoBegin () {
    return {
        type: ACTION_TYPES.DELETE_ELEMENTO_TRAFICO_BEGIN
    }
}

function deleteElementoTraficoSuccess (elementoTrafico) {
    return {
        type: ACTION_TYPES.DELETE_ELEMENTO_TRAFICO_SUCCESS,
        elementoTrafico
    }
}

function deleteElementoTraficoError (error) {
    return {
        type: ACTION_TYPES.DELETE_ELEMENTO_TRAFICO_ERROR,
        error
    }
}

export const actions = {
    createElementoTraficoBegin,
    createElementoTraficoSuccess,
    createElementoTraficoError,
    deleteElementoTraficoBegin,
    deleteElementoTraficoSuccess,
    deleteElementoTraficoError
}
