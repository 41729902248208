import React, {Component} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {Row, Col, Button} from 'react-bootstrap';

import {actions} from "../../actions/editor";

import ModalEditor from './ModalEditor';
import {getTrayectos} from "../../reducers/trayectos";
import {creaNuevoTrayecto} from "../../fetchactions/trayectos";
import {getBloqueSeleccionado} from "../../reducers/editor";
import {getProyecto} from "../../reducers/proyectos";
import {getLineas} from "../../reducers/lineas";
import {creaNuevaLinea} from "../../fetchactions/lineas";
import {creaNuevoPoligono} from "../../fetchactions/poligonos";
import {getPoligonos} from "../../reducers/poligonos";
import {getPuntos} from "../../reducers/puntos";


class ModalModoCrearElemento extends Component{

    constructor(props){
        super(props);

        this.onHide = this.onHide.bind(this);
        this.onClickDibujar = this.onClickDibujar.bind(this);
        this.onClickSubir = this.onClickSubir.bind(this);
    }

    onHide(){
        this.hide();
    }

    hide() {
        const {cambiarVisibleModalModoCrearElemento} = this.props;
        cambiarVisibleModalModoCrearElemento(false)
    }

    onClickDibujar(){
        const {bloqueSeleccionado} = this.props;
        switch (bloqueSeleccionado.tipo){
            case 'tr':
                this.dibujaTrayecto();
                break;
            case 'ln':
                this.dibujaLinea();
                break;
            case 'pl':
                this.dibujaPoligono();
                break;
            default:
                break;
        }


        this.hide();
    }

    dibujaTrayecto(){
        const {creaNuevoTrayecto, bloqueSeleccionado, proyecto} = this.props;
        let nombre = this.getNombreSiguienteElemento(bloqueSeleccionado);
        let propsDefecto = bloqueSeleccionado.props_defecto;
        creaNuevoTrayecto({
            nombre: nombre,
            color: propsDefecto.color ? propsDefecto.color : '#ff0000',
            grosor: propsDefecto.grosor ? propsDefecto.grosor : 5,
            bloque: bloqueSeleccionado.id,
            coordenadas: {
                "type": "LineString",
                "coordinates": []
            },
            proyecto: proyecto.id
        });
    }

    dibujaLinea(){
        const {bloqueSeleccionado, proyecto, creaNuevaLinea} = this.props;
        let nombre = this.getNombreSiguienteElemento(bloqueSeleccionado);
        let propsDefecto = bloqueSeleccionado.props_defecto;
        creaNuevaLinea({
            nombre: nombre,
            color: propsDefecto.color ? propsDefecto.color : '#000000',
            grosor: propsDefecto.grosor ? propsDefecto.grosor : 3,
            bloque: bloqueSeleccionado.id,
            patron: bloqueSeleccionado.patron.id,
            opacidad: propsDefecto.opacidad ? propsDefecto.opacidad: 1,
            coordenadas: {
                "type": "LineString",
                "coordinates": []
            },
            proyecto: proyecto.id
        });
    }

    dibujaPoligono() {
        const {bloqueSeleccionado, proyecto, creaNuevoPoligono} = this.props;
        let propsDefecto = bloqueSeleccionado.props_defecto;
        creaNuevoPoligono({
            nombre: this.getNombreSiguienteElemento(bloqueSeleccionado),
            color: propsDefecto.color ? propsDefecto.color: '#000000',
            grosor_linea: propsDefecto.grosor_linea ? propsDefecto.grosor_linea: 3,
            bloque: bloqueSeleccionado.id,
            patron_linea: bloqueSeleccionado.patron.id,
            opacidad_fondo: propsDefecto.opacidad_fondo ? propsDefecto.opacidad_fondo: .2,
            proyecto: proyecto.id,
            coordenadas: {
                "type": "Polygon",
                "coordinates": [[]]
            }
        });
    }

    getNombreSiguienteElemento(bloqueSeleccionado) {
        const { trayectos, lineas, poligonos, puntos } = this.props;
        const elementos = trayectos.concat(puntos, lineas, poligonos);
        let nombre;
        let contador = 1;
        while (!nombre) {
            const cont = contador;
            if (elementos.map(el => el.nombre).indexOf(bloqueSeleccionado.nombre + cont) < 0) {
                nombre = bloqueSeleccionado.nombre + cont;
            }
            contador++;
        }
        return nombre;
    }

    onClickSubir() {
        const {setVisibleModalSubirTrack} = this.props;
        setVisibleModalSubirTrack(true);
        this.hide();
    }

    render(){
        const {t, show} = this.props;
        return <ModalEditor show={show} onHide={this.onHide} titulo={t('selecionar-crear-trayecto')}>
            <Row>
                <Col className='btn-biblio' md={6} xs={12}>
                    <Button onClick={this.onClickDibujar} variant='dark'>
                        <div><i className='fa fa-pencil-ruler'/></div>
                        <div>{t('dibujar')}</div>
                    </Button>
                </Col>
                <Col className='btn-biblio' md={6} xs={12}>
                    <Button onClick={this.onClickSubir} variant='dark'>
                        <div><i className='fa fa-upload'/></div>
                        <div>{t('subir-track')}</div>
                    </Button>
                </Col>
            </Row>
        </ModalEditor>
    }
}


const mapStateToProps = state => ({
    trayectos: getTrayectos(state),
    bloqueSeleccionado: getBloqueSeleccionado(state),
    proyecto: getProyecto(state),
    lineas: getLineas(state),
    puntos: getPuntos(state),
    poligonos: getPoligonos(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    cambiarVisibleModalModoCrearElemento: actions.cambiarVisibleModalModoCrearElemento,
    creaNuevoTrayecto: creaNuevoTrayecto,
    setVisibleModalSubirTrack: actions.setVisibleModalSubirTrack,
    creaNuevaLinea: creaNuevaLinea,
    creaNuevoPoligono: creaNuevoPoligono
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalModoCrearElemento))
