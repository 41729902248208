import React, {Component} from 'react';
import {WMSTileLayer, TileLayer} from "react-leaflet";
import AppleLayer from "../views/editor/AppleLayer";

export default class CapaMapa extends Component{

    render(){
        const {capa, map} = this.props;
        if(capa.servicio === 'TMS') {
            return <TileLayer url={capa.url} attribution={capa.atribucion} zIndex={capa.transparencia ? 1 : 0}
                              maxNativeZoom={20} retina={'@2x'} detectRetina={true}/>
        } else if(capa.servicio === 'WMTS') {
            return <TileLayer url={capa.url + '?service=WMTS&request=GetTile&version=1.0.0&styles=&tilematrixSet=' + capa.tilematrixset + '&format=' + capa.formato + '&height=256&width=256&layer=' + capa.layer + '&transparent=' + capa.transparencia +'&tilematrix={z}&tilerow={y}&tilecol={x}'}
                              attribution={capa.atribucion} zIndex={capa.transparecia ? 1 : 0} maxNativeZoom={20}
                              retina={'@2x'} detectRetina={true}/>
        } else if(capa.servicio === 'WMS') {
            return <WMSTileLayer url={capa.url} layers={capa.layer} format={capa.formato} maxNativeZoom={20}
                              transparent={capa.transparencia} attribution={capa.atribucion}
                                 zIndex={capa.transparencia ? 1 : 0} retina={'@2x'} detectRetina={true}/>
        } else if(capa.servicio === 'APPLE') {
            return <AppleLayer layer={capa.layer} map={map} attribution={capa.atribucion} token={capa.token}
                               zIndex={capa.transparencia ? 1 : 0}/>
        }
    }
}
