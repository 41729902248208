import React from 'react'
import { render } from 'react-dom'
import { Helmet } from "react-helmet"
import { I18nextProvider } from "react-i18next"
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Provider from "react-redux/es/components/Provider"
import moment from 'moment'
import {} from 'moment/locale/es'
// import {} from 'moment/locale/en'

import './index.css'
import './tema_pordefecto.css'
import * as serviceWorker from './serviceWorker'

import store from './store/configureStore'


import i18n from './i18n'
import { SERVER_DOMAIN } from './constants/api'

import Editor from './views/editor/Editor.js';

// Esto depende tamén do que cargemos en '/moment/locale/<loc>

const LANGUAGE_CODE = window.LANGUAGE_CODE || 'es'
moment.locale(LANGUAGE_CODE)


// export const store = createStore()
// const history = syncHistoryWithStore(browserHistory, store);


render(
    <>
        <Helmet>
            <html lang="es" />
            <title>RaceMapp</title>
            <meta name="description" content="Encuentra el recorrido de tu prueba, interactivo y fácil." />
        </Helmet>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <Router>
                    <Route exact path="/:id(\d+)" component={Editor}/>
                </Router>
            </Provider>
        </I18nextProvider>
    </>,
    document.getElementById('root')
)


// Cargamos o CSS para os iconos, que depende de SERVER_DOMAIN
const sheet = document.createElement('link')
sheet.rel = 'stylesheet';
sheet.href = SERVER_DOMAIN + '/cssiconos.css';
sheet.type = 'text/css';
document.head.appendChild(sheet);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister() // Para non interceptar as peticións reales ó servidor
// serviceWorker.register()
