import React, {Component} from 'react';
import {Polyline} from "react-leaflet";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

import {getDuplicando} from "../reducers/editor";
import {actualizaLinea, eliminarLinea} from "../fetchactions/lineas";
import {actions} from "../actions/lineas";
import {actions as actionsEditor} from "../actions/editor";
import {getLineaActualizando} from "../reducers/lineas";

class NuevaLinea extends Component {

    constructor(props) {
        super(props);
        this.lineRef = React.createRef();
        this.type = 'nln';

        this.onDrawingEnd = this.onDrawingEnd.bind(this);
        this.onPressEsc = this.onPressEsc.bind(this);
    }

    componentDidMount() {
        const {duplicando, setDuplicando, resetNuevaLinea, addLinea, elemento, setObjetoSeleccionado,
            removeCapaColapsada} = this.props;
        removeCapaColapsada(elemento.bloque.capa);
        if(!duplicando){
            const line = this.lineRef.current.leafletElement;
            line.enableEdit().newShape();
            line.on('editable:drawing:end', this.onDrawingEnd);
            document.addEventListener('keyup', this.onPressEsc);
            setObjetoSeleccionado(this)
        } else {
            setDuplicando(false);
            resetNuevaLinea();
            addLinea(elemento)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const line = this.lineRef.current.leafletElement;
        line.enableEdit().newShape();
    }

    onDrawingEnd(e) {
        if(this.lineRef.current) {
            const {elemento, actualizaLinea, lineaActualizando, setVisibleModalPropAvanzada} = this.props;
            const line = this.lineRef.current.leafletElement;
            line.disableEdit();
            actualizaLinea(elemento.id, {
                nombre: lineaActualizando && lineaActualizando.id === elemento.id ?
                    lineaActualizando.nombre : elemento.nombre,
                bloque: elemento.bloque.id,
                coordenadas: line.toGeoJSON().geometry
            });
            setVisibleModalPropAvanzada(true);
            //resetNuevaLinea();
        }
    }

    getUltimasCoords() {
        const line = this.lineRef.current.leafletElement;
        return line.toGeoJSON().geometry;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const {elemento} = this.props;
        const line = this.lineRef.current.leafletElement;
        if(nextProps.lineaActualizando && nextProps.lineaActualizando.id === elemento.id) {
            line.setStyle({color: nextProps.lineaActualizando.color});
        }
        return false;
    }

    onPressEsc(e) {
        if(e.keyCode === 27) {
            const {resetNuevaLinea, elemento, eliminarLinea} = this.props;
            eliminarLinea(elemento);
            resetNuevaLinea();
        }
    }

    componentWillUnmount() {
        const {setObjetoSeleccionado} = this.props;
        setObjetoSeleccionado(null);
        document.removeEventListener('keyup', this.onPressEsc);
    }

    render() {
        const {elemento} = this.props;
        return <Polyline ref={this.lineRef} positions={[]} color={elemento.color} weight={elemento.grosor}
                  dashArray={elemento.bloque.patron.patron}/>
    }
}

const mapStateToProps = state => ({
    duplicando: getDuplicando(state),
    lineaActualizando: getLineaActualizando(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    actualizaLinea: actualizaLinea,
    resetNuevaLinea: actions.resetNuevaLinea,
    setDuplicando: actionsEditor.setDuplicando,
    addLinea: actions.addLinea,
    setObjetoSeleccionado: actionsEditor.objetoSeleccionado,
    removeCapaColapsada: actionsEditor.removeCapaColapsada,
    eliminarLinea: eliminarLinea,
    setVisibleModalPropAvanzada: actionsEditor.setVisibleModalPropAvanzadas
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NuevaLinea));
