import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Row, Col} from "react-bootstrap";

import ModalEditor from "./ModalEditor";
import {getObjetoSeleccionado} from "../../reducers/editor";
import {actions} from "../../actions/editor";
import {eliminarTrayecto} from "../../fetchactions/trayectos";
import {eliminarPunto} from "../../fetchactions/puntos";
import {eliminarLinea} from "../../fetchactions/lineas";
import {eliminarPoligono} from "../../fetchactions/poligonos";
import {getPuntos} from "../../reducers/puntos";
import {getRecorridos} from "../../reducers/recorridos";

class ModalEliminarElemento extends Component {
    constructor(props) {
        super(props);

        this.onClickAceptar = this.onClickAceptar.bind(this);
        this.onClickCancelar = this.onClickCancelar.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onClickAceptar() {
        const {objetoSeleccionado, eliminarTrayecto, eliminarPunto, eliminarLinea, eliminarPoligono} = this.props;
        objetoSeleccionado.eliminado = true;
        switch(objetoSeleccionado.props.elemento.bloque.tipo){
            case 'tr':
                eliminarTrayecto(objetoSeleccionado.props.elemento);
                break;
            case 'pt':
                eliminarPunto(objetoSeleccionado.props.elemento);
                break;
            case 'ln':
                eliminarLinea(objetoSeleccionado.props.elemento);
                break;
            case 'pl':
                eliminarPoligono(objetoSeleccionado.props.elemento);
                break;
            default:
                break;
        }
        this.hide();
    }

    onClickCancelar() {
        this.hide();
    }

    onHide() {
        this.hide();
    }

    hide() {
        const{cambiarVisible} = this.props;
        cambiarVisible(false);
    }

    isPuntoEnRecorrido() {
        const {recorridos, objetoSeleccionado} = this.props;
        let recorridosPresente = [];
        for(let i in recorridos) {
            for(let j in recorridos[i].sectores) {
                for(let k in recorridos[i].sectores[j].puntos_km) {
                    if(recorridos[i].sectores[j].puntos_km[k].punto.id === objetoSeleccionado.props.elemento.id) {
                        recorridosPresente.push(recorridos[i]);
                        break;
                    }
                }
                for(let k in recorridos[i].sectores[j].pdis_recorrido) {
                    if(recorridos[i].sectores[j].pdis_recorrido[k].id === objetoSeleccionado.props.elemento.id) {
                        recorridosPresente.push(recorridos[i]);
                        break;
                    }
                }
            }
        }
        if(recorridosPresente.length > 0)
            return recorridosPresente;
        else
            return false;
    }

    render() {
        const {t, show, objetoSeleccionado, puntos} = this.props;
        const recorridosPresente = objetoSeleccionado && objetoSeleccionado.props.elemento.bloque.tipo === 'pt' ?
            this.isPuntoEnRecorrido() : false;
        return <ModalEditor show={show} onHide={this.onHide} footer onClickAceptar={this.onClickAceptar}
                            onClickCancelar={this.onClickCancelar} titulo={t('confirmacion-borrado-titulo') +
                            (objetoSeleccionado ?
                                t(objetoSeleccionado.props.elemento.bloque.nombre).toLocaleLowerCase() : '')}>
            <Row>
                <Col>
                    {t('confirmacion-borrado-body')}
                    {(objetoSeleccionado ? <b>{objetoSeleccionado.props.elemento.nombre}</b> : '')}
                    {'?'}
                </Col>
            </Row>
            {objetoSeleccionado && objetoSeleccionado.props.elemento.bloque.tipo === 'tr' && puntos.filter(punto =>
                punto.trayecto_ancla === objetoSeleccionado.props.elemento.id).length > 0 &&
                <Row>
                    <Col>
                        {t('msg-borrar-pts-sobre-trayecto')}
                        <ul>
                            {puntos.filter(punto =>
                                punto.trayecto_ancla === objetoSeleccionado.props.elemento.id).map(punto =>
                                    <li key={punto.id}>
                                        {punto.nombre}
                                    </li>
                                )}
                        </ul>
                    </Col>
                </Row>
            }
            {objetoSeleccionado && objetoSeleccionado.props.elemento.bloque.tipo === 'pt' && recorridosPresente.length &&
                <Row>
                    <Col>
                        {t('msg-puntos-en-recorridos')}
                        <ul>
                            {recorridosPresente.map(r =>
                                <li key={r.id}>
                                    {r.nombre}
                                </li>
                            )}
                        </ul>
                    </Col>
                </Row>
            }
        </ModalEditor>
    }
}

const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state),
    puntos: getPuntos(state),
    recorridos: getRecorridos(state)
});

const mapDisaptchToProps = dispatch => bindActionCreators({
    cambiarVisible: actions.cambiarVisibleModalEliminarElemento,
    eliminarTrayecto: eliminarTrayecto,
    setObjetoSeleccionado: actions.objetoSeleccionado,
    eliminarPunto: eliminarPunto,
    eliminarLinea: eliminarLinea,
    eliminarPoligono: eliminarPoligono
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDisaptchToProps)(ModalEliminarElemento));
