export const ACTION_TYPES = {
    FETCH_DEPORTES_CORRECTO: 'FETCH_DEPORTES_CORRECTO',
    FETCH_DEPORTES_ERROR: 'FETCH_DEPORTES_ERROR',
    FETCH_DEPORTES_PENDIENTE: 'FETCH_DEPORTES_PENDIENTE'
};

function fetchDeportesCorrecto(deportes) {
    return {
        type: ACTION_TYPES.FETCH_DEPORTES_CORRECTO,
        deportes: deportes
    };
}

function fetchDeportesError(err) {
    return {
        type: ACTION_TYPES.FETCH_DEPORTES_ERROR,
        error: err
    };
}

function fetchDeportesPendiente() {
    return {
        type: ACTION_TYPES.FETCH_DEPORTES_PENDIENTE
    };
}


export const actions = {
    fetchDeportesCorrecto: fetchDeportesCorrecto,
    fetchDeportesError: fetchDeportesError,
    fetchDeportesPendiente: fetchDeportesPendiente
}
