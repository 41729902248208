import React, {Component} from 'react';
import {InputGroup, FormControl} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as turf from "@turf/turf";

import './InputPropiedad.css';

import {actualizaTrayecto} from "../../fetchactions/trayectos";
import {actualizaPunto} from "../../fetchactions/puntos";
import {getTrayectoActualizando} from "../../reducers/trayectos";
import {getPuntoActualizando} from "../../reducers/puntos";
import {actualizaLinea} from "../../fetchactions/lineas";
import {actualizaPoligono} from "../../fetchactions/poligonos";
import {getLineaActualizando} from "../../reducers/lineas";
import {getPoligonoActualizando} from "../../reducers/poligonos";
import {actions as actionsLineas} from "../../actions/lineas";
import {actions as actionsPoligonos} from "../../actions/poligonos";
import {actions as actionsPuntos} from "../../actions/puntos";
import {actions as actionsTrayectos} from "../../actions/trayectos";

class InputPropiedad extends Component{
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.elementoActualizado = null;

        this.onFocusOut = this.onFocusOut.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        let input = this.inputRef.current;
        input.addEventListener('focusout', this.onFocusOut)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {elemento, propiedad, trayectoActualizado, puntoActualizando, poligonoActualizando,
            lineaActualizando} = this.props;
        const esTrayectoActualizado = (!prevProps.trayectoActualizado && trayectoActualizado && trayectoActualizado.id === elemento.id);
        const esPuntoActualizando = (!prevProps.puntoActualizando && puntoActualizando && puntoActualizando.id === elemento.id);
        const esPoligonoActualizando = (!prevProps.poligonoActualizando && poligonoActualizando && poligonoActualizando.id === elemento.id);
        const esLineaActualizada = (!prevProps.lineaActualizando && lineaActualizando && lineaActualizando.id === elemento.id);
        if(prevProps.elemento.id !== elemento.id || esTrayectoActualizado || esPuntoActualizando || esPoligonoActualizando || esLineaActualizada) {
            const value = esTrayectoActualizado ? trayectoActualizado[propiedad] : (esPuntoActualizando ?
                puntoActualizando[propiedad] : (esPoligonoActualizando ?
                    poligonoActualizando[propiedad] : (esLineaActualizada ?
                        lineaActualizando[propiedad] : elemento[propiedad])));
            this.setValue(value);
        }
    }

    setValue(value) {
        let input = this.inputRef.current;
        input.value = value;
    }

    onFocusOut(e) {
        this.guardarCambios(e);
    }

    onKeyPress(e) {
        if(e.charCode === 13) {         //Enter
            this.guardarCambios(e);
        }
    }

    guardarCambios(e) {
        const {t, elemento, propiedad, actualizaTrayecto, objeto, actualizaPunto, actualizaLinea,
            actualizaPoligono} = this.props;
        if(propiedad === 'distancia_homologada') {
            if(!e.target.value || e.target.value === '') return false;
            else if(isNaN(e.target.value)){
                alert(t('distancia-debe-numero'));
                return false;
            }
            const distElemento = elemento.distancia_calculada ?
                elemento.distancia_calculada : turf.length(elemento.coordenadas, {units: 'meters'});
            const umbral = distElemento * .2;
            if(parseFloat(e.target.value) < distElemento - umbral
                || parseFloat(e.target.value) > distElemento + umbral) {
                alert(t('distancia-muy-diferente'))
            }
        }
        const elementoActualizado = this.elementoActualizado ? this.elementoActualizado : elemento;
        let opciones = {
            nombre: elementoActualizado.nombre,
            bloque: elemento.bloque.id,
        };
        if(objeto.getUltimasCoords()) {
            opciones['coordenadas']= objeto.getUltimasCoords();
        }
        opciones[propiedad] = e.target.value;
        switch (elemento.bloque.tipo) {
            case 'tr':
                actualizaTrayecto(elemento.id, opciones);
                break;
            case 'pt':
                opciones['icono'] = elemento.icono.id;
                actualizaPunto(elemento.id, opciones);
                break;
            case 'ln':
                actualizaLinea(elemento.id, opciones);
                break;
            case 'pl':
                if(opciones.coordenadas.coordinates[0].length < 4)
                    opciones.coordenadas = {"type": "Polygon", "coordinates": [[]]};
                actualizaPoligono(elemento.id, opciones);
                break;
            default:
                break;
        }
    }

    onChange(e) {
        const { setNombreLinea, setNombrePoligono, setNombrePunto, setNombreTrayecto, elemento, propiedad } = this.props;
        if (propiedad === 'nombre') {
            switch (elemento.bloque.tipo) {
                case 'tr':
                    setNombreTrayecto(e.target.value, elemento);
                    break;
                case 'pt':
                    setNombrePunto(e.target.value, elemento);
                    break;
                case 'pl':
                    setNombrePoligono(e.target.value, elemento);
                    break;
                default:
                    setNombreLinea(e.target.value, elemento);
            }
        }
    }

    render() {
        const {t, propiedad, elemento} = this.props;
        const textoPropiedad = propiedad === 'distancia_homologada' ? 'distancia_homologada_metros' : propiedad
        return <InputGroup className="input-group-propiedad">
            <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon3">{t(textoPropiedad) + ':'}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl onChange={this.onChange} ref={this.inputRef} aria-label="basic-addon3" aria-describedby="basic-addon3"
                         defaultValue={elemento[propiedad]} onKeyPress={this.onKeyPress}/>

        </InputGroup>
    }
}

const mapStateToProps = state => ({
    trayectoActualizado: getTrayectoActualizando(state),
    puntoActualizando: getPuntoActualizando(state),
    lineaActualizando: getLineaActualizando(state),
    poligonoActualizando: getPoligonoActualizando(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    actualizaTrayecto: actualizaTrayecto,
    actualizaPunto: actualizaPunto,
    actualizaLinea: actualizaLinea,
    actualizaPoligono: actualizaPoligono,
    setNombreLinea: actionsLineas.setNombreLinea,
    setNombrePoligono: actionsPoligonos.setNombrePoligono,
    setNombrePunto: actionsPuntos.setNombrePunto,
    setNombreTrayecto: actionsTrayectos.setNombreTrayecto
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InputPropiedad));
