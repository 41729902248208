import React, {Component} from 'react';
import {InputGroup} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {getLatitudDisplay} from "../../reducers/editor";
import {actions} from "../../actions/editor";


class DisplayLat extends Component {

    constructor(props) {
        super(props);

        this.onPointDrag = this.onPointDrag.bind(this);
    }

    componentDidMount() {
        const {objeto, setLatitudDisplay} = this.props;
        if(objeto.props.map && objeto.props.map.current) {
            const map = objeto.props.map.current.leafletElement;
            map.on('editable:drag', this.onPointDrag);
            map.on('editable:created', this.onPointDrag);
            const elemento = objeto.props.elemento;
            if(elemento.coordenadas) setLatitudDisplay(elemento.coordenadas.coordinates[1]);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {setLatitudDisplay, objeto} = this.props;
        if(objeto.props.elemento.id !== prevProps.objeto.props.elemento.id) {
            if(objeto.props.elemento.coordenadas) setLatitudDisplay(objeto.props.elemento.coordenadas.coordinates[1]);
        }
    }

    onPointDrag(e) {
        const {setLatitudDisplay} = this.props;
        setLatitudDisplay(e.layer._latlng.lat)
    }

    componentWillUnmount() {
        const {objeto, setLatitudDisplay} = this.props;
        if(objeto.props.map && objeto.props.map.current) {
            const map = objeto.props.map.current.leafletElement;
            map.off('editable:drag');
            map.off('editable:created');
        }
        setLatitudDisplay(null);
    }

    render (){
        const {latitud} = this.props;
        return <InputGroup className="input-group-propiedad input-group-propiedad-distancia-real input-group-propiedad-coord">
            <InputGroup.Prepend>
                <InputGroup.Text>{'Lat:'}</InputGroup.Text>
            </InputGroup.Prepend>
            <InputGroup.Text>
                <b>{latitud ? latitud.toFixed(4) : '-'}</b>
            </InputGroup.Text>
        </InputGroup>
    }
}

const mapStateToProps = state => ({
    latitud: getLatitudDisplay(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setLatitudDisplay: actions.setLatitudDisplay
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DisplayLat);