import {API_BASE_URL} from "../constants/api";
import {actions} from "../actions/recorridos";
import {actions as actionsComun} from "../actions/comun";
import {actualizaSector, creaNuevoSector, eliminaSector} from "./sectores";
import getCsrfToken from '../lib/csrf'
import { actions as actionsError } from '../actions/error'

export function fetchRecorridos(mapaId, fields=[]) {
    return dispatch => {
        let url = new URL(API_BASE_URL + 'recorrido/')
        const params = {'mapa': mapaId, 'editor': true}
        if (fields && fields.length) {
            params['fields'] = fields.join(',')
        }
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )
        dispatch(actions.fetchRecorridosPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(url, {
            credentials: 'include',
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);

                dispatch(actions.fetchRecorridosCorrecto(res));
                dispatch(actionsComun.restaPeticion());
            })
            .catch(err => {
                dispatch(actions.fetchRecorridosError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function fetchRecorrido(recorridoId) {
    return dispatch => {
        dispatch(actions.fetchRecorridoPendiente());
        dispatch(actionsComun.sumaPeticion());

        let url = API_BASE_URL + 'recorrido/' + recorridoId + '/'
        const key = new URLSearchParams(window.location.search).get('key')
        // Pasamos o parámetro key a API. Úsase a hora de xerar as capturas,
        // para poder ver recorridos non publicados.
        if (key) {
            url += '?key=' + key
        }

        fetch(url, {
            credentials: 'include',
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);
                if(res.detail) throw(res.detail);

                dispatch(actions.fetchRecorridoCorrecto(res));
                dispatch(actionsComun.restaPeticion());
            })
            .catch(err => {

                dispatch(actions.fetchRecorridoError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

//export function creaNuevoRecorrido(opciones, sectores, pksPorAnadir, idProyecto, bloqueSalida, bloqueMeta, bloqueSalidaMeta) {
export function creaNuevoRecorrido(opciones, sectores, pksPorAnadir) {
    return dispatch => {
        let totalEsquemas = 0
        for(let i in sectores){
            totalEsquemas += sectores[i].esquemas.length;
        }
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.nuevoRecorridoPendiente(totalEsquemas));
        fetch(API_BASE_URL + 'recorrido/' , {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.nuevoRecorridoCorrecto(data));
                            dispatch(actionsComun.restaPeticion());
                            for(let i in sectores) {
                                dispatch(creaNuevoSector({
                                    nombre: sectores[i].nombre,
                                    deporte: sectores[i].deporte ? (sectores[i].deporte.id ? sectores[i].deporte.id : sectores[i].deporte): null,
                                    descripcion: sectores[i].descripcion ? sectores[i].descripcion : '',
                                    orden: sectores[i].orden,
                                    recorrido: data.id,
                                    perfil_publicado: sectores[i].perfil_publicado,
                                    pdis_recorrido: sectores[i].pdis_recorrido.map(p => p.id),
                                    puntos_km_auto: sectores[i].puntos_km_auto,
                                    color: sectores[i].color,
                                    generar_inicio_fin: sectores[i].generar_inicio_fin
                                }, sectores[i].esquemas, pksPorAnadir, totalEsquemas));
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function actualizaRecorrido(id, opciones, sectores, sectoresAntiguos, pksPorAnadir) {
    let totalEsquemas = 0;
    for(let i in sectores) {
        totalEsquemas += sectores[i].esquemas.length;
    }
    return dispatch => {
        dispatch(actions.recorridoActualizandoPendiente(totalEsquemas));
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'recorrido/' + id + '/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.recorridoActualizandoCorrecto(data));
                            dispatch(actions.removeRecorrido(data));
                            dispatch(actions.addRecorrido(data));
                            dispatch(actionsComun.restaPeticion());
                            for(let i in sectoresAntiguos) {
                                const indexNuevo = sectores.map(sector => sector.id).indexOf(sectoresAntiguos[i].id)
                                if( indexNuevo >= 0) {
                                    dispatch(actualizaSector(sectoresAntiguos[i].id, {
                                        id: sectoresAntiguos[i].id,
                                        nombre: sectores[indexNuevo].nombre,
                                        deporte: sectores[indexNuevo].deporte ? (sectores[indexNuevo].deporte.id ? sectores[indexNuevo].deporte.id: sectores[indexNuevo].deporte) : null,
                                        descripcion: sectores[indexNuevo].descripcion ? sectores[indexNuevo].descripcion : '',
                                        orden: sectores[indexNuevo].orden,
                                        recorrido: data.id,
                                        perfil_publicado: sectores[indexNuevo].perfil_publicado,
                                        pdis_recorrido: sectores[indexNuevo].pdis_recorrido.map(p => p.id),
                                        puntos_km_auto: sectores[indexNuevo].puntos_km_auto,
                                        color: sectores[indexNuevo].color,
                                        generar_inicio_fin: sectores[indexNuevo].generar_inicio_fin
                                    }, data.id, sectores[indexNuevo].esquemas, sectoresAntiguos[i].esquemas, pksPorAnadir));
                                }else {
                                    dispatch(eliminaSector(sectoresAntiguos[i], data.id));
                                }
                            }
                            for(let i in sectores) {
                                if(sectoresAntiguos.map(sector => sector.id).indexOf(sectores[i].id) < 0) {
                                    dispatch(creaNuevoSector({
                                        nombre: sectores[i].nombre,
                                        deporte: sectores[i].deporte ? (sectores[i].deporte.id ? sectores[i].deporte.id : sectores[i].deporte): null,
                                        descripcion: sectores[i].descripcion ? sectores[i].descripcion : '',
                                        orden: sectores[i].orden,
                                        recorrido: data.id,
                                        perfil_publicado: sectores[i].perfil_publicado,
                                        pdis_recorrido: sectores[i].pdis_recorrido.map(p => p.id),
                                        puntos_km_auto: sectores[i].puntos_km_auto,
                                        color: sectores[i].color,
                                        generar_inicio_fin: sectores[i].generar_inicio_fin
                                    }, sectores[i].esquemas, pksPorAnadir));
                                }
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function eliminarRecorrido(recorrido) {
    return dispatch => {
        dispatch(actions.recorridoEliminandoPendiente(recorrido));
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'recorrido/' + recorrido.id + '/', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
        })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    dispatch(actions.recorridoEliminandoCorrecto(recorrido));
                    dispatch(actionsComun.restaPeticion());
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function creaNuevoPk(opciones, idRecorrido) {
    return dispatch => {
        dispatch(actions.nuevoPkPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'puntokilometrico/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        }).then(res => {
                    if(res.status !== 201) {
                        return res.json()
                            .then(data => {
                                dispatch(actionsError.hasError(data));
                                dispatch(actionsComun.restaPeticion());
                            });
                    } else {
                        return res.json()
                            .then(data => {
                                dispatch(actions.nuevoPkCorrecto(idRecorrido, data));
                                dispatch(actionsComun.restaPeticion());
                            });
            }
        })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actions.nuevoPkError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function eliminarPk(pk, idRecorrido) {
    return dispatch => {
        dispatch(actions.pkEliminandoPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'puntokilometrico/' + pk.id + '/', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
        }).then(res => {
            if(res.status !== 204) {
                return res.json()
                    .then(data => {
                        dispatch(actionsError.hasError(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            } else {
                dispatch(actions.pkEliminadoCorrecto(idRecorrido, pk));
                dispatch(actionsComun.restaPeticion());
            }
        })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actions.pkEliminandoError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function actualizaPk(opciones, idPk, idRecorrido) {
    return dispatch => {
        dispatch(actions.pkActualizandoPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'puntokilometrico/' + idPk + '/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        }).then(res => {
            if(res.status !== 200) {
                return res.json()
                    .then(data => {
                        dispatch(actionsError.hasError(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            } else {
                return res.json()
                    .then(data => {
                        dispatch(actions.pkActualizandoCorrecto(idRecorrido, data));
                        dispatch(actionsComun.restaPeticion());
                    });
            }
        })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actions.pkActualizandoError(err));
                dispatch(actionsComun.restaPeticion());
            })
    }
}

export function creaNuevoElementoRecorrido(opciones) {
    return dispatch => {
        dispatch(actions.nuevoElementoRecorridoPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'elementorecorrido/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        }).then(res => {
            if (res.status !== 201) {
                return res.json()
                    .then(data => {
                        dispatch(actionsError.hasError(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            } else {
                return res.json()
                    .then(data => {
                        dispatch(actions.nuevoElementoRecorridoCorrecto(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            }
        })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actions.nuevoElementoRecorridoError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function eliminaElementoRecorrido(elementoRecorrido){
    return dispatch => {
        dispatch(actions.elementoRecorridoEliminandoPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'elementorecorrido/' + elementoRecorrido.id + '/', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
        }).then(res => {
            if(res.status !== 204) {
                return res.json()
                    .then(data => {
                        dispatch(actionsError.hasError(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            } else {
                dispatch(actions.elementoRecorridoEliminandoCorrecto(elementoRecorrido));
                dispatch(actionsComun.restaPeticion());
            }
        })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actions.elementoRecorridoEliminandoError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function actualizaElementoRecorrido(opciones, idElementoRecorrido) {
    return dispatch => {
        dispatch(actions.elementoRecorridoActualizandoPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'elementorecorrido/' + idElementoRecorrido + '/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        }).then(res => {
            if(res.status !== 200) {
                return res.json()
                    .then(data => {
                        dispatch(actionsError.hasError(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            } else {
                return res.json()
                    .then(data => {
                        dispatch(actions.elementoRecorridoActualizandoCorrecto(data));
                        dispatch(actionsComun.restaPeticion());
                    });
            }
        })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actions.elementoRecorridoActualizandoError(err));
                dispatch(actionsComun.restaPeticion());
            })
    }
}
