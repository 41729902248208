import React, {Component} from 'react';
import {Col, Row, TabContainer} from "react-bootstrap";
import Nav from "react-bootstrap/esm/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import Tab from "react-bootstrap/esm/Tab";
import {connect} from "react-redux";

import SubTabSecuenciaSector from "./SubTabSecuenciaSector";
import {getRecorridoCreando} from "../../reducers/recorridos";
import {getDeportes} from "../../reducers/deportes";
import {getActiveKeySecuenciaSector} from "../../reducers/editor";
import {bindActionCreators} from "redux";
import {actions} from "../../actions/editor";


class ContainerSecuenciaSectores extends Component {

    componentDidMount() {
        const {recorridoCreando, setActiveKeySecuenciaSector} = this.props;
        setActiveKeySecuenciaSector(recorridoCreando.sectores[0].orden);
    }

    onClickTab(sector) {
        const {setActiveKeySecuenciaSector} = this.props;
        setActiveKeySecuenciaSector(sector.orden);
    }

    render() {
        const {
            recorridoCreando, deportes, onDrop, getDistanciaTrayecto, onChangeRepeticiones, reordenaTrayectos,
            modalRecorridos, onClickEliminarTrayecto, onChangeEsquemaSimplificado, onChangeDeporteSector,
            onChangeNombreSector, onClickEliminarSector, onChangeColorSector, onClickAddSector, activeKeySecuenciaSector
        } = this.props;
        return <TabContainer activeKey={activeKeySecuenciaSector}>
            <Row className='modal-secuenciaSectores'>
                <Nav variant="pills">
                    {recorridoCreando.sectores.map(s =>
                        <Col key={s.id}>
                            <Nav.Item>
                                <Nav.Link onClick={() => this.onClickTab(s)} eventKey={s.orden}>{s.nombre}</Nav.Link>
                            </Nav.Item>
                        </Col>
                    )}
                    <Col>
                        <Nav.Item>
                            <Nav.Link onClick={onClickAddSector}>
                                <FontAwesomeIcon icon={faPlusCircle}/>
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                </Nav>
            </Row>
            <Row>
                <Col>
                    {recorridoCreando.sectores.map(sec =>
                        <Tab.Content key={sec.orden}>
                            <Tab.Pane eventKey={sec.orden}>
                                <SubTabSecuenciaSector sector={sec} modalRecorridos={modalRecorridos}
                                                       deportes={deportes}
                                                       recorridoCreando={recorridoCreando}
                                                       onDrop={onDrop}
                                                       getDistanciaTrayecto={getDistanciaTrayecto}
                                                       onChangeRepeticiones={onChangeRepeticiones}
                                                       reordenaTrayectos={reordenaTrayectos}
                                                       onClickEliminarTrayecto={onClickEliminarTrayecto}
                                                       onChangeEsquemaSimplificado={onChangeEsquemaSimplificado}
                                                       onChangeDeporteSector={onChangeDeporteSector}
                                                       onChangeNombreSector={onChangeNombreSector}
                                                       onClickEliminarSector={onClickEliminarSector}
                                                       onChangeColorSector={onChangeColorSector}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    )}
                </Col>
            </Row>
        </TabContainer>
    }
}

const mapStateToProps = state => ({
    recorridoCreando: getRecorridoCreando(state),
    deportes: getDeportes(state),
    activeKeySecuenciaSector: getActiveKeySecuenciaSector(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setActiveKeySecuenciaSector: actions.setActiveKeySecuenciaSector
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(ContainerSecuenciaSectores);
