import React from "react"
import { useDrag, useDrop } from "react-dnd"

const FilaOtrosMapas = ({ mapa, handleClickEditar, handleClickEliminar, handleReordena }) => {
    const id = mapa.orden
    const [, drag] = useDrag({
        item: { type: 'fila', id },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
    })
    const [, drop] = useDrop({
        accept: 'fila',
        drop({id: draggedId}) {
            if(draggedId !== id) {
                handleReordena(draggedId, id)
            }
        }
    })

    return (
        <li ref={node=>drag(drop(node))} className='list-group-item d-flex justify-content-between align-items-center'>
            <span>
                <i className='fas fa-arrows-alt mr-3' />
                {mapa.nombre}{mapa.es_pagado ? ' *' : ''}
            </span>
            <div className='btn-toolbar' role='toolbar'>
                <div className='btn-group' role='group'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={() => handleClickEditar(mapa)}
                    >
                        <i className='fas fa-edit' />
                    </button>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        disabled={mapa.es_pagado}
                        onClick={() => handleClickEliminar(mapa)}
                    >
                        <i className='fas fa-trash-alt' />
                    </button>
                </div>
            </div>
        </li>
    )
}
export default FilaOtrosMapas
