import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {ModalSelectorServicios} from "./ModalSelectorServicios";
import {
    getBloqueSeleccionado,
    getFiltroAnadirElemento,
    getPdiEditar,
    getServiciosSeleccionados
} from "../../reducers/editor";
import {getServicios} from "../../reducers/servicios";
import {getProyecto} from "../../reducers/proyectos";
import {getPuntos} from "../../reducers/puntos";
import {actions} from "../../actions/editor";
import {actualizaPunto, creaNuevoPunto} from "../../fetchactions/puntos";

class ModalEditarServicios extends ModalSelectorServicios {

    componentDidMount() {
        const {servicios, pdiEditar, addServicioSeleccionado, setFiltroAnadirElementos,
            resetServiciosSeleccionado} = this.props;
        resetServiciosSeleccionado();
        setFiltroAnadirElementos('');
        for(let i in pdiEditar.servicios) {
            addServicioSeleccionado(pdiEditar.servicios[i].id ? pdiEditar.servicios[i]: servicios.filter(s =>
                s.id === pdiEditar.servicios[i])[0]);
        }
    }

    onClickAceptar() {
        const {actualizaPunto, pdiEditar, serviciosSeleccionados} = this.props;
        actualizaPunto(pdiEditar.id, {
            nombre: pdiEditar.nombre,
            bloque: pdiEditar.bloque.id,
            icono: pdiEditar.icono.id,
            servicios: serviciosSeleccionados.map(s => s.id)
        });
        this.hide();
    }

    hide() {
        const {setPdiEditar, setVisibleModalEditarServicios} = this.props;
        setPdiEditar(null);
        setVisibleModalEditarServicios(false);
    }
}

const mapStateToProps = state => ({
    bloqueSeleccionado: getBloqueSeleccionado(state),
    servicios: getServicios(state),
    serviciosSeleccionados: getServiciosSeleccionados(state),
    proyecto: getProyecto(state),
    puntos: getPuntos(state),
    pdiEditar: getPdiEditar(state),
    filtroAnadirElementos: getFiltroAnadirElemento(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalAnadirServicios: actions.setVisibleModalAnadirServicios,
    addServicioSeleccionado: actions.addServicioSeleccionado,
    removeServicioSeleccionado: actions.removeServicioSeleccionado,
    resetServiciosSeleccionado: actions.resetServiciosSeleccionado,
    creaNuevoPunto: creaNuevoPunto,
    setVisibleModalEditarServicios: actions.setVisibleModalEditarServicios,
    setPdiEditar: actions.setPdiEditar,
    actualizaPunto: actualizaPunto,
    setFiltroAnadirElementos: actions.setFiltroAnadirElementos
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalEditarServicios));
