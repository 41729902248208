import React, {useState} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import TextoAgrupacion from "./TextoAgrupacion";
import {Card} from "react-bootstrap";

const ItemAgrupacion = props => {
    const {agrupacion, indice, reordenaAgrupaciones, agrupa, multiple, ordenarElementos, desagrupa, elementoIsVisible,
        onClickLiElemento, onClickVisiblidadElemento, agrupacionIsVisible, onClickVisibilidadAgrupacion, puntos,
        onToggleAgrupacion, onDoubleClickAgrupacion, isSeleccionado} = props;
    const [, setHasDropped] = useState(false);
    const [, setHasDroppedOnChild] = useState(false);

    const [, drag] = useDrag({
        item: {
            type: 'fila' + agrupacion.elementos[0].bloque.capa.id,
            id: indice,
            agrupacion: agrupacion
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
    });
    const [, drop] = useDrop({
        accept: ['fila' + agrupacion.elementos[0].bloque.capa.id, 'fila-elemento'],
        drop: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if(didDrop) return;

            if(item.id !== indice && item.type === 'fila'+ agrupacion.elementos[0].bloque.capa.id) {
                reordenaAgrupaciones(item.id, indice);
                setHasDropped(true);
                setHasDroppedOnChild(didDrop)
            } else if(item.type === 'fila-elemento') {
                desagrupa(item.elemento, indice);
            }
        },
    });

    return <Card ref={node=>drag(drop(node))} className={'titulo-agrupacion'}
               onClick={multiple ? undefined : () => onClickLiElemento(agrupacion.elementos[0])}>
        <TextoAgrupacion indice={indice} nombre={multiple ? agrupacion.nombre : agrupacion.elementos[0].nombre}
                         agrupa={agrupa} multiple={multiple} elementos={agrupacion.elementos}
                         ordenarElementos={ordenarElementos} onClickLiElemento={onClickLiElemento}
                         elementoIsVisible={elementoIsVisible} onClickVisiblidadElemento={onClickVisiblidadElemento}
                         agrupacionIsVisible={agrupacionIsVisible} agrupacion={multiple ? agrupacion : null}
                         onClickVisibilidadAgrupacion={multiple ? onClickVisibilidadAgrupacion : null}
                         onToggleAgrupacion={onToggleAgrupacion} onDoubleClickAgrupacion={onDoubleClickAgrupacion}
                         isSeleccionado={isSeleccionado} puntos={puntos}
        />
    </Card>
};

export default ItemAgrupacion;
