import React, { Component } from "react"
import { connect } from 'react-redux'
import { withTranslation, Trans } from 'react-i18next'

import ModalEditor from "./ModalEditor"
import { actions } from "../../actions/editor"
import { deleteMapaTrafico } from "../../fetchactions/trafico"

class ModalEliminarMapaTrafico extends Component {
    handleClickAceptar () {
        const { dispatch, traficoEliminar } = this.props
        dispatch(deleteMapaTrafico(traficoEliminar))
        this.hide()
    }

    handleClickCancelar () {
        this.hide()
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalEliminarTrafico(false))
        dispatch(actions.setVisibleModalTrafico(true))
    }

    render() {
        const { t, traficoEliminar } = this.props
        return (
            <ModalEditor
                footer
                show
                onClickAceptar={this.handleClickAceptar.bind(this)}
                onClickCancelar={this.handleClickCancelar.bind(this)}
                onHide={this.handleClickCancelar.bind(this)}
                titulo={t('Eliminar el mapa de tráfico') + ': ' + traficoEliminar.nombre}
            >
                <div className='row'>
                    <div className='col-12'>
                        <p><Trans>¿Está seguro de querer eliminar este mapa de tráfico?</Trans></p>
                    </div>
                </div>
            </ModalEditor>
        )
    }
}

const mapStateToProps = state => ({
    traficoEliminar: state.editor.traficoEliminar
})

export default withTranslation()(connect(mapStateToProps)(ModalEliminarMapaTrafico))
