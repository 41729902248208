import {API_BASE_URL} from "../constants/api";
import {actions as actionsComun} from '../actions/comun';
import {actions as actionsEditor} from '../actions/editor';
import {actions} from '../actions/capasFondo';

export function fetchCapasFondo() {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.fetchCapasFondoPendiente());
        fetch(API_BASE_URL + 'capafondo/')
            .then( res => res.json())
            .then( res => {
                if(res.error) {
                    throw(res.error);
                }
                for(let i in res) {
                    if(!res[i].transparencia && res[i].por_defecto) {
                        dispatch(actionsEditor.setCapaFondo(res[i]))
                    } else if(res[i].transparencia && res[i].por_defecto) {
                        dispatch(actionsEditor.addCapaTransparente(res[i]))
                    }
                }
                dispatch(actions.fetchCapasFondoCorrecto(res));
                dispatch(actionsComun.restaPeticion());
                return res;
            })
            .catch(error => {
                dispatch(actions.fetchCapasFondoError(error));
                dispatch(actionsComun.restaPeticion())
            })
    }
}
