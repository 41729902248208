import React from 'react';
import {useDrag, useDrop} from "react-dnd";

const FilaDragRecorridos = (props) => {
    const {recorrido, reordena, onClickEliminar, eliminado} = props;
    const id = recorrido.orden;
    const [, drag] = useDrag({
        item: { type: 'fila', id },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
    })
    const [, drop] = useDrop({
        accept: 'fila',
        drop({ id: draggedId }) {
            if (draggedId !== id) {
                reordena(id, draggedId)
            }
        }
    })
    return (
        <li
            ref={eliminado ? () => {} : node=>drag(drop(node))}
            className={eliminado
                ? 'fila-eliminado list-group-item d-flex justify-content-between align-items-center'
                : 'list-group-item d-flex justify-content-between align-items-center'
            }
        >
            <span>
                <span>
                    {eliminado
                    ? <i className='fas fa-minus mr-3' />
                    : <i className='fas fa-arrows-alt mr-3' />}
                </span>
                {recorrido.nombre}
                {recorrido.es_pagado &&
                    <span> *</span>}
            </span>
            <div className='btn-toolbar' role='toolbar'>
                <div className='btn-group' role='group'>
                    <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={e => onClickEliminar(recorrido)}
                        disabled={recorrido.es_pagado}
                    >
                        {eliminado
                            ? <i className='fas fa-trash-restore-alt' />
                            : <i className='fas fa-trash' />}
                    </button>
                </div>
            </div>
        </li>
    )
};

export default FilaDragRecorridos;
