import React from 'react'
import moment from 'moment'
import {connect} from "react-redux"
import {Row, Col} from "react-bootstrap"
import {withTranslation, Trans} from "react-i18next"
import {ElementsConsumer} from "@stripe/react-stripe-js"

import ModalEditor from "./ModalEditor"
import {actions as carritoActions} from "../../actions/carrito";
import {carritoFetchCustomer} from "../../fetchactions/carrito";
import {fetchRecorridos} from "../../fetchactions/recorridos";
import SeccionTarjeta from "./pago/SeccionTarjeta";
import clickPagar from './pago/utils'
import {STRIPE_PRECIO_SUBSCRIPCION_PVP} from "../../constants/api";
import {ImgMapaFallback} from './widgets/ImgFallback'

import './ModalSubscripcion.css'
import PagoCorrecto from "./pago/PagoCorrecto";
import PagoError from "./pago/PagoError";


class ModalSubscripcion extends React.Component {

    componentDidMount() {
        const {dispatch} = this.props

        dispatch(carritoFetchCustomer())
    }

    onHide() {
        const {carrito, dispatch} = this.props
        dispatch(carritoActions.carritoModalVisible(!carrito.modalVisible))
    }

    onClickAceptar() {
    }

    onClickCancelar() {
        const {carrito, dispatch} = this.props
        dispatch(carritoActions.carritoModalVisible(!carrito.modalVisible))
    }

    onSelectRecorrido(recorrido) {
        const {dispatch, carrito} = this.props
        if (carrito.recorridos.indexOf(recorrido.id) === -1) {
            const recorridos = carrito.recorridos
            recorridos.push(recorrido.id)
            dispatch(carritoActions.carritoUpdateRecorridos(
                recorridos
            ))
        } else {
            const recorridos = carrito.recorridos
            recorridos.splice(recorridos.indexOf(recorrido.id), 1);
            dispatch(carritoActions.carritoUpdateRecorridos(
                recorridos
            ))
        }
    }

    onClickClose() {
        const {dispatch, mapas} = this.props
        // Borrar errores, etc...
        dispatch(carritoActions.carritoModalVisible(false))
        dispatch(carritoActions.carritoReset())
        dispatch(fetchRecorridos(mapas[0].id))
    }

    onEndClickBack() {
        const {dispatch, mapas} = this.props
        // Borrar errores, etc...
        dispatch(carritoActions.carritoReset())
        dispatch(fetchRecorridos(mapas[0].id))
    }

    render() {
        const {t, recorridos, carrito, stripe, elements, show} = this.props;
        const onSelectRecorrido = this.onSelectRecorrido.bind(this);
        const importeTotal = (STRIPE_PRECIO_SUBSCRIPCION_PVP * carrito.recorridos.length).toFixed(2);

        return <ModalEditor
            show={show}
            onHide={this.onHide.bind(this)}
            onClickAceptar={this.onClickAceptar.bind(this)}
            onClickCancelar={this.onClickCancelar.bind(this)}
            titulo={t('Subscripciones')}
        >
            {carrito.pagoCorrecto ? (
                <PagoCorrecto onClickBack={this.onClickClose.bind(this)}/>
            ): (
                carrito.pagoErrorDesc ? (
                    <PagoError onClickBack={this.onEndClickBack.bind(this)} errorDesc={carrito.pagoErrorDesc}/>
                ) : (
                    <Row className={'modal-subs'}>
                        <Col>
                            <div className={'modal-subs__table-wrapper'}>
                                <table className={'modal-subs__table'}>
                                    {recorridos.list && recorridos.list.map(recorrido => {
                                        let distancia = Math.round(
                                            recorrido.sectores.reduce((total, s) => total + s.distancia_metros, 0.0)
                                        )
                                        // console.info(recorrido.subscripcion_activa)
                                        // console.info(carrito.recorridos)
                                        const selected = carrito.recorridos &&  carrito.recorridos.indexOf(recorrido.id) !== -1

                                        let trStyle = {};
                                        let trOnClick = () => {};
                                        if (!recorrido.subscripcion_activa) {
                                            trStyle = {cursor: 'pointer'};
                                            trOnClick = () => { onSelectRecorrido(recorrido) };
                                        }

                                        return <tr
                                            key={recorrido.id} className={'modal-subs__row'}
                                            style={trStyle} onClick={trOnClick}
                                        >
                                            <td className={'modal-subs__cell'}>
                                                <ImgMapaFallback alt={recorrido.nombre}
                                                    src={recorrido.captura}
                                                    width={80} className={'modal-subs__img'}
                                                />
                                            </td>
                                            <td className={'modal-subs__cell'}>
                                                <h4>{recorrido.nombre}</h4>
                                                <Trans>
                                                    DISTANCIA TOTAL:<b>{{distancia}} M</b>
                                                </Trans> | <Trans>
                                                    CREADO:
                                                </Trans> <b>{ moment(recorrido.date_add).format('L') }</b> | <Trans>
                                                    MODIFICADO:
                                                </Trans> <b>{ moment(recorrido.date_mod).format('L') }</b>


                                            </td>
                                            <td className={'modal-subs__cell'}>
                                                {recorrido.subscripcion_activa ? (
                                                    <div className={'modal-subs__info-btn'}>
                                                        Publicado Hasta
                                                        <div className={'modal-subs__info-plazo'}>
                                                            {moment(recorrido.subscripcion_activa.fin).format('L')}
                                                        </div>
                                                    </div>
                                                ): (
                                                    <button
                                                        /*onClick={() => onSelectRecorrido(recorrido)}*/
                                                        className={`modal-subs__btn ${selected? 'modal-subs__btn--selected' : '' }`}>
                                                        Publicar por un año
                                                        <div className={'modal-subs__price'}>
                                                            {(STRIPE_PRECIO_SUBSCRIPCION_PVP * 100 / 100).toFixed(2)} €
                                                        </div>
                                                    </button>
                                                )}

                                            </td>
                                        </tr>
                                    })}
                                </table>
                            </div>
                            <div >
                                <form onSubmit={(evt) => clickPagar(stripe, elements, evt)} className={"modal-subs__form stripe__form stripe__form--subs"}>
                                    <SeccionTarjeta stripe={stripe}/>
                                    {carrito.stripeCardError &&
                                        <div className={'modal-subs__card-error'}>{carrito.stripeCardError}</div>
                                    }
                                    <button disabled={importeTotal === '0.00' || (!stripe || carrito.procesando)}>
                                        {carrito.procesando ? (
                                            <Trans>Procesando pago...</Trans>
                                        ) : (
                                            <Trans>Pagar <span className={'modal-subs__importeTotal'}>{importeTotal}</span> € y publicar</Trans>
                                        )}
                                    </button>
                                </form>
                            </div>
                        </Col>
                    </Row>
                )
            )}
        </ModalEditor>
    }
}

const mapStateToProps = state => ({
    proyecto: state.proyecto,
    recorridos: state.recorridos,
    carrito: state.carrito
})

const ModalSubscripcionCnn = withTranslation()(connect(mapStateToProps)(ModalSubscripcion))


const InjectedModalSubscripcion = (props) => {
  return (
    <ElementsConsumer >
      {({elements, stripe}) => (
        <ModalSubscripcionCnn elements={elements} stripe={stripe} {...props}/>
      )}
    </ElementsConsumer>
  );
};

export default InjectedModalSubscripcion;
