import { connect } from "react-redux"
import { withTranslation } from "react-i18next"

import { ModalNuevosVoluntarios } from "./ModalNuevosVoluntarios"
import { actions } from "../../actions/editor"
import { updateVoluntarios } from "../../fetchactions/voluntarios"

class ModalEditarVoluntarios extends ModalNuevosVoluntarios {
    componentDidMount () {
        this.setState({
            filtroElementos: ''
        })
    }

    handleClickAceptar () {
        const { dispatch, voluntariosEditando, voluntarios } = this.props
        if (this.valida()) {
            const prevMapa = voluntarios.filter(v => v.id === voluntariosEditando.id)
            dispatch(updateVoluntarios(
                voluntariosEditando.id,
                {
                    nombre: voluntariosEditando.nombre,
                    capa_fondo: voluntariosEditando.capa_fondo,
                    descripcion: voluntariosEditando.descripcion,
                    meta_descripcion: voluntariosEditando.meta_descripcion,
                    visible: voluntariosEditando.visible,
                    visible_todos: voluntariosEditando.visible_todos
                },
                prevMapa.length === 1 ? prevMapa[0].elementos_voluntarios : null,
                voluntariosEditando.elementos_voluntarios
            ))
            this.hide()
            dispatch(actions.setVisibleModalVoluntarios(true))
        }
    }

    hide() {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalEditarVoluntarios(false))
    }
}

const mapStateToProps = state => ({
    proyecto: state.proyecto.data,
    voluntariosEditando: state.voluntariosEditando,
    capasFondo: state.capasFondo.list,
    tiposMapas: state.tiposMapas.data,
    trayectos: state.trayectos.present.list,
    puntos: state.puntos.list,
    poligonos: state.poligonos.list,
    lineas: state.lineas.list,
    voluntarios: state.voluntarios.data,
    agrupaciones: state.agrupacionesProyecto.list
})

export default withTranslation()(connect(mapStateToProps)(ModalEditarVoluntarios))
