import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Button, Col, Row, Form, Popover, OverlayTrigger} from "react-bootstrap";

import ModalEditor from "./ModalEditor";
import {actions} from "../../actions/editor";
import {getBloqueSeleccionado, getFiltroAnadirElemento, getServiciosSeleccionados} from "../../reducers/editor";
import {getServicios} from "../../reducers/servicios";
import {getProyecto} from "../../reducers/proyectos";
import {creaNuevoPunto} from "../../fetchactions/puntos";
import {getPuntos} from "../../reducers/puntos";

export class ModalSelectorServicios extends Component {

    constructor(props) {
        super(props);

        this.onClickAceptar = this.onClickAceptar.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onChangeFiltro = this.onChangeFiltro.bind(this);
    }

    componentDidMount() {
        const {setFiltroAnadirElementos, resetServiciosSeleccionado} = this.props;
        resetServiciosSeleccionado()
        setFiltroAnadirElementos('');
    }

    onClickAceptar() {
        const {creaNuevoPunto, bloqueSeleccionado, proyecto, serviciosSeleccionados} = this.props;
        creaNuevoPunto({
            nombre: this.getNombreSiguientePunto(bloqueSeleccionado),
            color: bloqueSeleccionado.props_defecto.color ? bloqueSeleccionado.props_defecto.color: '#ffff00',
            color_icono: bloqueSeleccionado.props_defecto.color_icono ? bloqueSeleccionado.props_defecto.color_icono : '#000000',
            bloque: bloqueSeleccionado.id,
            icono: bloqueSeleccionado.props_defecto.icono_id ? bloqueSeleccionado.props_defecto.icono_id : null,
            proyecto: proyecto.id,
            servicios: serviciosSeleccionados.map(serv => serv.id)
        });
        this.hide();
    }

    getNombreSiguientePunto(bloque) {
        const {puntos} = this.props;
        let nombre;
        let contador = 1;
        while (!nombre) {
            const cont = contador;
            if (puntos.filter(punto => punto.nombre === bloque.nombre + cont).length === 0) {
                nombre = bloque.nombre + cont;
            }
            contador++;
        }
        return nombre;
    }

    onHide() {
        this.hide();
    }

    hide() {
        const {setVisibleModalAnadirServicios} = this.props;
        setVisibleModalAnadirServicios(false);
    }

    estaSeleccionado(servicio) {
        const {serviciosSeleccionados} = this.props;
        return serviciosSeleccionados.filter(serv => serv.id === servicio.id).length > 0
    }

    onClickServicio(servicio) {
        const {addServicioSeleccionado, removeServicioSeleccionado} = this.props;
        if(this.estaSeleccionado(servicio)) removeServicioSeleccionado(servicio);
        else addServicioSeleccionado(servicio);
    }

    onChangeFiltro(e) {
        const {setFiltroAnadirElementos} = this.props;
        setFiltroAnadirElementos(e.target.value);
    }

    render() {
        const { show, t, servicios, bloqueSeleccionado, filtroAnadirElementos } = this.props
        return <ModalEditor className='diag-add-elemento' show={show} onHide={this.onHide} footer
                     titulo={t('anadir-servicios')} onClickAceptar={this.onClickAceptar} onClickCancelar={this.onHide}>
            <Row>
                <Col>{t('msg-anadir-servicios')}</Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="filter-elementos">
                        <Form.Control type="text" placeholder={t('filtrar')} value={filtroAnadirElementos}
                                      onChange={this.onChangeFiltro}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                    {bloqueSeleccionado && bloqueSeleccionado.es_pk ?
                        t('msg-servicios-pks') + t('msg-servicios-pdis') : t('msg-servicios-pdis')}
                    </p>
                </Col>
            </Row>
            <Row className='container-btns-bibio'>
                {servicios.filter(s =>
                    new RegExp([filtroAnadirElementos].join('|'), 'i').test(s.nombre)).map(servicio =>
                    <Col className={'btn-biblio btn-biblio-servicios px-2' + (this.estaSeleccionado(servicio) ?
                        ' btn-biblio-servicio-seleccionado' : '' )} key={servicio.id} md={2} xs={6}>
                        {servicio.descripcion
                            ? (
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Popover>
                                            <Popover.Content>
                                                {servicio.descripcion}
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <Button onClick={() => this.onClickServicio(servicio)} variant="dark">
                                        <div>
                                            <i className={servicio.icono_descripcion} />
                                        </div>
                                        <div>{servicio.nombre}</div>
                                    </Button>
                                </OverlayTrigger>
                            ) : (
                                <Button onClick={() => this.onClickServicio(servicio)} variant="dark">
                                    <div>
                                        <i className={servicio.icono_descripcion} />
                                    </div>
                                    <div>{servicio.nombre}</div>
                                </Button>
                            )}
                    </Col>
                )}
            </Row>
        </ModalEditor>
    }
}


const mapStateTopProps = state => ({
    bloqueSeleccionado: getBloqueSeleccionado(state),
    servicios: getServicios(state),
    serviciosSeleccionados: getServiciosSeleccionados(state),
    proyecto: getProyecto(state),
    puntos: getPuntos(state),
    filtroAnadirElementos: getFiltroAnadirElemento(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalAnadirServicios: actions.setVisibleModalAnadirServicios,
    addServicioSeleccionado: actions.addServicioSeleccionado,
    removeServicioSeleccionado: actions.removeServicioSeleccionado,
    resetServiciosSeleccionado: actions.resetServiciosSeleccionado,
    creaNuevoPunto: creaNuevoPunto,
    setFiltroAnadirElementos: actions.setFiltroAnadirElementos
}, dispatch);

export default withTranslation()(connect(mapStateTopProps, mapDispatchToProps)(ModalSelectorServicios));
