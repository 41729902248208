import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Form, Row} from "react-bootstrap";

import {getRecorridoCreando} from "../../reducers/recorridos";
import {getCapasFondo} from "../../reducers/capasFondo";
import actions from "../../actions/recorridos";

class TabAvanzadoRecorrido extends Component {

    constructor(props) {
        super(props);

        this.onChangeUnidad = this.onChangeUnidad.bind(this);
        this.onChangeCapa = this.onChangeCapa.bind(this);
    }

    onChangeUnidad(e){
        const {cambiarUnidadDistancia} = this.props;
        cambiarUnidadDistancia(e.target.value);
    }

    onChangeCapa(e) {
        const {cambiarCapaFondoRecorrido} = this.props;
        cambiarCapaFondoRecorrido(parseInt(e.target.value));
    }

    render() {
        const {t, recorridoCreando, capasFondo, refUnidadesDistancia} = this.props;
        return [
            <Row key={1}>
                <Col>
                    <Form.Group as={Row} controlId='fondo-recorido'>
                        <Form.Label column sm={3} md={2}>{t('fondo_mapa') + ':'}</Form.Label>
                        <Col sm={9} md={10}>
                            <Form.Control name='capa_fondo' as='select' onChange={this.onChangeCapa}
                                          defaultValue={recorridoCreando.capa_fondo && recorridoCreando.capa_fondo.id ?
                                              recorridoCreando.capa_fondo.id : recorridoCreando.capa_fondo}>
                                {capasFondo.map(capa => !capa.transparencia &&
                                    <option key={capa.id} value={capa.id}>{capa.nombre}</option>
                                )}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Col>
                {/*<Col>*/}
                {/*    <Form.Row>*/}
                {/*        <Form.Check type='checkbox' id='fondo-etiquetas'>*/}
                {/*            <Form.Check.Input name='etiquetas' type='checkbox'*/}
                {/*                              defaultChecked={recorridoCreando.etiquetas}/>*/}
                {/*            <Form.Check.Label>{t('etiquetas')}</Form.Check.Label>*/}
                {/*        </Form.Check>*/}
                {/*    </Form.Row>*/}
                {/*</Col>*/}
            </Row>,
            <Row key={2}>
                <Form.Group as={Col} controlId='superficie-nuevo-recorrido'>
                    <Form.Label>{t('superficie') + ':'}</Form.Label>
                    <Form.Control name='superficie' defaultValue={recorridoCreando.superficie}
                                  placeholder={t('introducir-superficie-recorrido')}/>
                </Form.Group>
                <Form.Group controlId='dificultad-recorido'>
                    <Form.Label>{t('dificultad') + ':'}</Form.Label>
                    <Form.Control defaultValue={recorridoCreando.dificultad ? recorridoCreando.dificultad : ''}
                                  name='dificultad' as='select'>
                        <option value='' disabled={true}>{t('seleccione-dificultad')}</option>
                        <option value='mf'>{t('muy-facil')}</option>
                        <option value='f'>{t('facil')}</option>
                        <option value='m'>{t('moderado')}</option>
                        <option value='d'>{t('dificil')}</option>
                        <option value='md'>{t('muy-dificil')}</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId='unidad-distancia-recorrido'>
                    <Form.Label>{t('unidad_distancia') + ':'}</Form.Label>
                    <Form.Control onChange={this.onChangeUnidad}
                        defaultValue={recorridoCreando.unidad_distancia ? recorridoCreando.unidad_distancia : 'm'}
                        name='unidad_distancia' as='select' ref={refUnidadesDistancia}>
                        <option value='m'>{t('metros')}</option>
                        <option value='km'>{t('kilometros')}</option>
                        <option value='mi'>{t('millas')}</option>
                        <option value='ft'>{t('pies')}</option>
                    </Form.Control>
                </Form.Group>
            </Row>,
            <Row key={3}>
                <Form.Group as={Col} controlId='descripcion-nuevo-recorrido'>
                    <Form.Label>{t('descripcion-tecnica') + ':'}</Form.Label>
                    <Form.Control name='descripcion' as='textarea'
                                  defaultValue={recorridoCreando.descripcion}
                                  placeholder={t('introducir-descripcion-recorrido')}/>
                </Form.Group>
            </Row>,
            <Row key={4}>
                <Form.Group as={Col} controlId='meta-descripcion-nuevo-recorrido'>
                    <Form.Label>{t('meta-descripcion') + ':'}</Form.Label>
                    <Form.Control name='meta_descripcion' as='textarea'
                        defaultValue={recorridoCreando.meta_descripcion}
                        placeholder={t('introducir-meta-descripcion-recorrido')}
                    />
                </Form.Group>
            </Row>
        ]
    }
}

const mapStateToProps = state => ({
    recorridoCreando: getRecorridoCreando(state),
    capasFondo: getCapasFondo(state)
});

const mapDispatchToProps = disptach => bindActionCreators({
    cambiarUnidadDistancia: actions.cambiarUnidadDistancia,
    cambiarCapaFondoRecorrido: actions.cambiarCapaFondoRecorrido
}, disptach);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TabAvanzadoRecorrido));
