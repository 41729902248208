import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Row, Col} from "react-bootstrap";

import ModalEditor from "./ModalEditor";
import {actions} from "../../actions/editor";
import {actions as actionsRecorridos} from "../../actions/recorridos";
import {getSectorBorrarPks} from "../../reducers/editor";
import {actualizaSector} from "../../fetchactions/sectores";
import {eliminarPunto} from "../../fetchactions/puntos";


class ModalBorrarPks extends Component {

    constructor(props) {
        super(props);

        this.onClickAceptar = this.onClickAceptar.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onClickAceptar(){
        const {sectorBorrarPks, eliminarPunto, actualizaSector, removePkPorAnadir,
            eliminaPksRecorridoCreando} = this.props;
        actualizaSector(sectorBorrarPks.id, {
            nombre: sectorBorrarPks.nombre,
            recorrido: sectorBorrarPks.recorrido,
            orden: sectorBorrarPks.orden,
            puntos_km: sectorBorrarPks.puntos_km.filter(pk => !pk.punto.semiautomatico),
            puntos_km_auto: false
        }, sectorBorrarPks.recorrido, sectorBorrarPks.esquemas, sectorBorrarPks.esquemas,
            sectorBorrarPks.puntos_km.filter(pk => !pk.punto.semiautomatico), true);
        for(let i in sectorBorrarPks.puntos_km){
            if(sectorBorrarPks.puntos_km[i].punto.semiautomatico)
                removePkPorAnadir(sectorBorrarPks.puntos_km[i].punto, sectorBorrarPks);
                eliminarPunto(sectorBorrarPks.puntos_km[i].punto);
        }
        eliminaPksRecorridoCreando(sectorBorrarPks);
        this.hide();
    }

    onHide() {
        this.hide();
    }

    hide() {
        const {setVisibleModalBorrarPks} = this.props;
        setVisibleModalBorrarPks(false);
    }

    render() {
        const {t, show, sectorBorraPks} = this.props;
        return <ModalEditor show={show} onHide={this.onHide} footer onClickAceptar={this.onClickAceptar}
                            onClickCancelar={this.onHide} titulo={t('titulo-borrar-pks')}>
            <Row>
                <Col>{t('msg-confimacion-borrar-pks') + ' ' +  (sectorBorraPks ? sectorBorraPks.nombre : '') + '?'}</Col>
            </Row>
        </ModalEditor>
    }
}

const mapStateToProps = state => ({
    sectorBorrarPks: getSectorBorrarPks(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalBorrarPks: actions.setVisibleModalBorrarPks,
    eliminarPunto: eliminarPunto,
    actualizaSector: actualizaSector,
    removePkPorAnadir: actions.removePkPorAnadir,
    eliminaPksRecorridoCreando: actionsRecorridos.eliminaPksRecorridoCreando
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalBorrarPks));
