export const ACTIONS_TYPES = {
    ACTUALIZA_AGRUPACION_CORRECTO: 'ACTUALIZA_AGRUPACION_CORRECTO',
    ACTUALIZA_AGRUPACION_ERROR: 'ACTUALIZA_AGRUPACION_ERROR',
    ACTUALIZA_AGRUPACION_PENDIENTE: 'ACTUALIZA_AGRUPACION_PENDIENTE',
    ACTUALIZA_ELEMENTO_AGRUPACION: 'ACTUALIZA_ELEMENTO_AGRUPACION',
    ACTUALIZA_ELEMENTO_CORRECTO: 'ACTUALIZA_ELEMENTO_CORRECTO',
    ACTUALIZA_ELEMENTO_ERROR: 'ACTUALIZA_ELEMENTO_ERROR',
    ACTUALIZA_ELEMENTO_PENDIENTE: 'ACTUALIZA_ELEMENTO_PENDIENTE',
    ADD_AGRUPACION: 'ADD_AGRUPACION',
    CAMBIA_ORDENES_AGRUPACIONES: 'CAMBIA_ORDENES_AGRUPACIONES',
    ELIMINA_AGRUPACION_CORRECTO: 'ELIMINA_AGRUPACION_CORRECTO',
    ELIMINA_AGRUPACION_ERROR: 'ELIMINA_AGRUPACION_ERROR',
    ELIMINA_AGRUPACION_PENDIENTE: 'ELIMINA_AGRUPACION_PENDIENTE',
    ELIMINA_ELEMENTO_AGRUPACION: 'ELIMINA_ELEMENTO_AGRUPACION',
    FETCH_AGRUPACIONES_PROYECTO_CORRECTO: 'FETCH_AGRUPACIONES_PROYECTO_CORRECTO',
    FETCH_AGRUPACIONES_PROYECTO_ERROR: 'FETCH_AGRUPACIONES_PROYECTO_ERROR',
    FETCH_AGRUPACIONES_PROYECTO_PENDIENTE: 'FETCH_AGRUPACIONES_PENDIENTE',
    FETCH_AGRUPACIONES_OTRO_PROYECTO_PENDIENTE: 'FETCH_AGRUPACIONES_OTRO_PROYECTO_PENDIENTE',
    FETCH_AGRUPACIONES_OTRO_PROYECTO_CORRECTO: 'FETCH_AGRUPACIONES_OTRO_PROYECTO_CORRECTO',
    FETCH_AGRUPACIONES_OTRO_PROYECTO_ERROR: 'FETCH_AGRUPACIONES_OTRO_PROYECTO_ERROR',
    NUEVA_AGRUPACION_CORRECTO: 'NUEVA_AGRUPACION_CORRECTO',
    NUEVA_AGRUPACION_ERROR: 'NUEVA_AGRUPACION_ERROR',
    NUEVA_AGRUPACION_PENDIENTE: 'NUEVA_AGRUPACION_PENDIENTE'
};

function fetchAgrupacionesProyectoCorrecto(agrupaciones) {
    return{
        type: ACTIONS_TYPES.FETCH_AGRUPACIONES_PROYECTO_CORRECTO,
        agrupaciones: agrupaciones
    };
}

function fetchAgrupacionesProyectoError(err) {
    return{
        type: ACTIONS_TYPES.FETCH_AGRUPACIONES_PROYECTO_ERROR,
        error: err
    }
}

function fetchAgrupacionesProyectoPendiente() {
    return{
        type: ACTIONS_TYPES.FETCH_AGRUPACIONES_PROYECTO_PENDIENTE
    }
}

function addAgrupacion(agrupacion) {
    return {
        type: ACTIONS_TYPES.ADD_AGRUPACION,
        agrupacion: agrupacion
    }
}

function actualizaElementoAgruapcion(elemento) {
    return {
        type: ACTIONS_TYPES.ACTUALIZA_ELEMENTO_AGRUPACION,
        elemento: elemento
    }
}

function cambiaOrdenesAgrupaciones(aCambiar) {
    return {
        type: ACTIONS_TYPES.CAMBIA_ORDENES_AGRUPACIONES,
        aCambiar: aCambiar
    }
}

function actualizaAgrupacionCorrecto(agrupacion) {
    return {
        type: ACTIONS_TYPES.ACTUALIZA_AGRUPACION_CORRECTO,
        agrupacion: agrupacion
    };
}

function actualizaAgrupacionError(err) {
    return {
        type: ACTIONS_TYPES.ACTUALIZA_AGRUPACION_ERROR,
        error: err
    };
}

function actualizaAgrupacionPendiente() {
    return {
        type: ACTIONS_TYPES.ACTUALIZA_AGRUPACION_PENDIENTE,
    };
}

function nuevaAgrupacionCorrecto(agrupacion) {
    return {
        type: ACTIONS_TYPES.NUEVA_AGRUPACION_CORRECTO,
        agrupacion: agrupacion
    };
}

function nuevaAgrupacionError(err) {
    return {
        type: ACTIONS_TYPES.NUEVA_AGRUPACION_ERROR,
        error: err
    };
}

function nuevaAgrupacionPendiente() {
    return {
        type: ACTIONS_TYPES.NUEVA_AGRUPACION_PENDIENTE
    };
}

function eliminaAgrupacionCorrecto(agrupacion) {
    return {
        type: ACTIONS_TYPES.ELIMINA_AGRUPACION_CORRECTO,
        agrupacion: agrupacion
    };
}

function eliminaAgrupacionError(err) {
    return {
        type: ACTIONS_TYPES.ELIMINA_AGRUPACION_ERROR,
        error: err
    };
}

function eliminaAgrupacionPendiente(agrupacion) {
    return {
        type: ACTIONS_TYPES.ELIMINA_AGRUPACION_PENDIENTE,
        agrupacion: agrupacion
    };
}

function actualizaElementoCorrecto(elemento) {
    return {
        type: ACTIONS_TYPES.ACTUALIZA_ELEMENTO_CORRECTO,
        elemento: elemento
    };
}

function actualizaElementoPendiente() {
    return {
        type: ACTIONS_TYPES.ACTUALIZA_AGRUPACION_PENDIENTE
    };
}

function actualizaElementoError(err) {
    return {
        type: ACTIONS_TYPES.ACTUALIZA_AGRUPACION_ERROR,
        error: err
    };
}

function eliminaElementoAgrupacion(idElemento, idAgrupacion) {
    return {
        type: ACTIONS_TYPES.ELIMINA_ELEMENTO_AGRUPACION,
        idElemento: idElemento,
        idAgrupacion: idAgrupacion
    };
}

function fetchAgrupacionesOtroProyectoPendiente() {
    return {
        type: ACTIONS_TYPES.FETCH_AGRUPACIONES_OTRO_PROYECTO_PENDIENTE
    }
}

function fetchAgrupacionesOtroProyectoCorrecto(agrupaciones) {
    return {
        type: ACTIONS_TYPES.FETCH_AGRUPACIONES_OTRO_PROYECTO_CORRECTO,
        agrupaciones
    }
}

function fetchAgrupacionesOtroProyectoError(error) {
    return {
        type: ACTIONS_TYPES.FETCH_AGRUPACIONES_OTRO_PROYECTO_ERROR,
        error
    }
}

export const actions = {
    fetchAgrupacionesProyectoCorrecto: fetchAgrupacionesProyectoCorrecto,
    fetchAgrupacionesProyectoError: fetchAgrupacionesProyectoError,
    fetchAgrupacionesProyectoPendiente: fetchAgrupacionesProyectoPendiente,
    addAgrupacion: addAgrupacion,
    actualizaElementoAgruapcion: actualizaElementoAgruapcion,
    cambiaOrdenesAgrupaciones: cambiaOrdenesAgrupaciones,
    actualizaAgrupacionCorrecto: actualizaAgrupacionCorrecto,
    actualizaAgrupacionError: actualizaAgrupacionError,
    actualizaAgrupacionPendiente: actualizaAgrupacionPendiente,
    nuevaAgrupacionPendiente: nuevaAgrupacionPendiente,
    nuevaAgrupacionCorrecto: nuevaAgrupacionCorrecto,
    nuevaAgrupacionError: nuevaAgrupacionError,
    eliminaAgrupacionCorrecto: eliminaAgrupacionCorrecto,
    eliminaAgrupacionError: eliminaAgrupacionError,
    eliminaAgrupacionPendiente: eliminaAgrupacionPendiente,
    actualizaElementoPendiente: actualizaElementoPendiente,
    actualizaElementoCorrecto: actualizaElementoCorrecto,
    actualizaElementoError: actualizaElementoError,
    eliminaElementoAgrupacion: eliminaElementoAgrupacion,
    fetchAgrupacionesOtroProyectoCorrecto,
    fetchAgrupacionesOtroProyectoError,
    fetchAgrupacionesOtroProyectoPendiente
}
