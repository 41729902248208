/* global fetch */
import { actions } from "../actions/tiposMapa"
import { actions as actionsComun } from '../actions/comun'
import {API_BASE_URL} from "../constants/api";

export default function fetchTiposMapas (params = {}, fields = []) {
    return dispatch => {
        dispatch(actions.fetchTiposMapasBegin())
        dispatch(actionsComun.sumaPeticion())
        let url = new URL(API_BASE_URL + 'tipomapa/')
        if (fields && fields.length) {
            params['fields'] = fields.join(',')
        }
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )
        return fetch(url, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw res.error
                } else if (res && res.length) {
                    dispatch(actions.fetchTiposMapasSuccess(res))
                    dispatch(actionsComun.restaPeticion())
                } else {
                    throw new Error('Not found')
                }
            })
            .catch(err => {
                dispatch(actions.fetchTiposMapasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}
