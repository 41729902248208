
import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/sectores";

export function sectorReducer(state=initialState.sector, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_SECTOR_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: false
            }
        case ACTION_TYPES.FETCH_SECTOR_ERROR:
            return {
                ...state,
                data: null,
                pendiente: false,
                error: action.error
            }
        case ACTION_TYPES.FETCH_SECTOR_CORRECTO:
            return {
                ...state,
                pendiente: false,
                data: action.sector
            }
        default:
            return {
                ...state
            }
    }

}

export function sectorNuevoReducer(state = initialState.sectorNuevo, action) {
    switch (action.type) {
        case ACTION_TYPES.NUEVO_SECTOR_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        case ACTION_TYPES.NUEVO_SECTOR_CORRECTO:
            return {
                ...state,
                pendiente: false,
                sector: action.sector
            };
        case ACTION_TYPES.NUEVO_SECTOR_PENDIENTE:
            return {
                ...state,
                pendiente: false,
                error: false,
                sector: []
            };
        default:
            return {
                ...state
            };
    }
}

export function esquemaNuevoReducer(state = initialState.esquemaNuevo, action) {
    switch (action.type) {
        case ACTION_TYPES.NUEVO_TRAYECTO_ESQUEMA_PENDIENTE:
            return {
                ...state,
                esquema: null,
                pendiente: true,
                error: false
            };
        case ACTION_TYPES.NUEVO_TRAYECTO_ESQUEMA_CORRECTO:
            return  {
                ...state,
                pendiente: false,
                esquema: action.esquema
            };
        case ACTION_TYPES.NUEVO_TRAYECTO_ESQUEMA_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        default:
            return {
                ...state
            };
    }
}

export function sectorActualizandoReducer(state = initialState.sectorActualizando, action) {
    switch (action.type) {
        case ACTION_TYPES.SECTOR_ACTUALIZANDO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                sector: null,
                error: false
            };
        case ACTION_TYPES.SECTOR_ACTUALIZANDO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.SECTOR_ACTUALIZANDO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                sector: action.sector
            };
        default:
            return {
                ...state
            };
    }
}

export function sectorEliminandoReducer(state = initialState.sectorEliminando, action) {
    switch (action.type) {
        case ACTION_TYPES.SECTOR_ELIMINANDO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                correcto: true,
                sector: action.sector,
            };
        case ACTION_TYPES.SECTOR_ELIMINANDO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.SECTOR_ELIMINANDO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                error: false,
                correcto: false,
                sector: action.sector
            };
        default:
            return {
                ...state
            };

    }
}

export function esquemaEliminandoReducer(state = initialState.esquemaEliminando, action) {
    switch (action.type) {
        case ACTION_TYPES.ESQUEMA_ELIMINANDO_CORRECTO:
            return {
                ...state,
                correcto: true,
                pendiente: false,
                esquema: action.esquema
            };
        case ACTION_TYPES.ESQUEMA_ELIMINANDO_PENDIENTE:
            return {
                ...state,
                correcto: false,
                equema: action.esquema,
                pendiente: true,
                error: false
            };
        case ACTION_TYPES.ESQUEMA_ELIMINANDO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        default:
            return {
                ...state
            };
    }
}

export function esquemaActualizandoReducer(state = initialState.esquemaActualizando, action) {
    switch (action.type) {
        case ACTION_TYPES.ESQUEMA_ACTUALIZANDO_CORRECTO:
            return {
                ...state,
                esquema: action.esquema,
                pendiente: false
            };
        case ACTION_TYPES.ESQUEMA_ACTUALIZANDO_PENDIENTE:
            return {
                ...state,
                esquema: null,
                pendiente: true,
                error: false
            };
        case ACTION_TYPES.ESQUEMA_ACTUALIZANDO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        default:
            return {
                ...state
            };
    }
}


export const getSectorNuevo = state => state.sectorNuevo.sector;
export const getSectorNuevoPendiente = state => state.sectorNuevo.pendiente;
export const getSectorNuevoError = state => state.sectorNuevo.error;
export const getEsquemaNuevo = state => state.esquemaNuevo.esquema;
export const getEsquemaNuevoPendiente = state => state.esquemaNuevo.pendiente;
export const getEsquemaNuevoError = state => state.esquemaNuevo.error;
export const getSectorActualizando = state => state.sectorActualizando.sector;
export const getSectorActualizandoError = state => state.sectorActualizando.error;
export const getSectorActualizandoPendiente = state => state.sectorActualizando.pendiente;
export const getSectorEliminando = state => state.sectorEliminando.sector;
export const getSectorEliminandoCorrecto = state => state.sectorEliminando.correcto;
export const getSectorEliminandoError = state => state.sectorEliminando.error;
export const getSectorEliminandoPendiente = state => state.sectorEliminando.pendiente;
export const getEsquemaEliminando = state => state.esquemaEliminando.esquema;
export const getEsquemaEliminandoCorrecto = state => state.esquemaEliminando.correcto;
export const getEsquemaEliminandoError = state => state.esquemaEliminando.error;
export const getEsquemaEliminandoPendiente = state => state.esquemaEliminando.pendiente;
export const getEsquemaActualizando = state => state.esquemaActualizando.esquema;
export const getEsquemaActualizandoPendiente = state => state.esquemaActualizando.pendiente;
export const getEsquemaActualizandoError = state => state.esquemaActualizando.error;
