export const ACTION_TYPES = {
    UNDO_TRAYECTOS: 'UNDO_TRAYECTOS',
    REDO_TRAYECTOS: 'REDO_TRAYECTOS'
};

function undoTrayectos() {
    return {
        type: ACTION_TYPES.UNDO_TRAYECTOS
    };
}

function redoTrayectos() {
    return {
        type: ACTION_TYPES.REDO_TRAYECTOS
    }
}


export const actions = {
    undoTrayectos: undoTrayectos,
    redoTrayectos: redoTrayectos
};
