import React from 'react';
import {Trans} from 'react-i18next'

import './PagoCorrectoError.css'


export default function PagoCorrecto({onClickBack}) {
    return <div className={'stripe-end stripe-end--ok'}>
        <div className={'stripe-end__icon'}><i className={'fas fa-check-circle'}/></div>
        <div className={'stripe-end__title'}>
            <Trans>Pago Correcto</Trans>
        </div>
        <div className={'stripe-end__subtitle'}>
            <Trans>Gracias por utilizar nuestros servicios</Trans>
        </div>
        <button className={'stripe-end__back'} onClick={onClickBack}>
            <span className={'fa-stack'}>
                <i className={'fa fa-circle fa-stack-2x'}/>
                <i className={'fas fa-undo fa-stack-1x fa-inverse'}/>
            </span>
        </button>
    </div>
}
