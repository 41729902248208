export const ACTION_TYPES = {
    ACTUALIZA_COORDS_POLIGONO: 'ACTUALIZA_COORDS_POLIGONO',
    ADD_POLIGONO: 'ADD_POLIGONO',
    FETCH_POLIGNOS_CORRECTO: 'FETCH_POLIGNOS_CORRECTO',
    FETCH_POLIGONOS_ERROR: 'FETCH_POLIGONOS_ERROR',
    FETCH_POLIGONOS_PENDIENTE: 'FETCH_POLIGONOS_PENDIENTE',
    POLIGONO_ACTUALIZANDO_CORRECTO: 'POLIGONO_ACTUALIZANDO_CORRECTO',
    POLIGONO_ACTUALIZANDO_ERROR: 'POLIGONO_ACTUALIZANDO_ERROR',
    POLIGONO_ACTUALIZANDO_PENDIENTE: 'POLIGONO_ACTUALIZANDO_PENDIENTE',
    POLIGONO_ELIMINADO_CORRECTO: 'POLIGONO_ELIMINADO_CORRECTO',
    POLIGONO_ELIMINADO_ERROR: 'POLIGONO_ELIMINADO_ERROR',
    POLIGONO_ELIMINADO_PENDIENTE: 'POLIGONO_ELIMINADO_PENDIENTE',
    POLIGONO_NUEVO_CORRECTO: 'POLIGONO_NUEVO_CORRECTO',
    POLIGONO_NUEVO_ERROR: 'POLIGONO_NUEVO_ERROR',
    POLIGONO_NUEVO_PENDIENTE: 'POLIGONO_NUEVO_PENDIENTE',
    RESET_NUEVO_POLIGNO: 'RESET_NUEVO_POLIGNO',
    REMOVE_POLIGONO: 'REMOVE_POLIGONO',
    SET_COLOR_POLIGONO: 'SET_COLOR_POLIGONO',
    SET_NOMBRE_POLIGONO: 'SET_NOMBRE_POLIGONO'
};

function fetchPoligonosCorrecto(poligonos) {
    return {
        type: ACTION_TYPES.FETCH_POLIGNOS_CORRECTO,
        poligonos: poligonos
    };
}

function fetchPoligonosError(err) {
    return {
        type: ACTION_TYPES.FETCH_POLIGONOS_ERROR,
        error: err
    };
}

function fetchPoligonosPendiente() {
    return {
        type: ACTION_TYPES.FETCH_POLIGONOS_PENDIENTE
    }
}

function poligonoNuevoCorrecto(poligono) {
    return {
        type: ACTION_TYPES.POLIGONO_NUEVO_CORRECTO,
        poligono: poligono
    };
}

function poligonoNuevoError(err) {
    return {
        type: ACTION_TYPES.POLIGONO_NUEVO_ERROR,
        error: err
    };
}

function poligonoNuevoPendiente() {
    return {
        type: ACTION_TYPES.POLIGONO_NUEVO_PENDIENTE
    };
}

function resetNuevoPoligono() {
    return {
        type: ACTION_TYPES.RESET_NUEVO_POLIGNO
    }
}

function poligonoActualizandoCorrecto(poligono) {
    return {
        type: ACTION_TYPES.POLIGONO_ACTUALIZANDO_CORRECTO,
        poligono: poligono
    };
}

function poligonoActualizandoError(err) {
    return {
        type: ACTION_TYPES.POLIGONO_ACTUALIZANDO_ERROR,
        error: err
    };
}

function poligonoActualizandoPendiente() {
    return {
        type: ACTION_TYPES.POLIGONO_ACTUALIZANDO_PENDIENTE
    };
}

function addPoligono(poligono) {
    return {
        type: ACTION_TYPES.ADD_POLIGONO,
        poligono: poligono
    };
}

function removePoligono(poligono) {
    return {
        type: ACTION_TYPES.REMOVE_POLIGONO,
        poligono: poligono
    };
}

function actualizaCoordsPoligono(idPoligono, coords) {
    return {
        type: ACTION_TYPES.ACTUALIZA_COORDS_POLIGONO,
        idPoligono: idPoligono,
        coordenadas: coords
    };
}

function poligonoEliminadoCorrecto(poligono) {
    return {
        type: ACTION_TYPES.POLIGONO_ELIMINADO_CORRECTO,
        poligono: poligono
    };
}

function poligonoEliminadoError(err) {
    return {
        type: ACTION_TYPES.POLIGONO_ELIMINADO_ERROR,
        error: err
    };
}

function poligonoEliminadoPendiente(poligono) {
    return {
        type: ACTION_TYPES.POLIGONO_ELIMINADO_PENDIENTE,
        poligono: poligono
    };
}

function setColorPoligono(color, poligono) {
    return {
        type: ACTION_TYPES.SET_COLOR_POLIGONO,
        color: color,
        poligono: poligono
    };
}

function setNombrePoligono(nombre, poligono) {
    return {
        type: ACTION_TYPES.SET_NOMBRE_POLIGONO,
        nombre: nombre,
        poligono: poligono
    };
}

export const actions = {
    fetchPoligonosCorrecto: fetchPoligonosCorrecto,
    fetchPoligonosError: fetchPoligonosError,
    fetchPoligonosPendiente: fetchPoligonosPendiente,
    poligonoNuevoCorrecto: poligonoNuevoCorrecto,
    poligonoNuevoError: poligonoNuevoError,
    poligonoNuevoPendiente: poligonoNuevoPendiente,
    resetNuevoPoligono: resetNuevoPoligono,
    poligonoActualizandoCorrecto: poligonoActualizandoCorrecto,
    poligonoActualizandoError: poligonoActualizandoError,
    poligonoActualizandoPendiente: poligonoActualizandoPendiente,
    addPoligono: addPoligono,
    removePoligono: removePoligono,
    actualizaCoordsPoligono: actualizaCoordsPoligono,
    poligonoEliminadoCorrecto: poligonoEliminadoCorrecto,
    poligonoEliminadoError: poligonoEliminadoError,
    poligonoEliminadoPendiente: poligonoEliminadoPendiente,
    setColorPoligono: setColorPoligono,
    setNombrePoligono: setNombrePoligono
};
