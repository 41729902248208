import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import ModalEditor from "./ModalEditor";
import {actions} from "../../actions/editor";
import {getObjetoSeleccionado} from "../../reducers/editor";


class ModalConfirmarRecorte extends Component {

    constructor(props) {
        super(props);

        this.onClickAceptar = this.onClickAceptar.bind(this);
        this.onClickCancelar = this.onClickCancelar.bind(this);
    }

    onClickAceptar() {
        const {objetoSeleccionado} = this.props;
        objetoSeleccionado.aplicaRecorte();
        this.hide();
    }

    onClickCancelar() {
        const {objetoSeleccionado} = this.props;
        objetoSeleccionado.cancelaRecorte();
        this.hide();
    }

    hide() {
        const {setVisibleModalCofirmarRecorte} = this.props;
        setVisibleModalCofirmarRecorte(false);
    }

    render() {
        const {t, show} = this.props;
        return <ModalEditor show={show} footer onClickAceptar={this.onClickAceptar} onHide={this.onHide}
                            onClickCancelar={this.onClickCancelar} titulo={t('confirmar-recorte')}>
            <div className='row'>
                <div className='col-sm-12'>
                    {t('msg-recorte')}
                </div>
            </div>
        </ModalEditor>
    }
}


const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalCofirmarRecorte: actions.setVisibleModalCofirmarRecorte
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalConfirmarRecorte));
