import {initialState} from '../constants/state';
import {ACTION_TYPES} from "../actions/proyectos";
import { ACTIONS_TYPES } from "../actions/agrupaciones";


export function proyectosReducer(state = initialState.proyectos, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_PROYECTOS_PENDING:
            return {
                ...state,
                pendiente: true
            }
        case ACTION_TYPES.FETCH_PROYECTOS_SUCCESS:
            return {
                ...state,
                pendiente: false,
                list: action.proyectos
            }
        case ACTION_TYPES.FETCH_PROYECTOS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }
        case ACTIONS_TYPES.FETCH_AGRUPACIONES_OTRO_PROYECTO_CORRECTO:
            let list = state.list.slice()
            if (action.agrupaciones.length > 0) {
                const indexProyecto = list.map(p => p.id).indexOf(action.agrupaciones[0].proyecto)
                list[indexProyecto].agrupaciones = action.agrupaciones
            }
            return {
                ...state,
                list: list
            }

        default:
            return {
                ...state
            }
    }
}

export const getProyectos = state => state.proyectos.list;
export const getProyectosPending = state => state.proyectos.pendiente;
export const getProyectosError = state => state.proyectos.error;


export function proyectoReducer(state = initialState.proyecto, action) {
    switch(action.type) {
        case ACTION_TYPES.FETCH_PROYECTO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
            };
        case ACTION_TYPES.FETCH_PROYECTO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                data: action.proyecto
            };
        case ACTION_TYPES.FETCH_PROYECTO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.PROYECTO_ACTUALIZANDO_CORRECTO:
            return {
                ...state,
                data: action.proyecto
            };
        default:
            return{
                ...state
            };
    }
}

export function proyectoActualizandoReducer(state = initialState.proyectoActualizando, action) {
    switch (action.type) {
        case ACTION_TYPES.PROYECTO_ACTUALIZANDO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                proyecto: null,
                error: null
            };
        case ACTION_TYPES.PROYECTO_ACTUALIZANDO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                proyecto: action.proyecto
            };
        case ACTION_TYPES.PROYECTO_ACTUALIZANDO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        default:
            return {
                ...state
            };
    }
}

export const getProyecto = state => state.proyecto.data;
export const getProyectoPendiente = state => state.proyecto.pendiente;
export const getProyectoError = state => state.proyecto.error;
export const getProyectoActualizando = state => state.proyectoActualizando.proyecto;
export const getProyectoActualizandoError = state => state.proyectoActualizando.error;
export const getProyectoActualizandoPendiente = state => state.proyectoActualizando.pendiente;
