export const ACTION_TYPES = {
    CREATE_ELEMENTO_MONTAJE_BEGIN: 'CREATE_ELEMENTO_MONTAJE_BEGIN',
    CREATE_ELEMENTO_MONTAJE_SUCCESS: 'CREATE_ELEMENTO_MONTAJE_SUCCESS',
    CREATE_ELEMENTO_MONTAJE_ERROR: 'CREATE_ELEMENTO_MONTAJE_ERROR',
    DELETE_ELEMENTO_MONTAJE_BEGIN: 'DELETE_ELEMENTO_MONTAJE_BEGIN',
    DELETE_ELEMENTO_MONTAJE_SUCCESS: 'DELETE_ELEMENTO_MONTAJE_SUCCESS',
    DELETE_ELEMENTO_MONTAJE_ERROR: 'DELETE_ELEMENTO_MONTAJE_ERROR'
}

function createElementoMontajeBegin () {
    return {
        type: ACTION_TYPES.CREATE_ELEMENTO_MONTAJE_BEGIN
    }
}

function createElementoMontajeSuccess (elementoMontaje) {
    return {
        type: ACTION_TYPES.CREATE_ELEMENTO_MONTAJE_SUCCESS,
        elementoMontaje
    }
}

function createElementoMontajeError (error) {
    return {
        type: ACTION_TYPES.CREATE_ELEMENTO_MONTAJE_ERROR,
        error
    }
}

function deleteElementoMontajeBegin () {
    return {
        type: ACTION_TYPES.DELETE_ELEMENTO_MONTAJE_BEGIN
    }
}

function deleteElementoMontajeSuccess (elementoMontaje) {
    return {
        type: ACTION_TYPES.DELETE_ELEMENTO_MONTAJE_SUCCESS,
        elementoMontaje
    }
}

function deleteElementoMontajeError (error) {
    return {
        type: ACTION_TYPES.DELETE_ELEMENTO_MONTAJE_ERROR,
        error
    }
}

export const actions = {
    createElementoMontajeBegin,
    createElementoMontajeSuccess,
    createElementoMontajeError,
    deleteElementoMontajeBegin,
    deleteElementoMontajeSuccess,
    deleteElementoMontajeError
}
