import React, {Component} from 'react';
import {FormCheck, InputGroup} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import './SwitchPdiProyecto.css';
import {getLineaActualizando} from "../../reducers/lineas";
import {getPuntoActualizando} from "../../reducers/puntos";
import {getPoligonoActualizando} from "../../reducers/poligonos";
import {actualizaPunto} from "../../fetchactions/puntos";
import {actualizaLinea} from "../../fetchactions/lineas";
import {actualizaPoligono} from "../../fetchactions/poligonos";

class SwitchPdiProyecto extends Component {
    constructor(props) {
        super(props);
        this.checkRef = React.createRef();

        this.elementoActualizado = null;
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const {elemento, objeto, actualizaPunto, actualizaLinea, actualizaPoligono} = this.props;
        let elementoAct = this.elementoActualizado ? this.elementoActualizado: elemento;
        let opciones = {
            nombre: elementoAct.nombre,
            bloque: Number.isInteger(elemento.bloque) ? elemento.bloque : elemento.bloque.id,
            pdi_proyecto: e.target.checked
        };
        if(objeto.getUltimasCoords) opciones['coordenadas'] = objeto.getUltimasCoords();
        switch(elemento.bloque.tipo) {
            case 'pt':
                opciones['icono'] = elemento.icono.id;
                actualizaPunto(elemento.id, opciones);
                break;
            case 'ln':
                actualizaLinea(elemento.id, opciones);
                break;
            case 'pl':
                if(opciones.coordenadas.coordinates[0].length < 4)
                    opciones.coordenadas = {"type": "Polygon", "coordinates": [[]]};
                actualizaPoligono(elemento.id, opciones);
                break;
            default:
                break;
        }
    }

    render() {
        const {t, elemento} = this.props;
        return <InputGroup className="input-group-propiedad check-pdi-proyecto">
            <InputGroup.Prepend>
                <InputGroup.Text id='label-check-pdi-proyecto'>{t('pdi-proyecto') + ':'}</InputGroup.Text>
            </InputGroup.Prepend>
            <FormCheck aria-label="label-check-pdi-proyecto" checked={elemento.pdi_proyecto}
                       ref={this.checkRef} onChange={this.onChange}/>
        </InputGroup>
    }
}

const mapStateToProps = state => ({
    lineaActualizando: getLineaActualizando(state),
    puntoActualizando: getPuntoActualizando(state),
    poligonoActualizando: getPoligonoActualizando(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    actualizaPunto: actualizaPunto,
    actualizaLinea: actualizaLinea,
    actualizaPoligono: actualizaPoligono
}, dispatch);


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SwitchPdiProyecto));
