export const ACTION_TYPES = {
    CREATE_ELEMENTO_VOLUNTARIOS_BEGIN: 'CREATE_ELEMENTO_VOLUNTARIOS_BEGIN',
    CREATE_ELEMENTO_VOLUNTARIOS_SUCCESS: 'CREATE_ELEMENTO_VOLUNTARIOS_SUCCESS',
    CREATE_ELEMENTO_VOLUNTARIOS_ERROR: 'CREATE_ELEMENTO_VOLUNTARIOS_ERROR',
    DELETE_ELEMENTO_VOLUNTARIOS_BEGIN: 'DELETE_ELEMENTO_VOLUNTARIOS_BEGIN',
    DELETE_ELEMENTO_VOLUNTARIOS_SUCCESS: 'DELETE_ELEMENTO_VOLUNTARIOS_SUCCESS',
    DELETE_ELEMENTO_VOLUNTARIOS_ERROR: 'DELETE_ELEMENTO_VOLUNTARIOS_ERROR'
}

function createElementoVoluntariosBegin () {
    return {
        type: ACTION_TYPES.CREATE_ELEMENTO_VOLUNTARIOS_BEGIN
    }
}

function createElementoVoluntariosSuccess (elementoVoluntarios) {
    return {
        type: ACTION_TYPES.CREATE_ELEMENTO_VOLUNTARIOS_SUCCESS,
        elementoVoluntarios
    }
}

function createElementoVoluntariosError (error) {
    return {
        type: ACTION_TYPES.CREATE_ELEMENTO_VOLUNTARIOS_ERROR,
        error
    }
}

function deleteElementoVoluntariosBegin () {
    return {
        type: ACTION_TYPES.DELETE_ELEMENTO_VOLUNTARIOS_BEGIN
    }
}

function deleteElementoVoluntariosSuccess (elementoVoluntarios) {
    return {
        type: ACTION_TYPES.DELETE_ELEMENTO_VOLUNTARIOS_SUCCESS,
        elementoVoluntarios
    }
}

function deleteElementoVoluntariosError (error) {
    return {
        type: ACTION_TYPES.DELETE_ELEMENTO_VOLUNTARIOS_ERROR,
        error
    }
}

export const actions = {
    createElementoVoluntariosBegin,
    createElementoVoluntariosError,
    createElementoVoluntariosSuccess,
    deleteElementoVoluntariosBegin,
    deleteElementoVoluntariosSuccess,
    deleteElementoVoluntariosError
}
