import React, { Component } from 'react'
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import * as turf from "@turf/turf"

import { getBarraIzquierdaAbierta, getDisplayDistanciaPick } from "../../reducers/editor"

import './DisplayDistancias.css'
import { getRecorridos } from "../../reducers/recorridos"
import { getTrayectos } from "../../reducers/trayectos"


class DisplayDistancias extends Component {
    getUnits() {
        const { pickDisplayDistancias } = this.props
        switch (pickDisplayDistancias.trayecto.unidad_distancia) {
            case 'km': {
                return 'kilometers'
            }
            case 'mi': {
                return 'miles'
            }
            case 'ft': {
                return 'feet'
            }
            default: {
                return 'meters'
            }
        }
    }

    getDistanciaInicioTrayecto (noUnidades) {
        const { pickDisplayDistancias } = this.props
        if (pickDisplayDistancias) {
            let line = pickDisplayDistancias.trayecto.coordenadas
            let lineSlice = turf.lineSlice(
                line.coordinates[0],
                turf.point([pickDisplayDistancias.latLng.lng, pickDisplayDistancias.latLng.lat]),
                line
            )
            if (!noUnidades) {
                let distancia = turf.length(lineSlice, { units: this.getUnits() })
                    * pickDisplayDistancias.trayecto.distancia_metros
                    / turf.length(pickDisplayDistancias.trayecto.coordenadas, { units: 'meters' })
                return distancia.toFixed(pickDisplayDistancias.trayecto.unidad_distancia === 'km' ? 3 : 2)
                    + ' ' + pickDisplayDistancias.trayecto.unidad_distancia
            } else {
                return turf.length(lineSlice, { units: 'meters' })
                    * pickDisplayDistancias.trayecto.distancia_metros
                    / turf.length(pickDisplayDistancias.trayecto.coordenadas, { units: 'meters' })
            }
        } else {
            return ''
        }
    }

    getPasosRecorridos (trayecto) {
        if (trayecto) {
            const { recorridos } = this.props
            let pasos = []
            for (let i in recorridos) {
                for (let j in recorridos[i].sectores) {
                    let pasosRecorrido = {
                        recorrido: recorridos[i],
                        sector: recorridos[i].sectores[j],
                        pasos: []
                    }
                    let contador = 1;
                    for (let k in recorridos[i].sectores[j].esquemas) {
                        if (recorridos[i].sectores[j].esquemas[k].trayecto.id === trayecto.id) {
                            const distAnterior = this.getDistanciaAnteriores(recorridos[i], j, k)
                            const distInicio = this.getDistanciaInicioTrayecto(true)
                            const distTrayectoCompleto = this.getDistanciaTrayecto(trayecto)
                            for (let l = 0; l<recorridos[i].sectores[j].esquemas[k].repeticiones; l++) {
                                const dist = distAnterior + distTrayectoCompleto * l + distInicio
                                pasosRecorrido.pasos.push({
                                    dist: this.distanciaEnUnidad(dist, trayecto.unidad_medida),
                                    numero: contador
                                })
                                contador++
                            }
                        }
                    }
                    if (pasosRecorrido.pasos.length > 0) pasos.push(pasosRecorrido)
                }
            }
            return pasos
        } else {
            return null
        }
    }

    getDistanciaAnteriores (recorrido, indexSector, indexEsquema) {
        let dist = 0;
        const esquemasAnteriores = recorrido.sectores[indexSector].esquemas.slice(0, indexEsquema)
        for (let i in esquemasAnteriores) {
            dist += this.getDistanciaTrayecto(esquemasAnteriores[i].trayecto)
        }
        return dist
    }

    getDistanciaSector (sector) {
        let distancia = 0
        for (let i in sector.esquemas) {
            const trayecto = this.getTrayectoById(sector.esquemas[i].trayecto.id)
            distancia += trayecto.distancia_metros
        }
        return distancia
    }

    getDistanciaTrayecto (trayecto) {
        if (!trayecto.coordenadas)
            trayecto = this.getTrayectoById(trayecto.id)
        return trayecto ? trayecto.distancia_metros : 0
    }

    getTrayectoById (id) {
        const {trayectos} = this.props
        const encontrado = trayectos.filter(t => t.id === id)
        if (encontrado.length) {
            return encontrado[0]
        } else {
            return false;
            //throw new Error('El trayecto no existe')
        }
    }

    distanciaEnUnidad (distancia, unidad) {
        switch (unidad) {
            case 'km':
                return turf.convertLength(distancia, 'meters', 'kilometers').toFixed(3)
                    + ' km'
            case 'ft':
                return turf.convertLength(distancia, 'meters', 'feet').toFixed(2) + ' ft'
            case 'mi':
                return turf.convertLength(distancia, 'meters', 'miles').toFixed(2)
                    + ' mi'
            default:
                return distancia.toFixed(2) + ' m'
        }
    }

    render() {
        const {t, pickDisplayDistancias, barraIzquierdaAbierta} = this.props;
        const pasos = this.getPasosRecorridos(pickDisplayDistancias ? pickDisplayDistancias.trayecto: null)
        return (
            <div
                className={
                    'display-distancias' + (pickDisplayDistancias ? ' display-distancias-visible' : '') +
                    (!barraIzquierdaAbierta ? ' display-distancias-desplazado' : '')
                }
            >
                <h6 className='display-distancias-titulo'>
                    {t('trayecto')}:
                    <strong>{pickDisplayDistancias? ' ' + pickDisplayDistancias.trayecto.nombre : ''}</strong>
                </h6>
                <ul className={'display-distancias-ul'}>
                    <li>
                        <b>Lat:</b>
                        {' ' + (pickDisplayDistancias ? pickDisplayDistancias.latLng.lat.toFixed(4) : '')}
                        <b>  Lng:</b>
                        {' ' + (pickDisplayDistancias ? pickDisplayDistancias.latLng.lng.toFixed(4) : '')}
                    </li>
                    <li>
                        <b>{t('distancia-inicio-trayecto')}: </b> {this.getDistanciaInicioTrayecto()}
                    </li>
                    {pasos && pasos.length > 0 && pasos.map(itemPaso => itemPaso.pasos.map( paso =>
                        <li key={itemPaso.recorrido.id + ',' + paso.numero}>
                            <b>
                                {itemPaso.recorrido.nombre + ', ' + (itemPaso.recorrido.sectores.length > 1
                                    ? itemPaso.sector.nombre + ', ' : '') + t('paso') + ' ' + paso.numero + ': '}
                            </b>
                            {paso.dist}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    pickDisplayDistancias: getDisplayDistanciaPick(state),
    barraIzquierdaAbierta: getBarraIzquierdaAbierta(state),
    recorridos: getRecorridos(state),
    trayectos: getTrayectos(state)
});

export default withTranslation()(connect(mapStateToProps, null)(DisplayDistancias))
