
import store from '../../../store/configureStore';
import {CardElement} from "@stripe/react-stripe-js";
import {SERVER_DOMAIN, STRIPE_PRECIO_SUBSCRIPCION_ID} from "../../../constants/api";
import {actions as carritoActions} from "../../../actions/carrito";


function onSubscriptionComplete() {
    store.dispatch(carritoActions.carritoPagoOk());
}

function showCardError(err) {
    // console.trace(err);
    let msg = '' + err;
    if (err['error']) {
        msg = err['error'];
    }
    store.dispatch(carritoActions.carritoSetStirpeCardError(msg));
}


function displayError(err) {
    let msg = '' + err;
    if (err['error']) {
        msg = err['error'];
    }
    store.dispatch(carritoActions.carritoPagoKo(msg));
}

const clickPagar = async (stripe, elements, event) => {
    event.preventDefault()

    if (!stripe || !elements) {
        return
    }

    const stripeOpts = {
        stripe,
        elements
    }
    const cardElement = elements.getElement(CardElement);
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
        'latestInvoicePaymentIntentStatus'
    )
    const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
    })
    if (error) {
        console.log('[createPaymentMethod error]', error);
    } else {
        console.log('[PaymentMethod]', paymentMethod)
        const paymentMethodId = paymentMethod.id;
        if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
            // Update the payment method and retry invoice payment
            const invoiceId = localStorage.getItem('latestInvoiceId');
            return retryInvoiceWithNewPaymentMethod(
                paymentMethodId,
                invoiceId,
                STRIPE_PRECIO_SUBSCRIPCION_ID,
                stripeOpts
            );
        } else {
            // Create the subscription
            return createSubscription(
                paymentMethodId, STRIPE_PRECIO_SUBSCRIPCION_ID, stripeOpts
            )
        }
    }
}

export default clickPagar;


async function retryInvoiceWithNewPaymentMethod(paymentMethodId, invoiceId, priceId, stripeOpts) {
    return (
        fetch(SERVER_DOMAIN + '/api/1.0/stripe/retry-invoice/', {
            method: 'post',
            headers: {
                'Content-type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                paymentMethodId: paymentMethodId,
                invoiceId: invoiceId,
            }),
        })
            .then((response) => {
                return response.json();
            })
            // If the card is declined, display an error to the user.
            .then((result) => {
                if (result.error) {
                    // The card had an error when trying to attach it to a customer.
                    throw result;
                }
                return result;
            })
            // Normalize the result to contain the object returned by Stripe.
            // Add the addional details we need.
            .then((result) => {
                return {
                    // Use the Stripe 'object' property on the
                    // returned result to understand what object is returned.
                    invoice: result,
                    paymentMethodId: paymentMethodId,
                    priceId: priceId,
                    isRetry: true,
                };
            })
            // Some payment methods require a customer to be on session
            // to complete the payment process. Check the status of the
            // payment intent to handle these actions.
            .then(handlePaymentThatRequiresCustomerAction)
            // No more actions required. Provision your service for the user.
            .then(onSubscriptionComplete)
            .catch((error) => {
                // An error has happened. Display the failure to the user here.
                // We utilize the HTML element we created.
                displayError(error);
            })
    );
}

async function createSubscription(paymentMethodId, priceId, stripeOpts) {
    store.dispatch(carritoActions.carritoSetProcesando())
    const state = store.getState();
    return (
        fetch(SERVER_DOMAIN + '/api/1.0/stripe/subscribirse/', {
            method: 'post',
            headers: {
                'Content-type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                paymentMethodId: paymentMethodId,
                priceId: priceId,
                recorridos: state.carrito.recorridos
            }),
        })
            .then((response) => {
                store.dispatch(carritoActions.carritoUnsetProcesando())
                return response.json();
            })
            // If the card is declined, display an error to the user.
            .then((result) => {
                // console.info(result)
                if (result.error) {
                    // The card had an error when trying to attach it to a customer.
                    throw result;
                }
                return result;
            })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
            return {
                paymentMethodId: paymentMethodId,
                priceId: priceId,
                subscription: result,
                stripeOpts
            };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
            .catch((error) => {
                // An error has happened. Display the failure to the user here.
                // We utilize the HTML element we created.
                store.dispatch(carritoActions.carritoUnsetProcesando())
                showCardError(error);
            })
    );
}

function handlePaymentThatRequiresCustomerAction(
    {subscription, invoice, priceId, paymentMethodId, isRetry, stripeOpts}
) {
    if (subscription && subscription.status === 'active') {
        // Subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice ? invoice.payment_intent : subscription.latest_invoice.payment_intent;

    if (
        paymentIntent.status === 'requires_action' ||
        (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
        return stripeOpts.stripe
            .confirmCardPayment(paymentIntent.client_secret, {
                payment_method: paymentMethodId,
            })
            .then((result) => {
                if (result.error) {
                    // Start code flow to handle updating the payment details.
                    // Display error message in your UI.
                    // The card was declined (i.e. insufficient funds, card has expired, etc).
                    throw result;
                } else {
                    if (result.paymentIntent.status === 'succeeded') {
                        // Show a success message to your customer.
                        // There's a risk of the customer closing the window before the callback.
                        // We recommend setting up webhook endpoints later in this guide.
                        return {
                            priceId: priceId,
                            subscription: subscription,
                            invoice: invoice,
                            paymentMethodId: paymentMethodId,
                            stripeOpts
                        };
                    }
                }
            })
            .catch((error) => {
                displayError(error);
            });
    } else {
        // No customer action needed.
        return { subscription, priceId, paymentMethodId, stripeOpts };
    }
}

function handleRequiresPaymentMethod({
                                         subscription,
                                         paymentMethodId,
                                         priceId,
                                         stripeOpts
                                     }) {
    if (subscription.status === 'active') {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
    } else if (
        subscription.latest_invoice.payment_intent.status ===
        'requires_payment_method'
    ) {
        // Using localStorage to manage the state of the retry here,
        // feel free to replace with what you prefer.
        // Store the latest invoice ID and status.
        localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
        localStorage.setItem(
            'latestInvoicePaymentIntentStatus',
            subscription.latest_invoice.payment_intent.status
        );
        throw new Error('Your card was declined.')
    } else {
        return { subscription, priceId, paymentMethodId };
    }
}
