import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";

import './ModalEliminarRecorrido.css';
import ModalEditor from "./ModalEditor";
import {actualizaRecorrido, eliminarRecorrido} from "../../fetchactions/recorridos";
import {getRecorridosPorEliminar, getRecorridosReorden} from "../../reducers/editor";
import {actions} from "../../actions/editor";
import {eliminarPunto} from "../../fetchactions/puntos";


class ModalEliminarRecorrido extends Component {
    constructor(props) {
        super(props);

        this.onHide = this.onHide.bind(this);
        this.onClickAceptar = this.onClickAceptar.bind(this);
    }

    onClickAceptar() {
        const {eliminarRecorrido, recorridosPorEliminar, setRecorridosPorEliminar, actualizaRecorrido,
            setVisibleModalEliminarRecorrido, recorridosReorden, eliminarPunto} = this.props;
        for(let i in recorridosPorEliminar){
            for(let j in recorridosPorEliminar[i].sectores) {
                for(let k in recorridosPorEliminar[i].sectores[j].puntos_km) {
                    eliminarPunto(recorridosPorEliminar[i].sectores[j].puntos_km[k].punto);
                }
                for(let k in recorridosPorEliminar[i].sectores[j].pdis_recorrido) {
                    if(recorridosPorEliminar[i].sectores[j].pdis_recorrido[k].bloque.automatico){
                        eliminarPunto(recorridosPorEliminar[i].sectores[j].pdis_recorrido[k]);
                    }
                }
            }
            eliminarRecorrido(recorridosPorEliminar[i]);
        }
        for(let i in recorridosReorden){
            actualizaRecorrido(recorridosReorden[i].id, {
                nombre: recorridosReorden[i].nombre,
                mapa: recorridosReorden[i].mapa,
                capa_fondo: recorridosReorden[i].capa_fondo.id ? recorridosReorden[i].capa_fondo.id : recorridosReorden[i].capa_fondo,
                orden: recorridosReorden[i].orden
            })
        }
        setVisibleModalEliminarRecorrido(false);
        setRecorridosPorEliminar([]);
    }

    onHide() {
        this.hide()
    }

    hide() {
        const {setRecorridosPorEliminar, setVisibleModalEliminarRecorrido} = this.props;
        setVisibleModalEliminarRecorrido(false);
        setRecorridosPorEliminar([]);
    }

    render() {
        const {t, show, recorridosPorEliminar} = this.props;
            return <ModalEditor show={show} onHide={this.onHide} footer onClickAceptar={this.onClickAceptar}
                                onClickCancelar={this.onHide} titulo={t('confirmacion-borrado-recorridos')}>
                <Row className='lista-eliminar-recorridos'>
                    <Col>
                        <ul>
                            <span className='mensaje'>{t('confirmacion-borrado-body-recorridos')}</span>
                            {recorridosPorEliminar.map(recorrido =>
                                <li key={recorrido.id}><span className='nombre-recorrido'>{recorrido.nombre}</span>
                                    <span className='tambien-eliminar'>{t('tambien-eliminar')}:</span>
                                    <ul className='lista-subelementos'>
                                        {recorrido.sectores.map(s => s.puntos_km.map(pk =>
                                            <li key={pk.punto.id} className='nombre-punto'>{pk.punto.nombre}</li>
                                        ))}
                                        {recorrido.sectores.map(s =>
                                            s.pdis_recorrido.map(pdi => pdi.bloque.automatico &&
                                            <li key={pdi.id} className='nombre-punto'>{pdi.nombre}</li>
                                        ))}
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </Col>
                </Row>
            </ModalEditor>

    }
}


const mapStateToProps = state => ({
    recorridosPorEliminar: getRecorridosPorEliminar(state),
    recorridosReorden: getRecorridosReorden(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    eliminarRecorrido: eliminarRecorrido,
    actualizaRecorrido: actualizaRecorrido,
    eliminarPunto: eliminarPunto,
    setRecorridosPorEliminar: actions.setRecorridosPorEliminar,
    setVisibleModalEliminarRecorrido: actions.setVisibleModalEliminarRecorrido
}, dispatch);


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalEliminarRecorrido));
