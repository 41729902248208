import React from 'react';
import {useDrag, useDrop} from "react-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

const TextoElementoAgrupacion = props => {
    const {elemento, indice, ordenarElementos, onClickLiElemento, elementoIsVisible, onClickVisiblidadElemento,
        isSeleccionado, puntos} = props;

    const [, drag] = useDrag({
        item: {type: 'fila-elemento', id: indice, elemento: elemento},
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
    });

    const [, drop] = useDrop({
        accept: 'fila-elemento',
        drop: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if(didDrop) return;

            if(item.id !== indice) {
                ordenarElementos(item.id, indice, item.elemento.agrupacion);
                /*setHasDropped(true);
                setHasDroppedOnChild(didDrop)*/
            }
        },
    });

    let punto = puntos.filter(p => p.id === elemento.id);
    let color = (punto && punto[0] && !punto[0].es_pk && !punto[0].bloque.es_pk && !punto[0].bloque.automatico) ?
        (punto[0].servicios.length === 1 ? punto[0].servicios[0].color_fondo : elemento.color) : elemento.color;

    return <li ref={node=>drag(drop(node))} onClick={() => onClickLiElemento(elemento)}
               className={isSeleccionado(elemento) ? 'seleccionado' : ''}>
        <FontAwesomeIcon onClick={e => onClickVisiblidadElemento(e, elemento)}
                         icon={elementoIsVisible(elemento) ? faEye : faEyeSlash}/>
        <span className='editor-acordeon-marca-color' style={{backgroundColor: color}} />
        <span className={'editor-elemento-nombre'}>{elemento.nombre}</span>
        <span className='mover fas fa-arrows-alt'></span>
    </li>
};

export default TextoElementoAgrupacion
