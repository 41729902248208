export const ACTION_TYPES = {
    FETCH_CONTACTOS_BEGIN: 'FETCH_CONTACTOS_BEGIN',
    FETCH_CONTACTOS_SUCCESS: 'FETCH_CONTACTOS_SUCCESS',
    FETCH_CONTACTOS_ERROR: 'FETCH_CONTACTOS_ERROR'
}

function fetchContactosBegin () {
    return {
        type: ACTION_TYPES.FETCH_CONTACTOS_BEGIN
    }
}

function fetchContactosSuccess (contactos) {
    return {
        type: ACTION_TYPES.FETCH_CONTACTOS_SUCCESS,
        contactos
    }
}

function fetchContactosError (error) {
    return {
        type: ACTION_TYPES.FETCH_CONTACTOS_ERROR,
        error
    }
}

export const actions = {
    fetchContactosBegin,
    fetchContactosSuccess,
    fetchContactosError
}
