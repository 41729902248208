import {withTranslation} from "react-i18next";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as turf from '@turf/turf';
import * as L from 'leaflet';

import {Punto} from "./Punto";
import {actions as actionsEditor} from "../actions/editor";
import {actualizaPunto, creaNuevoPunto} from "../fetchactions/puntos";
import {actions} from "../actions/puntos";
import {getObjetoSeleccionado} from "../reducers/editor";
import {getProyecto} from "../reducers/proyectos";
import {getPuntoActualizando} from "../reducers/puntos";
import {getTrayectos} from "../reducers/trayectos";
import {Icon} from "../lib/leaflet.awesome-markers-svg";
import {getServicios} from "../reducers/servicios";
import invert from "invert-color";
import {getRecorridos} from "../reducers/recorridos";

import './PuntoSobreTrayecto.css';

class PuntoSobreTrayecto extends Punto {

    constructor(props) {
        super(props);
        this.errorDrag = false;
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onMapSecondClick = this.onMapSecondClick.bind(this);
        this.onChangeTrayectoAncla = this.onChangeTrayectoAncla.bind(this);
        this.distanciaInicio = null;
    }

    componentDidMount() {
        super.componentDidMount();
        this.distanciaInicio = this.calculaDistanciaInicioTrayecto();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        super.componentDidUpdate(prevProps, prevState, snapshot);
        const {objetoSeleccionado, elemento, actualizaPunto} = this.props;
        const objetoSeleccionadoPrev = prevProps.objetoSeleccionado;
        const map = this.props.map.current.leafletElement;
        const marker = this.pointRef.current.leafletElement;
        if(objetoSeleccionado && objetoSeleccionado.props.elemento.id === elemento.trayecto_ancla && (!objetoSeleccionadoPrev || objetoSeleccionadoPrev.props.elemento.id !== elemento.trayecto_ancla)) {
            map.on('editable:vertex:dragend', this.onChangeTrayectoAncla);
            map.on('editable:vertex:new', this.onChangeTrayectoAncla);
            map.on('editable:vertex:deleted', this.onChangeTrayectoAncla);
        } else if(objetoSeleccionadoPrev && objetoSeleccionadoPrev.props.elemento.id === elemento.trayecto_ancla && (!objetoSeleccionado || objetoSeleccionado.props.elemento.id !== elemento.trayecto_ancla)) {
            map.off('editable:vertex:dragend', this.onChangeTrayectoAncla);
            map.off('editable:vertex:new', this.onChangeTrayectoAncla);
            map.off('editable:vertex:deleted', this.onChangeTrayectoAncla);
            actualizaPunto(elemento.id, {
                nombre: elemento.nombre,
                bloque: elemento.bloque.id,
                icono: elemento.icono.id,
                coordenadas: marker.toGeoJSON().geometry
            });
        }
    }

    seleccionar(noCentrar) {
        super.seleccionar(noCentrar);
        const map = this.props.map.current.leafletElement;
        map.on('editable:dragend', this.onDragEnd);
    }

    deseleccionar() {
        super.deseleccionar();
        this.distanciaInicio = this.calculaDistanciaInicioTrayecto();
        const map = this.props.map.current.leafletElement;
        map.off('editable:dragend', this.onDragEnd);
    }

    onDragEnd(e) {
        const {t, elemento} = this.props;
        const marker = this.pointRef.current.leafletElement;
        const {puntoCercano, dist} = this.getDistanciaToTrayecto(e.layer);
        if(dist > 15) {
            this.errorDrag = true;
            marker.disableEdit();
            marker.enableEdit().startDrawing();
            alert(t('los') + ' ' + elemento.nombre + ' ' + t('msg-pt-sobre-trayecto'));
        } else {
            this.errorDrag = false;
            marker.setLatLng(L.latLng(puntoCercano.geometry.coordinates[1], puntoCercano.geometry.coordinates[0]));
            if(e.type === 'editable:drawing:end'){
                const map = this.props.map.current.leafletElement;
                setTimeout(() => {
                    map.on('click', this.onMapSecondClick);
                }, 50);
            }
        }
    }

    getDistanciaToTrayecto(layer) {
        const {trayectos, elemento} = this.props;
        const map = this.props.map.current.leafletElement;
        const trayecto = trayectos.filter(trayecto => trayecto.id === elemento.trayecto_ancla)[0];
        const puntoCercano = turf.nearestPointOnLine(trayecto.coordenadas, layer.toGeoJSON());
        const ptDragEndPantalla = map.latLngToContainerPoint(layer._latlng);
        const ptCercanoPantalla = map.latLngToContainerPoint(L.latLng(puntoCercano.geometry.coordinates[1],
            puntoCercano.geometry.coordinates[0]));
        return {
            dist: Math.sqrt((ptDragEndPantalla.x - ptCercanoPantalla.x) * (ptDragEndPantalla.x - ptCercanoPantalla.x)
                + (ptDragEndPantalla.y - ptCercanoPantalla.y) * (ptDragEndPantalla.y - ptCercanoPantalla.y)),
            puntoCercano: puntoCercano
        };
    }


    onMapClick(e) {
        const map = this.props.map.current.leafletElement;
        map.off('click',this.onMapClick);
        if(this.seleccionado && !this.errorDrag) {
            this.deseleccionar();
        } else {
            this.errorDrag = false;
            const marker = this.pointRef.current.leafletElement;
            marker.on('editable:drawing:end', this.onDragEnd)
        }
    }

    onMapSecondClick(e) {
        const map = this.props.map.current.leafletElement;
        map.off('click', this.onMapSecondClick);
        this.onMapClick(e);
    }

    onChangeTrayectoAncla(e) {
        const {elemento, actualizaCoordsPunto} = this.props;
        const marker = this.pointRef.current.leafletElement;
        if (elemento.bloque.automatico && elemento.bloque.tipo_especial !== 'pk' && elemento.bloque.tipo_especial !== 'n') {
            switch (elemento.bloque.tipo_especial) {
                case 'me':
                case 'sm':
                case 'fs':
                case 'sc':
                    marker.setLatLng(e.layer._latlngs[e.layer._latlngs.length - 1]);
                    break;
                case 'sa':
                case 'is':
                    marker.setLatLng(e.layer._latlngs[0]);
                    break;
                default:
                    break;
            }
        } else if(this.distanciaInicio !== 0) {
            const segmentIni = turf.lineSliceAlong(e.layer.toGeoJSON(), 0, this.distanciaInicio, {units: 'meters'});
            marker.setLatLng(L.latLng(segmentIni.geometry.coordinates[segmentIni.geometry.coordinates.length - 1][1],
                segmentIni.geometry.coordinates[segmentIni.geometry.coordinates.length - 1][0]));
        } else {
            marker.setLatLng(e.layer._latlngs[0]);
        }
        actualizaCoordsPunto(elemento.id, marker.toGeoJSON().geometry);
    }

    calculaDistanciaInicioTrayecto() {
        const {elemento, trayectos} = this.props;
        const trayecto = trayectos.filter(trayecto => trayecto.id === elemento.trayecto_ancla)[0];
        if (trayecto) {
            const lineaInicio = turf.lineSlice(trayecto.coordenadas.coordinates[0], elemento.coordenadas, trayecto.coordenadas);
            return turf.length(lineaInicio, {units: 'meters'});
        } else {
            return 0
        }
    }

    getIcon() {
        const {elemento, servicios} = this.props;
        if(elemento.bloque.pdi_sobre_trayecto && !elemento.bloque.es_pk && elemento.servicios.length === 1
            && !elemento.bloque.automatico) {
            const servicio = elemento.servicios[0].id ?
                elemento.servicios[0] : servicios.filter(srv => srv.id === elemento.servicios[0])[0];
            return new Icon({
                iconSize: [servicio.icono.fondo.ancho, servicio.icono.fondo.alto],
                iconAnchor: [servicio.icono.fondo.centro_x, servicio.icono.fondo.centro_y],
                icon: servicio.icono.icono,
                iconColor: servicio.color_icono,
                prefix: servicio.icono.prefijo_fuente,
                markerColor: servicio.color_fondo,
                svg: servicio.icono.fondo.svg,
                fontSizeIcon: servicio.icono.fondo.tamano_fuente_icono,
                classFondo: ' icono-' + servicio.icono.fondo.nombre,
            });
        } else {
            let classFondo = ' icono-' + elemento.icono.fondo.nombre;
            if (elemento.servicios.length > 0) {
                classFondo += ' con-servicios'
            }
            const distancia = elemento.bloque.es_pk ? this.getTextoDistanciaPk() : undefined
            if (distancia && parseFloat(distancia) % 5 === 0) {
                classFondo += ' kmdiv5';
            }
            return new Icon({
                iconSize: [elemento.icono.fondo.ancho, elemento.icono.fondo.alto],
                iconAnchor: [elemento.icono.fondo.centro_x, elemento.icono.fondo.centro_y],
                icon: elemento.icono.icono,
                iconColor: elemento.color_icono,
                prefix: elemento.icono.prefijo_fuente,
                markerColor: elemento.color,
                svg: elemento.icono.fondo.svg,
                fontSizeIcon: elemento.icono.fondo.tamano_fuente_icono,
                fontSizeText: elemento.icono.fondo.tamano_fuente_texto,
                classFondo: classFondo,
                text: distancia
            });
        }
    }

    getTextoDistanciaPk() {
        const {recorridos, elemento} = this.props;
        let pks = [];
        for (let i in recorridos) {
            for (let j in recorridos[i].sectores) {
                const pkSector = recorridos[i].sectores[j].puntos_km.filter(pk =>
                    pk.punto.id === (elemento ? elemento.id : null));
                if (pkSector.length > 0) pks = pks.concat(pkSector);
            }
        }
        if (pks.length !== 1) return 'km';

        let distancia = pks[0].distancia_oficial;
        const recorridoPresente = recorridos.filter(r => r.sectores.map(s => s.id).indexOf(pks[0].sector) >= 0)[0];
        if(recorridoPresente.unidad_distancia === 'm')
            distancia = pks[0].distancia_oficial / 1000;
        else if(recorridoPresente.unidad_distancia === 'ft')
            distancia = turf.convertLength(pks[0].distancia_oficial, 'feet', 'miles');

        return parseFloat(distancia) % 1 === 0 ?
            parseFloat(distancia).toFixed(0) : parseFloat(distancia).toFixed(1);
    }

    setStyleSeleccionado() {
        const {elemento, servicios} = this.props;
        const marker = this.pointRef.current.leafletElement;
        marker.setOpacity(.75);
        if(elemento.bloque.pdi_sobre_trayecto && !elemento.bloque.es_pk && elemento.servicios.length === 1) {
            const servicio = elemento.servicios[0].id ?
                elemento.servicios[0] : servicios.filter(srv => srv.id === elemento.servicios[0])[0];
            marker._icon.querySelectorAll('svg')[0].style.stroke = invert(servicio.color_fondo);
        } else {
            marker._icon.querySelectorAll('svg')[0].style.stroke = invert(elemento.color);
        }
        marker._icon.querySelectorAll('svg')[0].style.strokeWidth = '.3px';
    }

    componentWillUnmount() {
        const map = this.props.map.current? this.props.map.current.leafletElement : null;
        const marker = this.pointRef.current.leafletElement;
        marker.off('editable:drawing:end', this.onDragEnd);
        if(map) {
            map.off('editable:dragend', this.onDragEnd);
            map.off('editable:vertex:dragend', this.onChangeTrayectoAncla);
            map.off('editable:vertex:new', this.onChangeTrayectoAncla);
            map.off('editable:vertex:deleted', this.onChangeTrayectoAncla);
        }
        super.componentWillUnmount();
    }
}

const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state),
    proyecto: getProyecto(state),
    puntoActualizando: getPuntoActualizando(state),
    trayectos: getTrayectos(state),
    servicios: getServicios(state),
    recorridos: getRecorridos(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setObjetoSeleccionado: actionsEditor.objetoSeleccionado,
    actualizaPunto: actualizaPunto,
    actualizaCoordsPunto: actions.actualizaCoordsPunto,
    addObjetoDibujado: actionsEditor.addObjetoDibujado,
    removeObjetoDibujado: actionsEditor.removeObjetoDibujado,
    cambiarModoBarraHerramientas: actionsEditor.cambiarModoBarraHerramientas,
    cambiarVisibleBarraHerramientas: actionsEditor.cambiarVisibleBarraHerramientas,
    creaNuevoPunto: creaNuevoPunto,
    setDuplicando: actionsEditor.setDuplicando,
    removeCapaColapsada: actionsEditor.removeCapaColapsada,
    setVisibleModalPropAvanzada: actionsEditor.setVisibleModalPropAvanzadas,
    cambiarVisibleModalEliminarElemento: actionsEditor.cambiarVisibleModalEliminarElemento,
    removeAgrupacionColapsada: actionsEditor.removeAgrupacionColapsada
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PuntoSobreTrayecto));
