import {API_BASE_URL} from "../constants/api";
import {actions} from "../actions/mapas";
import {actions as actionsComun} from "../actions/comun";
// import {fetchRecorridos} from "./recorridos";

export function fetchMapas(idProyecto, fields=[]) {
    return dispatch => {
        let url = new URL(API_BASE_URL + 'mapa/')
        const params = {'proyecto': idProyecto}
        if (fields && fields.length) {
            params['fields'] = fields.join(',')
        }
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )

        dispatch(actions.fetchMapasPendiente());
        dispatch(actionsComun.sumaPeticion());
        return fetch(url, {
            credentials: 'include',
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);

                dispatch(actions.fetchMapasCorrecto(res));
                dispatch(actionsComun.restaPeticion());
                // @fixme: la virgen tamén...
                //dispatch(fetchRecorridos(res[0].id));
            })
            .catch(err => {
                dispatch(actions.fetchMapasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function fetchMapa(mapaId) {
    return dispatch => {
        dispatch(actions.fetchMapaPendiente());
        dispatch(actionsComun.sumaPeticion());
        fetch(API_BASE_URL + 'mapa/' + mapaId + '/', {
            credentials: 'include',
        })
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);

                dispatch(actions.fetchMapaCorrecto(res));
                dispatch(actionsComun.restaPeticion());
            })
            .catch(err => {
                dispatch(actions.fetchMapaError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}
