import { initialState } from "../constants/state"
import { ACTION_TYPES } from "../actions/voluntarios"
import { ACTION_TYPES as ACTION_TYPES_ELM } from "../actions/elementoVoluntarios";

export default function voluntariosReducer (state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_VOLUNTARIOS_BEGIN:
            return {
                ...state,
                pendiente: true,
                error: null,
                data: []
            }

        case ACTION_TYPES.FETCH_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.voluntarios
            }

        case ACTION_TYPES.FETCH_VOLUNTARIOS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        case ACTION_TYPES.CREATE_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                data: state.data.concat(action.voluntarios)
            }

        case ACTION_TYPES.DELETE_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                data: state.data.filter(v => v.id !== action.voluntarios.id)
            }

        case ACTION_TYPES.UPDATE_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                data: state.data.filter(v => v.id !== action.voluntarios.id).concat(action.voluntarios)
            }

        case ACTION_TYPES_ELM.CREATE_ELEMENTO_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                data: state.data.map(v => {
                    if (v.id === action.elementoVoluntarios.voluntarios) {
                        v.elementos_voluntarios = v.elementos_voluntarios
                            ? v.elementos_voluntarios.concat(action.elementoVoluntarios.elemento.id)
                            : [action.elementoVoluntarios.elemento.id]
                    }
                    return v
                })
            }

        case ACTION_TYPES_ELM.DELETE_ELEMENTO_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                data: state.data.map(v => {
                    if (v.id === action.elementoVoluntarios.voluntarios) {
                        v.elementos_voluntarios = v.elementos_voluntarios
                            ? v.elementos_voluntarios.filter(e => e !== action.elementoVoluntarios.elemento)
                            : []
                    }
                    return v
                })
            }

        default:
            return {
                ...state
            }
    }
}


export function voluntariosEditandoReducer (state = initialState.voluntariosEditando, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_NOMBRE:
            return {
                ...state,
                nombre: action.nombre
            }

        case ACTION_TYPES.SET_DESCRIPCION:
            return {
                ...state,
                descripcion: action.descripcion
            }

        case ACTION_TYPES.SET_META_DESCRIPCION:
            return {
                ...state,
                meta_descripcion: action.metaDescripcion
            }

        case ACTION_TYPES.SET_VISIBLE:
            return {
                ...state,
                visible: action.visible
            }

        case ACTION_TYPES.SET_CAPA_FONDO:
            return {
                ...state,
                capa_fondo: action.capaFondo
            }

        case ACTION_TYPES.SET_VISIBLE_TODOS:
            return {
                ...state,
                visible_todos: action.visibleTodos
            }

        case ACTION_TYPES.ADD_ELEMENTO_VOLUNTARIOS:
            return {
                ...state,
                elementos_voluntarios: state.elementos_voluntarios.concat(action.elemento)
            }

        case ACTION_TYPES.REMOVE_ELEMENTO_VOLUNTARIOS:
            return {
                ...state,
                elementos_voluntarios: state.elementos_voluntarios.filter(e => e !== action.elemento)
            }

        case ACTION_TYPES.RESET_VOLUNTARIOS_EDITANDO:
            return {
                ...state,
                nombre: '',
                capa_fondo: 11,
                visible: true,
                visible_todos: false,
                descripcion: '',
                meta_descripcion: '',
                elementos_voluntarios: []
            }

        case ACTION_TYPES.SET_VOLUNTARIOS_EDITANDO:
            return JSON.parse(JSON.stringify(action.voluntarios))

        default:
            return {
                ...state
            }
    }
}


export function voluntariosCreateReducer (state = initialState.voluntariosCreate, action) {
    switch (action.type) {
        case ACTION_TYPES.CREATE_VOLUNTARIOS_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: [],
                error: null
            }

        case ACTION_TYPES.CREATE_VOLUNTARIOS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        case ACTION_TYPES.CREATE_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.voluntarios
            }

        default: {
            return {
                ...state
            }
        }
    }
}

export function voluntariosDeleteReducer (state = initialState.voluntariosDelete, action) {
    switch (action.type) {
        case ACTION_TYPES.DELETE_VOLUNTARIOS_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.DELETE_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.voluntarios
            }

        case ACTION_TYPES.DELETE_VOLUNTARIOS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}


export function voluntariosUpdateReducer (state = initialState.voluntariosUpdate, action) {
    switch (action) {
        case ACTION_TYPES.UPDATE_VOLUNTARIOS_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.UPDATE_VOLUNTARIOS_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.voluntarios
            }

        case ACTION_TYPES.UPDATE_VOLUNTARIOS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}
