export const ACTION_TYPES = {
    ABRIR_BARRA_IZQUIERDA: 'ABRIR_BARRA_IZQUIERDA',
    ADD_ACCION_REDO: 'ADD_ACCION_REDO',
    ADD_ACCION_UNDO: 'ADD_ACCION_UNDO',
    ADD_AGRUPACION_COLAPSADA: 'ADD_AGRUPACION_COLAPSADA',
    ADD_ALL_ELEMENTOS_VISIBLES: 'ADD_ALL_ELEMENTOS_VISIBLES',
    ADD_CAPA_COLAPSADA: 'ADD_CAPA_COLAPSADA',
    ADD_CAPA_TRANSPARENTE: 'ADD_CAPA_TRANSPARENTE',
    ADD_ELEMENTO_IMPORTAR: 'ADD_ELEMENTO_IMPORTAR',
    ADD_ELEMENTO_VISIBLE: 'ADD_ELEMENTO_VISIBLE',
    ADD_FORMA_FICHERO_SUBIDO: 'ADD_FORMA_FICHERO_SUBIDO',
    ADD_OBJETO_DIBUJADO: 'ADD_OBJETO_DIBUJADO',
    ADD_PK_POR_ANADIR: 'ADD_PK_POR_ANADIR',
    ADD_RECORRIDO_ASOCIAR: 'ADD_RECORRIDO_ASOCIAR',
    ADD_RECORRIDO_POR_ELIMINAR: 'ADD_RECORRIDO_POR_ELIMINAR',
    ADD_SECTOR_ASOCIADO: 'ADD_SECTOR_ASOCIADO',
    ADD_SERVICIO_SELECCIONADO: 'ADD_SERVICIO_SELECCIONADO',
    CAMBIAR_MODO_BARRA_HERRAMIENTAS: 'CAMBIAR_MODO_BARRA_HERRAMIENTAS',
    CAMBIAR_VISIBLE_BARRA_HERRAMIENTAS: 'CAMBIAR_VISIBLE_BARRA_HERRAMIENTAS',
    CAMBIAR_VISIBLE_MODAL_ELIMINAR_ELEMENTO: 'CAMBIAR_VISIBLE_MODAL_ELIMINAR_ELEMENTO',
    CAMBIAR_VISIBLE_MODAL_ANADIR_ELEMENTO: 'CAMBIAR_VISIBLE_MODAL_ANADIR_ELEMENTO',
    CAMBIAR_VISIBLE_MODAL_MODO_CREAR_ELEMENTO: 'CAMBIAR_VISIBLE_MODAL_MODO_CREAR_ELEMENTO',
    CERRAR_BARRA_IZQUIERDA: 'CERRAR_BARRA_IZQUIERDA',
    CLEAR_ULTIMA_GEOMETRIA: 'CLEAR_ULTIMA_GEOMETRIA',
    DIBUJANDO_TRAYECTO: 'DIBUJANDO_TRAYECTO',
    EDITOR_INICIADO: 'EDITOR_INICIADO',
    HIDE_DROPDOWN_CAPAS: 'HIDE_DROPDOWN_CAPAS',
    OBJETO_SELECCIONADO: 'OBJETO_SELECCIONADO',
    REMOVE_AGRUPACION_COLAPSADA: 'REMOVE_AGRUPACION_COLAPSADA',
    REMOVE_ALL_ELEMENTOS_VISIBLES: 'REMOVE_ALL_ELEMENTOS_VISIBLES',
    REMOVE_CAPA_COLAPSADA: 'REMOVE_CAPA_COLAPSADA',
    REMOVE_CAPA_TRANSPARENTE: 'REMOVE_CAPA_TRANSPARENTE',
    REMOVE_ELEMENTO_VISIBLE: 'REMOVE_ELEMENTO_VISIBLE',
    REMOVE_ELEMENTO_IMPORTAR: 'REMOVE_ELEMENTO_IMPORTAR',
    REMOVE_OBJETO_DIBUJADO: 'REMOVE_OBJETO_DIBUJADO',
    REMOVE_PK_POR_ANADIR: 'REMOVE_PK_POR_ANADIR',
    REMOVE_RECORRIDO_ASOCIAR: 'REMOVE_RECORRIDO_ASOCIAR',
    REMOVE_RECORRIDO_POR_ELIMINAR: 'REMOVE_RECORRIDO_POR_ELIMINAR',
    REMOVE_SECTOR_ASOCIADO: 'REMOVE_SECTOR_ASOCIADO',
    REMOVE_SERVICIO_SELECIONADO: 'REMOVE_SERVICIO_SELECIONADO',
    RESET_ELEMENTOS_IMPORTAR: 'RESET_ELEMENTOS_IMPORTAR',
    RESET_FORMAS_FICHERO_SUBIDO: 'RESET_FORMAS_FICHERO_SUBIDO',
    RESET_RECORRIDO_ASOCIAR: 'RESET_RECORRIDO_ASOCIAR',
    RESET_PK_POR_ANADIR: 'RESET_PK_POR_ANADIR',
    RESET_SECTORES_ASOCIADOS: 'RESET_SECTORES_ASOCIADOS',
    RESET_SERVICIOS_SELECCIONADOS: 'RESET_SERVICIOS_SELECCIONADOS',
    SELECCIONAR_BLOQUE: 'SELECCIONAR_BLOQUE',
    SELECCIONAR_CAPA_BIBLIO: 'SELECCIONAR_CAPA_BIBLIO',
    SET_AGRUPACION_SELECCIONADA: 'SET_AGRUPACION_SELECCIONADA',
    SET_ANIMACION_CREAR_RECORRIDO: 'SET_ANIMACION_CREAR_RECORRIDO',
    SET_AVISO_DISTANCIA_MOSTRADO: 'SET_AVISO_DISTANCIA_MOSTRADO',
    SET_ACTIVE_KEY_SECUENCIA_SECTOR: 'SET_ACTIVE_KEY_SECUENCIA_SECTOR',
    SET_CAPA_FONDO: 'SET_CAPA_FONDO',
    SET_FILTRO_VISIBLE_ACTIVO: 'SET_FILTRO_VISIBLE_ACTIVO',
    SET_DISTANCIA_DISPLAY: 'SET_DISTANCIA_DISPLAY',
    SET_AREA_DISPLAY: 'SET_AREA_DISPLAY',
    SET_LATITUD_DISPLAY: 'SET_LATITUD_DISPLAY',
    SET_LONGITUD_DISPLAY: 'SET_LONGITUD_DISPLAY',
    SET_DUPLICANDO: 'SET_DUPLICANDO',
    SET_ELEMENTO_IMPORTADO: 'SET_ELEMENTO_IMPORTADO',
    SET_ELEMENTO_RECIEN_SUBIDO: 'SET_ELEMENTO_RECIEN_SUBIDO',
    SET_FILTRO_ANADIR_ELEMENTOS: 'SET_FILTRO_ANADIR_ELEMENTOS',
    SET_GRUPO_BLOQUES_SELECCIONADO: 'SET_GRUPO_BLOQUES_SELECCIONADO',
    SET_MONTAJE_ELIMINAR: 'SET_MONTAJE_ELIMINAR',
    SET_MUESTRA_AVISO_DISTANCIA: 'SET_MUESTRA_AVISO_DISTANCIA',
    SET_INDEX_SECTOR_PERFIL: 'SET_INDEX_SECTOR_PERFIL',
    SET_PDI_EDITAR: 'SET_PDI_EDITAR',
    SET_PESTANA_ACTIVA_RECORRIDO: 'SET_PESTANA_ACTIVA_RECORRIDO',
    SET_PICK_DISPLAY_DISTANCIA: 'SET_PICK_DISPLAY_DISTANCIA',
    SET_PKS_BORRADOS: 'SET_PKS_BORRADOS',
    SET_PREVIEW_IMAGEN_SUBIDA: 'SET_PREVIEW_IMAGEN_SUBIDA',
    SET_PRIMERA_CARGA: 'SET_PRIMERA_CARGA',
    SET_PUBLICADO_PK_POR_ANADIR: 'SET_PUBLICADO_PK_POR_ANADIR',
    SET_RECORRIDOS_POR_ELIMINAR: 'SET_RECORRIDOS_POR_ELIMINAR',
    SET_RECORRIDOS_REORDEN: 'SET_RECORRIDOS_REORDEN',
    SET_SERVICIOS_ANADIDOS: 'SET_SERVICIOS_ANADIDOS',
    SET_TRAFICO_ELIMINAR: 'SET_TRAFICO_ELIMINAR',
    SET_UNIENDO: 'SET_UNIENDO',
    SET_VISIBLE_MODAL_ANADIR_SERVICIOS: 'SET_VISIBLE_MODAL_ANADIR_SERVICIOS',
    SET_VISIBLE_MODAL_BIENVENIDA: 'SET_VISIBLE_MODAL_BIENVENIDA',
    SET_VISIBLE_MODAL_BORRAR_PKS: 'SET_VISIBLE_MODAL_BORRAR_PKS',
    SET_VISIBLE_MODAL_CONFIRMAR_RECORTE: 'SET_VISIBLE_MODAL_CONFIRMAR_RECORTE',
    SET_VISIBLE_MODAL_EDITAR_MONTAJE: 'SET_VISIBLE_MODAL_EDITAR_MONTAJE',
    SET_VISIBLE_MODAL_EDITAR_SERVICIOS: 'SET_VISIBLE_MODAL_EDITAR_SERVICIOS',
    SET_VISIBLE_MODAL_EDITAR_RECORRIDO: 'SET_VISIBLE_MODAL_EDITAR_RECORRIDO',
    SET_VISIBLE_MODAL_EDITAR_VOLUNTARIOS: 'SET_VISIBLE_MODAL_EDITAR_VOLUNTARIOS',
    SET_VISIBLE_MODAL_EDITAR_TRAFICO: 'SET_VISIBLE_MODAL_EDITAR_TRAFICO',
    SET_VISIBLE_MODAL_ELIMINAR_MONTAJE: 'SET_VISIBLE_MODAL_ELIMINAR_MONTAJE',
    SET_VISIBLE_MODAL_ELIMINAR_RECORRIDO: 'SET_VISIBLE_MODAL_ELIMINAR_RECORRIDO',
    SET_VISIBLE_MODAL_ELIMINAR_TRAFICO: 'SET_VISIBLE_MODAL_ELIMINAR_TRAFICO',
    SET_VISIBLE_MODAL_ELIMINAR_VOLUNTARIOS: 'SET_VISIBLE_MODAL_ELIMINAR_VOLUNTARIOS',
    SET_VISIBLE_MODAL_IMPORTAR: 'SET_VISIBLE_MODAL_IMPORTAR',
    SET_VISIBLE_MODAL_MONTAJE: 'SET_VISIBLE_MODAL_MONTAJE',
    SET_VISIBLE_MODAL_NOMBRE_AGRUPACION: 'SET_VISIBLE_MODAL_NOMBRE_AGRUPACION',
    SET_VISIBLE_MODAL_NUEVO_MONTAJE: 'SET_VISIBLE_MODAL_NUEVO_MONTAJE',
    SET_VISIBLE_MODAL_NUEVO_RECORRIDO: 'SET_VISIBLE_MODAL_NUEVO_RECORRIDO',
    SET_VISIBLE_MODAL_NUEVO_VOLUNTARIOS: 'SET_VISIBLE_MODAL_NUEVO_VOLUNTARIOS',
    SET_VISIBLE_MODAL_NUEVO_TRAFICO: 'SET_VISIBLE_MODAL_NUEVO_TRAFICO',
    SET_VISIBLE_MODAL_ORGANIZAR_RECORRIDOS: 'SET_VISIBLE_MODAL_ORGANIZAR_RECORRIDOS',
    SET_VISIBLE_MODAL_PERFIL: 'SET_VISIBLE_MODAL_PERFIL',
    SET_VISIBLE_MODAL_PREFERENCIAS: 'SET_VISIBLE_MODAL_PREFERENCIAS',
    SET_VISIBLE_MODAL_PROP_AVANZADAS: 'SET_VISIBLE_MODAL_PROP_AVANZADAS',
    SET_VISIBLE_MODAL_SELECCIONAR_FORMA: 'SET_VISIBLE_MODAL_SELECCIONAR_FORMA',
    SET_VISIBLE_MODAL_SUBIR_TRACK: 'SET_VISIBLE_MODAL_SUBIR_TRACK',
    SET_VISIBLE_MODAL_TRAFICO: 'SET_VISIBLE_MODAL_TRAFICO',
    SET_VISIBLE_MODAL_VOLUNTARIOS: 'SET_VISIBLE_MODAL_VOLUNTARIOS',
    SET_VOLUNTARIOS_ELIMINAR: 'SET_VOLUNTARIOS_ELIMINAR',
    SET_SECTOR_BORRAR_PKS: 'SET_SECTOR_BORRAR_PKS',
    SET_SHOW_DROPDOWN_RECORRIDOS: 'SET_SHOW_DROPDOWN_RECORRIDOS',
    SHOW_DROPDOWN_CAPAS: 'SHOW_DROPDOWN_CAPAS',
    PREVISUALIZAR_MAPA: 'PREVISUALIZAR_MAPA',
    TOGGLE_PREVISUALIZAR_MAPA: 'TOGGLE_PREVISUALIZAR_MAPA'
};

function editorIniciado() {
    return{
        type: ACTION_TYPES.EDITOR_INICIADO
    }
}

function abrirBarraIzquierda() {
    return{
        type: ACTION_TYPES.ABRIR_BARRA_IZQUIERDA
    }
}

function cerrarBarraIzquierda() {
    return{
        type: ACTION_TYPES.CERRAR_BARRA_IZQUIERDA
    }
}

function setCapaFondo(capa) {
    return {
        type: ACTION_TYPES.SET_CAPA_FONDO,
        capa: capa
    }
}

function showDropdownCapas() {
    return {
        type: ACTION_TYPES.SHOW_DROPDOWN_CAPAS
    }
}

function hideDropdownCapas() {
    return {
        type: ACTION_TYPES.HIDE_DROPDOWN_CAPAS
    }
}

function addCapaTransparente(capa) {
    return{
        type: ACTION_TYPES.ADD_CAPA_TRANSPARENTE,
        capa: capa
    }
}

function removeCapaTransparente(capa) {
    return {
        type: ACTION_TYPES.REMOVE_CAPA_TRANSPARENTE,
        capa: capa
    }
}

function cambiarVisibleModalAnadirElemento(visible) {
    return {
        type: ACTION_TYPES.CAMBIAR_VISIBLE_MODAL_ANADIR_ELEMENTO,
        visible: visible
    }
}

function seleccionarCapaBiblio(capa) {
    return {
        type: ACTION_TYPES.SELECCIONAR_CAPA_BIBLIO,
        capa: capa
    };
}

function cambiarVisibleModalModoCrearElemento(visible) {
    return {
        type: ACTION_TYPES.CAMBIAR_VISIBLE_MODAL_MODO_CREAR_ELEMENTO,
        visible: visible
    };
}

function addElementoVisible(elemento) {
    return {
        type: ACTION_TYPES.ADD_ELEMENTO_VISIBLE,
        elemento: elemento
    };
}

function removeElementoVisible(elemento) {
    return {
        type: ACTION_TYPES.REMOVE_ELEMENTO_VISIBLE,
        elemento: elemento
    };
}

function setPrimeraCarga(){
    return {
        type: ACTION_TYPES.SET_PRIMERA_CARGA
    };
}

function dibujandoTrayecto(dibujando) {
    return {
        type: ACTION_TYPES.DIBUJANDO_TRAYECTO,
        dibujando: dibujando
    };
}

function seleccionarBloque(bloque) {
    return {
        type: ACTION_TYPES.SELECCIONAR_BLOQUE,
        bloque: bloque
    };
}

function objetoSeleccionado(objeto) {
    return {
        type: ACTION_TYPES.OBJETO_SELECCIONADO,
        objeto: objeto
    };
}

function addObjetoDibujado(objeto) {
    return {
        type: ACTION_TYPES.ADD_OBJETO_DIBUJADO,
        objeto: objeto
    };
}

function removeObjetoDibujado(objeto) {
    return {
        type: ACTION_TYPES.REMOVE_OBJETO_DIBUJADO,
        objeto: objeto
    };
}

function cambiarModoBarraHerramientas(modo){
    return {
        type: ACTION_TYPES.CAMBIAR_MODO_BARRA_HERRAMIENTAS,
        modo: modo
    };
}

function cambiarVisibleBarraHerramientas(visible) {
    return{
        type: ACTION_TYPES.CAMBIAR_VISIBLE_BARRA_HERRAMIENTAS,
        visible: visible
    };
}

function cambiarVisibleModalEliminarElemento(visible) {
    return{
        type: ACTION_TYPES.CAMBIAR_VISIBLE_MODAL_ELIMINAR_ELEMENTO,
        visible: visible
    };
}

function setDuplicando(duplicando) {
    return {
        type: ACTION_TYPES.SET_DUPLICANDO,
        duplicando: duplicando
    };
}

function setUniendo(uniendo) {
    return {
        type: ACTION_TYPES.SET_UNIENDO,
        uniendo: uniendo
    };
}

function setVisibleModalPropAvanzadas(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_PROP_AVANZADAS,
        visible: visible
    };
}

function setPreviewImagenSubida(imagen) {
    return {
        type: ACTION_TYPES.SET_PREVIEW_IMAGEN_SUBIDA,
        imagen: imagen
    };
}

function setVisibleModalSubirTrack(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_SUBIR_TRACK,
        visible: visible
    };
}

function setPickDisplayDistancia(pick) {
    return {
        type: ACTION_TYPES.SET_PICK_DISPLAY_DISTANCIA,
        pick: pick
    };
}

function addCapaColapsada(capa) {
    return {
        type: ACTION_TYPES.ADD_CAPA_COLAPSADA,
        capa: capa
    };
}

function removeCapaColapsada(capa) {
    return {
        type: ACTION_TYPES.REMOVE_CAPA_COLAPSADA,
        capa: capa
    };
}

function setShowDropdownRecorridos(show) {
    return {
        type: ACTION_TYPES.SET_SHOW_DROPDOWN_RECORRIDOS,
        show: show
    };
}

function setVisibleModalNuevoRecorrido(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_NUEVO_RECORRIDO,
        visible: visible
    };
}

function setDistanciaDisplay(distancia) {
    return {
        type: ACTION_TYPES.SET_DISTANCIA_DISPLAY,
        distancia: distancia
    };
}

function setAreaDisplay(area) {
    return {
        type: ACTION_TYPES.SET_AREA_DISPLAY,
        area: area
    };
}

function setLatitudDisplay(latitud) {
    return {
        type: ACTION_TYPES.SET_LATITUD_DISPLAY,
        latitud: latitud
    };
}

function setLongitudDisplay(longitud) {
    return {
        type: ACTION_TYPES.SET_LONGITUD_DISPLAY,
        longitud: longitud
    }
}

function setVisibleModalEditarRecorrido(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_RECORRIDO,
        visible: visible
    };
}

function addRecorridoAsociar(recorrido) {
    return {
        type: ACTION_TYPES.ADD_RECORRIDO_ASOCIAR,
        recorrido: recorrido
    };
}

function removeRecorridoAsociar(recorrido) {
    return {
        type: ACTION_TYPES.REMOVE_RECORRIDO_ASOCIAR,
        recorrido: recorrido
    };
}

function addAllElementosVisibles(elementos) {
    return {
        type: ACTION_TYPES.ADD_ALL_ELEMENTOS_VISIBLES,
        elementos: elementos
    };
}

function removeAllElementosVisibles() {
    return {
        type: ACTION_TYPES.REMOVE_ALL_ELEMENTOS_VISIBLES
    };
}

function resetRecorridoAsociar() {
    return {
        type: ACTION_TYPES.RESET_RECORRIDO_ASOCIAR
    };
}

function setFiltroVisibleActivo(valor) {
    return {
        type: ACTION_TYPES.SET_FILTRO_VISIBLE_ACTIVO,
        valor: valor
    }
}

function setVisibleModalPreferencias(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_PREFERENCIAS,
        visible: visible
    };
}

function addAccionRedo(accion) {
    return {
        type: ACTION_TYPES.ADD_ACCION_REDO,
        accion: accion
    };
}

function addAccionUndo(accion, clearRedo) {
    return {
        type: ACTION_TYPES.ADD_ACCION_UNDO,
        accion: accion,
        clearRedo: clearRedo
    }
}

function clearUltimaGeometria() {
    return {
        type: ACTION_TYPES.CLEAR_ULTIMA_GEOMETRIA
    };
}

function addPkPorAnadir(pk) {
    return {
        type: ACTION_TYPES.ADD_PK_POR_ANADIR,
        pk: pk
    };
}

function removePkPorAnadir(punto, sector) {
    return {
        type: ACTION_TYPES.REMOVE_PK_POR_ANADIR,
        punto: punto,
        sector: sector
    };
}

function resetPkPorAnadir() {
    return {
        type: ACTION_TYPES.RESET_PK_POR_ANADIR
    };
}

function setServiciosAnadidos(servicios){
    return {
        type: ACTION_TYPES.SET_SERVICIOS_ANADIDOS,
        servicios: servicios
    };
}

function setVisibleModalEliminarRecorrido(visible){
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_ELIMINAR_RECORRIDO,
        visible: visible
    };
}

function setRecorridosPorEliminar(recorridos) {
    return {
        type: ACTION_TYPES.SET_RECORRIDOS_POR_ELIMINAR,
        recorridos: recorridos
    };
}

function addRecorridoPorEliminar(recorrido){
    return {
        type: ACTION_TYPES.ADD_RECORRIDO_POR_ELIMINAR,
        recorrido: recorrido
    };
}

function setVisibleModalOrganizarRecorridos(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_ORGANIZAR_RECORRIDOS,
        visible: visible
    };
}

function setRecorridosReorden(recorridos){
    return {
        type: ACTION_TYPES.SET_RECORRIDOS_REORDEN,
        recorridos: recorridos
    }
}

function setVisibleModalPerfil(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_PERFIL,
        visible: visible
    };
}

function setIndexSectorPerfil(index) {
    return {
        type: ACTION_TYPES.SET_INDEX_SECTOR_PERFIL,
        index: index
    };
}

function setVisibleModalAnadirServicios(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_ANADIR_SERVICIOS,
        visible: visible
    };
}

function addServicioSeleccionado(servicio) {
    return {
        type: ACTION_TYPES.ADD_SERVICIO_SELECCIONADO,
        servicio: servicio
    };
}

function removeServicioSeleccionado(servicio) {
    return {
        type: ACTION_TYPES.REMOVE_SERVICIO_SELECIONADO,
        servicio: servicio
    };
}

function resetServiciosSeleccionado() {
    return {
        type: ACTION_TYPES.RESET_SERVICIOS_SELECCIONADOS
    };
}

function setVisibleModalEditarServicios(visible){
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_SERVICIOS,
        visible: visible
    };
}

function setPdiEditar(pdi) {
    return {
        type: ACTION_TYPES.SET_PDI_EDITAR,
        pdi: pdi
    };
}

function setActiveKeySecuenciaSector(activeKey) {
    return {
        type: ACTION_TYPES.SET_ACTIVE_KEY_SECUENCIA_SECTOR,
        activeKey: activeKey
    };
}

function setPublicadoPkPorAnadir(sector, punto, publicado) {
    return {
        type: ACTION_TYPES.SET_PUBLICADO_PK_POR_ANADIR,
        sector: sector,
        punto: punto,
        publicado: publicado
    }
}

function setPestanaActivaRecorrido(pestanaActiva) {
    return {
        type: ACTION_TYPES.SET_PESTANA_ACTIVA_RECORRIDO,
        pestanaActiva: pestanaActiva
    };
}

function setVisibleModalBorrarPks(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_BORRAR_PKS,
        visible: visible
    };
}

function setSectorBorrarPks(sector) {
    return  {
        type: ACTION_TYPES.SET_SECTOR_BORRAR_PKS,
        sector: sector
    };
}

function setPksBorrados(value) {
    return {
        type: ACTION_TYPES.SET_PKS_BORRADOS,
        value: value
    };
}

function setVisibleModalCofirmarRecorte(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_CONFIRMAR_RECORTE,
        visible: visible
    };
}

function removeRecorridoPorEliminar(recorrido) {
    return {
        type: ACTION_TYPES.REMOVE_RECORRIDO_POR_ELIMINAR,
        recorrido: recorrido
    };
}

function setAvisoDistanciaMostrado(mostrado) {
    return {
        type: ACTION_TYPES.SET_AVISO_DISTANCIA_MOSTRADO,
        mostrado: mostrado
    };
}

function previsualizarMapa(url) {
    return {
        type: ACTION_TYPES.PREVISUALIZAR_MAPA,
        url: url
    }
}

function togglePrevisualizarMapa() {
    return {
        type: ACTION_TYPES.TOGGLE_PREVISUALIZAR_MAPA
    }
}

function addAgrupacionColapsada(agrupacion) {
    return {
        type: ACTION_TYPES.ADD_AGRUPACION_COLAPSADA,
        agrupacion: agrupacion
    };
}

function removeAgrupacionColapsada(agrupacion) {
    return {
        type: ACTION_TYPES.REMOVE_AGRUPACION_COLAPSADA,
        agrupacion: agrupacion
    };
}

function setAgrupacionSeleccionada(agrupacion) {
    return {
        type: ACTION_TYPES.SET_AGRUPACION_SELECCIONADA,
        agrupacion: agrupacion
    };
}

function setVisibleModalNombreAgrupacion(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_NOMBRE_AGRUPACION,
        visible: visible
    };
}

function addFormaFicheroSubido(forma) {
    return {
        type: ACTION_TYPES.ADD_FORMA_FICHERO_SUBIDO,
        forma: forma
    };
}

function resetFormasFicheroSubido() {
    return {
        type: ACTION_TYPES.RESET_FORMAS_FICHERO_SUBIDO
    };
}

function setVisibleModalSeleccionarForma(visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_SELECCIONAR_FORMA,
        visible: visible
    };
}

function setElementoRecienSubido(elemento) {
    return {
        type: ACTION_TYPES.SET_ELEMENTO_RECIEN_SUBIDO,
        elemento: elemento
    };
}

function setMuestraAvisoDistancia(mostrar) {
    return {
        type: ACTION_TYPES.SET_MUESTRA_AVISO_DISTANCIA,
        mostrar: mostrar
    }
}

function setFiltroAnadirElementos(filtro) {
    return {
        type: ACTION_TYPES.SET_FILTRO_ANADIR_ELEMENTOS,
        filtro: filtro
    };
}

function setAnimacionCrearRecorrido(activa) {
    return {
        type: ACTION_TYPES.SET_ANIMACION_CREAR_RECORRIDO,
        activa: activa
    };
}

function addSectorAsociado(sector) {
    return {
        type: ACTION_TYPES.ADD_SECTOR_ASOCIADO,
        sector: sector
    };
}

function removeSectorAsociado(sector) {
    return {
        type: ACTION_TYPES.REMOVE_SECTOR_ASOCIADO,
        sector: sector
    };
}

function resetSectoresAsociados() {
    return {
        type: ACTION_TYPES.RESET_SECTORES_ASOCIADOS
    };
}

function setGrupoBloquesSeleccionado (grupo) {
    return {
        type: ACTION_TYPES.SET_GRUPO_BLOQUES_SELECCIONADO,
        grupo
    };
}

function setVisibleModalImportar (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_IMPORTAR,
        visible
    }
}

function addElementoImportar(elemento) {
    return {
        type: ACTION_TYPES.ADD_ELEMENTO_IMPORTAR,
        elemento
    }
}

function removeElementoImportar (elemento) {
    return {
        type: ACTION_TYPES.REMOVE_ELEMENTO_IMPORTAR,
        elemento
    }
}

function resetElementosImportar () {
    return {
        type: ACTION_TYPES.RESET_ELEMENTOS_IMPORTAR
    }
}

function setElementoImportado (elemento) {
    return {
        type: ACTION_TYPES.SET_ELEMENTO_IMPORTADO,
        elemento
    }
}

function setVisibleModalBienvenida (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_BIENVENIDA,
        visible
    }
}

function setVisibleNuevoVoluntarios (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_NUEVO_VOLUNTARIOS,
        visible
    }
}

function setVisibleModalVoluntarios (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_VOLUNTARIOS,
        visible
    }
}

function setVisibleModalEliminarVoluntarios (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_ELIMINAR_VOLUNTARIOS,
        visible
    }
}

function setVoluntariosEliminar (voluntarios) {
    return {
        type: ACTION_TYPES.SET_VOLUNTARIOS_ELIMINAR,
        voluntarios
    }
}

function setVisibleModalEditarVoluntarios (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_VOLUNTARIOS,
        visible
    }
}

function setVisibleModalTrafico (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_TRAFICO,
        visible
    }
}

function setVisibleModalNuevoTrafico (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_NUEVO_TRAFICO,
        visible
    }
}

function setTraficoEliminar (mapaTrafico) {
    return {
        type: ACTION_TYPES.SET_TRAFICO_ELIMINAR,
        mapaTrafico
    }
}

function setVisibleModalEliminarTrafico (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_ELIMINAR_TRAFICO,
        visible
    }
}

function setVisibleModalEditarTrafico (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_TRAFICO,
        visible
    }
}

function setVisibleModalMontaje (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_MONTAJE,
        visible
    }
}

function setVisibleModalNuevoMontaje (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_NUEVO_MONTAJE,
        visible
    }
}

function setVisibleModalEditarMontaje (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_MONTAJE,
        visible
    }
}

function setMontajeEliminar (montaje) {
    return {
        type: ACTION_TYPES.SET_MONTAJE_ELIMINAR,
        montaje
    }
}

function setVisibleModalEliminarMontaje (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE_MODAL_ELIMINAR_MONTAJE,
        visible
    }
}

export const actions = {
    editorIniciado: editorIniciado,
    abrirBarraIzquierda: abrirBarraIzquierda,
    cerrarBarraIzquierda: cerrarBarraIzquierda,
    setCapaFondo: setCapaFondo,
    showDropdownCapas: showDropdownCapas,
    hideDropdownCapa:hideDropdownCapas,
    addCapaTransparente: addCapaTransparente,
    removeCapaTransparente: removeCapaTransparente,
    cambiarVisibleModalAnadirElemento: cambiarVisibleModalAnadirElemento,
    seleccionarCapaBiblio: seleccionarCapaBiblio,
    cambiarVisibleModalModoCrearElemento: cambiarVisibleModalModoCrearElemento,
    addElementoVisible: addElementoVisible,
    removeElementoVisible: removeElementoVisible,
    setPrimeraCarga: setPrimeraCarga,
    dibujandoTrayecto: dibujandoTrayecto,
    seleccionarBloque: seleccionarBloque,
    objetoSeleccionado: objetoSeleccionado,
    addObjetoDibujado: addObjetoDibujado,
    removeObjetoDibujado: removeObjetoDibujado,
    cambiarModoBarraHerramientas: cambiarModoBarraHerramientas,
    cambiarVisibleBarraHerramientas: cambiarVisibleBarraHerramientas,
    cambiarVisibleModalEliminarElemento: cambiarVisibleModalEliminarElemento,
    setDuplicando: setDuplicando,
    setUniendo: setUniendo,
    setVisibleModalPropAvanzadas: setVisibleModalPropAvanzadas,
    setPreviewImagenSubida: setPreviewImagenSubida,
    setVisibleModalSubirTrack: setVisibleModalSubirTrack,
    setPickDisplayDistancia: setPickDisplayDistancia,
    addCapaColapsada: addCapaColapsada,
    removeCapaColapsada: removeCapaColapsada,
    setShowDropdownRecorridos: setShowDropdownRecorridos,
    setVisibleModalNuevoRecorrido: setVisibleModalNuevoRecorrido,
    setDistanciaDisplay: setDistanciaDisplay,
    setAreaDisplay: setAreaDisplay,
    setLongitudDisplay: setLongitudDisplay,
    setLatitudDisplay: setLatitudDisplay,
    setVisibleModalEditarRecorrido: setVisibleModalEditarRecorrido,
    addRecorridoAsociar: addRecorridoAsociar,
    removeRecorridoAsociar: removeRecorridoAsociar,
    resetRecorridoAsociar: resetRecorridoAsociar,
    addAllElementosVisibles: addAllElementosVisibles,
    removeAllElementosVisibles: removeAllElementosVisibles,
    setFiltroVisibleActivo: setFiltroVisibleActivo,
    setVisibleModalPreferencias: setVisibleModalPreferencias,
    addAccionRedo: addAccionRedo,
    addAccionUndo: addAccionUndo,
    clearUltimaGeometria: clearUltimaGeometria,
    addPkPorAnadir: addPkPorAnadir,
    removePkPorAnadir: removePkPorAnadir,
    resetPkPorAnadir: resetPkPorAnadir,
    setServiciosAnadidos: setServiciosAnadidos,
    setVisibleModalEliminarRecorrido: setVisibleModalEliminarRecorrido,
    setRecorridosPorEliminar: setRecorridosPorEliminar,
    addRecorridoPorEliminar: addRecorridoPorEliminar,
    setVisibleModalOrganizarRecorridos: setVisibleModalOrganizarRecorridos,
    setRecorridosReorden: setRecorridosReorden,
    setVisibleModalPerfil: setVisibleModalPerfil,
    setIndexSectorPerfil: setIndexSectorPerfil,
    setVisibleModalAnadirServicios: setVisibleModalAnadirServicios,
    addServicioSeleccionado: addServicioSeleccionado,
    removeServicioSeleccionado: removeServicioSeleccionado,
    resetServiciosSeleccionado: resetServiciosSeleccionado,
    setVisibleModalEditarServicios: setVisibleModalEditarServicios,
    setPdiEditar: setPdiEditar,
    setActiveKeySecuenciaSector: setActiveKeySecuenciaSector,
    setPublicadoPkPorAnadir: setPublicadoPkPorAnadir,
    setPestanaActivaRecorrido: setPestanaActivaRecorrido,
    setVisibleModalBorrarPks: setVisibleModalBorrarPks,
    setSectorBorrarPks: setSectorBorrarPks,
    setPksBorrados: setPksBorrados,
    setVisibleModalCofirmarRecorte: setVisibleModalCofirmarRecorte,
    removeRecorridoPorEliminar: removeRecorridoPorEliminar,
    setAvisoDistanciaMostrado: setAvisoDistanciaMostrado,
    previsualizarMapa: previsualizarMapa,
    togglePrevisualizarMapa: togglePrevisualizarMapa,
    addAgrupacionColapsada: addAgrupacionColapsada,
    removeAgrupacionColapsada: removeAgrupacionColapsada,
    setAgrupacionSeleccionada: setAgrupacionSeleccionada,
    setVisibleModalNombreAgrupacion: setVisibleModalNombreAgrupacion,
    addFormaFicheroSubido: addFormaFicheroSubido,
    resetFormasFicheroSubido: resetFormasFicheroSubido,
    setVisibleModalSeleccionarForma: setVisibleModalSeleccionarForma,
    setElementoRecienSubido: setElementoRecienSubido,
    setMuestraAvisoDistancia: setMuestraAvisoDistancia,
    setFiltroAnadirElementos: setFiltroAnadirElementos,
    setAnimacionCrearRecorrido: setAnimacionCrearRecorrido,
    addSectorAsociado: addSectorAsociado,
    removeSectorAsociado: removeSectorAsociado,
    resetSectoresAsociados: resetSectoresAsociados,
    setGrupoBloquesSeleccionado,
    setVisibleModalImportar,
    addElementoImportar,
    removeElementoImportar,
    resetElementosImportar,
    setElementoImportado,
    setVisibleModalBienvenida,
    setVisibleModalVoluntarios,
    setVisibleNuevoVoluntarios,
    setVisibleModalEliminarVoluntarios,
    setVoluntariosEliminar,
    setVisibleModalEditarVoluntarios,
    setVisibleModalTrafico,
    setVisibleModalNuevoTrafico,
    setTraficoEliminar,
    setVisibleModalEliminarTrafico,
    setVisibleModalEditarTrafico,
    setVisibleModalMontaje,
    setVisibleModalNuevoMontaje,
    setVisibleModalEditarMontaje,
    setMontajeEliminar,
    setVisibleModalEliminarMontaje
}
