export const ACTION_TYPES = {
    FETCH_TIPOS_MAPAS_BEGIN: 'FETCH_TIPOS_MAPAS_BEGIN',
    FETCH_TIPOS_MAPAS_SUCCESS: 'FETCH_TIPOS_MAPAS_SUCCESS',
    FETCH_TIPOS_MAPAS_ERROR: 'FETCH_TIPOS_MAPAS_ERROR'
}

function fetchTiposMapasBegin () {
    return {
        type: ACTION_TYPES.FETCH_TIPOS_MAPAS_BEGIN
    }
}

function fetchTiposMapasSuccess (tiposMapas) {
    return {
        type: ACTION_TYPES.FETCH_TIPOS_MAPAS_SUCCESS,
        tiposMapas
    }
}

function fetchTiposMapasError (error) {
    return {
        type: ACTION_TYPES.FETCH_TIPOS_MAPAS_ERROR,
        error
    }
}

export const actions = {
    fetchTiposMapasBegin,
    fetchTiposMapasSuccess,
    fetchTiposMapasError
}
