export const ACTION_TYPES = {
    FETCH_CAPAS_ERROR: 'FETCH_CAPAS_ERROR',
    FETCH_CAPAS_CORRECTO: 'FETCH_CAPAS_CORRECTO',
    FETCH_CAPAS_PENDIENTE: 'FETCH_CAPAS_PENDIENTE'
};

function fetchCapasError(error) {
    return {
        type: ACTION_TYPES.FETCH_CAPAS_ERROR,
        error: error
    };
}

function fetchCapasCorrecto(capas) {
    return {
        type: ACTION_TYPES.FETCH_CAPAS_CORRECTO,
        capas: capas
    };
}

function fetchCapasPendiente() {
    return {
        type: ACTION_TYPES.FETCH_CAPAS_PENDIENTE
    }
}

export const actions = {
    fetchCapasError: fetchCapasError,
    fetchCapasCorrecto: fetchCapasCorrecto,
    fetchCapasPendiente: fetchCapasPendiente
}