import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {withTranslation} from "react-i18next";

import {actions} from "../actions/editor";
import BtnBarraHerramientas from "./BtnBarraHerramientas";

class BtnEliminarElemento extends Component{
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        const {cambiarVisibleModalEliminarElemento} = this.props;
        cambiarVisibleModalEliminarElemento(true);
    }

    render() {
        const {t, disabled} = this.props;
        return <BtnBarraHerramientas disabled={disabled} onClick={this.onClick} className={this.props.className}
                                     icon={faTrashAlt} titulo={t('eliminar')}/>
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    cambiarVisibleModalEliminarElemento: actions.cambiarVisibleModalEliminarElemento
}, dispatch);

export default withTranslation()(connect(null, mapDispatchToProps)(BtnEliminarElemento));
