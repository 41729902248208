/* global fetch */

import { actions } from "../actions/montaje"
import { actions as actionsComun } from '../actions/comun'
import { actions as actionsError } from '../actions/error'
import { API_BASE_URL } from "../constants/api"
import getCsrfToken from "../lib/csrf";
import {createElementoMontaje, deleteElementoMontaje} from "./elementosMontaje";

export function fetchMapasMontaje (params = {}, fields = []) {
    return dispatch => {
        dispatch(actions.fetchMapasMontajeBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'montaje/')
        if (fields && fields.length) {
            params['fields'] = fields.join(',')
        }
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )
        return fetch(url, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw res.error
                } else if (res && res.length) {
                    dispatch(actions.fetchMapasMontajeSuccess(res))
                    dispatch(actionsComun.restaPeticion())
                } else {
                    dispatch(actionsComun.restaPeticion())
                }
            })
            .catch(err => {
                dispatch(actions.fetchMapasMontajeError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function createMapaMontaje (opciones, elementos) {
    return dispatch => {
        dispatch(actions.createMapaMontajeBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'montaje/')
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createMapaMontajeError(data))
                            dispatch(actionsError.hasError(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createMapaMontajeSuccess(data))
                            dispatch(actionsComun.restaPeticion())
                            if (elementos) {
                                for (let i in elementos) {
                                    dispatch(createElementoMontaje({
                                        elemento: elementos[i],
                                        montaje: data.id
                                    }))
                                }
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actions.createMapaMontajeError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function updateMapaMontaje (idMapaMontaje, opciones, prevElem, nextElem) {
    return dispatch => {
        dispatch(actions.updateMapaMontajeBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'montaje/' + idMapaMontaje + '/')
        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    res.json()
                        .then(err => {
                            dispatch(actions.updateMapaMontajeError(err))
                            dispatch(actionsError.hasError(err))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    res.json()
                        .then(data => {
                            dispatch(actions.updateMapaMontajeSuccess(data))
                            dispatch(actionsComun.restaPeticion())
                            if (prevElem && nextElem) {
                                for (let i in prevElem) {
                                    if (!nextElem.includes(prevElem[i])) {
                                        dispatch(deleteElementoMontaje({
                                            elemento: prevElem[i],
                                            montaje: data.id
                                        }))
                                    }
                                }
                                for (let i in nextElem) {
                                    if (!prevElem.includes(nextElem[i])) {
                                        dispatch(createElementoMontaje({
                                            elemento: nextElem[i],
                                            montaje: data.id
                                        }))
                                    }
                                }
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actions.updateMapaMontajeError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function deleteMapaMontaje (mapaMontaje) {
    return dispatch => {
        dispatch(actions.deleteMapaMontajeBegin())
        dispatch(actionsComun.restaPeticion())
        const url = new URL(API_BASE_URL + 'montaje/' + mapaMontaje.id + '/')
        return fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'X-CSRFToken': getCsrfToken()
            }
        })
            .then(res => {
                if (res.status !== 204) {
                    res.json()
                        .then(err => {
                            dispatch(actions.deleteMapaMontajeError(err))
                            dispatch(actionsError.hasError(err))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    dispatch(actions.deleteMapaMontajeSuccess(mapaMontaje))
                    dispatch(actionsComun.restaPeticion())
                }
            })
            .catch(err => {
                dispatch(actions.deleteMapaMontajeError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}
