import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Col, Row, Form} from "react-bootstrap";

import ModalEditor from "./ModalEditor";
import {actions} from "../../actions/editor";
import {getAgrupacionSeleccionada} from "../../reducers/editor";
import {actualizaAgrupacion} from "../../fetchactions/agrupaciones";


class ModalCambiarNombreAgrupacion extends Component {

    constructor(props) {
        super(props);
        this.refInput = React.createRef();

        this.onClickAceptar = this.onClickAceptar.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onClickAceptar(){
        const {actualizaAgrupacion, agrupacionSeleccionada} = this.props;
        actualizaAgrupacion(agrupacionSeleccionada.id, {
            nombre: this.refInput.current.value,
            proyecto: agrupacionSeleccionada.proyecto,
        });
        this.hide();
    }

    onHide() {
        this.hide();
    }

    hide() {
        const {setVisibleModalNombreAgrupacion, setAgrupacionSeleccionada} = this.props;
        setVisibleModalNombreAgrupacion(false);
        setAgrupacionSeleccionada(null);
    }

    render() {
        const {t, show, agrupacionSeleccionada} = this.props;
        if(agrupacionSeleccionada) {
            return <ModalEditor show={show} footer onClickAceptar={this.onClickAceptar} onHide={this.onHide}
                                onClickCancelar={this.onHide} titulo={t('titulo-nombre-agrup')}>
                <Row>
                    <Form.Group as={Col} controlId='nombre-agrupacion'>
                        <Form.Label>{t('nombre')}</Form.Label>
                        <Form.Control ref={this.refInput} name='nombre' defaultValue={agrupacionSeleccionada.nombre}/>
                    </Form.Group>
                </Row>
            </ModalEditor>
        }
        else return null;

    }
}

const mapStateToProps = state => ({
    agrupacionSeleccionada: getAgrupacionSeleccionada(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalNombreAgrupacion: actions.setVisibleModalNombreAgrupacion,
    setAgrupacionSeleccionada: actions.setAgrupacionSeleccionada,
    actualizaAgrupacion: actualizaAgrupacion
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalCambiarNombreAgrupacion));
