import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import {getCapasFondo} from "../../reducers/capasFondo";
import {getRecorridoCreando, getRecorridos} from "../../reducers/recorridos";
import {getMapaRecorridos} from "../../reducers/mapas";
import {actions} from '../../actions/editor';
import {actions as actionsRecorridos} from "../../actions/recorridos";
import {actualizaRecorrido} from "../../fetchactions/recorridos";
import {getAgrupacionesProyecto} from "../../reducers/agrupaciones";
import {getDeportes} from "../../reducers/deportes";
import {ModalNuevoRecorrido} from "./ModalNuevoRecorrido";
import {
    getAnimacionCrearRecorrido,
    getIdUltimoRecorridoCreado, getPestanaActivaRecorrido,
    getPksBorrados,
    getPksPorAnadir,
    getRecorridoCompletoCreado
} from "../../reducers/editor";
import {getCapas} from "../../reducers/capas";
import {getProyecto} from "../../reducers/proyectos";
import {creaNuevoPunto, eliminarPunto} from "../../fetchactions/puntos";

class ModalEditarRecorrido extends ModalNuevoRecorrido {

    componentDidMount() {
        const {resetPksPorAnadir, addPkPorAnadir, recorridoCreando, setPksBorrados,
            setPestanaActivaRecorrido, setAnimacionCrearRecorrido} = this.props;
        resetPksPorAnadir();
        for(let i in recorridoCreando.sectores) {
            for(let j in recorridoCreando.sectores[i].puntos_km) {
                addPkPorAnadir(recorridoCreando.sectores[i].puntos_km[j]);
            }
        }
        this.recorridoPreEdicion = JSON.parse(JSON.stringify(recorridoCreando));
        setPksBorrados(false);
        setPestanaActivaRecorrido(1);
        setAnimacionCrearRecorrido(false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {recorridoCreando, recorridoCompletoCreado, setCadenciaPks, setPksBorrados, pksBorrados} = this.props;
        if(pksBorrados && !prevProps.pksBorrados) {
            this.recorridoPreEdicion = JSON.parse(JSON.stringify(recorridoCreando));
            setCadenciaPks(this.getCadenciaDefecto, pksBorrados);
            setPksBorrados(false);
        }
        if(this.recorridoAntes && recorridoCompletoCreado && !prevProps.recorridoCompletoCreado) {
            this.creaPksAuto();
            this.hide();
            this.resetSalidaMeta();
            this.resetInicioFinSectores();
        }
    }

    hide() {
        const {t, setVisibleModalEditarRecorrido, setSectorPorDefecto} = this.props;
        setSectorPorDefecto(t('sector') + ' 1');
        setVisibleModalEditarRecorrido(false);
    }

    onClickAceptar() {
        const {recorridoCreando, mapaRecorridos, actualizaRecorrido, recorridos, pksPorAnadir,
            setAnimacionCrearRecorrido} = this.props;
        if(this.valida()) {
            let opciones = {};
            const formData = new FormData(this.refForm.current);
            formData.forEach((value, key) => {
                opciones[key] = value;
            });
            if (!opciones.visible) {
                opciones.visible = false
            } else {
                opciones.visible = true
            }
            opciones.mapa = mapaRecorridos.id;
            if(recorridoCreando.vista_inicial) opciones.vista_inicial = recorridoCreando.vista_inicial
            const recorridoOriginal = recorridos.filter(recorrido => recorrido.id === recorridoCreando.id)[0];
            this.recorridoAntes = JSON.parse(JSON.stringify(recorridoCreando));
            actualizaRecorrido(recorridoCreando.id, opciones, recorridoCreando.sectores, recorridoOriginal.sectores,
                pksPorAnadir);
            setAnimacionCrearRecorrido(true);
        } else {
            return false;
        }
    }

    creaPksAuto() {
        const recorrido = this.getUltimoRecorridoCreado();
        for (let i in recorrido.sectores) {
            const sectorAntes = this.recorridoPreEdicion ?
                this.recorridoPreEdicion.sectores.filter(s => s.id === recorrido.sectores[i].id)[0] : null;
            if (recorrido.sectores[i].puntos_km_auto && (!sectorAntes || !sectorAntes.puntos_km_auto)) {
                this.creaPksSector(i, recorrido.sectores[i]);
            }
        }
    }

    resetSalidaMeta() {
        const { eliminarPunto } = this.props;
        const recorrido =  this.getUltimoRecorridoCreado();
        const sectorInicial = recorrido.sectores[0];
        const sectorFinal = recorrido.sectores[recorrido.sectores.length - 1];
        const salida = sectorInicial.pdis_recorrido.filter(p => p.bloque.automatico && p.bloque.tipo_especial === 'sa');
        let servicios = {
            salida: [],
            meta: [],
            salidaMeta: []
        };
        if (salida.length) {
            servicios.salida = servicios.salida.concat(salida[0].servicios.map(s => s.id ? s.id : s));
            eliminarPunto(salida[0], sectorInicial);
        }
        const meta = sectorFinal.pdis_recorrido.filter(p => p.bloque.automatico && p.bloque.tipo_especial === 'me');
        if (meta.length) {
            servicios.meta = servicios.meta.concat(meta[0].servicios.map(s => s.id ? s.id : s));
            eliminarPunto(meta[0], sectorFinal);
        }
        const salidaMeta = sectorFinal.pdis_recorrido.filter(p =>
            p.bloque.automatico && p.bloque.tipo_especial === 'sm');
        if (salidaMeta.length) {
            servicios.salidaMeta = servicios.salidaMeta.concat(salidaMeta[0].servicios.map(s => s.id ? s.id : s));
            eliminarPunto(salidaMeta[0], sectorFinal);
        }
        this.creaSalidaMeta(servicios)
    }

    resetInicioFinSectores() {
        const { eliminarPunto } = this.props;
        const recorrido = this.getUltimoRecorridoCreado();
        let servicios = {};
        recorrido.sectores.forEach(s => {
            if (s.generar_inicio_fin) {
               servicios[s.id] = {
                   inicio: [],
                   fin: [],
                   inicioFin: []
               };
               const iniSector = s.pdis_recorrido.filter(p => p.bloque.automatico && p.bloque.tipo_especial === 'is');
               if (iniSector.length) {
                   servicios[s.id].inicio = servicios[s.id].inicio.concat(iniSector[0].servicios.map(s =>
                       s.id ? s.id : s));
                   eliminarPunto(iniSector[0], s);
               }
               const finSector = s.pdis_recorrido.filter(p => p.bloque.automatico && p.bloque.tipo_especial === 'fs');
               if (finSector.length) {
                   servicios[s.id].fin = servicios[s.id].fin.concat(finSector[0].servicios.map(s => s.id ? s.id : s));
                   eliminarPunto(finSector[0], s);
               }
               const iniFinSector = s.pdis_recorrido.filter(p => p.bloque.automatico && p.bloque.tipo_especial === 'sc');
               if (iniFinSector.length) {
                   servicios[s.id].inicioFin = servicios[s.id].inicioFin.concat(iniFinSector[0].servicios.map(s =>
                       s.id ? s.id : s));
                   eliminarPunto(iniFinSector[0], s);
               }
            }
        });
        if (recorrido.sectores.length > 1) {
            this.recorridoAntes = JSON.parse(JSON.stringify(recorrido));
            this.creaInicioFinSectores(servicios);
        }
    }
}

const mapStateToProps = state => ({
    capasFondo: getCapasFondo(state),
    recorridoCreando: getRecorridoCreando(state),
    mapaRecorridos: getMapaRecorridos(state),
    recorridos: getRecorridos(state),
    agrupaciones: getAgrupacionesProyecto(state),
    deportes: getDeportes(state),
    pksPorAnadir: getPksPorAnadir(state),
    capas: getCapas(state),
    proyecto: getProyecto(state),
    recorridoCompletoCreado: getRecorridoCompletoCreado(state),
    idUltimoRecorridoCreado: getIdUltimoRecorridoCreado(state),
    pksBorrados: getPksBorrados(state),
    pestanaActivaRecorrido: getPestanaActivaRecorrido(state),
    animacionCrearRecorrido: getAnimacionCrearRecorrido(state),
    puntos: state.puntos.list
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalEditarRecorrido: actions.setVisibleModalEditarRecorrido,
    setSectorPorDefecto: actionsRecorridos.setSectorPorDefecto,
    addTrayectoToSector: actionsRecorridos.addTrayectoToSector,
    setRepeticionesTrayectoSector: actionsRecorridos.setRepeticionesTrayectoSector,
    reordenaTrayectosNuevo: actionsRecorridos.reordenaTrayectosNuevo,
    eliminaTrayectoSector: actionsRecorridos.eliminaTrayectoSector,
    addSectorNuevo: actionsRecorridos.addSectorNuevo,
    eliminarSectorNuevo: actionsRecorridos.eliminarSectorNuevo,
    actualizaRecorrido: actualizaRecorrido,
    cambiarNombreSectorNuevo: actionsRecorridos.cambiarNombreSectorNuevo,
    addAgrupacionRecorridoCreando: actionsRecorridos.addAgrupacionRecorridoCreando,
    removeAgrupacionRecorridoCreando: actionsRecorridos.removeAgrupacionRecorridoCreando,
    setEsquemaSimplificadoTrayectoSector: actionsRecorridos.setEsquemaSimplificadoTrayectoSector,
    addElementoRecorridoCreando: actionsRecorridos.addElementoRecorridoCreando,
    removeElementoRecorridoCreando: actionsRecorridos.removeElementoRecorridoCreando,
    reordenaPdis: actionsRecorridos.reordenaPdis,
    cambiarDeporteRecorridoCreando: actionsRecorridos.cambiarDeporteRecorridoCreando,
    cambiarDeporteSector: actionsRecorridos.cambiarDeporteSector,
    setVisibleModalPerfil: actions.setVisibleModalPerfil,
    setDatosRecorridoCreando: actionsRecorridos.setDatosRecorridoCreando,
    resetPksPorAnadir: actions.resetPkPorAnadir,
    addPkPorAnadir: actions.addPkPorAnadir,
    creaNuevoPunto: creaNuevoPunto,
    setPksBorrados: actions.setPksBorrados,
    setPestanaActivaRecorrido: actions.setPestanaActivaRecorrido,
    setCadenciaPks: actionsRecorridos.setCadenciaPks,
    setAnimacionCrearRecorrido: actions.setAnimacionCrearRecorrido,
    eliminarPunto: eliminarPunto
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalEditarRecorrido));
