import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/trayectos";
import polyline from '@mapbox/polyline';
import * as turf from '@turf/turf';

export function trayectosReducer(state = initialState.trayectos, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_TRAYECTOS_CORRECTO:
            action.trayectos.forEach(tr => {
                const line = polyline.decode(tr.linea_codificada)
                tr.coordenadas = turf.lineString(line).geometry
                const lineInterpolada = polyline.decode(tr.linea_interpolada_codificada).map((pt, i) =>
                    [...pt, tr.elevaciones[i]])
                tr.coordenadas_interpoladas = turf.lineString(lineInterpolada).geometry
            })
            return {
                ...state,
                list: action.trayectos,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_TRAYECTOS_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_TRAYECTOS_PENDIENTE:
            return {
                ...state,
                pendiente: true
            };
        case ACTION_TYPES.ADD_TRAYECTO:
            return {
                ...state,
                list: state.list.concat(action.trayecto)
            };
        case ACTION_TYPES.REMOVE_TRAYECTO:
        case ACTION_TYPES.TRAYECTO_ELIMINADO_CORRECTO:
            return {
                ...state,
                list: state.list.filter(trayecto => trayecto.id !== action.trayecto.id)
            };
        case ACTION_TYPES.ACTUALIZA_COORDS_TRAYECTO: {
            let indexTrayecto = state.list.map(trayecto => trayecto.id).indexOf(action.idTrayecto);
            let list = JSON.parse(JSON.stringify(state.list));
            if(indexTrayecto >= 0)
                list[indexTrayecto].coordenadas = action.coordenadas;
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.SET_COLOR_TRAYECTO: {
            let it = state.list.map(tr => tr.id).indexOf(action.trayecto.id);
            let list = JSON.parse(JSON.stringify(state.list));
            list[it].color = action.color;
            return {
                ...state,
                list: list
            };
        }
        case ACTION_TYPES.SET_NOMBRE_TRAYECTO: {
            let it = state.list.map(tr => tr.id).indexOf(action.trayecto.id);
            let list = JSON.parse(JSON.stringify(state.list));
            list[it].nombre = action.nombre;
            return {
                ...state,
                list
            };
        }
        default:
            return {
                ...state
            };
    }
}

export function trayectoNuevoReducer(state = initialState.trayectoNuevo, action) {
    switch (action.type) {
        case ACTION_TYPES.TRAYECTO_NUEVO_ELEMENTO:
            return {
                ...state,
                elemento: action.elemento,
                pendiente: false
            };
        case ACTION_TYPES.TRAYECTO_NUEVO_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        case ACTION_TYPES.TRAYECTO_NUEVO_PENDIENTE:
            return {
                ...state,
                pendiente: true
            };
        case ACTION_TYPES.RESET_TRAYECTO_NUEVO:
            return {
                ...state,
                elemento: null,
                error: false,
                pendiente: false
            };
        default:
            return {
                ...state
            };
    }
}

export function trayectoActualizandoReducer(state = initialState.trayectoActualizando, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_TRAYECTO_ACTUALIZANDO:
            return {
                ...state,
                correcto: false,
                elemento: action.trayecto
            };
        case ACTION_TYPES.TRAYECTO_ACTUALIZANDO_CORRECTO:
            return {
                ...state,
                elemento: action.trayecto,
                correcto: true,
                pendiente: false
            };
        case ACTION_TYPES.TRAYECTO_ACTUALIZANDO_ERROR:
            return {
                ...state,
                elemento: null,
                correcto: false,
                pendiente: false,
                error: action.error
            };
        case ACTION_TYPES.TRAYECTO_ACTUALIZANDO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                elemento: null,
                correcto: false
            };
        default:
            return {
                ...state
            }

    }
}

export function trayectoEliminadoReducer(state = initialState.trayectoEliminado, action) {
    switch (action.type) {
        case ACTION_TYPES.TRAYECTO_ELIMINADO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                correcto: false,
                elemento: action.trayecto,
                error: false
            };
        case ACTION_TYPES.TRAYECTO_ELIMINADO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                correcto: true
            };
        case ACTION_TYPES.TRAYECTO_ACTUALIZANDO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error,
                elemento: null
            };
        default:
            return {
                ...state
            };
    }
}


export const getTrayectos = state => state.trayectos.present.list;
export const getTrayectosPendiente = state => state.trayectos.present.pendiente;
export const getTrayectosError = state => state.trayectos.present.error;
export const getTrayectoNuevo = state => state.trayectoNuevo.elemento;
export const getTrayectoNuevoError = state => state.trayectoNuevo.error;
export const getTrayectoNuevoPendiente = state => state.trayectoNuevo.pendiente;
export const getTrayectoActualizando = state => state.trayectoActualizando.elemento;
export const getTrayectoActualizandoCorrecto = state => state.trayectoActualizando.correcto;
export const getTrayectoActualizandoError = state => state.trayectoActualizando.error;
export const getTrayectoActualizandoPendiente = state => state.trayectoActualizando.pendiente;
export const getTrayectoEliminadoCorrecto = state => state.trayectoEliminado.correcto;
export const getTrayectoEliminadoPendiente = state => state.trayectoEliminado.pendiente;
export const getTrayectoEliminadoError = state => state.trayectoEliminado.error;
export const getUltimoTrayectoEliminado = state => state.trayectoEliminado.elemento;
export const getTrayectosFuture = state => state.trayectos.future
