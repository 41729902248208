export const ACTION_TYPES = {
    IS_LOADING: 'IS_LAODING',
    IS_NOT_LOADING: 'IS_NOT_LOADING',
    RESTA_PETICION: 'RESTA_PETICION',
    SUMA_PETICION: 'SUMA_PETICION'
};

function isLoading() {
    return {
        type: ACTION_TYPES.IS_LOADING
    }
}

function isNotLoading() {
    return {
        type: ACTION_TYPES.IS_NOT_LOADING
    }
}

function sumaPeticion() {
    return {
        type: ACTION_TYPES.SUMA_PETICION
    };
}

function restaPeticion() {
    return {
        type: ACTION_TYPES.RESTA_PETICION
    };
}

export const actions = {
    isLoading: isLoading,
    isNotLoading: isNotLoading,
    sumaPeticion: sumaPeticion,
    restaPeticion: restaPeticion
}
