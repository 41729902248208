import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Form} from "react-bootstrap";
import * as turf from '@turf/turf';

import {getPuntos} from "../../reducers/puntos";
import {getTrayectos} from "../../reducers/trayectos";
import {getRecorridoCreando} from "../../reducers/recorridos";
import {getServicios} from "../../reducers/servicios";
import {actions} from "../../actions/editor";
import {actions as actionsRecorrido} from "../../actions/recorridos";
import {actualizaPunto} from "../../fetchactions/puntos";
import {
    getModalPropAvanzadasVisible,
    getObjetosDibujados,
    getObjetoSeleccionado,
    getPksPorAnadir
} from "../../reducers/editor";

class PksEsquema extends Component {

    constructor(props) {
        super(props);

        this.onClickMasServicios = this.onClickMasServicios.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {modalPropsAvanzadasVisible, resetPkPorAnadir, addPkPorAnadir, recorridoCreando, show} = this.props;
        if(prevProps.modalPropAvanzadasVisible && !modalPropsAvanzadasVisible && show){
                resetPkPorAnadir();
                for(let i in recorridoCreando.sectores) {
                    for(let j in recorridoCreando.sectores[i].puntos_km) {
                        addPkPorAnadir(recorridoCreando.sectores[i].puntos_km[j]);
                    }
                }
        }
    }

    getTayectoById(idTrayecto){
        const {trayectos} = this.props;
        return trayectos.filter(tr => tr.id === idTrayecto)[0];
    }

    getDistanciaInicioTrayecto(punto, trayecto) {
        const tr = this.getTayectoById(trayecto.id);
        const slice = turf.lineSlice(tr.coordenadas.coordinates[0], punto.coordenadas, tr.coordenadas);
        const dist = turf.length(slice, {units: this.getUnits()});
        return dist * trayecto.distancia_calculada / trayecto.distancia_metros;
    }

    getUnits() {
        const {recorridoCreando} = this.props;
        switch (recorridoCreando.unidad_distancia) {
            case 'km':
                return 'kilometers';
            case 'ft':
                return 'feet';
            case 'mi':
                return 'miles';
            default:
                return 'meters';
        }
    }

    getPksEsquema() {
        const {esquema, puntos, recorridoCreando} = this.props;
        let ptsDisponibles = puntos
            .filter(pt =>
                (pt.bloque.es_pk || pt.bloque.automatico) && !pt.semiautomatico && pt.bloque.pdi_sobre_trayecto &&
                pt.trayecto_ancla === esquema.trayecto.id)
            .map(p => {
                p.distanciaInicio = this.getDistanciaInicioTrayecto(p, esquema.trayecto);
                return p
            });
        const sector = recorridoCreando.sectores.filter(s => s.id === esquema.sector);
        if(sector.length > 0) {
            const ptsSector = sector[0].puntos_km.map(pk => pk.punto)
                .filter(pt => pt.trayecto_ancla === esquema.trayecto.id && pt.semiautomatico)
                .map(p => {
                    p.distanciaInicio = this.getDistanciaInicioTrayecto(p, esquema.trayecto);
                    return p
                });
            ptsDisponibles = ptsDisponibles.concat(ptsSector);
        }
        return ptsDisponibles.sort((a,b) => a.distanciaInicio - b.distanciaInicio);
    }

    onClickMasServicios(pdi){
        const {setVisibleModalEditarServicios, setPdiEditar} = this.props;
        setPdiEditar(pdi);
        setVisibleModalEditarServicios(true);
    }

    onChangePublicado(e, pdi){
        const {addPdiSector, removePdiSector, sector, addPkPorAnadir, pksPorAnadir, setPublicadoPkPorAnadir} = this.props;
        if(!pdi.es_pk){
            if(e.target.checked) addPdiSector(sector, pdi);
            else removePdiSector(sector, pdi);
        } else {
            if(e.target.checked) {
                if(pksPorAnadir.filter(pk => pk.sector === sector.id && pk.punto.id === pdi.id).length > 0){
                    setPublicadoPkPorAnadir(sector, pdi, true);
                } else {
                    const units = this.getUnits();
                    addPkPorAnadir({
                        sector: sector.id !== -1 ? sector.id : sector.orden,
                        punto: pdi,
                        distancia: units === 'meters' ?
                            pdi.distanciaInicio : turf.convertLength(pdi.distanciaInicio, units, 'meters')
                    });
                }
            } else {
                setPublicadoPkPorAnadir(sector, pdi, false);
            }
        }
    }

    esPublico(pdi) {
        const {sector, pksPorAnadir} = this.props;
        for(let i in sector.pdis_recorrido){
            if(sector.pdis_recorrido[i].id === pdi.id) return true;
        }
        for(let i in pksPorAnadir) {
            if(pksPorAnadir[i].sector === (sector.id !== -1 ? sector.id : sector.orden) &&
                pksPorAnadir[i].punto.id === pdi.id && pksPorAnadir[i].publicado)
                return true;
        }
        return false;
    }

    getDistanciaRecorridoOficial(punto) {
        const {sector, recorridoCreando, distanciaAcumulada} = this.props;
        const pkAsociado = sector.puntos_km ? sector.puntos_km.filter(pk => pk.punto.id === punto.id) : [];
        if (pkAsociado.length > 0)
            return pkAsociado[0].distancia_oficial.toFixed(2) + ' ' + recorridoCreando.unidad_distancia;
        else
            return (distanciaAcumulada + punto.distanciaInicio).toFixed(2) + ' ' + recorridoCreando.unidad_distancia
    }

    render() {
        const {esquema, paso, recorridoCreando, servicios} = this.props;
        if(recorridoCreando) {
            const pks = this.getPksEsquema();
            return pks.map(pt =>
                <tr key={esquema.id + '-' + pt.id + '-' + paso}>
                    <td>
                        <Form.Check type='checkbox' id={'publicado-' + pt.id + '-' + esquema.id + '-' + paso}>
                            <Form.Check.Input onChange={e => this.onChangePublicado(e, pt)} name='punto-publicado'
                                              type='checkbox' checked={this.esPublico(pt)}/>
                        </Form.Check>
                    </td>
                    <td>{pt.nombre}</td>
                    <td>{this.getDistanciaRecorridoOficial(pt)}</td>
                    <td>{pt.distanciaInicio.toFixed(2) + ' ' + recorridoCreando.unidad_distancia}</td>
                    <td>{paso}</td>
                    <td className='celda-servicios'>
                        {pt.servicios.map(servicio => {
                            const srv = servicio.id ? servicio : servicios.filter(s => s.id === servicio)[0];
                            return <div className='icono-servicio' key={srv.id}
                                        style={{backgroundColor: srv.color_fondo, color: srv.color_icono}}>
                                <i className={srv.icono ? srv.icono.prefijo_fuente + ' ' + srv.icono.prefijo_fuente + '-' + srv.icono.icono : ''} />
                            </div>
                        })}
                        <div onClick={() => this.onClickMasServicios(pt)}
                             className='icono-servicio icono-mas-servicio btn-primary'>
                            <i className='mdi mdi-plus'/>
                        </div>
                    </td>
                </tr>
            )
        } else {
            return <></>
        }
    }
}

const mapStateToProps = state => ({
    puntos: getPuntos(state),
    trayectos: getTrayectos(state),
    recorridoCreando: getRecorridoCreando(state),
    servicios: getServicios(state),
    pksPorAnadir: getPksPorAnadir(state),
    objetosDibujados: getObjetosDibujados(state),
    objetoSeleccionado: getObjetoSeleccionado(state),
    modalPropAvanzadasVisible: getModalPropAvanzadasVisible(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalEditarServicios: actions.setVisibleModalEditarServicios,
    setPdiEditar: actions.setPdiEditar,
    addPdiSector: actionsRecorrido.addPdiSector,
    removePdiSector: actionsRecorrido.removePdiSector,
    actualizaPunto: actualizaPunto,
    addPkPorAnadir: actions.addPkPorAnadir,
    removePkPorAnadir: actions.removePkPorAnadir,
    setPublicadoPkPorAnadir: actions.setPublicadoPkPorAnadir,
    setVisibleModalPropAvanzadas: actions.setVisibleModalPropAvanzadas,
    resetPkPorAnadir: actions.resetPkPorAnadir
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PksEsquema);
