import React from 'react';
import {useDrag} from "react-dnd";


const BoxTrayectoDrag = (props) => {
    const {trayecto} = props;
    const [, drag] = useDrag({item: {type: 'box', trayecto: trayecto}});

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(trayecto.color)
    const rgb = {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    }
    const brillo = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)

    return <div ref={drag} className="display-trayecto-crear-recorrido">
        <div className="nombre-trayecto">{trayecto.nombre}</div>
        <div className="distancia-trayecto" style={{backgroundColor: trayecto.color, color: brillo > 75 ? '#000' : '#fff'}}>
            {trayecto.distancia_metros} m
        </div>
    </div>
};

export default BoxTrayectoDrag;
