import React, { Component } from 'react'
import { withTranslation } from "react-i18next"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { Col, Form, Row } from "react-bootstrap"

import ModalEditor from "./ModalEditor"
import { actions } from "../../actions/editor"
import { getProyecto } from "../../reducers/proyectos"
import { actualizaProyecto, actualizaProyectoFormData } from "../../fetchactions/proyectos"
import LogoCuadrado from "../../images/logo.png"

class ModalPreferencias extends Component {
    constructor(props) {
        super(props)
        this.refForm = React.createRef()

        this.onClickAceptar = this.onClickAceptar.bind(this)
        this.onHide = this.onHide.bind(this)
    }

    onClickAceptar() {
        if (this.valida()) {
            const { actualizaProyectoFormData, proyecto } = this.props
            const formData = new FormData(this.refForm.current)
            if (formData.get('logo').size === 0) {
                formData.delete('logo')
            }
            actualizaProyectoFormData(proyecto.id, formData)
            this.hide()
        }
    }

    valida() {
        const { t } = this.props
        const nombre = document.getElementsByName('nombre')[0]
        if (nombre.value.length <= 0) {
            alert(t('msg-no-nombre'))
            return false
        }
        return true
    }

    onHide() {
        this.hide()
    }

    hide() {
        const { setVisibleModalPreferencias } = this.props
        setVisibleModalPreferencias(false)
    }

    render() {
        const { t, show, proyecto } = this.props
        return (
            <ModalEditor
                show={show} onHide={this.onHide} onClickAceptar={this.onClickAceptar} footer
                onClickCancelar={this.onHide} titulo={t('preferencias-propiedades')}
            >
                <Form ref={this.refForm}>
                    <Row>
                        <Form.Group as={Col} controlId='nombre-proyecto'>
                            <Form.Label>{t('nombre')}</Form.Label>
                            <Form.Control name='nombre' defaultValue={proyecto.nombre}/>
                        </Form.Group>
                    </Row>
                    <Row>
                        <div className={'col-12'}>
                            <div style={{display: 'inline-block'}}>
                                { proyecto.logo ? (
                                    <img src={proyecto.logo} className={'rounded'} alt={'Logo'} style={{height: '170px'}}/>
                                ) : (
                                    <img src={LogoCuadrado} className={'rounded'} alt={'Logo'} />
                                )}
                            </div>
                            <div style={{display: 'inline-block'}}>
                                <Form.Label>{t('Logotipo')}</Form.Label>
                                <p>
                                    {t('Se usará para identificar tu proyecto en los listados y al compartirlo en redes sociales.')}<br />
                                    {t('Es recomendable usar un PNG con fondo transparente de 200x200 px')}
                                </p>
                                <Form.Group as={Col} controlId={'logo-proyecto'}>
                                    <Form.Label>{t('logo-proyecto')}</Form.Label>
                                    <Form.Control type='file' name={'logo'} accept='image/*'/>
                                </Form.Group>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Form.Group as={Col} controlId='descripcion-proyecto'>
                            <Form.Label>{t('Descripción')}</Form.Label>
                            <Form.Control
                                as='textarea' placeholder={t('introducir-descripcion-proyecto')}
                                defaultValue={proyecto.descripcion} name='descripcion'
                            />
                        </Form.Group>
                    </Row>
                </Form>
            </ModalEditor>
        )
    }
}

const mapStateToProps = state => ({
    proyecto: getProyecto(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalPreferencias: actions.setVisibleModalPreferencias,
    actualizaProyecto: actualizaProyecto,
    actualizaProyectoFormData: actualizaProyectoFormData
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalPreferencias));
