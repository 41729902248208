import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/mapas";


export function mapasReducer(state = initialState.mapas, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_MAPAS_PENDIENTE:
            return {
                ...state,
                pendiente: true, 
                error: false,
                list: []
            };
        case ACTION_TYPES.FETCH_MAPAS_CORRECTO:
            return {
                ...state,
                pendiente: false,
                list: action.mapas
            };
        case ACTION_TYPES.FETCH_MAPAS_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        default: 
            return {
                ...state
            };
    }    
}


export function mapaReducer(state = initialState.mapa, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_MAPA_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                error: false,
                data: null
            };
        case ACTION_TYPES.FETCH_MAPA_CORRECTO:
            return {
                ...state,
                pendiente: false,
                data: action.mapa
            };
        case ACTION_TYPES.FETCH_MAPA_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error,
                data: null
            };
        default:
            return {
                ...state
            };
    }
}


export const getMapas = state => state.mapas.list;
export const getMapasPendiente = state => state.mapas.pendiente;
export const getMapasError = state => state.mapas.error;

// @fixme: la virgen!
export const getMapaRecorridos = state => state.mapas.list.filter(mapa => mapa.tipo.nombre === 'Recorrido')[0];

export const getMapa = state => state.mapa.data;
export const getMapaPendiente = state => state.mapa.pendiente;
export const getMapaError = state => state.mapa.error;
