const dotenv = require('dotenv')
dotenv.config()


export let SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN || 'http://localhost:8000'

export let FRONTEND_SERVER_DOMAIN = process.env.REACT_APP_FRONTEND_SERVER_DOMAIN || 'http://localhost:3001'

export let ALGOLIA = {
    APPLICATION_ID: 'FHPFLPI6IU',
    API_KEY: '41ef897df5477fe92db831e6b68be291',
    INDEX_NAME: process.env.REACT_APP_ALGOLIA_INDEX_NAME || 'proyecto'
}


export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY  ||
    'pk_test_51H4NFvHY8QkC6bmXbo44UDBztaWDuT74G1VY8L9FAG0tlKkYFnbLYlplKfTKHwfjTXLlKnqvouCjnsobmYFmxwAq00r2BpCk8F'

export const STRIPE_PRECIO_SUBSCRIPCION_ID = process.env.REACT_APP_STRIPE_PRECIO_SUBSCRIPCION_ID ||
    'price_1H4QVIHY8QkC6bmXuq2XF9dq'
export const STRIPE_PRECIO_SUBSCRIPCION_PVP = process.env.REACT_APP_STRIPE_PRECIO_SUBSCRIPCION_PVP || 20
//
//
// if (process.env.NODE_ENV === 'production') {
//     SERVER_DOMAIN = 'https://test.racemapp.com';
//     FRONTEND_SERVER_DOMAIN = 'https://test.racemapp.com'
// }

export const API_BASE_URL = `${SERVER_DOMAIN}/api/1.0/`;
