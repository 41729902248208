import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Row, Col, Table} from "react-bootstrap";
import * as turf from '@turf/turf';

import {getRecorridoCreando, getRecorridos} from "../../reducers/recorridos";
import PdisEsquema from "./PdisEsquema";
import {getPuntos} from "../../reducers/puntos";
import {actions} from "../../actions/recorridos";

class ContainerPdisSector extends Component {

    constructor(props) {
        super(props);

        this.onChangeGenerarInicioFin = this.onChangeGenerarInicioFin.bind(this);
        this.onChangePksAuto = this.onChangePksAuto.bind(this);
        this.onChangeCadencia = this.onChangeCadencia.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //Esto que sigue, non vale para nada
        const {sector, puntos, addPdiSector, editar, show} = this.props;
        if (!editar && show && !prevProps.show) {
            for (let i in sector.trayectos) {
                const ptsTrayecto = puntos.filter(pt => pt.trayecto_ancla === sector.trayectos[i].id);
                for (let j in ptsTrayecto) addPdiSector(sector, ptsTrayecto[j]);
            }
        }
    }

    getDistanciaAcumulada(esquema, repeticion) {
        const {sector} = this.props;
        let i = 0;
        let distancia = 0;
        while (sector.esquemas[i].orden !== esquema.orden) {
            distancia += sector.esquemas[i].trayecto.distancia_metros * sector.esquemas[i].repeticiones;
            i++;
        }
        return this.toUnits(distancia + esquema.trayecto.distancia_metros * (repeticion - 1 === 0 ? repeticion - 1 : 0));
    }

    toUnits(distancia) {
        const {recorridoCreando} = this.props;
        switch (recorridoCreando.unidad_distancia) {
            case 'km':
                return turf.convertLength(distancia, 'meters', 'kilometers');
            case 'ft':
                return turf.convertLength(distancia, 'meters', 'feet');
            case 'mi':
                return turf.convertLength(distancia, 'meters', 'miles');
            default:
                return distancia;
        }
    }

    getPaso(esquema, repeticion) {
        const {sector} = this.props;
        let pasos = 0;
        let i = 0;
        while (sector.esquemas[i].orden !== esquema.orden) {
            if (sector.esquemas[i].trayecto.id === esquema.trayecto.id) pasos += sector.esquemas[i].repeticiones;
            i++;
        }
        pasos += repeticion;
        return pasos;
    }

    onChangeGenerarInicioFin(e) {
        const {setGenerarInicioFinSector, sector} = this.props;
        setGenerarInicioFinSector(sector, e.target.checked);
    }

    pksYaCreados() {
        const {recorridos, sector} = this.props;
        const recorrido = recorridos.filter(r => r.id === sector.recorrido)[0];
        const sectorGuardado = recorrido ? recorrido.sectores.filter(s => s.id === sector.id)[0] : false;
        return sectorGuardado && sectorGuardado.puntos_km_auto;
    }

    onChangePksAuto(e) {
        const {setPuntosKmAuto, sector} = this.props;
        setPuntosKmAuto(e.target.checked, sector);
    }

    getCadencias() {
        const {recorridoCreando} = this.props;
        switch (recorridoCreando.unidad_distancia) {
            case 'km':
            case 'mi':
                return [1, 5, 10, 25, 50, 100];
            case 'ft':
                return [100, 500, 1000, 5000];
            default:
                return [1000, 5000, 10000];
        }
    }

    onChangeCadencia(e) {
        const {sector, setCadenciaPks} = this.props;
        setCadenciaPks(parseInt(e.target.value), sector);
    }

    render() {
        const {t, sector, recorridoCreando, show, puntos} = this.props;
        let haiPuntos = false;
        sector.esquemas.forEach(e => {
            if(puntos.filter(p =>
                (!p.bloque.es_pk && !p.bloque.automatico) && p.bloque.pdi_sobre_trayecto && p.trayecto_ancla === e.trayecto.id).length) {
                haiPuntos = true;
            }
        });

        return <Row>
            <Col>
                {haiPuntos ?
                    <Table>
                        <thead>
                        <tr>
                            <th>{t('publicado')}</th>
                            <th>{t('nombre')}</th>
                            <th>
                                {recorridoCreando.sectores.length > 1 ? t('distancia-sector') : t('distancia-recorrido')}
                            </th>
                            <th>{t('distancia-trayecto')}</th>
                            <th>{t('paso-trayecto')}</th>
                            <th>{t('servicios-asociados')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {sector && sector.esquemas.map(esquema => {
                            let filas = [];
                            for (let i = 0; i < esquema.repeticiones; i++) {
                                filas.push(<PdisEsquema key={esquema + '-' + i}
                                                        esquema={esquema}
                                                        sector={sector}
                                                        paso={this.getPaso(esquema, i + 1)}
                                                        distanciaAcumulada={this.getDistanciaAcumulada(esquema, i + 1)}
                                                        show={show}
                                />);
                            }
                            return filas
                        })}
                        </tbody>
                    </Table>
                    :
                    <>{t('msg-no-puntos-recorrido')}</>
                }
            </Col>
        </Row>
    }
}

const mapStateToProps = state => ({
    recorridoCreando: getRecorridoCreando(state),
    puntos: getPuntos(state),
    recorridos: getRecorridos(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    addPdisSector: actions.addPdiSector,
    setGenerarInicioFinSector: actions.setGenerarInicioFinSector,
    setPuntosKmAuto: actions.setPuntosKmAuto,
    setCadenciaPks: actions.setCadenciaPks
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContainerPdisSector));
