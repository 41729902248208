export const ACTION_TYPES = {
    ADD_ELEMENTO_MONTAJE: 'ADD_ELEMENTO_MONTAJE',
    CREATE_MAPA_MONTAJE_BEGIN: 'CREATE_MAPA_MONTAJE_BEGIN',
    CREATE_MAPA_MONTAJE_SUCCESS: 'CREATE_MAPA_MONTAJE_SUCCESS',
    CREATE_MAPA_MONTAJE_ERROR: 'CREATE_MAPA_MONTAJE_ERROR',
    DELETE_MAPA_MONTAJE_BEGIN: 'DELETE_MAPA_MONTAJE_BEGIN',
    DELETE_MAPA_MONTAJE_SUCCESS: 'DELETE_MAPA_MONTAJE_SUCCESS',
    DELETE_MAPA_MONTAJE_ERROR: 'DELETE_MAPA_MONTAJE_ERROR',
    FETCH_MAPAS_MONTAJE_BEGIN: 'FETCH_MAPAS_MONTAJE_BEGIN',
    FETCH_MAPAS_MONTAJE_SUCCESS: 'FETCH_MAPAS_MONTAJE_SUCCESS',
    FETCH_MAPAS_MONTAJE_ERROR: 'FETCH_MAPAS_MONTAJE_ERROR',
    REMOVE_ELEMENTO_MONTAJE: 'REMOVE_ELEMENTO_MONTAJE',
    RESET_MONTAJE_EDITANDO: 'RESET_MONTAJE_EDITANDO',
    SET_NOMBRE: 'SET_NOMBRE_MONTAJE',
    SET_CAPA_FONDO: 'SET_CAPA_FONDO_MONTAJE',
    SET_VISIBLE: 'SET_VISIBLE_MONTAJE',
    SET_DESCRIPCION: 'SET_DESCRIPCION_MONTAJE',
    SET_MAPA_MONTAJE_EDITANDO: 'SET_MAPA_MONTAJE_EDITANDO',
    SET_META_DESCRIPCION: 'SET_META_DESCRIPCION_MONTAJE',
    UPDATE_MAPA_MONTAJE_BEGIN: 'UPDATE_MAPA_MONTAJE_BEGIN',
    UPDATE_MAPA_MONTAJE_SUCCESS: 'UPDATE_MAPA_MONTAJE_SUCCESS',
    UPDATE_MAPA_MONTAJE_ERROR: 'UPDATE_MAPA_MONTAJE_ERROR'
}

function fetchMapasMontajeBegin () {
    return {
        type: ACTION_TYPES.FETCH_MAPAS_MONTAJE_BEGIN
    }
}

function fetchMapasMontajeSuccess (mapasMontaje) {
    return {
        type: ACTION_TYPES.FETCH_MAPAS_MONTAJE_SUCCESS,
        mapasMontaje
    }
}

function fetchMapasMontajeError (error) {
    return {
        type: ACTION_TYPES.FETCH_MAPAS_MONTAJE_ERROR,
        error
    }
}

function setNombre (nombre) {
    return {
        type: ACTION_TYPES.SET_NOMBRE,
        nombre
    }
}

function setCapaFondo (capaFondo) {
    return {
        type: ACTION_TYPES.SET_CAPA_FONDO,
        capaFondo
    }
}

function setVisible (visible) {
    return {
        type: ACTION_TYPES.SET_VISIBLE,
        visible
    }
}

function setDescripcion (descripcion) {
    return {
        type: ACTION_TYPES.SET_DESCRIPCION,
        descripcion
    }
}

function setMetaDescripcion (metaDescripcion) {
    return {
        type: ACTION_TYPES.SET_META_DESCRIPCION,
        metaDescripcion
    }
}

function resetMontajeEditando () {
    return {
        type: ACTION_TYPES.RESET_MONTAJE_EDITANDO
    }
}

function addElementoMontaje (elemento) {
    return {
        type: ACTION_TYPES.ADD_ELEMENTO_MONTAJE,
        elemento
    }
}

function removeElementoMontaje (elemento) {
    return {
        type: ACTION_TYPES.REMOVE_ELEMENTO_MONTAJE,
        elemento
    }
}

function createMapaMontajeBegin () {
    return {
        type: ACTION_TYPES.CREATE_MAPA_MONTAJE_BEGIN
    }
}

function createMapaMontajeSuccess (mapaMontaje) {
    return {
        type: ACTION_TYPES.CREATE_MAPA_MONTAJE_SUCCESS,
        mapaMontaje
    }
}

function createMapaMontajeError (error) {
    return {
        type: ACTION_TYPES.CREATE_MAPA_MONTAJE_ERROR,
        error
    }
}

function setMapaMontajeEditado (mapaMontaje) {
    return {
        type: ACTION_TYPES.SET_MAPA_MONTAJE_EDITANDO,
        mapaMontaje
    }
}

function updateMapaMontajeBegin () {
    return {
        type: ACTION_TYPES.UPDATE_MAPA_MONTAJE_BEGIN
    }
}

function updateMapaMontajeSuccess (mapaMontaje) {
    return {
        type: ACTION_TYPES.UPDATE_MAPA_MONTAJE_SUCCESS,
        mapaMontaje
    }
}

function updateMapaMontajeError (error) {
    return {
        type: ACTION_TYPES.UPDATE_MAPA_MONTAJE_ERROR,
        error
    }
}

function deleteMapaMontajeBegin () {
    return {
        type: ACTION_TYPES.DELETE_MAPA_MONTAJE_BEGIN
    }
}

function deleteMapaMontajeSuccess (mapaMontaje) {
    return {
        type: ACTION_TYPES.DELETE_MAPA_MONTAJE_SUCCESS,
        mapaMontaje
    }
}

function deleteMapaMontajeError (error) {
    return {
        type: ACTION_TYPES.DELETE_MAPA_MONTAJE_ERROR,
        error
    }
}

export const actions = {
    fetchMapasMontajeBegin,
    fetchMapasMontajeSuccess,
    fetchMapasMontajeError,
    setNombre,
    setCapaFondo,
    setVisible,
    setDescripcion,
    setMetaDescripcion,
    resetMontajeEditando,
    addElementoMontaje,
    removeElementoMontaje,
    createMapaMontajeBegin,
    createMapaMontajeSuccess,
    createMapaMontajeError,
    setMapaMontajeEditado,
    updateMapaMontajeBegin,
    updateMapaMontajeSuccess,
    updateMapaMontajeError,
    deleteMapaMontajeBegin,
    deleteMapaMontajeSuccess,
    deleteMapaMontajeError
}
