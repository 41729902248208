export const ACTION_TYPES = {
    ACTUALIZA_COORDS_LINEA: 'ACTUALIZA_COORDS_LINEA',
    ADD_LINEA: 'ADD_LINEA',
    FETCH_LINEAS_CORRECTO: 'FETCH_LINEAS_CORRECTO',
    FETCH_LINEAS_ERROR: 'FETCH_LINEAS_ERROR',
    FETCH_LINEAS_PENDIENTE: 'FETCH_LINEAS_PENDIENTE',
    LINEA_ACTUALIZANDO_CORRECTO: 'LINEA_ACTUALIZANDO_CORRECTO',
    LINEA_ACTUALIZANDO_ERROR: 'LINEA_ACTUALIZANDO_ERROR',
    LINEA_ACTUALIZANDO_PENDIENTE: 'LINEA_ACTUALIZANDO_PENDIENTE',
    LINEA_ELIMINADA_CORRECTO: 'LINEA_ELIMINADA_CORRECTO',
    LINEA_ELIMINADA_ERROR: 'LINEA_ELIMINADA_ERROR',
    LINEA_ELIMINADA_PENDIENTE: 'LINEA_ELIMINADA_PENDIENTE',
    LINEA_NUEVA_CORRECTO: 'LINEA_NUEVA_CORRECTO',
    LINEA_NUEVA_ERROR: 'LINEA_NUEVA_ERROR',
    LINEA_NUEVA_PENDIENTE: 'LINEA_NUEVA_PENDIENTE',
    REMOVE_LINEA: 'REMOVE_LINEA',
    RESET_NUEVA_LINEA: 'RESET_NUEVA_LINEA',
    SET_COLOR_LINEA: 'SET_COLOR_LINEA',
    SET_NOMBRE_LINEA: 'SET_NOMBRE_LINEA'
};

function fetchLineasCorrecto(lineas) {
    return {
        type: ACTION_TYPES.FETCH_LINEAS_CORRECTO,
        lineas: lineas
    };
}

function fetchLineasError(err) {
    return {
        type: ACTION_TYPES.FETCH_LINEAS_ERROR,
        error: err
    };
}

function fetchLineasPendiente() {
    return {
        type: ACTION_TYPES.FETCH_LINEAS_PENDIENTE
    };
}

function lineaNuevaCorrecto(linea) {
    return {
        type: ACTION_TYPES.LINEA_NUEVA_CORRECTO,
        linea: linea
    };
}

function lineaNuevaError(err) {
    return {
        type: ACTION_TYPES.LINEA_NUEVA_ERROR,
        error: err
    };
}

function lineaNuevaPendiente(){
    return {
        type: ACTION_TYPES.LINEA_NUEVA_PENDIENTE
    };
}

function lineaActualizandoCorrecto(linea) {
    return {
        type: ACTION_TYPES.LINEA_ACTUALIZANDO_CORRECTO,
        linea: linea
    };
}

function lineaActualizandoError(err){
    return {
        type: ACTION_TYPES.LINEA_ACTUALIZANDO_ERROR,
        error: err
    };
}

function lineaActualizandoPendiente() {
    return {
        type: ACTION_TYPES.LINEA_ACTUALIZANDO_PENDIENTE
    };
}

function addLinea(linea) {
    return {
        type: ACTION_TYPES.ADD_LINEA,
        linea: linea
    };
}

function removeLinea(linea) {
    return {
        type: ACTION_TYPES.REMOVE_LINEA,
        linea: linea
    };
}

function resetNuevaLinea() {
    return {
        type: ACTION_TYPES.RESET_NUEVA_LINEA
    };
}

function actualizaCoordsLinea(idLinea, coords){
    return{
        type: ACTION_TYPES.ACTUALIZA_COORDS_LINEA,
        idLinea: idLinea,
        coordenadas: coords
    };
}

function lineaEliminadaCorrecto(linea) {
    return {
        type: ACTION_TYPES.LINEA_ELIMINADA_CORRECTO,
        linea: linea
    };
}

function lineaEliminadaError(err) {
    return {
        type: ACTION_TYPES.LINEA_ELIMINADA_ERROR,
        error: err
    };
}

function lineaEliminadaPendiente(linea) {
    return{
        type: ACTION_TYPES.LINEA_ELIMINADA_PENDIENTE,
        linea: linea
    };
}

function setColorLinea(color, linea) {
    return {
        type: ACTION_TYPES.SET_COLOR_LINEA,
        color: color,
        linea: linea
    };
}

function setNombreLinea(nombre, linea) {
    return {
        type: ACTION_TYPES.SET_NOMBRE_LINEA,
        nombre: nombre,
        linea: linea
    };
}

export const actions = {
    fetchLineasCorrecto: fetchLineasCorrecto,
    fetchLineasError: fetchLineasError,
    fetchLineasPendiente: fetchLineasPendiente,
    lineaNuevaCorrecto: lineaNuevaCorrecto,
    lineaNuevaError: lineaNuevaError,
    lineaNuevaPendiente: lineaNuevaPendiente,
    lineaActualizandoCorrecto: lineaActualizandoCorrecto,
    lineaActualizandoError: lineaActualizandoError,
    lineaActualizandoPendiente: lineaActualizandoPendiente,
    addLinea: addLinea,
    removeLinea: removeLinea,
    resetNuevaLinea: resetNuevaLinea,
    actualizaCoordsLinea: actualizaCoordsLinea,
    lineaEliminadaCorrecto: lineaEliminadaCorrecto,
    lineaEliminadaError: lineaEliminadaError,
    lineaEliminadaPendiente: lineaEliminadaPendiente,
    setColorLinea: setColorLinea,
    setNombreLinea: setNombreLinea
};
