/* global fetch */
import { actions } from "../actions/voluntarios"
import { actions as actionsComun } from '../actions/comun'
import { actions as actionsError } from '../actions/error'
import { API_BASE_URL } from "../constants/api"
import getCsrfToken from "../lib/csrf"
import {createElementoVoluntarios, deleteElementoVoluntarios} from "./elementosVoluntarios";

export function fetchVoluntarios(params = {}, fields = {}) {
    return dispatch => {
        dispatch(actions.fetchVoluntariosBegin())
        dispatch(actionsComun.sumaPeticion())
        let url = new URL(API_BASE_URL + 'voluntarios/')
        if (fields && fields.length) {
            params['fields'] = fields.join(',')
        }
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )
        fetch(url, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw res.error
                } else if (res && res.length) {
                    dispatch(actions.fetchVoluntariosSuccess(res))
                    dispatch(actions.restaPeticion())
                } else {
                    dispatch(actionsComun.restaPeticion())
                }
            })
            .catch(err => {
                dispatch(actions.fetchVoluntariosError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function createVoluntarios (opciones, elementos) {
    return dispatch => {
        dispatch(actions.createVoluntariosBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'voluntarios/')
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createVoluntariosError(data))
                            dispatch(actionsError.hasError(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.createVoluntariosSuccess(data))
                            dispatch(actionsComun.restaPeticion())
                            if (elementos) {
                                for (let i in elementos) {
                                    dispatch(createElementoVoluntarios({
                                        elemento: elementos[i],
                                        voluntarios: data.id
                                    }))
                                }
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actions.createVoluntariosError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function deleteVoluntarios (voluntarios) {
    return dispatch => {
        dispatch(actions.deleteVoluntariosBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'voluntarios/' + voluntarios.id + '/')
        return fetch(url, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'X-CSRFToken': getCsrfToken()
            }
        })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    dispatch(actions.deleteVoluntariosSuccess(voluntarios))
                    dispatch(actionsComun.restaPeticion())
                }
            })
            .catch(err => {
                console.log(err)
                dispatch(actions.deleteVoluntariosError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function updateVoluntarios (idVoluntarios, opciones, prevElem, nextElem) {
    return dispatch => {
        dispatch(actions.updateVoluntariosBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'voluntarios/' + idVoluntarios + '/')
        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data))
                            dispatch(actionsComun.restaPeticion())
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.updateVoluntariosSuccess(data))
                            dispatch(actionsComun.restaPeticion())
                            if (prevElem && nextElem) {
                                for(let i in prevElem) {
                                    if (!nextElem.includes(prevElem[i])) {
                                        dispatch(deleteElementoVoluntarios({
                                            elemento: prevElem[i],
                                            voluntarios: data.id
                                        }))
                                    }
                                }
                                for (let i in nextElem) {
                                    if (!prevElem.includes(nextElem[i])) {
                                        dispatch(createElementoVoluntarios({
                                            elemento: nextElem[i],
                                            voluntarios: data.id
                                        }))
                                    }
                                }
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actions.updateVoluntariosError(err))
                dispatch(actionsError.hasError(err))
                dispatch(actionsComun.restaPeticion())
            })
    }
}
