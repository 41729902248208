import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/editor";
import {ACTION_TYPES as ACTIONS_TYPES_PT} from "../actions/puntos";
import {ACTION_TYPES as ACTIONS_TYPES_PL} from "../actions/poligonos";
import {ACTION_TYPES as ACTIONS_TYPES_LN} from "../actions/lineas";
import {ACTION_TYPES as ACTIONS_TYPES_TR} from "../actions/trayectos";
import {ACTION_TYPES as ACTION_TYPES_REC} from "../actions/recorridos";
import {ACTION_TYPES as ACTION_TYPES_SEC} from "../actions/sectores";

export default function editorReducer(state = initialState.editor, action) {
    switch (action.type) {
        case ACTION_TYPES.EDITOR_INICIADO:
            return {
                ...state,
                iniciado: true
            };
        case ACTION_TYPES.ABRIR_BARRA_IZQUIERDA:
            return {
                ...state,
                barraIzquierdaAbierta: true
            };
        case ACTION_TYPES.CERRAR_BARRA_IZQUIERDA:
            return {
                ...state,
                barraIzquierdaAbierta: false
            };
        case ACTION_TYPES.SET_CAPA_FONDO:
            return {
                ...state,
                capaFondo: action.capa
            };
        case ACTION_TYPES.SHOW_DROPDOWN_CAPAS:
            return {
                ...state,
                showDropdownCapas: true
            };
        case ACTION_TYPES.HIDE_DROPDOWN_CAPAS:
            return {
                ...state,
                showDropdownCapas: false
            };
        case ACTION_TYPES.ADD_CAPA_TRANSPARENTE:
            return {
                ...state,
                capasTransparentes: state.capasTransparentes.concat(action.capa)
            };
        case ACTION_TYPES.REMOVE_CAPA_TRANSPARENTE:
            return {
                ...state,
                capasTransparentes: state.capasTransparentes.filter(capaTrans => capaTrans.id !== action.capa.id)
            };
        case ACTION_TYPES.CAMBIAR_VISIBLE_MODAL_ANADIR_ELEMENTO:
            return {
                ...state,
                modalAnadirElementoVisible: action.visible
            };
        case ACTION_TYPES.SELECCIONAR_CAPA_BIBLIO:
            return{
                ...state,
                capaBiblioSeleccionada: action.capa
            };
        case ACTION_TYPES.CAMBIAR_VISIBLE_MODAL_MODO_CREAR_ELEMENTO:
            return {
                ...state,
                modalModoCrearElementoVisible: action.visible
            };
        case ACTION_TYPES.REMOVE_ELEMENTO_VISIBLE:
            return{
                ...state,
                elementosVisibles: state.elementosVisibles.filter(elemento => elemento.id !== action.elemento.id)
            };
        case ACTION_TYPES.SET_PRIMERA_CARGA:
            return {
                ...state,
                primeraCarga: false
            };
        case ACTION_TYPES.DIBUJANDO_TRAYECTO:
            return {
                ...state,
                dibujandoTrayecto: action.dibujando
            };
        case ACTION_TYPES.SELECCIONAR_BLOQUE:
            return {
                ...state,
                bloqueSeleccionado: action.bloque
            };
        case ACTION_TYPES.OBJETO_SELECCIONADO:
            return {
                ...state,
                objetoSeleccionado: action.objeto
            };
        case ACTION_TYPES.ADD_OBJETO_DIBUJADO:
            return {
                ...state,
                objetosDibujados: state.objetosDibujados.concat(action.objeto)
            };
        case ACTION_TYPES.REMOVE_OBJETO_DIBUJADO:
            return {
                ...state,
                objetosDibujados: state.objetosDibujados.filter(objeto => objeto.props.elemento.id !== action.objeto.props.elemento.id)
            };
        case ACTION_TYPES.CAMBIAR_MODO_BARRA_HERRAMIENTAS:
            return {
                ...state,
                modoBarraHerramientas: action.modo
            };
        case ACTION_TYPES.CAMBIAR_VISIBLE_BARRA_HERRAMIENTAS:
            return {
                ...state,
                barraHerramientasVisible: action.visible
            };
        case ACTION_TYPES.CAMBIAR_VISIBLE_MODAL_ELIMINAR_ELEMENTO:
            return {
                ...state,
                modalEliminarTrayectoVisible: action.visible
            };
        case ACTION_TYPES.SET_DUPLICANDO:
            return {
                ...state,
                duplicando: action.duplicando
            };
        case ACTION_TYPES.SET_UNIENDO:
            return {
                ...state,
                uniendo: action.uniendo
            };
        case ACTIONS_TYPES_LN.FETCH_LINEAS_CORRECTO:
            return {
                ...state,
                featuresIniciales: {
                    ...state.featuresIniciales,
                    lineas: state.featuresIniciales.lineas.concat(action.lineas)
                }
            };
        case ACTIONS_TYPES_PL.FETCH_POLIGNOS_CORRECTO:
            return {
                ...state,
                featuresIniciales: {
                    ...state.featuresIniciales,
                    poligonos: state.featuresIniciales.poligonos.concat(action.poligonos)
                }
            };
        case ACTIONS_TYPES_PT.FETCH_PUNTOS_CORRECTO:
            return {
                ...state,
                featuresIniciales: {
                    ...state.featuresIniciales,
                    puntos: state.featuresIniciales.puntos.concat(action.puntos)
                }
            };
        case ACTIONS_TYPES_TR.FETCH_TRAYECTOS_CORRECTO:
            return {
                ...state,
                featuresIniciales: {
                    ...state.featuresIniciales,
                    lineas: state.featuresIniciales.lineas.concat(action.trayectos)
                }
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_PROP_AVANZADAS:
            return {
                ...state,
                modalPropAvanzadasVisible: action.visible
            };
        case ACTION_TYPES.SET_PREVIEW_IMAGEN_SUBIDA:
            return {
                ...state,
                previewImagenSubida: action.imagen
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_SUBIR_TRACK:
            return {
                ...state,
                modalSubirTrackVisible: action.visible
            };
        case ACTION_TYPES.SET_PICK_DISPLAY_DISTANCIA:
            return {
                ...state,
                displayDistanciasPick: action.pick
            };
        case ACTIONS_TYPES_PT.PUNTO_NUEVO_ELEMENTO:
        case ACTIONS_TYPES_LN.LINEA_NUEVA_CORRECTO:
        case ACTIONS_TYPES_PL.POLIGONO_NUEVO_CORRECTO:
        case ACTION_TYPES.ADD_ELEMENTO_VISIBLE:
            const elementoAct = action.trayecto ? action.trayecto : (action.punto ? action.punto : (action.linea ?
                action.linea : (action.poligono ? action.poligono : action.elemento)));
            return {
                ...state,
                elementosVisibles: state.elementosVisibles.concat(elementoAct)
            };
        case ACTION_TYPES.ADD_CAPA_COLAPSADA:
            return {
                ...state,
                capasColapsadasBarra: state.capasColapsadasBarra.concat(action.capa)
            };
        case ACTION_TYPES.REMOVE_CAPA_COLAPSADA:
            return {
                ...state,
                capasColapsadasBarra: state.capasColapsadasBarra.filter(capa => capa.id !== action.capa.id)
            };
        case ACTION_TYPES.SET_SHOW_DROPDOWN_RECORRIDOS:
            return {
                ...state,
                showDropdownRecorridos: action.show
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_NUEVO_RECORRIDO:
            return {
                ...state,
                modalNuevoRecorridoVisible: action.visible
            };
        case ACTION_TYPES.SET_DISTANCIA_DISPLAY:
        case ACTION_TYPES.SET_AREA_DISPLAY:
        case ACTION_TYPES.SET_LATITUD_DISPLAY:
        case ACTION_TYPES.SET_LONGITUD_DISPLAY:
            return {
                ...state,
                displayMediciones: displayDistanciasReducer(state.displayMediciones, action)
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_RECORRIDO:
            return {
                ...state,
                modalEditarRecorridoVisible: action.visible
            };
        case ACTION_TYPES.ADD_RECORRIDO_ASOCIAR:
            return {
                ...state,
                recorridosAsociar: state.recorridosAsociar.concat(action.recorrido)
            };
        case ACTION_TYPES.REMOVE_RECORRIDO_ASOCIAR:
            return {
                ...state,
                recorridosAsociar: state.recorridosAsociar.filter(recorrido => recorrido.id !== action.recorrido.id)
            };
        case ACTION_TYPES.RESET_RECORRIDO_ASOCIAR:
            return {
                ...state,
                recorridosAsociar: []
            };
        case ACTION_TYPES.ADD_ALL_ELEMENTOS_VISIBLES:
            return {
                ...state,
                elementosVisibles: action.elementos
            };
        case ACTION_TYPES.REMOVE_ALL_ELEMENTOS_VISIBLES:
            return {
                ...state,
                elementosVisibles: []
            };
        case ACTION_TYPES.SET_FILTRO_VISIBLE_ACTIVO:
            return {
                ...state,
                filtroVisiblesActivo: action.valor
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_PREFERENCIAS:
            return {
                ...state,
                modalPreferenciasVisible: action.visible
            };
        case ACTIONS_TYPES_TR.ACTUALIZA_COORDS_TRAYECTO:
            if(action.noGuarda) return {
                ...state,
                ultimaGeometriaActualizada: {tipo:'tr', id: action.idTrayecto, geometria: action.coordenadas}
            };
            else return { ...state };
        case ACTION_TYPES.ADD_ACCION_UNDO:
            state.redoHistory.pop();
            return {
                ...state,
                undoHistory: state.undoHistory.concat(action.accion),
                redoHistory: action.clearRedo ? [] : state.redoHistory
            };
        case ACTION_TYPES.ADD_ACCION_REDO:
            const ultimo = state.undoHistory.pop();
            return {
                ...state,
                undoHistory: state.undoHistory,
                redoHistory: state.redoHistory.concat(ultimo)
            };
        case ACTION_TYPES.CLEAR_ULTIMA_GEOMETRIA:
            return {
                ...state,
                ultimaGeometriaActualizada: null
            };
        case ACTION_TYPES.ADD_PK_POR_ANADIR:
            return {
                ...state,
                pksPorAnadir: state.pksPorAnadir.concat(action.pk)
            };
        case ACTION_TYPES.REMOVE_PK_POR_ANADIR:
            return {
                ...state,
                pksPorAnadir: state.pksPorAnadir.filter(pk =>
                    pk.punto !== action.punto && pk.sector !== action.sector)
            };
        case ACTION_TYPES.RESET_PK_POR_ANADIR:
            return {
                ...state,
                pksPorAnadir: []
            };
        case ACTION_TYPES.SET_SERVICIOS_ANADIDOS:
            return {
                ...state,
                serviciosAnadidos: action.servicios
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_ELIMINAR_RECORRIDO:
            return {
                ...state,
                modalEliminarRecorridoVisible: action.visible
            };
        case ACTION_TYPES.SET_RECORRIDOS_POR_ELIMINAR:
            return {
                ...state,
                recorridosPorEliminar: action.recorridos
            };
        case ACTION_TYPES.ADD_RECORRIDO_POR_ELIMINAR:
            return {
                ...state,
                recorridosPorEliminar: state.recorridosPorEliminar.concat(action.recorrido),
                recorridosReorden: state.recorridosReorden.filter(recorrido => recorrido.id !== action.recorrido.id)
            };
        case ACTION_TYPES.REMOVE_RECORRIDO_POR_ELIMINAR:
            const nuevoRec = Object.assign({}, action.recorrido)
            if (!state.recorridosReorden.length) {
                return {
                    ...state
                }
            }
            nuevoRec.orden = state.recorridosReorden[state.recorridosReorden.length - 1].orden + 1
            return {
                ...state,
                recorridosPorEliminar: state.recorridosPorEliminar.filter(r => r.id !== action.recorrido.id),
                recorridosReorden: state.recorridosReorden.concat(nuevoRec)
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_ORGANIZAR_RECORRIDOS:
            return {
                ...state,
                modalOrganizarRecorridosVisible: action.visible
            };
        case ACTION_TYPES.SET_RECORRIDOS_REORDEN:
            return {
                ...state,
                recorridosReorden: JSON.parse(JSON.stringify(action.recorridos))
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_PERFIL:
            return {
                ...state,
                modalPerfilVisible: action.visible
            };
        case ACTION_TYPES.SET_INDEX_SECTOR_PERFIL:
            return {
                ...state,
                indexSectorPerfil: action.index
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_ANADIR_SERVICIOS:
            return {
                ...state,
                modalAnadirServiciosVisible: action.visible
            };
        case ACTION_TYPES.ADD_SERVICIO_SELECCIONADO:
            return {
                ...state,
                serviciosSeleccionados: state.serviciosSeleccionados.concat(action.servicio)
            };
        case ACTION_TYPES.REMOVE_SERVICIO_SELECIONADO:
            return {
                ...state,
                serviciosSeleccionados: state.serviciosSeleccionados.filter(serv => serv.id !== action.servicio.id)
            };
        case ACTION_TYPES.RESET_SERVICIOS_SELECCIONADOS:
            return {
                ...state,
                serviciosSeleccionados: []
            };
        case ACTION_TYPES.SET_PDI_EDITAR:
            return {
                ...state,
                pdiEditar: action.pdi
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_SERVICIOS:
            return{
                ...state,
                modalEditarServiciosVisible: action.visible
            };
        case ACTION_TYPES_REC.RECORRIDO_ACTUALIZANDO_PENDIENTE:
        case ACTION_TYPES_REC.NUEVO_RECORRIDO_PENDIENTE:
            return {
                ...state,
                recorridoCompletoCreado: false,
                totalEsquemasCrear: action.totalEsquemas,
                contadorEsquemasCreados: 0
            };
        case ACTION_TYPES_SEC.ESQUEMA_ACTUALIZANDO_CORRECTO:
        case ACTION_TYPES_SEC.NUEVO_TRAYECTO_ESQUEMA_CORRECTO:
            return{
                ...state,
                recorridoCompletoCreado: (state.contadorEsquemasCreados + 1) === state.totalEsquemasCrear,
                contadorEsquemasCreados: state.contadorEsquemasCreados + 1,
            };
        case ACTION_TYPES_REC.RECORRIDO_ACTUALIZANDO_CORRECTO:
        case ACTION_TYPES_REC.NUEVO_RECORRIDO_CORRECTO:
            return {
                ...state,
                idUltimoRecorridoCreado: action.recorrido.id
            };
        case ACTION_TYPES.SET_ACTIVE_KEY_SECUENCIA_SECTOR:
            return {
                ...state,
                activeKeySecuenciaSector: action.activeKey
            };
        case ACTION_TYPES.SET_PUBLICADO_PK_POR_ANADIR:{
            const list = state.pksPorAnadir.slice();
            const indexPk = list.map(pk => pk.punto.id).indexOf(action.punto.id);
            list[indexPk].publicado = action.publicado;
            return {
                ...state,
                pksPorAnadir: list
            }
        }
        case ACTION_TYPES.SET_PESTANA_ACTIVA_RECORRIDO:
            return {
                ...state,
                pestanaActivaRecorrido: action.pestanaActiva
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_BORRAR_PKS:
            return {
                ...state,
                modalBorrarPksVisible: action.visible
            };
        case ACTION_TYPES.SET_SECTOR_BORRAR_PKS:
            return {
                ...state,
                sectorBorrarPks: action.sector
            };
        case ACTION_TYPES.SET_PKS_BORRADOS:
            return {
                ...state,
                pksBorrados: action.value
            };
        case ACTION_TYPES_REC.ELIMINA_PKS_RECORRIDO_CREANDO:
            return {
                ...state,
                pksBorrados: action.sector
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_CONFIRMAR_RECORTE:
            return {
                ...state,
                modalConfirmarRecorteVisible: action.visible
            };
        case ACTION_TYPES.SET_AVISO_DISTANCIA_MOSTRADO:
            return {
                ...state,
                avisoDistanciaMostrado: action.mostrado
            };

        case ACTION_TYPES.PREVISUALIZAR_MAPA:
            return {
                ...state,
                urlPrevisualizacion: action.url
            };
        case ACTION_TYPES.TOGGLE_PREVISUALIZAR_MAPA:
            return {
                ...state,
                showDropdownPrevisualizar: !state.showDropdownPrevisualizar
            };
        case ACTION_TYPES.ADD_AGRUPACION_COLAPSADA:
            return {
                ...state,
                agrupacionesColapsadas: state.agrupacionesColapsadas.concat(action.agrupacion)
            };
        case ACTION_TYPES.REMOVE_AGRUPACION_COLAPSADA:
            return {
                ...state,
                agrupacionesColapsadas: state.agrupacionesColapsadas.filter(ag =>
                    ag.id !== (action.agrupacion.id ? action.agrupacion.id : action.agrupacion))
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_NOMBRE_AGRUPACION:
            return {
                ...state,
                modalNombreAgrupacionVisible: action.visible
            };
        case ACTION_TYPES.SET_AGRUPACION_SELECCIONADA:
            return {
                ...state,
                agrupacionSeleccionada: action.agrupacion
            };
        case ACTION_TYPES.ADD_FORMA_FICHERO_SUBIDO:
            return {
                ...state,
                formasFicheroSubido: state.formasFicheroSubido.concat(action.forma)
            };
        case ACTION_TYPES.RESET_FORMAS_FICHERO_SUBIDO:
            return {
                ...state,
                formasFicheroSubido: []
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_SELECCIONAR_FORMA:
            return {
                ...state,
                modalSeleccionarFormaVisible: action.visible
            };
        case ACTION_TYPES.SET_ELEMENTO_RECIEN_SUBIDO:
            return {
                ...state,
                elementoRecienSubido: action.elemento
            };
        case ACTION_TYPES.SET_MUESTRA_AVISO_DISTANCIA:
            return {
                ...state,
                muestraAvisoDistancia: action.mostrar
            };
        case ACTION_TYPES.SET_FILTRO_ANADIR_ELEMENTOS:
            return {
                ...state,
                filtroAnadirElementos: action.filtro
            };
        case ACTION_TYPES.SET_ANIMACION_CREAR_RECORRIDO:
            return {
                ...state,
                animacionCrearRecorrido: action.activa
            };
        case ACTION_TYPES.ADD_SECTOR_ASOCIADO:
            return {
                ...state,
                sectoresAsociados: state.sectoresAsociados.concat(action.sector)
            };
        case ACTION_TYPES.REMOVE_SECTOR_ASOCIADO:
            return {
                ...state,
                sectoresAsociados: state.sectoresAsociados.filter(sec => sec.id !== action.sector.id)
            };
        case ACTION_TYPES.RESET_SECTORES_ASOCIADOS:
            return {
                ...state,
                sectoresAsociados: []
            };
        case ACTION_TYPES.SET_GRUPO_BLOQUES_SELECCIONADO:
            return {
                ...state,
                grupoBloquesSeleccionado: action.grupo
            };
        case ACTION_TYPES.SET_VISIBLE_MODAL_IMPORTAR:
            return {
                ...state,
                modalImportarVisible: action.visible
            }
        case ACTION_TYPES.ADD_ELEMENTO_IMPORTAR:
            return {
                ...state,
                elementosImportar: state.elementosImportar.concat(action.elemento)
            }
        case ACTION_TYPES.REMOVE_ELEMENTO_IMPORTAR:
            return {
                ...state,
                elementosImportar: state.elementosImportar.filter(e => e.id !== action.elemento.id)
            }
        case ACTION_TYPES.RESET_ELEMENTOS_IMPORTAR:
            return {
                ...state,
                elementosImportar: []
            }
        case ACTION_TYPES.SET_ELEMENTO_IMPORTADO:
            return {
                ...state,
                ultimoElementoImportado: action.elemento
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_BIENVENIDA:
            return {
                ...state,
                modalBienvenidoVisible: action.visible
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_VOLUNTARIOS:
            return {
                ...state,
                modalVoluntariosVisible: action.visible
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_NUEVO_VOLUNTARIOS:
            return {
                ...state,
                modalNuevoVoluntariosVisible: action.visible
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_ELIMINAR_VOLUNTARIOS:
            return {
                ...state,
                modalEliminarVoluntariosVisible: action.visible
            }
        case ACTION_TYPES.SET_VOLUNTARIOS_ELIMINAR:
            return {
                ...state,
                voluntariosEliminar: action.voluntarios
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_VOLUNTARIOS:
            return {
                ...state,
                modalEditarVoluntariosVisible: action.visible
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_TRAFICO:
            return {
                ...state,
                modalTraficoVisible: action.visible
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_NUEVO_TRAFICO:
            return {
                ...state,
                modalNuevoTraficoVisible: action.visible
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_ELIMINAR_TRAFICO:
            return {
                ...state,
                modalEliminarTraficoVisible: action.visible
            }
        case ACTION_TYPES.SET_TRAFICO_ELIMINAR:
            return {
                ...state,
                traficoEliminar: action.mapaTrafico
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_TRAFICO:
            return {
                ...state,
                modalEditarTraficoVisible: action.visible
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_MONTAJE:
            return {
                ...state,
                modalMontajeVisible: action.visible
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_NUEVO_MONTAJE:
            return {
                ...state,
                modalNuevoMontajeVisible: action.visible
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_EDITAR_MONTAJE:
            return {
                ...state,
                modalEditarMontajeVisible: action.visible
            }
        case ACTION_TYPES.SET_MONTAJE_ELIMINAR:
            return {
                ...state,
                montajeEliminar: action.montaje
            }
        case ACTION_TYPES.SET_VISIBLE_MODAL_ELIMINAR_MONTAJE:
            return {
                ...state,
                modalEliminarMontajeVisible: action.visible
            }
        default:
            return {
                ...state
            };
    }
}


function displayDistanciasReducer(state, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_DISTANCIA_DISPLAY:
            return {
                ...state,
                distancia: action.distancia
            };
        case ACTION_TYPES.SET_AREA_DISPLAY:
            return {
                ...state,
                area: action.area
            };
        case ACTION_TYPES.SET_LONGITUD_DISPLAY:
            return {
                ...state,
                longitud: action.longitud
            };
        case ACTION_TYPES.SET_LATITUD_DISPLAY:
            return {
                ...state,
                latitud: action.latitud
            };
        default:
            return {
                ...state
            };
    }
}

export const getEditorIniciado = state => state.editor.iniciado;
export const getBarraIzquierdaAbierta = state => state.editor.barraIzquierdaAbierta;
export const getCapaFondoActiva = state => state.editor.capaFondo;
export const getShowDropdownCapas = state => state.editor.showDropdownCapas;
export const getCapasTransparentesAtivas = state => state.editor.capasTransparentes;
export const getModalAnadirElementoVisible = state => state.editor.modalAnadirElementoVisible;
export const getCapaBiblioSeleccionada = state => state.editor.capaBiblioSeleccionada;
export const getModalModoCrearElementoVisible = state => state.editor.modalModoCrearElementoVisible;
export const getPrimeraCarga = state => state.editor.primeraCarga;
export const getElementosVisibles = state => state.editor.elementosVisibles;
export const getDibujandoTrayecto = state => state.editor.dibujandoTrayecto;
export const getBloqueSeleccionado = state => state.editor.bloqueSeleccionado;
export const getObjetoSeleccionado = state => state.editor.objetoSeleccionado;
export const getObjetosDibujados = state => state.editor.objetosDibujados;
export const getModoBarraHerramientas = state => state.editor.modoBarraHerramientas;
export const getBarraHerramientasVisible = state => state.editor.barraHerramientasVisible;
export const getModalElimarTrayectoVisible = state => state.editor.modalEliminarTrayectoVisible;
export const getDuplicando = state => state.editor.duplicando;
export const getUniendo = state => state.editor.uniendo;
export const getFeaturesIniciales = state => state.editor.featuresIniciales;
export const getModalPropAvanzadasVisible = state => state.editor.modalPropAvanzadasVisible;
export const getPreviewImagenSubida = state => state.editor.previewImagenSubida;
export const getModalSubirTrackVisible = state => state.editor.modalSubirTrackVisible;
export const getDisplayDistanciaPick = state => state.editor.displayDistanciasPick;
export const getCapasColapsadasBarra = state => state.editor.capasColapsadasBarra;
export const getShowDropdownRecorridos = state => state.editor.showDropdownRecorridos;
export const getModalNuevoRecorridoVisible = state => state.editor.modalNuevoRecorridoVisible;
export const getDistanciaDisplay = state => state.editor.displayMediciones.distancia;
export const getAreaDisplay = state => state.editor.displayMediciones.area;
export const getLatitudDisplay = state => state.editor.displayMediciones.latitud;
export const getLongitudDisplay = state => state.editor.displayMediciones.longitud;
export const getModalEditarRecorridoVisilbe = state => state.editor.modalEditarRecorridoVisible;
export const getRecorridoAsociar = state => state.editor.recorridosAsociar;
export const getFiltroVisibleActivo = state => state.editor.filtroVisiblesActivo;
export const getModalPreferenciasVisible = state => state.editor.modalPreferenciasVisible;
export const getUltimaGeometriaActualizada = state => state.editor.ultimaGeometriaActualizada;
export const getUndoHistory = state => state.editor.undoHistory;
export const getRedoHistory = state => state.editor.redoHistory;
export const getPksPorAnadir = state => state.editor.pksPorAnadir;
export const getServiciosAnadidos = state => state.editor.serviciosAnadidos;
export const getRecorridosPorEliminar = state => state.editor.recorridosPorEliminar;
export const getModalEliminarRecorridoVisible = state => state.editor.modalEliminarRecorridoVisible;
export const getModalOrganizarRecorridosVisible = state => state.editor.modalOrganizarRecorridosVisible;
export const getRecorridosReorden = state => state.editor.recorridosReorden;
export const getModalPerfilVisible = state => state.editor.modalPerfilVisible;
export const getIndexSectorPerfil = state => state.editor.indexSectorPerfil;
export const getModalAnadirServiciosVisible = state => state.editor.modalAnadirServiciosVisible;
export const getServiciosSeleccionados = state => state.editor.serviciosSeleccionados;
export const getPdiEditar = state => state.editor.pdiEditar;
export const getModalEditarServiciosVisible = state => state.editor.modalEditarServiciosVisible;
export const getRecorridoCompletoCreado = state => state.editor.recorridoCompletoCreado;
export const getIdUltimoRecorridoCreado = state => state.editor.idUltimoRecorridoCreado;
export const getActiveKeySecuenciaSector = state => state.editor.activeKeySecuenciaSector;
export const getPestanaActivaRecorrido = state => state.editor.pestanaActivaRecorrido;
export const getModalBorrarPksVisible = state => state.editor.modalBorrarPksVisible;
export const getSectorBorrarPks = state => state.editor.sectorBorrarPks;
export const getPksBorrados = state => state.editor.pksBorrados;
export const getModalConfirmarRecorteVisible = state => state.editor.modalConfirmarRecorteVisible;
export const getAvisoDistanciaMostrado = state => state.editor.avisoDistanciaMostrado;
export const getAgrupacionesColapsadas = state => state.editor.agrupacionesColapsadas;
export const getAgrupacionSeleccionada = state => state.editor.agrupacionSeleccionada;
export const getModalNombreAgrupacionVisible = state => state.editor.modalNombreAgrupacionVisible;
export const getFormasFicheroSubido = state => state.editor.formasFicheroSubido;
export const getModalSeleccionarFormaVisible = state => state.editor.modalSeleccionarFormaVisible;
export const getElementoRecienSubido = state => state.editor.elementoRecienSubido;
export const getMuestraAvisoDistancia = state => state.editor.muestraAvisoDistancia;
export const getFiltroAnadirElemento = state => state.editor.filtroAnadirElementos;
export const getAnimacionCrearRecorrido = state => state.editor.animacionCrearRecorrido;
export const getSectoresAsociados = state => state.editor.sectoresAsociados;
export const getGrupoBloquesSeleccionado = state => state.editor.grupoBloquesSeleccionado;
