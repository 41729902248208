import { initialState } from "../constants/state"
import { ACTION_TYPES } from "../actions/trafico"
import { ACTION_TYPES as ACTION_TYPES_ELM } from '../actions/elementosTrafico'

export function mapasTraficoReducer (state = initialState.mapasTrafico, action) {
    switch (action.type) {
        case ACTION_TYPES.FETCH_MAPAS_TRAFICO_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: [],
                error: null
            }

        case ACTION_TYPES.FETCH_MAPAS_TRAFICO_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.mapasTrafico
            }

        case ACTION_TYPES.FETCH_MAPAS_TRAFICO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        case ACTION_TYPES.CREATE_MAPA_TRAFICO_SUCCESS:
            return {
                ...state,
                data: state.data.concat(action.mapaTrafico)
            }

        case ACTION_TYPES.DELETE_MAPA_TRAFICO_SUCCESS:
            return {
                ...state,
                data: state.data.filter(mt => mt.id !== action.mapaTrafico.id)
            }

        case ACTION_TYPES.UPDATE_MAPA_TRAFICO_SUCCESS:
            return {
                ...state,
                data: state.data.filter(mt => mt.id !== action.mapaTrafico.id).concat(action.mapaTrafico)
            }

        case ACTION_TYPES_ELM.CREATE_ELEMENTO_TRAFICO_SUCCESS:
            return {
                ...state,
                data: state.data.map(mt => {
                    if (mt.id === action.elementoTrafico.trafico) {
                        mt.elementos_trafico = mt.elementos_trafico
                            ? mt.elementos_trafico.concat(action.elementoTrafico.elemento.id)
                            : [action.elementoTrafico.elemento.id]
                    }
                    return mt
                })
            }

        case ACTION_TYPES_ELM.DELETE_ELEMENTO_TRAFICO_SUCCESS:
            return {
                ...state,
                data: state.data.map(mt => {
                    if (mt.id === action.elementoTrafico.trafico) {
                        mt.elementos_trafico = mt.elementos_trafico
                            ? mt.elementos_trafico.filter(e => e !== action.elementoTrafico.elemento)
                            : []
                    }
                    return mt
                })
            }

        default:
            return {
                ...state
            }
    }
}


export function traficoEditandoReducer (state = initialState.traficoEditando, action) {
    switch (action.type) {
        case ACTION_TYPES.SET_NOMBRE:
            return {
                ...state,
                nombre: action.nombre
            }

        case ACTION_TYPES.SET_CAPA_FONDO:
            return {
                ...state,
                capa_fondo: action.capaFondo
            }

        case ACTION_TYPES.RESET_TRAFICO_EDITANDO:
            return {
                ...state,
                nombre: '',
                capa_fondo: 11,
                visible: true,
                descripcion: '',
                meta_descripcion: '',
                elementos_trafico: []
            }

        case ACTION_TYPES.SET_VISIBLE:
            return {
                ...state,
                visible: action.visible
            }

        case ACTION_TYPES.SET_DESCRIPCION:
            return {
                ...state,
                descripcion: action.descripcion
            }

        case ACTION_TYPES.SET_META_DESCRIPCION:
            return {
                ...state,
                meta_descripcion: action.metaDescripcion
            }

        case ACTION_TYPES.ADD_ELEMENTO_TRAFICO:
            return {
                ...state,
                elementos_trafico: state.elementos_trafico.concat(action.elemento)
            }

        case ACTION_TYPES.REMOVE_ELEMENTO_TRAFICO:
            return {
                ...state,
                elementos_trafico: state.elementos_trafico.filter(e => e !== action.elemento)
            }

        case ACTION_TYPES.SET_MAPA_TRAFICO_EDITANDO:
            return JSON.parse(JSON.stringify(action.mapaTrafico))

        default:
            return {
                ...state
            }
    }
}

export function mapaTraficoCreateReducer (state = initialState.mapaTraficoCreate, action) {
    switch (action.type) {
        case ACTION_TYPES.CREATE_MAPA_TRAFICO_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.CREATE_MAPA_TRAFICO_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.mapaTrafico
            }

        case ACTION_TYPES.CREATE_MAPA_TRAFICO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}

export function mapaTraficoDeleteReducer (state = initialState.mapaTraficoDelete, action) {
    switch (action.type) {
        case ACTION_TYPES.DELETE_MAPA_TRAFICO_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.DELETE_MAPA_TRAFICO_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.mapaTrafico
            }

        case ACTION_TYPES.DELETE_MAPA_TRAFICO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }

    }
}

export function mapaTraficoUpdateReducer (state = initialState.mapaTraficoUpdate, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_MAPA_TRAFICO_BEGIN:
            return {
                ...state,
                pendiente: true,
                data: null,
                error: null
            }

        case ACTION_TYPES.UPDATE_MAPA_TRAFICO_SUCCESS:
            return {
                ...state,
                pendiente: false,
                data: action.mapaTrafico
            }

        case ACTION_TYPES.UPDATE_MAPA_TRAFICO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}
