import React, {Component} from 'react';
import {Marker, Polygon} from "react-leaflet";
import * as L from "leaflet";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as turf from "@turf/turf";
import invert from "invert-color";

import {actions} from "../actions/editor";
import {actions as actionsPoligonos} from "../actions/poligonos";
import {getElementoRecienSubido, getObjetoSeleccionado} from "../reducers/editor";
import {actualizaPoligono, creaNuevoPoligono} from "../fetchactions/poligonos";
import {getProyecto} from "../reducers/proyectos";
import {Icon} from "../lib/leaflet.awesome-markers-svg";


class Poligono extends Component {

    constructor(props) {
        super(props);
        this.poligonRef = React.createRef();
        this.markerRef = React.createRef();
        this.montado = true;
        this.eliminado = false;
        this.seleccionado = false;
        this.type = 'pl';

        this.onClick = this.onClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.onMapSecondClick = this.onMapSecondClick.bind(this);
        this.onContextmenu = this.onContextmenu.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentDidMount() {
        const {objetoSeleccionado, addObjetoDibujado, elemento} = this.props;
        addObjetoDibujado(this);
        if(objetoSeleccionado && objetoSeleccionado.type === 'pl' && objetoSeleccionado.props.elemento.id === elemento.id)
            this.seleccionar(true);
        this.montado = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { elementoRecienSubido, setElementoRecienSubido, setVisibleModalPropAvanzadas, elemento } = this.props;
        if (this.seleccionado) {
            const poligon = this.poligonRef.current.leafletElement;
            poligon.disableEdit();
            poligon.enableEdit();
        }

        if (elementoRecienSubido && elementoRecienSubido.id === elemento.id) {
            this.seleccionar();
            setElementoRecienSubido(null);
            setVisibleModalPropAvanzadas(true);
        }
    }

    onClick(e) {
        const {objetoSeleccionado, elemento} = this.props;
        e.originalEvent.stopPropagation();
        if(!objetoSeleccionado || objetoSeleccionado.props.elemento.id !== elemento.id)
            this.seleccionar(true);
    }

    onMapClick(e) {
        const map = this.props.map.current.leafletElement;
        map.off('click',this.onMapClick);
        if(!this.isInDistanciaSeguridad(e)){
            if(this.seleccionado) {
                this.deseleccionar()
            }
        } else {
            map.on('click', this.onMapSecondClick);
        }

    }

    onMapSecondClick(e) {
        const map = this.props.map.current.leafletElement;
        map.off('click', this.onMapSecondClick);
        this.onMapClick(e);
    }

    isInDistanciaSeguridad(e) {
        const map = this.props.map.current.leafletElement;
        const poligon = this.poligonRef.current.leafletElement;
        const puntoCercando = turf.nearestPointOnLine(turf.polygonToLine(poligon.toGeoJSON().geometry),
            [e.latlng.lng, e.latlng.lat]);
        const puntoPantalla = map.latLngToContainerPoint(L.latLng(puntoCercando.geometry.coordinates[1],
            puntoCercando.geometry.coordinates[0]));
        const dist = Math.sqrt((puntoPantalla.x - e.containerPoint.x) * (puntoPantalla.x - e.containerPoint.x)  +
            (puntoPantalla.y - e.containerPoint.y) * (puntoPantalla.y - e.containerPoint.y));
        return dist < 20
    }

    seleccionar(noCentrar) {
        const {objetoSeleccionado, setObjetoSeleccionado, cambiarModoBarraHerramientas, removeAgrupacionColapsada,
            cambiarVisibleBarraHerramientas, removeCapaColapsada, elemento} = this.props;
        const poligon = this.poligonRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;
        if(objetoSeleccionado && objetoSeleccionado.montado) objetoSeleccionado.deseleccionar();
        poligon.enableEdit();
        this.seleccionado = true;
        cambiarModoBarraHerramientas('pl');
        cambiarVisibleBarraHerramientas(true);
        if(!noCentrar) this.centrarEnMi();
        setObjetoSeleccionado(this);
        removeCapaColapsada(elemento.bloque.capa);
        removeAgrupacionColapsada(elemento.agrupacion);
        document.addEventListener('keyup', this.onKeyPress);
        setTimeout(() => {                          //esto non deberia ser necesario pero senon non funciona conmutar entre lineas
            map.once('click', this.onMapClick);
        }, 20);
    }

    deseleccionar(fn) {
        const {setObjetoSeleccionado, elemento, cambiarVisibleBarraHerramientas, actualizaPoligono,
            actualizaCoordsPoligono} = this.props;
        const poligon = this.poligonRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;
        poligon.disableEdit();
        actualizaCoordsPoligono(elemento.id, poligon.toGeoJSON().geometry);
        actualizaPoligono(elemento.id, {
            nombre: elemento.nombre,
            bloque: elemento.bloque.id,
            coordenadas: poligon.toGeoJSON().geometry
        });
        this.seleccionado = false;
        setObjetoSeleccionado(null);
        cambiarVisibleBarraHerramientas(false);
        document.removeEventListener('keyup', this.onKeyPress);
        map.off('click', this.onMapClick);
        if(fn) fn();
    }

    centrarEnMi(){
        const poligon = this.poligonRef.current.leafletElement;
        const map = this.props.map.current.leafletElement;
        map.flyToBounds(poligon.getBounds());
    }

    getLatLngs() {
        const {elemento} = this.props;
        let latLngs = [];
        for(let i in elemento.coordenadas.coordinates[0]) {
            latLngs.push(L.latLng(elemento.coordenadas.coordinates[0][i][1], elemento.coordenadas.coordinates[0][i][0]));
        }
        return latLngs
    }

    duplicar(paralelo, negativo) {
        const {t, elemento, proyecto, setDuplicando, creaNuevoPoligono} = this.props;
        const linePoligon = turf.polygonToLine(elemento.coordenadas);
        const offsetLine = turf.lineOffset(linePoligon, negativo ? -3 : 3, {units: 'meters'});
        const sufijo = paralelo ? t('paralelo').toLowerCase() + (negativo ? '-':'+') : t('duplicado').toLowerCase();
        setDuplicando(true);
        creaNuevoPoligono({
            nombre: elemento.nombre + '-' + sufijo,
            color: paralelo ? elemento.color : invert(elemento.color),
            grosor_linea: elemento.grosor_linea,
            bloque: elemento.bloque.id,
            patron_linea: elemento.patron_linea.id,
            opacidad_fondo: elemento.opacidad_fondo,
            proyecto: proyecto.id,
            coordenadas: paralelo ? turf.lineToPolygon(offsetLine, {orderCoords: false}).geometry : elemento.coordenadas
        });
    }

    getUltimasCoords() {
        const poligon = this.poligonRef.current.leafletElement;
        return poligon.toGeoJSON().geometry;
    }

    onContextmenu(e) {
        const {objetoSeleccionado, elemento, setVisibleModalPropAvanzadas} = this.props;
        e.originalEvent.stopPropagation();
        if(!objetoSeleccionado || objetoSeleccionado.props.elemento.id !== elemento.id)
            this.seleccionar(true);
        setVisibleModalPropAvanzadas(true)
    }

    onKeyPress(e) {
        if(e.keyCode === 27) {
            if(this.seleccionado) {
                this.deseleccionar();
            }
        }
        /*else if(e.keyCode === 46) { // para eliminar co botón de suprimir, pero funciona raro, xa o arrleglarei
            if(this.seleccionado) {
                const {cambiarVisibleModalEliminarElemento} = this.props;
                cambiarVisibleModalEliminarElemento(true);
            }
        }*/
    }

    getIconPosition() {
        const { elemento } = this.props
        const centroid = turf.centroid(elemento.coordenadas)
        return [centroid.geometry.coordinates[1], centroid.geometry.coordinates[0]]
    }

    getIcon(){
        const {elemento} = this.props;
        return new Icon({
            iconSize: [elemento.icono.fondo.ancho, elemento.icono.fondo.alto],
            iconAnchor: [elemento.icono.fondo.centro_x, elemento.icono.fondo.centro_y],
            icon: elemento.icono.icono,
            iconColor: elemento.bloque.props_defecto.color_fondo_icono,
            prefix: elemento.icono.prefijo_fuente,
            markerColor: elemento.bloque.props_defecto.color_icono,
            svg: elemento.icono.fondo.svg,
            fontSizeIcon: elemento.icono.fondo.tamano_fuente_icono,
            classFondo: ' icono-' + elemento.icono.fondo.nombre
        });
    }

    componentWillUnmount() {
        const {removeObjetoDibujado, cambiarVisibleBarraHerramientas, setObjetoSeleccionado} = this.props;
        const map = this.props.map.current? this.props.map.current.leafletElement : null;
        if(map) map.off('click', this.onMapClick);
        removeObjetoDibujado(this);
        if(this.seleccionado) {
            if(this.eliminado) {
                setObjetoSeleccionado(null);
                setTimeout(() => {
                    cambiarVisibleBarraHerramientas(false);
                }, 75)
            }
        }
        this.montado = false;
    }

    render() {
        const {elemento} = this.props
        return (
            <>
                <Polygon ref={this.poligonRef} positions={this.getLatLngs()} color={elemento.color}
                            weight={elemento.grosor_linea} fillOpacity={elemento.opacidad_fondo} onClick={this.onClick}
                            dashArray={elemento.bloque.patron.patron} onContextmenu={this.onContextmenu}/>
                <Marker position={this.getIconPosition()} icon={this.getIcon()} onContextmenu={this.onContextmenu}
                        onClick={this.onClick} ref={this.markerRef} />
            </>
        )
    }
}

const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state),
    proyecto: getProyecto(state),
    elementoRecienSubido: getElementoRecienSubido(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    addObjetoDibujado: actions.addObjetoDibujado,
    removeObjetoDibujado: actions.removeObjetoDibujado,
    setObjetoSeleccionado: actions.objetoSeleccionado,
    cambiarModoBarraHerramientas: actions.cambiarModoBarraHerramientas,
    cambiarVisibleBarraHerramientas: actions.cambiarVisibleBarraHerramientas,
    actualizaPoligono: actualizaPoligono,
    actualizaCoordsPoligono: actionsPoligonos.actualizaCoordsPoligono,
    setDuplicando: actions.setDuplicando,
    creaNuevoPoligono: creaNuevoPoligono,
    removeCapaColapsada: actions.removeCapaColapsada,
    setVisibleModalPropAvanzadas: actions.setVisibleModalPropAvanzadas,
    cambiarVisibleModalEliminarElemento: actions.cambiarVisibleModalEliminarElemento,
    removeAgrupacionColapsada: actions.removeAgrupacionColapsada,
    setElementoRecienSubido: actions.setElementoRecienSubido
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Poligono));
