import {ACTIONS_TYPES} from "../actions/agrupaciones";
import {ACTION_TYPES as ACTION_TYPES_TR} from "../actions/trayectos";
import {ACTION_TYPES as ACTION_TYPES_PT} from "../actions/puntos";
import {ACTION_TYPES as ACTION_TYPES_LN} from "../actions/lineas";
import {ACTION_TYPES as ACTION_TYPES_PL} from "../actions/poligonos";
import {initialState} from "../constants/state";

export function agrupacionesReducer(state = initialState.agrupacionesProyecto, action) {
    switch (action.type) {
        case ACTIONS_TYPES.FETCH_AGRUPACIONES_PROYECTO_CORRECTO:
            return{
                ...state,
                list: action.agrupaciones,
                pendiente: false
            };
        case ACTIONS_TYPES.FETCH_AGRUPACIONES_PROYECTO_ERROR:
            return {
                ...state,
                error: action.error,
                pendiente: false
            };
        case ACTIONS_TYPES.FETCH_AGRUPACIONES_PROYECTO_PENDIENTE:
            return {
                ...state,
                pendiente: true
            };
        case ACTIONS_TYPES.ADD_AGRUPACION:
            return {
                ...state,
                list: state.list.filter(ag => ag.id !== action.agrupacion.id).concat(action.agrupacion),
                pendiente: false
            };
        case ACTION_TYPES_TR.TRAYECTO_ELIMINADO_CORRECTO:
        case ACTION_TYPES_LN.LINEA_ELIMINADA_CORRECTO:
        case ACTION_TYPES_PT.PUNTO_ELIMINADO_CORRECTO:
        case ACTION_TYPES_PL.POLIGONO_ELIMINADO_CORRECTO: {
            const elementoElim = action.trayecto ? action.trayecto : (action.linea ? action.linea : (action.punto ?
                action.punto : action.poligono));
            let list = state.list.slice();
            let agrupacionSeleccionada;
            list.map( agrupacion => agrupacion.elementos.map(elemento => {
                agrupacionSeleccionada = elemento.id === elementoElim.id ? agrupacion: agrupacionSeleccionada;
                return true;
            }));
            if(agrupacionSeleccionada.elementos.length <= 1)
                list = state.list.filter(agrupacion => agrupacion.id !== agrupacionSeleccionada.id);
            else {
                let indexAgrup = state.list.map(agrupacion => agrupacion.id).indexOf(agrupacionSeleccionada.id);
                list = state.list.slice();
                list[indexAgrup].elementos = list[indexAgrup].elementos.filter(elemento => elemento.id !== elementoElim.id);
            }
            return {
                ...state,
                list: list
            }
        }
        case ACTION_TYPES_TR.TRAYECTO_ACTUALIZANDO_CORRECTO:
        case ACTION_TYPES_PT.PUNTO_ACTUALIZANDO_CORRECTO:
        case ACTION_TYPES_LN.LINEA_ACTUALIZANDO_CORRECTO:
        case ACTION_TYPES_PL.POLIGONO_ACTUALIZANDO_CORRECTO:{
            const elementoAct = action.trayecto ? action.trayecto : (action.linea ? action.linea : (action.punto ?
                action.punto : action.poligono));
            let list = state.list.slice();
            const indexAgrupacion = state.list.map(agrupacion => agrupacion.id).indexOf(elementoAct.agrupacion);
            if(indexAgrupacion !== - 1) {
                const indexElemento = state.list[indexAgrupacion].elementos.map(elemento => elemento.id).indexOf(elementoAct.id);
                list[indexAgrupacion].elementos[indexElemento] = elementoAct;
            }
            return {
                ...state,
                list: list
            };
        }
        case ACTIONS_TYPES.CAMBIA_ORDENES_AGRUPACIONES: {
            let list = state.list.slice();
            for(let i in action.aCambiar) {
                const index = list.map(ag => ag.id).indexOf(action.aCambiar[i].id);
                list[index].orden = action.aCambiar[i].orden;
            }
            list.sort((a, b) => a.orden - b.orden);
            return {
                ...state,
                list: list
            };
        }
        case ACTIONS_TYPES.ACTUALIZA_AGRUPACION_CORRECTO:
            return {
                ...state,
                list: state.list.filter(ag => ag.id !== action.agrupacion.id)
                    .concat(action.agrupacion)
                    .sort((a,b) => a.orden - b.orden)
            };
        case ACTIONS_TYPES.NUEVA_AGRUPACION_CORRECTO:
            return {
                ...state,
                list: state.list.concat(action.agrupacion)
                    .sort((a,b) => a.orden - b.orden)
            };
        case ACTIONS_TYPES.ELIMINA_AGRUPACION_CORRECTO:
            return {
                ...state,
                list: state.list.filter(ag => ag.id !== action.agrupacion.id)
                    .sort((a,b) => a.orden - b.orden)
            };
        case ACTIONS_TYPES.ACTUALIZA_ELEMENTO_CORRECTO:{
            let list = state.list.slice();
            const index = list.map(ag => ag.id).indexOf(action.elemento.agrupacion);
            const indexElemento = list[index].elementos.map(el => el.id).indexOf(action.elemento.id);
            list[index].elementos[indexElemento] = action.elemento;
            return {
                ...state,
                list: list
            };
        }
        case ACTIONS_TYPES.ELIMINA_ELEMENTO_AGRUPACION: {
            let list = state.list.slice();
            const index = list.map(ag => ag.id).indexOf(action.idAgrupacion);
            list[index].elementos = list[index].elementos.filter(el => el.id !== action.idElemento);
            return {
                ...state,
                list: list
            };
        }
        default:
            return {
                ...state
            };
    }
}

export function agrupacionActualizandoReducer(state = initialState.agrupacionActualizando, action) {
    switch (action.type) {
        case ACTIONS_TYPES.ACTUALIZA_AGRUPACION_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                error: false,
                elemento: null
            };
        case ACTIONS_TYPES.ACTUALIZA_AGRUPACION_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error,
            };
        case ACTIONS_TYPES.ACTUALIZA_AGRUPACION_CORRECTO:
            return {
                ...state,
                pendiente: false,
                elemento: action.agrupacion
            };
        default:
            return {
                ...state
            };
    }
}

export function agrupacionNuevaReducer(state = initialState.agrupacionNueva, action) {
    switch (action.type) {
        case ACTIONS_TYPES.NUEVA_AGRUPACION_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                error: false,
                elemento: null
            };
        case ACTIONS_TYPES.NUEVA_AGRUPACION_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error,
            };
        case ACTIONS_TYPES.NUEVA_AGRUPACION_CORRECTO:
            return {
                ...state,
                pendiente: false,
                elemento: action.agrupacion
            };
        default:
            return {
                ...state
            };
    }
}

export function agrupacionEliminarReducer(state = initialState.agrupacionEliminar, action) {
    switch (action.type) {
        case ACTIONS_TYPES.ELIMINA_AGRUPACION_PENDIENTE:
            return {
                ...state,
                correcto: false,
                pendiente: true,
                elemento: action.agrupacion,
                error: false
            };
        case ACTIONS_TYPES.ELIMINA_AGRUPACION_ERROR:
            return {
                ...state,
                pendiente: false,
                correcto: false,
                error: action.error
            };
        case ACTIONS_TYPES.ELIMINA_AGRUPACION_CORRECTO:
            return {
                ...state,
                pendiente: false,
                correcto: true,
                elemento: action.agrupacion
            };
        default:
            return {
                ...state
            };
    }
}

export function elementoActualizandoReducer(state = initialState.elementoActualizado, action) {
    switch (action.type) {
        case ACTIONS_TYPES.ACTUALIZA_ELEMENTO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                elemento: null,
                error: false
            };
        case ACTIONS_TYPES.ACTUALIZA_ELEMENTO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            };
        case ACTIONS_TYPES.ACTUALIZA_ELEMENTO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                elemento: state.elemento
            };
        default:
            return {
                ...state
            };
    }
}

export default function ultimasAgrupacionesReducer (state = initialState.ultimasAgrupacionesCargadas, action) {
    switch (action.type) {
        case ACTIONS_TYPES.FETCH_AGRUPACIONES_OTRO_PROYECTO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                data: [],
                error: false
            }

        case ACTIONS_TYPES.FETCH_AGRUPACIONES_OTRO_PROYECTO_CORRECTO:
            return {
                ...state,
                pendiente: false,
                data: action.agrupaciones
            }

        case ACTIONS_TYPES.FETCH_AGRUPACIONES_OTRO_PROYECTO_ERROR:
            return {
                ...state,
                pendiente: false,
                error: action.error
            }

        default:
            return {
                ...state
            }
    }
}

export const getAgrupacionesProyecto = state => state.agrupacionesProyecto.list;
export const getAgrupacionesProyectoError = state => state.agrupacionesProyecto.error;
export const getAgrupacionesProyectoPendiente = state => state.agrupacionesProyecto.pendiente;
export const getAgrupacionAcutalizando = state => state.agrupacionActualizando.elemento;
export const getAgrupacionActualizandoError = state => state.agrupacionActualizando.error;
export const getAgrupacionActualizandoPendiente = state => state.agrupacionActualizando.pendiente;
export const getAgrupacionNueva = state => state.agrupacionNueva.elemento;
export const getAgrupacionNuevaError = state => state.agrupacionNueva.error;
export const getAgrupacionNuevaPendiente = state => state.agrupacionNueva.pendiente;
export const getAgrupacionEliminar = state => state.agrupacionEliminar.elemento;
export const getAgrupacionEliminarCorrecto = state => state.agrupacionEliminar.correcto;
export const getAgrupacionEliminarError = state => state.agrupacionEliminar.error;
export const getAgrupacionEliminarPendiente = state => state.agrupacionEliminar.pendiente;
export const getElementoActualizando = state => state.elementoActualizado.elemento;
export const getElementoActualizandoPendiente = state => state.elementoActualizado.pendiente;
export const getElementoActualizandoError = state => state.elementoActualizado.error;
