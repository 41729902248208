import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Accordion, Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {bindActionCreators} from "redux";

import './AcordeonBarraIzquierda.css';

import {
    getAgrupacionesColapsadas,
    getCapasColapsadasBarra,
    getElementosVisibles,
    getObjetosDibujados,
    getObjetoSeleccionado
} from "../../reducers/editor";
import {actions} from "../../actions/editor";
import {actions as actionsAgrupaciones} from "../../actions/agrupaciones";
import {connect} from "react-redux";
import ItemAgrupacion from "../ordenar/ItemAgrupacon";
import {DndProvider} from "react-dnd";
import Backend from "react-dnd-html5-backend";
import {actualizaAgrupacion, actualizaElemento, creaNuevaAgrupacion} from "../../fetchactions/agrupaciones";
import {getProyecto} from "../../reducers/proyectos";
import {getPuntos} from "../../reducers/puntos";

class AcordeonBarraIzquierda extends Component {

    constructor(props){
        super(props);

        this.onClickToggle = this.onClickToggle.bind(this);
        this.onClickVisibilidadCapa = this.onClickVisibilidadCapa.bind(this);
        this.onClickLiElemento = this.onClickLiElemento.bind(this);
        this.reordenaAgrupaciones = this.reordenaAgrupaciones.bind(this);
        this.agrupa = this.agrupa.bind(this);
        this.ordenarElementos = this.ordenarElementos.bind(this);
        this.desagrupa = this.desagrupa.bind(this);
        this.elementoIsVisible = this.elementoIsVisible.bind(this);
        this.onClickVisiblidadElemento = this.onClickVisiblidadElemento.bind(this);
        this.agrupacionIsVisible = this.agrupacionIsVisible.bind(this);
        this.onClickVisibilidadCapa = this.onClickVisibilidadCapa.bind(this);
        this.onToggleAgrupacion = this.onToggleAgrupacion.bind(this);
        this.onDoubleClickAgrupacion = this.onDoubleClickAgrupacion.bind(this);
        this.onClickVisibilidadAgrupacion = this.onClickVisibilidadAgrupacion.bind(this);
        this.isSeleccionado = this.isSeleccionado.bind(this);
    }

    onClickLiElemento(elemento){
        const {objetosDibujados, objetoSeleccionado} = this.props;
        let objetoClick = objetosDibujados.filter(objeto => objeto.props.elemento.id === elemento.id)[0];
        if (objetoClick && (!objetoSeleccionado || objetoSeleccionado.props.elemento.id !== objetoClick.props.elemento.id))
            objetoClick.seleccionar();
    }

    onClickVisiblidadElemento(e, elemento){
        e.stopPropagation();
        const {addElementoVisible, removeElementoVisible, objetoSeleccionado} = this.props;
        if(objetoSeleccionado && objetoSeleccionado.props.elemento.id === elemento.id)
            objetoSeleccionado.deseleccionar();
        if(this.elementoIsVisible(elemento)) {
            removeElementoVisible(elemento);
        } else {
            addElementoVisible(elemento);
        }
    }

    onClickVisibilidadAgrupacion(e, agrupacion) {
        const {addElementoVisible, removeElementoVisible, objetoSeleccionado} = this.props;
        e.stopPropagation();
        if(this.agrupacionIsVisible(agrupacion)) {
            for(let i in agrupacion.elementos) {
                if(objetoSeleccionado && objetoSeleccionado.props.elemento.id === agrupacion.elementos[i].id) {
                    objetoSeleccionado.deseleccionar();
                }
                removeElementoVisible(agrupacion.elementos[i]);
            }
        } else {
            for(let i in agrupacion.elementos) {
                addElementoVisible(agrupacion.elementos[i]);
            }
        }
    }

    onClickVisibilidadCapa(e){
        const {agrupaciones, addElementoVisible, removeElementoVisible, capa, objetoSeleccionado} = this.props;
        e.stopPropagation();
        if(this.capaIsVisible(capa)) {
            for(let i in agrupaciones) {
                for(let j in agrupaciones[i].elementos) {
                    if(objetoSeleccionado && objetoSeleccionado.props.elemento.id === agrupaciones[i].elementos[j].id)
                        objetoSeleccionado.deseleccionar();
                    removeElementoVisible(agrupaciones[i].elementos[j]);
                }
            }
        } else {
            for(let i in agrupaciones) {
                for(let j in agrupaciones[i].elementos) {
                    addElementoVisible(agrupaciones[i].elementos[j]);
                }
            }
        }
    }

    elementoIsVisible(elemento){
        const {elementosVisibles} = this.props;
        return elementosVisibles.filter(elementoVisible => elementoVisible.id === elemento.id).length > 0;
    }

    agrupacionIsVisible(agrupacion) {
        const {elementosVisibles} = this.props;
        for(let i in agrupacion.elementos) {
            if(elementosVisibles.map(el => el.id).indexOf(agrupacion.elementos[i].id) !== -1) return true;
        }
        return false;
    }

    capaIsVisible(){
        const {agrupaciones} = this.props;
        for(let i in agrupaciones) {
            for(let j in agrupaciones[i].elementos) {
                if(this.elementoIsVisible(agrupaciones[i].elementos[j])){
                    return true;
                }
            }
        }
        return false;
    }

    onClickToggle() {
        const {capa, capasColapsadas, addCapaColapsada, removeCapaColapsada} = this.props;
        if(capasColapsadas.filter(capaColapsada =>  capaColapsada.id === capa.id).length === 0) {
            addCapaColapsada(capa);
        } else {
            removeCapaColapsada(capa);
        }
    }

    isColapsado(){
        const {capa, capasColapsadas} = this.props;
        return capasColapsadas.filter(capaColapsada => capaColapsada.id === capa.id).length > 0
    }

    isSeleccionado(elemento){
        const {objetoSeleccionado} = this.props;
        return objetoSeleccionado && objetoSeleccionado.props.elemento.id === elemento.id
    }

    reordenaAgrupaciones(prevIndex, newIndex) {
        const { agrupaciones, cambiaOrdenesAgrupaciones, actualizaAgrupacion, proyecto } = this.props
        let aCambiar = [{id: agrupaciones[prevIndex].id, orden: agrupaciones[newIndex].orden}]
        if(prevIndex > newIndex) {
            for(let i = newIndex; i < prevIndex; i++) {
                aCambiar.push({
                    id: agrupaciones[i].id,
                    orden: agrupaciones[i+1].orden
                })
            }
        } else {
            let aCambiar = [{id: agrupaciones[prevIndex].id, orden: agrupaciones[newIndex].orden}]
            console.log(prevIndex, newIndex)
            for(let i = newIndex; i > prevIndex; i--){
                aCambiar.push({
                    id: agrupaciones[i].id,
                    orden: agrupaciones[i-1].orden
                })
            }
        }
        cambiaOrdenesAgrupaciones(aCambiar)
        for(let i in aCambiar) {
            const agrupacion = agrupaciones.filter(ag => ag.id === aCambiar[i].id)
            actualizaAgrupacion(aCambiar[i].id, {
                nombre: agrupacion[0].nombre,
                proyecto: proyecto.id,
                orden: aCambiar[i].orden
            })
        }
    }

    agrupa(index1, index2) {
        const { t, agrupaciones, creaNuevaAgrupacion, proyecto } = this.props
        const agrupacion1 = agrupaciones[index1]
        const agrupacion2 = agrupaciones[index2]
        creaNuevaAgrupacion({
            nombre: agrupacion2.elementos.length > 1 ? agrupacion2.nombre: t('agrupacion'),
            proyecto: proyecto.id,
            orden: agrupacion2.orden,
            elementos: [].concat(agrupacion1.elementos.map(e => e.id))
                .concat(agrupacion2.elementos.map(e => e.id))
        }, agrupacion1, agrupacion2)
    }

    ordenarElementos(prevIndex, newIndex, idAgrupacion) {
        const {agrupaciones, actualizaElemento} = this.props;
        const agrupacion = agrupaciones.filter(ag => ag.id === idAgrupacion);
        if(agrupacion.length > 0) {
            const elementos = agrupacion[0].elementos.sort((a, b) => a.orden_editor - b.orden_editor);
            let aCambiar = [{id: elementos[prevIndex].id, orden: elementos[newIndex].orden_editor}];
            if(prevIndex > newIndex) {
                for(let i = newIndex; i < prevIndex; i++) {
                    aCambiar.push({
                        id: elementos[i].id,
                        orden: elementos[i+1].orden_editor
                    });
                }
            } else {
                for(let i = newIndex; i > prevIndex; i--){
                    aCambiar.push({
                        id: elementos[i].id,
                        orden: elementos[i-1].orden_editor
                    });
                }
            }
            for(let i in aCambiar) {
                const elemento = elementos.filter(el => el.id === aCambiar[i].id);
                actualizaElemento(aCambiar[i].id, {
                    nombre: elemento[0].nombre,
                    orden_editor: aCambiar[i].orden
                });
            }
        }
    }

    desagrupa(elemento, targetIndex) {
        const {creaNuevaAgrupacion, eliminaElementoAgrupacion, agrupaciones, actualizaAgrupacion, proyecto} = this.props;
        creaNuevaAgrupacion({
            nombre: elemento.nombre,
            proyecto: proyecto.id,
            elementos: [elemento.id],
            orden: agrupaciones[targetIndex].orden
        });
        for(let i = targetIndex; i < agrupaciones.length; i++) {
            actualizaAgrupacion(agrupaciones[i].id, {
                nombre: agrupaciones[i].nombre,
                proyecto: proyecto.id,
                orden: i !== agrupaciones.length - 1 ? agrupaciones[i+1].orden : agrupaciones[i].orden + 1
            })
        }
        eliminaElementoAgrupacion(elemento.id, elemento.agrupacion);
    }

    isAgrupacionColapsada(agrupacion) {
        const {agrupacionesColapsadas} = this.props;
        return agrupacionesColapsadas.map(ag => ag.id).indexOf(agrupacion.id) !== -1;
    }

    onToggleAgrupacion(agrupacion) {
        const {agrupacionesColapsadas, addAgrupacionColapsada, removeAgrupacionColapsada} = this.props;
        if(agrupacionesColapsadas.map(ag => ag.id).indexOf(agrupacion.id) === -1) addAgrupacionColapsada(agrupacion);
        else removeAgrupacionColapsada(agrupacion);
    }

    onDoubleClickAgrupacion(e, agrupacion) {
        const {setVisibleModalNombreAgrupacion, setAgrupacionSeleccionada} = this.props;
        e.stopPropagation();
        setAgrupacionSeleccionada(agrupacion);
        setVisibleModalNombreAgrupacion(true);
    }

    render() {
        const {capa, agrupaciones, puntos} = this.props;
        const agrupacionesOrdenadas = agrupaciones.sort((a, b) => a.orden - b.orden);
        let indices = 0;
        if(agrupaciones.length > 0) {
            return <Accordion defaultActiveKey={0} activeKey={this.isColapsado() ? null : 0}>
                <Card className='editor-card'>
                    <Accordion.Toggle onClick={this.onClickToggle} eventKey={0}
                                      className='editor-acordeon-header' as={Card.Header}>
                        <button onClick={this.onClickVisibilidadCapa}>
                            <FontAwesomeIcon icon={this.capaIsVisible(capa) ? faEye : faEyeSlash}/>
                        </button>
                        <span className={'capa-nombre'}>{capa.nombre}</span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={0} className='editor-acordeon-body'>
                        <Card.Body>
                            <DndProvider backend={Backend}>
                            <ul>
                                {agrupacionesOrdenadas.map(ag => {
                                    indices++;
                                    return <li key={ag.id}>
                                        <Accordion defaultActiveKey={0}
                                                   activeKey={this.isAgrupacionColapsada(ag) ? null : 0}
                                                   className='acordeon-agrupaciones'>
                                            {ag.elementos.length > 1 ?
                                                <ItemAgrupacion reordenaAgrupaciones={this.reordenaAgrupaciones}
                                                                agrupa={this.agrupa}
                                                                indice={indices - 1}
                                                                agrupacion={ag} multiple
                                                                ordenarElementos={this.ordenarElementos}
                                                                desagrupa={this.desagrupa}
                                                                onClickLiElemento={this.onClickLiElemento}
                                                                elementoIsVisible={this.elementoIsVisible}
                                                                onClickVisiblidadElemento={this.onClickVisiblidadElemento}
                                                                agrupacionIsVisible={this.agrupacionIsVisible}
                                                                onClickVisibilidadAgrupacion={this.onClickVisibilidadAgrupacion}
                                                                onToggleAgrupacion={this.onToggleAgrupacion}
                                                                onDoubleClickAgrupacion={this.onDoubleClickAgrupacion}
                                                                isSeleccionado={this.isSeleccionado}
                                                                puntos={puntos}
                                                />
                                                :
                                                <ItemAgrupacion reordenaAgrupaciones={this.reordenaAgrupaciones}
                                                                agrupa={this.agrupa} indice={indices - 1}
                                                                agrupacion={ag} desagrupa={this.desagrupa}
                                                                onClickLiElemento={this.onClickLiElemento}
                                                                elementoIsVisible={this.elementoIsVisible}
                                                                onClickVisiblidadElemento={this.onClickVisiblidadElemento}
                                                                onDoubleClickAgrupacion={this.onDoubleClickAgrupacion}
                                                                isSeleccionado={this.isSeleccionado}
                                                                puntos={puntos}
                                                />
                                            }
                                        </Accordion>
                                    </li>
                                })}
                            </ul>
                            </DndProvider>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        } else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state),
    capasColapsadas: getCapasColapsadasBarra(state),
    elementosVisibles: getElementosVisibles(state),
    objetosDibujados: getObjetosDibujados(state),
    agrupacionesColapsadas: getAgrupacionesColapsadas(state),
    proyecto: getProyecto(state),
    puntos: getPuntos(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setObjetoSeleccionado: actions.objetoSeleccionado,
    addCapaColapsada: actions.addCapaColapsada,
    removeCapaColapsada: actions.removeCapaColapsada,
    addElementoVisible: actions.addElementoVisible,
    removeElementoVisible: actions.removeElementoVisible,
    cambiaOrdenesAgrupaciones: actionsAgrupaciones.cambiaOrdenesAgrupaciones,
    actualizaAgrupacion: actualizaAgrupacion,
    creaNuevaAgrupacion: creaNuevaAgrupacion,
    actualizaElemento: actualizaElemento,
    eliminaElementoAgrupacion: actionsAgrupaciones.eliminaElementoAgrupacion,
    addAgrupacionColapsada: actions.addAgrupacionColapsada,
    removeAgrupacionColapsada: actions.removeAgrupacionColapsada,
    setAgrupacionSeleccionada: actions.setAgrupacionSeleccionada,
    setVisibleModalNombreAgrupacion: actions.setVisibleModalNombreAgrupacion
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AcordeonBarraIzquierda));
