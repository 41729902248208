import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Form} from "react-bootstrap";
import * as turf from '@turf/turf';

import {getPuntos} from "../../reducers/puntos";
import {getTrayectos} from "../../reducers/trayectos";
import {getRecorridoCreando} from "../../reducers/recorridos";
import {actions} from "../../actions/editor";
import {getServicios} from "../../reducers/servicios";
import {actions as actionsRecorrido} from "../../actions/recorridos";
import {actualizaPunto} from "../../fetchactions/puntos";
import {getObjetosDibujados, getObjetoSeleccionado, getPksPorAnadir} from "../../reducers/editor";

class PdisEsquema extends Component {

    constructor(props) {
        super(props);

        this.onClickMasServicios = this.onClickMasServicios.bind(this);
    }

    getTayectoById(idTrayecto){
        const {trayectos} = this.props;
        return trayectos.filter(tr => tr.id === idTrayecto)[0];
    }

    getDistanciaInicioTrayecto(punto, trayecto) {
        const tr = this.getTayectoById(trayecto.id);
        const slice = turf.lineSlice(tr.coordenadas.coordinates[0], punto.coordenadas, tr.coordenadas);
        const dist = turf.length(slice, {units: this.getUnits()});
        return dist * trayecto.distancia_calculada / trayecto.distancia_metros;
    }

    getUnits() {
        const {recorridoCreando} = this.props;
        switch (recorridoCreando.unidad_distancia) {
            case 'km':
                return 'kilometers';
            case 'ft':
                return 'feet';
            case 'mi':
                return 'miles';
            default:
                return 'meters';
        }
    }

    getPdisEsquema() {
        const {esquema, puntos} = this.props;
        return puntos
            .filter(pt =>
                (!pt.bloque.es_pk && !pt.bloque.automatico) && pt.bloque.pdi_sobre_trayecto && pt.trayecto_ancla === esquema.trayecto.id)
            .map(p => {
                p.distanciaInicio = this.getDistanciaInicioTrayecto(p, esquema.trayecto)
                return p
            })
            .sort((a,b) => a.distanciaInicio - b.distanciaInicio);
    }

    onClickMasServicios(pdi){
        const {setVisibleModalEditarServicios, setPdiEditar} = this.props;
        setPdiEditar(pdi);
        setVisibleModalEditarServicios(true);
    }

    onChangePublicado(e, pdi){
        const {addPdiSector, removePdiSector, sector} = this.props;
        if(e.target.checked) addPdiSector(sector, pdi);
        else removePdiSector(sector, pdi);
    }

    esPublico(pdi) {
        const {sector} = this.props;
        for(let i in sector.pdis_recorrido){
            if(sector.pdis_recorrido[i].id === pdi.id) return true;
        }
        return false;
    }

    render() {
        const {esquema, paso, distanciaAcumulada, recorridoCreando, servicios} = this.props;
        if(recorridoCreando) {
            const pdis = this.getPdisEsquema();
            return pdis.map(pt =>
                <tr key={esquema.id + '-' + pt.id + '-' + paso}>
                    <td>
                        <Form.Check type='checkbox' id={'publicado-' + pt.id + '-' + esquema.id + '-' + paso}>
                            <Form.Check.Input onChange={e => this.onChangePublicado(e, pt)} name='punto-publicado'
                                              type='checkbox' checked={this.esPublico(pt)}/>
                        </Form.Check>
                    </td>
                    <td>{pt.nombre}</td>
                    <td>{(distanciaAcumulada + pt.distanciaInicio).toFixed(2) + ' ' + recorridoCreando.unidad_distancia}</td>
                    <td>{pt.distanciaInicio.toFixed(2) + ' ' + recorridoCreando.unidad_distancia}</td>
                    <td>{paso}</td>
                    <td className='celda-servicios'>
                        {pt.servicios.map(servicio => {
                            const srv = servicio.id ? servicio : servicios.filter(s => s.id === servicio)[0];
                            return <div className='icono-servicio' key={srv.id}
                                        style={{backgroundColor: srv.color_fondo, color: srv.color_icono}}>
                                <i className={srv.icono ? srv.icono.prefijo_fuente + ' ' + srv.icono.prefijo_fuente + '-' + srv.icono.icono : ''} />
                            </div>
                        })}
                        <div onClick={() => this.onClickMasServicios(pt)}
                             className='icono-servicio icono-mas-servicio btn-primary'>
                            <i className='mdi mdi-plus'/>
                        </div>
                    </td>
                </tr>
            )
        } else {
            return <></>
        }
    }
}

const mapStateToProps = state => ({
    puntos: getPuntos(state),
    trayectos: getTrayectos(state),
    recorridoCreando: getRecorridoCreando(state),
    servicios: getServicios(state),
    pksPorAnadir: getPksPorAnadir(state),
    objetoSeleccionado: getObjetoSeleccionado(state),
    objetosDibujados: getObjetosDibujados(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalEditarServicios: actions.setVisibleModalEditarServicios,
    setPdiEditar: actions.setPdiEditar,
    addPdiSector: actionsRecorrido.addPdiSector,
    removePdiSector: actionsRecorrido.removePdiSector,
    actualizaPunto: actualizaPunto,
    addPkPorAnadir: actions.addPkPorAnadir,
    removePkPorAnadir: actions.removePkPorAnadir,
    setVisibleModalPropAvanzadas: actions.setVisibleModalPropAvanzadas
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PdisEsquema);
