import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import { withTranslation, Trans } from "react-i18next"
import {connect} from "react-redux";
import {Row, Col, Form, Button, FormCheck, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import * as turf from '@turf/turf';

import ModalEditor from "./ModalEditor";
import {actions} from "../../actions/editor";
import {actions as actionsTrayectos} from "../../actions/trayectos";
import {actions as actionsPuntos} from "../../actions/puntos";
import {actions as actionsLineas} from "../../actions/lineas";
import {actions as actionsPoligonos} from "../../actions/poligonos";
import {
    getAvisoDistanciaMostrado, getMuestraAvisoDistancia,
    getObjetoSeleccionado,
    getPksPorAnadir,
    getPreviewImagenSubida,
    getRecorridoAsociar, getSectoresAsociados
} from "../../reducers/editor";
import {actualizaTrayecto} from "../../fetchactions/trayectos";

import './ModalPropAvanzadas.css';
import {actualizaPunto} from "../../fetchactions/puntos";
import {actualizaLinea} from "../../fetchactions/lineas";
import {actualizaPoligono} from "../../fetchactions/poligonos";
import {getRecorridos} from "../../reducers/recorridos";
import {
    actualizaPk,
    creaNuevoElementoRecorrido,
    creaNuevoPk, eliminaElementoRecorrido,
    eliminarPk
} from "../../fetchactions/recorridos";
import {getServicios} from "../../reducers/servicios";
import {getTrayectoNuevo} from "../../reducers/trayectos";
import {getPuntoNuevo} from "../../reducers/puntos";
import {getLineaNueva} from "../../reducers/lineas";
import {getPoligonoNuevo} from "../../reducers/poligonos";
import {actualizaSectorSimple} from "../../fetchactions/sectores";
import {TIPOS_MAPAS_NOMBRES} from "../../constants/state";
import {SERVER_DOMAIN} from "../../constants/api";

class ModalPropAvanzadas extends Component{

    constructor(props) {
        super(props)
        this.refForm = React.createRef()
        this.refDistancia = React.createRef()
        this.state = {
            addNewUser: false,
            usuarios: [],
            usersFilter: ''
        }

        this.onHide = this.onHide.bind(this)
        this.onClickCancelar = this.onClickCancelar.bind(this)
        this.onClickAceptar = this.onClickAceptar.bind(this)
        this.onChangeImagen = this.onChangeImagen.bind(this)
        this.onClickBorrarImagen = this.onClickBorrarImagen.bind(this)
        this.onClickMasServicios = this.onClickMasServicios.bind(this)
    }

    componentDidMount() {
        const { recorridos, addPkPorAnadir, objetoSeleccionado, setAvisoDistanciaMostrado, resetSectoresAsociados,
            addSectorAsociado } = this.props;
        resetSectoresAsociados();
        if(objetoSeleccionado && objetoSeleccionado.props.elemento.bloque.tipo === 'pt'){
            recorridos.forEach(rec => {
                rec.sectores.forEach(sec => {
                    const pk = sec.puntos_km.filter(pk =>
                        pk.punto.id === objetoSeleccionado.props.elemento.id)[0];
                    if (pk) addPkPorAnadir(pk);
                    const indexPdi = sec.pdis_recorrido.map(pdi =>
                        pdi.id ? pdi.id : pdi).indexOf(objetoSeleccionado.props.elemento.id)
                    if(indexPdi >= 0) addSectorAsociado(sec);
                })
            })

        }
        setAvisoDistanciaMostrado(false);
        this.setState({
            usuarios: objetoSeleccionado.props.elemento.usuarios
        })
    }

    hide() {
        const {t, setVisibleModalPropAvanzadas, setPreviewImagenSubida, resetPkPorAnadir, resetTrayectoNuevo,
            trayectoNuevo, setAvisoDistanciaMostrado, avisoDistanciaMostrado, muestraAvisoDistancia,
            setMuestraAvisoDistancia, puntoNuevo, resetPuntoNuevo, lineaNueva, resetNuevaLinea, poligonoNuevo,
            resetNuevoPoligono} = this.props;
        const mostrarAviso = muestraAvisoDistancia || (trayectoNuevo && trayectoNuevo.id);
        if(mostrarAviso && this.refDistancia.current.value === '' && !avisoDistanciaMostrado) {
            alert(t('msg-aviso-distancia'));
            setAvisoDistanciaMostrado(true);
            return;
        }
        setVisibleModalPropAvanzadas(false);
        setPreviewImagenSubida(null);
        resetPkPorAnadir();
        if(trayectoNuevo) {
            resetTrayectoNuevo();
        }
        if(puntoNuevo) {
            resetPuntoNuevo();
        }
        if(lineaNueva){
            resetNuevaLinea();
        }
        if(poligonoNuevo) {
            resetNuevoPoligono()
        }
        if(muestraAvisoDistancia) {
            setMuestraAvisoDistancia(false);
        }
    }

    onHide(){
        const {resetRecorridoAsociar} = this.props;
        resetRecorridoAsociar();
        this.hide();
    }

    onClickCancelar(){
        this.hide();
    }

    onClickAceptar(){
        const {
            t, objetoSeleccionado, actualizaTrayecto, actualizaPunto, actualizaLinea, actualizaPoligono, trayectoNuevo,
            setAvisoDistanciaMostrado, avisoDistanciaMostrado, muestraAvisoDistancia, sectoresAsociados
        } = this.props
        const elemento = objetoSeleccionado.props.elemento
        const mostrarAviso = muestraAvisoDistancia || (trayectoNuevo && trayectoNuevo.id)
        if(mostrarAviso && this.refDistancia.current.value === '' && !avisoDistanciaMostrado) {
            alert(t('msg-aviso-distancia'))
            setAvisoDistanciaMostrado(true)
            return
        }
        if(this.comprobarDistancias()) {
            const form = this.refForm.current
            for(let i in form.elements) {
                if(form.elements[i].type === 'url') {
                    if(form.elements[i].value !== '' && !this.validURL(form.elements[i].value)){
                        alert(t('introduce-url-valida'))
                        return false
                    }
                } else if(form.elements[i].type === 'file') {
                    let mimeType = form.elements[i].files[0] ? form.elements[i].files[0].type : null
                    if(mimeType && mimeType.split('/')[0] !== 'image') {
                        alert(t('introduce-imagen-valida'))
                        return false
                    }
                }
            }
            let inputImagen = document.getElementsByName('imagen')[0]
            let formData = new FormData(form)
            if(inputImagen && !inputImagen.files[0]) formData.delete('imagen')
            formData.append('bloque', elemento.bloque.id)
            if(objetoSeleccionado.props.elemento.bloque.tipo !== 'pt') {
                formData.append('coordenadas', JSON.stringify(objetoSeleccionado.getUltimasCoords()))
            }
            this.state.usuarios.forEach(u => formData.append('usuarios', u.id))
            switch (elemento.bloque.tipo) {
                case 'tr':
                    const distElemento = elemento.distancia_calculada ? elemento.distancia_calculada :
                        turf.length(elemento.coordenadas, {units: 'meters'})
                    const umbralDistancia = distElemento  * .2
                    if(parseFloat(this.refDistancia.current.value) < distElemento - umbralDistancia
                        || parseFloat(this.refDistancia.current.value) > distElemento + umbralDistancia) {
                        alert(t('distancia-muy-diferente'))
                    }
                    formData.append('flechas', elemento.flechas)
                    actualizaTrayecto(elemento.id, null, formData)
                    break;
                case 'pt':
                    formData.append('icono', elemento.icono.id)
                    for(let i in objetoSeleccionado.props.elemento.servicios) {
                        formData.append('servicios', elemento.servicios[i].id ?
                          elemento.servicios[i].id : elemento.servicios[i])
                    }
                    if (!objetoSeleccionado.props.elemento.bloque.es_pk) {
                        sectoresAsociados.forEach(sec => {
                            formData.append('sectores', sec.id)
                        })
                    }
                    actualizaPunto(elemento.id, null, formData)
                    break
                case 'ln':
                    actualizaLinea(elemento.id, null, formData)
                    break
                case 'pl':
                    if(objetoSeleccionado.getUltimasCoords().coordinates[0].length < 4)
                        formData.append('coordenadas',JSON.stringify({"type": "Polygon", "coordinates": [[]]}))
                    actualizaPoligono(elemento.id, null, formData);
                    break
                default:
                    break
            }
            this.actualizarRecorridos()
            if(elemento.bloque.pdi_sobre_trayecto && elemento.bloque.es_pk) {
                this.actualizaPks()
            } else if(elemento.bloque.pdi_sobre_trayecto && !elemento.bloque.es_pk) {
                this.actualizaPdis()
            }
            this.hide()
        } else {
            alert(t('msg-error-distancias'))
        }
    }

    actualizarRecorridos() {
        const {recorridosAsociar, objetoSeleccionado, recorridos, creaNuevoElementoRecorrido,
            eliminaElementoRecorrido} = this.props;
        const elemento = objetoSeleccionado.props.elemento;
        for(let i in recorridosAsociar) {
            if(recorridosAsociar[i].elementos.map(er => er.elemento).indexOf(elemento.id) === -1) {
                const maxOrden = Math.max(...recorridosAsociar[i].elementos.map(er => er.orden));
                creaNuevoElementoRecorrido({
                    recorrido: recorridosAsociar[i].id,
                    elemento: elemento.id,
                    orden: maxOrden === Infinity || maxOrden === -Infinity || !maxOrden ? 1 : maxOrden + 1
                });
            }
        }
        for(let i in recorridos) {
            if(recorridosAsociar.map(recorrido => recorrido.id).indexOf(recorridos[i].id) < 0 &&
                recorridos[i].elementos.map(er => er.elemento).indexOf(elemento.id) >= 0) {
                const elementoEliminar = recorridos[i].elementos.filter(er => er.elemento === elemento.id)[0];
                if(elementoEliminar) {
                    eliminaElementoRecorrido(elementoEliminar);
                }
            }
        }
    }

    actualizaPks() {
        const {objetoSeleccionado, pksPorAnadir, recorridos, actualizaPk, eliminarPk, creaNuevoPk} = this.props;
        for(let i in recorridos) {
            for(let j in recorridos[i].sectores) {
                const pk = recorridos[i].sectores[j].puntos_km.filter(pk =>
                    (pk.punto.id ? pk.punto.id : pk.punto) === objetoSeleccionado.props.elemento.id)[0];
                if(pk) {
                    const pkAct = pksPorAnadir.filter(pkA => pkA.id === pk.id)[0];
                    if(pkAct) {
                        actualizaPk({
                            punto: pk.punto.id ? pk.punto.id: pk.punto,
                            sector: pk.sector,
                            distancia_oficial: pkAct.distancia_oficial,
                            publicado: true
                        }, pk.id, recorridos[i].id)
                    } else {
                        eliminarPk(pk, recorridos[i].id);
                    }
                }
            }
        }
        for(let i in pksPorAnadir) {
            if(!pksPorAnadir[i].id) {
                const recorrido = recorridos.filter(r =>
                    r.sectores.map(s => s.id).indexOf(pksPorAnadir[i].sector) >= 0)[0];
                creaNuevoPk(pksPorAnadir[i], recorrido.id);
            }
        }
    }

    actualizaPdis(){
        const {objetoSeleccionado, recorridos, sectoresAsociados, actualizaSectorSimple} = this.props;
        recorridos.forEach(r => r.sectores.forEach(sec => {
            const indexPdi = sec.pdis_recorrido.map(pdi =>
                pdi.id ? pdi.id : pdi).indexOf(objetoSeleccionado.props.elemento.id);
            const indexSectorAsociado = sectoresAsociados.map(s => s.id).indexOf(sec.id)
            if(indexPdi >= 0 && indexSectorAsociado === -1) {
                actualizaSectorSimple(sec.id, {
                    nombre: sec.nombre,
                    recorrido: r.id,
                    orden: sec.orden,
                    pdis_recorrido: sec.pdis_recorrido.filter(pdi =>
                        pdi.id ? pdi.id : pdi !== objetoSeleccionado.props.elemento.id).map(p =>
                        p.id ? p.id : p)
                });
            }
        }))
    }

    comprobarDistancias() {
        const {pksPorAnadir} = this.props;
        for(let i in pksPorAnadir){
            if(Number.isNaN(pksPorAnadir[i].distancia_oficial)) return false;
        }
        return true;
    }

    validURL(str) {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    onChangeImagen(e) {
        const {setPreviewImagenSubida} = this.props;
        if(e.target.files && e.target.files[0]){
            let reader = new FileReader();
            reader.onload = e => setPreviewImagenSubida(e.target.result);

            reader.readAsDataURL(e.target.files[0]);
        }
    }

    getTitulo() {
        const {t, objetoSeleccionado} = this.props;
        return t('props-avanzadas-titulo') + (objetoSeleccionado ? objetoSeleccionado.props.elemento.nombre : '');
    }

    onClickBorrarImagen(){
        const {objetoSeleccionado, actualizaTrayecto, setPreviewImagenSubida, actualizaLinea, actualizaPunto,
            actualizaPoligono} = this.props;
        const elemento = objetoSeleccionado.props.elemento;
        switch (elemento.bloque.tipo) {
            case 'tr':
                let opciones = {
                    nombre: elemento.nombre,
                    bloque: elemento.bloque.id,
                    coordenadas: elemento.coordenadas,
                    imagen: null
                };
                actualizaTrayecto(elemento.id, opciones);
                break;
            case 'ln':
                actualizaLinea(elemento.id, {
                    nombre: elemento.nombre,
                    bloque: elemento.bloque.id,
                    coordenadas: elemento.coordenadas,
                    imagen: null
                });
                break;
            case 'pt':
                actualizaPunto(elemento.id, {
                    nombre: elemento.nombre,
                    bloque: elemento.bloque.id,
                    icono: elemento.icono.id,
                    imagen: null
                });
                break;
            case 'pl':
                actualizaPoligono(elemento.id, {
                    nombre: elemento.nombre,
                    bloque: elemento.bloque.id,
                    coordenadas: elemento.coordenadas,
                    imagen: null
                });
                break;
            default:
                break;
        }
        setPreviewImagenSubida(null);
        document.getElementsByName('imagen')[0].value = '';
    }

    onChangeRecorrido(e, recorrido) {
        const {addRecorridoAsociar, removeRecorridoAsociar} = this.props;
        if(e.target.checked) {
            addRecorridoAsociar(recorrido);
        } else {
            removeRecorridoAsociar(recorrido);
        }
    }

    estaAnadido(recorrido) {
        const {objetoSeleccionado} = this.props;
        return recorrido.elementos.map(er => er.elemento).indexOf(objetoSeleccionado.props.elemento.id) >= 0
    }

    esPk(sector) {
        const {pksPorAnadir} = this.props;
        return pksPorAnadir.map(pk => pk.sector).indexOf(sector.id) >= 0
    }

    esPdi(sector) {
        const {sectoresAsociados} = this.props;
        return sectoresAsociados.map(s => s.id).indexOf(sector.id) >= 0;
    }

    onChangePk(e, sector) {
        const {objetoSeleccionado, addPkPorAnadir, removePkPorAnadir} = this.props;
        if(e.target.checked) {
            addPkPorAnadir({
                punto: objetoSeleccionado.props.elemento.id,
                sector: sector.id,
                distancia_oficial: 0,
                publicado: true
            });
        } else {
            removePkPorAnadir(objetoSeleccionado.props.elemento.id, sector.id);
        }
    }

    onChangePdi(e, sector) {
        const {addSectorAsociado, removeSectorAsociado} = this.props;
        if(e.target.checked) addSectorAsociado(sector);
        else removeSectorAsociado(sector);
    }

    onChangeDistancia(e, sector) {
        const {pksPorAnadir, addPkPorAnadir, removePkPorAnadir} = this.props;
        let pk = pksPorAnadir.filter(pk => pk.sector === sector.id)[0];
        pk.distancia_oficial = e.target.value;
        removePkPorAnadir(pk.punto, pk.sector);
        addPkPorAnadir(pk);
    }

    getValueDistancia(sector) {
        const {pksPorAnadir} = this.props;
        const pk = pksPorAnadir.filter(pk => pk.sector === sector.id)[0];
        return pk.distancia_oficial;
    }

    getServiciosActivos() {
        const {objetoSeleccionado, servicios} = this.props;
        return servicios.filter(serv => objetoSeleccionado.props.elemento.servicios.map(servicio =>
            servicio.id).indexOf(serv.id) >= 0);
    }

    getValoresUnidadesDistancia() {
        return [
            {nombre: 'Metros', value:'m'},
            {nombre: 'Kilometros', value: 'km'},
            {nombre: 'Millas', value: 'mi'},
            {nombre: 'Pies', value: 'ft'}
        ]
    }

    onClickMasServicios() {
        const {setPdiEditar, setVisibleModalEditarServicios, objetoSeleccionado} = this.props;
        setPdiEditar(objetoSeleccionado.props.elemento);
        setVisibleModalEditarServicios(true);
    }

    getRecorridosPresente() {
        const {objetoSeleccionado, recorridos} = this.props;
        return recorridos.filter(r => r.sectores.filter(s => s.esquemas.map(es =>
            es.trayecto.id).indexOf(objetoSeleccionado.props.elemento.trayecto_ancla) >= 0).length > 0);
    }

    getNumPasos(sector) {
        const {objetoSeleccionado} = this.props;
        const esquemas = sector.esquemas.filter(e => e.trayecto.id === objetoSeleccionado.props.elemento.trayecto_ancla);
        let pasos = 0;
        for(let i in esquemas) {
            pasos += esquemas[i].repeticiones;
        }
        return pasos;
    }

    getDistanciaPaso(sector, paso, recorrido) {
        const {objetoSeleccionado, pksPorAnadir} = this.props;
        const pk = pksPorAnadir.filter(p => p.sector === sector.id)[0];
        const esquemas = sector.esquemas.filter(e => e.trayecto.id === objetoSeleccionado.props.elemento.trayecto_ancla);
        if(!isNaN(pk.distancia_oficial) && pk.distancia_oficial !== '') {
            const distOficialMetros = this.toMetros(parseFloat(pk.distancia_oficial), recorrido);
            if(paso <= esquemas[0].repeticiones) {
                const distMetros = esquemas[0].trayecto.distancia_metros * (paso - 1) + distOficialMetros;
                return this.distanciaEnUnidad(distMetros, recorrido.unidad_distancia);
            } else {
                let indexEsquema = 0;
                let repeticionAcum = 0;
                for(let i = 1; i <= paso; i++) {
                    if(i > esquemas[indexEsquema].repeticiones + repeticionAcum) {
                        repeticionAcum += esquemas[indexEsquema].repeticiones;
                        indexEsquema++;
                    }
                }
                const esquemaPaso = esquemas[indexEsquema];
                let distAcum = 0;
                indexEsquema = 0;
                while(sector.esquemas[indexEsquema].id !== esquemaPaso.id) {
                    distAcum += sector.esquemas[indexEsquema].trayecto.distancia_metros;
                    indexEsquema++;
                }
                const pasoEsquema = paso - repeticionAcum;
                const distMetros = esquemaPaso.trayecto.distancia_metros * (pasoEsquema - 1) + distOficialMetros + distAcum;
                return this.distanciaEnUnidad(distMetros, recorrido.unidad_distancia);
            }
        }
        return 0
    }

    toMetros(distancia, recorrido){
        switch (recorrido.unidad_distancia) {
            case 'km':
                return turf.convertLength(distancia, 'kilometers', 'meters');
            case 'ft':
                return turf.convertLength(distancia, 'feet', 'meters');
            case 'mi':
                return turf.convertLength(distancia, 'miles', 'meters');
            default:
                return distancia;
        }
    }

    distanciaEnUnidad(distancia, unidad) {
        switch (unidad) {
            case 'km':
                return turf.convertLength(distancia, 'meters', 'kilometers');
            case 'ft':
                return turf.convertLength(distancia, 'meters', 'feet');
            case 'mi':
                return turf.convertLength(distancia, 'meters', 'miles');
            default:
                return distancia;
        }

    }

    getSectoresPresente (recorrido) {
        const { objetoSeleccionado } = this.props
        return recorrido.sectores.filter(s => s.esquemas.map(e =>
            e.trayecto.id).indexOf(objetoSeleccionado.props.elemento.trayecto_ancla) > -1)
    }

    handleClickNewUser () {
        this.setState({
            newUser: true,
            usersFilter: ''
        })
    }

    handleChangeBuscarUser (e) {
        this.setState({
            usersFilter: e.target.value
        })
    }

    handleClickAddUser (user) {
        this.setState({
            usuarios: this.state.usuarios.concat(user),
            newUser: false,
            usersFilter: ''
        })
    }

    handleClickRemoveUser (user) {
        this.setState({
            usuarios: this.state.usuarios.filter(u => u.id !== user.id)
        })
    }

    render() {
        const {
            t, show, objetoSeleccionado, previewImagenSubida, servicios, contactos, usuario, tiposMapas
        } = this.props
        const usuarios = contactos.map(c => c.user_1 === usuario.id ? c.user_2_obj : c.user_1_obj)
        const permitidoStaff = tiposMapas.filter(t =>
            [TIPOS_MAPAS_NOMBRES.STAFF, TIPOS_MAPAS_NOMBRES.MONTAJE].includes(t.nombre)).map(t =>
                t.capa_permitida).flat().map(c => c.id).includes(objetoSeleccionado.props.elemento.bloque.capa.id)
        return (
            <ModalEditor
                show={show}
                onHide={this.onHide}
                onClickAceptar={this.onClickAceptar}
                footer
                onClickCancelar={this.onClickCancelar}
                titulo={this.getTitulo()}
            >
                {objetoSeleccionado &&
                    <Form ref={this.refForm}>
                        <Row>
                            <Col
                                sm={
                                    (objetoSeleccionado.props.elemento.bloque.tipo !== 'pt' ||
                                    objetoSeleccionado.props.elemento.bloque.color_editable) ? 9 : 12
                                }
                            >
                                <Form.Group controlId='form-field_nombre'>
                                    <Form.Label>{t('nombre')}</Form.Label>
                                    <Form.Control name='nombre' defaultValue={objetoSeleccionado.props.elemento.nombre}/>
                                </Form.Group>
                            </Col>
                            {(objetoSeleccionado.props.elemento.bloque.tipo !== 'pt' ||
                                objetoSeleccionado.props.elemento.bloque.color_editable) &&
                                <Col sm={3}>
                                    <Form.Group controlId='form-field_color'>
                                        <Form.Label>{t('color')}</Form.Label>
                                        <Form.Control
                                            type='color'
                                            defaultValue={objetoSeleccionado.props.elemento.color}
                                            name='color'
                                        />
                                    </Form.Group>
                                </Col>}
                        </Row>
                        {objetoSeleccionado.props.elemento.bloque.tipo === 'pt' &&
                            objetoSeleccionado.props.elemento.bloque.color_editable &&
                            <Row>
                                <Col sm={3}>
                                    <Form.Group controlId='form-field_color'>
                                        <Form.Label>{t('color_icono')}</Form.Label>
                                        <Form.Control
                                            type='color'
                                            defaultValue={objetoSeleccionado.props.elemento.color_icono}
                                            name='color_icono'
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>}
                        {objetoSeleccionado.props.elemento.bloque.posible_pdi_proyecto &&
                            <Row>
                                <Col>
                                    <Form.Check as={Row} controlId='form-field_pdi_proyecto'>
                                        <Form.Check.Input
                                            defaultChecked={objetoSeleccionado.props.elemento.pdi_proyecto}
                                            name='pdi_proyecto'
                                        />
                                        <Form.Label>{t('pdi-proyecto')}</Form.Label>
                                    </Form.Check>
                                </Col>
                            </Row>}
                        {objetoSeleccionado.props.elemento.bloque.tipo === 'tr' &&
                            <Row>
                                <Col sm={12}>
                                    <Form.Group controlId='form-field_distancia_oficial'>
                                        <Form.Label>{t('distancia_homologada_metros')}</Form.Label>
                                        <Form.Control
                                            ref={this.refDistancia}
                                            type='text'
                                            name='distancia_homologada'
                                            defaultValue={objetoSeleccionado.props.elemento.distancia_homologada}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>}
                        <Row>
                            <Col sm={objetoSeleccionado.props.elemento.imagen ? 6 : null}>
                                <Form.Group controlId="form-basic-textarea">
                                    <Form.Label>{t('descripcion')}</Form.Label>
                                    {objetoSeleccionado.props.elemento.bloque.tipo === 'tr' &&
                                        <Form.Text className="text-muted">
                                            {t('msg-descripcion-no-publica')}
                                        </Form.Text>}
                                    <Form.Control
                                        name='descripcion'
                                        as="textarea"
                                        defaultValue={objetoSeleccionado.props.elemento.descripcion}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {objetoSeleccionado.props.elemento.bloque.tipo !== 'tr' &&
                            <Row>
                                <Col sm={objetoSeleccionado.props.elemento.imagen ? 6 : null}>
                                    <Form.Group controlId="form-basic-imagen">
                                        <Form.Label>{t('imagen')}</Form.Label>
                                        <Form.Control
                                            onChange={this.onChangeImagen} name='imagen' type="file" accept="image/*"
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="form-basic-url">
                                        <Form.Label>{t('url_embebido')}</Form.Label>
                                        <Form.Control
                                            name='url_embebido'
                                            type="url"
                                            defaultValue={objetoSeleccionado.props.elemento.url_embebido}
                                        />
                                    </Form.Group>
                                </Col>
                                {(objetoSeleccionado.props.elemento.imagen || previewImagenSubida) &&
                                    <Col sm={6}>
                                        <img
                                            className='previsualizacion-imagen'
                                            alt="Previsualización de imagen"
                                            src={
                                                previewImagenSubida
                                                    ? previewImagenSubida : objetoSeleccionado.props.elemento.imagen
                                            }
                                        />
                                        <Button onClick={this.onClickBorrarImagen} className='float-right'>
                                            <FontAwesomeIcon icon={faTrashAlt} transform="left-5"/>
                                            {t('borrar-imagen')}
                                        </Button>
                                    </Col>}
                            </Row>}
                        {objetoSeleccionado.props.elemento.bloque.es_contable &&
                            <Row>
                                <Col sm={12}>
                                    <Form.Label><Trans>Nº de unidades:</Trans></Form.Label>
                                    <Form.Control
                                        type='number'
                                        name='unidades'
                                        defaultValue={objetoSeleccionado.props.elemento.unidades}
                                    />
                                </Col>
                            </Row>}
                        {objetoSeleccionado.props.elemento.bloque.capa.permitido_recorrido &&
                            objetoSeleccionado.props.elemento.bloque.pdi_sobre_trayecto &&
                            <Row>
                                <Col sm={12}>
                                    <Form.Label>{t('msg-servicios-anadir')}</Form.Label>
                                    <div className='celda-servicios'>
                                        {objetoSeleccionado.props.elemento.servicios.map(servicio => {
                                            const srv = servicio.id
                                                ? servicio : servicios.filter(s => s.id === servicio)[0]
                                            return (
                                                <div
                                                    className='icono-servicio'
                                                    key={srv.id}
                                                    style={{backgroundColor: srv.color_fondo, color: srv.color_icono}}
                                                >
                                                    <i
                                                        className={
                                                            srv.icono
                                                                ? srv.icono.prefijo_fuente + ' ' + srv.icono.prefijo_fuente + '-' + srv.icono.icono
                                                                : ''
                                                        }
                                                    />
                                                </div>
                                            )}
                                        )}
                                        <div
                                            onClick={this.onClickMasServicios}
                                            className='icono-servicio icono-mas-servicio btn-primary'
                                        >
                                            <i className='mdi mdi-plus'/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>}
                        {objetoSeleccionado.props.elemento.bloque.capa.permitido_recorrido &&
                            objetoSeleccionado.props.elemento.bloque.pdi_sobre_trayecto &&
                            <Row>
                                <Col sm={12}>
                                    <Form.Label className="mt-3">{t('msg-permitido-recorrido')}</Form.Label>
                                </Col>
                            </Row>}
                        {objetoSeleccionado.props.elemento.bloque.capa.permitido_recorrido &&
                            objetoSeleccionado.props.elemento.bloque.pdi_sobre_trayecto &&
                            !objetoSeleccionado.props.elemento.bloque.es_pk &&
                            this.getRecorridosPresente().map(recorrido =>
                            <Row className='modal-recorrido mx-1' key={recorrido.id}>
                                {recorrido.sectores.length > 1 &&
                                    <Col>
                                        {recorrido.nombre}
                                        {recorrido.sectores.length > 1 && this.getSectoresPresente(recorrido).map(sector =>
                                            <Row key={sector.id} className='row-sector-pk'>
                                                <Col className='col-12 modal-recorrido-titulo'>
                                                    <FormCheck
                                                        onChange={(e) => {this.onChangePdi(e, sector)}}
                                                        checked={this.esPdi(sector)}
                                                        label={sector.nombre}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>}
                                {recorrido.sectores.length === 1 &&
                                    <Col className='col-12 modal-recorrido-titulo' key={1}>
                                        <FormCheck
                                            onChange={(e) => {this.onChangePdi(e, recorrido.sectores[0])}}
                                            checked={this.esPdi(recorrido.sectores[0])}
                                            label={recorrido.nombre}
                                        />
                                    </Col>}
                            </Row>
                        )}
                        {objetoSeleccionado.props.elemento.bloque.capa.permitido_recorrido &&
                            objetoSeleccionado.props.elemento.bloque.pdi_sobre_trayecto &&
                            objetoSeleccionado.props.elemento.bloque.es_pk &&
                            this.getRecorridosPresente().map(recorrido =>
                            <Row className='modal-recorrido mx-1' key={recorrido.id}>
                                {recorrido.sectores.length > 1 &&
                                <Col>
                                    {recorrido.nombre}
                                    {recorrido.sectores.length > 1 && this.getSectoresPresente(recorrido).map(sector =>
                                        <Row key={sector.id} className='row-sector-pk'>
                                            <Col className='col-12 modal-recorrido-titulo'>
                                                <FormCheck
                                                    onChange={(e) => this.onChangePk(e, sector)}
                                                    checked={this.esPk(sector)}
                                                    label={sector.nombre}/>
                                            </Col>
                                            <Col className='col-12 modal-recorrido-tabla'>
                                                {this.esPk(sector) &&
                                                    <div className='row pt-2 pb-2 d-flex align-items-center'>
                                                        <div className='col-3'>
                                                            <label
                                                                htmlFor={'dist-oficial' + recorrido.id}
                                                                className='font-weight-bold'
                                                            >
                                                                {t('distancia_homologada')}
                                                            </label>
                                                        </div>
                                                        <div className='col-9 d-flex align-items-center'>
                                                            <input
                                                                className='form-control'
                                                                type='float'
                                                                defaultValue={this.getValueDistancia(sector)}
                                                                onChange={e => this.onChangeDistancia(e, sector)}
                                                            />
                                                            <span className='ml-1 text-lowercase'>
                                                                {recorrido.unidad_distancia}
                                                            </span>
                                                        </div>
                                                    </div>}
                                            </Col>
                                        </Row>
                                    )}
                                </Col>}
                        {recorrido.sectores.length === 1 && [
                            <Col className='col-12 modal-recorrido-titulo' key={1}>
                                <FormCheck
                                    onChange={(e) => this.onChangePk(e, recorrido.sectores[0])}
                                    checked={this.esPk(recorrido.sectores[0])}
                                    label={recorrido.nombre}
                                />
                            </Col>,
                            <Col className='col-12 modal-recorrido-tabla' key={2}>
                                {this.esPk(recorrido.sectores[0]) &&
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>{t('paso')}</th>
                                                <th>{t('distancia_homologada')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...Array(this.getNumPasos(recorrido.sectores[0]))].map((e, i) =>
                                                <tr key={i}>
                                                    <td>{t('paso') + ' ' + (i+1)}</td>
                                                    <td>
                                                        {i===0
                                                            ? (
                                                                <Form.Group
                                                                    as={Row}
                                                                    controlId={"distancia-homologada-"+recorrido.id}
                                                                    className='modal-recorrido-distancia-homologada'
                                                                >
                                                                    <Col sm={12}>
                                                                        <Form.Control
                                                                            name={'dist-oficial' + recorrido.id}
                                                                            type="float"
                                                                            defaultValue={this.getValueDistancia(recorrido.sectores[0])}
                                                                            onChange={
                                                                                e =>
                                                                                    this.onChangeDistancia(e, recorrido.sectores[0])
                                                                            }
                                                                        />
                                                                        <span className='modal-recorrido-unidades'>
                                                                            {recorrido.unidad_distancia}
                                                                        </span>
                                                                    </Col>
                                                                </Form.Group>
                                                            ) : (
                                                                <div>
                                                                    {this.getDistanciaPaso(recorrido.sectores[0], (i+1), recorrido)}
                                                                    <span className='modal-recorrido-unidades'>
                                                                        {recorrido.unidad_distancia}
                                                                    </span>
                                                                </div>
                                                            )}
                                                    </td>
                                                </tr>
                                             )}
                                        </tbody>
                                    </Table>}
                            </Col>
                        ]}
                    </Row>
                )}
                {permitidoStaff &&
                    <>
                        <div className='row'>
                            <div className='col-12'>
                                <label className='form-label'><Trans>Usuarios</Trans></label>
                            </div>
                        </div>
                        {this.state.usuarios.length > 0 &&
                            <div className='row mb-1'>
                                <div className='col-12'>
                                    <ul className='list-group list-group-flush'>
                                        {this.state.usuarios.map(u =>
                                            <li key={u.id} className='list-group-item'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='d-flex align-items-center'>
                                                        {u.avatar
                                                            ? (
                                                                <img
                                                                    src={SERVER_DOMAIN + u.avatar}
                                                                    alt='avatar'
                                                                    className='avanzado__user-avatar rounded-circle mr-1'
                                                                />
                                                            ) : <i className='fas fa-user-circle fa-3x mr-2' />}
                                                        {u.first_name}
                                                    </span>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={() => {this.handleClickRemoveUser(u)}}
                                                    >
                                                        <i className='fas fa-trash-alt' title={t('Eliminar')} />
                                                    </button>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>}
                        <div className='row'>
                            <div className='col-12 pt-1'>
                                {!this.state.newUser
                                    ? (
                                        <button className='btn btn-primary' onClick={this.handleClickNewUser.bind(this)}>
                                            <i className='fas fa-plus-circle mr-1' />
                                            <Trans>Asociar usuario</Trans>
                                        </button>
                                    ) : (
                                        <input
                                            className='form-control w-50'
                                            placeholder={t('Buscar usuario')}
                                            value={this.state.usersFilter}
                                            onChange={this.handleChangeBuscarUser.bind(this)}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        {this.state.usersFilter !== '' &&
                            <div className='row mt-3'>
                                <div className='col-12'>
                                    <ul className='list-group list-group-flush'>
                                        {usuarios.filter(u => new RegExp([this.state.usersFilter].join('|'), 'i').test(u.first_name)).map(u =>
                                            <li key={u.id} className='list-group-item'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <span className='d-flex align-items-center'>
                                                        {u.avatar
                                                            ? (
                                                                <img
                                                                    src={SERVER_DOMAIN + u.avatar}
                                                                    alt='avatar'
                                                                    className='avanzado__user-avatar rounded-circle mr-1'
                                                                />
                                                            ) : <i className='fas fa-user-circle fa-3x mr-2' />}
                                                        {u.first_name}
                                                    </span>
                                                    <button
                                                        className='btn btn-primary'
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            this.handleClickAddUser(u)
                                                        }}
                                                    >
                                                        <i className='fas fa-plus' title={t('Asociar')} />
                                                    </button>
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>}
                    </>}
            </Form>}
        </ModalEditor>
        )
    }
}


const mapStateToProps = state => ({
    objetoSeleccionado: getObjetoSeleccionado(state),
    previewImagenSubida: getPreviewImagenSubida(state),
    recorridos: getRecorridos(state),
    recorridosAsociar: getRecorridoAsociar(state),
    pksPorAnadir: getPksPorAnadir(state),
    servicios: getServicios(state),
    trayectoNuevo: getTrayectoNuevo(state),
    avisoDistanciaMostrado: getAvisoDistanciaMostrado(state),
    muestraAvisoDistancia: getMuestraAvisoDistancia(state),
    puntoNuevo: getPuntoNuevo(state),
    lineaNueva: getLineaNueva(state),
    poligonoNuevo: getPoligonoNuevo(state),
    sectoresAsociados: getSectoresAsociados(state),
    contactos: state.contactos.data,
    usuario: state.usuario.data,
    tiposMapas: state.tiposMapas.data
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalPropAvanzadas: actions.setVisibleModalPropAvanzadas,
    actualizaTrayecto: actualizaTrayecto,
    setPreviewImagenSubida: actions.setPreviewImagenSubida,
    actualizaPunto: actualizaPunto,
    actualizaLinea: actualizaLinea,
    actualizaPoligono: actualizaPoligono,
    addRecorridoAsociar: actions.addRecorridoAsociar,
    removeRecorridoAsociar: actions.removeRecorridoAsociar,
    resetRecorridoAsociar: actions.resetRecorridoAsociar,
    resetPkPorAnadir: actions.resetPkPorAnadir,
    addPkPorAnadir: actions.addPkPorAnadir,
    removePkPorAnadir: actions.removePkPorAnadir,
    actualizaPk: actualizaPk,
    creaNuevoPk: creaNuevoPk,
    eliminarPk: eliminarPk,
    creaNuevoElementoRecorrido: creaNuevoElementoRecorrido,
    eliminaElementoRecorrido: eliminaElementoRecorrido,
    setPdiEditar: actions.setPdiEditar,
    setVisibleModalEditarServicios: actions.setVisibleModalEditarServicios,
    resetTrayectoNuevo: actionsTrayectos.resetTrayectoNuevo,
    setAvisoDistanciaMostrado: actions.setAvisoDistanciaMostrado,
    setMuestraAvisoDistancia: actions.setMuestraAvisoDistancia,
    resetPuntoNuevo: actionsPuntos.resetNuevoPunto,
    resetNuevaLinea: actionsLineas.resetNuevaLinea,
    resetNuevoPoligono: actionsPoligonos.resetNuevoPoligono,
    addSectorAsociado: actions.addSectorAsociado,
    removeSectorAsociado: actions.removeSectorAsociado,
    resetSectoresAsociados: actions.resetSectoresAsociados,
    actualizaSectorSimple: actualizaSectorSimple
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalPropAvanzadas));
