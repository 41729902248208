import React, {Component} from 'react';
import {Map, CircleMarker, Rectangle, FeatureGroup} from "react-leaflet";
import * as L from 'leaflet';
import {bindActionCreators} from "redux";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import * as turf from '@turf/turf';
import {Row, Col, Button} from "react-bootstrap";

import {getRecorridoCreando} from "../../reducers/recorridos";
import CapaMapa from "../../components/CapaMapa";
import {getCapasFondo} from "../../reducers/capasFondo";
import {getPuntos} from "../../reducers/puntos";
import {getTrayectos} from "../../reducers/trayectos";
import {getLineas} from "../../reducers/lineas";
import {getPoligonos} from "../../reducers/poligonos";
import Trayecto from "../../components/Trayecto";
import actions from "../../actions/recorridos";



class TabVistaInicialRecorrido extends Component {

    constructor(props) {
        super(props);

        this.mapRef = React.createRef();
        this.rectangleRef = React.createRef();

        this.onDragEnd = this.onDragEnd.bind(this);
        this.onClickRestablecer = this.onClickRestablecer.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {pestanaActiva, recorridoCreando} = this.props;
        if(!prevProps.pestanaActiva && pestanaActiva) {
            setTimeout(() =>{
                this.mapRef.current.leafletElement.invalidateSize();
                if(recorridoCreando.sectores[0].esquemas.length > 0) {
                    this.mapRef.current.leafletElement.fitBounds(this.getBoundsRecorrido(), {
                        padding: [50, 50]
                    });
                }
            }, 200)
        }
        if(this.rectangleRef.current && this.mapRef.current) {
            this.rectangleRef.current.leafletElement.disableEdit();
            this.rectangleRef.current.leafletElement.enableEdit();
            this.mapRef.current.leafletElement.addEventListener('editable:vertex:dragend', this.onDragEnd);
        }
    }

    onDragEnd(e) {
        const {setVistaInicial} = this.props;
        let multipoint = turf.multiPoint([e.layer._bounds._northEast, e.layer._bounds._southWest]);
        multipoint.geometry.coordinates[0] = [multipoint.geometry.coordinates[0].lng, multipoint.geometry.coordinates[0].lat];
        multipoint.geometry.coordinates[1] = [multipoint.geometry.coordinates[1].lng, multipoint.geometry.coordinates[1].lat];
        setVistaInicial(multipoint.geometry);
    }

    getCapaFondo() {
        const {recorridoCreando, capasFondo} = this.props;
        return capasFondo.filter(cf => cf.id === recorridoCreando.capa_fondo)[0];
    }

    getBoundsIniciales() {
        const {recorridoCreando, puntos, lineas, poligonos} = this.props;
        let trayectosBounds = [];
        let puntosBounds = [];
        let lineasBounds = [];
        let poligonosBounds = [];

        recorridoCreando.sectores.forEach(s => {
            s.esquemas.map(es => es.trayecto).forEach(t => {
                const trayecto = this.getTrayectoById(t.id ? t.id : t);
                if(trayecto && trayectosBounds.map(tr => tr.id).indexOf(trayecto.id) === -1)
                    trayectosBounds.push(trayecto);
            });
            s.pdis_recorrido.forEach(pt => {
                const punto = this.getPuntoById(pt.id ? pt.id : pt);
                if(punto && puntosBounds.map(p => p.id).indexOf(punto.id) === -1) puntosBounds.push(punto);
            });
            if(s.puntos_km) {
                s.puntos_km.map(pk => pk.punto).forEach(pt => {
                    const punto = this.getPuntoById(pt.id ? pt.id : pt);
                    if(punto && puntosBounds.map(p => p.id).indexOf(punto.id) === -1) puntosBounds.push(punto);
                });
            }
        });

        puntos.forEach(pt => {
            if(pt.pdi_proyecto && puntosBounds.map(p => p.id).indexOf(pt.id)) puntosBounds.push(pt);
        });
        lineas.forEach(ln => {
            if(ln.pdi_proyecto) lineasBounds.push(ln);
        });
        poligonos.forEach(pl => {
            if(pl.pdi_proyecto) poligonosBounds.push(pl);
        });

        let features = [];
        features = features.concat(trayectosBounds.map(tr => turf.lineString(tr.coordenadas.coordinates)));
        features = features.concat(puntosBounds.map(pt => turf.point(pt.coordenadas.coordinates)));
        features = features.concat(lineasBounds.map(ln => turf.lineString(ln.coordenadas.coordinates)));
        features = features.concat(poligonosBounds.map(pl => turf.polygon(pl.coordenadas.coordinates)));

        if(features.length > 0) return L.geoJSON(turf.envelope(turf.featureCollection(features))).getBounds();
        else return null;
    }

    getTrayectoById(id) {
        const {trayectos} = this.props;
        const trayecto = trayectos.filter(t => t.id === id);
        return trayecto.length > 0 ? trayecto[0] : null;
    }

    getPuntoById(id) {
        const {puntos} = this.props;
        const punto = puntos.filter(p => p.id === id);
        return punto.length > 0 ? punto[0] : null;
    }

    getTrayectos() {
        const {recorridoCreando} = this.props;
        let trayectos = [];
        recorridoCreando.sectores.forEach(s => {
            s.esquemas.map(es => es.trayecto).forEach(t => {
                const trayecto = this.getTrayectoById(t.id ? t.id : t);
                if(trayecto && trayectos.map(tr => tr.id).indexOf(trayecto.id) === -1)
                    trayectos.push(trayecto)
            })
        });
        return trayectos;
    }

    getBoundsRecorrido() {
        const {recorridoCreando} = this.props;
        return recorridoCreando.vista_inicial ?
            L.geoJSON(recorridoCreando.vista_inicial).getBounds() : this.getBoundsIniciales();
    }

    onClickRestablecer() {
        const {setVistaInicial} = this.props;
        setVistaInicial(null);
    }

    render() {
        const {t, recorridoCreando} = this.props;
        const bounds = recorridoCreando.sectores[0].esquemas.length > 0 ? this.getBoundsRecorrido() : null;
        const trayectos = recorridoCreando.sectores[0].esquemas.length > 0 ? this.getTrayectos() : [];
        let trSalida = recorridoCreando.sectores[0].esquemas.length > 0 ?
            recorridoCreando.sectores[0].esquemas[0].trayecto : null;
        trSalida = trSalida ? this.getTrayectoById(trSalida.id) : null;
        const ptSalida = trSalida ? trSalida.coordenadas.coordinates[0] : null;
        let trMeta = recorridoCreando.sectores[0].esquemas.length > 0 ?
            recorridoCreando.sectores[recorridoCreando.sectores.length - 1].esquemas[recorridoCreando.sectores[recorridoCreando.sectores.length - 1].esquemas.length - 1].trayecto : null;
        trMeta = trMeta ? this.getTrayectoById(trMeta.id): null;
        const ptMeta = trMeta ? trMeta.coordenadas.coordinates[trMeta.coordenadas.coordinates.length - 1] : null;

        return <div>
            <Row>
                <Col className={"minusculas mb-4"}><p>{t('msg-vista-inicial-1')}</p><p>{t('msg-vista-inicial-2')}</p></Col>
            </Row>
            <Map ref={this.mapRef} center={!bounds ? L.latLng(43.045817, -7.569672) : undefined} editable={true}
                 bounds={bounds} boundsOptions={{padding: [0, 0]}} className='contenedor-mapa-vista-inicial'
                 zoomControl={false} zoom={!bounds ? 6 : undefined}>
                {recorridoCreando.capa_fondo &&
                    <CapaMapa capa={recorridoCreando.capa_fondo.id ? recorridoCreando.capa_fondo : this.getCapaFondo()}
                              map={this.mapRef}/>
                }
                <FeatureGroup>
                    {trayectos.map(t =>
                        <Trayecto key={t.id} elemento={t} editable={false} map={this.mapRef}/>
                    )}
                    {ptSalida &&
                        <CircleMarker radius={15} center={[ptSalida[1], ptSalida[0]]} color='#00ff00' fillOpacity={.8}/>
                    }
                    {ptMeta &&
                        <CircleMarker radius={15} center={[ptMeta[1], ptMeta[0]]} color='#ff0000' fillOpacity={.8}/>
                    }
                    {bounds &&
                        <Rectangle ref={this.rectangleRef} bounds={bounds} color='#fff' fillOpacity={.5} />
                    }
                </FeatureGroup>
                {recorridoCreando.vista_inicial &&
                    <Button className="btn-restablecer-vista align-self-end" onClick={this.onClickRestablecer}>
                        {t('restablecer-vista-inicial')}
                    </Button>
                }
            </Map>
        </div>
    }
}

const mapStateToProps = state => ({
    recorridoCreando: getRecorridoCreando(state),
    capasFondo: getCapasFondo(state),
    puntos: getPuntos(state),
    trayectos: getTrayectos(state),
    lineas: getLineas(state),
    poligonos: getPoligonos(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVistaInicial: actions.setVistaInicial
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TabVistaInicialRecorrido));
