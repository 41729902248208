import React from "react";

const ICON_SIZE = 10
const ICON_PADDING = 5

export default class IconosPuntos extends React.Component {


    getTopForIndex(mostrarMas, index) {
        if (mostrarMas) {
            index ++
        }
        return (ICON_PADDING * 2 + ICON_SIZE) * index + index  // tamaño cuadros + separación 1 px entre cuadros
    }

    render () {
        const {x, y, punto} = this.props

        const mostrarMas = punto.servicios.length > 2
        const servicios = punto.servicios.slice(0, 2)
        const numPuntos = servicios.length ? servicios.length : 1

        const w = ICON_SIZE + (ICON_PADDING * 2)
        let h =  (ICON_PADDING * 2 + ICON_SIZE) * numPuntos + (numPuntos - 1)  // tamaño cuadros + separación 1 px entre cuadros
        if (mostrarMas) {
            h += w
        }

        return <svg  x={x - w / 2} y={y - h} width={w} height={h} /* onClick={onClick }*/>

            {mostrarMas && <g>
                <rect width={w} height={w} fill={'red'} stroke={''} y={0}/>
                <text alignmentBaseline="middle" textAnchor="middle"
                      x="50%" y={ w * .7} stroke={'white'} fill={'white'}
                      className={'fa fas'}
                      style={{fontSize: `${ICON_SIZE}px`}}>
                    {String.fromCodePoint("0xf067")}
                </text>
            </g>
            }
            {servicios.map((servicio, index) => {
                if (servicio.icono) {
                    const y = this.getTopForIndex(mostrarMas, index)

                    return <g key={servicio.id}>
                        <rect width={w} height={w}
                              fill={servicio.color_fondo} stroke={''} y={y}/>
                        <text alignmentBaseline="middle" textAnchor="middle"
                              x="50%" y={y + w * .7}
                              stroke={servicio.color_icono} fill={servicio.color_icono}
                              className={servicio.icono.prefijo_fuente}
                              style={{fontSize: `${ICON_SIZE}px`}}>
                            {String.fromCodePoint("0x" + servicio.icono.caracter)}
                        </text>
                    </g>
                }
                return null
            })}
            {punto.servicios.length === 0 && <g>
                <rect width={w} height={w}
                      fill={punto.color} stroke={''}  y={0} />
                <text
                    alignmentBaseline="middle" textAnchor="middle" x="50%" y={w * .7}
                    stroke={punto.color_icono} fill={punto.color_icono}
                    className={punto.icono.prefijo_fuente}
                    style={{fontSize: `${ICON_SIZE}px`}}>
                    {String.fromCodePoint("0x" + punto.icono.caracter)}
                </text>
            </g>
            }
        </svg>
    }
}
