import React, {Component} from 'react';
import {Button, Row, Col, Breadcrumb, Form, OverlayTrigger, Popover} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { withTranslation, Trans } from "react-i18next";

import ModalEditor from './ModalEditor';

import './ModalAnadirElemento.css';

import {actions} from '../../actions/editor';
import {getCapas, getCapasError, getCapasPendiente} from "../../reducers/capas";
import {getCapaBiblioSeleccionada, getFiltroAnadirElemento, getGrupoBloquesSeleccionado} from "../../reducers/editor";
import {creaNuevoPunto} from "../../fetchactions/puntos";
import {getProyecto} from "../../reducers/proyectos";
import {getPuntos} from "../../reducers/puntos";
import {getTrayectos} from "../../reducers/trayectos";
import {getPoligonos} from "../../reducers/poligonos";
import {getLineas} from "../../reducers/lineas";
import {TIPOS_MAPAS_NOMBRES} from "../../constants/state";

class ModalAnadirElemento extends Component{

    constructor(props){
        super(props);

        this.onHide = this.onHide.bind(this);
        this.onClickBreadcrumb = this.onClickBreadcrumb.bind(this);
        this.onChangeFiltro = this.onChangeFiltro.bind(this)
    }

    componentDidMount() {
        const {setFiltroAnadirElementos} = this.props;
        setFiltroAnadirElementos('');
    }

    /*componentDidUpdate(prevProps, prevState, snapshot) {
        const {show, setFiltroAnadirElementos} = this.props;
        if(show && !prevProps.show) {
            setFiltroAnadirElementos('');
        }
    }*/

    onClickBreadcrumb(capa) {
        const {
            capaBiblioSeleccionada, seleccionarCapaBiblio, setFiltroAnadirElementos, setGrupoBloquesSeleccionado
        } = this.props;
        if(capa) {
            setGrupoBloquesSeleccionado(null);
        } else if(capaBiblioSeleccionada) {
            setGrupoBloquesSeleccionado(null);
            seleccionarCapaBiblio(null);
        }
        setFiltroAnadirElementos('');
    }

    onClickBtnCapa(capa){
        const {seleccionarCapaBiblio, setFiltroAnadirElementos} = this.props;
        setFiltroAnadirElementos('');
        seleccionarCapaBiblio(capa);
    }

    onClickBtnBloque(bloque) {
        this.bloqueSeleccionado(bloque);
    }

    onClickBtnGrupo(grupo) {
        const { setGrupoBloquesSeleccionado } = this.props;
        if(grupo.bloques.length === 1) {
            this.bloqueSeleccionado(grupo.bloques[0]);
        } else {
            setGrupoBloquesSeleccionado(grupo);
        }
    }

    bloqueSeleccionado (bloque) {
        const {cambiarVisibleModalModoCrearElemento, seleccionarBloque, setVisibleModalAnadirServicios} = this.props;
        seleccionarBloque(bloque);
        if(bloque.tipo === 'tr') {
            this.onHide();
            cambiarVisibleModalModoCrearElemento(true);
        } else if(bloque.tipo === 'pt') {
            if(!bloque.pdi_sobre_trayecto) this.creaNuevoPunto(bloque);
            else setVisibleModalAnadirServicios(true);
            this.onHide();
        } else if(bloque.tipo === 'ln') {
            this.onHide();
            cambiarVisibleModalModoCrearElemento(true);
        } else if(bloque.tipo === 'pl') {
            this.onHide();
            cambiarVisibleModalModoCrearElemento(true);
        }
    }

    creaNuevoPunto(bloque) {
        const {creaNuevoPunto, proyecto} = this.props;
        const nombre = this.getNombreSiguientePunto(bloque);
        const propsDefecto = bloque.props_defecto;
        const options = {
            nombre: nombre,
            color: propsDefecto.color ? propsDefecto.color: '#ffff00',
            color_icono: propsDefecto.color_icono ? propsDefecto.color_icono : '#000000',
            bloque: bloque.id,
            icono: propsDefecto.icono_id ? propsDefecto.icono_id : null,
            proyecto: proyecto.id
        };
        creaNuevoPunto(options);
    }

    getNombreSiguientePunto(bloque) {
        const { puntos, lineas, poligonos } = this.props;
        let nombre;
        let contador = 1;
        const elementos = puntos.concat(lineas, poligonos);
        while (!nombre) {
            const cont = contador;
            if (elementos.map(el => el.nombre).indexOf(bloque.nombre + cont) < 0) {
                nombre = bloque.nombre + cont;
            }
            contador++;
        }
        return nombre;
    }

    onHide() {
        const {cambiarVisibleModalAnadirElemento, seleccionarCapaBiblio, setGrupoBloquesSeleccionado} = this.props;
        cambiarVisibleModalAnadirElemento(false);
        seleccionarCapaBiblio(null);
        setGrupoBloquesSeleccionado(null);
    }

    onChangeFiltro(e) {
        const {setFiltroAnadirElementos} = this.props;
        setFiltroAnadirElementos(e.target.value);
    }

    getBloquesGeometrias () {
        const { t, grupoBloquesSeleccionado } = this.props;
        let bloques = [];
        grupoBloquesSeleccionado.bloques.forEach(bl => {
            let nombre = '';
            let icono = '';
            switch (bl.tipo) {
                case 'pt':
                    nombre = t('Ubicación');
                    icono = 'fas fa-map-marker-alt';
                    break;
                case 'ln':
                    nombre = t('Linea');
                    icono = 'mdi mdi-vector-polyline';
                    break;
                case 'pl':
                    nombre = t('Superficie');
                    icono = 'fas fa-draw-polygon';
                    break;
                default:
                    nombre = t('Trayecto');
                    icono = ''
            }
            bloques.push({
                nombre: nombre,
                icono: icono,
                bloque: bl
            })
        });
        return bloques;
    }

    onClickImportar () {
        const { setVisibleModalImportar } = this.props
        this.onHide()
        setVisibleModalImportar(true)
    }

    getMapasPermitida (capa) {
        const { tiposMapas } = this.props
        let mapas = []
        tiposMapas.forEach(tm => {
            if (tm.capa_permitida.map(c => c.id).includes(capa.id)) {
                mapas.push(tm)
            }
        })
        if (!mapas.map(m => m.nombre).includes(TIPOS_MAPAS_NOMBRES.RECORRIDOS)) {
            const bloquesPdi = capa.bloques.filter(b => b.posible_pdi_proyecto)
            if (bloquesPdi.length) {
                mapas = mapas.concat(tiposMapas.filter(tm => tm.nombre === TIPOS_MAPAS_NOMBRES.RECORRIDOS))
            }
        }
        return mapas.sort((a, b) => a.id - b.id)
    }

    render(){
        const {
            show, t, capas, capaBiblioSeleccionada, filtroAnadirElementos, grupoBloquesSeleccionado, recorridos
        } = this.props
        const capaTieneBloques = capaBiblioSeleccionada && capaBiblioSeleccionada.grupos_bloques.filter(gr =>
            gr.bloques.filter(bl => !bl.automatico && !bl.pdi_sobre_trayecto).length > 0).length > 0;

        if (grupoBloquesSeleccionado) {
            var bloquesGeometrias = this.getBloquesGeometrias()
        }

        return <ModalEditor className='diag-add-elemento' show={show} onHide={this.onHide}
                            titulo={t('anadir-nuevo-elemento')}>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => this.onClickBreadcrumb()}
                                         active={!capaBiblioSeleccionada}>{t('biblioteca')}
                                         </Breadcrumb.Item>
                        {capaBiblioSeleccionada &&
                            <Breadcrumb.Item active={!grupoBloquesSeleccionado}
                                             onClick={() => this.onClickBreadcrumb(true)}>
                                {capaBiblioSeleccionada.nombre}
                            </Breadcrumb.Item>
                        }
                        {grupoBloquesSeleccionado &&
                            <Breadcrumb.Item active>
                                {grupoBloquesSeleccionado.nombre}
                            </Breadcrumb.Item>
                        }
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col sm={7}>
                    <Form.Group controlId="filter-elementos">
                        <Form.Control type="text" placeholder={t('filtrar')} value={filtroAnadirElementos}
                                      onChange={this.onChangeFiltro}/>
                    </Form.Group>
                </Col>
                <Col sm={5}>
                    <button className='btn btn-secondary float-right' onClick={this.onClickImportar.bind(this)}>
                        <i className='fas fa-file-import mr-2' />
                        <Trans>Importar desde otro proyecto</Trans>
                    </button>
                </Col>
            </Row>
            {!capaBiblioSeleccionada &&
            <Row className='container-btns-bibio'>
                {capas.filter(c => new RegExp([filtroAnadirElementos].join('|'), "i").test(c.nombre)).map(capa =>
                    <Col className='btn-biblio px-2' key={capa.id} md={2} xs={6}>
                        {capa.descripcion
                            ? (
                                <OverlayTrigger
                                    placement='bottom'
                                    overlay={
                                        <Popover>
                                            <Popover.Content>
                                                {capa.descripcion}
                                                <p className='mt-1 mb-0'>
                                                    <Trans>
                                                        Estos elementos están disponibles para los siguientes tipos de mapa:
                                                    </Trans>
                                                </p>
                                                <ul>
                                                    {this.getMapasPermitida(capa).map(m =>
                                                        <li key={m.id}>{m.nombre}</li>
                                                    )}
                                                </ul>
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    <Button
                                        onClick={() => this.onClickBtnCapa(capa)}
                                        variant="dark"
                                        className={capa.destacada_biblioteca ? 'btn-biblio__destacado' : ''}
                                    >
                                        <div><i className={capa.icono}/></div>
                                        <div>{t(capa.nombre)}</div>
                                    </Button>
                                </OverlayTrigger>
                            ) : (
                                <Button
                                    onClick={() => this.onClickBtnCapa(capa)}
                                    variant="dark"
                                    className={capa.destacada_biblioteca ? 'btn-biblio__destacado' : ''}
                                >
                                    <div><i className={capa.icono}/></div>
                                    <div>{t(capa.nombre)}</div>
                                </Button>
                            )}
                    </Col>
                )}
                </Row>
            }
            {capaBiblioSeleccionada &&
                <>
                    {!grupoBloquesSeleccionado ?
                        <>
                            {capaBiblioSeleccionada.descripcion &&
                                <Row>
                                    <Col sm={12}>
                                        <p>{capaBiblioSeleccionada.descripcion}</p>
                                        <p className='mt-1 mb-0'>
                                            <Trans>
                                                Estos elementos están disponibles para los siguientes tipos de mapa:
                                            </Trans>
                                        </p>
                                        <ul>
                                            {this.getMapasPermitida(capaBiblioSeleccionada).map(m =>
                                                <li key={m.id}><p className='mb-0'>{m.nombre}</p></li>
                                            )}
                                        </ul>
                                    </Col>
                                </Row>
                            }
                            <Row className='container-btns-bibio'>
                                {capaBiblioSeleccionada.grupos_bloques.filter(gb => (gb.bloques.filter(bl => !bl.automatico &&
                                    (!bl.pdi_sobre_trayecto || (bl.pdi_sobre_trayecto && recorridos.length > 0))).length > 0) &&
                                    new RegExp([filtroAnadirElementos].join('|'), 'i').test(gb.nombre)).map(gb =>
                                    <Col className='btn-biblio px-2' key={gb.id} md={2} xs={6}>
                                        {gb.descripcion
                                            ? (
                                                <>
                                                    <OverlayTrigger
                                                        placement='bottom'
                                                        overlay={
                                                            <Popover>
                                                                <Popover.Content>
                                                                    {gb.descripcion}
                                                                </Popover.Content>
                                                            </Popover>
                                                        }
                                                    >
                                                        <Button onClick={() => {this.onClickBtnGrupo(gb)}} variant="dark">
                                                            <div><i className={gb.icono_descripcion}/></div>
                                                            <div>{gb.nombre}</div>
                                                        </Button>
                                                    </OverlayTrigger>
                                                </>
                                            ) : (
                                                <Button onClick={() => {this.onClickBtnGrupo(gb)}} variant="dark">
                                                    <div><i className={gb.icono_descripcion}/></div>
                                                    <div>{gb.nombre}</div>
                                                </Button>
                                            )
                                        }
                                    </Col>
                                )}
                                {recorridos.length === 0 && !capaTieneBloques &&
                                    <Col sm={12}>
                                        <p>
                                            <i className='icono fas fa-exclamation-triangle' /> {t('msg-pdis-no-trayectos')}
                                        </p>
                                    </Col>
                                }
                            </Row>
                        </>
                        :
                        <>
                            {grupoBloquesSeleccionado.descripcion &&
                                <Row>
                                    <Col sm={12}>
                                        <p>{grupoBloquesSeleccionado.descripcion}</p>
                                    </Col>
                                </Row>
                            }
                            <Row className='container-btns-bibio'>
                                {bloquesGeometrias.filter(bg =>
                                    new RegExp([filtroAnadirElementos].join('|'), 'i').test(bg.nombre)).map(bg =>
                                        <Col className='btn-biblio p-2' key={bg.bloque.id} md={2} xs={6}>
                                            <Button onClick={() => {this.onClickBtnBloque(bg.bloque)}} variant="dark">
                                                <div><i className={bg.icono}/></div>
                                                <div>{bg.nombre}</div>
                                            </Button>
                                        </Col>
                                )}
                            </Row>
                        </>}
                </>}
        </ModalEditor>
    }
}

const MapStateToProps = state => ({
    capas: getCapas(state),
    capasError: getCapasError(state),
    capasPendiente: getCapasPendiente(state),
    capaBiblioSeleccionada: getCapaBiblioSeleccionada(state),
    proyecto: getProyecto(state),
    puntos: getPuntos(state),
    lineas: getLineas(state),
    poligonos: getPoligonos(state),
    trayectos: getTrayectos(state),
    filtroAnadirElementos: getFiltroAnadirElemento(state),
    grupoBloquesSeleccionado: getGrupoBloquesSeleccionado(state),
    recorridos: state.recorridos.list,
    tiposMapas: state.tiposMapas.data
});

const MapDispatchToProps = dispatch => bindActionCreators({
    cambiarVisibleModalAnadirElemento: actions.cambiarVisibleModalAnadirElemento,
    seleccionarCapaBiblio: actions.seleccionarCapaBiblio,
    cambiarVisibleModalModoCrearElemento: actions.cambiarVisibleModalModoCrearElemento,
    seleccionarBloque: actions.seleccionarBloque,
    creaNuevoPunto: creaNuevoPunto,
    setVisibleModalAnadirServicios: actions.setVisibleModalAnadirServicios,
    setFiltroAnadirElementos: actions.setFiltroAnadirElementos,
    setGrupoBloquesSeleccionado: actions.setGrupoBloquesSeleccionado,
    setVisibleModalImportar: actions.setVisibleModalImportar
}, dispatch);

export default withTranslation()(connect(MapStateToProps, MapDispatchToProps)(ModalAnadirElemento));
