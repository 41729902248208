import React from 'react'
import { useDrop } from 'react-dnd'
import { Table } from "react-bootstrap"
import { withTranslation } from "react-i18next"
import FilaDragTrayectos from "./FilaDragTrayectos"


const AreaDragSector = props => {
    const {
        t, sector, onDrop, distanciaEnUnidad, getDistanciaTrayecto, onChangeRepeticiones, reordenaTrayectos,
        onClickEliminar, onChangeEsquemaSimplificado
    } = props
    const [{ isOver }, drop] = useDrop({
        accept: 'box',
        drop(item, monitor) {
            onDrop(item, sector)
        },
        collect: monitor => ({
            isOver: monitor.isOver()
        })
    })
    sector.esquemas.sort((a, b) => {return a.orden - b.orden});
    return <div
        ref={drop}
        className={'contenedor-lista-sectores' + (sector.esquemas.length === 0 ? ' vacia' : '') + (isOver ? ' is-drag-over' : '')}
    >
        {sector.esquemas.length === 0
            ? <div className='msg'>{t('msg-anadir-trayectos-recorrido')}</div>
            : (
                <Table className='tabla-trayectos-sector'>
                    <thead>
                        <tr>
                            <th />
                            <th>{t('nombre')}</th>
                            <th>{t('repeticiones')}</th>
                            <th>{t('esquema-principal')}</th>
                            <th>{t('distancia-real')}</th>
                            <th>{t('distancia_homologada')}</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {sector.esquemas.map(trayecto =>
                            <FilaDragTrayectos
                                key={trayecto.orden}
                                index={trayecto.orden}
                                trayecto={trayecto.trayecto}
                                onChangeRepeticiones={onChangeRepeticiones}
                                distanciaEnUnidad={distanciaEnUnidad}
                                getDistanciaTrayecto={getDistanciaTrayecto}
                                sector={sector}
                                repeticiones={trayecto.repeticiones}
                                reordenaTrayectos={reordenaTrayectos}
                                onClickEliminar={onClickEliminar}
                                esquema={trayecto}
                                onChangeEsquemaSimplificado={onChangeEsquemaSimplificado}
                                esquemaSimplificado={trayecto.esquema_simplificado}
                            />
                        )}
                    </tbody>
                </Table>
            )}
    </div>
}


export default withTranslation()(AreaDragSector);
