import React, { Component } from 'react'
import { ButtonGroup } from "react-bootstrap"
import { connect } from "react-redux"

import './BarraHerramientasEditor.css'
import { getBarraHerramientasVisible, getModoBarraHerramientas, getObjetoSeleccionado } from "../../reducers/editor"
import BtnDuplicarElemento from "../../components/BtnDuplicarElemento"
import BtnEliminarElemento from "../../components/BtnEliminarElemento"
import BtnCambiarSentido from "../../components/BtnCambiarSentido"
import BtnElementoParaleloPositivo from "../../components/BtnElementoParaleloPositivo"
import BtnElementoParaleloNegativo from "../../components/BtnElementoParaleloNegativo"
import BtnUnirTrayecto from "../../components/BtnUnirTrayecto"
import BtnContinuar from "../../components/BtnContinuar"
import BtnCortar from "../../components/BtnCortar"
import { getRecorridos } from "../../reducers/recorridos"
import BtnDescargarTrack from "../../components/BtnDescargarTrack"
import BtnDuplicarTramo from "../../components/BtnDuplicarTramo"

class BarraHerramientasEditor extends Component {

    isObjetoAnadidoRecorrido() {
        const { objetoSeleccionado, recorridos } = this.props;
        if (objetoSeleccionado && objetoSeleccionado.props.elemento.bloque.tipo === 'tr') {
            for (let i in recorridos) {
               for (let j in recorridos[i].sectores) {
                   for (let k in recorridos[i].sectores[j].trayectos) {
                       if (recorridos[i].sectores[j].trayectos[k].id === objetoSeleccionado.props.elemento.id) {
                           return true
                       }
                   }
               }
            }
        }
        return false
    }

    render() {
        const { modoBarraHerramientas, barraHerramientasVisible, objetoSeleccionado } = this.props
        return (
            <ButtonGroup
                vertical
                className={'barra-herramientas' + (barraHerramientasVisible ? ' barra-herramientas-visible' : '')}
            >
                <BtnDuplicarElemento className='btn-barra-herramientas-editor'/>
                {modoBarraHerramientas !== 'pt' &&
                    <>
                        <BtnElementoParaleloPositivo className='btn-barra-herramientas-editor'/>
                        <BtnElementoParaleloNegativo className='btn-barra-herramientas-editor'/>
                    </>}
                {modoBarraHerramientas === 'tr' &&
                    <>
                        <BtnCambiarSentido className='btn-barra-herramientas-editor' />
                        <BtnUnirTrayecto className='btn-barra-herramientas-editor' />
                    </>}
                {(modoBarraHerramientas ==='tr' || modoBarraHerramientas === 'ln') &&
                    <>
                        <BtnDuplicarTramo
                            className='btn-barra-herramientas-editor'
                            objetoSeleccionado={objetoSeleccionado}
                        />
                        <BtnCortar className='btn-barra-herramientas-editor' />
                        <BtnContinuar className='btn-barra-herramientas-editor' />
                        <BtnDescargarTrack
                            className='btn-barra-herramientas-editor'
                            objetoSeleccionado={objetoSeleccionado}
                        />
                   </>}
                <BtnEliminarElemento
                    disabled={this.isObjetoAnadidoRecorrido()}
                    className='btn-barra-herramientas-editor btn-barra-herramientas-eliminar'
                />
            </ButtonGroup>
        )
    }
}

const mapStateToProps = state => ({
    modoBarraHerramientas: getModoBarraHerramientas(state),
    barraHerramientasVisible: getBarraHerramientasVisible(state),
    objetoSeleccionado: getObjetoSeleccionado(state),
    recorridos: getRecorridos(state)
});

export default connect(mapStateToProps, null)(BarraHerramientasEditor);
