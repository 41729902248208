
import React from 'react'
import Dimensions from 'react-dimensions'
import {
    VictoryChart,
    VictoryArea,
    VictoryTheme,
    VictoryAxis,
    VictoryLabel,
    VictoryLine,
    VictoryPortal,
    VictoryTooltip,
    VictoryZoomContainer
} from 'victory'
import * as Hammer from 'hammerjs'
import {hexToRgbA} from '../../../../lib/color'
import * as turf from '@turf/turf'
import { actions as visorActions } from '../../../../actions/visor'
import IconosPuntos from './IconosPuntos'
import {CSS_FONT_FAMILY} from "../../../../constants/css";


const estiloEjes = {
    axis: {
        stroke: 'black',
        strokeOpacity: 1
    },
    ticks: {
        size: 2,
        stroke: 'black',
        strokeOpacity: 0.1
    },
    grid: {
        stroke: '#ccc',
        strokeWidth: 1,
        strokeDasharray: '6, 6',
    },
    tickLabels: {
        fontSize: '14px',
        fontFamily: 'inherit',
        fillOpacity: 1,
        fill: 'black',
        margin: 0,
        padding: 0
    },
    axisLabel: {
        fontsize: 13
    }
}


export class GraficoPerfil extends React.Component {

    constructor (props) {
        super(props)
        this.victoryRef = null
        this.currentDomain = null
        this.alturaMaxima = null
        this.alturaMinima = null
        this.alturaRestar = 0
    }

    _calculaDomain (sector, trayectos) {

        let distancia = sector.esquemas.reduce((total, b) => total + b.trayecto.distancia_metros * b.repeticiones, 0)

        if (distancia > 10000) {
            distancia = Math.round((distancia / 1000) * 100) / 100
        }
        let diffY = this.alturaMaxima - this.alturaRestar
        if (diffY < 300) {
            diffY = 300
        }
        const y1 = diffY * 1.4
        const y0 = - diffY

        return {
            x: [0, distancia],
            y: [y0, y1]
        }
    }

    componentDidMount () {
        const {trayectos, sector, dispatch, visor} = this.props
        this.currentDomain = null

        // Se non, non se está especificando o domain no estado...
        if (visor && dispatch) {
            const domain = this._calculaDomain(sector, trayectos)
            dispatch(visorActions.setGraficoDomain(JSON.parse(JSON.stringify(domain))))
            dispatch(visorActions.setGraficoZoomDomain(JSON.parse(JSON.stringify(domain))))

            this.victoryDomainCurrent = domain
        }
    }

    handleContainerRef(ref) {
        const {dispatch, visor} = this.props

        // Se non, non se está especificando o domain no estado...
        if (visor && dispatch) {
            this.victoryRef = ref
            this.iniciaEventosZoom()
        }
    }

    iniciaEventosZoom() {
        // console.info(this.victoryRef)
        // console.info(victoryZoomDomain)
        if (this.victoryRef) {
            const hammer = new Hammer(this.victoryRef, {})
            hammer.get('pinch').set({enable: true})

            hammer.on('pinch', (e) => {
                const {visor, dispatch} = this.props

                if (this.victoryDomainCurrent === null) {
                    this.victoryDomainCurrent = JSON.parse(JSON.stringify(visor.graficoZoomDomain))
                }
                let x = this.victoryDomainCurrent.x[1] - this.victoryDomainCurrent.x[0]
                const newX = x / e.scale
                // alert(x / e.scale)
                const zoom = JSON.parse(JSON.stringify(visor.graficoZoomDomain))
                zoom.x[0] = this.victoryDomainCurrent.x[0] + (x - newX) / 2
                zoom.x[1] = this.victoryDomainCurrent.x[1] - (x - newX) / 2
                if (zoom.x[0] < visor.graficoDomain.x[0]) {
                    zoom.x[0] = visor.graficoDomain.x[0]
                }
                if (zoom.x[1] > visor.graficoDomain.x[1]) {
                    zoom.x[1] = visor.graficoDomain.x[1]
                }

                dispatch(visorActions.setGraficoZoomDomain(zoom))
            })
            hammer.on('pinchend', (e) => {
                const {visor} = this.props
                this.victoryDomainCurrent = JSON.parse(JSON.stringify(visor.graficoZoomDomain))
            })
        }

    }

    procesaLineas (esquemas, trayectos, distanciaUnidad) {
        let distanciaTotal = 0

        const alturaMinima = Math.round(
            Math.min(...trayectos.map(t => t.altura_minima))
        )
        const alturaMaxima = Math.round(
            Math.max(...trayectos.map(t => t.altura_maxima))
        )
        this.alturaRestar = alturaMinima - (.2 * (alturaMaxima - alturaMinima))
        this.alturaRestar = Math.floor(this.alturaRestar / 100) * 100
        if (this.alturaRestar < 0) {
            this.alturaRestar = 0
        }
        this.alturaMaxima = alturaMaxima
        this.alturaMinima = alturaMinima

        return esquemas.map(esquema => {
            let trayecto = trayectos.filter(t => t.id === esquema.trayecto.id)
            if (trayecto.length === 0) {
                return null
            }

            let anterior = null
            const puntosTrayecto = []
            let distanciaTrayecto = 0

            const trayectoNumeroPuntos = trayecto[0].coordenadas_interpoladas.coordinates.length
            const divisor = Math.round(trayectoNumeroPuntos / 50) // solo collemos 50 puntos por trayecto...

            let fnCorregirDistancia = (x) => x;

            if (trayecto[0].distancia_homologada &&  trayecto[0].distancia_homologada !== trayecto[0].distancia_calculada) {
                const ratio = trayecto[0].distancia_homologada / trayecto[0].distancia_calculada;
                fnCorregirDistancia = (x) => ratio * x
            }
            trayecto[0].coordenadas_interpoladas.coordinates.forEach(
                (punto, index) => {
                    if (anterior) {
                        distanciaTrayecto += fnCorregirDistancia(
                            turf.distance(
                                anterior.slice(0, 2), punto.slice(0, 2),
                                {units: 'meters'}
                            )
                        )
                    }
                    if (trayectoNumeroPuntos < 50 ||
                        ((index % divisor) === 0 || index === 0 || index ===
                            trayectoNumeroPuntos)) {
                        const altura = Math.round(punto[2])
                        puntosTrayecto.push({
                            x: Math.round(
                                distanciaTotal + distanciaTrayecto),
                            y: altura - this.alturaRestar
                        })
                    }
                    anterior = punto
                })
            let puntos = []

            // Esto e para evitar un error de compilación de react <<no-loop-func>>,
            // así que en vez de facer os cálculos no bucle <<for>> temos que crear
            // un array de funcións que se executarán despois...
            // https://stackoverflow.com/questions/750486/
            function concatena (i) {
                // console.info(i)
                if (distanciaUnidad === 'm') {
                    puntos = puntos.concat(...puntosTrayecto.map(p => {
                        const distancia = p.x + (distanciaTrayecto * i)
                        return {
                            x: Math.round(distancia),
                            y: p.y
                        }
                    }))
                } else {
                    puntos = puntos.concat(...puntosTrayecto.map(p => {
                        const distancia = p.x + (distanciaTrayecto * i)
                        return {
                            x: Math.round((distancia / 1000) * 100) / 100,
                            y: p.y
                        }
                    }))
                }
                distanciaTotal += distanciaTrayecto
            }

            let fns = []
            for (let i = 0; i < esquema.repeticiones; i++) {
                fns[i] = concatena.bind(this, i)
            }
            fns.forEach((f, index) => f(index))

            return {
                id: esquema.id,
                nombre: esquema.trayecto.nombre,
                stroke: esquema.trayecto.color,
                fill: hexToRgbA(esquema.trayecto.color, .2),
                data: puntos,
                orden: esquema.orden
            }
        }).filter(o => o !== null)
    }

    /**
     * Línea que está a unha determinada distancia
     * */
    getLineaDistancia (lineas, distancia) {
        let encontrado = false
        let sectorCount = 0
        while (!encontrado && sectorCount < lineas.length) {
            const linea = lineas[sectorCount]
            const puntos = linea.data
            if (puntos[0].x <= distancia && puntos[puntos.length - 1].x >= distancia) {
                return linea
            }
            sectorCount ++
        }
        return null
    }

    preprocesaEsquemaConDistancias(esquemas, trayectos) {
        let lineaTotal = []
        esquemas.forEach(esquema => {
            let trayecto = trayectos.filter(t => t.id === esquema.trayecto.id)
            if (trayecto.length === 0) {
                return null
            }
            let i = 0

            while (i < esquema.repeticiones) {
                lineaTotal = lineaTotal.concat(trayecto[0].coordenadas_interpoladas.coordinates)
                i++
            }
        })

        const coordenadasConDistancia = []
        let last = null
        let distancia = 0.0
        lineaTotal.forEach(point => {
            if (last !== null) {
                distancia += turf.distance(last, point, {units: 'meters'})
            }
            const p =  turf.point(point)
            p.distancia = distancia
            coordenadasConDistancia.push(p)
            last = point
        })
        return turf.featureCollection(coordenadasConDistancia)
    }

    render () {
        const {sector, trayectos, handleClickPuntoKm, visor} = this.props

        if (!sector) {
            return null
        }

        let distanciaSector = sector.esquemas.reduce((total, b) =>
            total + b.trayecto.distancia_metros * b.repeticiones, 0);

        let distanciaUnidad = 'm'
        if (distanciaSector > 10000) {
            distanciaUnidad = 'km'
        }
        const lineas = this.procesaLineas(sector.esquemas, trayectos, distanciaUnidad)

        let puntosKmRender = null
        let puntosKmAlturas = []

        if (sector.pdis_recorrido && sector.pdis_recorrido.length) {
            const coordenadasConDistancia = this.preprocesaEsquemaConDistancias(sector.esquemas, trayectos)

            // console.info(coordenadasConDistancia)
            let pdis_recorrido = sector.pdis_recorrido
            if(sector.puntos_km) {
                pdis_recorrido = pdis_recorrido.concat(
                     sector.puntos_km.map(p => p.punto).flat(true)
                )
            }

            puntosKmRender = pdis_recorrido.map(punto => {
                if (punto.es_pk && punto.servicios.length === 0) {
                    return false
                }
                // console.info(punto.icono)
                // console.info(punto.servicios)
                const interseccion = turf.nearestPoint(
                    turf.point(punto.coordenadas.coordinates),
                    coordenadasConDistancia
                )
                const altura = Math.round(interseccion.geometry.coordinates[2])
                const distancia = (distanciaUnidad === 'm') ? interseccion.distancia : interseccion.distancia / 1000

                puntosKmAlturas.push(
                    <VictoryLine
                        key={`l-${punto.id}`}
                        style={{
                            data: {stroke: 'black', strokeWidth: 0},
                            labels: {}
                        }}
                        x={() => distancia}
                        labelComponent={
                            <VictoryTooltip
                                active={true} y={40}
                                cornerRadius={0} pointerLength={0} renderInPortal={true}
                                style={{fontSize: '8px', fill: 'white'}}
                                flyoutStyle={{
                                    stroke: "white",
                                    strokeWidth: 1,
                                    fill: "black"
                                }}
                            />
                        }
                        labels={[altura + "m"]}
                    />
                )
                return <VictoryLine
                    key={`l-${punto.id}`}
                    style={{
                        data: {stroke: 'black', strokeWidth: 1},
                        labels: {}
                    }}
                    labels={['']}
                    labelComponent={
                        <VictoryPortal>
                            <IconosPuntos
                                punto={punto}
                                textAnchor={'start'}
                                renderInPortal={true}
                                angle={0}
                                /* esto parece non facer nada... */
                                // onClick={() => {handleClickPuntoKm(punto)}}
                                // events={{
                                //     onClick: () => {
                                //         alert('sdfasdf')
                                //         handleClickPuntoKm(punto)
                                //     }
                                // }}
                            />
                        </VictoryPortal>
                    }
                    x={() => distancia}
                    events={[
                        {
                            target: "data",
                            eventHandlers: {
                                onClick: () => {
                                    handleClickPuntoKm(punto)
                                }
                            }
                        },
                        /* e esto tampouco fai nada... */
                        {
                            target: "labels",
                            eventHandlers: {
                                onClick: () => {
                                    handleClickPuntoKm(punto)
                                }
                            }
                        }
                    ]}
                />
            })
        }

        const domain = this._calculaDomain(sector, trayectos)

        if (lineas.length === 0) {
            return null
        }
        return <VictoryChart
            width={this.props.containerWidth}
            height={this.props.containerHeight}
            standalone={true}
            responsive={true}
            domain={visor ? visor.graficoDomain : domain}
            containerComponent={
                <VictoryZoomContainer
                    allowZoom={true} allowPan={true}
                    responsive={true} zoomDimension="x"
                    zoomDomain={visor ? visor.graficoZoomDomain : domain}
                    containerRef={this.handleContainerRef.bind(this)}
                />
            }
            theme={VictoryTheme.material}
        >
            {this.props.titulo &&
                <>
                    <VictoryLabel
                    x={this.props.containerWidth / 2} y={10}
                    text={this.props.titulo}
                    textAnchor={'middle'}
                    className={'victory__chart-title'}
                    style={{fontFamily: CSS_FONT_FAMILY}}
                    />
                    {this.props.subtitulo &&
                        <VictoryLabel
                        x={this.props.containerWidth / 2} y={27}
                        text={this.props.subtitulo}
                        textAnchor={'middle'}
                        className={'victory__chart-subtitle'}
                        style={{fontFamily: CSS_FONT_FAMILY}}
                        />
                    }
                </>
            }

            <VictoryAxis
                style={estiloEjes}
                label="Distancia"
                axisLabelComponent={<VictoryLabel dy={-22}/>}
                tickLabelComponent={
                    <VictoryLabel
                        dy={0}
                        text={({datum}) => datum + ' ' + distanciaUnidad}
                    />
                }
            />
            <VictoryAxis
                style={estiloEjes} dependentAxis
                label="Altura (m)"
                axisLabelComponent={<VictoryLabel dy={-20}/>}
                tickLabelComponent={
                    <VictoryLabel
                        dy={0}
                        text={({datum}) => {
                            if (datum >= 0) {
                                return datum + this.alturaRestar
                            }
                        }}
                    />
                }
            />
            {lineas.map(linea => {
                // console.info(linea.id + linea.nombre)
                return <VictoryArea
                    key={linea.orden}
                    interpolation="natural"
                    labelComponent={<VictoryLabel/>}
                    style={{data: {fill: linea.fill, stroke: linea.stroke, strokeWidth: 4}}}
                    data={linea.data}
                />
            })}
            {/*
            Por algún estraño motivo, se non se usa unha variable para os
            puntos km, cando non hai ningún a librería intenta convertir a
            svg un elmento vacío e peta muchirmo.
            */}
            {puntosKmAlturas}
            {puntosKmRender}
        </VictoryChart>
    }
}

const GraficoPerfilAjustado = Dimensions()(GraficoPerfil)
export default GraficoPerfilAjustado
