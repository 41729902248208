import {API_BASE_URL} from "../constants/api";
import {actions} from "../actions/poligonos";
import {actions as actionsComun} from "../actions/comun";
import {actions as actionsEditor} from "../actions/editor";
import {fetchAgrupacion} from "./agrupaciones";
import getCsrfToken from '../lib/csrf'
import { actions as actionsError } from '../actions/error'
import validaFiltrosElemento from './utils'

export function fetchPoligonos(params) {

    const url = validaFiltrosElemento(API_BASE_URL + 'poligono/', params)

    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.fetchPoligonosPendiente());
        fetch(url)
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);

                dispatch(actions.fetchPoligonosCorrecto(res));
                dispatch(actionsComun.restaPeticion());
                return res;
            })
            .catch(err => {
                dispatch(actions.fetchPoligonosError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function creaNuevoPoligono (opciones, subido, importado, formData) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.poligonoNuevoPendiente());
        fetch(API_BASE_URL + 'poligono/', {
            method: 'POST',
            headers: formData
                ? {
                    'X-CSRFToken': getCsrfToken()
                } : {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCsrfToken()
                },
            credentials: 'include',
            body: formData ? formData : JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.poligonoNuevoCorrecto(data));
                            dispatch(actionsComun.restaPeticion());
                            dispatch(fetchAgrupacion(data.agrupacion));
                            if(subido) {
                                dispatch(actions.resetNuevoPoligono());
                                dispatch(actions.addPoligono(data));
                                dispatch(actionsEditor.setElementoRecienSubido(data));
                            }
                            if (importado) {
                                dispatch(actions.resetNuevoPoligono())
                                dispatch(actions.addPoligono(data))
                            }
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


export function actualizaPoligono(id, opciones, formData) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.poligonoActualizandoPendiente());
        fetch(API_BASE_URL + 'poligono/' + id + '/',{
            method: 'PUT',
            headers: formData ? {
                'X-CSRFToken': getCsrfToken()
            } : {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: formData ? formData : JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    return res.json()
                        .then(data => {
                            dispatch(actions.poligonoActualizandoCorrecto(data));
                            dispatch(actions.removePoligono(data));
                            dispatch(actions.addPoligono(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}

export function eliminarPoligono(poligono) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.poligonoEliminadoPendiente(poligono))
        fetch(API_BASE_URL + 'poligono/' + poligono.id + '/', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
        })
            .then(res => {
                if (res.status !== 204) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        })
                } else {
                    dispatch(actions.poligonoEliminadoCorrecto(poligono));
                    dispatch(actionsComun.restaPeticion());
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}
