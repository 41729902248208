import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";

import ModalEditor from "./ModalEditor";
import {actions} from "../../actions/editor";

const ModalBienvenida = ({ dispatch, t }) => {
    return (
        <ModalEditor
            show
            onHide={() => {
                dispatch(actions.setVisibleModalBienvenida(false))
            }}
            titulo={t('Bienvenido/a')}
        >
            <p><Trans>Bienvenidos y bienvenidas a nuestro editor.</Trans></p>
            <p>
                <Trans>
                    Aquí <strong>podrás importar, dibujar y editar tus trayectos (tracks) y todas las ubicaciones</strong> alrededor de tu evento. Cuando lo hayas hecho podrás <strong>configurar un recorrido</strong>, que será el mapa interactivo que <strong>podrás publicar para ver y compartir</strong> con todos tus seguidores y colaboradores.
                </Trans>
            </p>
            <p>
                <Trans>
                    Te pedimos disculpas por adelantado, porque en este momento <strong>nuestro editor está en fase BETA </strong>y no podemos garantizar que no te encuentres con algún pequeño problema con su uso. Si esto ocurre, no dudes en comunicárnoslo a través de nuestro <strong>servicio de soporte</strong>, y en el te intentaremos dar la solución mas adecuada.
                </Trans>
            </p>
            <p><Trans>Muchas gracias por ayudarnos y confiar en nosotros.</Trans></p>
            <p><Trans>El equipo de RaceMapp</Trans></p>
        </ModalEditor>
    )
}

export default withTranslation()(connect()(ModalBienvenida))
