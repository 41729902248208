import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import ModalEditor from "./ModalEditor";
import {getRecorridos} from "../../reducers/recorridos";
import {actions} from "../../actions/editor";
import FilaDragRecorridos from "../../components/FilaDragRecorridos";
import {getRecorridosPorEliminar, getRecorridosReorden} from "../../reducers/editor";

import './ModalOrganizarRecorridos.css';
import {actualizaRecorrido} from "../../fetchactions/recorridos";

class ModalOrganizarRecorridos extends Component {
    constructor (props) {
        super(props)

        this.onClickAceptar = this.onClickAceptar.bind(this)
        this.onHide = this.onHide.bind(this)
        this.reordena = this.reordena.bind(this)
        this.onClickEliminar = this.onClickEliminar.bind(this)
        this.onClickDeseliminar = this.onClickDeseliminar.bind(this)
    }

    componentDidMount () {
        const { recorridos, setRecorridosReorden, setRecorridosPorEliminar } = this.props
        setRecorridosReorden(recorridos)
        setRecorridosPorEliminar([])
    }

    onClickAceptar () {
        const {recorridosReorden, actualizaRecorrido, recorridosPorEliminar,
            setVisibleModalEliminarRecorrido} = this.props
        if(recorridosPorEliminar.length === 0) {
            for(let i in recorridosReorden) {
                actualizaRecorrido(recorridosReorden[i].id, {
                    nombre: recorridosReorden[i].nombre,
                    mapa: recorridosReorden[i].mapa,
                    capa_fondo: recorridosReorden[i].capa_fondo.id
                        ? recorridosReorden[i].capa_fondo.id : recorridosReorden[i].capa_fondo,
                    orden: recorridosReorden[i].orden
                })
            }
        } else {
            setVisibleModalEliminarRecorrido(true)
        }
        this.hide()
    }

    onHide () {
        this.hide()
    }

    hide () {
        const { setVisibleModalOrganizarRecorridos } = this.props
        setVisibleModalOrganizarRecorridos(false)
    }

    reordena (ordenNuevo, ordenOriginal) {
        const { recorridosReorden, setRecorridosReorden } = this.props

        let nuevaLista = []
        if (ordenNuevo < ordenOriginal) {
            for (let i = 0; i < recorridosReorden.length; i++) {
                if (recorridosReorden[i].orden < ordenNuevo || recorridosReorden[i].orden > ordenOriginal) {
                    nuevaLista.push(Object.assign({}, recorridosReorden[i]))
                } else if (recorridosReorden[i].orden === ordenNuevo || recorridosReorden[i].orden < ordenOriginal) {
                    let nuevoItem = Object.assign({}, recorridosReorden[i])
                    nuevoItem.orden = recorridosReorden[i+1].orden
                    nuevaLista.push(nuevoItem)
                } else if (recorridosReorden[i].orden === ordenOriginal) {
                    let nuevoItem = Object.assign({}, recorridosReorden[i])
                    nuevoItem.orden = ordenNuevo
                    nuevaLista.push(nuevoItem)
                }
            }
        } else if (ordenNuevo > ordenOriginal) {
            for (let i in recorridosReorden) {
                if (recorridosReorden[i].orden > ordenNuevo || recorridosReorden[i].orden < ordenOriginal) {
                    nuevaLista.push(Object.assign({}, recorridosReorden[i]))
                } else if (recorridosReorden[i].orden === ordenOriginal) {
                    let nuevoItem = Object.assign({}, recorridosReorden[i])
                    nuevoItem.orden = ordenNuevo
                    nuevaLista.push(nuevoItem)
                } else if (recorridosReorden[i].orden > ordenOriginal && recorridosReorden[i].orden <= ordenNuevo) {
                    let nuevoItem = Object.assign({}, recorridosReorden[i])
                    nuevoItem.orden = recorridosReorden[i-1].orden
                    nuevaLista.push(nuevoItem)
                }
            }
        } else nuevaLista = recorridosReorden
        setRecorridosReorden(nuevaLista.sort((a,b) => a.orden - b.orden))
    }

    onClickEliminar (recorrido) {
        const { addRecorridoPorEliminar } = this.props
        addRecorridoPorEliminar(recorrido)
    }

    onClickDeseliminar (recorrido) {
        const { removeRecorridoPorEliminar } = this.props
        removeRecorridoPorEliminar(recorrido)
    }

    render() {
        const { t, show, recorridosReorden, recorridos } = this.props
        let contador = 0
        return (
            <ModalEditor
                show={show}
                onHide={this.onHide}
                onClickAceptar={this.onClickAceptar}
                footer
                onClickCancelar={this.onHide}
                titulo={t('organizar-recorridos')}
            >
                <p>{t('msg-ordenar-recorridos')}</p>
                <div className='row'>
                    <div className='col-12'>
                        <ul className='list-group list-group-flush'>
                            {recorridosReorden.map(recorrido=> {
                                contador++;
                                return (
                                    <FilaDragRecorridos
                                        key={recorrido.id}
                                        recorrido={recorrido}
                                        reordena={this.reordena}
                                        onClickEliminar={this.onClickEliminar}
                                        contador={contador}
                                    />
                                )
                            })}
                            {recorridos.map(r => {
                                if (recorridosReorden.map(rec => rec.id).indexOf(r.id) === -1)
                                    return (
                                        <FilaDragRecorridos
                                            key={r.id}
                                            recorrido={r}
                                            reordena={() => {}}
                                            eliminado
                                            onClickEliminar={this.onClickDeseliminar}
                                            contador='-'
                                        />
                                   )
                                else
                                    return null;
                            })}
                        </ul>
                    </div>
                </div>
                <p className='mt-3 text-right blockquote-footer'>{t('msg-no-eliminar-recorridos-pagados')}</p>
            </ModalEditor>
        )
    }
}


const mapStateToProps = state => ({
    recorridos: getRecorridos(state),
    recorridosReorden: getRecorridosReorden(state),
    recorridosPorEliminar: getRecorridosPorEliminar(state)
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setVisibleModalOrganizarRecorridos: actions.setVisibleModalOrganizarRecorridos,
    setRecorridosReorden: actions.setRecorridosReorden,
    actualizaRecorrido: actualizaRecorrido,
    setRecorridosPorEliminar: actions.setRecorridosPorEliminar,
    setVisibleModalEliminarRecorrido: actions.setVisibleModalEliminarRecorrido,
    addRecorridoPorEliminar: actions.addRecorridoPorEliminar,
    removeRecorridoPorEliminar: actions.removeRecorridoPorEliminar
}, dispatch);

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ModalOrganizarRecorridos));
