import {API_BASE_URL} from "../constants/api";
import {actions as actionsComun} from "../actions/comun";
import {actions} from "../actions/agrupaciones";
import getCsrfToken from "../lib/csrf";
import {actions as actionsError} from "../actions/error";


export function fetchAgrupacionesProyecto(params, fields, otroProyecto) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion())
        if (otroProyecto) dispatch(actions.fetchAgrupacionesOtroProyectoPendiente())
        else dispatch(actions.fetchAgrupacionesProyectoPendiente())
        const url = new URL(API_BASE_URL + 'agrupacion/')
        if (fields && fields.length) {
            params.fields = fields.join(',')
        }
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )
        fetch(url)
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error)
                if (otroProyecto) dispatch(actions.fetchAgrupacionesOtroProyectoCorrecto(res))
                else dispatch(actions.fetchAgrupacionesProyectoCorrecto(res))
                dispatch(actionsComun.restaPeticion())
            })
            .catch(error => {
                if (otroProyecto) dispatch(actions.fetchAgrupacionesOtroProyectoError(error))
                else dispatch(actions.fetchAgrupacionesProyectoError(error))
                dispatch(actionsComun.restaPeticion())
            })
    }
}


export function fetchAgrupacion(id) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.fetchAgrupacionesProyectoPendiente());
        fetch(API_BASE_URL + 'agrupacion/' + id + '/')
            .then(res => res.json())
            .then(res => {
                if(res.error) throw(res.error);
                dispatch(actions.addAgrupacion(res));
                dispatch(actionsComun.restaPeticion());
            })
            .catch(err => {
                dispatch(actions.fetchAgrupacionesProyectoError(err));
                dispatch(actionsComun.restaPeticion());
            })
    }
}

export function actualizaAgrupacion(id, opciones) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.actualizaAgrupacionPendiente());
        fetch(API_BASE_URL + 'agrupacion/' + id + '/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 200) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        });
                } else {
                    res.json()
                        .then(data => {
                           dispatch(actions.actualizaAgrupacionCorrecto(data));
                           dispatch(actionsComun.restaPeticion());
                        });
                }
            })
            .catch(err => {
                dispatch(actions.actualizaAgrupacionError(err));
                dispatch(actionsError.hasError(err));
                dispatch(actionsComun.restaPeticion());
            })
    }
}

export function creaNuevaAgrupacion(opciones, agrupacion1, agrupacion2) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.nuevaAgrupacionPendiente())
        fetch(API_BASE_URL + 'agrupacion/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if (res.status !== 201) {
                    return res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        });
                } else {
                    res.json()
                        .then( data => {
                            dispatch(actions.nuevaAgrupacionCorrecto(data));
                            dispatch(actionsComun.restaPeticion());
                            if(agrupacion1) {
                                dispatch(eliminaAgrupacion(agrupacion1));
                            }
                            if(agrupacion2) {
                                dispatch(eliminaAgrupacion(agrupacion2));
                            }
                        });
                }
            })
            .catch(err => {
                dispatch(actions.nuevaAgrupacionError(err));
                dispatch(actionsComun.restaPeticion());
                dispatch(actionsError.hasError(err));
            })
    }
}

export function eliminaAgrupacion(agrupacion) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.eliminaAgrupacionPendiente(agrupacion));
        fetch(API_BASE_URL + 'agrupacion/' + agrupacion.id + '/', {
            method: 'DELETE',
            headers: {
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
        })
            .then(res => {
                if(res.status !== 204) {
                    res.json()
                        .then(data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        });
                } else {
                    dispatch(actions.eliminaAgrupacionCorrecto(agrupacion));
                    dispatch(actionsComun.restaPeticion());
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actions.eliminaAgrupacionError(err));
                dispatch(actionsComun.restaPeticion())
            })
    }
}

export function actualizaElemento(id, opciones) {
    return dispatch => {
        dispatch(actionsComun.sumaPeticion());
        dispatch(actions.actualizaElementoPendiente());
        fetch(API_BASE_URL + 'elemento/' + id + '/', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken()
            },
            credentials: 'include',
            body: JSON.stringify(opciones)
        })
            .then(res => {
                if(res.status !== 200) {
                    res.json()
                        .then( data => {
                            dispatch(actionsError.hasError(data));
                            dispatch(actionsComun.restaPeticion());
                        });
                } else {
                    res.json()
                        .then(data => {
                            dispatch(actions.actualizaElementoCorrecto(data));
                            dispatch(actionsComun.restaPeticion());
                        });
                }
            })
            .catch(err => {
                dispatch(actionsError.hasError(err));
                dispatch(actions.actualizaElementoError(err));
                dispatch(actionsComun.restaPeticion());
            });
    }
}


