import {initialState} from "../constants/state";
import {ACTION_TYPES} from "../actions/usuario";

export function usuarioReducer(state = initialState.usuario, action) {

    switch (action.type) {
        case ACTION_TYPES.FETCH_USUARIO_PENDIENTE:
            return {
                ...state,
                pendiente: true,
                error: null,
                data: null,
            };
        case ACTION_TYPES.FETCH_USUARIO_CORRECTO:
            return {
                ...state,
                data: action.data,
                error: null,
                pendiente: false
            };
        case ACTION_TYPES.FETCH_USUARIO_ERROR:
            return {
                ...state,
                error: action.error,
                data: null,
                pendiente: false
            };
        default :
            return{
                ...state
            };
    }
}


export const getUsuarioPendiente = state => state.usuario.pendiente;
export const getUsuario = state => state.usuario.data;
export const getUsuarioError = state => state.usuario.error;
