import { withTranslation } from "react-i18next"
import { connect } from 'react-redux'

import { ModalNuevoMapaMontaje } from "./ModalNuevoMapaMontaje"
import { actions } from "../../actions/editor"
import {updateMapaMontaje} from "../../fetchactions/montaje";

class ModalEditarMontaje extends ModalNuevoMapaMontaje {
    componentDidMount () {}

    handleClickAceptar () {
        const { mapasMontaje, montajeEditando, dispatch } = this.props
        if (this.valida()) {
            const prevMapa = mapasMontaje.filter(mm => mm.id === montajeEditando.id)
            dispatch(updateMapaMontaje(
                montajeEditando.id,
                {
                    nombre: montajeEditando.nombre,
                    visible: montajeEditando.visible,
                    capa_fondo: montajeEditando.capa_fondo,
                    descripcion: montajeEditando.descripcion,
                    meta_descripcion: montajeEditando.meta_descripcion
                },
                prevMapa.length > 0 ? prevMapa[0].elementos_montaje : [],
                montajeEditando.elementos_montaje
            ))
            this.hide()
        }
    }

    hide () {
        const { dispatch } = this.props
        dispatch(actions.setVisibleModalEditarMontaje(false))
        dispatch(actions.setVisibleModalMontaje(true))
    }
}

const mapStateToProps = state => ({
    montajeEditando: state.montajeEditando,
    capasFondo: state.capasFondo.list,
    trayectos: state.trayectos.present.list,
    puntos: state.puntos.list,
    poligonos: state.poligonos.list,
    lineas: state.lineas.list,
    tiposMapas: state.tiposMapas.data,
    agrupaciones: state.agrupacionesProyecto.list,
    proyecto: state.proyecto.data,
    mapasMontaje: state.mapasMontaje.data
})

export default withTranslation()(connect(mapStateToProps)(ModalEditarMontaje))
