/* global fetch */
import { actions } from "../actions/contactos"
import { actions as actionsComun } from '../actions/comun'
import { actions as actionsError } from '../actions/error'
import { API_BASE_URL } from "../constants/api"

export function fetchContactos (params = {}, fields = []) {
    return dispatch => {
        dispatch(actions.fetchContactosBegin())
        dispatch(actionsComun.sumaPeticion())
        const url = new URL(API_BASE_URL + 'contacto/')
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )
        Object.keys(params).forEach(
            key => url.searchParams.append(key, params[key])
        )
        return fetch(url, {
            credentials: 'include'
        })
            .then(res => res.json())
            .then(res => {
                if (res.error) {
                    throw res.error
                } else if (res && res.length) {
                    dispatch(actions.fetchContactosSuccess(res))
                    dispatch(actionsComun.restaPeticion())
                } else {
                    dispatch(actionsComun.restaPeticion())
                }
            })
            .catch(error => {
                dispatch(actions.fetchContactosError(error))
                dispatch(actionsError.hasError(error))
                dispatch(actionsComun.restaPeticion())
            })
    }
}
